import { Button, Paper } from '@material-ui/core';
import backgroundCircles from 'shared/icons/bg-circles.svg';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { BrokerFooter, BrokerHeader } from './BrokerLayout';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Content = styled.div`
  width: 434px;
  max-width: 434px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -42px;
    left: -64px;
    right: -64px;
    height: 140px;
    background: url(${backgroundCircles});
    background-size: 20px 20px;
  }
`;

export const LoginLayout: React.FC = ({ children }) => {
  return (
    <PageLayout
      contentBackground="inherit"
      header={
        <BrokerHeader
          actions={
            <Button
              color="primary"
              variant="outlined"
              href="https://superdispatch.com/signup/shipper"
            >
              Request access
            </Button>
          }
        />
      }
      footer={<BrokerFooter />}
    >
      <Wrapper>
        <Content>
          <Paper>{children}</Paper>
        </Content>
      </Wrapper>
    </PageLayout>
  );
};
