import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Inline, Tag } from '@superdispatch/ui';
import Order, { OrderPaymentSuperPay } from 'shared/types/order';
import { OrderPaymentOnHoldStatus } from './OrderPaymentOnHoldStatus';

interface PaymentStatusTagProps {
  superPay?: OrderPaymentSuperPay | null;
}

export function OrderPaymentStatusTag({ superPay }: PaymentStatusTagProps) {
  switch (superPay?.status) {
    case 'CHARGE_INITIATED':
      return (
        <Tag variant="subtle" color="grey">
          SuperPay Charged
        </Tag>
      );
    case 'SUPER_PAY_CHARGED':
      return (
        <Tag variant="subtle" color="grey">
          SuperPay Charged
        </Tag>
      );
    case 'CHARGE_SCHEDULED':
      return (
        <Tag variant="subtle" color="grey">
          Charge Scheduled
        </Tag>
      );
    case 'PAYMENT_INITIATED':
    case 'PAYMENT_SCHEDULED':
      return (
        <Tag variant="subtle" color="purple">
          Payment Initiated
        </Tag>
      );
    case 'FAILED':
    case 'CHARGE_FAILED':
    case 'PAYMENT_FAILED':
      return (
        <Inline verticalAlign="center" space="xxsmall">
          <Tag variant="subtle" color="red">
            Payment Failed
          </Tag>
          {superPay.error_details && (
            <Tooltip title={superPay.error_details}>
              <Info fontSize="small" color="action" />
            </Tooltip>
          )}
        </Inline>
      );
    case 'PAID':
      return (
        <Tag variant="subtle" color="blue">
          Paid
        </Tag>
      );
    case 'CANCELED':
      return (
        <Tag variant="subtle" color="red">
          Canceled
        </Tag>
      );

    default:
      return null;
  }
}

interface OrderSuperPayStatusProps {
  order: Order;
}

export function OrderPaymentStatus({ order }: OrderSuperPayStatusProps) {
  const superPay = order.payment?.super_pay;
  const showPaymentOnHoldStatus = superPay?.is_on_hold;
  const isPaymentFailed =
    superPay?.status === 'FAILED' ||
    superPay?.status === 'CHARGE_FAILED' ||
    superPay?.status === 'PAYMENT_FAILED';

  const showPaymentStatus = !showPaymentOnHoldStatus || isPaymentFailed;
  return (
    <Inline aria-label="payment status">
      {showPaymentOnHoldStatus && <OrderPaymentOnHoldStatus order={order} />}
      {showPaymentStatus && <OrderPaymentStatusTag superPay={superPay} />}
    </Inline>
  );
}
