import { createSvgIcon } from 'shared/icons/IconUtils';

export const NotificationsIcon = createSvgIcon(
  'NotificationsIcon',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2442 2.01514C12.1643 2.00516 12.0828 2 12 2C10.9 2 10 2.9 10 4V4.29C7.03 5.17 5 7.9 5 11V17L3 19V20H21V19L19 17V12.9291C18.6734 12.9758 18.3395 13 18 13C14.134 13 11 9.86599 11 6C11 4.51937 11.4597 3.1461 12.2442 2.01514ZM14 21C14 22.11 13.11 23 12 23C10.9 23 10 22.11 10 21H14Z"
      fill="currentColor"
    />
    <path
      d="M16.8925 1C16.7325 1 16.6525 1.08 16.6525 1.24L16.3925 2.5C16.1725 2.68 15.8525 2.84 15.6125 3L14.3325 2.5C14.2525 2.5 14.0925 2.5 14.0125 2.6L13.0525 4.36C12.9725 4.44 12.9725 4.6 13.1325 4.68L14.1725 5.5V6.5L13.1325 7.32C13.0525 7.4 12.9725 7.56 13.0525 7.64L14.0125 9.4C14.0925 9.5 14.2525 9.5 14.3325 9.5L15.6125 9C15.8525 9.16 16.1725 9.32 16.3925 9.5L16.6525 10.76C16.6525 10.92 16.7325 11 16.8925 11H18.8925C18.9725 11 19.1325 10.92 19.1325 10.76L19.2925 9.5C19.6125 9.32 19.9325 9.16 20.1725 9L21.3925 9.5C21.5325 9.5 21.6925 9.5 21.6925 9.4L22.7325 7.64C22.8125 7.56 22.7325 7.4 22.6525 7.32L21.6125 6.5V5.5L22.6525 4.68C22.7325 4.6 22.8125 4.44 22.7325 4.36L21.6925 2.6C21.6925 2.5 21.5325 2.5 21.3925 2.5L20.1725 3C19.9325 2.84 19.6125 2.68 19.2925 2.5L19.1325 1.24C19.1325 1.08 18.9725 1 18.8925 1H16.8925ZM17.9325 4.5C18.7325 4.5 19.3925 5.16 19.3925 6.04C19.3925 6.84 18.7325 7.5 17.9325 7.5C17.0525 7.5 16.3925 6.84 16.3925 6.04C16.3925 5.16 17.0525 4.5 17.9325 4.5Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </>,
);
