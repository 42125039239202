import { Toolbar, Typography } from '@material-ui/core';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { PageLayout } from 'shared/layout/PageLayout';
import { PaymentDetailsCard } from './PaymentDetailsCard';
import { SubscriptionAlerts } from './SubscriptionAlerts';
import { SubscriptionPlanCard } from './SubscriptionPlanCard';

export function SubscriptionDetailsPageLegacy() {
  const isMobile = useResponsiveValue(true, false);
  return (
    <PageLayout
      header={
        <Toolbar>
          <Box marginLeft="small" marginRight="small">
            <Typography variant="h2">Subscription Details</Typography>
          </Box>
        </Toolbar>
      }
    >
      <Stack align="center" space="none">
        <Box maxWidth="736px" paddingTop={['large']}>
          <SubscriptionAlerts />
        </Box>
        <Box paddingTop={['small', 'medium']}>
          {isMobile ? (
            <Stack space="small">
              <PaymentDetailsCard />
              <SubscriptionPlanCard />
            </Stack>
          ) : (
            <Inline space="small" horizontalAlign="center">
              <PaymentDetailsCard />
              <SubscriptionPlanCard />
            </Inline>
          )}
        </Box>
      </Stack>
    </PageLayout>
  );
}
