import { Tooltip } from '@material-ui/core';
import { Color, Inline } from '@superdispatch/ui';
import { EnclosedIcon } from 'shared/icons/EnclosedIcon';
import { InoperableVehicleIcon } from 'shared/icons/InoperableVehicleIcon';

interface Props {
  isInoperable: boolean;
  requiresEnclosedTrailer: boolean;
}

export function VehicleConditionIcon({
  isInoperable,
  requiresEnclosedTrailer,
}: Props) {
  return (
    <Inline space="xxsmall">
      {isInoperable && (
        <Tooltip title="Inoperable Vehicle" enterDelay={800}>
          <InoperableVehicleIcon
            fontSize="small"
            htmlColor={Color.Red500}
            aria-label="Inoperable Vehicle icon"
          />
        </Tooltip>
      )}

      {requiresEnclosedTrailer && (
        <Tooltip title="Enclosed Trailer Required" enterDelay={800}>
          <EnclosedIcon
            aria-label="Enclosed Trailer Required icon"
            color="action"
          />
        </Tooltip>
      )}
    </Inline>
  );
}
