import { DateTime } from 'luxon';
import {
  yupBoolean,
  yupDateString,
  yupEnum,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, StringSchema } from 'yup';
import { hasOnlyDigits } from '../../shared/utils/StringUtils';

export const VERIFICATION_APPLICATION_STATUS = [
  'NOT_SUBMITTED',
  'PENDING',
  'WAITING_FOR_CUSTOMER',
  'INTERNATIONAL_SHIPPER',
  'REJECTED',
  'REVOKED',
  'VERIFIED',
] as const;

export const SHIPPER_TYPE = [
  'ONLINE_MARKETPLACE',
  'BROKER',
  'DEALER',
  'CARRIER_DRIVER',
  'OEM',
  'AUCTION',
  'RENTAL',
  'IMPORT_EXPORT',
  'FINANCIAL_INSTITUTION',
  'WHOLESALER',
  'UPFITTER',
] as const;

export const INSURANCE_TYPE = ['BOND', 'INSURANCE'] as const;
export type InsuranceType = typeof INSURANCE_TYPE[number];

export type VerificationApplicationDTO = InferType<
  typeof verificationApplicationSchema
>;
export const verificationApplicationSchema = yupObject({
  status: yupEnum(VERIFICATION_APPLICATION_STATUS, 'NOT_SUBMITTED'),
  company_type: yupEnum(SHIPPER_TYPE, null),
  legal_company_name: yupString().ensure(),
  dba_company_name: yupString().ensure(),
  dba_name_displayed: yupBoolean(),
  street: yupString().ensure(),
  city: yupString().ensure(),
  state: yupString().ensure(),
  zip: yupString().ensure(),
  usdot: yupString().ensure(),
  w9_certificate_url: yupString().ensure(),
  dealer_license_url: yupString().nullable(),
  insurance_certificate_url: yupString().ensure(),
  company_insurance_type: yupEnum(INSURANCE_TYPE, null),
  bond_company_name: yupString().ensure(),
  bond_agent_name: yupString().ensure(),
  bond_agent_phone: yupString().ensure(),
  bond_expiration_date: yupDateString('DateISO'),
  revocation_date: yupDateString('DateISO'),
});

export type VerificationCompanyInformationDTO = InferType<
  typeof verificationCompanyInformationSchema
>;
export const verificationCompanyInformationSchema = yupObject({
  company_type: yupEnum(SHIPPER_TYPE, null).required(),
  legal_company_name: yupString().ensure().required(),
  dba_company_name: yupString().ensure().nullable(),
  dba_name_displayed: yupBoolean(),
  street: yupString().ensure().required(),
  city: yupString().ensure().required(),
  state: yupString().ensure().required(),
  zip: yupString()
    .test('only zip digits', 'Invalid ZIP', hasOnlyDigits)
    .ensure()
    .required(),
});

export type VerificationIdentificationDTO = InferType<
  typeof verificationIdentificationSchema
>;
export const verificationIdentificationSchema = yupObject({
  usdot: yupString()
    .ensure()
    .when('$companyType', (companyType: string, schema: StringSchema) => {
      return companyType === 'BROKER'
        ? schema.test('only digits', 'Invalid USDOT', hasOnlyDigits)
        : schema;
    }),
  w9_certificate_url: yupString().ensure().required(),
  dealer_license_url: yupString()
    .ensure()
    .when('$companyType', (companyType: string, schema: StringSchema) => {
      return companyType === 'DEALER' ? schema.required() : schema.nullable();
    }),
});

export type VerificationInsuranceDTO = InferType<
  typeof verificationInsuranceSchema
>;
export const verificationInsuranceSchema = yupObject({
  insurance_certificate_url: yupString()
    .ensure()
    .required('Certificate of Insurance is required'),
  company_insurance_type: yupEnum(INSURANCE_TYPE, null).required(),
  bond_company_name: yupString().ensure().required(),
  bond_agent_name: yupString().ensure().nullable(),
  bond_agent_phone: yupPhone().ensure().required(),
  bond_expiration_date: yupDateString('DateISO')
    .when(
      'company_insurance_type',
      (insuranceType: string, schema: StringSchema) =>
        insuranceType === 'BOND' ? schema.nullable() : schema.required(),
    )
    .test(
      'validate-date',
      'Expiration Date must be in future',
      (value) =>
        !value ||
        DateTime.fromJSDate(new Date(value)) > DateTime.local().startOf('day'),
    ),
});
