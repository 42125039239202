import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import Order from 'shared/types/order';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import { usePostSDLoadboardMutation } from '../../data/OrderActionAPI';

interface InstantDispatchPostLoadboardDialogContentProps {
  order: Order;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function InstantDispatchPostLoadboardDialogContent({
  order,
  onClose,
  onSubmitSuccess,
}: InstantDispatchPostLoadboardDialogContentProps) {
  const { mutate, isLoading } = usePostSDLoadboardMutation(onSubmitSuccess);

  return (
    <>
      <DialogTitle>No available carriers</DialogTitle>

      <DialogContent>
        Would you like to post the load to the Loadboards?
      </DialogContent>

      <DialogActions>
        <Button variant="neutral" onClick={onClose} disabled={isLoading}>
          No, Don’t Post
        </Button>

        <Button
          pending={isLoading}
          onClick={() => {
            mutate(order.guid);
          }}
        >
          Yes, Post to SLB
        </Button>
      </DialogActions>
    </>
  );
}

interface InstantDispatchPostLoadboardDialogProps
  extends Omit<InstantDispatchPostLoadboardDialogContentProps, 'order'> {
  order: Order | undefined;
}

export function InstantDispatchPostLoadboardDialog({
  order,
  ...props
}: InstantDispatchPostLoadboardDialogProps) {
  return (
    <Dialog open={!!order}>
      <ExitTransitionPlaceholder in={!!order}>
        {order && (
          <InstantDispatchPostLoadboardDialogContent order={order} {...props} />
        )}
      </ExitTransitionPlaceholder>
    </Dialog>
  );
}
