import { Box, Checkbox, Link } from '@material-ui/core';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';

export interface NumberProps {
  id: Order['id'];
  link: string;
  number: string;
  toggleSelectOrder: (id: number) => void;
  selectable?: boolean;
  selected?: boolean;
}

export const OrderTableNumber: React.ComponentType<NumberProps> = ({
  id,
  selectable,
  selected,
  link,
  number,
  toggleSelectOrder,
}) => (
  <Box display="flex" alignItems="center">
    {selectable && (
      <Box mr={1}>
        <Checkbox onChange={() => toggleSelectOrder(id)} checked={!!selected} />
      </Box>
    )}

    <Link component={RouterLink} to={link}>
      <TextOverflow size={100}>{number}</TextOverflow>
    </Link>
  </Box>
);
