import { Link, Typography } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useOnboarding } from 'shared/context/OnboardingContext';
import { useCreditCard } from 'shared/form/ChargebeeFormFields';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { ShieldIcon } from 'shared/icons/ShieldIcon';
import { usePaywallAPI, useSubscriptionDetails } from '../data/PaywallAPI';
import { paywallBillingAddressSchema } from '../data/PaywallDTO';
import { trackClickedSubscribeNow } from '../PaywallAnalyticsEvents';
import { PaywallAccountUpgradedDialog } from './PaywallAccountUpgradedDialog';
import { PaywallBillingAddress } from './PaywallBillingAddress';
import { PaywallChargebeeCard } from './PaywallChargebeeCard';
import { PaywallPlanInformation } from './PaywallPlanInformation';
import { PaywallSubscriptionNotFoundDialog } from './PaywallSubscriptionNotFoundDialog';

export function ChargebeeEmptyPlaceholder() {
  return (
    <Stack space="small">
      <Skeleton height="32px" />
      <Skeleton height="32px" />
      <Columns space="medium">
        <Column width="1/2">
          <Skeleton height="32px" />
        </Column>
        <Column width="1/2">
          <Skeleton height="32px" />
        </Column>
      </Columns>
    </Stack>
  );
}

export function PaywallPaymentForm() {
  const [focus, setFocus] = useState<'number' | 'expiry' | 'cvv'>();
  const [isSubscriptionNotFound, setIsSubsciptionNotFound] = useState(false);
  const [isAccountUpgraded, setIsAccounUpgraded] = useState(false);
  const { completeStep } = useOnboarding();
  const { data } = useSubscriptionDetails({
    onError: (error) => {
      if (error.responseError?.status === 404) {
        setIsSubsciptionNotFound(true);
      }
    },
    onSuccess: ({ chargebee }) => {
      if (
        (chargebee.status === 'ACTIVE' ||
          chargebee.status === 'NON_RENEWING') &&
        !isAccountUpgraded
      ) {
        window.location.reload();
      }
    },
  });
  const { addCreditCard, cardRef, isChargebeeAvailable } = useCreditCard(
    data?.chargebee.client_token,
  );
  const { purchaseSubscription } = usePaywallAPI();

  const formik = useFormikEnhanced({
    initialValues: {
      country: 'US',
      state: '',
      zip: '',
      cardholder_name: '',
      address: '',
      address_second_line: '',
      city: '',
    },
    validationSchema: paywallBillingAddressSchema,
    validateOnBlur: false,
    getFormErrors: (submitError) => {
      return defaultFormErrorsGetter(submitError);
    },
    onSubmit: async (values) => {
      const [firstName, lastName] = values.cardholder_name.split(' ');

      const {
        token,
        additional_information: { braintree },
      } = await addCreditCard({ firstName, lastName });

      const cardNumberStartingDigits = braintree
        ? 'creditCard' in braintree
          ? braintree.creditCard.details.bin
          : braintree.bin
        : 'not_defined';

      return purchaseSubscription({
        token_id: token,
        card_number: `${cardNumberStartingDigits}***`,
        plan_code: 'express-monthly',
        ...values,
      });
    },
    onSubmitSuccess: () => {
      completeStep('SUBSCRIBE');
      setIsAccounUpgraded(true);
    },
  });

  return (
    <Stack space="medium">
      <PaywallPlanInformation />
      <PaywallAccountUpgradedDialog open={isAccountUpgraded} />
      <PaywallSubscriptionNotFoundDialog
        open={isSubscriptionNotFound}
        onClose={() => {
          setIsSubsciptionNotFound(false);
        }}
      />
      <Typography variant="h3">Add Payment Information</Typography>
      <Stack>
        <Typography variant="h5" color="textSecondary">
          Card Details
        </Typography>
        <Box maxWidth="420px">
          <Stack space="xsmall">
            <Columns space="xxsmall" align="top">
              <Column width="content">
                <ShieldIcon fontSize="small" edgeMode="2px" />
              </Column>
              <Column width="fluid">
                <TextBox color="secondary" variant="body">
                  We use industry-standard encryption and security protocols to
                  safeguard your information.
                </TextBox>
              </Column>
            </Columns>
            <Columns space="xxsmall" align="top">
              <Column width="content">
                <Check fontSize="small" htmlColor={Color.Dark100} />
              </Column>
              <Column width="fluid">
                <TextBox color="secondary" variant="body">
                  We accept all major credit cards
                </TextBox>
              </Column>
            </Columns>
          </Stack>
        </Box>

        <FormikProvider value={formik}>
          <Form>
            <Stack space="medium">
              {isChargebeeAvailable ? (
                <PaywallChargebeeCard
                  ref={cardRef}
                  onFocus={setFocus}
                  focus={focus}
                />
              ) : (
                <ChargebeeEmptyPlaceholder />
              )}

              {isChargebeeAvailable && (
                <PaywallBillingAddress
                  onFocus={() => {
                    setFocus(undefined);
                  }}
                />
              )}

              <Box paddingTop="xsmall">
                <Stack space="small">
                  <Button
                    pending={formik.isSubmitting}
                    fullWidth={true}
                    type="submit"
                    size="large"
                    onClick={trackClickedSubscribeNow}
                  >
                    Subscribe Now
                  </Button>
                  <Inline horizontalAlign="center">
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="caption"
                    >
                      By subscribing, you agree to our{' '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.superdispatch.com/terms-of-service"
                      >
                        Terms of Service
                      </Link>
                    </Typography>
                  </Inline>
                  {formik.status.type === 'rejected' && (
                    <Alert
                      icon={<Info fontSize="small" htmlColor={Color.Red500} />}
                      severity="critical"
                    >
                      {formik.status.payload.message}
                    </Alert>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </Stack>
    </Stack>
  );
}
