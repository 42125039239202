import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { forwardRef, useState } from 'react';
import {
  CVVTitleProps,
  FormCardCVV,
  FormCardExpiry,
  FormCardNumber,
} from 'shared/form/ChargebeeFormFields';
import {
  ChargebeeErrorField,
  ChargebeeEvent,
  fonts,
} from 'shared/helpers/ChargebeeWeb';

interface PaywallChargebeeCardProps {
  focus: 'number' | 'expiry' | 'cvv' | undefined;
  onFocus: (param: 'number' | 'expiry' | 'cvv' | undefined) => void;
}

export const PaywallChargebeeCard = forwardRef<
  ChargebeeComponents | null,
  PaywallChargebeeCardProps
>(({ onFocus, focus }, cardRef) => {
  const [errors, setErrors] = useState<ChargebeeErrorField>({
    expiry: null,
    cvv: null,
    number: null,
  });
  const [card, setCard] = useState<CVVTitleProps>({
    isEmpty: true,
    cardType: undefined,
  });

  const handleError = (event: ChargebeeEvent) => {
    const { field, error } = event;
    setErrors((prevErr) => ({ ...prevErr, [field]: error ?? null }));
    if (field === 'number') {
      setCard({ cardType: event.cardType, isEmpty: event.empty });
    }
  };

  const defocus = () => {
    onFocus(undefined);
  };

  return (
    <CardComponent
      placeholder={{ cvv: ' ', number: ' ', expiry: 'MM / YY' }}
      onChange={handleError}
      fonts={fonts}
      ref={cardRef}
    >
      <Box paddingTop="xsmall">
        <Stack space="small">
          <FormCardNumber
            label="Card Number"
            focused={focus === 'number'}
            onFocus={() => {
              onFocus('number');
            }}
            onBlur={defocus}
            error={errors.number ? errors.number.message : ''}
          />

          <Columns space="small">
            <Column width="1/2">
              <FormCardExpiry
                label="Expiration"
                focused={focus === 'expiry'}
                onFocus={() => {
                  onFocus('expiry');
                }}
                onBlur={defocus}
                error={errors.expiry ? errors.expiry.message : ''}
              />
            </Column>
            <Column width="1/2">
              <FormCardCVV
                {...card}
                label="Security Code"
                focused={focus === 'cvv'}
                onFocus={() => {
                  onFocus('cvv');
                }}
                onBlur={defocus}
                error={errors.cvv ? errors.cvv.message : ''}
              />
            </Column>
          </Columns>
        </Stack>
      </Box>
    </CardComponent>
  );
});

PaywallChargebeeCard.displayName = 'PaywallChargebeeCard';
