import { Box, Grid, Link, MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { CUSTOMER_TYPES, formatCustomerType } from '@superdispatch/sdk';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { useCustomer } from 'customers/data/CustomerAPI';
import { getIn, useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { counterpartyDTO, CounterpartyDTO } from 'shared/dto/CounterpartyDTO';
import { required } from 'shared/utils/ValidatorUtils';
import { OrderFormValues } from '../OrderForm';
import { CounterpartyAddress } from './CounterpartyAddress';
import { CounterpartyContactFields } from './CounterpartyContactFields';
import {
  CustomerContact,
  TerminalAutocompleteValue,
} from './CustomerAutocomplete';
import {
  TerminalAutocomplete,
  TerminalChangeReason,
  useTerminalAutocompleteProps,
} from './TerminalAutocomplete';

function validateCounterparty(value: TerminalAutocompleteValue | undefined) {
  const error = required(value?.name);
  if (error) return { name: error };
  return undefined;
}

interface StepCounterpartyFieldsProps {
  name: 'pickup' | 'delivery';
  isOrderCreate: boolean;
}

export function StepCounterpartyFields({
  name,
  isOrderCreate,
}: StepCounterpartyFieldsProps) {
  const { user } = useUserState();
  const { setFieldValue, values } = useFormikContext<OrderFormValues>();

  const adjustedName = `${name}.venue`;
  const isNewCounterParty =
    get(values, `${name}.save_as_new`) &&
    !get(values, `${name}.venue.save_as_recent`);
  const isNewContact = get(values, `${name}.save_as_new_contact`);
  const counterpartyGuid = getIn(values, `${name}.counterparty_guid`);

  const isCustomerVisible = user
    ? user.order_form_settings.is_customer_block_visible ||
      (user.order_form_settings.customer.is_address_visible &&
        values.customer.address) ||
      (user.order_form_settings.customer.is_address_visible &&
        values.customer.city) ||
      (user.order_form_settings.customer.is_address_visible &&
        values.customer.state) ||
      (user.order_form_settings.customer.is_address_visible &&
        values.customer.zip) ||
      values.customer.name ||
      (user.order_form_settings.customer.is_notes_visible &&
        values.customer.notes)
    : false;

  const showSameAsCustomerButton =
    values.customer &&
    user?.shipper.shipper_type === 'BROKER' &&
    isCustomerVisible;

  const { isAdvancedTier } = useProductTiers();

  const autocompleteProps = useTerminalAutocompleteProps();
  const contacts = useMemo(() => {
    const option = autocompleteProps.options.find(
      (x) => x.guid === counterpartyGuid,
    );

    return option?.contacts;
  }, [autocompleteProps.options, counterpartyGuid]);

  useEffect(() => {
    const counterparty = autocompleteProps.options.find(
      (x) => x.guid === counterpartyGuid,
    );

    if (counterparty && isOrderCreate && !!autocompleteProps.loading) {
      handleCounterpartyChange(counterparty, 'select-option');
    }
  }, [autocompleteProps.options, counterpartyGuid]);

  const setFormValue = (
    fieldName: string,
    value?:
      | string
      | number
      | boolean
      | null
      | CustomerContact
      | CounterpartyDTO,
  ) => {
    setFieldValue(fieldName, value);
  };

  function handleCounterpartyChange(
    counterparty: TerminalAutocompleteValue,
    reason: TerminalChangeReason,
  ) {
    if (reason === 'clear') {
      setFormValue(`${name}.latitude`, null);
      setFormValue(`${name}.longitude`, null);
      setFormValue(`${name}.notes`, null);
      setFormValue(`${name}.save_as_new`, false);
      setFormValue(`${name}.save_as_new_contact`, false);
      setFormValue(`${name}.counterparty_guid`, null);
    } else if (reason === 'dont-save') {
      setFormValue(`${name}.save_as_new`, false);
    } else {
      const { contact, contacts: terminalContacts = [] } = counterparty;

      if (reason === 'select-option') {
        if (terminalContacts.length > 0) {
          const nextContact = (terminalContacts.find(
            (terminalContact) => terminalContact.is_primary,
          ) || terminalContacts[0]) as CustomerContact;

          const {
            is_contact_mobile_phone_visible,
            is_contact_email_visible,
            is_contact_title_visible,
          } = user?.order_form_settings[name].venue ?? {};

          setFormValue(`${adjustedName}.contact`, {
            ...nextContact,
            mobile_phone: is_contact_mobile_phone_visible
              ? nextContact.mobile_phone
              : '',
            email: is_contact_email_visible ? nextContact.email : '',
            title: is_contact_title_visible ? nextContact.title : '',
          });
        }
      } else {
        setFormValue(`${adjustedName}.contact`, contact);
      }

      setFormValue(`${name}.counterparty_guid`, counterparty.guid);
      setFormValue(`${name}.notes`, counterparty.notes);
      setFormValue(`${name}.save_as_new`, reason === 'save-as-new');
      setFormValue(`${adjustedName}.address`, counterparty.address);
      setFormValue(`${adjustedName}.city`, counterparty.city);
      setFormValue(
        `${adjustedName}.business_type`,
        user?.order_form_settings[name].venue.is_business_type_visible
          ? counterparty.business_type
          : null,
      );
      setFormValue(`${adjustedName}.state`, counterparty.state);
      setFormValue(`${adjustedName}.zip`, counterparty.zip);
      setFormValue(`${name}.latitude`, null);
      setFormValue(`${name}.longitude`, null);

      if (isOrderCreate) {
        setFormValue(
          `${adjustedName}.selected_from_recent`,
          counterparty.save_as_recent,
        );

        if (
          typeof counterparty.save_as_recent !== 'undefined' &&
          !get(values, `${adjustedName}.save_as_recent`)
        ) {
          setFormValue(`${adjustedName}.save_as_new`, false);
        }
      }
    }
  }

  const [counterPartyGuid, setCounterPartyGuid] = useState<string | null>(null);
  const { data: customer } = useCustomer({ id: null, guid: counterPartyGuid });

  function setSameAsCustomerValues(notes: string | null) {
    const customerValues = counterpartyDTO.cast({ ...values.customer, notes });

    if (values.customer.contact) {
      customerValues.contacts = [
        {
          ...values.customer.contact,
          name: values.customer.contact.name || '',
        },
      ];
    }

    setFormValue(adjustedName, customerValues);
    handleCounterpartyChange(customerValues, 'select-option');
  }

  useEffect(() => {
    if (customer) {
      setSameAsCustomerValues(customer.notes);
      setCounterPartyGuid(null);
    }
  }, [customer]);

  function handleSameAsCustomerClick() {
    if (values.customer.counterparty_guid) {
      setCounterPartyGuid(values.customer.counterparty_guid);
    } else {
      setSameAsCustomerValues(null);
    }
  }

  return (
    <Stack space="small">
      <Grid container={true} spacing={2} wrap="wrap">
        <Grid item={true} xs={12} md={8}>
          <TerminalAutocomplete
            {...autocompleteProps}
            name={adjustedName}
            isNew={isNewCounterParty}
            onChange={handleCounterpartyChange}
            validate={
              isNewCounterParty || isNewContact
                ? validateCounterparty
                : undefined
            }
            label={
              <Box display="flex" justifyContent="space-between">
                <Inline>
                  <Typography>Business Name</Typography>

                  {isNewCounterParty && (
                    <Tag variant="subtle" color="blue">
                      New Terminal
                    </Tag>
                  )}
                </Inline>

                {showSameAsCustomerButton && isAdvancedTier && (
                  <Link
                    aria-label="same as customer"
                    onClick={handleSameAsCustomerClick}
                  >
                    Same as Customer
                  </Link>
                )}
              </Box>
            }
          />
        </Grid>

        {user?.order_form_settings[name].venue.is_business_type_visible && (
          <Grid item={true} xs={12} md={4}>
            <FormikTextField
              label="Type"
              fullWidth={true}
              select={true}
              name={`${adjustedName}.business_type`}
              unstableOnKeyDownSelection={true}
            >
              {CUSTOMER_TYPES.map((value) => (
                <MenuItem key={value} value={value}>
                  {formatCustomerType(value)}
                </MenuItem>
              ))}
            </FormikTextField>
          </Grid>
        )}
      </Grid>

      <CounterpartyAddress name={adjustedName} setFieldValue={setFieldValue} />

      <CounterpartyContactFields
        contacts={contacts}
        isNew={isNewContact}
        name={adjustedName}
        stepName={name}
        showContinue={true}
        onChange={(_, reason) => {
          setFormValue(`${name}.save_as_new_contact`, reason === 'save-as-new');
        }}
        title={values[name].venue.contact_title}
        phone={values[name].venue.contact_mobile_phone}
        email={values[name].venue.contact_email}
      />
    </Stack>
  );
}
