import { Link, Typography } from '@material-ui/core';
import { Notifications, Warning } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import styled from 'styled-components';
import { useNotificationSettings } from '../../../notification-settings/data/NotificationSettingsAPI';
import { useNotificationSettingsChannel } from '../../../notification-settings/data/NotificationsSettingsChannel';
import { trackEvent } from '../../../shared/helpers/AnalyticsHelpers';

const NotificationBox = styled.div<{ $isWarning: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  background-color: ${(props) => (props.$isWarning ? Color.Yellow50 : 'none')};
  border-radius: ${(props) => (props.$isWarning ? '4px' : 'none')};
  padding: ${(props) => (props.$isWarning ? '8px' : 'none')};
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
const IconWrapper = styled.div`
  padding-top: 1px;
`;

function getNotificationEnabledText(type: string) {
  return `The dispatcher receives ${type} notifications for order updates.`;
}

function NotificationStatusText({
  isEmailEnabled,
  isPushEnabled,
}: {
  isEmailEnabled: boolean;
  isPushEnabled: boolean;
}) {
  const isDisabled = !isEmailEnabled && !isPushEnabled;

  function getText() {
    if (isDisabled) {
      return 'Notifications are off. No order updates will be sent.';
    }

    if (isEmailEnabled && isPushEnabled) {
      return getNotificationEnabledText('email and push');
    }

    if (isEmailEnabled) {
      return getNotificationEnabledText('email');
    }

    return getNotificationEnabledText('push');
  }

  return (
    <Typography color={isDisabled ? 'textPrimary' : 'textSecondary'}>
      {getText()}
    </Typography>
  );
}

function SelectIcon({
  isNotificationsDisabled,
}: {
  isNotificationsDisabled: boolean;
}) {
  if (isNotificationsDisabled) {
    return <Warning fontSize="small" htmlColor={Color.Yellow300} />;
  }
  return <Notifications fontSize="small" htmlColor={Color.Dark100} />;
}

export function DispatcherNotification() {
  const { data: notificationSettings, refetch } = useNotificationSettings();
  useNotificationSettingsChannel(() => {
    void refetch();
  });
  const isEmailEnabled =
    !notificationSettings?.is_personal_email_unsubscribed &&
    notificationSettings?.is_personal_email_notifications_enabled;
  const isPushEnabled =
    notificationSettings?.is_personal_push_notifications_enabled;
  const isNotificationsDisabled = !isEmailEnabled && !isPushEnabled;

  return (
    <NotificationBox $isWarning={isNotificationsDisabled}>
      <IconWrapper>
        <SelectIcon isNotificationsDisabled={isNotificationsDisabled} />
      </IconWrapper>

      <TextWrapper>
        <NotificationStatusText
          isEmailEnabled={!!isEmailEnabled}
          isPushEnabled={!!isPushEnabled}
        />

        <Link
          href="/notification-settings"
          target="_blank"
          color={isNotificationsDisabled ? 'textPrimary' : 'textSecondary'}
          onClick={() => {
            trackEvent('Shipper Clicked Manage Notifications', {
              utm_medium: 'Create/Edit order',
              utm_content: 'Dispatcher & Salesperson Block',
            });
          }}
        >
          {' '}
          Manage Notifications
        </Link>
      </TextWrapper>
    </NotificationBox>
  );
}
