import { MessagePayload } from '@firebase/messaging';
import { NextFn, Observer } from '@firebase/util';
import { getApps, initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { useEffect, useMemo, useState } from 'react';
import { FIREBASE_CONFIG } from '../config/FirebaseCredentials';
import {
  readStorageItem,
  removeStorageItem,
  useStorageValue,
  writeStorageItem,
} from './LocalStorage';
import { logError } from './MonitoringService';

const FCM_APP_NAME = '[DEFAULT]';
const FCM_TOKEN_KEY = 'fcm_token';
const FCM_SW_SCOPE = '/firebase-cloud-messaging-push-scope';

async function registerSW() {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker
      .register('/service-worker.js', {
        scope: FCM_SW_SCOPE,
      })
      .then((registration) => {
        // see https://github.com/firebase/firebase-js-sdk/blob/master/packages/messaging/src/helpers/registerDefaultSw.ts
        void registration.update();

        return registration;
      });
  }

  throw Error('ServiceWorker not supported');
}

export function setFCMCurrentToken(clientToken: string | undefined): void {
  if (clientToken) {
    writeStorageItem(FCM_TOKEN_KEY, clientToken);
  } else {
    removeStorageItem(FCM_TOKEN_KEY);
  }
}

export function useFCMClientToken() {
  return useStorageValue(FCM_TOKEN_KEY);
}

export function getFCMCurrentToken() {
  return readStorageItem(FCM_TOKEN_KEY);
}

function getFirebaseApp() {
  const app = getApps().find((x) => x.name === FCM_APP_NAME);

  if (app) {
    return app;
  }

  try {
    return initializeApp(FIREBASE_CONFIG);
  } catch (error: unknown) {
    logError(error, 'FirebaseApp');
  }

  return null;
}

function getFirebaseMessaging() {
  const app = getFirebaseApp();

  return app && getMessaging(app);
}

export function useFirebaseMessaging() {
  const [isMessageSupported, setIsMessageSupported] = useState(false);

  useEffect(() => {
    void isSupported().then(setIsMessageSupported);
  }, []);

  return useMemo(() => {
    if (!isMessageSupported) {
      return null;
    }

    const messaging = getFirebaseMessaging();

    if (!messaging) {
      return null;
    }

    return {
      onMessage: (
        nextOrObserver: NextFn<MessagePayload> | Observer<MessagePayload>,
      ) => onMessage(messaging, nextOrObserver),

      requestAndGetToken: () => {
        return registerSW().then((registration) => {
          return getToken(messaging, {
            serviceWorkerRegistration: registration,
          });
        });
      },
    };
  }, [isMessageSupported]);
}
