import { CardContent, IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { MouseEvent, useState } from 'react';
import {
  MOOV_BUSINESS_TYPE_DISPLAY_NAMES,
  MOOV_MCC_CODE_DISPLAY_NAMES,
} from 'shared/helpers/superpay/MoovDTO';
import styled from 'styled-components';
import { BusinessDTO } from '../data/BusinessDTO';
import { BusinessDetailsEditDrawer, Source } from './BusinessDetailsEditDrawer';
import { EmptyField } from './EmptyField';

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export function BusinessDetails() {
  const [sourceDrawer, setSourceDrawer] = useState<Source>();
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const { setValues, values } = useFormikContext<BusinessDTO>();
  const { legalBusinessName, einNumber, businessType, mccNumber, description } =
    values;

  const openDrawer = (
    e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
    source: Source,
  ) => {
    e.preventDefault();
    setOpenDrawer(true);
    setSourceDrawer(source);
  };

  return (
    <>
      <BusinessDetailsEditDrawer
        source={sourceDrawer}
        open={isOpenDrawer}
        onClose={() => setOpenDrawer(false)}
        onSave={(data) => setValues({ ...values, ...data })}
        values={values}
      />
      <CardContent>
        <Stack space="small">
          <StyledBox>
            <Typography variant="h3">Business Details</Typography>
            <IconButton
              size="small"
              onClick={(e) => openDrawer(e, 'Edit Icon')}
              aria-label="edit-business-details"
            >
              <Edit fontSize="medium" color="action" />
            </IconButton>
          </StyledBox>
          <Stack space="small">
            <Stack>
              <Typography color="textSecondary">Legal Business Name</Typography>
              <Typography>{legalBusinessName}</Typography>
            </Stack>

            <Stack>
              <Typography color="textSecondary">Business Type</Typography>
              <Typography>
                {MOOV_BUSINESS_TYPE_DISPLAY_NAMES[businessType]}
              </Typography>
            </Stack>

            <Stack>
              <Typography color="textSecondary">
                Employer Identification Number (EIN)
              </Typography>
              <EmptyField
                onClick={(e) => openDrawer(e, 'Add Link')}
                isDisabled={!einNumber}
              >
                <Typography>{einNumber}</Typography>
              </EmptyField>
            </Stack>

            <Stack>
              <Typography color="textSecondary">Industry - MCC code</Typography>
              <Typography>
                {MOOV_MCC_CODE_DISPLAY_NAMES[mccNumber]} - {mccNumber}
              </Typography>
            </Stack>

            <Stack>
              <Typography color="textSecondary">Description</Typography>
              <EmptyField
                onClick={(e) => openDrawer(e, 'Add Link')}
                isDisabled={!description}
              >
                <Typography>{description}</Typography>
              </EmptyField>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </>
  );
}
