import { Link, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Box, Flex } from '@rebass/grid';
import { Stack, useUID } from '@superdispatch/ui';
import * as React from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { AttachmentWithFile } from 'shared/types/attachment';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { NoData } from './NoData';

export interface AttachmentsProps {
  order: Order;
}

const StyledLinkWrapper = styled.div`
  overflow-wrap: anywhere;
`;

const getAttachmentSubtitle = (attachment: AttachmentWithFile) => {
  let subtitle = '';

  if (attachment.is_shared_with_customer && attachment.is_shared_with_carrier) {
    subtitle = 'Available to Customer & Carrier';
  } else if (
    !attachment.is_shared_with_customer &&
    !attachment.is_shared_with_carrier
  ) {
    subtitle = 'Internal attachment';
  } else {
    subtitle = `Available to ${
      attachment.is_shared_with_carrier ? 'Carrier' : 'Customer'
    }`;
  }

  return subtitle;
};

export const OrderViewAttachments: React.FunctionComponent<AttachmentsProps> =
  ({ order }) => {
    const uid = useUID();
    const { user } = useUserState();

    return (
      <div aria-labelledby={uid} aria-label="Attachments">
        <Typography variant="h3">Attachments</Typography>

        {order.attachments && order.attachments.length > 0 ? (
          order.attachments.map((attachment, idx) => (
            <Flex mt={idx === 0 ? 3 : 2} key={attachment.id}>
              <Typography variant="h4" color="textSecondary">
                <AttachFileIcon fontSize="inherit" />
              </Typography>

              <Box ml={1}>
                <Stack space="xxsmall">
                  <StyledLinkWrapper>
                    <Link
                      href={attachment.url}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="attachment link"
                    >
                      {attachment.name}
                    </Link>
                  </StyledLinkWrapper>
                  {user?.shipper.shipper_type === 'BROKER' && (
                    <Typography variant="caption" color="textSecondary">
                      {getAttachmentSubtitle(attachment)}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Flex>
          ))
        ) : (
          <NoData my={3} text="No attachments yet" />
        )}
      </div>
    );
  };
