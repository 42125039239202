import { Link } from '@material-ui/core';
import { Business, Code, Phone, Room } from '@material-ui/icons';
import { formatCustomerType } from '@superdispatch/sdk';
import { Stack } from '@superdispatch/ui';
import { DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { BrokerUser } from 'shared/data/UserPermissions';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { TextFieldCopy } from 'shared/form/TextFieldCopy';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import { TerminalDetailsCard } from './TerminalDetailsCard';

interface Props {
  onEdit: () => void;
  terminal: TerminalDTO;
}

export function TerminalDetailsInfoCard({ terminal, onEdit }: Props) {
  const {
    name,
    phone,
    city,
    state,
    zip,
    address,
    business_type,
    custom_external_id,
  } = terminal;

  return (
    <TerminalDetailsCard title="Terminal Info" onEdit={onEdit}>
      <Stack space="small">
        <DescriptionItem icon={<Business fontSize="small" color="action" />}>
          <Stack space="xxsmall">
            <TextBox>{name}</TextBox>
            <TextBox color="secondary">
              {formatCustomerType(business_type)}
            </TextBox>
          </Stack>
        </DescriptionItem>

        <DescriptionItem
          fallback="No phone"
          icon={<Phone fontSize="small" color="action" />}
        >
          {phone && (
            <Link href={`tel:${phone}`} variant="body2">
              {phone}
            </Link>
          )}
        </DescriptionItem>

        <DescriptionItem
          icon={<Room fontSize="small" color="action" />}
          fallback="No address"
        >
          {makeAddressCityStateZip({ address, city, state, zip })}
        </DescriptionItem>

        <BrokerUser>
          <DescriptionItem
            icon={<Code fontSize="small" color="action" />}
            fallback="No custom external ID"
          >
            {custom_external_id && (
              <TextFieldCopy
                fullWidth={true}
                value={custom_external_id}
                inputProps={{ 'aria-label': 'terminal external id' }}
                label={<TextBox color="secondary">Custom External ID</TextBox>}
              />
            )}
          </DescriptionItem>
        </BrokerUser>
      </Stack>
    </TerminalDetailsCard>
  );
}
