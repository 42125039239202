import {
  emitLogout,
  readAppToken,
  readShipperIdForAdmin,
} from 'shared/data/AppUserState';
import { emitPaywallError } from 'shared/errors/paywall/PaywallEvents';
import { SHIPPER_API_HOST } from '../../config/ServerConstants';

export const getAuthorizationHeader = () => {
  const token = readAppToken();
  return token && `Bearer ${token}`;
};

export const getHeaders = () => {
  const shipperIdForAdmin = readShipperIdForAdmin();
  return {
    Authorization: getAuthorizationHeader(),
    ...(shipperIdForAdmin && { 'X-shipper-ID': shipperIdForAdmin }),
    'Content-Type': 'application/json',
  };
};

export const getAPIEndpointUrl = (ENDPOINT_URL) =>
  `${SHIPPER_API_HOST}/${ENDPOINT_URL}`;

export const handleResponseSuccess = (response) => {
  const contentType = response.headers.get('content-type');
  if (contentType) {
    const mimeType = contentType.split(';').shift();

    switch (mimeType) {
      case 'text/plain':
        return response.text();
      case 'application/pdf':
      case 'image/tiff':
      case 'text/csv':
        return response.blob();
      case 'application/json':
      default:
        return response.json();
    }
  }
  return {};
};

export const handleResponseFailure = async (response) => {
  const error = await response.json();

  return Promise.reject(error);
};

export const handleResponse = (response) => {
  const { ok, status } = response;
  if (ok && status >= 200 && status < 300) {
    if (status === 204) return;
    return handleResponseSuccess(response);
  }
  if (status === 401) {
    emitLogout();
    return new Promise(() => {});
  }
  if (status === 402) {
    emitPaywallError();
    return new Promise(() => {});
  }
  return handleResponseFailure(response);
};

export const get = (ENDPOINT_URL, params = {}, { signal } = {}) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const headers = getHeaders();
  const options = { headers, signal };
  Object.keys(params).forEach(
    (key) => params[key] && url.searchParams.append(key, params[key]),
  );
  return fetch(url, options).then(handleResponse);
};

export const whoAmI = () => {
  return get('whoami');
};

export const getText = (ENDPOINT_URL) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const headers = { ...getHeaders(), Accept: 'text/plain' };
  const options = { headers };
  return fetch(url, options).then((response) => response.text());
};

export const post = (ENDPOINT_URL, data) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const headers = getHeaders();
  const body = JSON.stringify(data);
  const method = 'POST';
  const options = { headers, body, method };
  return fetch(url, options).then(handleResponse);
};

export const patch = (ENDPOINT_URL, data) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const headers = getHeaders();
  const body = JSON.stringify(data);
  const method = 'PATCH';
  const options = { headers, body, method };
  return fetch(url, options).then(handleResponse);
};

export const remove = (ENDPOINT_URL, data) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const headers = getHeaders();
  const method = 'DELETE';
  const body = JSON.stringify(data);
  const options = { headers, method, body };

  return fetch(url, options).then(handleResponse);
};

export const update = (ENDPOINT_URL, data) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const headers = getHeaders();
  const method = 'PUT';
  const body = JSON.stringify(data);
  const options = { headers, method, body };

  return fetch(url, options).then(handleResponse);
};

export const file = (ENDPOINT_URL, data) => {
  const url = new URL(getAPIEndpointUrl(ENDPOINT_URL));
  const shipperIdForAdmin = readShipperIdForAdmin();
  const headers = {
    Authorization: getAuthorizationHeader(),
    ...(shipperIdForAdmin && { 'X-shipper-ID': shipperIdForAdmin }),
  };

  const method = 'POST';
  const body = new FormData();

  body.append('file', data);

  const options = { headers, method, body };

  return fetch(url, options).then(handleResponse);
};
