import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const PrivateNetworkIcon = createSvgIcon(
  'PrivateNetworkIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.05829 14.275C3.3194 14.5361 3.63329 14.6667 3.99996 14.6667H12C12.3666 14.6667 12.6805 14.5361 12.9416 14.275C13.2027 14.0139 13.3333 13.7 13.3333 13.3334V6.66669C13.3333 6.30002 13.2027 5.98613 12.9416 5.72502C12.6805 5.46391 12.3666 5.33335 12 5.33335H11.3333V4.00002C11.3333 3.0778 11.0083 2.29169 10.3583 1.64169C9.70829 0.991687 8.92218 0.666687 7.99996 0.666687C7.07774 0.666687 6.29163 0.991687 5.64163 1.64169C4.99163 2.29169 4.66663 3.0778 4.66663 4.00002V5.33335H3.99996C3.63329 5.33335 3.3194 5.46391 3.05829 5.72502C2.79718 5.98613 2.66663 6.30002 2.66663 6.66669V13.3334C2.66663 13.7 2.79718 14.0139 3.05829 14.275ZM9.99996 4.00002V5.33335H5.99996V4.00002C5.99996 3.44446 6.1944 2.97224 6.58329 2.58335C6.97218 2.19446 7.4444 2.00002 7.99996 2.00002C8.55551 2.00002 9.02774 2.19446 9.41663 2.58335C9.80551 2.97224 9.99996 3.44446 9.99996 4.00002ZM4.93323 9.86669L7.29989 12.2334L11.0666 8.46669L10.0999 7.50002L7.29989 10.3L5.89989 8.90002L4.93323 9.86669Z"
    fill={Color.Yellow500}
  />,
  { viewBox: '0 0 14 16', style: { width: 14, height: 14 } },
);
