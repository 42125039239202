import { Typography } from '@material-ui/core';
import { isAPIError, isAPIImportError, isAPIValidationError } from './APIError';
import { APIImportErrorMessage } from './APIImportErrorMessage';
import { APIValidationErrorMessage } from './APIValidationErrorMessage';

interface APIErrorMessageProps {
  error: unknown;
  defaultMessage?: string;
}

export function APIErrorMessage({
  error,
  defaultMessage,
}: APIErrorMessageProps) {
  if (isAPIError(error)) {
    if (isAPIValidationError(error)) {
      return <APIValidationErrorMessage error={error} />;
    }

    if (isAPIImportError(error)) {
      return <APIImportErrorMessage error={error} />;
    }

    if (typeof error.details === 'string') {
      return <Typography>{error.details}</Typography>;
    }

    return <Typography>{error.message}</Typography>;
  }

  if (error instanceof Error) {
    return <Typography>{error.message}</Typography>;
  }

  if (typeof error === 'string') {
    return <Typography>{error}</Typography>;
  }

  return (
    <Typography>
      {defaultMessage && 'Something went wrong. Please try again.'}
    </Typography>
  );
}
