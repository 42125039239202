import { Typography } from '@material-ui/core';
import { Box, Flex } from '@rebass/grid';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, useSnackbarStack, useUID } from '@superdispatch/ui';
import { CarrierAutocompleteField } from 'core/CarrierAutocomplete';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useCanExecute } from 'shared/data/UserPermissions';
import { LinkedBrokerDTO } from 'shared/dto/LinkedBrokerDTO';
import { SourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import { Carrier, CarrierFullInfo } from 'shared/types/carrier';
import { Offer } from 'shared/types/offer';
import Order from 'shared/types/order';
import { Drawer } from 'shared/ui/Drawer';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';
import {
  OrderActionSource,
  trackOderActionEvent,
} from '../actions/OrderActionsAnalytics';
import { UnverifiedCarrierWarningDialog } from '../actions/UnverifiedCarrierWarningDialog';
import { SendOfferNewCarrierFields } from './SendOfferNewCarrierFields';
import { SendOfferPartnerCarriers } from './SendOfferPartnerCarriers';

const createOffer = (formParams: Carrier): Partial<Offer> => ({
  carrier_type: formParams.carrier_type,
  carrier_guid: formParams.guid,
  carrier_name: formParams.dba_name || formParams.name,
  carrier_contact_name: formParams.contact_name,
  carrier_email: formParams.email,
  carrier_phone: formParams.phone_numbers,
  carrier_usdot: formParams.us_dot,
  carrier_address: formParams.address,
  carrier_city: formParams.city,
  carrier_state: formParams.state,
  carrier_zip: formParams.zip,
});

export interface BulkSendOfferDrawerProps {
  orders: Order[] | undefined;
  onClose: () => void;
  onSubmitSuccess: () => void;
  source: OrderActionSource;
}

export function BulkSendOfferDrawer({
  orders,
  source,
  onClose,
  onSubmitSuccess,
}: BulkSendOfferDrawerProps) {
  const formID = useUID();
  const { bulkSendOffer } = useBulkOrderActionAPI();
  const { addSnackbar } = useSnackbarStack();
  const { user } = useUserState();
  const [currentModal, setCurrentModal] = useState<
    'unverified-carrier' | undefined
  >();

  const form = useFormikEnhanced<
    { carrier: LinkedBrokerDTO | CarrierFullInfo | Carrier | null },
    unknown
  >({
    key: orders,
    initialValues: { carrier: null },
    validate({ carrier }) {
      if (!carrier) {
        return { carrier: { guid: 'Please select carrier' } };
      }

      return undefined;
    },
    onSubmit({ carrier }) {
      if (carrier && orders) {
        const ids = orders.map((x) => x.id);
        const offer = createOffer(carrier);

        return bulkSendOffer(ids, offer);
      }

      return Promise.reject(new Error('Orders not selected'));
    },
    onSubmitSuccess(_, { carrier }) {
      if (orders && carrier) {
        for (const order of orders) {
          trackOderActionEvent({
            name: '[STMS] Sent Load Offer',
            order,
            carrier,
            source,
            user,
            carrierSource: 'partner_brokers',
            offer: { price: Number(order.price) },
          });
        }
      }
      addSnackbar('Offer Sent', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const {
    setFieldValue,
    values: { carrier },
  } = form;

  const canUpdateCarrierProfile = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  const notApprovedOrBlacklisted =
    !carrier || !carrier.broker_records?.approved;
  const disableSend =
    (!canUpdateCarrierProfile && notApprovedOrBlacklisted) || !carrier?.guid;

  function setCarrier(value: Carrier | null) {
    setFieldValue('carrier', value);
  }

  return (
    <>
      <UnverifiedCarrierWarningDialog
        open={currentModal === 'unverified-carrier'}
        onClose={() => setCurrentModal(undefined)}
        onContinue={() => {
          setCurrentModal(undefined);
          void form.submitForm();
        }}
      />

      <Drawer
        isOpen={!!orders}
        onClose={onClose}
        width="600px"
        header={
          <Flex flex={1} alignItems="center">
            <Box mt="-2px">
              <Typography variant="h3">Send Load Offers</Typography>

              {orders && orders.length > 0 && (
                <Typography variant="body1">{orders.length} orders</Typography>
              )}
            </Box>

            <Box flex={1}>
              <Flex justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  form={formID}
                  disabled={disableSend}
                  isLoading={form.isSubmitting}
                  onClick={(event) => {
                    event.preventDefault();
                    if (
                      carrier?.is_verified ||
                      carrier?.broker_records?.approved ||
                      carrier?.carrier_type === 'BROKER'
                    ) {
                      void form.submitForm();
                    } else {
                      setCurrentModal('unverified-carrier');
                    }
                  }}
                >
                  Send Offers
                </Button>
              </Flex>
            </Box>
          </Flex>
        }
      >
        <FormikProvider value={form}>
          <Form id={formID} noValidate={true}>
            <Flex m={3} flexDirection="column">
              <SourceManagerLegacy primarySource="Bulk Send Offer Drawer">
                <CarrierAutocompleteField name="carrier" withFMCSA={true} />
              </SourceManagerLegacy>

              {carrier && !carrier.has_signed_up && (
                <Box mt={3}>
                  <SendOfferNewCarrierFields />
                </Box>
              )}

              <SendOfferPartnerCarriers
                onSelect={setCarrier}
                selectedCarrierGuid={carrier?.guid}
              />
            </Flex>
          </Form>
        </FormikProvider>
      </Drawer>
    </>
  );
}
