import { Popper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface VehicleYearAutocompleteProps {
  name: string;
  disabled?: boolean;
}

const StyledTextField = styled(TextField)`
  max-width: 90px;
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 30px;
  }
`;

const StyledPopper = styled(Popper)`
  & .MuiAutocomplete-paper {
    border: none;
  }
`;

const currentYear = new Date().getFullYear();
const yearOptions: string[] = [
  currentYear + 1,
  currentYear,
  currentYear - 1,
  currentYear - 2,
  currentYear - 3,
].map((year: number) => year.toString());

export function VehicleYearAutocomplete({
  name,
  disabled,
}: VehicleYearAutocompleteProps) {
  const [options, setOptions] = React.useState<string[]>(yearOptions);

  const [field, meta, { setValue }] = useField(name);
  const { onBlur } = field;
  const inputValue = useMemo(() => field.value || '', [field.value]);

  React.useEffect(() => {
    setOptions(yearOptions.filter((option) => option.startsWith(inputValue)));
  }, [inputValue]);

  return (
    <Autocomplete
      disabled={disabled}
      inputValue={inputValue?.toString()}
      ListboxProps={{ 'aria-label': 'vehicle year list' }}
      PopperComponent={options.length > 0 ? Popper : StyledPopper}
      autoComplete={true}
      freeSolo={true}
      includeInputInList={true}
      filterOptions={(x) => x}
      options={options}
      onBlur={onBlur}
      onChange={(_event, newValue) => {
        if (newValue) {
          setValue(newValue);
        }
      }}
      onInputChange={(_event, newInputValue, reason) => {
        if (newInputValue || reason === 'clear') {
          setValue(newInputValue);
        }
      }}
      renderOption={(option: string) => option}
      renderInput={(props) => (
        <StyledTextField
          {...props}
          error={
            meta.touched &&
            !!inputValue &&
            (Number(inputValue) < 1900 || Number(inputValue) > currentYear + 10)
          }
        />
      )}
    />
  );
}
