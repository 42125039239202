import { Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Button,
  Color,
  InfoCard,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { useCanExecute } from 'shared/data/UserPermissions';
import { CarrierFullInfo } from 'shared/types/carrier';
import { MultilineText } from 'shared/ui/MultilineTextView';
import styled from 'styled-components';
import {
  useCarrierInternalNotes,
  useCarrierProfileAPI,
} from '../data/ManageCarriersAPI';

interface CarrierInternalNotesProps {
  carrier: CarrierFullInfo;
}

const InfoBox = styled.div`
  border: none;
  border-radius: 3px;
  background-color: ${Color.Blue50};
  padding: 8px 16px;
`;

export function CarrierInternalNotes({ carrier }: CarrierInternalNotesProps) {
  const { addSnackbar } = useSnackbarStack();
  const { addCarrierNote } = useCarrierProfileAPI();
  const { data: notes, error } = useCarrierInternalNotes(carrier.guid);
  const canUserAddNote = useCanExecute('UPDATE_CARRIER_PROFILE_FOR_BROKER');
  const [formikKey, setFormikKey] = useState(false);

  const formik = useFormikEnhanced({
    key: formikKey,
    initialValues: { note: '' },
    onSubmit: (values) => addCarrierNote(carrier.guid, values),
    onSubmitSuccess: () => {
      setFormikKey((x) => !x);
    },
    onSubmitFailure: () =>
      addSnackbar('Failed to add internal note', {
        variant: 'error',
      }),
  });

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to load internal notes', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  return (
    <InfoCard size="large">
      <Stack space="small">
        <Typography variant="h3">Internal Notes</Typography>

        <Stack space="large">
          {canUserAddNote && (
            <FormikProvider value={formik}>
              <Form>
                <Stack space="small">
                  <FormikMaxLengthTextField
                    name="note"
                    rows={2}
                    maxLength={200}
                    fullWidth={true}
                    multiline={true}
                  />

                  <Button
                    disabled={!formik.dirty}
                    isLoading={formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Add Note
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          )}

          {notes?.objects.length ? (
            <Stack space="small">
              {notes.objects.map(({ id, created_at, note, user }, index) => (
                <div key={id}>
                  <Inline aria-label={`internal note ${index + 1} title`}>
                    <Typography variant="body1" gutterBottom={true}>
                      {user.first_name} {user.last_name}
                    </Typography>
                    &bull;
                    <FormattedDate date={created_at} variant="Date" />
                  </Inline>

                  <InfoBox aria-label={`internal note ${index + 1}`}>
                    <MultilineText>{note}</MultilineText>
                  </InfoBox>
                </div>
              ))}
            </Stack>
          ) : !canUserAddNote ? (
            <Typography color="textSecondary">No Internal Notes</Typography>
          ) : null}
        </Stack>
      </Stack>
    </InfoCard>
  );
}
