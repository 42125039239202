import { Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { DrawerActions, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';

interface OtherRequirementsEditDrawerContentProps {
  profile: ShipperProfileDTO;
  onClose: () => void;
}

export function OtherRequirementsEditDrawerContent({
  profile,
  onClose,
}: OtherRequirementsEditDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateAdditionalCarrierRequirements } = useShipperProfileAPI();
  const shouldShowACHPayment = useFeatureToggle('ach-payment.access.ui');

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateAdditionalCarrierRequirements(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('Carrier Requirements', {
        carrier_certificate_of_insurance:
          updatedValues.carrier_certificate_of_insurance,
        carrier_requirements: updatedValues.carrier_requirements,
      });

      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title="Other Requirements"
      actions={
        <DrawerActions>
          <Button
            type="submit"
            variant="primary"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
      DrawerContentProps={{
        style: {
          width: '432px',
        },
      }}
    >
      <Stack space="large">
        {shouldShowACHPayment && (
          <Stack space="small">
            <Typography variant="h4">Payment</Typography>

            <FormikCheckboxField
              name="ach_payments"
              label="ACH Payment Information"
              helperText="Bank name, routing number, bank account number, voided check file."
            />
          </Stack>
        )}

        <FormikMaxLengthTextField
          rows={10}
          rowsMax={10}
          maxLength={500}
          multiline={true}
          fullWidth={true}
          name="carrier_requirements"
          parse={(event) => event.target.value.slice(0, 500)}
          placeholder="For example, add a link to compliance package"
          label="Additional Instructions"
        />
      </Stack>
    </FormikDrawerContent>
  );
}

interface OtherRequirementsEditDrawerProps
  extends OtherRequirementsEditDrawerContentProps {
  open: boolean;
}

export function OtherRequirementsEditDrawer({
  open,
  onClose,
  profile,
}: OtherRequirementsEditDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      {open && (
        <OtherRequirementsEditDrawerContent
          profile={profile}
          onClose={onClose}
        />
      )}
    </FormikDrawer>
  );
}
