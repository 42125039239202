import { render } from 'react-dom';
import 'reflect-metadata';
import { App } from './App';
import { RootProvider } from './RootProvider';
import { bootstrapApp } from './shared/helpers/AppBotstrap';
import './shared/theme/OutdatedBrowser';

bootstrapApp();

render(
  <RootProvider>
    <App />
  </RootProvider>,
  document.getElementById('root'),
);
