import { NullableDateString } from '@superdispatch/dates';
import { Exclude, Expose, plainToClass, Transform } from 'class-transformer';
import { TransformationType } from 'class-transformer/TransformOperationExecutor';
import {
  DictionaryCarrierDTO,
  DictionaryPlaceDTO,
} from 'core/dictionary/data/DictionaryDTO';
import {
  TransformDateString,
  TransformString,
} from 'shared/utils/TransformUtils';

function arrayToToggleRecord(value: string[]) {
  return value.reduce((acc, x) => ({ ...acc, [x]: true }), {});
}

function toggleRecordToArray(value: Record<string, boolean>) {
  return Object.keys(value).filter((key) => value[key]);
}

function TransformToggleRecord() {
  return Transform((raw, _, transformationType) => {
    const value = !raw
      ? []
      : Array.isArray(raw)
      ? raw
      : toggleRecordToArray(raw);

    return transformationType === TransformationType.CLASS_TO_PLAIN
      ? value
      : arrayToToggleRecord(value);
  });
}

function TransformTags() {
  return Transform((value: unknown[] | undefined, _, transformationType) => {
    const parsed = value?.map(Number);

    return transformationType === TransformationType.CLASS_TO_PLAIN
      ? parsed
      : parsed?.map(String);
  });
}
@Exclude()
export class ReportParamsDTO {
  static create(raw: unknown) {
    const value = typeof raw === 'string' ? JSON.parse(raw) : raw;

    return plainToClass(ReportParamsDTO, value);
  }

  @Expose() @TransformDateString('JodaISO') created_begin?: NullableDateString;
  @Expose() @TransformDateString('JodaISO') created_end?: NullableDateString;
  @Expose() @TransformDateString('JodaISO') accepted_begin?: NullableDateString;
  @Expose() @TransformDateString('JodaISO') accepted_end?: NullableDateString;
  @Expose() @TransformDateString('JodaISO') pickup_begin?: NullableDateString;
  @Expose() @TransformDateString('JodaISO') pickup_end?: NullableDateString;
  @Expose()
  @TransformDateString('JodaISO')
  scheduled_pickup_begin?: NullableDateString;
  @Expose()
  @TransformDateString('JodaISO')
  scheduled_pickup_end?: NullableDateString;

  @Expose() @TransformDateString('JodaISO') delivery_begin?: NullableDateString;
  @Expose() @TransformDateString('JodaISO') delivery_end?: NullableDateString;
  @Expose()
  @TransformDateString('JodaISO')
  scheduled_delivery_begin?: NullableDateString;
  @Expose()
  @TransformDateString('JodaISO')
  scheduled_delivery_end?: NullableDateString;
  @Expose() report_name?: string | null;
  @Expose() user_id?: number | null;

  @Expose()
  @TransformString()
  send_report_to: string;

  @Expose() order_by?: string | null;

  @Expose() @TransformToggleRecord() fields: Record<string, boolean>;
  @Expose() include: 'all' | 'active' | 'archived';
  @Expose()
  @Transform((value: string | undefined | null) => value ?? 'ALL')
  order_type: 'ALL' | 'ORDERS' | 'LOADS';
  @Expose() @TransformToggleRecord() statuses: Record<string, boolean>;
  @Expose() @TransformToggleRecord() payment_statuses: Record<string, boolean>;
  @Expose() pickup_addresses?: DictionaryPlaceDTO[];
  @Expose() delivery_addresses?: DictionaryPlaceDTO[];
  @Expose() customer_names?: string[];
  @Expose() dispatcher_names?: string[];
  @Expose() salespersons?: string[];
  @Expose() carriers?: DictionaryCarrierDTO[];
  @Expose() carrier_names?: string[];

  @Expose()
  @TransformTags()
  tags?: string[];
}
