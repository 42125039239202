import { PaymentMethod, PaymentTerm } from '@superdispatch/sdk';

export class CustomerPaymentDTO {
  tariff: number;

  deposit: number;

  amount: number;

  terms: PaymentTerm;

  notes: string;

  method: PaymentMethod;

  reference_number: string;

  received_date: string;
}
