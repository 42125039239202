import { useMemo, useState } from 'react';
import { DictionaryKey, DictionaryValues } from './data/DictionaryDTO';

interface DictionaryListOptions {
  values: DictionaryValues;
  items: Set<DictionaryKey>;

  onRemove: (key: DictionaryKey) => void;
  onChange: <T extends keyof DictionaryValues>(
    key: T,
    value: DictionaryValues[T],
  ) => void;
}

export function useDictionaryList({
  items,
  values,
  onRemove,
  onChange,
}: DictionaryListOptions) {
  const [active, setActive] = useState<DictionaryKey>();

  const activeItems = useMemo(() => {
    const next = Object.keys(values).filter(
      (key) => !!values[key]?.length && items.has(key as DictionaryKey),
    );

    if (active && !next.includes(active)) {
      next.push(active);
    }

    return next as DictionaryKey[];
  }, [active, items, values]);

  const availableItems = useMemo(() => {
    const next = new Set(items);

    for (const item of activeItems) {
      next.delete(item);
    }

    return Array.from(next);
  }, [activeItems, items]);

  function getProps<T extends DictionaryKey>(type: T) {
    return {
      type,
      value: values[type],
      active: active === type,

      onClose: () => setActive(undefined),
      onChange: (value: DictionaryValues[T]) => onChange(type, value),
      onSelect: () => {
        setActive((prev) => (prev === type ? undefined : type));
      },
      onRemove: () => {
        onRemove(type);
        setActive(undefined);
      },
    };
  }

  return {
    setActive,
    getProps,
    activeItems,
    availableItems,

    disabled: !!active || availableItems.length === 0,
  };
}
