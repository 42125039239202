import { Typography } from '@material-ui/core';
import {
  FormikMaxLengthTextField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { DrawerForm } from 'shared/form/DrawerForm';
import styled from 'styled-components';
import { FormikBusinessSinceField } from '../BusinessSinceField';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';

interface AboutUsEditDrawerProps {
  open: boolean;
  profile: ShipperProfileDTO;
  onClose: () => void;
  onUpdate?: () => void;
}

/*
  FIXME: Analyze behaviour of the form without using this component.
  This component is used with `FormControl` to provide max width

  Behavior: Width of the drawer increases as a new textfield component is added.
  Grid doesn't wrap its elements even if the rule is force set.
  It is happening particularly with textfield components which are build
  on top of `<input/>` element.

  Workaround:
    1. Explicitly setting width of the section of form.
    2. Wrap the section with a parent compoinent which has `position: absolute`
 */
export const NormalizeWidth = styled.div`
  max-width: 368px;
`;

export function AboutUsEditFormFields() {
  return (
    <Stack space="large">
      <Stack space="small">
        <FormikMaxLengthTextField
          name="about_us"
          label="About"
          maxLength={1000}
          placeholder="Write about your company"
          multiline={true}
          fullWidth={true}
        />

        <FormikBusinessSinceField
          name="in_business_since"
          label={
            <Inline space="xxsmall">
              In Business Since{' '}
              <Typography color="textSecondary" display="inline">
                (Required)
              </Typography>{' '}
            </Inline>
          }
        />

        <FormikTextField
          name="personal_page_url"
          label="Website"
          fullWidth={true}
        />
      </Stack>

      <Stack space="small">
        <Typography variant="h4">Location</Typography>

        <FormikTextField
          name="address"
          label="Street Address"
          fullWidth={true}
        />

        <NormalizeWidth>
          <Columns space="xsmall" collapseBelow="tablet">
            <Column>
              <FormikTextField name="city" label="City" fullWidth={true} />
            </Column>

            <Column>
              <FormikTextField name="state" label="State" fullWidth={true} />
            </Column>
            <Column>
              <FormikTextField
                name="zip"
                label="ZIP"
                fullWidth={true}
                // ZIP+4 and Delivery Point support
                inputProps={{ maxLength: 13 }}
              />
            </Column>
          </Columns>
        </NormalizeWidth>
      </Stack>
    </Stack>
  );
}

export function AboutUsEditDrawer({
  open,
  profile,
  onClose,
  onUpdate,
}: AboutUsEditDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateAboutsUs } = useShipperProfileAPI();
  const formik = useFormikEnhanced({
    key: open,
    initialValues: profile,
    onSubmit: (values) => {
      return updateAboutsUs(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('About Us', {
        about_us: updatedValues.about_us,
        in_business_since: updatedValues.in_business_since,
        personal_page_url: updatedValues.personal_page_url,
        address: updatedValues.address,
        city: updatedValues.city,
        state: updatedValues.state,
        zip: updatedValues.zip,
      });

      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to update profile', { variant: 'error' });
    },
  });
  const { dirty, isValid, isSubmitting, status, handleSubmit } = formik;

  return (
    <DrawerForm
      title="About Us"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
    >
      <FormikProvider value={formik}>
        <Stack space="large">
          <AboutUsEditFormFields />
          <Button
            type="submit"
            variant="primary"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </Button>
        </Stack>
      </FormikProvider>
    </DrawerForm>
  );
}
