import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CardButton, useUID } from '@superdispatch/ui';
import { Fragment } from 'react';
import { usePermission } from 'shared/data/UserPermissions';
import { Divider } from 'shared/ui/Divider';
import { CustomerDTO } from '../../shared/dto/CustomerDTO';
import { LabelInfo } from './CustomerDetails';
import { CustomerDetailsCard } from './CustomerDetailsCard';

export const notesForCarrierHint =
  'Instructions or notes that will be displayed to a carrier inside the order once the load offer is accepted.';
export const internalNotesHint =
  'Notes specific to this Customer. Used and displayed internally in Shipper TMS only.';

interface Props {
  customer: CustomerDTO;
  onEdit: () => void;
  onAddNotes: () => void;
  onAddInternalNotes: () => void;
}

export function CustomerDetailsNotes({
  onEdit,
  customer,
  onAddNotes,
  onAddInternalNotes,
}: Props) {
  const notesId = useUID();
  const internalNotesId = useUID();
  const { notes, internal_notes: internalNotes } = customer;

  const canManageCustomer = usePermission('CUSTOMER_MANAGEMENT', 'canExecute');

  return (
    <CustomerDetailsCard title="Notes" onEdit={onEdit}>
      {notes ? (
        <>
          <Box mt={2}>
            <LabelInfo
              text="Notes for Carrier"
              tooltip={notesForCarrierHint}
              TextProps={{ id: notesId }}
            />
          </Box>

          <Box mt={1} aria-labelledby={notesId}>
            {notes.split('\n').map((item, key) => (
              <Fragment key={key}>
                {item}

                <br />
              </Fragment>
            ))}
          </Box>
        </>
      ) : (
        <CardButton
          size="large"
          startIcon={canManageCustomer ? <Add /> : null}
          hint={notesForCarrierHint}
          disabled={!canManageCustomer}
          onClick={canManageCustomer ? onAddNotes : () => {}}
        >
          {canManageCustomer ? 'Add Notes for Carrier' : ''}
        </CardButton>
      )}

      {!!notes || !!internalNotes ? <Divider my={3} /> : <Box my={2} />}

      {internalNotes ? (
        <>
          <Box mt={2}>
            <LabelInfo
              text="Internal Notes"
              tooltip={internalNotesHint}
              TextProps={{ id: internalNotesId }}
            />
          </Box>

          <Box mt={1} aria-labelledby={internalNotesId}>
            {internalNotes.split('\n').map((item, key) => (
              <Fragment key={key}>
                {item}

                <br />
              </Fragment>
            ))}
          </Box>
        </>
      ) : (
        <CardButton
          size="large"
          startIcon={canManageCustomer ? <Add /> : null}
          hint={internalNotesHint}
          disabled={!canManageCustomer}
          onClick={canManageCustomer ? onAddInternalNotes : () => {}}
        >
          {canManageCustomer ? 'Add Internal Notes' : ''}
        </CardButton>
      )}
    </CustomerDetailsCard>
  );
}
