import { Dialog, Link, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { CheckCircleBigIcon } from './assets/CheckCircleBigIcon';

interface SubscriptionSuccessDialogProps {
  open: boolean;
  onClose: () => void;
}

export function SubscriptionSuccessDialog({
  open,
  onClose,
}: SubscriptionSuccessDialogProps) {
  const platform = useResponsiveValue('mobile', 'other');
  const { data } = useSubscriptionDetails();

  return (
    <Dialog open={open} onClose={onClose} fullScreen={platform === 'mobile'}>
      <Box padding="large" maxWidth="480px">
        <Stack space="large" align="center">
          <CheckCircleBigIcon />
          <Stack space="xsmall" align="center">
            <Typography variant="h2" align="center">
              You subscription has been
              <br />
              updated!
            </Typography>
            <Stack space="medium">
              <Typography align="center">
                Your Shipper Express subscription will start on{' '}
                {formatDate(data?.chargebee.activated_at, {
                  variant: 'Date',
                })}
                .
              </Typography>
              <Typography align="center">
                Thank you for choosing Super Dispatch! If you have any questions
                please contact us at{' '}
                <Link color="primary" href="mailto:support@superdispatch.com">
                  support@superdispatch.com
                </Link>{' '}
                or click the chat icon in the bottom right corner.
              </Typography>
            </Stack>
          </Stack>

          <Button onClick={onClose}>Got it</Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
