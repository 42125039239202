import { Typography } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { formatCustomerType } from '@superdispatch/sdk';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { Divider } from 'shared/ui/Divider';
import { NoData } from './NoData';
import { OrderViewContactInfo } from './OrderViewContactInfo';
import { OrderViewNote } from './OrderViewNote';
import { OrderViewVenue } from './OrderViewVenue';

interface CustomerProps {
  order: Order;
}

export function OrderViewCustomerInfo({ order }: CustomerProps) {
  const {
    name,
    address,
    city,
    state,
    zip,
    phone,
    email,
    business_type: businessType,
    contact_name: contactName,
    counterparty_guid: counterpartyGuid,
    contact_phone: contactPhone,
    contact_mobile_phone: contactMobilePhone,
    contact_email: contactEmail,
    contact_title: contactTitle,
    notes,
  } = order.customer || {};

  const { user } = useUserState();

  return (
    <Flex flexDirection="column" aria-label="customer section">
      <Typography variant="h3">Customer</Typography>

      {name ||
      address ||
      city ||
      state ||
      zip ||
      contactName ||
      phone ||
      email ||
      notes ? (
        <>
          <OrderViewVenue
            mt={2}
            name={name}
            guid={counterpartyGuid}
            type="CUSTOMER"
            info={
              (businessType &&
                user?.order_form_settings.customer.is_business_type_visible &&
                formatCustomerType(businessType)) ||
              null
            }
            phone={phone}
            email={email}
          />

          <OrderViewContactInfo
            name={contactName}
            info={contactTitle}
            phone={contactPhone}
            mobilePhone={contactMobilePhone}
            email={contactEmail}
            ContainerProps={{ mt: 3, 'aria-label': 'customer contact section' }}
          />

          {notes && (
            <>
              <Divider my={3} />

              <Typography variant="body2" color="textSecondary">
                Notes for Customer
              </Typography>

              <OrderViewNote mt={2} aria-label="customer notes" notes={notes} />
            </>
          )}
        </>
      ) : (
        <NoData my={3} text="No customer data yet" />
      )}
    </Flex>
  );
}
