import { Add } from '@material-ui/icons';
import { CardButton, Stack } from '@superdispatch/ui';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { TerminalDetailsCard } from './TerminalDetailsCard';

const hint =
  'Instructions or notes that will be displayed to a carrier inside the order once the load offer is accepted.';

export interface Props {
  onEdit: () => void;
  terminal: TerminalDTO;
}

export function TerminalDetailsNotesCard({ terminal, onEdit }: Props) {
  const { notes } = terminal;

  return (
    <TerminalDetailsCard
      hint={hint}
      onEdit={onEdit}
      title={notes ? 'Notes for Carrier' : null}
    >
      {notes ? (
        <Stack space="none">{notes.split('\n')}</Stack>
      ) : (
        <CardButton
          size="large"
          hint={hint}
          startIcon={<Add />}
          onClick={onEdit}
        >
          Add Notes for Carrier
        </CardButton>
      )}
    </TerminalDetailsCard>
  );
}
