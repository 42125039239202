import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useOnboardingSettings } from 'shared/context/OnboardingContext';
import { useUserState } from './AppUserState';
import { useFeatureToggle } from './FeatureToggle';

export function useOnboardingState() {
  const { isAdmin, isSuperUser, user } = useUserState();
  const { data } = useOnboardingSettings();
  const { data: orderCounts } = useOrderCounts();

  const isOnboardingEnabled = useFeatureToggle('self-serve-onboarding.enabled');
  const isNewOnboardingEnabled = useFeatureToggle(
    'self-serve-onboarding-v2.enabled',
  );

  const isSelfServe = !!user?.shipper.is_self_serve && (isAdmin || isSuperUser);
  const isOnboardingAvailable =
    isSelfServe && isOnboardingEnabled && !user.shipper.is_onboarding_completed;

  const isNewOnboardingAvailable =
    isSelfServe &&
    isNewOnboardingEnabled &&
    (!data?.GET_VERIFIED || !orderCounts?.posted_to_lb);

  const showOnboarding = isOnboardingAvailable || isNewOnboardingAvailable;

  return { isOnboardingAvailable, isNewOnboardingAvailable, showOnboarding };
}
