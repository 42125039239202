import { plainToClass } from 'class-transformer';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { Contact } from 'shared/types/contact';
import { User } from 'shared/types/user';
import { DictionaryCarrierDTO } from './DictionaryDTO';

/**
 * @description Offer sent carriers list
 * */
export function useCarriers(name: string | undefined) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<DictionaryCarrierDTO>(
    ['carriers', 'offer_sent_carriers', { name }],
    () => {
      return requestPage(
        '/internal/carriers/offer_sent_carriers{?name}',
        (data) => plainToClass(DictionaryCarrierDTO, data),
        { name },
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!name,
    },
  );
}

export function useCustomers(name: string | undefined) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<Contact>(
    ['orders', 'customers', { name }],
    () => {
      return requestPage(
        '/internal/orders/customers{?name}',
        (data) => data as Contact,
        { name },
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!name,
    },
  );
}

export function useCustomerContacts(name: string | undefined) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<string>(
    ['orders', 'customer_contacts', { name }],
    () => {
      return requestPage(
        '/internal/orders/customer_contacts{?name}',
        (data) => String(data),
        { name },
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!name,
    },
  );
}

export function useInternalUsers() {
  const { requestPage } = useAPI();

  return useAPIPageQuery<User>(
    'users',
    () => {
      return requestPage('/internal/users?size=200', (data) => data as User);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
    },
  );
}
