import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { useMutation } from 'react-query';
import { SuperPayRepresentative } from 'shared/helpers/superpay/MoovHelpers';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { useMoovServiceContext } from 'superpay/MoovServiceContext';

interface Props {
  open: boolean;
  representative?: SuperPayRepresentative;
  onDeleteSuccess: () => void;
  onClose: () => void;
}

export function OwnerDeleteConfirmationForm({
  open,
  representative,
  onDeleteSuccess,
  onClose,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { moovService } = useMoovServiceContext();
  const { logPaymentInfo } = usePaymentLogger();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      if (!moovService || !representative) {
        return Promise.reject(
          new Error('Please try again or reload the page.'),
        );
      }

      return moovService.deleteRepresentative(representative.representativeID);
    },
    onSuccess: () => {
      addSnackbar('Representative deleted', { variant: 'success' });
      onDeleteSuccess();
      logPaymentInfo(
        'Deleted Representative',
        'DeleteRepresentativeConfirmation',
        {
          representativeId: representative?.representativeID,
        },
      );
      onClose();
    },
    onError: (error: Error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmDialog
      open={open}
      maxWidth="xs"
      onClose={isLoading ? undefined : onClose}
      title="Delete representative?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: isLoading,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: 'Delete',
        isLoading,
        onClick: () => mutate(),
        autoFocus: false,
      }}
    >
      {!!representative && (
        <Typography>
          {representative.name.firstName} {representative.name.lastName}
        </Typography>
      )}
    </ConfirmDialog>
  );
}
