import { useOrderCounts } from 'shared/api/OrderCountAPI';

export function usePaywallCounters() {
  const { data, isLoading } = useOrderCounts();

  const counters = {
    pendingLoadRequests: data?.pending,
    loadsPostedToLoadBoard: data?.posted_to_lb,
    deliveredVehicles: data?.delivered,
  };

  const hasSomeCounters = Object.values(counters).some(
    (value) => value !== undefined && value > 0,
  );

  return {
    isCountersLoading: isLoading,
    hasSomeCounters,
    counters,
  };
}
