import { plainToClass } from 'class-transformer';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { LinkedBrokerDTO } from '../dto/LinkedBrokerDTO';
import { useAPI } from './API';

export function usePartnerCarrier() {
  const { requestPage } = useAPI();

  return useAPIPageQuery<LinkedBrokerDTO>(['shippers', 'linked_brokers'], () =>
    requestPage('/internal/shippers/linked_brokers', (data) =>
      plainToClass(LinkedBrokerDTO, data),
    ),
  );
}
