import { EditBillingAddressDrawerContent } from './EditBillingAddressDrawerContent';
import { EditDrawer } from './EditDrawer';

interface BillingAddressEditDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function EditBillingAddressDrawer({
  open,
  onClose,
}: BillingAddressEditDrawerProps) {
  return (
    <EditDrawer open={open} onClose={onClose} title="Edit Billing Address">
      <EditBillingAddressDrawerContent onSubmitSuccess={onClose} />
    </EditDrawer>
  );
}
