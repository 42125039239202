import { Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Color, Inline, useUID } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';

const ProgressStepContainer = styled.div`
  flex: 1;
  min-height: 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 4px solid ${Color.Silver400};

  &[aria-current='step'] {
    border-bottom: 4px solid ${Color.Blue300};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
    align-items: flex-start;
    padding: 0 16px;
    min-height: 60px;

    &[aria-current='step'] {
      display: flex;
      border-bottom: none;
    }
  }
`;

interface ProgressStepProps {
  title: ReactNode;
  subtitle: string;
  isActive: boolean;
  isCompleted: boolean;
  onClick?: () => void;
}

export function ProgressStep({
  isActive,
  isCompleted,
  subtitle,
  title,
  onClick,
}: ProgressStepProps) {
  const uid = useUID();

  return (
    <ProgressStepContainer
      aria-labelledby={uid}
      aria-current={isActive ? 'step' : undefined}
      onClick={onClick}
    >
      <Inline verticalAlign="center" horizontalAlign="right">
        {isCompleted && <Check fontSize="small" color="primary" />}

        <Typography
          id={uid}
          align="center"
          variant="h6"
          color={isActive ? 'primary' : 'textSecondary'}
        >
          {title}
        </Typography>
      </Inline>

      <Typography
        align="center"
        variant={isActive ? 'body1' : 'body2'}
        color={isActive ? 'initial' : 'textSecondary'}
      >
        {subtitle}
      </Typography>
    </ProgressStepContainer>
  );
}

const ProgressStepsWrapper = styled.div`
  display: flex;
`;

const ProgressStepsContainer = styled.div`
  position: relative;
`;

const ProgressStepsLine = styled.div`
  display: none;
  height: 4px;
  width: 100%;
  background-color: ${Color.Silver400};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: flex;
  }
`;

const ProgressStepsBar = styled.div<{ progress?: number }>`
  width: ${({ progress }) => progress || 0}%;
  background-color: ${Color.Blue300};
`;

interface ProgressStepperProps {
  children: ReactNode;
  progress?: number;
}

export function ProgressStepper({ children, progress }: ProgressStepperProps) {
  return (
    <ProgressStepsContainer>
      <ProgressStepsWrapper>{children}</ProgressStepsWrapper>
      <ProgressStepsLine>
        <ProgressStepsBar progress={progress} />
      </ProgressStepsLine>
    </ProgressStepsContainer>
  );
}
