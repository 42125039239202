import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function BillingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M1.333 3.333h13.334v8.89H1.333v-8.89zM8 5.556A2.222 2.222 0 118 10a2.222 2.222 0 010-4.444zm-3.704-.741a1.481 1.481 0 01-1.481 1.481V9.26a1.481 1.481 0 011.481 1.482h7.408a1.481 1.481 0 011.481-1.482V6.296a1.481 1.481 0 01-1.481-1.481H4.296z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
