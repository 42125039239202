import { Box, CircularProgress } from '@material-ui/core';
import { lazy, Suspense } from 'react';
import type { MapboxDirectionsMapProps } from './MapboxDirectionsMap';

const MapboxDirectionsMapComponent = lazy<
  React.ComponentType<MapboxDirectionsMapProps>
>(() =>
  import('./MapboxDirectionsMap').then((module) => ({
    default: module.MapboxDirectionsMap,
  })),
);

export function MapboxDirectionsMapLazy(props: MapboxDirectionsMapProps) {
  return (
    <Suspense
      fallback={
        <div style={props.style} className={props.className}>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        </div>
      }
    >
      <MapboxDirectionsMapComponent {...props} />
    </Suspense>
  );
}
