import { CircularProgress, Typography } from '@material-ui/core';
import { GetApp, Info } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, FileDropZone, toBytes } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const Container = styled.div`
  height: 280px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export interface DropZoneProps {
  isUploading: boolean;
  isRejected: boolean;
  onDrop: (file: File) => void;
}

export function OrderImportDropZone({
  isUploading,
  isRejected,
  onDrop,
}: DropZoneProps) {
  return (
    <Box maxWidth="752px" width="100%" margin="large">
      <FileDropZone
        startIcon={null}
        maxFiles={1}
        accept=".csv"
        maxSize={toBytes(20, 'mb')}
        disabled={isUploading}
        hintText={null}
        onDropAccepted={(files) => {
          if (files[0]) onDrop(files[0]);
        }}
      >
        <Container>
          {isUploading ? (
            <CircularProgress />
          ) : isRejected ? (
            <Inline space="xsmall" verticalAlign="center">
              <Info color="error" />
              <Typography variant="h4" color="error">
                There was an error processing your file. Please try again.
              </Typography>
            </Inline>
          ) : (
            <Stack align="center" space="xxsmall">
              <Inline space="xsmall" verticalAlign="center">
                <GetApp />
                <Typography variant="h4">Import</Typography>
              </Inline>
              <Typography color="textSecondary">
                Drop or upload CSV file here.
              </Typography>
            </Stack>
          )}
        </Container>
      </FileDropZone>
    </Box>
  );
}
