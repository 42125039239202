import {
  DateFormat,
  NullableDateInput,
  stringifyDate,
} from '@superdispatch/dates';
import { Transform, TransformationType } from 'class-transformer';
import { isString } from 'lodash';

export function transformNil(transformationType: TransformationType) {
  return transformationType === TransformationType.CLASS_TO_PLAIN
    ? null
    : undefined;
}

export interface TransformStringOptions {
  nullable?: boolean;
}

export function transformString(
  value: unknown,
  { nullable }: TransformStringOptions,
  transformationType: TransformationType,
) {
  if (value == null) {
    if (nullable) {
      return transformNil(transformationType);
    }

    return '';
  }

  return String(value).trim();
}

export function TransformString(options: TransformStringOptions = {}) {
  return Transform((value, _, transformationType) =>
    transformString(value, options, transformationType),
  );
}

export function TransformDateString(format: DateFormat) {
  return Transform((input: NullableDateInput, _, transformationType) => {
    const value = stringifyDate(input, { format });

    return value ?? transformNil(transformationType);
  });
}

export interface TransformNumberOptions {
  max?: number;
  min?: number;
  integer?: boolean;
  defaultValue?: number;
}

export const transformNumber = (
  value: unknown,
  { max, min, integer, defaultValue }: TransformNumberOptions,
  transformationType: TransformationType,
): unknown => {
  if (value != null) {
    let number = parseFloat(value as string);

    if (Number.isFinite(number)) {
      if (integer) {
        number = Math.round(number);
      }

      if (max != null) {
        number = Math.min(max, number);
      }

      if (min != null) {
        number = Math.max(min, number);
      }

      return number;
    }
  }

  return defaultValue ?? transformNil(transformationType);
};

export function TransformNumber(options: TransformNumberOptions = {}) {
  return Transform((value: unknown, _, transformationType) =>
    transformNumber(value, options, transformationType),
  );
}

export function transformQueryArray(
  value: unknown,
  transformationType: TransformationType,
) {
  const values = Array.isArray(value)
    ? value
    : isString(value)
    ? value.trim().split(',')
    : [value];

  const clean = Array.from(new Set(values.filter(Boolean)));

  return transformationType !== TransformationType.CLASS_TO_PLAIN
    ? clean
    : clean.join(',') || undefined;
}

export function TransformQueryArray() {
  return Transform((value, _, transformationType) =>
    transformQueryArray(value, transformationType),
  );
}

export function TransformArray() {
  return Transform((raw) => {
    return Array.isArray(raw) ? raw : raw == null ? [] : [raw];
  });
}

export function transformEnum<T extends null | string>(
  value: T,
  allowedValues: readonly T[],
  transformationType: TransformationType,
) {
  if (allowedValues.length === 0) {
    throw new Error('[TransformEnum] "allowedValues" array can not be empty.');
  }

  return allowedValues.includes(value)
    ? value
    : transformNil(transformationType);
}

export function TransformEnum<T extends null | string>(
  allowedValues: readonly T[],
) {
  return Transform((raw, _, transformationType) => {
    return transformEnum<T>(raw, allowedValues, transformationType);
  });
}
