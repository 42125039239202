import { Link, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { RouteStatus } from '../../shared/types/routeStatus';
import emptyLoads from '../assets/empty_loads.png';

interface Props {
  status?: RouteStatus;
}

export function OrdersListPostedEmptyState({ status }: Props) {
  if (status?.startsWith('posted_to')) {
    return (
      <Box padding="large">
        <Stack align="center" space="medium">
          <img height="80px" src={emptyLoads} alt="" />

          <Stack align="center" space="xsmall">
            <Typography variant="h3">No posted orders yet</Typography>
            <Typography color="textSecondary">
              Create and post your next order.
            </Typography>
          </Stack>

          {status === 'posted_to_private_loadboard' && (
            <Link
              color="primary"
              rel="noreferrer"
              target="_blank"
              href="https://support.superdispatch.com/en/articles/9066625-private-network"
            >
              What is Private Loadboard?
            </Link>
          )}
        </Stack>
      </Box>
    );
  }

  return null;
}
