import { Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { ComponentType } from 'react';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { isEmpty } from '../data/ShipperProfileUtils';

export const isEmptyLoadID = (profile: ShipperProfileDTO) => {
  return isEmpty(profile, ['current_load_id', 'load_id_prefix']);
};

export const LoadIDView: ComponentType<ShipperProfileDTO> = ({
  current_load_id,
  load_id_prefix,
}) => {
  return (
    <Stack space="xxsmall">
      {load_id_prefix && (
        <DescriptionItem icon={<HashtagIcon />} label="Prefix">
          {load_id_prefix}
        </DescriptionItem>
      )}
      {current_load_id && (
        <DescriptionItem icon={<HashtagIcon />} label="Initial Number">
          {current_load_id}
        </DescriptionItem>
      )}
    </Stack>
  );
};
