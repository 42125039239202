import { ErrorItem } from './ErrorItem';
import { APIOrderErrorResponse } from './OrderErrorModal';

export const ErrorItems = ({ error }: { error: APIOrderErrorResponse }) => {
  const details = error.details ? Object.keys(error.details) : [];
  const { message } = error;

  return details.length > 0 ? (
    <>
      {details.map((item) => (
        <ErrorItem key={item} label={item} value={error.details[item]} />
      ))}
    </>
  ) : (
    <p>{message}</p>
  );
};
