import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Color, Column, Columns, SuperDispatchTheme } from '@superdispatch/ui';
import { ReactNode } from 'react';
import logo from 'shared/icons/logo-with-brand.svg';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    ${theme.breakpoints.up('sm')} {
      padding: 0 100px;
    }
  `,
);

export function BrokerHeader({ actions }: { actions: ReactNode }) {
  return (
    <AppBar>
      <Wrapper>
        <Toolbar>
          <Columns align="center">
            <Column>
              <a href="https://superdispatch.com">
                <img
                  src={logo}
                  width="216px"
                  alt="Super Dispatch Shipper TMS"
                />
              </a>
            </Column>

            <Column width="content">{actions}</Column>
          </Columns>
        </Toolbar>
      </Wrapper>
    </AppBar>
  );
}

const Footer = styled.footer(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    padding: 16px;
    color: ${Color.Grey300};

    ${theme.breakpoints.up('sm')} {
      padding: 16px 100px 32px;
    }
  `,
);

export function BrokerFooter() {
  return (
    <Footer>
      <Typography variant="h4">
        © {new Date().getFullYear()} Super Dispatch. All rights reserved.
      </Typography>
    </Footer>
  );
}
