import { HTTPError } from '@superdispatch/http';
import mitt from 'mitt';
import { useEffect, useState } from 'react';

const PaywallEmitter = mitt();
const PAYWALL_ERROR_EVENT = 'paywall-error';

export function usePaywallEventListener(handler: (error: unknown) => void) {
  useEffect(() => {
    PaywallEmitter.on(PAYWALL_ERROR_EVENT, handler);
    return () => {
      PaywallEmitter.off(PAYWALL_ERROR_EVENT, handler);
    };
  });
}

export function useHasPaywall() {
  const [hasPaywallError, setHasPaywallError] = useState(false);

  usePaywallEventListener(() => {
    setHasPaywallError(true);
  });

  return hasPaywallError;
}

export function isPaywallError(error: HTTPError) {
  return error.response.status === 402;
}

export function emitPaywallError(error: unknown): void {
  PaywallEmitter.emit(PAYWALL_ERROR_EVENT, error);
}
