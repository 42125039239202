import * as sdk from '@superdispatch/sdk';
import { CounterpartyContactDTO } from 'shared/dto/CounterpartyContactDTO';
import {
  lazyAndStripEmpty,
  yupBoolean,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, mixed } from 'yup';

export type CounterpartyDTO = InferType<typeof counterpartyDTO>;

export const counterpartyDTO = yupObject({
  id: lazyAndStripEmpty(),
  guid: lazyAndStripEmpty(),
  address: yupString().nullable(),
  custom_external_id: yupString().nullable(),
  name: yupString().ensure(),
  notes: yupString().nullable(),
  state: yupString().nullable(),
  zip: yupString().nullable(),
  city: yupString().nullable(),
  business_type: mixed<sdk.CustomerType>().transform((value) => value || null),
  contacts: mixed<CounterpartyContactDTO[]>().notRequired(),
  save_as_recent: yupBoolean().optional().strip(true),
  save_as_new: yupBoolean().optional().strip(true),
  save_as_new_contact: yupBoolean().optional().strip(true),
  selected_from_recent: yupBoolean().optional().strip(true),
});
