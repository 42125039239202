import { useDeepEqualMemo } from '@superdispatch/hooks';
import { classToPlain, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import { IStringifyOptions, parse, stringify } from 'qs';
import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

export type URLSearchQuery = Record<string, string | undefined>;
export type URLSearchQueryInit = Record<string, unknown>;

export function parseSearchQuery(search: string): URLSearchQuery {
  return parse(search, {
    ignoreQueryPrefix: true,
  }) as URLSearchQuery;
}

export function stringifySearchQuery(
  query: URLSearchQueryInit,
  options?: IStringifyOptions,
): string {
  return stringify(query, {
    skipNulls: true,
    sort: (a: string, b: string) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
    ...options,
  });
}

export function updateSearchQuery(
  search: string,
  updater: (query: URLSearchQuery) => URLSearchQueryInit,
): string {
  const query = parseSearchQuery(search);
  const updated = updater(query);

  return stringifySearchQuery(updated);
}

export type QueryUpdater<TValue, TPartial = Partial<TValue>> = (
  updater: TPartial | ((prevState: TValue) => TPartial),
  replace?: true,
) => void;

export function useQuery(
  defaultValues?: URLSearchQueryInit,
): [URLSearchQuery, QueryUpdater<URLSearchQuery, URLSearchQueryInit>] {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useDeepEqualMemo(() => {
    const queryParams = parseSearchQuery(search);
    return { ...defaultValues, ...queryParams };
  }, [defaultValues, search]);

  const setQuery = useCallback<
    QueryUpdater<URLSearchQuery, URLSearchQueryInit>
  >(
    (partialOrFactory, replace) => {
      const currentSearch = search;
      const nextSearch = updateSearchQuery(currentSearch, (prevQuery) => {
        const nextQuery =
          typeof partialOrFactory === 'function'
            ? partialOrFactory(prevQuery)
            : partialOrFactory;

        return { ...prevQuery, ...nextQuery };
      });

      if (nextSearch !== currentSearch) {
        if (replace) {
          navigate({ search: nextSearch }, { replace: true });
        } else {
          navigate({ search: nextSearch });
        }
      }
    },
    [navigate, search],
  );

  return [query as URLSearchQuery, setQuery];
}

export function useQueryParams<T>(
  cls: ClassType<T>,
  defaultValues?: Partial<T>,
): [T, QueryUpdater<T>] {
  const [query, setQuery] = useQuery();
  const params = useDeepEqualMemo(
    () => plainToClass(cls, { ...defaultValues, ...query }),
    [cls, query, defaultValues],
  );

  const setParams = useCallback<QueryUpdater<T>>(
    (plainOrFactory, replace) => {
      setQuery((prevQuery) => {
        const prevParams = plainToClass(cls, prevQuery);
        const plainPrevParams = classToPlain(prevParams);
        const plainNextParams =
          typeof plainOrFactory === 'function'
            ? plainOrFactory(prevParams)
            : plainOrFactory;
        const nextParams = plainToClass(cls, {
          ...plainPrevParams,
          ...plainNextParams,
        });

        return classToPlain(nextParams) as URLSearchQueryInit;
      }, replace);
    },
    [cls, setQuery],
  );

  return [params, setParams];
}

export function useTryBack(): {
  tryBack: (fallBackUrl: To) => void;
} {
  const navigate = useNavigate();
  return {
    tryBack: (fallBackUrl: To) =>
      window.history.length > 2
        ? navigate(-1)
        : navigate(fallBackUrl, { replace: true }),
  };
}

export function parseRedirectUrlQuery(redirectUrl: string) {
  const redirectSearchQuery = new URL(redirectUrl, window.location.origin)
    .search;
  return parseSearchQuery(redirectSearchQuery);
}
