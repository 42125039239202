import { Link } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useState } from 'react';
import { FormikFileField } from '../../shared/form/FormikFileField';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';
import { DealerLicenseHelpDrawer } from './DealerLicenseHelpDrawer';
import { W9HelpDrawer } from './W9HelpDrawer';

export function IdentificationForm() {
  const { data } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const [currentDrawer, setCurrentDrawer] = useState<
    'w9_help' | 'dealer_license_help' | undefined
  >();

  return (
    <Stack space="small">
      <W9HelpDrawer
        open={currentDrawer === 'w9_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      <DealerLicenseHelpDrawer
        open={currentDrawer === 'dealer_license_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      <FormikFileField
        label="Upload W-9"
        name="w9_certificate_url"
        helperText={
          <Link
            color="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Learn More',
                utm_content: 'W-9',
              });
              setCurrentDrawer('w9_help');
            }}
          >
            Learn more
          </Link>
        }
      />
      {data?.company_type === 'DEALER' && (
        <FormikFileField
          label="Dealer License"
          name="dealer_license_url"
          helperText={
            <Link
              color="primary"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                trackVerificationApplicationEvent({
                  name: 'Shipper Clicked on Learn More',
                  utm_content: 'Dealer License',
                });
                setCurrentDrawer('dealer_license_help');
              }}
            >
              Learn more
            </Link>
          }
        />
      )}
      {data?.company_type === 'BROKER' && (
        <FormikTextField name="usdot" label="USDOT" fullWidth={true} />
      )}
    </Stack>
  );
}
