import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIPageResponse, useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { NotificationDTO } from 'shared/data/notifications/NotificationDTO';
import { APIQueryDataUpdater, setAPIQueryData } from '../../api/APIQuery';

type NotificationResult = APIPageResponse<NotificationDTO>['data'];

export function useUnreadNotificationsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const setQueryData = (data: APIQueryDataUpdater<NotificationResult>) => {
      setAPIQueryData<NotificationResult>(
        queryClient,
        ['notifications', 'unread'],
        data,
      );
    };

    return { setQueryData };
  }, [queryClient]);
}

export function useUnreadNotifications() {
  const { requestPage } = useAPI();

  return useAPIPageQuery(['notifications', 'unread'], () =>
    requestPage('/internal/notifications/list/unread', (data) =>
      plainToClass(NotificationDTO, data),
    ),
  );
}

export function useNotificationAPI() {
  const { request } = useAPI();
  return useMemo(
    () => ({
      subscribeToPushNotification: (current_token: string) =>
        request('POST /internal/push/subscribe', {
          json: { current_token },
        }),
      resubscribeToPushNotification: (
        current_token: string,
        new_token: string | null,
      ) =>
        request('POST /internal/push/resubscribe', {
          json: {
            current_token,
            new_token,
          },
        }),
      unsubscribeFromPushNotification: (current_token: string) =>
        request('POST /internal/push/unsubscribe', {
          json: {
            current_token,
          },
        }),
      disablePushNotification: (current_token: string) =>
        request('POST /internal/push/disable', {
          json: {
            current_token,
          },
        }),
    }),
    [request],
  );
}

export function useMarkAsReadMutation(options: APIMutationOptions<string>) {
  const { request } = useAPI();
  return useAPIMutation(
    (guid: string) =>
      request('PUT /internal/notifications/{guid}/markAsRead', { guid }),
    options,
  );
}

export function useMarkAsAllReadMutation(options: APIMutationOptions) {
  const { request } = useAPI();
  return useAPIMutation(
    () => request('PUT /internal/notifications/markAllAsRead'),
    options,
  );
}
