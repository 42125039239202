import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useAPI } from 'shared/api/API';
import { useAPIMutation } from 'shared/api/APIMutation';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { DecodedOrderVehicleDTO } from './DecodedOrderVehicleDTO';
import { VehicleMake, VehicleModel } from './OrderFormVehicleDTO';

export function useVehicleAPI() {
  const { requestResource } = useAPI();

  return useMemo(
    () => ({
      decodeVin: (vin: string) =>
        requestResource(
          '/internal/vins{?vin}',
          (data) => plainToClass(DecodedOrderVehicleDTO, data),
          { vin },
        ).catch(() => {
          // api may return empty response instead of json response
          return undefined;
        }),
      bulkDecode: (vinNumbers: string[]) =>
        requestResource(
          'POST /internal/vins',
          (data) => (data as { decoded: DecodedOrderVehicleDTO[] }).decoded,
          { json: vinNumbers },
        ),
    }),
    [requestResource],
  );
}

export const useVehicleMakers = (name: string, enabled: boolean) => {
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['carInfo', 'maker', { name }],
    () =>
      requestPage(
        '/internal/carInfo/maker{?name}',
        (data) => data as VehicleMake,
        { name },
      ),
    {
      enabled,
    },
  );
};

export const useVehicleModels = (
  name: string,
  maker: string,
  year: string | undefined,
  enabled: boolean,
) => {
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['carInfo', 'model', { name, maker, year }],
    () =>
      requestPage(
        '/internal/carInfo/model{?name,maker,year}',
        (data) => data as VehicleModel,
        { name, maker, year },
      ),
    {
      enabled,
    },
  );
};

export function useDecodeVinMutation(
  onSuccess: (value: DecodedOrderVehicleDTO | undefined) => void,
) {
  const { decodeVin } = useVehicleAPI();
  return useAPIMutation(decodeVin, { onSuccess });
}
