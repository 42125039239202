import { formatRelativeTime, parseDate } from '@superdispatch/dates';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import Order from 'shared/types/order';
import { Step } from 'shared/types/step';

const activeOrderPriority: Array<Order['status']> = [
  'new',
  'pending',
  'declined',
  'canceled',
  'accepted',
  'picked_up',
];

function isDelayedStatus(
  targetStatus: Order['status'],
  currentStatus: Order['status'],
) {
  return (
    activeOrderPriority.includes(currentStatus) &&
    activeOrderPriority.indexOf(currentStatus) <=
      activeOrderPriority.indexOf(targetStatus)
  );
}

function isDelayRestricted(order: Order) {
  return !order.is_active || order.is_archived;
}

export function isOrderStepDelayed(step: Step | undefined): boolean {
  if (!step?.scheduled_at) {
    return false;
  }
  const scheduledDate = parseDate(step.scheduled_at, { format: 'JodaISO' });
  const compareDate =
    step.adjusted_date || step.completed_at
      ? parseDate(step.adjusted_date || step.completed_at, {
          format: 'JodaISO',
        })
      : DateTime.local();

  return compareDate.diff(scheduledDate, 'hours').hours >= 24;
}

export function useMightBeLateDays(order: Order): number {
  return useMemo(() => {
    let mightBeLatedays = 0;
    const scheduledPickupDate = parseDate(order.pickup?.scheduled_at, {
      format: 'JodaISO',
    });
    const scheduledDeliveryDate = parseDate(order.delivery?.scheduled_at, {
      format: 'JodaISO',
    });

    const comparePickupDate = order.eta_information?.pickup_eta
      ? parseDate(order.eta_information.pickup_eta.date_from, {
          format: 'JodaISO',
        })
      : null;

    const deliveryETADate = order.eta_information?.delivery_eta?.date_from;

    const compareDeliveryDate = deliveryETADate
      ? parseDate(deliveryETADate, {
          format: 'JodaISO',
        })
      : null;

    const now = DateTime.local();

    const hasPickupDates =
      comparePickupDate && scheduledPickupDate && comparePickupDate > now;
    const hasDeliveryDates =
      compareDeliveryDate &&
      scheduledDeliveryDate &&
      scheduledDeliveryDate > now &&
      compareDeliveryDate > now;

    if (hasPickupDates && comparePickupDate > scheduledPickupDate) {
      mightBeLatedays = comparePickupDate.diff(
        scheduledPickupDate,
        'days',
      ).days;
    }

    if (hasDeliveryDates && compareDeliveryDate > scheduledDeliveryDate) {
      mightBeLatedays = compareDeliveryDate.diff(
        scheduledDeliveryDate,
        'day',
      ).days;
    }

    return Math.trunc(mightBeLatedays);
  }, [order]);
}

export function isOrderAgeDelayed(order: Order): boolean {
  const { created_at, status } = order;
  const now = DateTime.local();
  const createDate = parseDate(created_at, { format: 'JodaISO' });
  const activeStatus: Array<Order['status']> = [
    'new',
    'pending',
    'declined',
    'canceled',
    'order_canceled',
  ];

  return (
    !isDelayRestricted(order) &&
    activeStatus.includes(status) &&
    now.diff(createDate, 'days').days >= 5
  );
}

export function isOrderDelayed(order: Order): boolean {
  const pickup = isOrderStepDelayed(order.pickup);
  const delivery = isOrderStepDelayed(order.delivery);

  return isDelayRestricted(order)
    ? false
    : (isDelayedStatus('accepted', order.status) && pickup) ||
        (isDelayedStatus('picked_up', order.status) && delivery);
}

export function getOrderStepRelativeTime(step?: Step) {
  return formatRelativeTime(
    step?.scheduled_at_by_carrier || step?.scheduled_at,
    {
      base: step?.adjusted_date || step?.completed_at || DateTime.local(),
    },
    { format: 'JodaISO' },
  );
}

export function abbreviateRelativeTimeText(relativeTime: string) {
  return relativeTime
    .replace('ago', '')
    .replace(/(\d+) hours?/, '$1h')
    .replace(/(\d+) minutes?/, '$1m')
    .replace(/(\d+) days?/, '$1d')
    .replace(/(\d+) monthe?s?/, '$1mo.')
    .replace(/(\d+) years?/, '$1yr.');
}
