import { Link, Paper, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Button, Color, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useGeneralSettingsAPI } from 'general-settings/data/GeneralSettingsAPI';
import { WorkOSPortalLinkDTO } from 'general-settings/data/GeneralSettingsDTO';
import { ReactNode } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import backgroundCircles from 'shared/icons/bg-circles.svg';
import styled from 'styled-components';
import { loginSSOFormSchema } from './data/LoginFormDTO';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Content = styled.div`
  width: 434px;
  max-width: 434px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -42px;
    left: -64px;
    right: -64px;
    height: 140px;
    background: url(${backgroundCircles});
    background-size: 20px 20px;
  }
`;

const StyledSubtitle = styled(Typography)`
  color: ${Color.Black};
`;

interface LoginFormProps {
  title: string;
  waiver: ReactNode;
  onBackClick: () => void;
}

export function LoginFormSSO({ title, waiver, onBackClick }: LoginFormProps) {
  const { getAuthLink } = useGeneralSettingsAPI();
  const { addSnackbar } = useSnackbarStack();

  const form = useAppFormik({
    validationSchema: loginSSOFormSchema,
    onSubmit: (values) => getAuthLink(values.email),
    onSubmitSuccess(response: WorkOSPortalLinkDTO) {
      window.location.replace(response.url);
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
  });

  return (
    <Wrapper>
      <Content>
        <Stack space="small">
          <Paper>
            <FormikProvider value={form}>
              <Form autoComplete="off">
                <Box padding="medium">
                  <Stack space="large">
                    <Stack space="large">
                      <Stack align="center" space="small">
                        <Typography variant="h1" align="center">
                          {title}
                        </Typography>

                        <StyledSubtitle align="center">
                          {`Single Sign-On allows you to login to Super Dispatch via your company's Identity Provider.${' '}`}
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.superdispatch.com/en/articles/6221496-signing-on-with-single-sign-on-sso"
                          >
                            Learn More
                          </Link>
                        </StyledSubtitle>
                      </Stack>

                      <FormikTextField
                        fullWidth={true}
                        autoFocus={true}
                        name="email"
                        label="Work Email"
                        autoComplete="off"
                      />
                    </Stack>

                    <Stack space="small">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        isLoading={form.isSubmitting}
                      >
                        Log In
                      </Button>

                      <Button
                        size="large"
                        fullWidth={true}
                        onClick={onBackClick}
                      >
                        Back
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Form>
            </FormikProvider>
          </Paper>

          <Box paddingRight="large" paddingLeft="large">
            {waiver}
          </Box>
        </Stack>
      </Content>
    </Wrapper>
  );
}
