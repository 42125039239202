import {
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, ErrorOutlined, Mail } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  FormikCheckboxField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import {
  Button,
  CheckboxField,
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { FieldArray } from 'formik';
import { StyledAlert } from 'notification-settings/NotificationSettingsPage';
import { useState } from 'react';
import { useCanExecute } from 'shared/data/UserPermissions';
import { InfoIcon } from 'shared/icons/InfoIcon';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import {
  composeValidators,
  email,
  required,
} from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import {
  AdditionalEmailPreferencesDTO,
  NotificationSettingsDTO,
} from '../data/NotificationSettingsDTO';

const Divider = styled.div`
  border-top: 1px solid ${Color.Silver400};
  margin: 16px 0;
`;

const StyledTableCellHead = styled(TableCell)`
  padding: 8px;
`;

const StyledTableCell = styled(TableCell)`
  padding: 12px 8px;
`;

const AlignedTableHead = styled.div`
  display: flex;
  align-items: center;
  white-space: normal;
`;

const StyledStack = styled.div`
  min-height: 72px;
`;

export function CompanyNotifications({
  notificationSettings,
  subscribeEmail,
  handleSubmit,
  values,
  setSmsNotificationPhoneNumber,
  storeLastSmsPhoneNumber,
  lastSmsPhoneNumber,
}: {
  notificationSettings?: NotificationSettingsDTO;
  subscribeEmail: (email: string) => void;
  values?: NotificationSettingsDTO;
  handleSubmit: () => void;
  setSmsNotificationPhoneNumber: (value: string | null) => void;
  storeLastSmsPhoneNumber: (value: string | null) => void;
  lastSmsPhoneNumber: string | null;
}) {
  const { addSnackbar } = useSnackbarStack();
  const canUpdateCompanyProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [indexOfEmailToRemove, setIndexOfEmailToRemove] = useState(-1);
  const [smsChecked, setSmsChecked] = useState(
    Boolean(notificationSettings?.sms_notification_phone_number),
  );

  const showNoPermissionsSnackbar = () => {
    if (!canUpdateCompanyProfile) {
      addSnackbar(
        <Stack space="none">
          <Typography>No edit permission.</Typography>
          <Typography>Request manager to update settings.</Typography>
        </Stack>,
      );
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Typography variant="h3">Company Notifications</Typography>

          <Stack space="large">
            <Stack>
              <Typography color="textSecondary">Updates sent to:</Typography>

              <Columns space="xsmall" collapseBelow="tablet">
                <Column width="content">
                  <DescriptionItem icon={<Mail />}>
                    <Typography component="span" color="textPrimary">
                      {notificationSettings?.company_email || 'No email'}
                    </Typography>
                  </DescriptionItem>
                </Column>
                <Column>
                  <Inline>
                    <Typography color="textSecondary">
                      Change email at
                      <Typography component="span" color="textPrimary">
                        {' '}
                        <Link href="/profile/contact-info/edit">
                          Profile Page
                        </Link>
                        .
                      </Typography>
                    </Typography>
                  </Inline>
                </Column>
              </Columns>
            </Stack>

            <Stack space="large">
              <Stack space="xxsmall">
                <Typography variant="h4">
                  Updates on all orders across your Shipper TMS
                </Typography>

                <Columns space="large" collapseBelow="desktop">
                  <Column width={['fluid', '3/4']}>
                    <Typography
                      color="textSecondary"
                      style={{ maxWidth: '423px' }}
                    >
                      Load offers, carrier requests, offer cancellation,
                      bookings, pickup/delivery updates and modifications, as
                      well as customer invoices and BOLs.
                    </Typography>
                  </Column>
                  <Column width={['fluid', '1/4']}>
                    <div onClick={showNoPermissionsSnackbar}>
                      <FormikCheckboxField
                        label="Email"
                        name="is_company_email_notifications_enabled"
                        disabled={
                          !canUpdateCompanyProfile ||
                          notificationSettings?.is_company_email_unsubscribed
                        }
                      />
                    </div>
                  </Column>
                </Columns>
              </Stack>

              <Columns
                space="large"
                collapseBelow="desktop"
                align="top"
                aria-label="order request updates"
              >
                <Column width={['fluid', '3/4']}>
                  <Stack space="xxsmall">
                    <Typography variant="h4">Requests</Typography>
                    <Typography
                      color="textSecondary"
                      style={{ maxWidth: '423px' }}
                    >
                      Carrier requests on orders posted to Super Loadboard.
                    </Typography>
                  </Stack>
                </Column>
                <Column width={['fluid', '1/4']}>
                  <StyledStack>
                    <Stack>
                      <div onClick={showNoPermissionsSnackbar}>
                        <CheckboxField
                          label="SMS"
                          checked={smsChecked}
                          onChange={(_, checked) => {
                            if (!checked) {
                              storeLastSmsPhoneNumber(
                                values?.sms_notification_phone_number || null,
                              );
                              setSmsNotificationPhoneNumber(null);
                            } else {
                              setSmsNotificationPhoneNumber(lastSmsPhoneNumber);
                            }

                            setSmsChecked(checked);
                          }}
                          disabled={!canUpdateCompanyProfile}
                        />
                      </div>
                      {smsChecked && (
                        <SuspendedFormikPhoneField
                          name="sms_notification_phone_number"
                          fullWidth={true}
                          disabled={!canUpdateCompanyProfile}
                          validate={required}
                        />
                      )}
                    </Stack>
                  </StyledStack>
                </Column>
              </Columns>

              {notificationSettings?.is_company_email_unsubscribed && (
                <StyledAlert severity="error" icon={<ErrorOutlined />}>
                  <Inline verticalAlign="center" space="small">
                    <Typography>Email has been unsubscribed.</Typography>
                    <Button
                      size="small"
                      style={{ background: Color.White }}
                      onClick={() => {
                        if (notificationSettings.company_email) {
                          subscribeEmail(notificationSettings.company_email);
                        }
                      }}
                    >
                      Subscribe
                    </Button>
                  </Inline>
                </StyledAlert>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>

      <Divider />

      <CardContent>
        <FieldArray name="additional_email_preferences">
          {(arrayHelpers) => {
            return values ? (
              <Stack>
                <Stack space="xxsmall">
                  <Typography variant="h4">Additional Recipients</Typography>

                  <Typography color="textSecondary">
                    Add new recipients for company notifications.
                  </Typography>

                  {platform === 'desktop' ? (
                    <TableContainer>
                      {values.additional_email_preferences &&
                        values.additional_email_preferences.length > 0 && (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCellHead>
                                  <Typography
                                    variant="h5"
                                    style={{ color: Color.Grey300 }}
                                  >
                                    #
                                  </Typography>
                                </StyledTableCellHead>
                                <StyledTableCellHead>
                                  <Typography
                                    variant="h5"
                                    style={{ color: Color.Grey300 }}
                                  >
                                    Email
                                  </Typography>
                                </StyledTableCellHead>
                                <StyledTableCellHead style={{ width: 140 }}>
                                  <Inline verticalAlign="center">
                                    <Typography
                                      variant="h5"
                                      style={{ color: Color.Grey300 }}
                                    >
                                      Load Offers
                                    </Typography>
                                    <Tooltip
                                      title="Accepted and Declined Load Offers"
                                      placement="top"
                                    >
                                      <InfoIcon fontSize="small" />
                                    </Tooltip>
                                  </Inline>
                                </StyledTableCellHead>
                                <StyledTableCellHead style={{ width: 120 }}>
                                  <Inline verticalAlign="center">
                                    <Typography
                                      variant="h5"
                                      style={{ color: Color.Grey300 }}
                                    >
                                      Requests
                                    </Typography>
                                    <Tooltip
                                      title="Carrier requests and bookings"
                                      placement="top"
                                    >
                                      <InfoIcon fontSize="small" />
                                    </Tooltip>
                                  </Inline>
                                </StyledTableCellHead>
                                <StyledTableCellHead style={{ width: 160 }}>
                                  <AlignedTableHead>
                                    <Typography
                                      variant="h5"
                                      align="center"
                                      style={{ color: Color.Grey300 }}
                                    >
                                      Pickup/Delivery and Modifications
                                    </Typography>
                                    <Tooltip
                                      title="Pickup, Delivery and order changes by carriers"
                                      placement="top"
                                    >
                                      <InfoIcon fontSize="small" />
                                    </Tooltip>
                                  </AlignedTableHead>
                                </StyledTableCellHead>
                                <StyledTableCellHead style={{ width: 100 }}>
                                  <Inline verticalAlign="center">
                                    <Typography
                                      variant="h5"
                                      style={{ color: Color.Grey300 }}
                                    >
                                      Billing
                                    </Typography>
                                    <Tooltip
                                      title="Customer Invoice and BOL"
                                      placement="top"
                                    >
                                      <InfoIcon fontSize="small" />
                                    </Tooltip>
                                  </Inline>
                                </StyledTableCellHead>
                                <StyledTableCellHead style={{ width: 40 }} />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.additional_email_preferences
                                ? values.additional_email_preferences.map(
                                    (item, index) => (
                                      <TableRow key={index}>
                                        <StyledTableCell>
                                          {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          <Columns
                                            align="center"
                                            space="xsmall"
                                          >
                                            <Column>
                                              <FormikTextField
                                                aria-label={
                                                  item.email || undefined
                                                }
                                                name={`additional_email_preferences[${index}].email`}
                                                fullWidth={true}
                                                disabled={
                                                  !canUpdateCompanyProfile ||
                                                  item.is_unsubscribed
                                                }
                                                validate={composeValidators(
                                                  required,
                                                  email,
                                                )}
                                                required={true}
                                                formatError={() =>
                                                  'Invalid email address.'
                                                }
                                              />
                                            </Column>

                                            {item.is_unsubscribed && (
                                              <Column width="content">
                                                <Inline verticalAlign="center">
                                                  <Tooltip
                                                    title="Email has been unsubscribed. Click Subcribe to start receiving email notifications again."
                                                    placement="top"
                                                  >
                                                    <ErrorOutlined
                                                      fontSize="small"
                                                      style={{
                                                        color: Color.Red500,
                                                      }}
                                                    />
                                                  </Tooltip>
                                                  <Button
                                                    size="small"
                                                    onClick={() => {
                                                      if (item.email) {
                                                        subscribeEmail(
                                                          item.email,
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    Subscribe
                                                  </Button>
                                                </Inline>
                                              </Column>
                                            )}
                                          </Columns>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div
                                            onClick={showNoPermissionsSnackbar}
                                          >
                                            <FormikCheckboxField
                                              label=""
                                              disabled={
                                                !canUpdateCompanyProfile ||
                                                item.is_unsubscribed
                                              }
                                              name={`additional_email_preferences[${index}].is_load_offers_enabled`}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div
                                            onClick={showNoPermissionsSnackbar}
                                          >
                                            <FormikCheckboxField
                                              label=""
                                              disabled={
                                                !canUpdateCompanyProfile ||
                                                item.is_unsubscribed
                                              }
                                              name={`additional_email_preferences[${index}].is_load_requests_enabled`}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div
                                            onClick={showNoPermissionsSnackbar}
                                          >
                                            <FormikCheckboxField
                                              label=""
                                              disabled={
                                                !canUpdateCompanyProfile ||
                                                item.is_unsubscribed
                                              }
                                              name={`additional_email_preferences[${index}].is_status_and_modifications_enabled`}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div
                                            onClick={showNoPermissionsSnackbar}
                                          >
                                            <FormikCheckboxField
                                              label=""
                                              disabled={
                                                !canUpdateCompanyProfile ||
                                                item.is_unsubscribed
                                              }
                                              name={`additional_email_preferences[${index}].is_billing_enabled`}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <div
                                            onClick={showNoPermissionsSnackbar}
                                          >
                                            <IconButton
                                              size="small"
                                              aria-label="remove vehicle"
                                              disabled={
                                                !canUpdateCompanyProfile ||
                                                item.is_unsubscribed
                                              }
                                              onClick={() => {
                                                setIndexOfEmailToRemove(index);
                                                setShowConfirmation(true);
                                              }}
                                            >
                                              <DeleteIcon color="action" />
                                            </IconButton>
                                          </div>
                                        </StyledTableCell>
                                      </TableRow>
                                    ),
                                  )
                                : null}
                            </TableBody>
                          </Table>
                        )}
                    </TableContainer>
                  ) : (
                    <Box mt={4}>
                      <Stack space="large">
                        {values.additional_email_preferences
                          ? values.additional_email_preferences.map(
                              (item, index) => (
                                <Stack space="large" key={index}>
                                  <Stack space="xxsmall">
                                    <label htmlFor={`email${index}`}>
                                      <Typography>Email</Typography>
                                    </label>
                                    <Columns align="center" space="small">
                                      <Column width="fluid">
                                        <FormikTextField
                                          aria-label={item.email || undefined}
                                          id={`email${index}`}
                                          name={`additional_email_preferences[${index}].email`}
                                          disabled={
                                            !canUpdateCompanyProfile ||
                                            item.is_unsubscribed
                                          }
                                          fullWidth={true}
                                          validate={composeValidators(
                                            required,
                                            email,
                                          )}
                                          formatError={() =>
                                            'Invalid email address.'
                                          }
                                        />
                                      </Column>
                                      <Column width="content">
                                        <div
                                          onClick={showNoPermissionsSnackbar}
                                        >
                                          <IconButton
                                            size="small"
                                            aria-label="remove vehicle"
                                            disabled={
                                              !canUpdateCompanyProfile ||
                                              item.is_unsubscribed
                                            }
                                            onClick={() => {
                                              setIndexOfEmailToRemove(index);
                                              setShowConfirmation(true);
                                            }}
                                          >
                                            <DeleteIcon color="action" />
                                          </IconButton>
                                        </div>
                                      </Column>
                                    </Columns>
                                  </Stack>
                                  <div onClick={showNoPermissionsSnackbar}>
                                    <FormikCheckboxField
                                      label="Load Offers"
                                      disabled={
                                        !canUpdateCompanyProfile ||
                                        item.is_unsubscribed
                                      }
                                      name={`additional_email_preferences[${index}].is_load_offers_enabled`}
                                    />
                                  </div>
                                  <Stack space="none">
                                    <div onClick={showNoPermissionsSnackbar}>
                                      <FormikCheckboxField
                                        label="Requests"
                                        disabled={
                                          !canUpdateCompanyProfile ||
                                          item.is_unsubscribed
                                        }
                                        name={`additional_email_preferences[${index}].is_load_requests_enabled`}
                                      />
                                    </div>
                                    <Typography
                                      color="textSecondary"
                                      style={{ paddingLeft: 40 }}
                                    >
                                      Carrier requests and bookings
                                    </Typography>
                                  </Stack>
                                  <div onClick={showNoPermissionsSnackbar}>
                                    <FormikCheckboxField
                                      label="Pickup/Delivery and Modifications"
                                      disabled={
                                        !canUpdateCompanyProfile ||
                                        item.is_unsubscribed
                                      }
                                      name={`additional_email_preferences[${index}].is_status_and_modifications_enabled`}
                                    />
                                  </div>
                                  <Stack space="none">
                                    <div onClick={showNoPermissionsSnackbar}>
                                      <FormikCheckboxField
                                        label="Billing"
                                        disabled={
                                          !canUpdateCompanyProfile ||
                                          item.is_unsubscribed
                                        }
                                        name={`additional_email_preferences[${index}].is_billing_enabled`}
                                      />
                                    </div>
                                    <Typography
                                      color="textSecondary"
                                      style={{ paddingLeft: 40 }}
                                    >
                                      Customer Invoice and BOL
                                    </Typography>
                                  </Stack>

                                  {item.is_unsubscribed && (
                                    <StyledAlert
                                      severity="error"
                                      icon={<ErrorOutlined />}
                                    >
                                      <Inline
                                        verticalAlign="center"
                                        space="small"
                                      >
                                        <Typography>
                                          Email has been unsubscribed.
                                        </Typography>
                                        <Button
                                          size="small"
                                          style={{
                                            background: Color.White,
                                          }}
                                          onClick={() => {
                                            if (item.email) {
                                              subscribeEmail(item.email);
                                            }
                                          }}
                                        >
                                          Subscribe
                                        </Button>
                                      </Inline>
                                    </StyledAlert>
                                  )}
                                  <Divider style={{ margin: '12px 0 0 0' }} />
                                </Stack>
                              ),
                            )
                          : null}
                      </Stack>
                    </Box>
                  )}
                </Stack>

                <ConfirmDialog
                  open={showConfirmation}
                  onClose={() => {
                    setShowConfirmation(false);
                  }}
                  title="Delete Recipient?"
                  cancelButtonProps={{ children: 'Cancel' }}
                  confirmButtonProps={{
                    onClick: () => {
                      setShowConfirmation(false);
                      setIndexOfEmailToRemove(-1);
                      arrayHelpers.remove(indexOfEmailToRemove);
                      handleSubmit();
                    },
                    children: 'Delete',
                    color: 'error',
                  }}
                />

                <Button
                  aria-label="add recipient"
                  variant="text"
                  size="small"
                  disabled={!canUpdateCompanyProfile}
                  onClick={() => {
                    arrayHelpers.push(
                      AdditionalEmailPreferencesDTO.create({
                        email: '',
                        is_load_offers_enabled: false,
                        is_load_requests_enabled: false,
                        is_status_and_modifications_enabled: false,
                        is_billing_enabled: false,
                        is_unsubscribed: false,
                      }),
                    );
                  }}
                >
                  <Inline verticalAlign="center">
                    <Add fontSize="small" />
                    Add Recipient
                  </Inline>
                </Button>
              </Stack>
            ) : null;
          }}
        </FieldArray>
      </CardContent>
    </Card>
  );
}
