import {
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { formatDate, FormattedDate } from '@superdispatch/dates';
import {
  FormikCheckboxField,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import {
  ATTACHMENT_ACCEPT_TYPES,
  ATTACHMENT_ALLOWED_MIME_TYPES,
  ATTACHMENT_MAX_FILE_SIZE,
} from 'shared/config/AttachmentConstants';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { FormikAttachmentList } from 'shared/form/FormikAttachmentList';
import { FormikFileField } from 'shared/form/FormikFileField';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import {
  validateFile,
  validateFileList,
} from 'shared/helpers/ValidationHelpers';
import {
  CarrierBrokerPreferencesEdit,
  CarrierFullInfo,
  CarrierStatus,
} from 'shared/types/carrier';
import { ExpeditePaymentWarning } from '../list/ExpeditePaymentWarning';
import { PrivateNetworkStatusChangeWarningBox } from '../list/PrivateNetworkStatusChangeWarningBox';
import { PrivateNetworkCheckboxField } from './PrivateNetworkCheckboxField';

const INSURANCE_MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
const INSURANCE_ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
];
const INSURANCE_ACCEPT_TYPES = INSURANCE_ALLOWED_MIME_TYPES.join();

const carrierStatusOptions = [
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Blocklisted',
    value: 'IN_BLACKLIST',
  },
  {
    label: 'Default',
    value: 'DEFAULT',
  },
];

interface CarrierInternalRecordsFieldsProps {
  title: string;
  carrier: CarrierFullInfo;
  onClose: () => void;
}

export function CarrierInternalRecordsFields({
  title,
  carrier,
  onClose,
}: CarrierInternalRecordsFieldsProps) {
  const { carrier_type, name, is_super_pay_enabled } = carrier;
  const profile = carrier.broker_records;
  const isCarrierInternal = carrier_type === 'INTERNAL';
  const { values, initialValues } =
    useFormikContext<CarrierBrokerPreferencesEdit>();
  const isCarrierEligibility = useFeatureToggle(
    'payments.expedited.pay.carrier.eligibility.access.ui',
  );
  const { user } = useUserState();

  const renderCarrierStatusLabel = useCallback(
    (value: unknown) => {
      const { approved_since, in_blacklist_since } = profile;

      switch (value as CarrierStatus) {
        case 'APPROVED':
          return (
            <>
              Approved
              {approved_since &&
                ` (on ${formatDate(approved_since, { variant: 'Date' })})`}
            </>
          );

        case 'IN_BLACKLIST':
          return (
            <>
              Blocklisted
              {in_blacklist_since &&
                ` (on ${formatDate(in_blacklist_since, {
                  variant: 'Date',
                })})`}
            </>
          );

        default:
          return 'Default';
      }
    },
    [profile],
  );

  return (
    <Stack aria-label={title} space="large">
      <TextField
        label="Carrier Name"
        disabled={true}
        fullWidth={true}
        value={name}
      />

      <Stack space="small">
        <Stack space="xsmall">
          <FormikTextField
            name="status"
            label="Carrier Status"
            fullWidth={true}
            select={true}
            SelectProps={{
              renderValue: renderCarrierStatusLabel,
            }}
          >
            {carrierStatusOptions.map(({ label, value }, idx) => (
              <MenuItem key={idx} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormikTextField>

          <Link
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            href="https://support.superdispatch.com/en/articles/4165914-approved-carrier-status-for-carriers"
          >
            Learn More about Carrier Status
          </Link>
        </Stack>

        {profile.is_in_private_network &&
          values.status !== 'APPROVED' &&
          initialValues.status === 'APPROVED' && (
            <PrivateNetworkStatusChangeWarningBox />
          )}

        {!isCarrierInternal && values.status === 'APPROVED' && (
          <Stack space="xsmall">
            <FormikCheckboxField
              name="preferred"
              label={
                <Inline verticalAlign="center">
                  <Typography>Can Instantly Book</Typography>

                  <Tooltip
                    title={
                      <>
                        This carrier can book your loads <br />
                        24/7 on Super Loadboard.
                      </>
                    }
                  >
                    <Info fontSize="small" color="action" />
                  </Tooltip>

                  {values.preferred && profile.preferred && (
                    <Typography variant="caption" color="textSecondary">
                      on{' '}
                      <FormattedDate
                        variant="Date"
                        date={profile.preferred_since}
                        format="DateISO"
                      />
                    </Typography>
                  )}
                </Inline>
              }
            />

            <PrivateNetworkCheckboxField
              source="Internal Records Drawer"
              onRequestAccess={onClose}
            />
          </Stack>
        )}

        {isCarrierEligibility &&
          user?.shipper.super_pay_settings
            ?.can_expedite_payment_tab_available &&
          values.status !== 'IN_BLACKLIST' && (
            <Stack space="xsmall">
              <FormikCheckboxField
                name="can_expedite_payment"
                label={
                  <Inline verticalAlign="center">
                    <Typography>Can Expedite Payment</Typography>

                    <Tooltip
                      title={
                        <>
                          This carrier can Expedite Payment on <br />
                          SuperPay Delivered Loads
                        </>
                      }
                    >
                      <Info fontSize="small" color="action" />
                    </Tooltip>

                    {profile.can_expedite_payment_since &&
                      values.can_expedite_payment && (
                        <Typography variant="caption" color="textSecondary">
                          on{' '}
                          <FormattedDate
                            variant="Date"
                            date={profile.can_expedite_payment_since}
                            format="DateISO"
                          />
                        </Typography>
                      )}
                  </Inline>
                }
              />
              {!is_super_pay_enabled && values.can_expedite_payment && (
                <ExpeditePaymentWarning />
              )}
            </Stack>
          )}
      </Stack>

      <Stack space="small">
        <Typography variant="h4">Certificate Holder</Typography>

        <FormikCheckboxField
          name="insurance_certificate_holder"
          label={
            <Inline verticalAlign="center">
              <Typography>Insurance Certificate Holder</Typography>

              {values.insurance_certificate_holder &&
                profile.insurance_certificate_holder_since && (
                  <Typography variant="caption" color="textSecondary">
                    on{' '}
                    <FormattedDate
                      variant="Date"
                      date={profile.insurance_certificate_holder_since}
                      format="DateISO"
                    />
                  </Typography>
                )}
            </Inline>
          }
        />

        <FormikDateField
          label="Expiration Date"
          name="insurance_expires_at"
          format="DateISO"
          fullWidth={true}
          enableClearable={true}
        />

        <FormikFileField
          aria-label="insurance file"
          label="Insurance File"
          name="insurance_cert_holder_file_url"
          accept={INSURANCE_ACCEPT_TYPES}
          validate={(value) =>
            value instanceof File
              ? validateFile(value, {
                  allowFormats: INSURANCE_ALLOWED_MIME_TYPES,
                  maxSize: INSURANCE_MAX_FILE_SIZE,
                  maxSizeMessage: `File size should be less than ${formatNumber(
                    INSURANCE_MAX_FILE_SIZE / (1024 * 1024),
                  )} MB`,
                })
              : undefined
          }
        />
      </Stack>

      <FormikAttachmentList
        name="attachments"
        accept={ATTACHMENT_ACCEPT_TYPES}
        validate={(value) =>
          validateFileList(
            value.filter((x: unknown) => x instanceof File),
            {
              allowFormats: ATTACHMENT_ALLOWED_MIME_TYPES,
              maxSize: ATTACHMENT_MAX_FILE_SIZE,
              maxSizeMessage: `File size should be less than ${formatNumber(
                ATTACHMENT_MAX_FILE_SIZE / (1024 * 1024),
              )} MB`,
            },
          )
        }
      />

      <FormikTextField
        name="custom_external_id"
        label="Custom External ID"
        fullWidth={true}
        helperText="This field can be used to store any information and is available through API"
      />
    </Stack>
  );
}
