import { Box, Chip, makeStyles, MenuItem, Typography } from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  formatPaymentMethod,
  formatPaymentTerm,
  isValidPaymentTerm,
  isValidSuperPayTerm,
  PaymentMethod,
} from '@superdispatch/sdk';
import {
  Button,
  DrawerActions,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { usePaymentOptions } from 'core/SuperPayUtils';
import { Form, FormikProvider } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { DrawerForm } from 'shared/form/DrawerForm';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useQuery } from 'shared/helpers/RouteHelpers';
import {
  SelectSuperPayHelperText,
  useSuperPayEnabled,
  useSuperPaySuspend,
} from 'shared/helpers/superpay/SuperPayHelpers';
import { ShipperProfileDTO } from 'shipper-profile/data/ShipperProfileDTO';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';

interface PaymentInfoEditProps {
  open: boolean;
  profile: ShipperProfileDTO;
  onClose: () => void;
  onUpdate: () => void;
}
const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    margin: '2px',
  },
});

export function PaymentInfoEditDrawer({
  profile,
  open,
  onClose,
  onUpdate,
}: PaymentInfoEditProps) {
  const styles = useStyles();
  const { addSnackbar } = useSnackbarStack();
  const { updatePaymentInfo } = useShipperProfileAPI();
  const [{ source = 'profile' }] = useQuery();
  const isSuperPaySuspend = useSuperPaySuspend();
  const isSuperPayEnabled = useSuperPayEnabled();

  useEffect(() => {
    if (open) trackEventLegacy('Opened Payment Info Edit Drawer', { source });
  }, [source, open]);

  const formik = useFormikEnhanced({
    initialValues: {
      ...profile,
      default_payment_terms: profile.default_payment_terms ?? 'other',
    },
    onSubmit: (values) => {
      return updatePaymentInfo(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      addSnackbar('Profile updated successfully', {
        variant: 'success',
      });
      trackUpdatedProfileValues('Payment Info', {
        billing_contact_name: updatedValues.billing_contact_name,
        billing_email: updatedValues.billing_email,
        billing_phone: updatedValues.billing_phone,
        preferred_payment_methods: updatedValues.preferred_payment_methods,
        alternative_payment_methods: updatedValues.alternative_payment_methods,
        default_payment_terms: updatedValues.default_payment_terms,
        default_payment_method: updatedValues.default_payment_method,
      });
      onUpdate();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const {
    status,
    dirty,
    isSubmitting,
    isValid,
    values,
    handleSubmit,
    setFieldValue,
  } = formik;

  const { paymentMethods, paymentTerms } = usePaymentOptions(
    values.default_payment_method,
  );

  const onDelete = (key: string, value: string) => {
    setFieldValue(
      key,
      values[key].filter((item: string) => item !== value),
    );
  };

  const onPaymentMethodChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    if (
      value === 'superpay' &&
      !isValidSuperPayTerm(values.default_payment_terms)
    ) {
      setFieldValue('default_payment_terms', '1_3_days');
    } else if (!isValidPaymentTerm(values.default_payment_terms)) {
      setFieldValue('default_payment_terms', '');
    }
  };

  // eslint-disable-next-line react/display-name
  const renderValue = (key: string) => (options: unknown) =>
    (
      <div className={styles.list}>
        {(options as string[]).map((value, index) => (
          <Chip
            key={index}
            className={styles.item}
            label={formatPaymentMethod(value as PaymentMethod)}
            onDelete={() => onDelete(key, value)}
            onMouseDown={(event) => event.stopPropagation()}
          />
        ))}
      </div>
    );

  return (
    <DrawerForm
      title="Payment Info"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
      drawerContentProps={{ style: { width: '432px' } }}
      actions={
        <DrawerActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack space="small">
            <Typography variant="h4">Billing</Typography>
            <FormikTextField
              name="billing_contact_name"
              label="Name"
              fullWidth={true}
            />
            <FormikTextField
              name="billing_email"
              label="Email"
              type="email"
              fullWidth={true}
              helperText="Displayed inside the outgoing Customer Invoices and Dispatch Sheets."
            />
            <SuspendedFormikPhoneField
              name="billing_phone"
              label="Phone"
              fullWidth={true}
            />
            <Box mt={2}>
              <Typography variant="h4">Payment Defaults</Typography>
              <Typography color="textSecondary">
                Set by default in new orders
              </Typography>
            </Box>
            <FormikTextField
              name="default_payment_method"
              label="Payment Method"
              fullWidth={true}
              select={true}
              onChange={onPaymentMethodChange}
              helperText={
                values.default_payment_method !== 'superpay' &&
                !isSuperPaySuspend &&
                isSuperPayEnabled && (
                  <SelectSuperPayHelperText
                    source="Shipper Profile"
                    onClick={() => {
                      setFieldValue('default_payment_method', 'superpay');
                      setFieldValue('default_payment_terms', '1_3_days');
                    }}
                  />
                )
              }
            >
              {paymentMethods.map((value) => (
                <MenuItem key={value} value={value}>
                  {formatPaymentMethod(value)}
                </MenuItem>
              ))}
            </FormikTextField>
            <FormikTextField
              name="default_payment_terms"
              label="Terms"
              fullWidth={true}
              select={true}
            >
              {paymentTerms.map((value) => (
                <MenuItem key={value} value={value}>
                  {formatPaymentTerm(value)}
                </MenuItem>
              ))}
            </FormikTextField>
            <Box mt={2}>
              <Typography variant="h4">Payment Method</Typography>
            </Box>
            <FormikTextField
              name="preferred_payment_methods"
              label="Preferred"
              fullWidth={true}
              select={true}
              format={(x) => x} // TODO fix this issue on superdispatch/ui
              SelectProps={{
                multiple: true,
                renderValue: renderValue('preferred_payment_methods'),
              }}
            >
              {paymentMethods.map((value) => (
                <MenuItem key={value} value={value}>
                  {formatPaymentMethod(value)}
                </MenuItem>
              ))}
            </FormikTextField>
            <FormikTextField
              name="alternative_payment_methods"
              label="Alternative"
              fullWidth={true}
              select={true}
              format={(x) => x}
              SelectProps={{
                multiple: true,
                renderValue: renderValue('alternative_payment_methods'),
              }}
            >
              {paymentMethods.map((value) => (
                <MenuItem key={value} value={value}>
                  {formatPaymentMethod(value)}
                </MenuItem>
              ))}
            </FormikTextField>
          </Stack>
        </Form>
      </FormikProvider>
    </DrawerForm>
  );
}
