import {
  Dialog,
  Divider,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { AllInbox, Close } from '@material-ui/icons';
import { formatDate } from '@superdispatch/dates';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { showIntercomDialog } from 'shared/helpers/IntercomHelpers';
import { TripIcon } from 'shared/icons/TripIcon';
import { VerifiedIconNew } from 'shared/icons/VerifiedIconNew';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { useReactivateSubscription } from './useReactivateSubscription';
import { useSubscriptionPlan } from './useSubscriptionPlan';

export function CancelSuccessDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { plan, isPlanLoading } = useSubscriptionPlan();
  const { isReactivatingSubscription, reactivateSubscription } =
    useReactivateSubscription({
      onSuccess: onClose,
    });

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <Box padding="xxlarge">
        {isPlanLoading && <LoadingContainer />}
        {plan !== undefined && (
          <Stack space="large">
            <Stack space="medium">
              <Stack space="small">
                <Columns>
                  <Column width="fluid">
                    <Typography variant="h2">
                      Your subscription has been canceled
                    </Typography>
                  </Column>

                  <Column width="content">
                    <IconButton edge="end" onClick={onClose} size="small">
                      <Close />
                    </IconButton>
                  </Column>
                </Columns>

                <Stack space="medium">
                  <Typography>
                    Your subscription will be canceled on{' '}
                    <b>
                      {formatDate(plan.activeTillDate, {
                        variant: 'DateTime',
                      })}
                    </b>
                    . You will have access to all subscription features until
                    this date.
                  </Typography>

                  <Typography>
                    Elevate your experience by reactivating your plan today for
                    full functionality:
                  </Typography>
                </Stack>
              </Stack>

              <Stack space="medium">
                {(
                  [
                    [
                      AllInbox,
                      'Centralized Order Management',
                      'Create and import orders on the Super Loadboard.',
                    ],
                    [
                      TripIcon,
                      'Real Time Load Updates',
                      'Receive real-time status updates for all of your Super Loadboard orders.',
                    ],
                    [
                      VerifiedIconNew,
                      'Access our Verified Carrier Network',
                      'Connect with thousands of trusted carriers monitored for FMCSA authority and insurance.',
                    ],
                  ] as const
                ).map(([Icon, title, description]) => (
                  <Stack key={title} space="xxsmall">
                    <Columns space="medium">
                      <Column width="content">
                        <Icon htmlColor={Color.Dark300} fontSize="large" />
                      </Column>

                      <Column width="fluid">
                        <Stack space="xxsmall">
                          <Typography variant="h4">{title}</Typography>
                          <Typography color="textSecondary">
                            {description}
                          </Typography>
                        </Stack>
                      </Column>
                    </Columns>
                  </Stack>
                ))}
              </Stack>
            </Stack>

            <Divider />

            <Stack space="large" align="center">
              <Button
                variant="primary"
                size="large"
                onClick={() => reactivateSubscription()}
                disabled={isReactivatingSubscription}
              >
                Reactivate Subscription
              </Button>

              <Link onClick={showIntercomDialog} component="button">
                Contact Support
              </Link>
            </Stack>
          </Stack>
        )}
      </Box>
    </Dialog>
  );
}
