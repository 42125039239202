import { useFormikEnhanced } from '@superdispatch/forms';
import { Inline } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnboarding } from 'shared/context/OnboardingContext';
import {
  usePaywallAPI,
  useSubscriptionDetails,
} from 'shared/errors/paywall/data/PaywallAPI';
import { paywallBillingAddressSchema } from 'shared/errors/paywall/data/PaywallDTO';
import { useCreditCard } from 'shared/form/ChargebeeFormFields';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { PaymentDetailsCard } from './PaymentDetailsCard';
import { PaymentSummaryCard } from './PaymentSummary';
import { SubscriptionSuccessDialog } from './SubscriptionSuccessDialog';
import { useSelectedSubscriptionPlan } from './useSelectedSubscriptionPlan';

export function SubscriptionPaymentForm() {
  const navigate = useNavigate();
  const { data } = useSubscriptionDetails();
  const [isSubscribed, setSubscribed] = useState(false);
  const { addCreditCard, cardRef, isChargebeeAvailable } = useCreditCard(
    data?.chargebee.client_token,
  );
  const { purchaseSubscription } = usePaywallAPI();
  const { subscriptionPlan } = useSelectedSubscriptionPlan();
  const { completeStep } = useOnboarding();

  const formik = useFormikEnhanced({
    initialValues: {
      country: 'US',
      state: '',
      zip: '',
      address: '',
      address_second_line: '',
      city: '',
    },
    validationSchema: paywallBillingAddressSchema,
    validateOnBlur: false,
    getFormErrors: (submitError) => {
      return defaultFormErrorsGetter(submitError);
    },
    onSubmit: async (values) => {
      const { token } = await addCreditCard({});

      return purchaseSubscription({
        token_id: token,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        plan_code: subscriptionPlan!.plan_code,
        ...values,
        ...(values.country !== 'US' && { state: '', zip: '' }),
      });
    },
    onSubmitSuccess: () => {
      setSubscribed(true);
      completeStep('SUBSCRIBE');
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Inline space="small" horizontalAlign="center">
          <PaymentDetailsCard
            cardRef={cardRef}
            isChargebeeAvailable={isChargebeeAvailable}
          />
          <PaymentSummaryCard />
        </Inline>
        <SubscriptionSuccessDialog
          open={isSubscribed}
          onClose={() => {
            setSubscribed(false);
            navigate('/subscription', { replace: true });
          }}
        />
      </Form>
    </FormikProvider>
  );
}
