import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { trackEvent } from './AnalyticsHelpers';

const Context = createContext<SourceValue | null>(null);

interface SourceValue {
  primarySource?: string;
  secondarySource?: string;
}

export function useTrackEventWithSource() {
  const context = useSourceManager();
  return useCallback(
    (
      eventName: string,
      data?: Record<string, string | number | undefined | boolean | null>,
      callback?: () => void,
    ) => {
      trackEvent(
        eventName,
        {
          utm_medium: context.primarySource,
          utm_content: context.secondarySource,
          ...data,
        },
        callback,
      );
    },
    [context],
  );
}

export function useSourceManager() {
  const value = useContext(Context);

  if (value === null) {
    return {};
  }

  return value;
}

interface SourceManagerProps {
  primarySource?: string;
  secondarySource?: string;
  children?: ReactNode;
}

export function SourceManager({
  primarySource,
  secondarySource,
  children,
}: SourceManagerProps) {
  const context = useSourceManager();
  const value = useMemo(() => {
    return {
      primarySource: primarySource ?? context.primarySource,
      secondarySource: secondarySource ?? context.secondarySource,
    };
  }, [primarySource, secondarySource, context]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
