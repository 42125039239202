import { Box, Tooltip, Typography } from '@material-ui/core';
import { Add, PlaylistAdd } from '@material-ui/icons';
import { useValueObserver } from '@superdispatch/hooks';
import { Color, Column, Columns, InfoTooltip, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { EditSuperPayOrderBanner } from 'orders/core/EditSuperPayBanner';
import { useOrderPaymentFlags } from 'orders/data/OrderPaymentFlagsAPI';
import { findDuplicateVins } from 'orders/data/OrdersAPILegacy';
import { useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { APIResponse } from 'shared/types/APIResponse';
import { ScrollableViewport } from 'shared/ui/ScrollableViewport';
import styled from 'styled-components';
import { DecodedOrderVehicleDTO } from '../data/DecodedOrderVehicleDTO';
import { OrderFormVehicleDTO } from '../data/OrderFormVehicleDTO';
import { calculateVehiclesSum } from '../helpers/orderFormHelpers';
import { OrderFormValues } from '../OrderForm';
import { PaperBox } from '../PaperBox';
import { BulkVinDecode } from './BulkVinDecode';
import { DuplicateVinModal } from './DuplicateVinModal';
import { VehicleItem } from './VehicleItem';

const Table = styled.table`
  border-collapse: collapse;
  min-width: 1250px;

  & > thead,
  & > tbody {
    border: none;
    border-radius: 2px;
    overflow: hidden;

    & > tr {
      & > th {
        padding: 8px;
        text-align: left;
        vertical-align: middle;
        color: ${Color.Grey500};
        border-bottom: 1px solid ${Color.Silver400};
      }

      & > td {
        border-bottom: 1px solid ${Color.Silver400};
        padding: 16px 8px;

        &:nth-of-type(3) {
          width: 85px;
        }
        &:nth-of-type(6) {
          width: 150px;
        }
      }
    }
  }
`;

interface OrderWithVin {
  number: string;
  guid: string;
}

type VinsList = Record<string, OrderWithVin[]>;

export interface VehiclesProps {
  id: number;
  guid: string;
  isSuperPayStatusAvailable: boolean;
  remove: FieldArrayRenderProps['remove'];
  push: FieldArrayRenderProps['push'];
  replace: FieldArrayRenderProps['replace'];
}

export function Vehicles({
  id,
  guid,
  isSuperPayStatusAvailable,
  remove,
  push,
  replace,
}: VehiclesProps) {
  const {
    setFieldValue,
    dirty,
    values: { vehicles },
  } = useFormikContext<OrderFormValues>();

  const { user } = useUserState();
  const orderPaymentFlags = useOrderPaymentFlags(id);
  const [isBulkVinDecodeOpen, setIsBulkVinDecodeOpen] = useState(false);
  const [modalVinList, setModalModalVinList] = useState<VinsList>();

  useValueObserver(vehicles, (prevValues) => {
    const price = calculateVehiclesSum(vehicles, 'price');
    const tariff = calculateVehiclesSum(vehicles, 'tariff');
    const isEqualPrice = price === calculateVehiclesSum(prevValues, 'price');
    const isEqualTariff = tariff === calculateVehiclesSum(prevValues, 'tariff');

    if (!isEqualPrice && dirty) {
      setFieldValue('price', price || 0);
    }

    if (!isEqualTariff && dirty) {
      setFieldValue('customer_payment.tariff', tariff || null);
    }
  });

  const checkDuplicateVin = (vinNumber: string[]) => {
    void findDuplicateVins(vinNumber).then(
      (response: APIResponse<VinsList>) => {
        const vinsList = response.data.object;
        const duplicateVINs = Object.keys(vinsList);
        const duplicateOrders = Object.values(vinsList);

        if (
          duplicateVINs.length > 0 &&
          duplicateOrders.some((orders) => orders.length > 0)
        ) {
          setModalModalVinList(vinsList);
        }
      },
    );
  };

  const handleBulkDecoded = (
    vins: string[],
    decodedVehicles: DecodedOrderVehicleDTO[],
  ) => {
    vehicles.forEach(({ vin, make, model, year }, index) => {
      if (!vin && !make && !model && !year) {
        remove(index);
      }
    });

    decodedVehicles.forEach((vehicle) => {
      push(OrderFormVehicleDTO.create(vehicle));
    });

    checkDuplicateVin(vins);

    setIsBulkVinDecodeOpen(false);
  };

  const handleVinChange = (input: string) => {
    if (input.length === 8 || input.length === 16 || input.length === 17) {
      checkDuplicateVin([input]);
    }
  };

  const hasColorValue = vehicles.some((item) => item.color);
  const hasLotValue = vehicles.some((item) => item.lot_number);

  const { isAdvancedTier } = useProductTiers();

  return (
    <PaperBox
      title={
        <Box marginY="-2px">
          <Columns align="center" space="xxsmall">
            <Column width="content">
              <Typography variant="h3">Vehicles</Typography>
            </Column>
            <Column width="content">
              <Tooltip
                title={
                  <Box maxWidth="210px">
                    Adding vehicles after Pickup is not available when the
                    payment method is SuperPay.
                  </Box>
                }
                disableHoverListener={!isSuperPayStatusAvailable}
              >
                <span>
                  <Button
                    type="button"
                    variant="text"
                    disabled={isSuperPayStatusAvailable}
                    startIcon={<Add />}
                    onClick={() => {
                      push(
                        OrderFormVehicleDTO.create({
                          type: 'other',
                          curb_weight_unit: 'lbs',
                        }),
                      );
                    }}
                  >
                    Add
                  </Button>
                </span>
              </Tooltip>
            </Column>

            <Column width="content">
              <Button
                variant="text"
                type="button"
                disabled={isSuperPayStatusAvailable}
                startIcon={<PlaylistAdd />}
                onClick={() => {
                  setIsBulkVinDecodeOpen(true);
                }}
              >
                Bulk VIN Decode
              </Button>
            </Column>
            {orderPaymentFlags?.can_be_edited && (
              <Column width="fluid">
                <Inline horizontalAlign="right">
                  <EditSuperPayOrderBanner orderGuid={guid} source="vehicles" />
                </Inline>
              </Column>
            )}
          </Columns>
        </Box>
      }
    >
      <>
        <Box mt="16px">
          <ScrollableViewport rightRefStyle={{ marginRight: '1px' }}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Typography>#</Typography>
                  </th>
                  <th />
                  <th>
                    <Typography>VIN</Typography>
                  </th>
                  <th>
                    <Typography>Year</Typography>
                  </th>
                  <th>
                    <Typography display="inline">Make</Typography>{' '}
                    <Typography display="inline" color="textSecondary">
                      (Required)
                    </Typography>
                  </th>
                  <th>
                    <Typography display="inline">Model</Typography>{' '}
                    <Typography display="inline" color="textSecondary">
                      (Required)
                    </Typography>
                  </th>
                  <th>
                    <Typography>Type</Typography>
                  </th>
                  <th>
                    <Typography>Curb Weight</Typography>
                  </th>
                  {((user?.order_form_settings.vehicle &&
                    user.order_form_settings.vehicle.is_color_visible) ||
                    hasColorValue) && (
                    <th>
                      <Typography>Color</Typography>
                    </th>
                  )}
                  {((user?.order_form_settings.vehicle &&
                    user.order_form_settings.vehicle.is_lot_number_visible) ||
                    hasLotValue) && (
                    <th>
                      <Typography>Lot #</Typography>
                    </th>
                  )}
                  {user?.shipper.shipper_type === 'BROKER' && isAdvancedTier && (
                    <th>
                      <Typography>Tariff</Typography>
                    </th>
                  )}
                  {user?.shipper.shipper_type === 'BROKER' && (
                    <th>
                      <Typography>Carrier Price</Typography>
                    </th>
                  )}
                  <th>
                    <InfoTooltip
                      title="INOP - Inoperable vehicle"
                      placement="top"
                      TextProps={{
                        color: 'textPrimary',
                      }}
                    >
                      INOP
                    </InfoTooltip>
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {vehicles
                  ? vehicles.map((vehicle, index) => {
                      return (
                        vehicle && (
                          <VehicleItem
                            key={vehicle.key}
                            index={index}
                            name={`vehicles[${index}]`}
                            vehicle={vehicle}
                            hasColorValue={hasColorValue}
                            hasLotValue={hasLotValue}
                            autoFocus={!vehicle.id && index > 0}
                            onRemove={() => {
                              remove(index);
                            }}
                            onVinDecode={(nextVehicle) => {
                              replace(index, nextVehicle);
                            }}
                            onVinChange={(input) => {
                              handleVinChange(input);
                            }}
                          />
                        )
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </ScrollableViewport>
        </Box>

        <BulkVinDecode
          isOpen={isBulkVinDecodeOpen}
          onDecoded={handleBulkDecoded}
          onCancel={() => {
            setIsBulkVinDecodeOpen(false);
          }}
        />
      </>

      <DuplicateVinModal
        vinList={modalVinList}
        onClose={() => setModalModalVinList(undefined)}
      />
    </PaperBox>
  );
}
