import { Link, Typography } from '@material-ui/core';
import { Language, Room } from '@material-ui/icons';
import {
  DescriptionList,
  DescriptionListItem,
  InfoCard,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { normalizeURL } from '@superdispatch/uri';
import * as React from 'react';
import { useMemo } from 'react';
import { trackAnchorClickLegacy } from 'shared/helpers/AnalyticsHelpers';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { TrackBackIcon } from 'shared/icons/TrackBackIcon';
import {
  CarrierFullInfo,
  CARRIER_LOGISTICS,
  isCarrierHasValidUSDOT,
} from 'shared/types/carrier';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import { useAuthority } from '../data/ManageCarriersAPI';

interface CarrierCompanyInfoProps {
  carrier: CarrierFullInfo;
}

export function CarrierCompanyInfo({ carrier }: CarrierCompanyInfoProps) {
  const { data: authority } = useAuthority(carrier.us_dot);

  const isCarrierInternal = carrier.carrier_type === 'INTERNAL';
  const carrierAddress = useMemo(
    () =>
      makeAddressCityStateZip({
        address: carrier.address,
        city: carrier.city,
        state: carrier.state,
        zip: carrier.zip,
      }),
    [carrier],
  );
  const carrierWebsite = useMemo(
    () => normalizeURL(carrier.website),
    [carrier.website],
  );

  const hasCompanyInfo =
    carrierAddress ||
    carrierWebsite ||
    carrier.us_dot ||
    carrier.logistics_type ||
    (!isCarrierInternal && carrier.mc_number);

  if (!hasCompanyInfo) {
    return (
      <InfoCard size="large" aria-label="carrier company info">
        <Stack space="small">
          <Typography variant="h3">Company Info</Typography>

          <Typography color="textSecondary">No Information Provided</Typography>
        </Stack>
      </InfoCard>
    );
  }

  return (
    <InfoCard size="large" aria-label="carrier company info">
      <Stack space="small">
        <Typography variant="h3">Company Info</Typography>

        <DescriptionList size="small">
          {carrier.is_verified && !isCarrierHasValidUSDOT(carrier) ? (
            <DescriptionListItem
              aria-label="carrier operation"
              icon={<HashtagIcon />}
              label="Carrier Operation"
              content="Intrastate"
            />
          ) : authority?.carrier_operation ? (
            <DescriptionListItem
              aria-label="carrier operation"
              icon={<HashtagIcon />}
              label="Carrier Operation"
              content={authority.carrier_operation}
            />
          ) : null}

          {carrier.logistics_type &&
            CARRIER_LOGISTICS[carrier.logistics_type] && (
              <DescriptionListItem
                aria-label="logistics"
                icon={<HashtagIcon />}
                label="Logistics"
                content={CARRIER_LOGISTICS[carrier.logistics_type]}
              />
            )}
          {!isCarrierInternal && carrier.us_dot && (
            <DescriptionListItem
              aria-label="usdot number"
              icon={<HashtagIcon />}
              label="USDOT"
              content={carrier.us_dot}
            />
          )}
          {!isCarrierInternal && carrier.mc_number && (
            <DescriptionListItem
              aria-label="mc number"
              icon={<HashtagIcon />}
              label="MC"
              content={carrier.mc_number}
            />
          )}
          {carrierWebsite && (
            <DescriptionListItem
              icon={<Language />}
              contentTypographyProps={{ 'aria-label': 'personal page' }}
              content={
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={carrierWebsite}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                    trackAnchorClickLegacy(
                      'Clicked Carrier Website',
                      undefined,
                      event,
                    )
                  }
                >
                  {carrier.website}
                </Link>
              }
            />
          )}
          {carrierAddress && (
            <DescriptionListItem
              icon={<Room />}
              contentTypographyProps={{
                'aria-label': 'carrier address',
              }}
              content={carrierAddress}
            />
          )}
        </DescriptionList>

        {!!carrier.largest_trailer_capacity && (
          <Stack>
            <Inline verticalAlign="center">
              <Typography variant="h4">Equipment</Typography>
              <Tag color="blue" variant="subtle">
                New
              </Tag>
            </Inline>

            <DescriptionList size="small">
              <DescriptionListItem
                icon={<TrackBackIcon color="secondary" />}
                label="Largest trailer capacity"
                content={`${carrier.largest_trailer_capacity} sedans`}
              />
            </DescriptionList>
          </Stack>
        )}
      </Stack>
    </InfoCard>
  );
}
