import { useQuery } from 'shared/helpers/RouteHelpers';

export type BillingPeriod = 'monthly' | 'anually';

export function useBillingPeriod() {
  const [query, setQuery] = useQuery();

  const billingPeriod = query.billingPeriod
    ? (query.billingPeriod as BillingPeriod)
    : 'monthly';

  function toggleBillingPeriod() {
    const newBillingPeriod =
      billingPeriod === 'monthly' ? 'anually' : 'monthly';

    setQuery(
      {
        billingPeriod: newBillingPeriod,
      },
      true,
    );
  }

  return [billingPeriod, toggleBillingPeriod] as const;
}
