import { Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DrawerActions, DrawerContent, DrawerTitle } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

interface PerformanceDrawerProps {
  onClose: () => void;
  open: boolean;
  title: ReactNode;
  children: ReactNode;
}

export function PerformanceDrawer({
  onClose,
  open,
  title,
  children,
}: PerformanceDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title={title}
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">{children}</Box>
      </DrawerContent>
      <DrawerActions>
        <Button onClick={onClose}>Ok</Button>
      </DrawerActions>
    </Drawer>
  );
}
