import { PaymentMethod } from '@superdispatch/sdk';
import { yupArray, yupObject, yupString } from 'shared/utils/YupUtils';
import { boolean, InferType, number } from 'yup';
import { LoadStep } from './step';

export interface LoadPayment {
  price?: number;
  terms?: string;
  notes?: string;
  method?: PaymentMethod;
  reference_number?: string;
}

export interface Load {
  number?: string;
  pickup: LoadStep;
  delivery: LoadStep;
  payment: LoadPayment;
  instructions: string | null;
  loadboard_instructions: string | null;
}

export interface LoadBasicInfo {
  order_id: number;
  related_order_guid: string;
  related_order_number: string;
}

export type OrderWithLoadsDTO = InferType<typeof orderWithLoadsSchema>;
export const orderWithLoadsSchema = yupObject({
  order_id: number(),
  has_unassigned_vehicles: boolean().default(false),
  loads: yupArray(
    yupObject({
      guid: yupString(),
      number: yupString(),
      status: yupString(),
      pickup_address: yupString().ensure(),
      delivery_address: yupString().ensure(),
    }),
  ).defined(),
});
