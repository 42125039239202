import type { LogsGlobal } from '@datadog/browser-logs';

declare global {
  interface Window {
    DD_LOGS?: LogsGlobal;
  }
}

function getDatadogLogs() {
  return window.DD_LOGS;
}

function getDatadogLogger() {
  return getDatadogLogs()?.logger;
}

interface DataDogUser {
  id: string;
  shipperGuid: string;
  shipperName: string;
  browserFingerprint: string;
}

export function setUserForDataDog({
  id,
  shipperGuid,
  shipperName,
  browserFingerprint,
}: DataDogUser) {
  const ddLogs = getDatadogLogs();

  if (!ddLogs) {
    return;
  }

  ddLogs.setUser({
    id,
    shipper_guid: shipperGuid,
    shipper_name: shipperName,
    browser_fingerprint: browserFingerprint,
  });
}
export interface LogExceptionOptions extends Record<string, unknown> {
  namespace?: string;
}

export function identifyErrorTrackerUser(user: DataDogUser) {
  setUserForDataDog(user);
}

const moduleImportErrorMessages = [
  'failed to fetch dynamically imported module',
  'error loading dynamically imported module',
  'importing a module script failed',
];

const moduleImportErrorMessagesRegExp = new RegExp(
  moduleImportErrorMessages.join('|'),
  'i',
);

export function isModuleImportError(error: unknown) {
  return (
    error instanceof Error &&
    moduleImportErrorMessagesRegExp.test(error.message)
  );
}

export function logError(
  error: unknown,
  namespace: string,
  { extraInfo }: LogExceptionOptions = {},
) {
  const logger = getDatadogLogger();

  if (!logger) {
    return;
  }

  const messageContext = {
    namespace,
    extraInfo,
    original_error: error,
  };

  if (typeof error === 'string') {
    logger.error(error, messageContext);
  } else if (error instanceof Error) {
    logger.error(error.message, messageContext);
  } else {
    logger.error('Unknown Error', messageContext);
  }

  if (isModuleImportError(error)) {
    setTimeout(() => {
      requestForceReload();
    }, 2000);
  }
}

export function logInfo(message: string, extraInfo?: Record<string, unknown>) {
  const logger = getDatadogLogger();
  if (!logger) {
    return;
  }
  const messageContext = {
    namespace: extraInfo?.namespace,
    extraInfo,
  };
  logger.info(message, messageContext);
}

export function logWarning(
  message: string,
  extraInfo?: Record<string, unknown>,
) {
  const logger = getDatadogLogger();

  if (!logger) {
    return;
  }

  const messageContext = {
    namespace: extraInfo?.namespace,
    extraInfo,
  };
  logger.warn(message, messageContext);
}

export function requestForceReload() {
  if (
    window.confirm(
      'Failed to load required assets. This can happen either due to bad internet connection or a new version of the website. Reload the page?\n' +
        '(Clear your browser cache and reload the page manually if this error occurs again)',
    )
  ) {
    window.location.reload();
  }
}
