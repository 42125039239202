import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { paymentOnHoldSchema } from 'shared/dto/ActionsDTO';
import Order from 'shared/types/order';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';
import { PaymentOnHoldDialog } from './MarkAsPaymentOnHoldDialog';

interface UnmarkAsDeliveredDialogProps {
  order?: Order;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export function UnmarkAsDeliveredDialog({
  order,
  onCancel,
  onSubmitSuccess,
}: UnmarkAsDeliveredDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { rollbackManualStatusChange } = useSingleOrderActionAPI();

  const form = useFormikEnhanced({
    initialValues: {
      custom_on_hold_reason: '',
    },
    validationSchema: paymentOnHoldSchema,
    onSubmit(values) {
      if (!order) {
        return Promise.reject(new Error('No order found'));
      }

      return rollbackManualStatusChange(order.id, values);
    },
    onSubmitSuccess() {
      addSnackbar('Status changed successfully', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure() {
      addSnackbar('Status changed failed', { variant: 'error' });
    },
  });

  const { submitForm } = form;

  return (
    <FormikProvider value={form}>
      <Form>
        <PaymentOnHoldDialog
          title="Unmark as Delivered will put Payment on Hold"
          open={!!order}
          onClose={onCancel}
          onSend={submitForm}
        />
      </Form>
    </FormikProvider>
  );
}
