import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Button,
  Column,
  Columns,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { OrderTable } from '../core';
import { OrderActionsProvider } from '../core/actions/OrderActionsContext';
import { useOrderImportAPI } from './data/OrderImportAPI';
import { OrderImportInfoDTO } from './data/OrderImportInfoDTO';
import { OrderImportErrorMessage } from './OrderImportErrorMessage';
import { OrderImportForm } from './OrderImportForm';
import { OrderImportListItemActions } from './OrderImportListItemActions';

export function OrderImportPage() {
  const navigate = useNavigate();
  const uid = useUID();
  const { addSnackbar } = useSnackbarStack();

  const { deleteImportedOrders } = useOrderImportAPI();
  const [importedOrders, setImportedOrders] = useState<OrderImportInfoDTO>();
  const [importError, setImportError] = useState<Record<string, string[]>>();

  const deleteAllForm = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      if (!importedOrders?.batch_id) {
        return Promise.reject(new Error('No imported orders were found'));
      }

      return deleteImportedOrders(importedOrders.batch_id);
    },
    onSubmitSuccess() {
      setImportedOrders(undefined);
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  useDocumentTitle('Import Orders');

  useEffect(() => {
    if (
      importedOrders?.errors &&
      Object.keys(importedOrders.errors).length > 0
    ) {
      setImportError(importedOrders.errors);
    }
  }, [importedOrders?.errors]);

  const onClose = () => {
    setImportError(undefined);
  };

  return (
    <>
      <PageLayout
        header={
          <AppBar>
            <Toolbar style={{ padding: '16px 24px' }}>
              <Columns>
                <Column width="fluid">
                  <Typography variant="h3">Import Orders</Typography>

                  <Link onClick={() => navigate(-1)}>← Orders</Link>
                </Column>

                {importedOrders?.batch_id && (
                  <Column width="content">
                    <Button
                      color="primary"
                      variant="contained"
                      isLoading={deleteAllForm.isSubmitting}
                      onClick={() => deleteAllForm.handleSubmit()}
                    >
                      Delete All
                    </Button>
                  </Column>
                )}
              </Columns>
            </Toolbar>
          </AppBar>
        }
      >
        <div>
          {!importedOrders?.orders?.length ? (
            <OrderImportForm
              onImportSuccess={(data) => setImportedOrders(data)}
            />
          ) : (
            <OrderActionsProvider
              source="Import Order"
              onActionComplete={() => {}}
            >
              <OrderTable
                isLoading={false}
                selectedOrders={[]}
                onUnselectAllOrders={() => {}}
                onSelectAllOrders={() => {}}
                toggleSelectOrder={() => {}}
                orders={importedOrders.orders}
                renderActions={(order) => (
                  <OrderImportListItemActions
                    order={order}
                    onDeleteSuccess={() => {
                      setImportedOrders((prev) => {
                        if (!prev) {
                          return undefined;
                        }
                        return {
                          ...prev,
                          orders: prev.orders?.filter((x) => x.id !== order.id),
                        };
                      });
                    }}
                  />
                )}
              />
            </OrderActionsProvider>
          )}
        </div>
      </PageLayout>
      <Dialog open={!!importError} aria-labelledby={uid} onClose={onClose}>
        <DialogTitle>
          <Typography id={uid} variant="h2">
            There are errors in imported CSV file:
          </Typography>
        </DialogTitle>

        <DialogContent>
          {importError && <OrderImportErrorMessage errors={importError} />}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
}
