import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { Carrier } from '../../shared/types/carrier';
import { InternalCarrierForm } from '../core/InternalCarrierForm';
import { useCarrierProfileAPI } from '../data/ManageCarriersAPI';

interface Props {
  carrier: Carrier;
  onSubmitSuccess: () => void;
}

export function EditInternalCarrierForm({ carrier, onSubmitSuccess }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { updateInternalCarrier } = useCarrierProfileAPI();

  const form = useFormikEnhanced({
    initialValues: carrier,
    onSubmit: (values) => updateInternalCarrier(values),
    onSubmitSuccess() {
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={form}>
      <InternalCarrierForm title="Edit Internal Carrier" />
    </FormikProvider>
  );
}
