import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoRows: 'minmax(0, auto)',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "pickup           pickup"
      "delivery         delivery"
      "dates            dates"
      "activity         activity"
      "payment          payment"
      "instructions     instructions"
      "notesFromShipper notesFromShipper"
      "attachments      attachments"`,
  },
  pickup: {
    gridArea: 'pickup',
  },
  delivery: {
    gridArea: 'delivery',
  },
  payment: {
    gridArea: 'payment',
  },
  notesFromShipper: {
    gridArea: 'notesFromShipper',
  },
  instructions: {
    gridArea: 'instructions',
  },
  activity: {
    gridArea: 'activity',
  },
  dates: {
    gridArea: 'dates',
  },
  attachments: {
    gridArea: 'attachments',
  },
  '@media (min-width: 700px)': {
    gridWrapper: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "pickup           delivery"
        "dates            payment"
        "activity         ......."
        "instructions     instructions"
        "notesFromShipper notesFromShipper"
        "attachments      ......."
      `,
    },
  },
  '@media (min-width: 1280px)': {
    gridWrapper: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        "dates  pickup           delivery         payment"
        "attachments  notesFromShipper notesFromShipper activity"
        "attachments  instructions     instructions     activity"`,
    },
  },
}));

interface TemplateProps {
  pickup: React.ReactNode;
  delivery: React.ReactNode;
  payment: React.ReactNode;
  dates: React.ReactNode;
  instructions: React.ReactNode;
  activities: React.ReactNode;
  notesFromShipper: React.ReactNode;
  hasInstructions: boolean;
  attachments: React.ReactNode;
}

export const CustomerTemplate: React.ComponentType<TemplateProps> = ({
  pickup,
  delivery,
  payment,
  dates,
  instructions,
  activities,
  notesFromShipper,
  hasInstructions,
  attachments,
}) => {
  const classes = useStyles();
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  const cardStyles = isMobile
    ? {
        borderRadius: '0',
        borderLeft: 'none',
        borderRight: 'none',
      }
    : {};

  return (
    <Box
      pt={3}
      px={isMobile ? 0 : 3}
      pb={isMobile ? 7 : 3}
      className={classes.gridWrapper}
    >
      <Box className={classes.pickup}>
        <Card style={cardStyles}>
          <CardContent>{pickup}</CardContent>
        </Card>
      </Box>
      <Box className={classes.delivery}>
        <Card style={cardStyles}>
          <CardContent>{delivery}</CardContent>
        </Card>
      </Box>
      <Box className={classes.payment}>
        <Card style={cardStyles}>
          <CardContent>{payment}</CardContent>
        </Card>
      </Box>

      {hasInstructions && (
        <Box className={classes.instructions}>
          <Card style={cardStyles}>
            <CardContent>{instructions}</CardContent>
          </Card>
        </Box>
      )}

      <Box className={classes.activity} overflow="hidden">
        <Card style={cardStyles}>
          <CardContent>{activities}</CardContent>
        </Card>
      </Box>

      <Box className={classes.dates}>
        <Card style={cardStyles}>
          <CardContent>{dates}</CardContent>
        </Card>
      </Box>

      <Box className={classes.notesFromShipper}>
        <Card style={cardStyles}>
          <CardContent>{notesFromShipper}</CardContent>
        </Card>
      </Box>

      <Box className={classes.attachments}>
        <Card style={cardStyles}>
          <CardContent>{attachments}</CardContent>
        </Card>
      </Box>
    </Box>
  );
};
