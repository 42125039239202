import {
  Checkbox,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ViewWeek } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { getOnlineBOLUrl } from 'orders/core/actions/OrderActionUtils';
import { OrderInspectionPhotoIssues } from 'orders/core/OrderInspectionPhotoIssues';
import { useOrderInspectionPhotoIssues } from 'orders/data/OrderAPI';
import { useEffect, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { useLocalStorage, useSet } from 'shared/helpers/ReactHelpers';
import Order from 'shared/types/order';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { useProductTiers } from '../../shared/data/TiersUtils';
import { OrderViewRelatedVehicles } from './OrderViewRelatedVehicles';
import { OrderViewVehiclesOnly } from './OrderViewVehiclesOnly';

function formatDimensionValue(value: string) {
  return formatNumber(parseFloat(value), {
    maximumFractionDigits: 0,
  });
}

export function formatDimensions({
  height,
  width,
  length,
}: VehicleDTO): string {
  return [
    length && `${formatDimensionValue(length)}″ L`,
    width && `${formatDimensionValue(width)}″ W`,
    height && `${formatDimensionValue(height)}″ H`,
  ]
    .filter(Boolean)
    .join(' x ');
}

const dynamicColumns = [
  { label: 'Status', value: 'status' },
  { label: 'Pickup Date', value: 'pickupDate' },
  { label: 'Delivery Date', value: 'deliveryDate' },
  { label: 'Vehicle Details', value: 'vehicleDetails' },
  { label: 'Lot #', value: 'lotNumber' },
];

function TableHeader({ children, ...props }: TableCellProps) {
  return (
    <TableCell {...props}>
      <TextBox noWrap={true} color="secondary" variant="heading-5">
        {children}
      </TextBox>
    </TableCell>
  );
}

export interface VehiclesProps {
  order: Order;
  loads: Order[];
  handleRemoveRelations: (id: number) => void;
}
export function OrderViewVehicles({
  order,
  loads,
  handleRemoveRelations,
}: VehiclesProps) {
  const { vehicles = [] } = order;
  const { user } = useUserState();
  const buildLoadsEnabled = useFeatureToggle('build-loads.enabled.ui');
  const hasRelatedLoads =
    buildLoadsEnabled && order.has_loads && !order.is_load;
  const shipperType = user?.shipper.shipper_type;
  const hasLotValue = vehicles.some((item) => item.lot_number);
  const [isExpanded, setExpandState] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [storedHiddenFields, updateStoredHiddenFields] = useLocalStorage(
    'hiddenVehicleFields',
  );
  const [vehiclesOnlyView, setVehiclesOnlyView] = useState(!hasRelatedLoads);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isDesktop = platform === 'desktop';

  const orderInspectionPhotoIssues = useOrderInspectionPhotoIssues([order.id]);

  const dynamicColumnsModified =
    !user?.order_form_settings.vehicle.is_lot_number_visible && !hasLotValue
      ? dynamicColumns.filter((column) => column.value !== 'lotNumber')
      : dynamicColumns;

  const [hiddenFields, addHiddenFields, removeHiddenFields] = useSet<
    typeof dynamicColumnsModified[number]['label']
  >(new Set(storedHiddenFields?.split(',')));

  const { isAdvancedTier } = useProductTiers();

  useEffect(() => {
    updateStoredHiddenFields(Array.from(hiddenFields).join() || null);
  }, [updateStoredHiddenFields, hiddenFields]);

  useEffect(() => {
    setVehiclesOnlyView(!hasRelatedLoads);
  }, [hasRelatedLoads]);

  return (
    <div data-intercom-target="vehicles section">
      <Stack space="none">
        <Box paddingLeft="small">
          <Columns align="center">
            <Column>
              <Inline verticalAlign="center">
                <Box>
                  <Typography variant="h3" display="inline">
                    Vehicles
                  </Typography>

                  {vehicles.length > 0 && (
                    <Typography
                      variant="h3"
                      display="inline"
                      color="textSecondary"
                    >
                      &nbsp;({vehicles.length})
                    </Typography>
                  )}
                </Box>

                <OrderInspectionPhotoIssues
                  onClick={() => {
                    trackEvent('Shipper Clicked Non-vehicle Photo Issues', {
                      utm_medium: 'order_details',
                    });
                  }}
                  online_bol_url={getOnlineBOLUrl(
                    order,
                    'Inspection Photo Issues Link',
                  )}
                  inspection_photo_issues_count={
                    orderInspectionPhotoIssues.data?.[order.id]
                  }
                />
              </Inline>
            </Column>

            {hasRelatedLoads && isDesktop && (
              <Column width="content">
                <Inline verticalAlign="center" space="none">
                  <Typography>Vehicles only</Typography>

                  <Switch
                    onClick={() => {
                      setVehiclesOnlyView(!vehiclesOnlyView);
                    }}
                  />
                </Inline>
              </Column>
            )}

            <Column width="content">
              <IconButton
                aria-label="manage columns"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <ViewWeek />
              </IconButton>

              <IconButton
                onClick={() => {
                  setExpandState((prev) => !prev);
                }}
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Column>
          </Columns>

          {hasRelatedLoads && !isDesktop && (
            <Inline horizontalAlign="right" verticalAlign="center" space="none">
              <Typography>Vehicles only</Typography>

              <Switch
                onClick={() => {
                  setVehiclesOnlyView(!vehiclesOnlyView);
                }}
              />
            </Inline>
          )}
        </Box>

        <Collapse in={isExpanded}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>#</TableHeader>
                  <TableHeader />
                  {!hiddenFields.has('status') && (
                    <TableHeader>Status</TableHeader>
                  )}
                  {!hiddenFields.has('pickupDate') && (
                    <TableHeader style={{ minWidth: 180 }}>
                      Pickup Date
                    </TableHeader>
                  )}
                  {!hiddenFields.has('deliveryDate') && (
                    <TableHeader>Delivery Date</TableHeader>
                  )}
                  <TableHeader />
                  <TableHeader>Vehicle</TableHeader>
                  {!hiddenFields.has('vehicleDetails') && (
                    <TableHeader style={{ minWidth: 250 }}>
                      Vehicle Details
                    </TableHeader>
                  )}
                  {!hiddenFields.has('lotNumber') &&
                    (user?.order_form_settings.vehicle.is_lot_number_visible ||
                      hasLotValue) && <TableHeader>Lot #</TableHeader>}
                  {isAdvancedTier && (
                    <TableHeader align="right">Tariff</TableHeader>
                  )}
                  {shipperType === 'BROKER' && (
                    <TableHeader align="right">Carrier Price</TableHeader>
                  )}
                  <TableHeader align="center" />
                </TableRow>
              </TableHead>

              <TableBody>
                {!vehiclesOnlyView ? (
                  <OrderViewRelatedVehicles
                    order={order}
                    loads={loads}
                    hiddenFields={hiddenFields}
                    handleRemoveRelations={handleRemoveRelations}
                  />
                ) : (
                  <OrderViewVehiclesOnly
                    order={order}
                    vehicles={vehicles}
                    hiddenFields={hiddenFields}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>

        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {dynamicColumnsModified.map(({ label, value }) => (
            <MenuItem
              key={label}
              dense={true}
              onClick={() => {
                const checked = hiddenFields.has(value);

                if (checked) {
                  removeHiddenFields(value);
                } else {
                  addHiddenFields(value);
                }

                trackEventLegacy('Toggled vehicle list column', {
                  column: label,
                  visibility: checked ? 'visible' : 'hidden',
                });
              }}
            >
              <ListItemIcon>
                <Checkbox
                  disableRipple={true}
                  checked={!hiddenFields.has(value)}
                />
              </ListItemIcon>

              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </div>
  );
}
