import { ChevronRight } from '@material-ui/icons';
import { Button, Color, useResponsiveValue } from '@superdispatch/ui';
import * as React from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { useOrderRequestCount } from '../data/OrderRequestsAPI';

const StyledButton = styled(Button)`
  min-width: 116px;

  & > span {
    display: flex;
    align-items: center;
  }
`;

const StyledBadge = styled.span<{ isHighlighted?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
  height: 20px;
  min-width: 22px;
  color: ${({ isHighlighted }) => (isHighlighted ? Color.White : '#1B2638')};
  background: ${({ isHighlighted }) =>
    isHighlighted ? Color.Blue300 : '#EAEBEC'};
`;

interface RequestedLoadsButtonProps {
  order: Order;
  onClick: () => void;
  revalidate?: boolean;
}

export const RequestedLoadsButton: React.ComponentType<RequestedLoadsButtonProps> =
  ({ order, onClick, revalidate }) => {
    const { data } = useOrderRequestCount(order.guid, revalidate);
    const { user } = useUserState();
    const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
    const showButton =
      order.is_active &&
      !order.is_archived &&
      user?.shipper.shipper_type === 'BROKER';

    return !showButton ? null : (
      <StyledButton
        color="primary"
        variant="outlined"
        size={platform === 'mobile' ? 'small' : 'medium'}
        onClick={onClick}
        data-intercom-target={
          data && data.load_request_count > 0 ? 'order requests' : undefined
        }
      >
        {data && data.load_request_count > 0 && (
          <StyledBadge isHighlighted={data.has_new_load_request}>
            {data.load_request_count}
          </StyledBadge>
        )}
        Requests
        <ChevronRight color="action" />
      </StyledButton>
    );
  };
