import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ArrowUpIcon = createSvgIcon(
  'ArrowUpIcon',
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 11.0868L15.8041 12.3044L12.8379 9.26836L12.8379 18H11.1621L11.1621 9.26836L8.19591 12.3044L7 11.0868L12.0039 6L17 11.0868Z"
      fill={Color.Dark500}
    />
  </svg>,
);
