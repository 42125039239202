import { Divider, MenuItem, Popover } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import {
  usePrivateNetworkAPIs,
  usePrivateNetworkGroups,
} from 'manage-carriers/data/PrivateNetworkAPI';
import { formatGroupNames } from 'manage-carriers/data/PrivateNetworkDTO';
import { useEffect, useMemo, useState } from 'react';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArray';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';

interface AddPrivateNetworkGroupFormProps {
  carrierGuids: string[];
  selectedGroupGuid?: string;
  onSubmitSuccess: () => void;
  onSubmitting: (value: boolean) => void;
}

export function AddPrivateNetworkGroupForm({
  carrierGuids,
  onSubmitSuccess,
  onSubmitting,
  selectedGroupGuid,
}: AddPrivateNetworkGroupFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data } = usePrivateNetworkGroups();
  const { addCarrierToPrivateNetworkGroups } = usePrivateNetworkAPIs();

  const formik = useFormikEnhanced({
    initialValues: { groups: [] },
    onSubmit({ groups }) {
      if (groups.length) {
        return addCarrierToPrivateNetworkGroups(carrierGuids, groups);
      }

      return Promise.reject(new Error('Select at least one option'));
    },
    onSubmitSuccess(_, { groups }) {
      const names = formatGroupNames(groups, data?.groups)
        .filter(Boolean)
        .map((x) => `'${x || ''}'`)
        .join(', ');

      addSnackbar(
        `${carrierGuids.length} ${formatPlural(
          carrierGuids.length,
          'carrier',
          'carriers',
        )} added to ${formatPlural(
          groups.length,
          `'${names}' group`,
          `${groups.length} groups`,
        )}`,
      );
      trackEvent('Shipper Added Carrier to Private Network & Groups', {
        carrier_count: carrierGuids.length,
        group_count: groups.length,
      });
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  useEffect(() => {
    onSubmitting(formik.isSubmitting);
  }, [formik.isSubmitting, onSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box paddingTop="xsmall">
          <FormikCheckboxArrayGroup name="groups">
            {data?.groups
              .filter((group) => group.guid !== selectedGroupGuid)
              .map(({ guid, name }) => (
                <MenuItem key={guid} component="label">
                  <FormikCheckboxArrayField
                    name={guid}
                    label={name}
                    disabled={formik.isSubmitting}
                  />
                </MenuItem>
              ))}

            <Divider />

            <MenuItem button={false}>
              <Columns>
                <Column width="fluid" />
                <Column width="content">
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={!formik.dirty}
                    isLoading={formik.isSubmitting}
                    data-intercom-target={formik.dirty ? 'Save' : undefined}
                  >
                    Save
                  </Button>
                </Column>
              </Columns>
            </MenuItem>
          </FormikCheckboxArrayGroup>
        </Box>
      </Form>
    </FormikProvider>
  );
}

interface AddPrivateNetworkGroupButtonProps {
  carrierGuids: string[];
  selectedGroupGuid?: string;
  onSubmitSuccess: () => void;
}

export function AddPrivateNetworkGroupButton({
  carrierGuids,
  onSubmitSuccess,
  selectedGroupGuid,
}: AddPrivateNetworkGroupButtonProps) {
  const { data } = usePrivateNetworkGroups();
  const [isSubmitting, setSubmitting] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const availableGroups = useMemo(
    () => data?.groups.filter((x) => x.guid !== selectedGroupGuid),
    [data, selectedGroupGuid],
  );
  return (
    <>
      <Button
        disabled={!availableGroups?.length}
        onClick={(event) => {
          setAnchor(event.currentTarget);
        }}
      >
        Add to Group
      </Button>

      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => {
          if (!isSubmitting) {
            setAnchor(null);
          }
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 0,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AddPrivateNetworkGroupForm
          selectedGroupGuid={selectedGroupGuid}
          carrierGuids={carrierGuids}
          onSubmitting={setSubmitting}
          onSubmitSuccess={() => {
            setAnchor(null);
            onSubmitSuccess();
          }}
        />
      </Popover>
    </>
  );
}
