import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { ExitTransitionPlaceholder, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useBulkOrderActionAPI } from 'orders/data/OrderActionAPI';
import { useEffect, useMemo } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { SuperPayNowPreview } from './SuperPayNowPreview';

interface BulkSuperPayNowDialogProps {
  orders?: Order[];
  onClose: () => void;
  onSubmitSuccess: () => void;
}
export function BulkSuperPayNowDialog({
  orders,
  onClose,
  onSubmitSuccess,
}: BulkSuperPayNowDialogProps) {
  const open = !!orders && orders.length > 0;
  return (
    <Dialog open={open} onClose={onClose}>
      <Box width={['auto', '400px']}>
        <ExitTransitionPlaceholder in={open}>
          {open && (
            <BulkSuperPayNowDialogContent
              orders={orders}
              onClose={onClose}
              onSubmitSucess={onSubmitSuccess}
            />
          )}
        </ExitTransitionPlaceholder>
      </Box>
    </Dialog>
  );
}

interface BulkSuperPayNowDialogContentProps {
  orders: Order[];
  onClose: () => void;
  onSubmitSucess: () => void;
}
function BulkSuperPayNowDialogContent({
  orders,
  onClose,
  onSubmitSucess,
}: BulkSuperPayNowDialogContentProps) {
  const { bulkSuperPayNow } = useBulkOrderActionAPI();
  const { addSnackbar } = useSnackbarStack();

  const nonSuperPayOrdersCount = useMemo(
    () => orders.filter((order) => order.payment?.method !== 'superpay').length,
    [orders],
  );

  useEffect(() => {
    trackEvent('Shipper Bulk Opened SuperPay Now');
  }, []);

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      const ids = orders.map((x) => x.id);
      return bulkSuperPayNow(ids);
    },
    onSubmitSuccess: () => {
      addSnackbar('Orders updated', { variant: 'success' });
      trackEvent('Shipper Bulk SuperPaid Now', {
        order_count: orders.length,
        change_to_SuperPay: nonSuperPayOrdersCount,
      });
      onSubmitSucess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form aria-label="superpay now dialog form">
        <DialogTitle>
          <Typography variant="h3">SuperPay Now</Typography>
        </DialogTitle>

        <SuperPayNowPreview
          orders={orders}
          nonSuperPayOrdersCount={nonSuperPayOrdersCount}
        />

        <DialogActions>
          <Button
            variant="neutral"
            onClick={() => {
              trackEvent('Shipper Canceled Bulk SuperPay Now Dialog');
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button type="submit" variant="primary" pending={formik.isSubmitting}>
            SuperPay Now
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
