import { Switch, Typography } from '@material-ui/core';
import { Inline, Tag } from '@superdispatch/ui';
import { useBillingPeriod } from './useBillingPeriod';

export function BillingPeriodToggle() {
  const [billingPeriod, toggleBillingPeriod] = useBillingPeriod();

  return (
    <Inline verticalAlign="center" horizontalAlign="center">
      <Inline verticalAlign="center" space="none">
        <Typography variant={billingPeriod === 'monthly' ? 'body1' : 'body2'}>
          Billed Monthly
        </Typography>

        <Switch
          checked={billingPeriod === 'anually'}
          onChange={toggleBillingPeriod}
        />

        <Typography variant={billingPeriod === 'monthly' ? 'body2' : 'body1'}>
          Billed Anually
        </Typography>
      </Inline>

      <Tag variant="bold" color="green">
        Save up to 20%
      </Tag>
    </Inline>
  );
}
