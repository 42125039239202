import { Typography } from '@material-ui/core';
import { formatDateType } from '@superdispatch/sdk';
import Order from 'shared/types/order';
import { BulletTextItem } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';

interface OrderListCarrierDateProps {
  type: 'pickup' | 'delivery';
  order: Order;
}

export function OrderListCarrierDate({
  type,
  order,
}: OrderListCarrierDateProps) {
  const step = order[type];

  return (
    <div>
      <BulletTextItem
        primary={
          step?.date_type !== 'estimated' ? (
            <DetailedFormattedDate
              variant="ShortDate"
              date={step?.scheduled_at}
            />
          ) : (
            <DetailedFormattedDateRange
              range={[step.scheduled_at, step.scheduled_ends_at]}
            />
          )
        }
        secondary={
          step?.date_type && (
            <Typography color="textSecondary">
              {formatDateType(step.date_type)}
            </Typography>
          )
        }
      />

      {step?.scheduled_at_by_carrier && (
        <BulletTextItem
          secondary={
            <Typography color="textSecondary" noWrap={true}>
              Carrier Updated
            </Typography>
          }
          primary={
            <DetailedFormattedDate
              variant="ShortDate"
              date={step.scheduled_at_by_carrier}
            />
          }
        />
      )}
    </div>
  );
}
