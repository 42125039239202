import { DateString } from '@superdispatch/dates';
import { Expose } from 'class-transformer';

/** @deprecated */
export interface Attachment {
  id: number;
  is_active: boolean;
  changed_at: string;
  created_at: string;
  name: string;
  guid: string;
  is_private: boolean;
  private: boolean;
  is_read_only: boolean;
  is_shared_with_carrier: boolean;
  is_shared_with_customer: boolean;
  shared_by: 'CARRIER' | 'CUSTOMER';
  changed?: boolean;
  url: string;
}

export class AttachmentDTO {
  @Expose() id: number;
  @Expose() is_active: boolean;
  @Expose() changed_at: DateString;
  @Expose() created_at: DateString;
  @Expose() name: string;
  @Expose() guid: string;
  @Expose() is_private: boolean;
  @Expose() private: boolean;
  @Expose() is_read_only: boolean;
  @Expose() is_shared_with_carrier: boolean;
  @Expose() is_shared_with_customer: boolean;
  @Expose() shared_by: 'CARRIER' | 'CUSTOMER';
  @Expose() url: string;
}

/** @deprecated */
export type AttachmentWithFile = { file?: File } & Partial<Attachment>;
