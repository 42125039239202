import { FormControlLabel, Link, Radio } from '@material-ui/core';
import {
  FormikDateField,
  FormikRadioGroupField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useState } from 'react';
import { getInsuranceTypeLabel } from 'shipper-profile/data/ShipperProfileUtils';
import { INSURANCE_TYPE } from 'shipper-profile/data/VerificationApplicationDTO';
import { FormikFileField } from '../../shared/form/FormikFileField';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { InsuranceHelpDrawer } from './InsuranceHelpDrawer';

export function InsuranceForm() {
  const [currentDrawer, setCurrentDrawer] = useState<
    'insurance_help' | undefined
  >();

  return (
    <Stack space="small">
      <InsuranceHelpDrawer
        open={currentDrawer === 'insurance_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      <FormikRadioGroupField
        label="Insurance type"
        name="company_insurance_type"
      >
        {INSURANCE_TYPE.map((insuranceType) => (
          <FormControlLabel
            key={insuranceType}
            value={insuranceType}
            control={<Radio />}
            label={getInsuranceTypeLabel(insuranceType)}
          />
        ))}
      </FormikRadioGroupField>

      <FormikFileField
        label="Proof of Bond/Insurance"
        name="insurance_certificate_url"
        helperText={
          <Link
            color="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Learn More',
                utm_content: 'Certificate of Insurance',
              });
              setCurrentDrawer('insurance_help');
            }}
          >
            Learn more
          </Link>
        }
      />
      <FormikDateField
        label="Expiration Date"
        name="bond_expiration_date"
        format="DateISO"
        enableClearable={true}
        fullWidth={true}
      />
      <FormikTextField
        label="Bond/Insurance Holder Name"
        name="bond_company_name"
        fullWidth={true}
      />

      <FormikTextField
        label="Agent Name"
        name="bond_agent_name"
        fullWidth={true}
      />
      <SuspendedFormikPhoneField
        name="bond_agent_phone"
        label="Agent Phone Number"
      />
    </Stack>
  );
}
