import { Popper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import { useMemo } from 'react';
import styled from 'styled-components';
import { VehicleMake, VehicleModel } from '../data/OrderFormVehicleDTO';

interface VehicleBaseAutocompleteProps {
  name: string;
  disabled?: boolean;
  options: VehicleMake[] | VehicleModel[];
  listBoxLabel: string;
  setInputValue: (value: string) => void;
  onChangeProp?: (newValue: VehicleMake | VehicleModel | string) => void;
}

const StyledTextField = styled(TextField)`
  max-width: 200px;

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 30px;
  }
`;

const StyledPopper = styled(Popper)`
  & .MuiAutocomplete-paper {
    border: none;
  }
`;

export function VehicleBaseAutocomplete({
  name,
  disabled,
  options,
  listBoxLabel,
  setInputValue,
  onChangeProp,
}: VehicleBaseAutocompleteProps) {
  const [field] = useField(name);
  const { onBlur, value } = field;
  const inputValue = useMemo(() => value || '', [value]);

  return (
    <Autocomplete
      value={inputValue}
      disabled={disabled}
      ListboxProps={{ 'aria-label': listBoxLabel }}
      PopperComponent={options.length > 0 ? Popper : StyledPopper}
      autoComplete={true}
      freeSolo={true}
      includeInputInList={true}
      filterSelectedOptions={true}
      filterOptions={(x) => x}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      options={options}
      onBlur={onBlur}
      onChange={(_event, newValue) => {
        if (newValue) {
          onChangeProp?.(newValue);
        }
      }}
      onInputChange={(_event, newInputValue) => {
        onChangeProp?.(newInputValue);
        setInputValue(newInputValue);
      }}
      renderOption={(option: VehicleMake) => option.display_name || option.name}
      renderInput={(props) => <StyledTextField {...props} />}
    />
  );
}
