import { Divider } from '@material-ui/core';
import {
  DrawerActions,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const MobileDrawerActions = styled(DrawerActions)`
  position: absolute;
  margin-bottom: 14px;
  bottom: 0;
`;

export interface EditDrawerActionsProps {
  primaryActionDisabled?: boolean;
  primaryActionText?: string;
  onPrimaryAction?: () => void;

  secondaryActionDisabled?: boolean;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
}

export function EditDrawerActions({
  primaryActionDisabled,
  primaryActionText,
  onPrimaryAction,

  secondaryActionDisabled,
  secondaryActionText,
  onSecondaryAction,
}: EditDrawerActionsProps) {
  const isMobile = useResponsiveValue(true, false);

  const primaryButtonType = onPrimaryAction ? 'button' : 'submit';
  const secondaryButtonType = onSecondaryAction ? 'button' : 'submit';

  if (isMobile) {
    return (
      <MobileDrawerActions>
        <Stack space="small">
          <Divider />

          <Button
            type={primaryButtonType}
            disabled={primaryActionDisabled}
            fullWidth={true}
            onClick={onPrimaryAction}
          >
            {primaryActionText}
          </Button>

          {secondaryActionText && (
            <Button
              type={secondaryButtonType}
              disabled={secondaryActionDisabled}
              fullWidth={true}
              variant="neutral"
              onClick={onSecondaryAction}
            >
              {secondaryActionText}
            </Button>
          )}
        </Stack>
      </MobileDrawerActions>
    );
  }

  return (
    <DrawerActions>
      <Inline space="small">
        <Button
          type={primaryButtonType}
          disabled={primaryActionDisabled}
          onClick={onPrimaryAction}
        >
          {primaryActionText}
        </Button>

        {secondaryActionText && (
          <Button
            type={secondaryButtonType}
            disabled={secondaryActionDisabled}
            onClick={onSecondaryAction}
            variant="neutral"
          >
            {secondaryActionText}
          </Button>
        )}
      </Inline>
    </DrawerActions>
  );
}
