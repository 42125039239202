import { IconButton as MuiIconButton } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import johnPhoto from '../assets/john.png';
import lisaPhoto from '../assets/lisa.png';
import meredithPhoto from '../assets/meredith.png';

const comments = [
  {
    photo: (
      <img
        src={meredithPhoto}
        alt="Meredith Jones"
        width={48}
        height={48}
        loading="lazy"
      />
    ),
    name: 'Meredith Jones',
    company: 'Flexco',
    comment:
      '“I took a look at our 3 biggest processes and broke down the time we were spending on the other load board compared to using Super Dispatch. And just on those three processes, we are saving 17 hours a week”',
  },
  {
    photo: (
      <img
        src={lisaPhoto}
        alt="Lisa Meinen Doerksen"
        width={48}
        height={48}
        loading="lazy"
      />
    ),
    name: 'Lisa Meinen Doerksen',
    company: 'Jack Cooper',
    comment:
      '“The ability to increase our volume through integrating our systems with the TMS has been super helpful. Super Dispatch has doubled our productivity”',
  },
  {
    photo: (
      <img
        src={johnPhoto}
        alt="John Robertson"
        width={48}
        height={48}
        loading="lazy"
      />
    ),
    name: 'John Robertson',
    company: 'SYCN',
    comment:
      '“We find and grow our Carrier network on the Super Loadboard and we recommend it to all the Carriers we know.',
  },
];

const ActiveTab = styled(Box)`
  width: 12px;
  height: 3px;
  border-radius: 3px;
  background-color: ${Color.Blue300};
`;

const Tab = styled(Box)`
  width: 6px;
  height: 3px;
  border-radius: 3px;
  background-color: ${Color.Dark500};
  opacity: 0.3;
`;

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

export function PaywallCustomerComments() {
  const [currentCommentId, setCurrentCommentId] = useState(0);
  const currentComment = useMemo(
    () => comments[currentCommentId],
    [currentCommentId],
  );

  return (
    <Stack space="small">
      <Box>
        <Columns align="top" space="xsmall">
          <Column width="content">
            <Inline horizontalAlign="right">{currentComment?.photo}</Inline>
          </Column>
          <Column>
            <Stack space="xxsmall">
              <TextBox variant="heading-5">{currentComment?.comment}</TextBox>
              <Inline>
                <TextBox variant="body">{currentComment?.name},</TextBox>
                <TextBox variant="caption" color="secondary">
                  {currentComment?.company}
                </TextBox>
              </Inline>
            </Stack>
          </Column>
        </Columns>
      </Box>

      <Columns>
        <Column width="content">
          <Box width="56px" />
        </Column>
        <Column width="fluid">
          <Inline space="xxsmall">
            {comments.map((comment, index) => (
              <IconButton
                aria-label={comment.name}
                key={index}
                onClick={() => {
                  setCurrentCommentId(index);
                }}
              >
                {index === currentCommentId ? <ActiveTab /> : <Tab />}
              </IconButton>
            ))}
          </Inline>
        </Column>
      </Columns>
    </Stack>
  );
}
