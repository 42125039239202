import { Dialog, Paper } from '@material-ui/core';
import { CloseSharp as CloseSharpIcon } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { lazy, Suspense } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import styled from 'styled-components';

const YouTubeEmbedLazy = lazy(() => import('shared/ui/YouTubeEmbed'));

const YouTubeEmbedLoadingWrapper = styled.div`
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledYouTubeEmbed = styled(YouTubeEmbedLazy)`
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const StyledDialogPaper = styled(Paper)`
  overflow: visible;
  max-width: 840px;
  padding: 4px;
  border-radius: 4px;
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: -64px;
  right: -8px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  color: ${Color.White};
  cursor: pointer;
`;

const StyledCloseButtonIcon = styled(CloseSharpIcon)`
  font-size: 64px;
`;

export function OrdersEmptyStateWatchDemoButton() {
  const [isDialogOpen, handleOpen, handleClose] = useBoolean(false);

  return (
    <>
      <Button size="large" variant="text" onClick={handleOpen}>
        Watch Demo Video
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth={true}
        PaperComponent={StyledDialogPaper}
      >
        <Suspense
          fallback={
            <YouTubeEmbedLoadingWrapper>
              <LoadingContainer />
            </YouTubeEmbedLoadingWrapper>
          }
        >
          <StyledYouTubeEmbed
            videoId="vAqelNv3o98"
            title="Unlock Auto Transport Efficiency: On-Demand Webinar on Mastering Shipper TMS Express"
            onPlayOnce={() => {
              trackEvent('Shipper Watched the Demo Video', {
                utm_medium: 'All Orders Page',
              });
            }}
          />
        </Suspense>

        <StyledCloseButton>
          <StyledCloseButtonIcon onClick={handleClose} />
        </StyledCloseButton>
      </Dialog>
    </>
  );
}
