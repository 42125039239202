import { Link } from '@material-ui/core';
import { FunctionComponent } from 'react';
import Order from 'shared/types/order';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';

export interface CustomerInvoiceProps {
  order: Order;
}

const renderInvoiceLink = (
  invoice: Order['customer_invoice'],
  invoiceUrl?: string,
) => {
  return invoice?.sent_date ? (
    <div key={invoice.sent_date}>
      <Link href={invoiceUrl} target="_blank" rel="noreferrer">
        <DetailedFormattedDate date={invoice.sent_date} />
      </Link>
    </div>
  ) : null;
};

export const OrderViewCustomerInvoice: FunctionComponent<CustomerInvoiceProps> =
  ({ order }) => {
    const { customer_invoice, customer_invoices } = order;

    return customer_invoices && Object.keys(customer_invoices).length === 0 ? (
      renderInvoiceLink(
        customer_invoice,
        `/orders/preview/customer_invoice/${order.id}`,
      )
    ) : customer_invoices ? (
      <>
        {customer_invoices.map((invoice) =>
          renderInvoiceLink(invoice, invoice.file_url),
        )}
      </>
    ) : null;
  };
