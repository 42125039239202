import { Box, Typography } from '@material-ui/core';
import { Inline, InlineProps } from '@superdispatch/ui';
import { Children, Fragment, ReactNode } from 'react';

interface BulletTextItemProps {
  primary: ReactNode;
  secondary: ReactNode;
}

/** @deprecated */
export function BulletTextItem({ primary, secondary }: BulletTextItemProps) {
  return (
    <Box display="flex">
      <div>{primary}</div>
      {secondary && (
        <>
          <Box paddingX="4px">
            <Typography color="textSecondary">·</Typography>
          </Box>
          <div>
            <Typography variant="caption" color="textSecondary">
              {secondary}
            </Typography>
          </div>
        </>
      )}
    </Box>
  );
}

export function InlineBulletItems({ children, ...props }: InlineProps) {
  const items = Children.toArray(children).filter(Boolean);
  return (
    <Inline space="xxsmall" {...props}>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item}
          {index !== items.length - 1 && '·'}
        </Fragment>
      ))}
    </Inline>
  );
}
