import { Dialog } from '@material-ui/core';
import { ExitTransitionPlaceholder } from '@superdispatch/ui';
import { PrivateNetworkGroupDTO } from '../data/PrivateNetworkDTO';
import { PrivateNetworkDeleteGroupDialogContent } from './PrivateNetworkDeleteGroupDialogContent';

export interface PrivateNetworkDeleteGroupDialogProps {
  open: boolean;
  group: PrivateNetworkGroupDTO | undefined;
  onClose: () => void;
}

export function PrivateNetworkDeleteGroupDialog({
  open,
  group,
  onClose,
}: PrivateNetworkDeleteGroupDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <ExitTransitionPlaceholder in={!!group}>
        {group && (
          <PrivateNetworkDeleteGroupDialogContent
            onClose={onClose}
            group={group}
          />
        )}
      </ExitTransitionPlaceholder>
    </Dialog>
  );
}
