import { Link } from '@material-ui/core';
import { useNotificationSettings } from 'notification-settings/data/NotificationSettingsAPI';
import { Link as RouterLink } from 'react-router-dom';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';

export function SMSNotificationLink() {
  const { data: notificationSettings } = useNotificationSettings(false);
  return !notificationSettings?.sms_notification_phone_number ? (
    <Link
      component={RouterLink}
      to="/notification-settings"
      color="textPrimary"
      target="_blank"
      onClick={() => {
        trackEventLegacy(
          'Clicked Enable SMS notification link in Requests Drawer',
        );
      }}
    >
      <span role="img" aria-label="enable sms updates icon">
        🔔
      </span>{' '}
      Enable SMS updates
    </Link>
  ) : null;
}
