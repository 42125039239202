import { parseDate } from '@superdispatch/dates';
import { Button, DrawerActions, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import {
  PriceNegotiationDTO,
  PriceNegotiationFormDTO,
  priceNegotiationFormSchema,
} from 'orders/data/dto/PriceNegotiationDTO';
import { usePriceNegotiationAPI } from 'orders/data/PriceNegotiationAPI';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackOderActionEvent } from '../OrderActionsAnalytics';
import { PriceNegotiationDrawerContentProps } from './PriceNegotiationDrawerContent';
import { PriceNegotiationDrawerForm } from './PriceNegotiationDrawerForm';
import { PriceNegotiationDrawerSummary } from './PriceNegotiationDrawerSummary';

export function CreatePriceNegotiationDrawerForm({
  order,
  source,
  onSubmitSuccess,
}: PriceNegotiationDrawerContentProps) {
  const { createPriceNegotiation } = usePriceNegotiationAPI();

  const form = useAppFormik<PriceNegotiationFormDTO, PriceNegotiationDTO>({
    validationSchema: priceNegotiationFormSchema,
    initialValues: {
      negotiation_budget: null,
      order_guid: order.guid,
      increase_by_percent: null,
      increase_every: null,
      start_date: parseDate(order.created_at, { format: 'JodaISO' }).startOf(
        'day',
      ),
      acceptable_pickup_days: 0,
      acceptable_delivery_days: 0,
    },
    onSubmit(values) {
      return createPriceNegotiation(values);
    },
    getSuccessMessage: () => 'Price Negotiation is enabled.',
    onSubmitSuccess(response) {
      trackOderActionEvent({
        name: 'Shipper Added Price Negotiation',
        order,
        source,
        priceNegotiation: response,
      });
      onSubmitSuccess(order);
    },
  });

  return (
    <PriceNegotiationDrawerForm
      order={order}
      form={form}
      actions={
        <DrawerActions>
          <Box paddingTop="small" paddingBottom="small">
            <Stack space="large">
              <PriceNegotiationDrawerSummary
                order={order}
                currentValues={form.values}
              />

              <Inline space="small" verticalAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  isLoading={form.isSubmitting}
                  disabled={!form.values.negotiation_budget}
                >
                  Enable Negotiation
                </Button>
              </Inline>
            </Stack>
          </Box>
        </DrawerActions>
      }
    />
  );
}
