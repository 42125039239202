import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import { ConfirmDialog, ConfirmDialogProps } from 'shared/ui/ConfirmDialog';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';

interface RestoreOrderDialogProps extends ConfirmDialogProps {
  onCancel: () => void;
  onSubmit: () => void;
  onSubmitSuccess: () => void;
}

function RestoreOrderDialog({
  open,
  onCancel,
  onSubmit,
  onSubmitSuccess,
  ...props
}: RestoreOrderDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const { isSubmitting, handleSubmit } = useFormikEnhanced({
    key: open,
    initialValues: {},
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmDialog
      {...props}
      open={open}
      onClose={isSubmitting ? undefined : onCancel}
      cancelButtonProps={{ children: 'No', disabled: isSubmitting }}
      confirmButtonProps={{
        children: 'Yes, Restore',
        isLoading: isSubmitting,
        onClick: () => handleSubmit(),
      }}
    />
  );
}

interface BulkRestoreOrderDialogProps {
  orders: Order[] | undefined;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export function BulkRestoreOrderDialog({
  orders,
  onCancel,
  onSubmitSuccess,
}: BulkRestoreOrderDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkRestoreOrders } = useBulkOrderActionAPI();

  return (
    <RestoreOrderDialog
      open={!!orders}
      onCancel={onCancel}
      title={
        orders && `Restore ${formatPlural(orders.length, 'order', 'orders')}?`
      }
      onSubmit={() => {
        if (orders) {
          const ids = orders.map((x) => x.id);
          return bulkRestoreOrders(ids);
        }

        return Promise.reject(new Error('No order found'));
      }}
      onSubmitSuccess={() => {
        if (orders) {
          trackEventLegacy('Bulk Restore', {
            count: orders.length,
          });
          addSnackbar('Order(s) restored', { variant: 'success' });

          onSubmitSuccess();
        }
      }}
    />
  );
}
