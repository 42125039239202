import { useConstant } from '@superdispatch/hooks';
import { useEffect, useMemo } from 'react';

type ChannelName = 'instant-booking' | 'notifications-settings';

export interface BroadcastMessage<T> {
  payload: T;
}

export type BroadcastChannelHandler<T> = (
  message: MessageEvent<BroadcastMessage<T>>,
) => void;

export function useBroadcastChannel<T>(
  channelName: ChannelName,
  handler?: BroadcastChannelHandler<T>,
) {
  const channel = useConstant(() =>
    'BroadcastChannel' in window ? new BroadcastChannel(channelName) : null,
  );

  useEffect(() => {
    if (!handler || !channel) return;

    channel.addEventListener('message', handler);
    return () => channel.removeEventListener('message', handler);
  }, [channel, handler]);

  return useMemo(
    () => ({
      sendBroadcastMessage: (message: T) => channel?.postMessage(message),
    }),
    [channel],
  );
}
