import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import {
  ConfirmPopover,
  ConfirmPopoverProps,
} from 'shared/ui/ConfirmationPopup';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';

interface BulkUnarchivePopoverProps extends Omit<ConfirmPopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
}

export function BulkUnarchivePopover({
  orders,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...props
}: BulkUnarchivePopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkUnarchive } = useBulkOrderActionAPI();

  const { handleSubmit, isSubmitting } = useFormikEnhanced({
    key: !!orders,
    initialValues: {},
    onSubmit() {
      if (orders) {
        const ids = orders.map((x) => x.id);
        return bulkUnarchive(ids);
      }

      return Promise.reject(new Error('Order not selected'));
    },
    onSubmitSuccess() {
      if (orders) {
        addSnackbar('Order(s) unarchived', { variant: 'success' });
        trackEventLegacy('Bulk Unarchive', {
          count: orders.length,
        });
        onSubmitSuccess();
      }
    },
    onSubmitFailure({ message }) {
      addSnackbar(message, { variant: 'error' });
    },
  });
  return (
    <ConfirmPopover
      {...props}
      onClose={isSubmitting ? undefined : onClose}
      anchorEl={anchorEl}
      open={!!orders && !!anchorEl}
      title={`Unarchive ${orders?.length || 0} orders?`}
      cancelButtonProps={{ onClick: onClose, disabled: isSubmitting }}
      confirmButtonProps={{
        children: 'Unarchive',
        isLoading: isSubmitting,
        onClick: () => handleSubmit(),
      }}
    />
  );
}
