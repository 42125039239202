import { Link, Typography } from '@material-ui/core';
import { Column, Columns, InfoCard, Stack, useUID } from '@superdispatch/ui';
import { useMemo } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { stringifySearchQuery } from 'shared/helpers/RouteHelpers';
import { DictionaryCarrierDTO } from '../../core/dictionary/data/DictionaryDTO';

interface CarrierDispatchedProps {
  carrier: DictionaryCarrierDTO;
  totalDispatched: number;
}

export function CarrierDispatched({
  carrier,
  totalDispatched,
}: CarrierDispatchedProps) {
  const query = useMemo(
    () =>
      stringifySearchQuery({
        filters: [
          {
            type: 'statuses',
            value: ['accepted', 'picked_up', 'delivered', 'invoiced', 'paid'],
          },
          {
            type: 'carriers',
            value: [carrier],
          },
        ],
      }),
    [carrier],
  );

  const uid = useUID();
  return (
    <InfoCard size="large">
      <Stack space="small">
        <Typography id={uid} variant="h3">
          Dispatched by you
        </Typography>

        <Columns align="center">
          <Column width="fluid">
            <Typography aria-labelledby={uid} color="textSecondary">
              {totalDispatched || 0}{' '}
              {formatPlural(totalDispatched || 0, 'order', 'orders')}
            </Typography>
          </Column>
          <Column width="content">
            <Link
              href={`/orders?${query}`}
              onClick={() => {
                trackEventLegacy('Clicked on Total Orders Dispatched by You', {
                  utm_medium: 'carrier_profile',
                });
              }}
              color="textPrimary"
              target="_blank"
              rel="noreferrer"
            >
              View
            </Link>
          </Column>
        </Columns>
      </Stack>
    </InfoCard>
  );
}
