import { createSvgIcon } from './IconUtils';

export const RecommendedPriceIcon = createSvgIcon(
  'RecommendedPriceIcon',
  <>
    <path
      d="M17.5002 6.66675C16.2918 6.66675 15.6168 7.86675 15.8918 8.75841L12.9335 11.7251C12.6835 11.6501 12.3168 11.6501 12.0668 11.7251L9.94183 9.60008C10.2252 8.70842 9.55016 7.50008 8.3335 7.50008C7.12516 7.50008 6.44183 8.70008 6.72516 9.60008L2.92516 13.3917C2.0335 13.1167 0.833496 13.7917 0.833496 15.0001C0.833496 15.9167 1.5835 16.6667 2.50016 16.6667C3.7085 16.6667 4.3835 15.4667 4.1085 14.5751L7.90016 10.7751C8.15016 10.8501 8.51683 10.8501 8.76683 10.7751L10.8918 12.9001C10.6085 13.7917 11.2835 15.0001 12.5002 15.0001C13.7085 15.0001 14.3918 13.8001 14.1085 12.9001L17.0752 9.94175C17.9668 10.2167 19.1668 9.54175 19.1668 8.33341C19.1668 7.41675 18.4168 6.66675 17.5002 6.66675Z"
      fill="currentColor"
    />
    <path
      d="M12.5 7.5L13.2833 5.775L15 5L13.2833 4.225L12.5 2.5L11.7333 4.225L10 5L11.7333 5.775L12.5 7.5Z"
      fill="currentColor"
    />
    <path
      d="M2.91683 9.16667L3.3335 7.5L5.00016 7.08333L3.3335 6.66667L2.91683 5L2.50016 6.66667L0.833496 7.08333L2.50016 7.5L2.91683 9.16667Z"
      fill="currentColor"
    />
  </>,
  { viewBox: '0 0 20 20', color: 'action' },
);
