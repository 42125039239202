import { parseDate } from '@superdispatch/dates';
import { DateTime } from 'luxon';
import { Order } from 'shared/types/order';
import {
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupOmit,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, NumberSchema } from 'yup';

export type PriceNegotiationDTO = InferType<typeof priceNegotiationSchema>;
export const priceNegotiationSchema = yupObject({
  guid: yupString(),
  order_guid: yupString(),
  active: yupBoolean(),
  start_date: yupDateString('JodaISO'),
  acceptable_pickup_days: yupNumber().default(0),
  acceptable_delivery_days: yupNumber().default(0),
  negotiation_budget: yupNumber()
    .required('Enter negotiation budget')
    .min(1, 'No less than $1'),
  increase_by_percent: yupNumber()
    .min(1, 'No less than 1%')
    .max(100, 'No more than 100%')
    .when('increase_every', {
      is: (item) => item != null,
      then: (schema: NumberSchema) => schema.required('Enter percentage'),
    }),
  increase_every: yupObject({
    value: yupNumber()
      .required('Enter days')
      .min(1, 'No less than 1 day')
      .max(30, 'No more than 30 days'),
    unit: yupEnum(['DAYS'], 'DAYS'),
  }).nullable(true),
});

export type PriceNegotiationFormDTO = InferType<
  typeof priceNegotiationFormSchema
>;
export const priceNegotiationFormSchema = priceNegotiationSchema.shape({
  guid: yupOmit,
  active: yupOmit,
});

export function calculatePriceNegotiationInfo(
  price: number | undefined,
  {
    start_date,
    increase_by_percent,
    increase_every,
    negotiation_budget,
  }: Pick<
    PriceNegotiationDTO,
    | 'start_date'
    | 'increase_by_percent'
    | 'increase_every'
    | 'negotiation_budget'
  >,
) {
  const startDate = parseDate(start_date, { format: 'JodaISO' });
  const increaseByDays = increase_every?.value || 1;
  const increaseByPercentage = increase_by_percent || 100;

  const maxPrice = Number(price) + negotiation_budget;
  const maxIteration = Math.ceil(100 / increaseByPercentage);
  const daysSinceStarted = DateTime.local().diff(startDate, 'days').days;
  const currentIteration =
    increase_by_percent == null
      ? 1 // without gradual increase
      : daysSinceStarted < 0
      ? 0 // future start date
      : Math.ceil(daysSinceStarted / increaseByDays);

  const calcNthPrice = (iteration: number) => {
    const increaseRate = (increaseByPercentage / 100) * negotiation_budget;

    if (!Number.isFinite(negotiation_budget)) {
      return NaN;
    }

    if (iteration < 1) {
      return Number(price);
    }

    if (iteration >= maxIteration) {
      return maxPrice;
    }

    return Number(price) + iteration * increaseRate;
  };

  const calcNthDay = (iteration: number) => {
    if (iteration <= 1) {
      return iteration;
    }

    return (iteration - 1) * increaseByDays + 1;
  };

  const calcNthDate = (iteration: number): DateTime => {
    if (iteration < 1) {
      return DateTime.invalid('Price negotiation in the future');
    }

    if (iteration === 1) {
      return startDate;
    }

    return startDate.plus({ days: (iteration - 1) * increaseByDays });
  };

  return {
    calcNthPrice,
    calcNthDay,
    calcNthDate,

    currentIteration,

    maxPrice,
    maxIteration,
    maxDay: calcNthDay(maxIteration),
    maxDate: calcNthDate(maxIteration),

    startDate,
    startDay: 1,
    startPrice: calcNthPrice(1),

    currentDay: Math.ceil(daysSinceStarted),
    currentDate: DateTime.local(),
    currentPrice: calcNthPrice(currentIteration),
  };
}

export function isPriceNegotiationAvailable(order: Order) {
  const allowedStatuses = [
    'new',
    'pending',
    'canceled',
    'declined',
    'order_canceled',
  ];

  if (order.is_archived) {
    return false;
  }

  if (allowedStatuses.includes(order.status)) {
    return true;
  }

  if (order.is_posted_to_loadboard) {
    return true;
  }

  return false;
}
