import { Avatar, Card, CardContent, Typography } from '@material-ui/core';
import { Business, Loop } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { SuperPaySettingsDTO } from '../data/SuperPayDTO';
import { VerificationAccountStatusTag } from './VerificationStatusTag';

const BankAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  background: ${Color.Silver200};
`;

export function BusinessAccountInformation({
  superPaySettings,
}: {
  superPaySettings: SuperPaySettingsDTO;
}) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Card square={isMobile} aria-label="business account">
      <CardContent>
        <Stack space="small">
          <Typography variant="h3">Business Account</Typography>
          <Columns align="center">
            <Column>
              <Inline verticalAlign="center" space="small">
                <BankAvatar>
                  <Business color="action" />
                </BankAvatar>
                <Typography variant="h3">
                  {superPaySettings.legal_business_name}
                </Typography>
              </Inline>
            </Column>
            <Column width="content">
              <VerificationAccountStatusTag status={superPaySettings.status} />
            </Column>
          </Columns>
          {superPaySettings.status !== 'VERIFIED' && (
            <Box
              padding="small"
              backgroundColor="Silver200"
              borderRadius="small"
            >
              <Columns space="xsmall">
                <Column width="content">
                  <Box top="2px" position="relative">
                    <Loop fontSize="small" htmlColor={Color.Dark300} />
                  </Box>
                </Column>
                <Column>
                  <Typography>
                    It may take up to 3 business days to review your business
                    details.
                  </Typography>
                </Column>
              </Columns>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
