export interface Coords {
  latitude?: number | null;
  longitude?: number | null;
}

export const getGooglePathURL = (origin: Coords, destination: Coords) => {
  const { latitude: lat1, longitude: lng1 } = origin;
  const { latitude: lat2, longitude: lng2 } = destination;

  if (lat1 == null || lng1 == null || lat2 == null || lng2 == null) {
    return null;
  }

  return `https://www.google.com/maps/dir/?api=1&origin=${lat1 || ''},${
    lng1 || ''
  }&destination=${lat2 || ''},${lng2 || ''}&travelmode=driving`;
};
