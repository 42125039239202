import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export const sendResetPasswordLinkSchema = yupObject({
  username: yupString().ensure().email().required(),
});

export const resetPasswordSchema = yupObject({
  password: yupString().required(),
});

export type SendResetPasswordLinkDTO = InferType<
  typeof sendResetPasswordLinkSchema
>;
export type ResetPasswordDTO = InferType<typeof resetPasswordSchema>;
