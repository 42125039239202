import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { Box, Flex, FlexProps } from '@rebass/grid';
import { Color, Inline, Stack, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const DefinitionList = styled(Flex)(({ hidden }) => ({
  background: Color.White,
  display: hidden ? 'none' : undefined,
}));

export const DefinitionListLabel = styled(Typography).attrs({
  variant: 'body2',
  component: 'div',
})`
  color: ${Color.Grey300};
`;

export const DefinitionListValue = styled(Typography).attrs({
  variant: 'body2',
  color: 'textPrimary',
  component: 'div',
})``;

const DefinitionListGuide = styled(Box)<{ guideHeight?: string }>`
  display: block;
  height: ${({ guideHeight }) => (guideHeight ? guideHeight : '16px')};
  border-bottom: 1px dotted
    ${({ hidden }) => (hidden ? 'transparent' : Color.Silver400)};
`;

interface DefinitionListItemProps extends Omit<FlexProps, 'label' | 'value'> {
  label: React.ReactNode;
  value: React.ReactNode;
  align?: TypographyProps['align'];
  withGuide?: boolean;
  guideHeight?: string;
}

export const DefinitionListItem: React.ComponentType<DefinitionListItemProps> =
  ({
    label,
    value,
    align,
    hidden,
    withGuide = true,
    flexDirection = 'column',
    guideHeight,
    ...props
  }) => {
    const uid = useUID();

    if (hidden) {
      return null;
    }

    return (
      <Flex flexDirection={flexDirection} {...props}>
        <Box mb={flexDirection === 'column' ? 1 : 0}>
          <DefinitionListLabel id={uid} align={align}>
            {label}
          </DefinitionListLabel>
        </Box>

        {flexDirection === 'row' && (
          <DefinitionListGuide
            flex={1}
            mx={2}
            hidden={!withGuide}
            guideHeight={guideHeight}
          />
        )}

        <Box>
          <DefinitionListValue
            aria-labelledby={uid}
            align={align || (flexDirection === 'row' ? 'right' : 'inherit')}
          >
            {value}
          </DefinitionListValue>
        </Box>
      </Flex>
    );
  };

// Use @superdispatch/ui-lab/DescriptionLineItem
export const DefinitionListColumnLegacy: React.ComponentType<DefinitionListItemProps> =
  (props) => <DefinitionListItem flexDirection="column" {...props} />;

// Use @superdispatch/ui-lab/DescriptionLineItem
export const DefinitionListRowLegacy: React.ComponentType<DefinitionListItemProps> =
  (props) => <DefinitionListItem flexDirection="row" {...props} />;

interface DescriptionStackItemProps {
  label: ReactNode;
  children: ReactNode;
  icon?: ReactNode;
}

export function DescriptionStackItem({
  label,
  children,
  icon,
}: DescriptionStackItemProps) {
  const uid = useUID();
  return (
    <Stack space={['xsmall', 'xxsmall']}>
      <Inline verticalAlign="center">
        <TextBox id={uid} color="secondary">
          {label}
        </TextBox>
        {icon}
      </Inline>

      <div aria-labelledby={uid}>{children}</div>
    </Stack>
  );
}
