import { CircularProgress } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function LoadingContainer(props: CircularProgressProps) {
  return (
    <Wrapper>
      <CircularProgress {...props} />
    </Wrapper>
  );
}
