import { plainToClass } from 'class-transformer';
import { useEffect } from 'react';
import { APIPageResponse, useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { Order } from 'shared/types/order';
import { InstantDispatchCarriersDTO } from './dto/InstantDispatchDTO';
import { useSingleOrderActionAPI } from './OrderActionAPI';

function canInstantDispatchOrder(order: Order | undefined): boolean {
  if (!order) return false;

  const isParentOrder = order.has_loads && !order.is_load;
  if (isParentOrder) return false;

  return (
    order.is_on_hold ||
    order.is_posted_to_loadboard ||
    order.status === 'pending' ||
    order.status === 'declined' ||
    order.status === 'canceled' ||
    order.status === 'new'
  );
}

export function useInstantDispatchCarriers(
  order: Order | undefined,
  options?: UseAPIQueryOptions<
    APIPageResponse<InstantDispatchCarriersDTO>['data']
  >,
) {
  const { requestPage } = useAPI();
  const hasAccess = useFeatureToggle('instant-dispatch.access.ui');
  const isEnabled = hasAccess && canInstantDispatchOrder(order);

  return useAPIQuery(
    [
      'order',
      'instant-dispatch-carriers',
      {
        guid: order?.guid,
        status: order?.status,
        is_on_hold: order?.is_on_hold,
        is_posted_to_loadboard: order?.is_posted_to_loadboard,
        vehicles: order?.vehicles,
        pickup: order?.pickup,
        delivery: order?.delivery,
      },
    ],
    () => {
      return requestPage(
        'GET /internal/orders/{orderGuid}/instant_dispatch/carriers',
        (data) => plainToClass(InstantDispatchCarriersDTO, data),
        { orderGuid: order?.guid },
      );
    },
    {
      ...options,
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
}

const eventCache = new Set<string>();
export function useCanInstantDispatch(order: Order | undefined): boolean {
  const { data: carriers } = useInstantDispatchCarriers(order);

  useEffect(() => {
    if (order && carriers?.objects.length && !eventCache.has(order.guid)) {
      eventCache.add(order.guid);
      trackEvent('Instant Dispatch Available', {
        number: order.number,
        guid: order.guid,
      });
    }
  }, [carriers, order]);

  return !!carriers?.objects.length;
}

export function useInstantDispatchMutation(
  options?: APIMutationOptions<
    [
      orderGuid: string,
      carrierGuid: string,
      laneGuid: string,
      expectedPrice: number,
    ]
  >,
) {
  const { instantDispatch } = useSingleOrderActionAPI();
  return useAPIMutation<
    [
      orderGuid: string,
      carrierGuid: string,
      laneGuid: string,
      expectedPrice: number,
    ]
  >((args) => instantDispatch(...args), options);
}
