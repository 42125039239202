import { trackEvent } from 'shared/helpers/AnalyticsHelpers';

type UpdateSettingsEvent =
  | {
      name: 'Hide details';
      hide_contact_info_for: string | null;
    }
  | {
      name: 'Super Loadboard';
      is_instant_booking_allowed_for_verified_carriers: boolean;
    }
  | {
      name: 'Private Network';
      private_loadboard_duration: string;
    }
  | {
      name: 'Auto Repost Declined Automatically';
      is_auto_repost_for_declined_offers_enabled: boolean;
    }
  | {
      name: 'Auto Repost Cancelled Automatically';
      is_auto_repost_for_cancel_offers_enabled: boolean;
    };

export function trackUpdatedSuperLoadboardSettingsValues({
  name,
  ...event
}: UpdateSettingsEvent) {
  trackEvent(`Shipper Updated Super Loadboard "${name}" Settings`, {
    ...event,
  });
}
