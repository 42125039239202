import { Exclude, Expose } from 'class-transformer';

export type CarrierRatingType = 'POSITIVE' | 'NEGATIVE';
export type CarrierPositiveRatingTag =
  | 'ON_TIME_TRANSPORT'
  | 'GOOD_COMMUNICATION'
  | 'GOOD_CUSTOMER_SERVICE';

export type CarrierNegativeRatingTag =
  | 'LATE_TRANSPORT'
  | 'DAMAGE'
  | 'BAD_COMMUNICATION'
  | 'BAD_CUSTOMER_SERVICE'
  | 'LATE_CANCELLATION_OR_NO_SHOW'
  | 'DOUBLE_BROKERING';

export type CarrierRatingTag =
  | CarrierPositiveRatingTag
  | CarrierNegativeRatingTag;

export type CarrierRatingStatus = 'PENDING' | 'PUBLISHED';

export class RateCarrierPayloadDTO {
  @Expose() rating: CarrierRatingType;
  @Expose() comment: string | null;
  @Expose() tags: CarrierRatingTag[];
}

@Exclude()
export class CarrierRatingDTO {
  static formatRatingTag(tag: CarrierRatingTag) {
    switch (tag) {
      case 'ON_TIME_TRANSPORT':
        return 'On-Time Transport';
      case 'GOOD_COMMUNICATION':
        return 'Good Communication';
      case 'GOOD_CUSTOMER_SERVICE':
        return 'Good Customer Service';
      case 'LATE_TRANSPORT':
        return 'Late Transport';
      case 'DAMAGE':
        return 'Damage';
      case 'BAD_COMMUNICATION':
        return 'Bad Communication';
      case 'BAD_CUSTOMER_SERVICE':
        return 'Bad Customer Service';
      case 'LATE_CANCELLATION_OR_NO_SHOW':
        return 'Late Cancellation or No Show';
      case 'DOUBLE_BROKERING':
        return 'Double Brokering';
    }
  }
  @Expose() guid: string;
  @Expose() rated_at: string;
  @Expose() comment: string | null;
  @Expose() rating: CarrierRatingType;
  @Expose() shipper_region: string;
  @Expose() overall_moved_vehicles_count: number | null;
  @Expose() tags: CarrierRatingTag[];
  @Expose() status: CarrierRatingStatus;
  @Expose() response: string | null;
  @Expose() response_date: string | null;
}

@Exclude()
export class RatingStatsDTO {
  @Expose() total_rating_count: number | null;
  @Expose() overall_rating: number | null;
}

@Exclude()
export class LastRatedAtDTO {
  @Expose() rated_at: string | null;
}

@Exclude()
export class CarrierRatingTagStatsDTO {
  @Expose() ON_TIME_TRANSPORT: number;
  @Expose() GOOD_CUSTOMER_SERVICE: number;
  @Expose() GOOD_COMMUNICATION: number;
}

@Exclude()
export class UnratedCarriersCountDTO {
  @Expose() unrated_count: number;
}
