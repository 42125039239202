import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { DrawerForm } from 'shared/form/DrawerForm';
import { FormikPasswordField } from 'shared/form/FormikPasswordField';
import { PasswordValidationStepper } from 'shared/ui/PasswordValidationStepper';
import {
  composeValidators,
  maxLength,
  required,
  Value,
} from 'shared/utils/ValidatorUtils';
import { yupString } from 'shared/utils/YupUtils';
import { NormalizeWidth } from 'shipper-profile/sections/AboutUsEditDrawer';
import { ref } from 'yup';
import { useManageUsersAPI } from './data/ManageUsersAPI';
import { ResetPasswordDTO, resetPasswordSchema } from './data/ResetPasswordDTO';
import { UserDTO } from './data/UserDTO';

const formSchema = resetPasswordSchema.shape({
  password_confirm: yupString().oneOf(
    [ref('password')],
    'Passwords do not match',
  ),
});

interface UserResetPasswordDrawerProps {
  open: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  user?: UserDTO;
}

export function UserResetPasswordDrawer({
  open,
  onClose,
  onUpdate,
  user,
}: UserResetPasswordDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { resetPassword } = useManageUsersAPI();
  const formik = useFormikEnhanced<ResetPasswordDTO, unknown>({
    key: open,
    initialValues: {
      password: '',
    },
    validationSchema: formSchema,
    onSubmit: ({ password }) => {
      return resetPassword({ password, guid: user?.guid });
    },
    onSubmitSuccess: (_) => {
      addSnackbar('Password updated', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
  });
  const {
    dirty,
    isSubmitting,
    isValid,
    status,
    handleSubmit,
    values: { password: passwordValue },
  } = formik;

  return (
    <DrawerForm
      title="Reset Password"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
    >
      <FormikProvider value={formik}>
        <NormalizeWidth>
          <Stack space="large">
            <Stack space="small">
              <Stack space="xsmall">
                <FormikPasswordField
                  name="password"
                  label="New Password"
                  validate={composeValidators(required, (value: Value) =>
                    maxLength(value, 30),
                  )}
                  fullWidth={true}
                  autoComplete="new-password"
                />
                <PasswordValidationStepper value={passwordValue} />
              </Stack>

              <FormikPasswordField
                name="password_confirm"
                label="Confirm Password"
                fullWidth={true}
                autoComplete="new-password"
              />
            </Stack>

            <Button
              type="submit"
              variant="primary"
              disabled={isSubmitting || !dirty || !isValid}
            >
              Reset
            </Button>
          </Stack>
        </NormalizeWidth>
      </FormikProvider>
    </DrawerForm>
  );
}
