import { CircularProgress, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { lazy, Suspense, useState } from 'react';
import { isAPIError } from 'shared/errors/APIError';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';
import { Indicator, NotVerifiedInfo } from '../core/Indicators';
import { trackPerformanceAnalytics } from '../core/PerformanceAnalytics';
import { PerformanceDrawer } from '../core/PerformanceDrawer';
import {
  useCalculateEtaRates,
  useCalculateLateCancellationRates,
  useCalculatePhotosOnSiteRates,
} from '../core/PerformanceHelpers';
import { useCarrierPerformanceIndicators } from '../data/ManageCarriersAPI';

const LateCancellationDrawerLazy = lazy(
  () => import('../core/LateCancellationDrawer'),
);

const EtaDrawerLazy = lazy(() => import('../core/EtaDrawer'));

const PhotosOnSiteDrawerLazy = lazy(() => import('../core/PhotosOnSiteDrawer'));

const PerformanceEstimateText = styled(Typography)`
  color: ${Color.Dark100};
`;

export interface CarrierPerformanceIndicatorsProps {
  carrierGuid: string;
  isVerified: boolean;
}

export function CarrierPerformanceIndicators({
  carrierGuid,
  isVerified,
}: CarrierPerformanceIndicatorsProps) {
  const [currentDrawer, setCurrentDrawer] = useState<
    'eta' | 'lateCancellation' | 'photosTaken' | undefined
  >();

  const {
    data: indicators,
    isFetched,
    error,
  } = useCarrierPerformanceIndicators(carrierGuid);

  const lateCancellationGeneralCount =
    indicators?.late_cancellation_general_count || 0;
  const etaProvidedGeneralCount = indicators?.eta_provided_general_count || 0;
  const photosOnSiteGeneralCount =
    indicators?.photos_on_site_general_count || 0;

  const [etaProvidedMeanRate, etaThresholds] = useCalculateEtaRates(indicators);
  const [lateCancellationMeanRate, lateCancellationThresholds] =
    useCalculateLateCancellationRates(indicators);
  const [photosOnSiteMeanRate, photosOnSiteThresholds] =
    useCalculatePhotosOnSiteRates(indicators);

  const showUnverifiedBanner =
    !isVerified ||
    (isAPIError(error) && error.error_id === 'CARRIER_IS_NOT_SUPER');

  return (
    <Stack aria-label="Performance" space="small">
      <PerformanceDrawer
        onClose={() => {
          setCurrentDrawer(undefined);
        }}
        open={!!currentDrawer}
        title={
          currentDrawer === 'eta'
            ? 'ETA Provided'
            : currentDrawer === 'lateCancellation'
            ? 'Late Cancellation'
            : 'Photos Taken On-Site'
        }
      >
        <ExitTransitionPlaceholder in={!!currentDrawer}>
          <Suspense fallback={<CircularProgress />}>
            {currentDrawer === 'lateCancellation' && (
              <LateCancellationDrawerLazy carrierGuid={carrierGuid} />
            )}
            {currentDrawer === 'eta' && (
              <EtaDrawerLazy carrierGuid={carrierGuid} />
            )}
            {currentDrawer === 'photosTaken' && (
              <PhotosOnSiteDrawerLazy carrierGuid={carrierGuid} />
            )}
          </Suspense>
        </ExitTransitionPlaceholder>
      </PerformanceDrawer>

      <Inline space="xxsmall" verticalAlign="center">
        <Typography variant="h3">Performance</Typography>
        <PerformanceEstimateText variant="h3">
          for last 60 days
        </PerformanceEstimateText>
        <ResponsiveTooltip
          interactive={true}
          placement="right"
          title="Only loads accepted through Super Dispatch are included in the performance metrics. Loads that are imported or manually created are excluded."
        >
          <Help fontSize="small" color="action" />
        </ResponsiveTooltip>
      </Inline>

      {!isFetched ? (
        <Box padding="small">
          <Stack align="center">
            <CircularProgress size={24} />
          </Stack>
        </Box>
      ) : showUnverifiedBanner ? (
        <NotVerifiedInfo />
      ) : (
        <Columns space={['small', 'xsmall']} collapseBelow="tablet">
          <Column width="1/3">
            <Indicator
              label="Late Cancellation"
              meanRate={lateCancellationMeanRate ?? null}
              canImprove={!!lateCancellationThresholds?.canImprove}
              generalCountCaption={formatPlural(
                lateCancellationGeneralCount,
                `${lateCancellationGeneralCount} acceptance`,
                `${lateCancellationGeneralCount} acceptances`,
              )}
              onClick={() => {
                trackPerformanceAnalytics({
                  name: 'Shipper Clicked on Performance Indicator',
                  indicator_type: 'Late cancellation',
                });
                setCurrentDrawer('lateCancellation');
              }}
            />
          </Column>
          <Column width="1/3">
            <Indicator
              label="ETA provided"
              meanRate={etaProvidedMeanRate ?? null}
              canImprove={!!etaThresholds?.canImprove}
              generalCountCaption={formatPlural(
                etaProvidedGeneralCount,
                `${etaProvidedGeneralCount} delivery`,
                `${etaProvidedGeneralCount} deliveries`,
              )}
              onClick={() => {
                trackPerformanceAnalytics({
                  name: 'Shipper Clicked on Performance Indicator',
                  indicator_type: 'ETA provided',
                });
                setCurrentDrawer('eta');
              }}
            />
          </Column>
          <Column width="1/3">
            <Indicator
              label="Photos taken on-site"
              meanRate={photosOnSiteMeanRate ?? null}
              canImprove={!!photosOnSiteThresholds?.canImprove}
              generalCountCaption={formatPlural(
                photosOnSiteGeneralCount,
                `${photosOnSiteGeneralCount} delivery`,
                `${photosOnSiteGeneralCount} deliveries`,
              )}
              onClick={() => {
                trackPerformanceAnalytics({
                  name: 'Shipper Clicked on Performance Indicator',
                  indicator_type: 'Photos taken on-site',
                });
                setCurrentDrawer('photosTaken');
              }}
            />
          </Column>
        </Columns>
      )}
    </Stack>
  );
}
