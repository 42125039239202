import { Box, Card, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { Inline, Tag, useResponsiveValue } from '@superdispatch/ui';
import * as React from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useProductTiers } from 'shared/data/TiersUtils';
import { PageLayout } from 'shared/layout/PageLayout';
import { Divider } from 'shared/ui/Divider';
import styled from 'styled-components';
import { BrokerTemplate } from './BrokerTemplate';
import { CustomerTemplate } from './CustomerTemplate';

const StyledTabs = styled(Tabs)`
  button {
    min-width: auto;
    min-height: 44px;
    padding: 0 16px;
  }
`;

interface TemplateProps {
  isLoad: boolean;
  isDemo: boolean;
  header: React.ReactNode;
  info: React.ReactNode;
  vehicles: React.ReactNode;
  pickup: React.ReactNode;
  delivery: React.ReactNode;
  payment: React.ReactNode;
  dates: React.ReactNode;
  carrier: React.ReactNode;
  customer: React.ReactNode;
  instructions: React.ReactNode;
  internalNotes: React.ReactNode;
  attachments: React.ReactNode;
  activities: React.ReactNode;
  revisions: React.ReactNode;
  notesFromShipper: React.ReactNode;
  ratingBanner: React.ReactNode;
  relatedLoads: React.ReactNode;
  hasInstructions: boolean;
  hasCustomer: boolean;
  hasRelatedLoads: boolean;
  numberOfRelatedLoads: number;
  tab?: string;
}

export const Template: React.ComponentType<TemplateProps> = ({
  isLoad,
  isDemo,
  header,
  info,
  vehicles,
  pickup,
  delivery,
  payment,
  dates,
  carrier,
  customer,
  hasCustomer,
  instructions,
  hasInstructions,
  internalNotes,
  attachments,
  activities,
  revisions,
  notesFromShipper,
  ratingBanner,
  relatedLoads,
  hasRelatedLoads,
  numberOfRelatedLoads,
  tab = 'details',
}) => {
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const buildLoadsEnabled = useFeatureToggle('build-loads.enabled.ui');

  const isMobile = platform === 'mobile';

  const cardStyles = isMobile
    ? {
        borderRadius: '0',
        borderLeft: 'none',
        borderRight: 'none',
      }
    : {};

  const { isAdvancedTier } = useProductTiers();

  return (
    <PageLayout
      header={
        <>
          {header}

          {shipperType === 'BROKER' && (
            <Flex px={platform !== 'desktop' ? 3 : 4} mb="-1px">
              <StyledTabs value={tab}>
                <Tab value="details" href="#details" label="Details" />
                <Tab value="history" href="#history" label="Revision History" />

                {buildLoadsEnabled && hasRelatedLoads && (
                  <Tab
                    value="related"
                    href="#related"
                    label={
                      numberOfRelatedLoads > 0 ? (
                        <Inline verticalAlign="center" space="xxsmall">
                          <Typography>
                            Related {isLoad ? 'Orders' : 'Loads'}
                          </Typography>
                          <Tag color="blue" variant="subtle">
                            {numberOfRelatedLoads}
                          </Tag>
                        </Inline>
                      ) : (
                        `Related ${isLoad ? 'Orders' : 'Loads'}`
                      )
                    }
                  />
                )}
              </StyledTabs>
            </Flex>
          )}

          <Divider />
        </>
      }
    >
      <>
        {tab === 'details' && (
          <>
            {info}
            <Divider />

            {shipperType === 'BROKER' && ratingBanner}

            <Grid
              container={true}
              justifyContent="space-between"
              direction="column"
              wrap="nowrap"
              data-intercom-target={isDemo ? 'demo order' : undefined}
            >
              <Box maxWidth={1} pt={2} px={platform === 'mobile' ? 0 : 3}>
                <Card style={cardStyles}>{vehicles}</Card>
              </Box>

              {shipperType === 'BROKER' && (
                <BrokerTemplate
                  pickup={pickup}
                  delivery={delivery}
                  payment={payment}
                  dates={dates}
                  carrier={carrier}
                  customer={customer}
                  hasCustomer={hasCustomer && isAdvancedTier}
                  instructions={instructions}
                  hasInstructions={hasInstructions}
                  internalNotes={internalNotes}
                  attachments={attachments}
                  activities={activities}
                />
              )}

              {shipperType === 'CUSTOMER' && (
                <CustomerTemplate
                  pickup={pickup}
                  delivery={delivery}
                  payment={payment}
                  dates={dates}
                  instructions={instructions}
                  hasInstructions={hasInstructions}
                  activities={activities}
                  notesFromShipper={notesFromShipper}
                  attachments={attachments}
                />
              )}
            </Grid>
          </>
        )}

        {tab === 'history' && (
          <Flex flexDirection="column" flex={1} pb={2}>
            {revisions}
          </Flex>
        )}

        {tab === 'related' && (
          <Flex flexDirection="column" flex={1} pb={2}>
            {relatedLoads}
          </Flex>
        )}
      </>
    </PageLayout>
  );
};
