import { ReactNode } from 'react';
import { logError } from './MonitoringService';

export function formatNumber(
  value: number,
  options?: Intl.NumberFormatOptions,
): string {
  if (Number.isFinite(value)) {
    try {
      return value.toLocaleString('en-US', options);
    } catch (e: unknown) {
      logError(e, 'Intl#formatNumber');
    }
  }

  return String(value);
}

export function formatCurrency(
  value: number,
  {
    maximumFractionDigits,
    minimumFractionDigits = 2,
  }: Pick<
    Intl.NumberFormatOptions,
    'minimumFractionDigits' | 'maximumFractionDigits'
  > = {},
): string {
  if (maximumFractionDigits != null) {
    minimumFractionDigits = Math.min(
      maximumFractionDigits,
      minimumFractionDigits,
    );
  }

  return formatNumber(value, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatPlural<TOne extends ReactNode, TOther extends ReactNode>(
  value: number,
  one: TOne,
  other: TOther,
): TOne | TOther {
  return value === 1 || value === -1 ? one : other;
}
