import {
  Backdrop,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
} from '@material-ui/core';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ImgHTMLAttributes, useState } from 'react';
import { ZoomInMapIcon } from 'shared/icons/ZoomInMapIcon';
import styled from 'styled-components';
import { LightboxGallery } from './LightboxGallery';

const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const ImageCount = styled(TextBox)`
  position: absolute;
  z-index: 1;
`;

const ThumbnailImage = styled.img`
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  filter: ${({ hasReducedBrightness }: { hasReducedBrightness: boolean }) =>
    hasReducedBrightness ? 'brightness(0.5)' : 'brightness(1)'};

  &:hover {
    filter: brightness(0.5);
  }
`;

const FullImage = styled.img`
  max-height: 70vh;
  max-width: 65vw;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  border-radius: 4px;
`;

const PhotoViewerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 100%;
  height: 100%;

  & > img {
    object-fit: contain;
  }
`;

interface PhotoViewerProps {
  slides?: string[];
  imageUrl: string;
  thumbnailUrl?: string;
  FullImageProps?: Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'onLoad'>;
  isPhotoGalleryAvailable?: boolean;
  ThumbnailImageProps?: Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    'src' | 'onClick'
  >;
}

export function PhotoViewer({
  slides,
  imageUrl,
  thumbnailUrl,
  FullImageProps,
  ThumbnailImageProps,
}: PhotoViewerProps) {
  const [isLoaded, setLoadedState] = useState(false);
  const [isFullscreen, setFullscreenState] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const hasSlides = slides && slides.length > 1;

  return (
    <>
      <ImageWrapper
        onClick={() => {
          if (hasSlides) {
            setCurrentIndex(0);
          } else {
            setFullscreenState(true);
          }
        }}
      >
        {hasSlides && (
          <ImageCount variant="heading-6" color="white">
            +{slides.length - 1}
          </ImageCount>
        )}
        <ThumbnailImage
          aria-label="thumbnail"
          hasReducedBrightness={!!hasSlides}
          {...ThumbnailImageProps}
          src={thumbnailUrl || imageUrl}
        />
      </ImageWrapper>
      {hasSlides && (
        <LightboxGallery
          currentSlideIndex={currentIndex}
          setCurrentSlideIndex={setCurrentIndex}
          slides={slides}
        />
      )}

      <Modal
        open={isFullscreen}
        aria-label="fullscreen modal"
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={() => {
          setLoadedState(false);
          setFullscreenState(false);
        }}
      >
        <PhotoViewerWrapper>
          <Box position="absolute">
            <Fade in={!isLoaded}>
              <CircularProgress color="inherit" />
            </Fade>
          </Box>

          <Fade in={isLoaded}>
            <PhotoViewerWrapper>
              <Box position="relative">
                <FullImage
                  {...FullImageProps}
                  src={imageUrl}
                  onLoad={() => {
                    setLoadedState(true);
                  }}
                />

                <Box
                  right="-58px"
                  top="-58px"
                  borderRadius="small"
                  position="absolute"
                  backgroundColor="Grey300"
                >
                  <IconButton
                    aria-label="toggle fullscreen"
                    onClick={() => {
                      setFullscreenState(!isFullscreen);
                    }}
                  >
                    <ZoomInMapIcon />
                  </IconButton>
                </Box>
              </Box>
            </PhotoViewerWrapper>
          </Fade>
        </PhotoViewerWrapper>
      </Modal>
    </>
  );
}
