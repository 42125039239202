import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Inline, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { formatPlural } from '../../shared/helpers/IntlHelpers';
import {
  usePrivateNetworkAPIs,
  usePrivateNetworkPostedCount,
} from '../data/PrivateNetworkAPI';
import { PrivateNetworkGroupDTO } from '../data/PrivateNetworkDTO';

export interface PrivateNetworkDeleteGroupDialogContentProps {
  onClose: () => void;
  group: PrivateNetworkGroupDTO;
}

export function PrivateNetworkDeleteGroupDialogContent({
  onClose,
  group,
}: PrivateNetworkDeleteGroupDialogContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { deletePrivateNetworkGroup } = usePrivateNetworkAPIs();
  const { data } = usePrivateNetworkPostedCount(group.guid);

  const formik = useFormikEnhanced<{}, void>({
    initialValues: {},
    onSubmit: () => deletePrivateNetworkGroup(group.guid),
    onSubmitSuccess: () => {
      addSnackbar('Private network group deleted', {
        variant: 'success',
      });
      onClose();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message || 'Failed to delete group', {
        variant: 'error',
      });
    },
  });
  const { isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box width="360px">
          <DialogTitle>
            <Typography variant="h3">{`Delete '${group.name}' group?`}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {!data ? (
                <Inline verticalAlign="center" horizontalAlign="center">
                  <CircularProgress size={16} />
                </Inline>
              ) : data.posted_count > 0 ? (
                `${data.posted_count} ${formatPlural(
                  data.posted_count,
                  'order',
                  'orders',
                )} for this group will be unposted from the Private Loadboard`
              ) : (
                'There are no orders poster for this group.'
              )}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Columns collapseBelow="tablet" space="small">
              <Column width="fluid" />
              <Column width="content">
                <Button
                  variant="neutral"
                  onClick={() => {
                    onClose();
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Column>
              <Column width="content">
                <Button
                  type="submit"
                  disabled={!data}
                  pending={isSubmitting}
                  onClick={() => {
                    void formik.submitForm();
                  }}
                >
                  Delete
                </Button>
              </Column>
            </Columns>
          </DialogActions>
        </Box>
      </Form>
    </FormikProvider>
  );
}
