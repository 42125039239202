import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import {
  customerContactDTO,
  CustomerContactDTO,
  CustomerContactFormData,
} from 'shared/dto/CustomerContactDTO';

export function useContactsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateContacts(customerID: number) {
      void queryClient.invalidateQueries([
        'customer-contacts',
        'list',
        { customerID },
      ]);
    }

    return {
      invalidateContacts,
    };
  }, [queryClient]);
}

export function useCustomerContacts(
  customerID: number | undefined,
  page?: number,
) {
  const { requestPage } = useAPI();
  return useAPIPageQuery(
    ['customer-contacts', 'list', { customerID, page }],
    () =>
      requestPage(
        '/internal/customers/{customerID}/contacts{?size,page}',
        (data) => customerContactDTO.cast(data),
        { customerID, page, size: 20 },
      ),
    { enabled: customerID != null },
  );
}

export function useContactAPI() {
  const cache = useContactsCache();
  const { request, requestResource } = useAPI();

  return useMemo(
    () => ({
      createContact(
        customerID: number,
        customerContactFormData: CustomerContactFormData,
      ) {
        const customerContactForCreate = customerContactDTO.cast(
          customerContactFormData,
        );

        return requestResource<CustomerContactDTO, { customerID: number }>(
          'POST /internal/customers/{customerID}/contacts',
          (data) => customerContactDTO.cast(data),
          { customerID, json: customerContactForCreate },
        ).then((response) => {
          cache.invalidateContacts(customerID);
          return response;
        });
      },
      deleteContact: (customerID: number, contactID: number) =>
        request(
          'DELETE /internal/customers/{customerID}/contacts/{contactID}',
          { contactID, customerID },
        ).then(() => {
          cache.invalidateContacts(customerID);
        }),
      updateContact(
        customerID: number,
        contactID: number,
        customerContactFormData: CustomerContactFormData,
      ) {
        const customerContactForUpdate = customerContactDTO.cast(
          customerContactFormData,
        );

        return requestResource<CustomerContactDTO>(
          'PUT /internal/customers/{customerID}/contacts/{contactID}',
          (data) => customerContactDTO.cast(data),
          {
            contactID,
            customerID,
            json: customerContactForUpdate,
          },
        ).then((response) => {
          cache.invalidateContacts(customerID);
          return response;
        });
      },
      markContactAsPrimary: (customerID: number, contactID: number) =>
        request<{ customerID: number; contactID: number }>(
          'PUT /internal/customers/{customerID}/contacts/{contactID}/mark_as_primary',
          { customerID, contactID },
        ).then((response) => {
          cache.invalidateContacts(customerID);
          return response;
        }),
    }),
    [cache, request, requestResource],
  );
}

export function useMarkAsPrimaryMutation(
  options?: APIMutationOptions<{ customerID: number; contactID: number }>,
) {
  const { markContactAsPrimary } = useContactAPI();
  return useAPIMutation<{ customerID: number; contactID: number }>(
    ({ contactID, customerID }) => markContactAsPrimary(customerID, contactID),
    options,
  );
}

export function useDeleteContactMutation(
  options?: APIMutationOptions<{ customerID: number; contactID: number }>,
) {
  const { deleteContact } = useContactAPI();
  return useAPIMutation<{ customerID: number; contactID: number }>(
    ({ contactID, customerID }) => deleteContact(customerID, contactID),
    options,
  );
}
