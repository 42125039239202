import { Grid, Typography } from '@material-ui/core';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import styled from 'styled-components';

const Gutter = styled.div`
  height: 75%;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  &[data-start='true'] {
    background: ${Color.Blue300};
  }
  &[data-end='true'] {
    background: ${Color.Silver400};
  }
`;

const GutterLine = styled.div`
  flex: 1;
  width: 1px;
  background-color: ${Color.Blue300};
  margin: 2px 0;
`;

interface Waypoints2Props {
  startPrimaryText: string;
  startSecondaryText?: string;
  startLeadingText?: string;
  endPrimaryText: string | React.ReactElement;
  endSecondaryText?: string;
  endLeadingPrimaryText?: string | React.ReactElement;
  endLeadingSecondaryText?: string;
}

export function Waypoints2({
  startPrimaryText,
  startSecondaryText,
  startLeadingText,
  endPrimaryText,
  endSecondaryText,
  endLeadingPrimaryText,
  endLeadingSecondaryText,
}: Waypoints2Props) {
  return (
    <Grid container={true} spacing={1} wrap="nowrap">
      <Grid item={true}>
        <Gutter>
          <GutterPoint data-start="true" />
          <GutterLine />
          <GutterPoint data-end="true" />
        </Gutter>
      </Grid>

      <Grid item={true} xs={true}>
        <Stack space="medium">
          <Columns>
            <Column>
              <Stack space="xxsmall">
                <Typography variant="h5">{startPrimaryText}</Typography>
                <Typography color="textSecondary">
                  {startSecondaryText}
                </Typography>
              </Stack>
            </Column>
            <Column width="content">
              <Typography variant="h5">{startLeadingText}</Typography>
            </Column>
          </Columns>

          <Columns>
            <Column>
              <Stack space="xxsmall">
                <Typography variant="h5">{endPrimaryText}</Typography>
                <Typography color="textSecondary">
                  {endSecondaryText}
                </Typography>
              </Stack>
            </Column>
            <Column width="content">
              <Stack space="xxsmall" align="right">
                {endLeadingPrimaryText}

                <Typography color="textSecondary">
                  {endLeadingSecondaryText}
                </Typography>
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Grid>
    </Grid>
  );
}
