import { Toolbar, Typography } from '@material-ui/core';
import { Box } from '@superdispatch/ui-lab';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { SubscriptionAlerts } from './SubscriptionAlerts';
import { SubscriptionMonthlyUsageCard } from './SubscriptionMonthlyUsageCard/SubscriptionMonthlyUsageCard';
import { SubscriptionPaymentInformationCard } from './SubscriptionPaymentInformationCard';
import { SubscriptionPlanInformationCard } from './SubscriptionPlanInformationCard';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  max-width: calc(736px + 64px);
  gap: 16px;
`;

const StyledCardsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 16px;
  justify-content: center;

  & > :nth-child(3) {
    grid-column: span 2;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    & > :nth-child(3) {
      grid-column: 1;
    }
  }
`;

export function SubscriptionDetailsPage() {
  return (
    <PageLayout
      header={
        <Toolbar>
          <Box marginLeft="small" marginRight="small">
            <Typography variant="h2">Subscription Details</Typography>
          </Box>
        </Toolbar>
      }
    >
      <StyledContainer>
        <SubscriptionAlerts />
        <StyledCardsLayout>
          <SubscriptionPlanInformationCard />
          <SubscriptionPaymentInformationCard />
          <SubscriptionMonthlyUsageCard />
        </StyledCardsLayout>
      </StyledContainer>
    </PageLayout>
  );
}
