import { Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { showIntercomDialog } from 'shared/helpers/IntercomHelpers';
import { RequirementItem } from 'shared/ui/RequirementItem';
import {
  trackVerificationApplicationEvent,
  useTrackContactComplianceEvent,
} from '../data/VerificationAnalyticsEvents';

export interface InsuranceHelpDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function InsuranceHelpDrawer({
  open,
  onClose,
}: InsuranceHelpDrawerProps) {
  const trackContactComplianceEvent = useTrackContactComplianceEvent();
  const isInternationalShippersEnabled = useFeatureToggle(
    'verification-application.international-shippers.enabled.ui',
  );

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="xlarge">
            <Stack space="small">
              <Typography variant="h2">
                Why do we need your Certificate of Insurance?
              </Typography>
              <Typography>
                A Certificate of Insurance is required to ship vehicles using
                Super Dispatch. This ensures you have the coverage necessary to
                protect your business and assets.
              </Typography>
            </Stack>
            <Stack space="small">
              <Typography>
                Before uploading your Certificate of Insurance verify:
              </Typography>
              <RequirementItem text="General / garage liability coverage is included." />
              <RequirementItem text="The company name matches the company name on your Super Dispatch account." />
              <RequirementItem text="The policy effective date and expiration date are visible and insurance has not expired." />
              <RequirementItem text="If it is a surety bond, the bond effective date must be included." />
            </Stack>
            <Stack space="small">
              <Typography variant="h4">Can we help?</Typography>
              <AnchorButton
                variant="text"
                endIcon={<ChevronRight />}
                target="_blank"
                href="https://support.superdispatch.com/en/articles/6902578-shipper-tms-verified-shipper-requirements-and-information"
              >
                Read our Article
              </AnchorButton>
              <Button
                variant="text"
                endIcon={<ChevronRight />}
                onClick={() => {
                  trackContactComplianceEvent('Certificate of Insurance');
                  showIntercomDialog();
                }}
              >
                Contact our Compliance Team
              </Button>
              {isInternationalShippersEnabled && (
                <Button
                  variant="text"
                  endIcon={<ChevronRight />}
                  onClick={() => {
                    trackVerificationApplicationEvent({
                      name: 'Shipper Clicked on "I Do Not Own An Insurance" Link',
                    });
                  }}
                >
                  I do not own an insurance
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Ok
        </Button>
      </DrawerActions>
    </Drawer>
  );
}
