import { Typography } from '@material-ui/core';
import { Comment } from '@material-ui/icons';
import {
  FormattedDate,
  FormattedRelativeTime,
  parseDate,
} from '@superdispatch/dates';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { DateTime } from 'luxon';
import { PickupETADTO } from 'orders/data/dto/ETAInformationDTO';
import { ETAIcon } from 'shared/icons/ETAIcon';
import { getDateWithOffset } from 'shared/utils/DataUtils';
import styled from 'styled-components';
import { trackEvent } from '../helpers/AnalyticsHelpers';
import { ResponsiveTooltip } from './ResponsiveTooltip';

interface PickupETAprops {
  pickupETA?: PickupETADTO;
  inline?: boolean;
  showEmpty?: boolean;
}

const StyledDateCaption = styled(Typography)`
  color: ${Color.Dark300};
`;

const StyledComment = styled(Comment)`
  color: ${Color.Dark100};
  margin-top: 2px;
  z-index: 1;
`;

export function PickupETA({ pickupETA, inline, showEmpty }: PickupETAprops) {
  const now = DateTime.local();

  const fromDate = getDateWithOffset(
    pickupETA?.date_from,
    pickupETA?.zone_offset_minutes,
  );
  const untilDate = getDateWithOffset(
    pickupETA?.date_until,
    pickupETA?.zone_offset_minutes,
  );

  const pickupETADate = parseDate(pickupETA?.date_until, {
    format: 'JodaISO',
  });

  const showPickupETA =
    pickupETA && pickupETA.date_from && pickupETADate && pickupETADate > now;

  return showPickupETA ? (
    <span data-intercom-target="pickup eta">
      <Inline space="xxsmall" noWrap={true} aria-label="pickup eta">
        <ETAIcon fontSize="small" style={{ marginTop: '2px' }} />
        {inline ? (
          <Inline space="xxsmall" noWrap={true} verticalAlign="center">
            <Typography aria-label="pickup eta date">
              Driver ETA{' '}
              <FormattedDate
                date={fromDate}
                format="JodaISO"
                variant="ShortDate"
              />
              ,
            </Typography>

            <Typography aria-label="pickup eta time from">
              {fromDate?.toLocaleString({ hour: 'numeric', hourCycle: 'h12' })}
            </Typography>

            {pickupETA.date_until && (
              <>
                <Typography>–</Typography>
                <Typography aria-label="pickup eta time until">
                  {untilDate?.toLocaleString({
                    hour: 'numeric',
                    hourCycle: 'h12',
                  })}
                </Typography>
              </>
            )}

            {pickupETA.time_zone && (
              <Typography aria-label="pickup eta timezone">
                {pickupETA.time_zone}
              </Typography>
            )}

            {pickupETA.notes && (
              <ResponsiveTooltip
                title={pickupETA.notes}
                interactive={true}
                placement="top"
                onOpen={() => {
                  trackEvent('Shipper hovered over Pickup ETA notes');
                }}
              >
                <StyledComment fontSize="small" />
              </ResponsiveTooltip>
            )}

            {pickupETA.updated_at && (
              <StyledDateCaption
                variant="caption"
                aria-label="pickup eta updated at"
              >
                {' · '}
                <FormattedRelativeTime
                  date={pickupETA.updated_at}
                  format="JodaISO"
                />
              </StyledDateCaption>
            )}
          </Inline>
        ) : (
          <Stack space="none">
            <Inline verticalAlign="center" space="xxsmall" noWrap={true}>
              <Typography aria-label="pickup eta date">
                Driver ETA{' '}
                <FormattedDate
                  date={fromDate}
                  format="JodaISO"
                  variant="ShortDate"
                />
              </Typography>

              {pickupETA.notes && (
                <ResponsiveTooltip
                  placement="top"
                  interactive={true}
                  title={pickupETA.notes}
                  onOpen={() => {
                    trackEvent('Shipper hovered over Pickup ETA notes');
                  }}
                >
                  <StyledComment fontSize="small" />
                </ResponsiveTooltip>
              )}
            </Inline>

            <Inline verticalAlign="center" noWrap={true} space="xxsmall">
              <StyledDateCaption
                variant="caption"
                aria-label="pickup eta time from"
              >
                {fromDate?.toLocaleString({
                  hour: 'numeric',
                  hourCycle: 'h12',
                })}
              </StyledDateCaption>

              {pickupETA.date_until && (
                <>
                  <StyledDateCaption variant="caption">–</StyledDateCaption>
                  <StyledDateCaption
                    variant="caption"
                    aria-label="pickup eta time until"
                  >
                    {untilDate?.toLocaleString({
                      hour: 'numeric',
                      hourCycle: 'h12',
                    })}
                  </StyledDateCaption>
                </>
              )}

              {pickupETA.time_zone && (
                <StyledDateCaption
                  variant="caption"
                  aria-label="pickup eta timezone"
                >
                  {pickupETA.time_zone}
                </StyledDateCaption>
              )}
            </Inline>

            {pickupETA.updated_at && (
              <StyledDateCaption
                variant="caption"
                aria-label="pickup eta updated at"
              >
                <FormattedRelativeTime
                  date={pickupETA.updated_at}
                  format="JodaISO"
                />
              </StyledDateCaption>
            )}
          </Stack>
        )}
      </Inline>
    </span>
  ) : showEmpty ? (
    <Typography>-</Typography>
  ) : null;
}
