import { MenuItem } from '@material-ui/core';
import { NullableDateRangeInput, parseDate } from '@superdispatch/dates';
import { FormikTextField } from '@superdispatch/forms';
import { formatDateType } from '@superdispatch/sdk';
import { useField } from 'formik';
import { Step } from 'shared/types/step';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';

export function AcceptableDateField({
  name,
  step,
  label,
}: {
  name: string;
  step?: Step;
  label: string;
}) {
  const [{ value }] = useField<number>(name);
  const isValidDateType = step?.date_type === 'estimated';

  const dateRange: NullableDateRangeInput = [
    parseDate(step?.scheduled_at, { format: 'JodaISO' }),
    parseDate(step?.scheduled_ends_at || step?.scheduled_at, {
      format: 'JodaISO',
    })
      .endOf(value > 0 ? 'day' : 'milliseconds')
      .plus({ days: value }),
  ];

  return (
    <FormikTextField
      name={name}
      select={true}
      fullWidth={true}
      label={label}
      disabled={!isValidDateType}
      helperText={
        isValidDateType ? (
          <DetailedFormattedDateRange range={dateRange} />
        ) : (
          `${formatDateType(step?.date_type)} date type in use`
        )
      }
    >
      <MenuItem value={0}>Not flexible</MenuItem>
      <MenuItem value={1}>+ 1 Day</MenuItem>
      <MenuItem value={2}>+ 2 Days</MenuItem>
      <MenuItem value={3}>+ 3 Days</MenuItem>
    </FormikTextField>
  );
}
