import { useMemo } from 'react';
import styled from 'styled-components';
import { Lightbox, SlideImage } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/plugins/counter.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

const LighboxImage = styled(Lightbox)`
  .yarl__container {
    background-color: rgba(0, 0, 0, 0.85);
  }
  .yarl__slide_title_container {
    background: transparent;
  }
`;

interface LightboxGalleryProps {
  slides: string[];
  currentSlideIndex: number;
  setCurrentSlideIndex: (index: number) => void;
}

export function LightboxGallery({
  slides,
  currentSlideIndex,
  setCurrentSlideIndex,
}: LightboxGalleryProps) {
  const gallerySlides = useMemo((): SlideImage[] | undefined => {
    return slides.map((image) => ({
      src: image,
      alt: '',
      imageFit: 'contain',
    }));
  }, [slides]);

  return (
    <LighboxImage
      plugins={[Fullscreen, Zoom, Captions, Counter]}
      captions={{ descriptionTextAlign: 'start' }}
      on={{
        view: (e) => {
          setCurrentSlideIndex(e.index);
        },
      }}
      counter={{ container: { style: { top: 0, bottom: 'unset' } } }}
      zoom={{
        scrollToZoom: false,
        zoomInMultiplier: 2,
        maxZoomPixelRatio: 1000,
      }}
      index={currentSlideIndex}
      slides={gallerySlides}
      open={currentSlideIndex >= 0}
      close={() => {
        setCurrentSlideIndex(-1);
      }}
      controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
    />
  );
}
