import {
  CalendarQuickSelection,
  CalendarQuickSelectionItem,
  DateRangeField,
  DateTimeRange,
  formatDateRange,
  NullableDateRangeInput,
  parseDate,
  stringifyDate,
  toDateRangePayload,
} from '@superdispatch/dates';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { endOfWorkDay, startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { DictionaryAutocompleteProps } from './DictionaryAutocomplete';

export function DeliveredOnDateRange({
  type,
  value: valueProp,
  onChange,
  onSelect,
}: DictionaryAutocompleteProps) {
  const [deliveredOnDate, setDeliveredOnDate] =
    useState<NullableDateRangeInput>();
  const value = type === 'delivered_on_date' ? valueProp : undefined; //excluding DictionaryPlaceDTO[] from value's type
  const [deliveredStart, deliveredEnd] = value ?? [null, null];

  return (
    <DateRangeField
      aria-label="Delivered on date"
      fullWidth={true}
      format="JodaISO"
      placeholder="Select date"
      enableClearable={false}
      value={deliveredOnDate ?? [deliveredStart, deliveredEnd]}
      onChange={({
        dateValue: [startDateNullableValue, endDateNullableValue],
      }) => {
        setDeliveredOnDate([startDateNullableValue, endDateNullableValue]);

        if (!startDateNullableValue || !endDateNullableValue) {
          return;
        }

        const startDate = getDateStringValue(startDateNullableValue);
        const endDate = getDateStringValue(endDateNullableValue);

        if (startDate && endDate && type === 'delivered_on_date') {
          onChange([startDate, endDate]);
          onSelect();
        }
      }}
      renderQuickSelection={({ close, dateValue, change }) => (
        <DeliveredOnQuickSelection
          close={close}
          dateValue={dateValue}
          change={change}
        />
      )}
    />
  );
}

interface DeliveredOnQuickSelectionProps {
  close: () => void;
  dateValue: DateTimeRange;
  change: (value: NullableDateRangeInput) => void;
}

function DeliveredOnQuickSelection({
  close,
  dateValue,
  change,
}: DeliveredOnQuickSelectionProps) {
  const selectionStart = useMemo(() => startOfWorkDay(DateTime.local()), []);

  const monthToDate = useMemo(() => {
    const daysCount = DateTime.local().day;
    return getEndDateStringValue(selectionStart, daysCount);
  }, [selectionStart]);

  const lastMonthDateRange = useMemo(() => {
    const lastMonthStartDate = DateTime.local()
      .minus({ month: 1 })
      .startOf('month');
    const lastMonthEndDate = DateTime.local()
      .minus({ month: 1 })
      .endOf('month');
    return toDateRangePayload([lastMonthStartDate, lastMonthEndDate], {
      format: 'JodaISO',
    });
  }, []);

  return (
    <CalendarQuickSelection>
      {[7, 14, 30].map((daysCount, idx) => {
        const [startValue, endValue] = dateValue;
        const selectionEnd = getEndDateStringValue(selectionStart, daysCount);

        return (
          <CalendarQuickSelectionItem
            key={idx}
            selected={
              startValue === selectionStart && endValue === selectionEnd
            }
            onClick={() => {
              change([selectionStart, selectionEnd]);
              close();
            }}
          >
            {daysCount === 7
              ? 'Last week'
              : daysCount === 14
              ? 'Last 2 weeks'
              : 'Last 30 days'}
          </CalendarQuickSelectionItem>
        );
      })}

      <CalendarQuickSelectionItem
        key="month-to-date"
        selected={
          dateValue[0] === selectionStart && dateValue[1] === monthToDate
        }
        onClick={() => {
          change([selectionStart, monthToDate]);
          close();
        }}
      >
        Month-to-date
      </CalendarQuickSelectionItem>

      <CalendarQuickSelectionItem
        key="last-month"
        selected={
          dateValue[0] === lastMonthDateRange.dateValue[0] &&
          dateValue[1] === lastMonthDateRange.dateValue[1]
        }
        onClick={() => {
          change([
            lastMonthDateRange.dateValue[0],
            lastMonthDateRange.dateValue[1],
          ]);
          close();
        }}
      >
        Last month
      </CalendarQuickSelectionItem>
    </CalendarQuickSelection>
  );
}

function getDateStringValue(date: DateTime) {
  return stringifyDate(startOfWorkDay(date), { format: 'JodaISO' });
}

function getEndDateStringValue(startDate: DateTime, daysCount: number) {
  return stringifyDate(
    endOfWorkDay(parseDate(startDate, { format: 'JodaISO' })).minus({
      day: daysCount - 1,
    }),
    { format: 'JodaISO' },
  );
}

export function formatDeliveredOnDate(startDate: string, endDate: string) {
  const displayValue = formatDateRange(
    [startDate, endDate],
    { fallback: 'Invalid date' },
    { format: 'JodaISO' },
  );
  return displayValue;
}
