import { Card, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { OutlineCheckIcon } from 'shared/icons/OutlineCheckIcon';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  height: 100%;

  ${({ highlighted }: { highlighted: boolean }) =>
    highlighted &&
    `
    border: 3px solid ${Color.Blue500};
  `}
`;

const StyledNotice = styled.div`
  border: 1px solid ${Color.Silver400};
  border-radius: 4px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

interface SubscriptionPlanCardFeature {
  title: string;
  helpText: string;
}

export interface SubscriptionPlanCardProps {
  className?: string;
  highlighted?: boolean;
  prepend?: React.ReactNode;
  title: string;
  description: string;
  headerAppend?: React.ReactNode;
  isFixedPrice?: boolean;
  price?: number;
  priceLoading?: boolean;
  ctaText: string;
  ctaVariant: 'primary' | 'neutral';
  onCtaClick: () => void;
  featuresTitle: string;
  features: readonly SubscriptionPlanCardFeature[];
  notice?: string[];
  vins?: number;
  contentAppend?: React.ReactNode;
}

export function SubscriptionPlanCard({
  className,
  highlighted = false,
  prepend,
  title,
  headerAppend,
  description,
  price,
  isFixedPrice = false,
  priceLoading,
  ctaText,
  ctaVariant,
  onCtaClick,
  featuresTitle,
  features,
  notice,
  vins,
  contentAppend,
}: SubscriptionPlanCardProps) {
  return (
    <StyledCard className={className} highlighted={highlighted}>
      {prepend}

      <Box
        paddingLeft="large"
        paddingRight="large"
        paddingTop="small"
        paddingBottom="large"
      >
        <Stack>
          <Columns align="center" space="large">
            <Column width="fluid">
              <Typography variant="h1">{title}</Typography>
            </Column>
            {headerAppend && <Column width="content">{headerAppend}</Column>}
          </Columns>

          <Typography color="textSecondary">{description}</Typography>

          <Inline space="none" verticalAlign="bottom">
            {!isFixedPrice && (
              <Typography
                variant="h5"
                color="textSecondary"
                style={{ marginBottom: 3 }}
              >
                Starts at &nbsp;
              </Typography>
            )}
            <Typography variant="h1">
              {priceLoading && <Skeleton width={78} />}
              {price !== undefined &&
                formatCurrency(price, {
                  maximumFractionDigits: 0,
                })}
            </Typography>
            &nbsp;
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ marginBottom: 3 }}
            >
              / month
            </Typography>
            &nbsp;
            {vins && (
              <Typography
                variant="h5"
                color="textSecondary"
                style={{ marginBottom: 3 }}
              >
                / {vins} vins
              </Typography>
            )}
          </Inline>
        </Stack>

        <Box marginTop="large" marginBottom="medium">
          <Button
            fullWidth={true}
            size="large"
            variant={ctaVariant}
            onClick={onCtaClick}
          >
            {ctaText}
          </Button>
        </Box>

        {!!notice && notice.length > 0 && (
          <StyledNotice>
            <Typography variant="body1">
              <ul>
                {notice.map((val) => (
                  <li key={val}>{val}</li>
                ))}
              </ul>
            </Typography>
          </StyledNotice>
        )}

        <Stack space="small">
          <Typography variant="h3">{featuresTitle}</Typography>

          {features.map((feature) => (
            <Columns space="small" key={feature.title} align="center">
              <Column width="fluid">
                <Inline verticalAlign="center" space="small">
                  <OutlineCheckIcon color="primary" style={{ fontSize: 20 }} />
                  <Typography variant="h4">{feature.title}</Typography>
                </Inline>
              </Column>
              <Column width="content">
                <ResponsiveTooltip
                  interactive={true}
                  placement="bottom"
                  title={feature.helpText}
                >
                  <Help fontSize="small" color="action" />
                </ResponsiveTooltip>
              </Column>
            </Columns>
          ))}
        </Stack>

        {contentAppend}
      </Box>
    </StyledCard>
  );
}
