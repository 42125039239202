import { Menu, MenuItem, MenuItemProps, Typography } from '@material-ui/core';
import {
  AccountBalanceWallet,
  AccountCircle as ProfileIcon,
  Error,
  Group as UsersIcon,
  MonetizationOn,
  Settings as SettingsIcon,
  Warning,
} from '@material-ui/icons';
import { Color, Inline, Stack, Tag } from '@superdispatch/ui';
import {
  Box,
  NavbarAvatar,
  NavbarItem,
  useNavbarContext,
} from '@superdispatch/ui-lab';
import { isString } from 'lodash';
import { forwardRef, ReactNode, useState } from 'react';
import { matchPath, To, useLocation, useNavigate } from 'react-router-dom';
import { useOnboardingState } from 'shared/data/OnboardingState';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import styled from 'styled-components';
import { useUserState } from '../data/AppUserState';
import { useFeatureToggle } from '../data/FeatureToggle';
import { useProductTiers } from '../data/TiersUtils';
import { BoltIcon } from '../icons/BoltIcon';
import { BulletinBoardIcon } from '../icons/Icons';
import { LogoutIcon } from '../icons/LogoutIcon';
import { ManageOrderFieldsIcon } from '../icons/ManageOrderFieldsIcon';
import { NotificationsIcon } from '../icons/NotificationsIcon';
import { getInitials } from '../utils/TextUtils';
import { setCurrentTab } from './AppNavbar';

const ListDivider = styled.div`
  border-bottom: 1px solid ${Color.Silver400};
  margin: 16px -16px;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledMenuIcon = styled(Box)`
  & svg {
    font-size: 24px;
    color: ${Color.Dark100};
  }
`;

const Wrapper = styled.div<{ active?: boolean }>`
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  background: ${Color.Dark400};

  border-left: 4px solid
    ${({ active }) => (active ? Color.Blue300 : 'transparent')};

  &:hover {
    background: ${Color.Dark400};
    border-left-color: ${Color.Blue300};
  }
`;

interface NavbarMenuItemProps extends Omit<MenuItemProps, 'button'> {
  icon: ReactNode;
  children: ReactNode;
  hasError?: boolean;
  hasWarning?: boolean;
  shouldShowNewTag?: boolean;
}

const NavbarMenuItem = forwardRef<HTMLLIElement, NavbarMenuItemProps>(
  (
    { icon, shouldShowNewTag, hasWarning, hasError, children, ...props },
    ref,
  ) => {
    return (
      <StyledMenuItem {...props} ref={ref}>
        <Inline space="small" verticalAlign="center" noWrap={true}>
          <StyledMenuIcon>{icon}</StyledMenuIcon>

          <Box color="Dark500">{children}</Box>
        </Inline>
        {hasError ? (
          <Error htmlColor={Color.Red300} fontSize="small" />
        ) : hasWarning ? (
          <Warning fontSize="small" htmlColor={Color.Yellow300} />
        ) : shouldShowNewTag ? (
          <Tag color="blue" variant="subtle">
            New
          </Tag>
        ) : null}
      </StyledMenuItem>
    );
  },
);
NavbarMenuItem.displayName = 'NavbarMenuItem';

interface NavbarMenuLinkProps extends NavbarMenuItemProps {
  path: To;
}

const NavbarMenuLink = forwardRef<HTMLLIElement, NavbarMenuLinkProps>(
  ({ path, children, ...props }, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = (isString(path) ? path : path.pathname) || '';

    return (
      <NavbarMenuItem
        {...props}
        ref={ref}
        selected={location.pathname.startsWith(pathname)}
        onClick={() => {
          navigate(path);
        }}
      >
        {children}
      </NavbarMenuItem>
    );
  },
);
NavbarMenuLink.displayName = 'NavbarMenuLink';

export function AppNavbarMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAdmin, isSuperUser, logout } = useUserState();
  const { showOnboarding } = useOnboardingState();
  const { setDrawerOpen } = useNavbarContext();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isSubscriptionEnabled = useFeatureToggle(
    'update-credit-card-info.enabled',
  );
  const showSubscription =
    (isAdmin || isSuperUser) &&
    user?.shipper.is_self_serve &&
    isSubscriptionEnabled;

  const shipper = user?.shipper;

  const shipperType = shipper?.shipper_type;
  const canManageUsers = isAdmin || isSuperUser;

  const superPayWarning =
    shipper?.super_pay_settings?.alert_status === 'WARNING';
  const superPayError = shipper?.super_pay_settings?.alert_status === 'ERROR';

  const { isAdvancedTier } = useProductTiers();

  const match = matchPath(location.pathname, '/getting-started');

  return (
    <Stack space="medium">
      {shipperType === 'BROKER' && showOnboarding && (
        <NavbarItem
          key="Getting Started"
          label="Getting Started"
          aria-label="Getting Started"
          data-cy="getting_started_sidebar_item"
          active={!!match}
          icon={<BoltIcon />}
          onClick={() => {
            trackEventLegacy('Viewed Tab', { tab: 'Getting Started' });
            setCurrentTab('Getting Started');
            navigate('/getting-started');
          }}
        />
      )}
      <Wrapper
        active={!!anchor}
        onClick={(event) => {
          setAnchor(event.currentTarget);
        }}
      >
        <NavbarAvatar
          title={shipper?.name}
          subtitle={user?.username}
          src={shipper?.logo_url}
        >
          {getInitials(shipper?.name || '')}
        </NavbarAvatar>
      </Wrapper>

      <Menu
        open={!!anchor}
        anchorEl={anchor}
        autoFocus={false}
        onClose={() => {
          setAnchor(null);
        }}
        PaperProps={{
          style: { width: 240 },
          onClick: () => {
            setAnchor(null);
            setDrawerOpen(false);
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {shipperType === 'BROKER' && [
          <NavbarMenuLink key="1" path="/profile" icon={<ProfileIcon />}>
            Profile
          </NavbarMenuLink>,

          <ListDivider key="2" />,

          <Box key="3" padding="xsmall" paddingLeft="small">
            <Typography variant="h6" style={{ color: Color.Dark100 }}>
              SETTINGS
            </Typography>
          </Box>,
        ]}

        {shipperType === 'BROKER' && (
          <NavbarMenuLink path="/general-settings" icon={<SettingsIcon />}>
            General
          </NavbarMenuLink>
        )}

        {shipperType === 'BROKER' && (
          <NavbarMenuLink
            path={{
              pathname: '/superpay',
              search: 'utm_medium=SuperPay Menu',
            }}
            icon={<MonetizationOn />}
            hasError={superPayError}
            hasWarning={superPayWarning}
          >
            SuperPay
          </NavbarMenuLink>
        )}

        {shipperType === 'BROKER' && showSubscription && (
          <NavbarMenuLink
            path={{
              pathname: '/subscription',
              search: 'utm_medium=Subscription Details',
            }}
            icon={<AccountBalanceWallet />}
          >
            Subscription Details
          </NavbarMenuLink>
        )}

        {shipperType === 'BROKER' && (
          <NavbarMenuLink
            path={{
              pathname: '/super-loadboard-settings',
              search: 'utm_medium=Super Loadboard Menu',
            }}
            icon={<BulletinBoardIcon />}
            shouldShowNewTag={true}
          >
            Super Loadboard
          </NavbarMenuLink>
        )}

        {shipperType === 'BROKER' && canManageUsers && (
          <NavbarMenuLink path="/users" icon={<UsersIcon />}>
            Manage Users
          </NavbarMenuLink>
        )}

        {shipperType === 'BROKER' && isAdvancedTier && (
          <NavbarMenuLink
            path="/manage-fields"
            icon={<ManageOrderFieldsIcon />}
          >
            Manage Order Fields
          </NavbarMenuLink>
        )}

        {shipperType === 'BROKER' && [
          <NavbarMenuLink
            key="1"
            path="/notification-settings"
            icon={<NotificationsIcon />}
          >
            Notifications
          </NavbarMenuLink>,

          <ListDivider key="2" />,
        ]}

        <NavbarMenuItem
          icon={<LogoutIcon />}
          onClick={() => {
            logout();
            navigate('/signin');
          }}
        >
          Log Out
        </NavbarMenuItem>
      </Menu>
    </Stack>
  );
}
