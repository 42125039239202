import Order from 'shared/types/order';
import { RouteStatus } from 'shared/types/routeStatus';

export function mapOrderToRouteStatus(order: Order): RouteStatus {
  if (order.is_archived) {
    return 'archived';
  }

  if (order.is_on_hold) {
    return 'on_hold';
  }

  if (order.is_offer_canceled_by_carrier) {
    return 'order_canceled';
  }

  switch (order.status) {
    case 'canceled':
      return 'order_canceled';
    case 'submitted':
      return 'new';
    case 'scheduled':
      return 'new';
    case 'completed':
      return 'delivered';
    default:
      return order.status;
  }
}
