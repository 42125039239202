import { Close } from '@material-ui/icons';
import { Color, SuperDispatchTheme } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { TextOverflow } from 'shared/ui/TextOverflow';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    z-index: 3;
    position: absolute;
    bottom: auto;
    top: 40px;
    width: 450px;
    height: fit-content;
    border-radius: 2px;
    padding: 12px;
    background-color: ${Color.White};
    box-shadow: 1px 3px 6px #d2d6dd;

    ${theme.breakpoints.down('sm')} {
      left: 0;
      width: 100%;
    }
  `,
);

export const Item = styled.div`
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid ${Color.Silver500};
  color: #1b2638;
  background-color: ${Color.White};
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 8px;
  position: relative;
  word-break: break-word;

  &[data-active='true'] {
    border-color: #0075ffa6;
    background: #0075ff14;
  }
`;

export const Label = styled.span`
  line-height: 20px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 6px;
  cursor: pointer;
  white-space: nowrap;
`;

export const Remove = styled(Close)`
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 9px;
  vertical-align: middle;
  fill: ${Color.Dark200};
  cursor: pointer;
  position: relative;
  top: -1px;

  &:hover {
    fill: #666a6f;
  }
`;

export interface InputControlProps {
  active: boolean;
  label: ReactNode;
  children?: ReactNode;
  onRemove: () => void;
  onSelect: () => void;
}

export function InputControl({
  active,
  label,
  onSelect,
  onRemove,
  children,
}: InputControlProps) {
  return (
    <>
      <Item
        tabIndex={0}
        data-active={active}
        onClick={onSelect}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSelect();
          }
        }}
      >
        <Label>
          <TextOverflow size={256}>{label}</TextOverflow>
          <Remove
            onClick={(event) => {
              event.stopPropagation();
              onRemove();
            }}
          />
        </Label>
      </Item>
      {active && <Wrapper>{children}</Wrapper>}
    </>
  );
}
