import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Stack } from '@superdispatch/ui';
import { Button, DescriptionLineItem } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { useField } from 'formik';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import { useExpectedChargeDateSchedule } from 'superpay/data/SuperPayAPI';

interface ScheduleDialogProps {
  onClick: () => void;
  onCancel: () => void;
  isOpen: boolean;
  isLoading: boolean;
  totalCharge?: number;
  ordersCount?: number;
}

export function SuperPayScheduleDialog({
  onClick,
  onCancel,
  isOpen,
  totalCharge,
  ordersCount,
  isLoading,
}: ScheduleDialogProps) {
  const [{ value: expectedDate }] = useField<string | null>('expected_date');
  const { data: expectedChargeData } =
    useExpectedChargeDateSchedule(expectedDate);

  const ordersCountTitle =
    ordersCount && ordersCount > 1 ? `(${ordersCount} orders)` : '';

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h3">Schedule SuperPay</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack space="small">
          <DescriptionLineItem
            title={
              <Typography color="textSecondary">
                Total Charge {ordersCountTitle}
              </Typography>
            }
          >
            <Typography variant="h5">
              <Price value={totalCharge} />
            </Typography>
          </DescriptionLineItem>

          {expectedChargeData?.expected_charge_date && (
            <DescriptionLineItem
              title={
                <Typography color="textSecondary">Charge Scheduled</Typography>
              }
            >
              <Typography variant="h5">
                <FormattedDate
                  variant="Date"
                  format="DateISO"
                  date={expectedChargeData.expected_charge_date}
                />
              </Typography>
            </DescriptionLineItem>
          )}

          {expectedDate && (
            <DescriptionLineItem
              title={
                <Typography color="textSecondary">Deposit Expected</Typography>
              }
            >
              <Typography variant="h5">
                <FormattedDate
                  variant="Date"
                  format="DateISO"
                  date={expectedDate}
                />
              </Typography>
            </DescriptionLineItem>
          )}

          <Divider />
          <Stack space="xsmall">
            <Typography color="textSecondary">
              Order changes to <SuperPayLogo /> method
            </Typography>
            <Typography color="textSecondary">
              Inspection type will be changed to Advanced.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="neutral" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onClick} variant="primary" pending={isLoading}>
          Schedule SuperPay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
