import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';

const reasons = [
  'MANUALLY_PUT_PAYMENT_ON_HOLD',
  'VEHICLE_MARKED_WITH_DAMAGES',
  'VEHICLE_IS_NOT_PICKED_UP',
  'PICKUP_CUSTOMER_SIGNATURE_LOCATION_NOT_EXISTS',
  'PICKUP_CUSTOMER_SIGNATURE_IS_NOT_CLOSE',
  'PICKUP_CUSTOMER_SIGNATURE_NOT_EXISTS',
  'PICKUP_ACTUAL_LOCATION_NOT_EXISTS',
  'PICKUP_ACTUAL_LOCATION_IS_NOT_CLOSE',
  'PICKUP_VIN_SCANNED_LOCATION_NOT_EXISTS',
  'PICKUP_VIN_SCANNED_LOCATION_IS_NOT_CLOSE',
  'DELIVERY_VIN_SCANNED_LOCATION_NOT_EXISTS',
  'DELIVERY_VIN_SCANNED_LOCATION_IS_NOT_CLOSE',
  'PICKUP_INSPECTION_PHOTOS_LOCATION_NOT_EXISTS',
  'PICKUP_INSPECTION_PHOTOS_LOCATION_IS_NOT_CLOSE',
  'PICKUP_MARKED_MANUALLY_BY_CARRIER',
  'DELIVERY_CUSTOMER_SIGNATURE_LOCATION_NOT_EXISTS',
  'DELIVERY_CUSTOMER_SIGNATURE_IS_NOT_CLOSE',
  'DELIVERY_CUSTOMER_SIGNATURE_NOT_EXISTS',
  'DELIVERY_ACTUAL_LOCATION_NOT_EXISTS',
  'DELIVERY_ACTUAL_LOCATION_IS_NOT_CLOSE',
  'DELIVERY_INSPECTION_PHOTOS_LOCATION_NOT_EXISTS',
  'DELIVERY_INSPECTION_PHOTOS_LOCATION_IS_NOT_CLOSE',
  'DELIVERY_MARKED_MANUALLY_BY_CARRIER',
] as const;

export type DeliveryVerificationFailureReasonType = typeof reasons[number];
export const orderDeliveryVerificationSchema = yupObject({
  verified_at: yupDateString('DateISO'),
  is_succeed: yupBoolean(),
  fail_reasons: yupArray(yupString().oneOf(reasons)),
});
