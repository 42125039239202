import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { DescriptionLineItem } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { UsageDetails } from './useUsageDetails';
import { calculateOveragesTotalPrice } from './utils';

export function PricingSectionTotalPrice({
  usages,
  monthlyPlanPrice,
}: {
  usages: UsageDetails['usages'];
  monthlyPlanPrice: number;
}) {
  const total = monthlyPlanPrice + calculateOveragesTotalPrice(usages);

  return (
    <Stack space="xsmall">
      <DescriptionLineItem title="Total">
        <Typography variant="h4">
          {formatCurrency(total, {
            maximumFractionDigits: 2,
          })}
        </Typography>
      </DescriptionLineItem>

      <Typography align="right" color="textSecondary">
        Plus applicable taxes and usage fees
      </Typography>
    </Stack>
  );
}
