import { parseDate } from '@superdispatch/dates';
import { renderChildren, useVisibilityObserver } from '@superdispatch/ui';
import { DateTime } from 'luxon';
import {
  ReactNode,
  RefCallback,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { trackEvent } from '../../../shared/helpers/AnalyticsHelpers';
import { Order } from '../../../shared/types/order';
import { Step } from '../../../shared/types/step';
import { OrderRequestDTO } from '../../data/dto/OrderRequestDTO';

interface PostingLoadCardEventProps {
  order: Order;
  request: OrderRequestDTO;
  position: number;
  children: (ref: RefCallback<HTMLElement | undefined>) => ReactNode;
}

function isDateFit(step: Step | undefined, date: string | null) {
  const orderDateStart = parseDate(step?.scheduled_at);
  const orderDateEnd = parseDate(step?.scheduled_ends_at);
  const requestDate = parseDate(date);

  if (step?.date_type !== 'estimated') {
    return true; // for other date types since they cannot be out of preferred range
  }
  return orderDateStart <= requestDate && orderDateEnd >= requestDate;
}

function trackViewedRequestEvent(order: Order, request: OrderRequestDTO) {
  const brokerRecords = request.broker_records;
  const requestPrice = request.counter_offer_price || request.price;
  const changedAmount = Number(requestPrice) - Number(order.price);
  const is_bid = !!changedAmount;

  trackEvent('Shipper Viewed Carrier Request', {
    order_guid: order.guid,
    request_guid: request.guid,
    carrier_guid: request.carrier_guid,
    pickup_date_type: order.pickup?.date_type,
    delivery_date_type: order.delivery?.date_type,
    carrier_overall_rating: request.carrier_rating_stats?.overall_rating,
    carrier_total_rating_count:
      request.carrier_rating_stats?.total_rating_count,
    is_bid,
    viewed_at_time: DateTime.local()
      .startOf('second')
      .toISO({ suppressMilliseconds: true }),
    request_status: request.status,
    is_pickup_date_match: isDateFit(order.pickup, request.pickup_date),
    is_delivery_date_match: isDateFit(order.delivery, request.delivery_date),
    is_carrier_verified: request.is_super_carrier,
    is_carrier_approved: brokerRecords?.approved,
    is_carrier_can_instantly_book: brokerRecords?.preferred,
  });
}

export function OrderRequestEventObserver({
  request,
  order,
  children,
  position,
}: PostingLoadCardEventProps) {
  const [node, setNode] = useState<HTMLElement | null>(null);

  const visibilityState = useDebouncedValue(
    useVisibilityObserver(node, { threshold: 1 }),
    600,
  );

  useEffect(() => {
    if (visibilityState === 'visible') {
      trackViewedRequestEvent(order, request);
    }
  }, [visibilityState, request, order, position]);

  const ref = useCallback<RefCallback<HTMLElement>>(
    (nodeElement) => {
      setNode(nodeElement);
    },
    [setNode],
  );

  return renderChildren(children(ref));
}
