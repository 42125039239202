import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { usePrivateNetworkAPIs } from '../data/PrivateNetworkAPI';
import {
  PrivateNetworkCreateGroupDTO,
  privateNetworkCreateGroupSchema,
  PrivateNetworkGroupDTO,
} from '../data/PrivateNetworkDTO';

export interface PrivateNetworkDialogContentProps {
  onClose: () => void;
  source: string;
  group: PrivateNetworkGroupDTO | undefined;
}

export function PrivateNetworkDialogContent({
  onClose,
  source,
  group,
}: PrivateNetworkDialogContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { createPrivateNetworkGroup, renamePrivateNetworkGroup } =
    usePrivateNetworkAPIs();
  const formik = useFormikEnhanced<PrivateNetworkCreateGroupDTO, unknown>({
    initialValues: {
      name: group?.name || '',
    },
    validationSchema: privateNetworkCreateGroupSchema,
    onSubmit: (values) => {
      values.name = values.name.replace(/\s{2,}/g, ' ').trim();
      if (group?.guid) {
        return renamePrivateNetworkGroup(values.name, group.guid);
      }
      return createPrivateNetworkGroup(values.name);
    },
    onSubmitSuccess: (_, values) => {
      addSnackbar(
        group?.guid
          ? 'Private network group renamed'
          : `'${values.name}' group created`,
        {
          variant: 'success',
        },
      );
      trackEvent(
        `Shipper ${group?.guid ? 'renamed' : 'created'} Private network group `,
        {
          utm_medium: source,
        },
      );
      onClose();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message || 'Failed to create group', {
        variant: 'error',
      });
    },
  });
  const { isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Box width="360px">
        <DialogTitle>
          <Typography variant="h3">
            {group?.guid ? 'Rename Group' : 'Create Group'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormikMaxLengthTextField
            name="name"
            maxLength={18}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Columns collapseBelow="tablet" space="small">
            <Column width="fluid" />
            <Column width="content">
              <Button
                variant="neutral"
                onClick={() => {
                  onClose();
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Column>
            <Column width="content">
              <Button
                disabled={!formik.dirty || !formik.isValid}
                type="submit"
                pending={isSubmitting}
                onClick={() => {
                  void formik.submitForm();
                }}
              >
                {group?.guid ? 'Rename' : 'Create'}
              </Button>
            </Column>
          </Columns>
        </DialogActions>
      </Box>
    </FormikProvider>
  );
}
