import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { VerificationStatusBanner } from './core/VerificationStatusBanner';
import { ShipperProfileDTO } from './data/ShipperProfileDTO';
import { Section } from './Section';
import { AboutUsSection } from './sections/AboutUsSection';
import { BondInsuranceSection } from './sections/BondInsuranceSection';
import { CarrierRequirementsSection } from './sections/CarrierRequirementsSection';
import { CentralDispatchIntegration } from './sections/CentralDispatchIntegration';
import { ContactInfoSection } from './sections/ContactInfoSection';
import { LoadIDEdit } from './sections/LoadIDEdit';
import { isEmptyLoadID, LoadIDView } from './sections/LoadIDView';
import { PaymentInfoSection } from './sections/PaymentInfoSection';
import { ReferencesSection } from './sections/ReferencesSection';
import { TermsAndConditionsEdit } from './sections/TermsAndConditionsEdit';
import {
  isEmptyTermsAndConditions,
  TermsAndConditionsView,
} from './sections/TermsAndConditionsView';

interface ShipperProfileOverviewLayoutProps {
  infoBanner?: ReactNode;
  aboutUs?: ReactNode;
  paymentInfo?: ReactNode;
  bondInsurance?: ReactNode;
  internalInfo?: ReactNode;
  centralIntegration?: ReactNode;
  contactInfo?: ReactNode;
  carrierRequirements?: ReactNode;
  references?: ReactNode;
  termsAndConditions?: ReactNode;
}

function ShipperProfileOverviewLayout({
  infoBanner,
  aboutUs,
  paymentInfo,
  bondInsurance,
  internalInfo,
  centralIntegration,
  contactInfo,
  carrierRequirements,
  references,
  termsAndConditions,
}: ShipperProfileOverviewLayoutProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');

  return platform === 'desktop' ? (
    <Stack space="small">
      {infoBanner}
      <Columns space="small">
        <Column>
          <Stack space="small">
            {aboutUs}
            {paymentInfo}
            {bondInsurance}
            {internalInfo}
            {centralIntegration}
          </Stack>
        </Column>
        <Column>
          <Stack space="small">
            {contactInfo}
            {carrierRequirements}
            {references}
            {termsAndConditions}
          </Stack>
        </Column>
      </Columns>
    </Stack>
  ) : (
    <Stack space="small">
      {infoBanner}
      {aboutUs}
      {contactInfo}
      {paymentInfo}
      {bondInsurance}
      {carrierRequirements}
      {internalInfo}
      {centralIntegration}
      {references}
      {termsAndConditions}
    </Stack>
  );
}

interface ShipperProfileOverviewProps {
  profile: ShipperProfileDTO;
}

export function ShipperProfileOverview({
  profile,
}: ShipperProfileOverviewProps) {
  return (
    <ShipperProfileOverviewLayout
      infoBanner={
        <VerificationStatusBanner status={profile.verification_status} />
      }
      aboutUs={<AboutUsSection profile={profile} />}
      paymentInfo={<PaymentInfoSection profile={profile} />}
      bondInsurance={<BondInsuranceSection profile={profile} />}
      internalInfo={
        <Section
          title="Order ID"
          tooltip="The Prefix and Initial Number informed here will be used to generate the Order ID during order creation"
          profile={profile}
          isEmpty={isEmptyLoadID}
          viewComponent={LoadIDView}
          editComponent={LoadIDEdit}
        />
      }
      centralIntegration={<CentralDispatchIntegration />}
      contactInfo={<ContactInfoSection profile={profile} />}
      carrierRequirements={<CarrierRequirementsSection profile={profile} />}
      references={<ReferencesSection profile={profile} />}
      termsAndConditions={
        <Section
          title="Terms and Conditions"
          profile={profile}
          isEmpty={isEmptyTermsAndConditions}
          viewComponent={TermsAndConditionsView}
          editComponent={TermsAndConditionsEdit}
        />
      }
    />
  );
}
