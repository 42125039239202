import { AuthenticatedRouteObject } from 'AppRouter';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSelfServeOnlyProductTour } from 'shared/hooks/useProductTour';
import { AccessRestrictedDialog } from './core/actions/AccessRestrictedDialog';
import { OrderCreatePage } from './create/OrderCreatePage';
import { OrderEditPage } from './edit/OrderEditPage';
import { OrderImportPage } from './import/OrderImportPage';
import { OrdersListPage } from './list/OrdersListPage';
import { OrderRequestsPage } from './order-requests/OrderRequestsPage';
import { OrderPreviewPage } from './preview/OrderPreviewPage';
import { OrderViewPage } from './view/OrderViewPage';

const CombineOrdersPageLazy = lazy(
  () => import('./combine-orders/CombineOrdersPage'),
);

const OrderSplitLoadsPageLazy = lazy(
  () => import('./split-loads/OrderSplitLoadsPage'),
);

const OrderSplitPageLazy = lazy(() => import('./order-split/OrderSplitPage'));

export const ordersRoute: AuthenticatedRouteObject = {
  path: 'orders',
  Component: OrdersContainer,
  children: [
    {
      path: 'create',
      Component: OrderCreatePage,
    },
    {
      path: 'edit/:guid',
      Component: OrderEditPage,
    },
    {
      path: 'view/:guid/*',
      element: <OrderViewPage isActive={true} path="/orders/view" />,
    },
    {
      path: 'inactive/view/:guid/*',
      element: <OrderViewPage isActive={false} path="/orders/inactive/view" />,
    },
    {
      path: 'split/:guid',
      Component: OrderSplitPageLazy,
    },
    {
      path: 'split-loads/:guid',
      element: <OrderSplitLoadsPageLazy />,
    },
    {
      path: 'combine',
      Component: CombineOrdersPageLazy,
    },
    {
      path: 'preview/:type/:orderID',
      Component: OrderPreviewPage,
    },
    {
      path: 'import',
      Component: OrderImportPage,
    },
    {
      path: 'requests',
      Component: OrderRequestsPage,
    },
    {
      path: ':status?',
      Component: OrdersListPage,
    },
  ],
};

function OrdersContainer() {
  useTrackEvent('[STMS] Viewed Orders Page');
  useOrdersPageTour();
  return (
    <>
      <Outlet />
      <AccessRestrictedDialog />
    </>
  );
}

function useIsFirstOrderPostedToLb() {
  const { data: orderCounts } = useOrderCounts();

  if (!orderCounts) {
    return false;
  }

  const countersThatShouldBeZero: Array<keyof typeof orderCounts> = [
    'new',
    'pending',
    'declined',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'canceled',
    'requests',
  ];

  return (
    orderCounts.posted_to_lb > 0 &&
    countersThatShouldBeZero.every((key) => orderCounts[key] === 0)
  );
}

function useOrdersPageTour() {
  const { data: orderCounts } = useOrderCounts();

  useSelfServeOnlyProductTour({
    tourKey: 'self_serve_reboot_orders_page',
    enabled: orderCounts?.total === 0,
  });

  useSelfServeOnlyProductTour({
    tourKey: 'self_serve_reboot_orders_page_highlight_requests',
    enabled: useIsFirstOrderPostedToLb(),
  });
}
