import {
  MOOV_BUSINESS_TYPES,
  MOOV_MCC_CODES,
  USA_STATES,
} from 'shared/helpers/superpay/MoovDTO';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import {
  yupBoolean,
  yupEnum,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, string } from 'yup';

const ALPANUMERIC_SPECIAL_CHARACTERS_WITH_SPACE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9&()\[\]"',.-]+(\s[a-zA-Z0-9&()\[\]"',.-]+)*$/gm;

export const businessDetailsSchema = yupObject({
  legalBusinessName: yupString()
    .max(64)
    .required('Enter legal business name')
    .matches(
      ALPANUMERIC_SPECIAL_CHARACTERS_WITH_SPACE_REGEX,
      'Only english letters and numbers are allowed',
    )
    .defined(),
  businessType: yupEnum(MOOV_BUSINESS_TYPES, 'llc').required().defined(),
  einNumber: yupString()
    .test('only ein digits', 'Invalid EIN number', hasOnlyDigits)
    .length(9)
    .required('Invalid EIN number')
    .defined(),
  mccNumber: yupEnum(MOOV_MCC_CODES, '4789')
    .required('Invalid MCC number')
    .defined(),
  description: yupString()
    .min(10, 'Description must be at least 10 characters.')
    .max(100)
    .required('Enter description')
    .defined(),
});
export type BusinessDetailsDTO = InferType<typeof businessDetailsSchema>;

export const businessContactsSchema = yupObject({
  phoneNumber: yupPhone().nullable().required('Enter phone number').defined(),
  website: string()
    .url('Enter a valid website URL (e.g. https://www.superdispatch.com)')
    .max(100)
    .optional(),
  streetAddress: yupString().max(60).required('Enter street address').defined(),
  zip: yupString()
    .test('only zip digits', 'Invalid ZIP', hasOnlyDigits)
    .length(5)
    .required('Enter ZIP')
    .defined(),
  city: yupString().max(24).required('Enter city').defined(),
  state: yupString()
    .oneOf(USA_STATES, 'Invalid state')
    .required('Select state')
    .defined(),
});
export type BusinessContactsDTO = InferType<typeof businessContactsSchema>;

export const businessSchema = businessDetailsSchema
  .concat(businessContactsSchema)
  .concat(
    yupObject({
      termsConditionsAccept: yupBoolean().oneOf(
        [true],
        'To proceed, you must accept our terms and conditions',
      ),
    }),
  );
export type BusinessDTO = InferType<typeof businessSchema>;
