import { Typography } from '@material-ui/core';
import { FormikRadioGroupField, useFormikEnhanced } from '@superdispatch/forms';
import {
  CheckboxField,
  DrawerActions,
  RadioField,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import {
  cleanCargoLimits,
  fillCargoLimits,
  validateCargoLimits,
} from '../data/ShipperProfileUtils';
import { CarrierRequirementsDetails } from './CarrierRequirementsDetails';
import { InsuranceRequirementsMinimumFields } from './core/InsuranceRequirementsMinimumFields';

interface InsuranceRequirementsEditContentProps {
  profile: ShipperProfileDTO;
  onClose: () => void;
}

export function InsuranceRequirementsEditDrawerContent({
  profile,
  onClose,
}: InsuranceRequirementsEditContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateAdditionalCarrierRequirements } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    initialValues: fillCargoLimits(profile),
    validate: (values) => {
      const limits = values.carrier_insurance_requirements;
      if (limits && limits.length > 1) {
        return validateCargoLimits(values);
      }
      return {};
    },
    onSubmit: (values) => {
      return updateAdditionalCarrierRequirements(cleanCargoLimits(values));
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('Carrier Requirements', {
        carrier_certificate_of_insurance:
          updatedValues.carrier_certificate_of_insurance,
        carrier_requirements: updatedValues.carrier_requirements,
        cargo_limit_units_filled:
          updatedValues.carrier_insurance_requirements?.length,
      });

      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to update profile', { variant: 'error' });
    },
  });

  const hasCertificateInsurance =
    !!formik.values.carrier_certificate_of_insurance;

  return (
    <FormikDrawerContent
      formik={formik}
      title="Insurance Requirements"
      actions={
        <DrawerActions>
          <Button
            type="submit"
            variant="primary"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
      DrawerContentProps={{
        style: {
          width: '432px',
        },
      }}
    >
      <Stack space="large">
        <Stack>
          <CheckboxField
            checked={hasCertificateInsurance}
            label={
              <Typography variant="h4">Certificate of Insurance</Typography>
            }
            onChange={(_, checked) => {
              formik.setFieldValue(
                'carrier_certificate_of_insurance',
                !checked ? null : 'CERTIFICATE_HOLDER',
              );
            }}
          />

          <Box paddingLeft="large">
            <Stack>
              <CarrierRequirementsDetails profile={profile} />

              <FormikRadioGroupField name="carrier_certificate_of_insurance">
                <RadioField
                  value="CERTIFICATE_HOLDER"
                  label="List as Certificate Holder"
                  disabled={!hasCertificateInsurance}
                />
                <RadioField
                  value="ADDITIONALLY_INSURED"
                  label="List as Additionally Insured"
                  disabled={!hasCertificateInsurance}
                />
              </FormikRadioGroupField>
            </Stack>
          </Box>
        </Stack>
        <InsuranceRequirementsMinimumFields />
      </Stack>
    </FormikDrawerContent>
  );
}

interface InsuranceRequirementsEditDrawerProps
  extends InsuranceRequirementsEditContentProps {
  open: boolean;
}

export function InsuranceRequirementsEditDrawer({
  open,
  onClose,
  profile,
}: InsuranceRequirementsEditDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      {open && (
        <InsuranceRequirementsEditDrawerContent
          profile={profile}
          onClose={onClose}
        />
      )}
    </FormikDrawer>
  );
}
