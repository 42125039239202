import { MenuItem } from '@material-ui/core';
import { FormikDateField, FormikTextField } from '@superdispatch/forms';
import {
  formatDateType,
  formatPaymentMethod,
  formatPaymentTerm,
  isValidPaymentTerm,
  isValidSuperPayTerm,
  listDateTypes,
  PaymentMethod,
} from '@superdispatch/sdk';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { usePaymentOptions } from 'core/SuperPayUtils';
import { useField } from 'formik';
import { DateTime } from 'luxon';
import { ChangeEvent, useMemo } from 'react';
import { AllPaymentTerm } from 'shared/dto/Order/CarrierPaymentDTO';
import { FormikCurrencyField } from 'shared/form/FormikCurrencyField';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import {
  SelectSuperPayHelperText,
  useSuperPayEnabled,
  useSuperPaySuspend,
} from 'shared/helpers/superpay/SuperPayHelpers';
import Order from 'shared/types/order';
import { required } from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { OrderActionSource } from '../actions/OrderActionsAnalytics';

const StyledFormikDateField = styled(FormikDateField)`
  & .MuiFormLabel-asterisk,
  & .MuiFormHelperText-root.Mui-error {
    display: none;
  }
`;

export function SendOfferFields({
  order,
  source,
}: {
  order?: Order;
  source: OrderActionSource;
}) {
  const isSuperPayEnabled = useSuperPayEnabled();
  const isSuperPaySuspend = useSuperPaySuspend();
  const hasSuperPayStatus = !!order?.payment?.super_pay?.status;
  const initialTime = useMemo(() => startOfWorkDay(DateTime.local()), []);
  const [{ value: methodValue }, , { setValue: setMethod }] =
    useField<PaymentMethod>('payment_method');
  const [{ value: termsValue }, , { setValue: setTerms }] =
    useField<AllPaymentTerm>('payment_terms');
  const isDisableSuperPayMethod = order?.payment?.superpay_canceled;
  const { paymentTerms, paymentMethods } = usePaymentOptions(
    methodValue,
    isDisableSuperPayMethod,
  );

  const onPaymentMethodChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    if (value === 'superpay' && !isValidSuperPayTerm(termsValue)) {
      setTerms('1_3_days');
    } else if (!isValidPaymentTerm(termsValue)) {
      setTerms('other');
    }
  };

  return (
    <Stack space="small">
      <Columns space="small">
        <Column width="1/2">
          <FormikTextField
            select={true}
            fullWidth={true}
            name="pickup.date_type"
            label="Pickup Date Type"
          >
            {listDateTypes().map((value) => (
              <MenuItem key={value} value={value}>
                {formatDateType(value)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>

        <Column width="1/2">
          <StyledFormikDateField
            required={true}
            fullWidth={true}
            name="pickup.scheduled_at"
            label="Carrier Pickup Date"
            CalendarProps={{ initialTime }}
          />
        </Column>
      </Columns>

      <Columns space="small">
        <Column width="1/2">
          <FormikTextField
            select={true}
            fullWidth={true}
            name="delivery.date_type"
            label="Delivery Date Type"
          >
            {listDateTypes().map((value) => (
              <MenuItem key={value} value={value}>
                {formatDateType(value)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>
        <Column width="1/2">
          <StyledFormikDateField
            required={true}
            fullWidth={true}
            name="delivery.scheduled_at"
            label="Carrier Delivery Date"
            CalendarProps={{ initialTime }}
          />
        </Column>
      </Columns>

      <Columns>
        <Column width="1/2">
          <Box paddingRight="xsmall">
            <FormikCurrencyField
              disabled={hasSuperPayStatus}
              name="price"
              label="Carrier price"
              validate={required}
              inputMode="decimal"
              fullWidth={true}
            />
          </Box>
        </Column>
      </Columns>

      <Columns space="small">
        <Column width="1/2">
          <FormikTextField
            select={true}
            fullWidth={true}
            name="payment_method"
            label="Payment Method"
            onChange={onPaymentMethodChange}
            disabled={hasSuperPayStatus}
            helperText={
              methodValue !== 'superpay' &&
              !isSuperPaySuspend &&
              isSuperPayEnabled &&
              !isDisableSuperPayMethod && (
                <SelectSuperPayHelperText
                  source={source}
                  onClick={() => {
                    setMethod('superpay');
                    setTerms('1_3_days');
                  }}
                />
              )
            }
          >
            {paymentMethods.map((value) => (
              <MenuItem key={value} value={value}>
                {formatPaymentMethod(value)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>
        <Column width="1/2">
          <FormikTextField
            disabled={hasSuperPayStatus}
            select={true}
            fullWidth={true}
            label="Terms"
            name="payment_terms"
          >
            {paymentTerms.map((value) => (
              <MenuItem key={value} value={value}>
                {formatPaymentTerm(value)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>
      </Columns>
    </Stack>
  );
}
