import { InfoTooltip } from '@superdispatch/ui';
import { OrderTagsAutocomplete } from 'core/dictionary/OrderTagsAutocomplete';
import { useField, useFormikContext } from 'formik';
import { useGeneralSettings } from 'general-settings/data/GeneralSettingsAPI';
import { OrderFormValues } from './OrderForm';

export function OrderTagsField() {
  const { data: settings } = useGeneralSettings();
  const { touched, errors } = useFormikContext<OrderFormValues>();
  const showError = Boolean(touched.tags && errors.tags); // Check if there are errors

  const [{ value = [] }, _meta, { setValue }] = useField<number[]>('tags');
  const stringValues = value.map(String); // order create/edit works with number type

  return (
    <OrderTagsAutocomplete
      value={stringValues}
      autoFocus={false}
      openOnFocus={false}
      error={showError}
      helperText={showError ? errors.tags : undefined}
      onChange={(updatedValue) => {
        if (settings && updatedValue.length <= settings.max_number_of_tags) {
          setValue(updatedValue.map(Number));
        }
      }}
      label={
        <InfoTooltip
          placement="top"
          title={
            <>
              Select a Tag from the list to assign it to the current order.
              <br />
              Selected tag will be displayed in the order list.
            </>
          }
        >
          Tags
        </InfoTooltip>
      }
    />
  );
}
