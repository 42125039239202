import {
  ButtonBase,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  formatRelativeTime,
  parseDate,
  stringifyDate,
} from '@superdispatch/dates';
import {
  FormikContextTypeEnhanced,
  FormikDateField,
  FormikNumberField,
} from '@superdispatch/forms';
import {
  Button,
  Column,
  Columns,
  InfoTooltip,
  Inline,
  Stack,
} from '@superdispatch/ui';
import {
  Box,
  DescriptionItem,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import {
  PriceNegotiationDTO,
  PriceNegotiationFormDTO,
} from 'orders/data/dto/PriceNegotiationDTO';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { FormikCurrencyField } from 'shared/form/FormikCurrencyField';
import { FormikDrawerContent } from 'shared/form/FormikDrawer';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { Order } from 'shared/types/order';
import { AcceptableDateField } from './AcceptableDateField';
import { FormattedStep } from './FormattedStep';
import { PriceNegotiationPrediction } from './PriceNegotiationPrediction';
import { PriceNegotiationWarning } from './PriceNegotiationWarning';

function ExpandButton({
  isExpanded,
  onClick,
  children,
}: {
  children: ReactNode;
  onClick: () => void;
  isExpanded: boolean;
}) {
  return (
    <ButtonBase onClick={onClick}>
      <Inline space="xsmall" verticalAlign="center">
        <TextBox color="secondary">{children}</TextBox>

        {isExpanded ? (
          <KeyboardArrowUp color="action" />
        ) : (
          <KeyboardArrowDown color="action" />
        )}
      </Inline>
    </ButtonBase>
  );
}

interface PriceNegotiationDrawerFormProps {
  order: Order;
  actions: ReactNode;
  form: FormikContextTypeEnhanced<PriceNegotiationFormDTO, PriceNegotiationDTO>;
}

export function PriceNegotiationDrawerForm({
  order,
  form,
  actions,
}: PriceNegotiationDrawerFormProps) {
  const {
    setFieldValue,
    values,
    values: {
      increase_every,
      acceptable_delivery_days,
      acceptable_pickup_days,
    },
  } = form;

  const [isOrderDetailsExpanded, setOrderDetailsExpanded] = useState(false);
  const createdOrderDate = parseDate(order.created_at, { format: 'JodaISO' });
  const firstVehicle = order.vehicles?.[0];
  const showGradualIncrease = increase_every != null;
  const [showAcceptableDates, $showAcceptableDates] = useState(
    () => !!acceptable_delivery_days || !!acceptable_pickup_days,
  );

  const today = useMemo(
    () => stringifyDate(DateTime.local().startOf('day'), { format: 'JodaISO' }),
    [],
  );

  const isBudgetExceedsTariff =
    order.customer_payment?.tariff != null &&
    order.customer_payment.tariff > 0 &&
    order.price != null &&
    order.customer_payment.tariff - order.price <
      form.values.negotiation_budget;

  const isBudgetExceedsTotalCarrierPrice =
    order.price != null && order.price < form.values.negotiation_budget;

  // reset acceptable dates when order date type changed
  useEffect(() => {
    if (order.pickup?.date_type !== 'estimated') {
      setFieldValue('acceptable_pickup_days', 0);
    }

    if (order.delivery?.date_type !== 'estimated') {
      setFieldValue('acceptable_delivery_days', 0);
    }
  }, [setFieldValue, order.delivery?.date_type, order.pickup?.date_type]);

  return (
    <Box maxWidth={['100%', '432px']} paddingBottom="large">
      <FormikDrawerContent
        formik={form}
        actions={actions}
        title="Price Negotiation"
      >
        <Stack space="small">
          <TextBox color="secondary">
            Automate price negotiations for instantly bookable loads.{' '}
            <Link
              target="_blank"
              color="textSecondary"
              rel="noopener noreferrer"
              href="https://support.superdispatch.com/en/articles/9071057-shipper-tms-automated-price-negotiation-apn"
            >
              Learn more
            </Link>
          </TextBox>

          <Box
            borderTopWidth="small"
            borderBottomWidth="small"
            backgroundColor="Silver100"
            borderColor="Silver400"
            paddingTop="small"
            paddingBottom="small"
            paddingLeft={['medium', 'medium', 'large']}
            paddingRight={['medium', 'medium', 'large']}
            marginLeft={['-medium', '-medium', '-large']}
            marginRight={['-medium', '-medium', '-large']}
          >
            <Stack space="small">
              <TextBox variant="heading-5" color="secondary">
                Order Details
              </TextBox>

              {order.customer_payment?.tariff != null &&
                order.customer_payment.tariff > 0 && (
                  <DescriptionLineItem
                    title={<TextBox color="secondary">Total Tariff</TextBox>}
                  >
                    <TextBox>
                      {formatCurrency(order.customer_payment.tariff, {
                        maximumFractionDigits: 0,
                      })}
                    </TextBox>
                  </DescriptionLineItem>
                )}

              {order.price != null && (
                <DescriptionLineItem
                  title={
                    <TextBox color="secondary">Total Carrier Price</TextBox>
                  }
                >
                  <TextBox aria-label="Total Carrier Price">
                    {formatCurrency(order.price, {
                      maximumFractionDigits: 0,
                    })}
                  </TextBox>
                </DescriptionLineItem>
              )}

              {isOrderDetailsExpanded && (
                <Stack space="small">
                  <DescriptionItem label="Order Age">
                    {formatRelativeTime(order.created_at)}
                  </DescriptionItem>

                  <Stack space="xxsmall">
                    <Typography variant="h6" color="textSecondary">
                      Order Dates
                    </Typography>
                    <FormattedStep step={order.pickup} label="Pickup" />
                    <FormattedStep step={order.delivery} label="Delivery" />
                  </Stack>

                  <Stack space="none">
                    {!!firstVehicle && (
                      <Typography>
                        {VehicleDTO.formatVehicleModel({
                          year: firstVehicle.year,
                          make: firstVehicle.make || 'Unknown make',
                          model: firstVehicle.model,
                        })}
                      </Typography>
                    )}
                    {order.vehicles && order.vehicles.length > 1 && (
                      <Typography color="textSecondary">
                        +{order.vehicles.length - 1} more
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              )}

              <ExpandButton
                isExpanded={isOrderDetailsExpanded}
                onClick={() => {
                  setOrderDetailsExpanded((prev) => !prev);
                  if (!isOrderDetailsExpanded) {
                    trackEvent(
                      'Shipper Expanded Order Details on Price Negotiation',
                    );
                  }
                }}
              >
                {isOrderDetailsExpanded ? 'Show less' : 'Show more'}
              </ExpandButton>
            </Stack>
          </Box>

          <Stack space="large">
            <Stack space="xsmall">
              <FormikCurrencyField
                data-intercom-target="price negotiation budget"
                fullWidth={true}
                name="negotiation_budget"
                aria-label="Negotiation Budget"
                label={
                  <Stack>
                    <Typography variant="h4">Negotiation Budget</Typography>
                    <Typography color="textSecondary">
                      How much more you are willing to pay in addition to your
                      total carrier price.
                    </Typography>
                  </Stack>
                }
                inputProps={{ decimalScale: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              {isBudgetExceedsTariff ? (
                <PriceNegotiationWarning message="This budget raises max price above tariff, causing a loss." />
              ) : isBudgetExceedsTotalCarrierPrice ? (
                <PriceNegotiationWarning message="This budget exceeds the Total Carrier Price. Please review it to ensure accuracy." />
              ) : null}
            </Stack>

            {!showAcceptableDates ? (
              <Button
                data-intercom-target="add acceptable carrier dates"
                variant="text"
                onClick={() => {
                  if (order.pickup?.date_type === 'estimated') {
                    form.setFieldValue('acceptable_pickup_days', 3);
                  }

                  if (order.delivery?.date_type === 'estimated') {
                    form.setFieldValue('acceptable_delivery_days', 3);
                  }

                  $showAcceptableDates(true);
                }}
              >
                + Add Acceptable Carrier Dates
              </Button>
            ) : (
              <Stack space="small">
                <Columns space="small" align="center">
                  <Column width="content">
                    <TextBox variant="heading-4">
                      Acceptable Carrier Dates
                    </TextBox>
                    <InfoTooltip
                      title={
                        <>
                          Indicate flexibility of Requested <br />
                          Carrier Pickup/Delivery Dates.
                        </>
                      }
                    />
                  </Column>

                  <Column>
                    <Divider />
                  </Column>

                  <Column width="content">
                    <IconButton
                      size="small"
                      onClick={() => {
                        form.setFieldValue('acceptable_pickup_days', 0);
                        form.setFieldValue('acceptable_delivery_days', 0);
                        $showAcceptableDates(false);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Column>
                </Columns>

                <Columns space="small">
                  <Column width="1/2">
                    <AcceptableDateField
                      step={order.pickup}
                      label="Pickup Dates"
                      name="acceptable_pickup_days"
                    />
                  </Column>

                  <Column width="1/2">
                    <AcceptableDateField
                      step={order.delivery}
                      label="Delivery Dates"
                      name="acceptable_delivery_days"
                    />
                  </Column>
                </Columns>
              </Stack>
            )}

            {!showGradualIncrease ? (
              <Tooltip
                placement="right"
                title={
                  <>
                    Increase acceptable <br />
                    bid as order ages
                  </>
                }
              >
                <Button
                  data-intercom-target="add gradual increase"
                  variant="text"
                  onClick={() => {
                    form.setFieldValue('increase_by_percent', null);
                    form.setFieldValue('start_date', today);
                    form.setFieldValue('increase_every', {
                      value: null,
                      unit: 'DAYS',
                    });
                  }}
                >
                  + Add Gradual Increase
                </Button>
              </Tooltip>
            ) : (
              <Stack space="small">
                <Columns space="small" align="center">
                  <Column width="content">
                    <TextBox variant="heading-4">Gradual Increase</TextBox>
                  </Column>

                  <Column>
                    <Divider />
                  </Column>

                  <Column width="content">
                    <IconButton
                      size="small"
                      onClick={() => {
                        form.setFieldValue('increase_every', null);
                        form.setFieldValue('increase_by_percent', null);
                        form.setFieldValue(
                          'start_date',
                          createdOrderDate.startOf('day'),
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Column>
                </Columns>

                <Columns space="small">
                  <Column width="1/2">
                    <FormikDateField
                      fullWidth={true}
                      name="start_date"
                      label={
                        <InfoTooltip
                          title={
                            <>
                              Indicate when Price Negotiation will <br />
                              begin accepting bids and sending counter offers.
                            </>
                          }
                        >
                          Start Date
                        </InfoTooltip>
                      }
                      CalendarProps={{
                        initialTime: today,
                        disabledDays({ dateValue }) {
                          return dateValue < createdOrderDate.startOf('day');
                        },
                      }}
                    />
                  </Column>

                  <Column>
                    <div>{/*empty space for previous column size*/}</div>
                  </Column>
                </Columns>

                <Columns space="small">
                  <Column width="fluid">
                    <FormikNumberField
                      fullWidth={true}
                      label="Increase by"
                      name="increase_by_percent"
                      inputProps={{ decimalScale: 0 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Column>

                  <Column width="fluid">
                    <FormikNumberField
                      label="Every"
                      fullWidth={true}
                      name="increase_every.value"
                      inputProps={{ decimalScale: 0 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body1" color="textSecondary">
                              days
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Column>
                </Columns>
              </Stack>
            )}
          </Stack>

          {!!values.increase_every?.value &&
            !!values.increase_by_percent &&
            Number.isFinite(values.negotiation_budget) && (
              <PriceNegotiationPrediction
                priceNegotiation={values}
                order={order}
              />
            )}
        </Stack>
      </FormikDrawerContent>
    </Box>
  );
}
