import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';
import { CarrierInsuranceRequirementsDTO } from '../../data/ShipperProfileDTO';

const Line = styled.div`
  height: 5px;
  width: 100%;
  min-width: 8px;
  margin-bottom: 4px;
  border-bottom: 1px dotted ${Color.Dark100};
`;

export interface CargoInsuranceMinimumTooltipProps {
  limits: CarrierInsuranceRequirementsDTO[];
}

export function CargoInsuranceMinimumTooltip({
  limits,
}: CargoInsuranceMinimumTooltipProps) {
  const lastIndex = limits.length - 1;

  if (limits.length === 1) {
    return <Typography color="textPrimary">Cargo Insurance Minimum</Typography>;
  }

  return (
    <ResponsiveTooltip
      placement="top"
      title={
        <Stack>
          {limits.map((limit, index) => (
            <Columns key={limit.trailer_size} space="xxsmall" align="bottom">
              <Column width="content">
                <Typography noWrap={true}>
                  {limit.trailer_size}
                  {lastIndex === index ? '+' : ''}{' '}
                  {formatPlural(limit.trailer_size || 0, 'unit', 'units')}
                </Typography>
              </Column>
              <Column width="fluid">
                <Line />
              </Column>
              <Column width="content">
                <Typography noWrap={true}>
                  {formatCurrency(limit.cargo_limit || 0, {
                    minimumFractionDigits: 0,
                  })}
                </Typography>
              </Column>
            </Columns>
          ))}
        </Stack>
      }
    >
      <Box>
        <Inline verticalAlign="center">
          <Typography color="textPrimary">Cargo Insurance Minimum</Typography>
          <Info color="action" fontSize="small" />
        </Inline>
      </Box>
    </ResponsiveTooltip>
  );
}
