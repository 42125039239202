import { Card, Divider, Link, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Color, Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, Button, DescriptionLineItem } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { useFormikContext } from 'formik';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { Waypoints2 } from 'shared/ui/Waypoints2';
import styled from 'styled-components';
import { useBillingPeriod } from './useBillingPeriod';
import { useSelectedSubscriptionPlan } from './useSelectedSubscriptionPlan';

const PlanPrice = styled(Typography)`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const DiscountPrice = styled(Typography)`
  color: ${Color.Dark300};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-decoration: line-through;
`;

const StyledDivider = styled(Divider)`
  background-color: ${Color.Silver500};
`;

export function PaymentSummaryBox() {
  const [billingPeriod] = useBillingPeriod();
  const {
    subscriptionPlan,
    isSubscriptionPlansLoading,
    subscriptionPlanGroup,
  } = useSelectedSubscriptionPlan();

  if (isSubscriptionPlansLoading) {
    return <LoadingContainer />;
  }

  if (!subscriptionPlan) {
    return null;
  }

  const {
    monthly_price,
    overage_fee,
    volume_max,
    volume_min,
    max_monthly_posted_vins,
  } = subscriptionPlan;

  const price =
    billingPeriod === 'monthly'
      ? monthly_price
      : subscriptionPlanGroup.annual_price;

  return (
    <Box padding="small" borderRadius="small" backgroundColor="Silver200">
      <Stack space="xsmall">
        <Columns align="center" space="small">
          <Column width="fluid">
            <Stack space="xxsmall">
              <Typography variant="h6" color="textSecondary">
                PLAN
              </Typography>
              <Typography variant="body1">
                {subscriptionPlanGroup.name}
              </Typography>
            </Stack>
          </Column>
          <Column width="content">
            <Box paddingTop="xsmall" paddingBottom="xsmall">
              <Stack space="xxsmall">
                {billingPeriod === 'anually' && (
                  <DiscountPrice>
                    <Price
                      value={
                        subscriptionPlanGroup.non_discountable_annual_price
                      }
                    />
                  </DiscountPrice>
                )}
                <Inline verticalAlign="bottom" space="none">
                  <PlanPrice>
                    <Price value={price} />
                  </PlanPrice>
                  <Typography variant="h5" color="textSecondary">
                    /{billingPeriod === 'monthly' ? 'month' : 'year'}
                  </Typography>
                </Inline>

                <Inline>
                  <Typography color="textSecondary">
                    Billed{' '}
                    {billingPeriod === 'monthly' ? 'Monthly' : 'Annually'}
                  </Typography>

                  {billingPeriod === 'anually' &&
                    subscriptionPlanGroup.discount_percent !== null && (
                      <Tag color="green" variant="bold">
                        Save {subscriptionPlanGroup.discount_percent}%
                      </Tag>
                    )}
                </Inline>
              </Stack>
            </Box>
          </Column>
        </Columns>
        <StyledDivider />
        <Box paddingTop="xsmall" paddingBottom="xsmall">
          <Columns space="small">
            <Column width="content">
              <Stack space="xxsmall">
                <Typography variant="h6" color="textSecondary">
                  Package
                </Typography>
                <Typography variant="body1">
                  {volume_min} - {volume_max}
                </Typography>
              </Stack>
            </Column>
            <Column width="fluid">
              <Stack space="xxsmall">
                <ul style={{ margin: 0 }}>
                  <li>
                    Up to {volume_max} delivered & {max_monthly_posted_vins}{' '}
                    posted VINs monthly
                  </li>
                  <li>${overage_fee} per additional VIN usage</li>
                </ul>
              </Stack>
            </Column>
          </Columns>
        </Box>
      </Stack>
    </Box>
  );
}

export function PaymentSummaryCard() {
  const [billingPeriod] = useBillingPeriod();
  const {
    subscriptionPlan,
    subscriptionPlanGroup,
    isSubscriptionPlansLoading,
  } = useSelectedSubscriptionPlan();
  const { isSubmitting } = useFormikContext();

  if (isSubscriptionPlansLoading) {
    return <LoadingContainer />;
  }

  if (!subscriptionPlan) {
    return null;
  }

  const { monthly_price } = subscriptionPlan;

  return (
    <Card>
      <Box
        paddingLeft="large"
        paddingRight="large"
        paddingTop="medium"
        paddingBottom="medium"
        width="464px"
      >
        <Stack space="small">
          <PaymentSummaryBox />

          <PlanSummary
            plan={subscriptionPlanGroup.name}
            planPrice={
              billingPeriod === 'monthly'
                ? monthly_price
                : subscriptionPlanGroup.annual_price
            }
          />
          <Stack space="small" align="center">
            <Button
              pending={isSubmitting}
              fullWidth={true}
              type="submit"
              size="large"
            >
              Subscribe Now
            </Button>
            <Typography align="center" color="textSecondary" variant="caption">
              By subscribing, you agree to our{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.superdispatch.com/terms-of-service"
              >
                Terms of Service
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
}

interface PlanSummaryProps {
  plan: string;
  planPrice: number | null;
}

function PlanSummary({ plan, planPrice }: PlanSummaryProps) {
  const [billingPeriod] = useBillingPeriod();
  const { data } = useSubscriptionDetails();

  return (
    <Stack space="small">
      {data?.chargebee.status === 'IN_TRIAL' ? (
        <Waypoints2
          startPrimaryText="Today"
          startSecondaryText="Trial"
          startLeadingText="Free"
          endPrimaryText={
            <FormattedDate
              date={data.chargebee.trial_end}
              variant="Date"
              fallback="N/A"
            />
          }
          endSecondaryText={plan}
          endLeadingPrimaryText={
            <Typography variant="h5">
              <Price value={planPrice} />
            </Typography>
          }
          endLeadingSecondaryText={
            billingPeriod === 'monthly' ? 'Monthly' : 'Every Year'
          }
        />
      ) : (
        <Typography variant="body1">Effective Today</Typography>
      )}
      <Stack space="xsmall">
        <DescriptionLineItem title="Total">
          <Typography variant="h4">
            <Price value={planPrice} />
          </Typography>
        </DescriptionLineItem>
        <Typography align="right">+ Tax if applicable</Typography>
      </Stack>
    </Stack>
  );
}
