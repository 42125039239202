import { NewRepresentative } from '@moovio/moov-js';
import { useSnackbarStack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { useMoovSyncAPI } from 'shared/helpers/superpay/MoovHelpers';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import {
  ownerAddFormValidationSchema,
  OwnerAddFormValues,
} from './OwnerAddFormValues';

interface OwnerAddFormProps {
  initialValues?: Partial<OwnerAddFormValues>;
  isController?: boolean;
  onClose?: () => void;
  onSubmitSuccess: () => void;
}

export function useOwnerAddForm({
  initialValues,
  isController,
  onClose,
  onSubmitSuccess,
}: OwnerAddFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { addSyncRepresentative } = useMoovSyncAPI();
  const { logPaymentInfo } = usePaymentLogger();

  return useAppFormik({
    initialValues,
    validationSchema: ownerAddFormValidationSchema,
    onSubmit: (data) =>
      addSyncRepresentative(normalizePayload(data, isController)),
    onSubmitSuccess: () => {
      addSnackbar('Owner added', { variant: 'success' });
      onSubmitSuccess();
      logPaymentInfo('Added Representative', 'AddRepresentativeForm');
      onClose?.();
    },
    getErrorMessage: ({ message }) =>
      message.includes('422') ? `Invalid form values. ${message}` : message,
  });
}

function normalizePayload(data: OwnerAddFormValues, isController?: boolean) {
  const { responsibilities, address, phone } = data;
  return {
    ...data,
    phone: {
      countryCode: '1',
      number: phone,
    },
    address: { ...address, country: 'US' },
    responsibilities: {
      ...responsibilities,
      isController: !!isController || !!responsibilities.isController,
      isOwner: true,
    },
  } as unknown as NewRepresentative;
}
