import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';

type OrderPreviewVariant =
  | 'bol'
  | 'customer_invoice'
  | 'dispatch-sheet'
  | 'carrier_invoice';

const previewPathMap = new Map<OrderPreviewVariant, string>([
  ['bol', 'bol'],
  ['customer_invoice', 'customer_invoice'],
  ['dispatch-sheet', 'dispatch_sheet'],
  ['carrier_invoice', 'carrier_invoice'],
]);

export function isPreviewVariant(type: string): type is OrderPreviewVariant {
  return previewPathMap.has(type as OrderPreviewVariant);
}

interface PreviewFileOptions {
  orderId?: number;
  type?: OrderPreviewVariant;
}

export function usePreviewFile({ orderId, type }: PreviewFileOptions = {}) {
  const { requestBlob } = useAPI();

  return useAPIQuery(
    ['orders', type ?? '', { orderId }],
    () =>
      requestBlob('/internal/orders/{orderId}/{type}', {
        orderId,
        type: type ? previewPathMap.get(type) : '',
      }),
    {
      enabled: !!orderId && !!type,
    },
  );
}
