import { createSvgIcon } from './IconUtils';

export const CalendarIcon = createSvgIcon(
  'Calendar',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.1333 13.6875C14.1333 14.4121 13.551 15 12.8333 15H3.3C2.58229 15 2 14.4121 2 13.6875V4.0625C2 3.33789 2.58229 2.75 3.3 2.75H4.6V1.32812C4.6 1.14766 4.74625 1 4.925 1H6.00833C6.18708 1 6.33333 1.14766 6.33333 1.32812V2.75H9.8V1.32812C9.8 1.14766 9.94625 1 10.125 1H11.2083C11.3871 1 11.5333 1.14766 11.5333 1.32812V2.75H12.8333C13.551 2.75 14.1333 3.33789 14.1333 4.0625V13.6875ZM4.92505 8.875H6.00838C6.18713 8.875 6.33338 8.72734 6.33338 8.54688V7.45312C6.33338 7.27266 6.18713 7.125 6.00838 7.125H4.92505C4.7463 7.125 4.60005 7.27266 4.60005 7.45312V8.54688C4.60005 8.72734 4.7463 8.875 4.92505 8.875ZM8.93332 7.45312V8.54688C8.93332 8.72734 8.78707 8.875 8.60832 8.875H7.52498C7.34623 8.875 7.19998 8.72734 7.19998 8.54688V7.45312C7.19998 7.27266 7.34623 7.125 7.52498 7.125H8.60832C8.78707 7.125 8.93332 7.27266 8.93332 7.45312ZM11.5334 8.54688V7.45312C11.5334 7.27266 11.3871 7.125 11.2084 7.125H10.125C9.94628 7.125 9.80003 7.27266 9.80003 7.45312V8.54688C9.80003 8.72734 9.94628 8.875 10.125 8.875H11.2084C11.3871 8.875 11.5334 8.72734 11.5334 8.54688ZM8.93332 10.0781V11.1719C8.93332 11.3523 8.78707 11.5 8.60832 11.5H7.52498C7.34623 11.5 7.19998 11.3523 7.19998 11.1719V10.0781C7.19998 9.89766 7.34623 9.75 7.52498 9.75H8.60832C8.78707 9.75 8.93332 9.89766 8.93332 10.0781ZM6.33338 11.1719V10.0781C6.33338 9.89766 6.18713 9.75 6.00838 9.75H4.92505C4.7463 9.75 4.60005 9.89766 4.60005 10.0781V11.1719C4.60005 11.3523 4.7463 11.5 4.92505 11.5H6.00838C6.18713 11.5 6.33338 11.3523 6.33338 11.1719ZM11.5334 10.0781V11.1719C11.5334 11.3523 11.3871 11.5 11.2084 11.5H10.125C9.94628 11.5 9.80003 11.3523 9.80003 11.1719V10.0781C9.80003 9.89766 9.94628 9.75 10.125 9.75H11.2084C11.3871 9.75 11.5334 9.89766 11.5334 10.0781ZM12.8333 5.375V13.5234C12.8333 13.6137 12.7602 13.6875 12.6708 13.6875H3.46247C3.37309 13.6875 3.29997 13.6137 3.29997 13.5234V5.375H12.8333Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
