import { Inline, Tag } from '@superdispatch/ui';
import { OrderDeliveryVerificationFailedTooltip } from 'orders/OrderDeliveryVerificationFailedTooltip';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import Order from 'shared/types/order';

export function OrderDeliveryVerificationStatus({ order }: { order: Order }) {
  const { delivery_verification_status: status } = order;
  const isDeliveryVerificationEnabled = useFeatureToggle(
    'stms.system.delivery.verification.enabled',
  );

  if (!status || !isDeliveryVerificationEnabled) {
    return null;
  }

  switch (status) {
    case 'SUCCEED':
      return (
        <Tag color="blue" variant="subtle">
          Delivery Verified
        </Tag>
      );
    case 'VERIFIED_MANUALLY':
      return (
        <Tag color="teal" variant="subtle">
          Marked as Delivery Verified
        </Tag>
      );
    case 'FAILED':
      return (
        <Inline space="xxsmall" verticalAlign="center">
          <Tag variant="subtle" color="yellow">
            Delivery Verification Failed
          </Tag>
          <OrderDeliveryVerificationFailedTooltip order={order} />
        </Inline>
      );
    default:
      return null;
  }
}
