import { IconButton, Link } from '@material-ui/core';
import { Add, Edit, Mail, Person, Phone } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { FaxIcon } from 'shared/icons/FaxIcon';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { isEmpty } from '../data/ShipperProfileUtils';
import { EmptySection, SectionLayout } from '../SectionLayout';
import { ContactInfoEditDrawer } from './ContactInfoEditDrawer';

interface ContactInfoSectionProps {
  profile: ShipperProfileDTO;
  onUpdate?: () => void;
}

export function ContactInfoSection({
  profile,
  onUpdate,
}: ContactInfoSectionProps) {
  const { contact_email, contact_name, contact_phone, fax } = profile;
  const drawerMatch = useMatch('/profile/contact-info/edit');

  const isContentEmpty = useMemo(
    () =>
      isEmpty(profile, [
        'contact_email',
        'contact_name',
        'contact_phone',
        'fax',
      ]),
    [profile],
  );

  const navigate = useNavigate();
  const { tryBack } = useTryBack();

  const openDrawer = () => navigate('/profile/contact-info/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  return (
    <>
      <ContactInfoEditDrawer
        profile={profile}
        open={!!drawerMatch}
        onClose={closeDrawer}
        onUpdate={onUpdate}
      />

      {isContentEmpty ? (
        <EmptySection onClick={openDrawer} startIcon={<Add />}>
          Add Contact Info
        </EmptySection>
      ) : (
        <SectionLayout
          title="Contact Info"
          editComponent={
            <IconButton
              aria-label="edit contact info"
              size="small"
              onClick={openDrawer}
            >
              <Edit />
            </IconButton>
          }
        >
          <Stack space="small">
            <Stack space="xxsmall">
              {contact_name && (
                <DescriptionItem icon={<Person />}>
                  {contact_name}
                </DescriptionItem>
              )}
              {contact_email && (
                <DescriptionItem icon={<Mail />}>
                  <Link href={`mailto:${contact_email}`}>{contact_email}</Link>
                </DescriptionItem>
              )}
              {contact_phone && (
                <DescriptionItem icon={<Phone />}>
                  <SuspendedPhoneLink
                    phone={contact_phone}
                    fallback={contact_phone}
                  />
                </DescriptionItem>
              )}
              {fax && (
                <DescriptionItem icon={<FaxIcon />}>
                  <SuspendedPhoneLink phone={fax} fallback={fax} />
                </DescriptionItem>
              )}
            </Stack>
          </Stack>
        </SectionLayout>
      )}
    </>
  );
}
