import { Card, CardContent } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPaySettingsCache } from 'superpay/data/SuperPayAPI';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { SuperPayOnboardingPageLayout } from 'superpay/SuperPayOnboardingPageLayout';
import { BankAccountForm } from './BankAccountForm';
import { MicroDepositsInitiatedDialog } from './MicroDepositsInitiatedDialog';
import { useBankAccountForm } from './useBankAccountForm';

export function BankAccountPage() {
  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();
  const isMobile = useResponsiveValue(true, false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { logPaymentInfo } = usePaymentLogger();

  const onComplete = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = () => {
    invalidateSuperPaySettings();
  };

  const formik = useBankAccountForm({ onComplete });
  const { isSubmitting } = formik;

  useEffect(() => {
    trackEvent('Shipper Opened Bank Details');
    logPaymentInfo('Opened Bank Details', 'BankAccountDetailsPage');
  }, [logPaymentInfo]);

  return (
    <SuperPayOnboardingPageLayout stepNumber={4}>
      <FormikProvider value={formik}>
        <Form aria-label="Bank account">
          <Card square={isMobile}>
            <CardContent>
              <Stack space="medium">
                <BankAccountForm />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  pending={isSubmitting}
                >
                  Submit
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Form>
      </FormikProvider>
      <MicroDepositsInitiatedDialog
        open={isDialogOpen}
        onClose={handleSubmit}
      />
    </SuperPayOnboardingPageLayout>
  );
}
