import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';

interface VerificationStatusDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function VerificationStatusDrawer({
  open,
  onClose,
}: VerificationStatusDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="medium">
            <Typography variant="h2">
              Your verification application is being reviewed by a member of our
              compliance team.
            </Typography>
            <Stack space="none">
              <Typography variant="h4">What&apos;s next?</Typography>
              <Typography>
                If all your documentation is verified, our team will then verify
                your registered phone number by calling you.
              </Typography>
            </Stack>
            <Stack space="none">
              <Typography variant="h4">What to do in the meantime?</Typography>
              <Typography>
                Complete your profile and import your contacts, so you are ready
                to start posting loads when your account has been verified.
              </Typography>
            </Stack>
            <Stack space="none">
              <Typography variant="h4">
                Can my application be declined?
              </Typography>
              <Typography>
                Yes, if any of your documents can&apos;t be verified, your
                application will be declined. Don&apos;t panic, we understand
                mistakes happen. A member of our team will contact you with
                details on what was missing or incorrect, so you can re-submit
                if needed.
              </Typography>
            </Stack>
            <Stack space="none">
              <Typography variant="h4">
                What happens after verification?
              </Typography>
              <Typography>
                Once you are verified, you can start posting orders on the Super
                Loadboard and start moving vehicles faster and easier with Super
                Dispatch.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Ok
        </Button>
      </DrawerActions>
    </Drawer>
  );
}
