import { CustomerType } from '@superdispatch/sdk';
import { Expose } from 'class-transformer';

export class OrderCustomerDTO {
  @Expose() name?: string;
  @Expose() address?: string;
  @Expose() city?: string;
  @Expose() state?: string;
  @Expose() zip?: string;
  @Expose() counterparty_guid?: string;
  @Expose() counterparty_type?: 'CUSTOMER' | 'TERMINAL' | null;
  @Expose() email?: string;
  @Expose() phone?: string;
  @Expose() business_type?: CustomerType;
  @Expose() notes?: string;
  @Expose() bill_the_delivery?: boolean;
  @Expose() contact_name?: string;
  @Expose() contact_email?: string;
  @Expose() contact_phone?: string;
  @Expose() contact_mobile_phone?: string;
  @Expose() contact_title?: string;
}
