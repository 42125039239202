import { Box, Link, Typography } from '@material-ui/core';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import { Order } from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../form/OrderForm';
import { PricingInsightsDrawer } from './PricingInsightsDrawer';
import { isOrderBelowRecommendedPrice } from './PricingRecommendationHelpers';

const BelowText = styled(TextBox)`
  color: ${Color.Yellow500};
`;

const BelowContainer = styled.div`
  width: 100%;
  margin-left: -22px;
  display: inline-flex;
  align-items: center;
`;

const PointerLink = styled(Link)`
  cursor: pointer;
`;

interface PricingRecommendationOrderListProps {
  order: Partial<Order> | OrderFormValues;
  onPriceClick: (value: number) => void;
  recommendedPrice?: number | null;
}

export function PricingRecommendationOrderList({
  order,
  onPriceClick,
  recommendedPrice = order.recommended_price,
}: PricingRecommendationOrderListProps) {
  const [isOpenPricingInsightsDrawer, setIsOpenPricingInsightsDrawer] =
    useState(false);

  if (!isOrderBelowRecommendedPrice(order)) {
    return null;
  }

  if (!recommendedPrice) {
    return null;
  }

  return (
    <SourceManager secondarySource="Recommended Price">
      <BelowContainer data-intercom-target="pricing recommendation">
        <Inline verticalAlign="top" noWrap={true}>
          <Box paddingTop="2px">
            <RecommendedPriceIcon fontSize="small" />
          </Box>

          <Stack space="none">
            <BelowText variant="caption">Below Recommended Price</BelowText>

            <Inline space="xxsmall">
              <PointerLink
                color="primary"
                variant="caption"
                onClick={() => {
                  onPriceClick(recommendedPrice);
                  trackEvent('Shipper Clicked Set Recommended Price', {
                    utm_medium: 'Order List',
                  });
                }}
              >
                Set to{' '}
                {formatCurrency(recommendedPrice, {
                  maximumFractionDigits: 0,
                })}
              </PointerLink>

              <Typography variant="caption" color="textSecondary">
                for faster booking ·‎
              </Typography>

              <Link
                variant="caption"
                color="textSecondary"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setIsOpenPricingInsightsDrawer(true);
                  trackEvent('Shipper Clicked Pricing Insights Link', {
                    utm_source: 'Order List',
                  });
                }}
              >
                Pricing Insights
              </Link>
            </Inline>
          </Stack>
        </Inline>

        <PricingInsightsDrawer
          order={order as Partial<Order>}
          open={isOpenPricingInsightsDrawer}
          onClose={() => {
            setIsOpenPricingInsightsDrawer(false);
          }}
        />
      </BelowContainer>
    </SourceManager>
  );
}
