import {
  IconButton,
  Tooltip as MuiTooltip,
  TooltipProps,
} from '@material-ui/core';
import { Done, FileCopy } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { Color } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useClipboard } from 'shared/helpers/DomHelpers';
import styled from 'styled-components';
import { ResponsiveTooltip as CustomTooltip } from './ResponsiveTooltip';

const CopyButton = styled(IconButton)`
  padding: 8px 12px;
  border-radius: 0;
  background: none !important;
`;

//Override tooltip styles is only way to change with withStyles, need to update from #web-ui
const ResponsiveTooltip = withStyles({
  tooltip: {
    padding: 0,
  },
})(CustomTooltip);

const Tooltip = withStyles({
  tooltip: {
    padding: 0,
  },
})(MuiTooltip);

interface CopyToClipboardTooltipProps {
  text: string;
  title?: string;
  successTitle?: string;
  onCopy?: () => void;
  children: React.ReactElement;
  tooltipPlacement?: TooltipProps['placement'];
}

export function ResponsiveCopyToClipboardTooltip({
  text,
  title,
  onCopy,
  children,
  successTitle,
  tooltipPlacement = 'right-end',
}: CopyToClipboardTooltipProps) {
  const { copy, status, reset } = useClipboard();

  return (
    <ResponsiveTooltip
      interactive={true}
      onClose={() => {
        // Reset copy status after tooltip is closed, there was issue with resetting status before tooltip is closed
        setTimeout(reset, 200);
      }}
      placement={tooltipPlacement}
      title={
        <TooltipTitle
          status={status}
          title={title || 'Copy'}
          successTitle={successTitle || 'Copied'}
          onCopy={() => {
            copy(text);
            onCopy?.();
          }}
        />
      }
    >
      {children}
    </ResponsiveTooltip>
  );
}

export function CopyToClipboardTooltip({
  text,
  title,
  onCopy,
  children,
  successTitle,
  tooltipPlacement = 'right-end',
}: CopyToClipboardTooltipProps) {
  const { copy, status, reset } = useClipboard();

  return (
    <Tooltip
      interactive={true}
      onClose={() => {
        // Reset copy status after tooltip is closed, there was issue with resetting status before tooltip is closed
        setTimeout(reset, 200);
      }}
      placement={tooltipPlacement}
      title={
        <TooltipTitle
          status={status}
          title={title || 'Copy'}
          successTitle={successTitle || 'Copied'}
          onCopy={() => {
            copy(text);
            onCopy?.();
          }}
        />
      }
    >
      {children}
    </Tooltip>
  );
}

interface TooltipTitleProps {
  status: 'waiting' | 'copying' | 'copied';
  title: string;
  successTitle: string;
  onCopy: () => void;
}

function TooltipTitle({
  status,
  title,
  successTitle,
  onCopy,
}: TooltipTitleProps) {
  return (
    <CopyButton disableFocusRipple={true} disableRipple={true} onClick={onCopy}>
      <Box marginRight="xxsmall">
        {status === 'copied' ? (
          <Done htmlColor={Color.White} fontSize="small" />
        ) : (
          <FileCopy htmlColor={Color.White} fontSize="small" />
        )}
      </Box>
      <TextBox color="white">
        {status === 'copied' ? successTitle : title}
      </TextBox>
    </CopyButton>
  );
}
