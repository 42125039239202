import { CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { InfoCard, Stack, VisibilityObserver } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { EmptyRatingsToGiveIcon } from 'shared/icons/EmptyRatingsToGiveIcon';
import { useUnratedCarriers } from './data/ShipperProfileAPI';
import { UnratedCarrier } from './UnratedCarrier';

export function UnratedCarriers() {
  const {
    list: unratedCarriers,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
  } = useUnratedCarriers();

  useTrackEvent('Shipper Opened Ratings to Give');

  return (
    <InfoCard size="large">
      {!unratedCarriers ? (
        <CircularProgress />
      ) : !(unratedCarriers.length > 0) ? (
        <Stack space="small">
          <TextBox variant="heading-3">Ratings to Give</TextBox>
          <Stack space="small" align="center">
            <EmptyRatingsToGiveIcon />
            <Stack space="xsmall" align="center">
              <TextBox color="secondary" variant="heading-3">
                All caught up!
              </TextBox>
              <TextBox color="secondary" align="center">
                Carriers you haven’t rated <br /> yet will be listed here.
              </TextBox>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack space="large">
          <Stack space="small">
            <TextBox variant="heading-3">Ratings to Give</TextBox>
            <Stack space="large">
              {unratedCarriers.map((carrier) => (
                <UnratedCarrier
                  key={carrier.guid}
                  carrier={carrier}
                  onRateSuccess={refetch}
                />
              ))}
            </Stack>
          </Stack>

          {hasNextPage && (
            <VisibilityObserver
              onChange={(visibility) => {
                if (!isFetchingNextPage && visibility === 'visible') {
                  void fetchNextPage();
                }
              }}
              render={({ ref }) => (
                <div ref={ref}>
                  {Array.from({ length: 3 }, (_, key) => (
                    <Skeleton
                      key={key}
                      width="100%"
                      height="50px"
                      animation={false}
                    />
                  ))}
                </div>
              )}
            />
          )}
        </Stack>
      )}
    </InfoCard>
  );
}
