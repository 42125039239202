import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class OrderLastPriceDTO {
  @Expose() created_at: string;
  @Expose() delivered_date: string;
  @Expose() delivery_city: string;
  @Expose() delivery_state: string;
  @Expose() pickup_city: string;
  @Expose() pickup_state: string;
  @Expose() price: number;
  @Expose() load_count: number;
  @Expose() vehicle_types: string;
  @Expose() guid: string | null;
  @Expose() posted_to_loadboard: boolean;
}

@Exclude()
export class OrderPricePredictionDTO {
  @Expose() estimated_price: number;
  @Expose() is_inoperable: boolean;
  @Expose() vehicle_type_name: string;
}
