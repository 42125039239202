import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';

export type APIMutationFunction<TInput, TData = unknown> = MutationFunction<
  TData,
  TInput
>;

export type APIMutationOptions<
  TInput = void,
  TData = unknown,
  TContext = unknown,
> = UseMutationOptions<TData, Error, TInput, TContext>;

export type APIMutationResult<
  TInput = void,
  TData = unknown,
  TContext = unknown,
> = UseMutationResult<TData, Error, TInput, TContext>;

export function useAPIMutation<TInput, TData = unknown, TContext = unknown>(
  mutation: APIMutationFunction<TInput, TData>,
  options?: APIMutationOptions<TInput, TData, TContext>,
): APIMutationResult<TInput, TData, TContext> {
  return useMutation(mutation, options);
}
