import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { ArrowBack, Search } from '@material-ui/icons';
import { Color, useResponsiveValue } from '@superdispatch/ui';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

interface UserSearchFieldProps {
  query: string;
  handleSearch: (query: string) => void;
}

const StyledSearchBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${Color.White};
`;

export function UserSearchField({ query, handleSearch }: UserSearchFieldProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';
  const [searchVisibility, setSearchVisibility] = useState(false);

  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    handleSearch(value);
  }

  return (
    <>
      {!isMobile ? (
        <Box flex="2" maxWidth="30rem">
          <TextField
            placeholder="Type at least 3 symbols to start searching"
            value={query}
            fullWidth={true}
            autoFocus={true}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" htmlColor={Color.Grey100} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : (
        <IconButton
          aria-label="search"
          style={{ color: Color.Grey100 }}
          onClick={() => {
            setSearchVisibility(true);
          }}
        >
          <Search fontSize="small" />
        </IconButton>
      )}

      {searchVisibility && (
        <StyledSearchBox px={2} display="flex" alignItems="center">
          <OutlinedInput
            type="text"
            value={query}
            onChange={handleChange}
            autoFocus={true}
            fullWidth={true}
            placeholder="3 symbols to search"
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    setSearchVisibility(false);
                    handleSearch('');
                  }}
                >
                  <ArrowBack />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </StyledSearchBox>
      )}
    </>
  );
}
