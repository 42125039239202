import { FunctionComponent } from 'react';
import Order from 'shared/types/order';
import styled from 'styled-components';

export const inspectionTypeLabels = {
  standard: 'Standard',
  advanced: 'Advanced',
  aiag: 'AIAG',
};

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface InspectionTypeComponentProps {
  order: Order;
}

export const InspectionType: FunctionComponent<InspectionTypeComponentProps> =
  ({ order }) => (
    <StyledIconContainer>
      {inspectionTypeLabels[order.inspection_type]}
    </StyledIconContainer>
  );
