import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from '../../shared/api/API';
import { useAPIQuery, UseAPIQueryOptions } from '../../shared/api/APIQuery';
import {
  VerificationApplicationDTO,
  verificationApplicationSchema,
  VerificationCompanyInformationDTO,
  verificationCompanyInformationSchema,
  VerificationIdentificationDTO,
  verificationIdentificationSchema,
  VerificationInsuranceDTO,
  verificationInsuranceSchema,
} from './VerificationApplicationDTO';

export function useVerificationApplication(
  options?: UseAPIQueryOptions<VerificationApplicationDTO>,
) {
  const { requestResource } = useAPI();
  return useAPIQuery(
    ['shippers', 'verification_info'],
    () =>
      requestResource(
        'GET /internal/shippers/verification_info',
        (data) => data as VerificationApplicationDTO,
      ),
    { ...options, schema: verificationApplicationSchema },
  );
}

export function useVerificationApplicationAPI() {
  const queryClient = useQueryClient();
  const { request } = useAPI();
  return useMemo(
    () => ({
      updateCompanyInformation: (data: VerificationCompanyInformationDTO) =>
        request('PATCH /internal/shippers/verification_info', {
          json: verificationCompanyInformationSchema.cast(data),
        }).then((response) => {
          void queryClient.invalidateQueries(['shippers', 'verification_info']);
          return response;
        }),
      updateIdentification: (data: VerificationIdentificationDTO) =>
        request('PATCH /internal/shippers/verification_info', {
          json: verificationIdentificationSchema.cast(data),
        }).then((response) => {
          void queryClient.invalidateQueries(['shippers', 'verification_info']);
          return response;
        }),
      updateInsurance: (data: VerificationInsuranceDTO) =>
        request('PATCH /internal/shippers/verification_info', {
          json: verificationInsuranceSchema.cast(data),
        }).then((response) => {
          void queryClient.invalidateQueries(['shippers', 'verification_info']);
          void queryClient.invalidateQueries(['shippers', 'profile']);
          return response;
        }),
      uploadFile: (file: File) => {
        const body = new FormData();
        body.append('file', file);

        return request('POST /internal/shippers/verification_info/file', {
          body,
        }).then((response) => response.text());
      },
    }),
    [request],
  );
}
