import { Typography, useTheme } from '@material-ui/core';
import { forwardRef, ImgHTMLAttributes, Ref } from 'react';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';

export interface FlagProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  code: CountryFieldOption;
}

export const Flag = forwardRef<HTMLElement, FlagProps>(
  ({ code, alt = code, ...props }, ref) => {
    const theme = useTheme();

    if (!countryFieldOptions.get(code)) {
      return (
        <Typography
          ref={ref}
          variant="h6"
          align="center"
          component="span"
          color="textSecondary"
          style={{
            minWidth: theme.spacing(2.75),
            minHeight: theme.spacing(2),
          }}
        >
          {code}
        </Typography>
      );
    }

    return (
      <img
        {...props}
        alt={alt}
        ref={ref as Ref<HTMLImageElement>}
        style={{
          minWidth: theme.spacing(2.75),
          minHeight: theme.spacing(2),
        }}
        src={`https://cdn.jsdelivr.net/gh/madebybowtie/FlagKit@2.2/Assets/SVG/${code}.svg`}
      />
    );
  },
);

Flag.displayName = 'Flag';
