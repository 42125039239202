import { Popover, PopoverProps } from '@material-ui/core';
import { NullableDateString, stringifyDate } from '@superdispatch/dates';
import { FormikDateField, useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import countBy from 'lodash/countBy';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';

interface MarkAsPickedUpPopoverProps<T> extends Omit<PopoverProps, 'onSubmit'> {
  orders?: Order[];
  onSubmitSuccess: (response: T) => void;
  onSubmit: (values: { adjusted_date: NullableDateString }) => Promise<T>;
  onClose: () => void;
}

function MarkAsPickedUpPopover<T>({
  orders,
  open,
  onSubmit,
  onClose,
  onSubmitSuccess,
  ...popoverProps
}: MarkAsPickedUpPopoverProps<T>) {
  const { addSnackbar } = useSnackbarStack();

  const initialValues = useMemo(
    () => ({
      adjusted_date: stringifyDate(startOfWorkDay(DateTime.local()), {
        format: 'JodaISO',
      }),
    }),
    [],
  );

  const form = useFormikEnhanced<{ adjusted_date: NullableDateString }, T>({
    key: open,
    initialValues,
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Popover
      {...popoverProps}
      open={open}
      onClose={form.isSubmitting ? undefined : handleClose}
    >
      <FormikProvider value={form}>
        <Form>
          <Box padding="small">
            <Stack space="small" align="right">
              <FormikDateField
                fullWidth={true}
                name="adjusted_date"
                label="Adjusted Pickup Date"
              />

              <Button
                variant="primary"
                pending={form.isSubmitting}
                type="submit"
              >
                Done
              </Button>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </Popover>
  );
}

interface BulkMarkAsPickedUpPopoverProps extends Omit<PopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
  onClose: () => void;
}

export function BulkMarkAsPickedUpPopover({
  orders,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...popoverProps
}: BulkMarkAsPickedUpPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkMarkAsPickedUp } = useBulkOrderActionAPI();

  return (
    <MarkAsPickedUpPopover
      {...popoverProps}
      anchorEl={anchorEl}
      onClose={onClose}
      open={!!orders && !!anchorEl}
      orders={orders}
      onSubmit={(values) => {
        if (orders) {
          const ids = orders.map((x) => x.id);
          return bulkMarkAsPickedUp(ids, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={() => {
        if (orders) {
          addSnackbar(
            `${orders.length} ${formatPlural(
              orders.length,
              'order',
              'orders',
            )} marked as picked up`,
            { variant: 'success' },
          );
          trackEventLegacy('Bulk Mark as Picked Up', {
            count: orders.length,
            orders: countBy(orders, 'status'),
          });
          onSubmitSuccess();
        }
      }}
    />
  );
}

export interface SingleMarkAsPickedUpPopoverProps
  extends Omit<PopoverProps, 'open'> {
  order?: Order;
  onClose: () => void;
  onSubmitSuccess: (order: Order) => void;
}

export function SingleMarkAsPickedUpPopover({
  order,
  anchorEl,
  onClose,
  onSubmitSuccess,
  ...popoverProps
}: SingleMarkAsPickedUpPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markAsPickedUp } = useSingleOrderActionAPI();

  return (
    <MarkAsPickedUpPopover
      {...popoverProps}
      onClose={onClose}
      anchorEl={anchorEl}
      open={!!order && !!anchorEl}
      orders={order ? [order] : undefined}
      onSubmit={(values) => {
        if (order) {
          return markAsPickedUp(order.id, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={(updatedOrder: Order) => {
        addSnackbar('Status changed successfully', { variant: 'success' });
        trackEventLegacy('Marked as Picked Up');
        onSubmitSuccess(updatedOrder);
      }}
    />
  );
}
