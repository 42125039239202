import { Tooltip, TooltipProps } from '@material-ui/core';
import {
  formatDate,
  FormattedDate,
  NullableDateRangeInput,
} from '@superdispatch/dates';
import { HTMLAttributes } from 'react';

interface DetailedFormattedDateRangeProps {
  range: NullableDateRangeInput;
  ContainerProps?: HTMLAttributes<HTMLSpanElement>;
  title?: string;
  placement?: TooltipProps['placement'];
}

export function DetailedFormattedDateRange({
  range,
  ContainerProps,
  title,
  placement = 'bottom',
}: DetailedFormattedDateRangeProps) {
  return (
    <Tooltip
      title={
        title || (
          <div>
            {range?.filter(Boolean).map((x, index) => (
              <div key={index}>
                <FormattedDate date={x} format="JodaISO" variant="DateTime" />
              </div>
            ))}
          </div>
        )
      }
      placement={placement}
    >
      <span style={{ whiteSpace: 'nowrap' }} {...ContainerProps}>
        {range
          ?.map((date) =>
            // TODO add variant option to formatDateRange
            formatDate(
              date,
              { variant: 'ShortDate', fallback: '…' },
              { format: 'JodaISO' },
            ),
          )
          .join(' - ')}
      </span>
    </Tooltip>
  );
}
