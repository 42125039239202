import { createContext, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { yupBoolean, yupObject } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type OnboardingSettingsDTO = InferType<typeof onboardingSettingsSchema>;

const onboardingSettingsSchema = yupObject({
  SET_UP_PROFILE: yupBoolean(),
  GET_VERIFIED: yupBoolean(),
  SET_UP_INSTANT_BOOKING: yupBoolean(),
  CONFIGURE_NOTIFICATION: yupBoolean(),
  SUBSCRIBE: yupBoolean(),
});

const ONBOARDING_SETTINGS_CACHE_KEY = [
  'shipper-profile',
  'onboarding',
] as const;

export function useOnboardingSettings(
  options?: APIQueryOptions<OnboardingSettingsDTO>,
): APIQueryResult<OnboardingSettingsDTO> {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ONBOARDING_SETTINGS_CACHE_KEY,
    () =>
      requestResource(
        'GET /internal/shippers/onboarding',
        (data) => data as OnboardingSettingsDTO,
      ),
    { ...options, schema: onboardingSettingsSchema },
  );
}

function useOnboardingCompleteStep() {
  const { request } = useAPI();
  const queryClient = useQueryClient();

  return (step: OnboardingStepKey) =>
    request(`POST /internal/shippers/onboarding/${step}/complete`).then(() => {
      void queryClient.invalidateQueries(ONBOARDING_SETTINGS_CACHE_KEY);
    });
}

export type OnboardingStepKey = keyof typeof onboardingSettingsSchema.fields;

interface OnboardingContextValue {
  isOnboardingLoading: boolean;
  totalStepsCount: number;
  completedStepsCount: number;
  remainingStepsCount: number;
  hasCompletedAllSteps: boolean;
  isVerified: boolean;
  hasPostedOrder: boolean;
  checkIsStepComplete: (stepKey: OnboardingStepKey) => boolean;
  completeStep: (stepKey: OnboardingStepKey) => void;
}

const OnboardingContext = createContext<OnboardingContextValue | undefined>(
  undefined,
);

export function OnboardingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data, isLoading: isOnboardingLoading } = useOnboardingSettings();
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const { data: orderCounts } = useOrderCounts();
  const isSubscriptionStepFeatureEnabled = useFeatureToggle(
    'onboarding.steps.subscription.ui',
  );
  const isNewOnboardingEnabled = useFeatureToggle(
    'self-serve-onboarding-v2.enabled',
  );

  const dataWithModifiedSubscribe = data
    ? {
        ...data,
        SUBSCRIBE:
          data.SUBSCRIBE ||
          subscriptionDetails?.chargebee.status !== 'IN_TRIAL',
      }
    : undefined;

  const stepKeys = Object.keys(dataWithModifiedSubscribe ?? {});

  const filteredStepKeys = isSubscriptionStepFeatureEnabled
    ? stepKeys
    : stepKeys.filter((key) => key !== 'SUBSCRIBE');

  const stepValues = filteredStepKeys.map(
    (key) => !!dataWithModifiedSubscribe?.[key],
  );

  const totalStepsCount = stepValues.length;

  const completedStepsCount = stepValues.filter(Boolean).length;

  const remainingStepsCount = totalStepsCount - completedStepsCount;
  const isVerified = !!dataWithModifiedSubscribe?.GET_VERIFIED;
  const hasPostedOrder = !!orderCounts?.posted_to_lb;
  const hasCompletedNewOnboarding = isVerified && hasPostedOrder;
  const hasCompletedAllSteps = isNewOnboardingEnabled
    ? hasCompletedNewOnboarding
    : totalStepsCount === completedStepsCount;

  function checkIsStepComplete(stepKey: OnboardingStepKey) {
    if (!dataWithModifiedSubscribe) {
      return false;
    }

    return dataWithModifiedSubscribe[stepKey] ?? false;
  }

  const completeStep = useOnboardingCompleteStep();

  const contextValue = {
    totalStepsCount,
    completedStepsCount,
    remainingStepsCount,
    hasCompletedAllSteps,
    isVerified,
    hasPostedOrder,
    checkIsStepComplete,
    isOnboardingLoading,
    completeStep,
  };

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
}

export function useOnboarding() {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within a OnboardingProvider');
  }
  return context;
}
