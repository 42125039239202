import { Dialog, Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { PaymentOnHoldDTO, paymentOnHoldSchema } from 'shared/dto/ActionsDTO';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';

const MESSAGES_DEFAULT = [
  'Potentially damaged vehicle. Payment will be on hold until resolution. Contact us for details.',
  'Signed W-9 Form is required to receive payment.',
];

interface PaymentOnHoldDialogProps {
  open: boolean;
  sendTitle?: string;
  onClose: () => void;
  onSend: () => void;
  title?: string;
}

export function PaymentOnHoldDialog({
  open,
  onClose,
  onSend,
  title = 'Payment on Hold Reason',
}: PaymentOnHoldDialogProps) {
  const localStorageKey = 'paymentOnHoldMessages';
  const messagesStorage = useStorageValue(localStorageKey);
  const messages = useMemo(
    () =>
      messagesStorage
        ? (JSON.parse(messagesStorage) as string[])
        : MESSAGES_DEFAULT,
    [messagesStorage],
  );

  const {
    values: { custom_on_hold_reason: holdMessage },
    isSubmitting,
    setFieldValue,
  } = useFormikContext<PaymentOnHoldDTO>();

  function send() {
    if (holdMessage.trim() && !messages.includes(holdMessage)) {
      const messagesList = [holdMessage, ...messages].slice(0, 3);
      writeStorageItem(localStorageKey, JSON.stringify(messagesList));
    }
    onSend();
  }

  function setMessage(value: string) {
    setFieldValue('custom_on_hold_reason', value);
  }

  return (
    <Dialog
      open={open}
      onClose={isSubmitting ? undefined : onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <Box padding="medium">
        <Stack space="small">
          <Typography variant="h3">{title}</Typography>
          <Stack space="small">
            <Inline space="xsmall" horizontalAlign="left">
              {messages.map((value) => (
                <Button
                  key={value}
                  variant="neutral"
                  disabled={isSubmitting || !!holdMessage.trim()}
                  onClick={() => setMessage(value)}
                >
                  <Typography
                    color={holdMessage.trim() ? 'inherit' : 'primary'}
                  >
                    <TextOverflow size={130}>{value}</TextOverflow>
                  </Typography>
                </Button>
              ))}
            </Inline>
            <FormikTextField
              name="custom_on_hold_reason"
              type="textarea"
              fullWidth={true}
              label={
                <TextBox align="right" color="secondary">
                  {holdMessage.length} of 160
                </TextBox>
              }
              inputProps={{ maxLength: 160 }}
              minRows={3}
              maxRows={3}
              multiline={true}
              placeholder="To skip without reason, click “Mark as Payment on Hold.”"
            />
            <Inline space="small" horizontalAlign="right">
              <Button
                variant="neutral"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={isSubmitting}
                pending={isSubmitting}
                onClick={send}
              >
                Mark as Payment on Hold
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}

interface MarkAsPaymentOnHoldProps<T> {
  open: boolean;
  onSubmitSuccess: (response: T) => void;
  onSubmit: (values: PaymentOnHoldDTO) => Promise<T>;
  onClose: () => void;
}

function PaymentOnHold<T>({
  open,
  onSubmit,
  onClose,
  onSubmitSuccess,
}: MarkAsPaymentOnHoldProps<T>) {
  const { addSnackbar } = useSnackbarStack();

  const form = useFormikEnhanced<PaymentOnHoldDTO, T>({
    initialValues: {
      custom_on_hold_reason: '',
    },
    validationSchema: paymentOnHoldSchema,
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={form}>
      <Form>
        <PaymentOnHoldDialog
          open={open}
          onClose={onClose}
          onSend={form.submitForm}
        />
      </Form>
    </FormikProvider>
  );
}

interface BulkMarkAsPaymentOnHoldProps {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
  onClose: () => void;
}

export function BulkMarkAsPaymentOnHold({
  orders,
  onClose,
  onSubmitSuccess,
}: BulkMarkAsPaymentOnHoldProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkMarkAsPaymentOnHold } = useBulkOrderActionAPI();

  return (
    <PaymentOnHold
      open={!!orders}
      onClose={onClose}
      onSubmit={(values) => {
        if (orders) {
          const ids = orders.map((x) => x.id);
          return bulkMarkAsPaymentOnHold(ids, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={() => {
        if (orders) {
          addSnackbar(
            `${orders.length} ${formatPlural(
              orders.length,
              'order',
              'orders',
            )} marked as payment on hold`,
            { variant: 'success' },
          );
          trackEvent('Bulk Mark as Payment on Hold', {
            count: orders.length,
          });
          onSubmitSuccess();
        }
      }}
    />
  );
}

interface SingleMarkAsPaymentOnHoldDialogProps {
  order?: Order;
  onSubmitSuccess: (order: Order) => void;
  onClose: () => void;
}

export function SingleMarkAsPaymentOnHold({
  order,
  onClose,
  onSubmitSuccess,
}: SingleMarkAsPaymentOnHoldDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markAsPaymentOnHold } = useSingleOrderActionAPI();

  return (
    <PaymentOnHold
      open={!!order}
      onClose={onClose}
      onSubmit={(values) => {
        if (order) {
          return markAsPaymentOnHold(order.id, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={(updatedOrder: Order) => {
        addSnackbar('Status changed successfully', { variant: 'success' });
        trackEvent('Marked as Payment on Hold');
        onSubmitSuccess(updatedOrder);
      }}
    />
  );
}
