import isEmail from 'validator/lib/isEmail';

export type Value = string | number | undefined | unknown;
export type Validator = (value: Value) => string | undefined;

export const composeValidators =
  (...validators: Validator[]) =>
  (value: Value) =>
    validators.reduce<ReturnType<Validator>>(
      (error, validator) => error || validator(value),
      undefined,
    );

export const hasUppercaseAndLowercaseLetter = (value: string) => {
  return /(?=.*[a-z])(?=.*[A-Z])/.test(value);
};

export const hasNumber = (value: string) => /\d/.test(value);

export const required = (value: Value) => {
  const trimmedValue =
    value && typeof value === 'string' ? value.trim() : value;
  return trimmedValue || trimmedValue === 0 ? undefined : 'Fill out this field';
};

export const password = (value: Value) => {
  return value && typeof value === 'string' && value.trim().length > 7
    ? undefined
    : 'Password should be not less than 8 symbols';
};

export const validateConfirmPassword = (pass: Value, value: Value) =>
  (typeof value === 'string' &&
    typeof pass === 'string' &&
    pass &&
    value &&
    pass !== value &&
    'Passwords do not match') ||
  undefined;

export const validatePasswordNumbers = (value: Value) => {
  return value && typeof value === 'string' && hasNumber(value)
    ? undefined
    : 'Password should contain a number';
};

export const validatePasswordLetters = (value: Value) => {
  return value &&
    typeof value === 'string' &&
    hasUppercaseAndLowercaseLetter(value)
    ? undefined
    : 'Password should contain lowercase and uppercase letters';
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const maxLength = (value: Value, maxLength = 100) => {
  return value && typeof value === 'string' && value.trim().length <= maxLength
    ? undefined
    : `Should be less than ${maxLength} symbols`;
};

export const email = (value: Value) =>
  value && typeof value === 'string'
    ? isEmail(value)
      ? undefined
      : "Invalid email address. Valid email can contain only latin letters, numbers, '@' and '.'"
    : undefined;

export const emails = (value: Value) => {
  if (!value || typeof value !== 'string') return;
  return value
    .split(',')
    .map((str) => str.trim())
    .some((str) => !isEmail(str))
    ? "Invalid email address. Valid email can contain only latin letters, numbers, '@' and '.'"
    : undefined;
};

// todo move to @superdispatch/sdk
export const isValidVin = (vin: string) => {
  vin = vin.toLowerCase();
  if (!/^[a-hj-npr-z0-9]{8}[0-9xX][a-hj-npr-z0-9]{8}$/.test(vin)) {
    return false;
  }

  const transliterationTable = {
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    j: 1,
    k: 2,
    l: 3,
    m: 4,
    n: 5,
    p: 7,
    r: 9,
    s: 2,
    t: 3,
    u: 4,
    v: 5,
    w: 6,
    x: 7,
    y: 8,
    z: 9,
  };

  const weightsTable = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
  let sum = 0;

  for (let i = 0; i < vin.length; ++i) {
    sum += transliterationTable[vin.charAt(i)] * (weightsTable[i] || 0);
  }

  let mod = sum % 11;
  return mod === 10 ? vin.charAt(8) === 'x' : vin.charAt(8) === mod.toString();
};

type DateValues = {
  day: number | null;
  month: number | null;
  year: number | null;
} | null;

export function isValidDate(values?: DateValues) {
  const { day, month, year } = values || {};

  if (!day || !month || !year) return false;

  const inputDate = new Date(`${year}.${month}.${day}`);
  const isInvalidDate = Number.isNaN(inputDate.getTime());
  const isUnrealDate = day !== inputDate.getDate(); // Check implicit date shifts to the next month

  return !isInvalidDate && !isUnrealDate;
}
