import { Link, TextField, Typography } from '@material-ui/core';
import { Add, ArrowForward } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Tag } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { get } from 'lodash-es';
import { useCustomersWithTerminalList } from 'orders/data/CounterpartAPI';
import { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { isCustomerDTO } from 'shared/dto/CustomerDTO';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useRecentSelections } from 'shared/helpers/RecentSelectionsHelpers';

export function useTerminalAutocompleteProps() {
  const [query, setQuery] = useState<string>('');

  const debouncedQuery = useDebouncedValue(query, 500);
  const { data, isLoading } = useCustomersWithTerminalList(
    debouncedQuery.trim().length < 3
      ? undefined
      : { query: debouncedQuery, sort: 'name,ASC' },
  );
  const options = useMemo(
    () => (data ? [...data.terminals, ...data.customers] : []),
    [data],
  );

  return {
    query,
    options,
    loading: isLoading,
    onInputChange: (_: ChangeEvent<{}>, input: string) => setQuery(input),
  };
}

interface TerminalContact {
  title: string | null;
  name: string | null;
  phone: string | null;
  email: string | null;
  mobile_phone: string | null;
}

export interface TerminalAutocompleteValue
  extends Partial<Omit<TerminalDTO, 'guid'>> {
  guid?: string | null;
  contact?: TerminalContact;
}

export type TerminalChangeReason =
  | 'select-option'
  | 'save-as-new'
  | 'dont-save'
  | 'close'
  | 'clear';

interface TerminalAutocompleteProps {
  name: string;
  label: ReactNode;
  query: string;
  isNew: boolean;
  onChange: (
    value: TerminalAutocompleteValue,
    reason: TerminalChangeReason,
  ) => void;
  validate?: (value: TerminalAutocompleteValue) => void;
  options: TerminalAutocompleteValue[];
  loading: boolean;
  onInputChange: (event: ChangeEvent<{}>, value: string) => void;
}

export function TerminalAutocomplete({
  name,
  query,
  label,
  validate,
  isNew,
  options,
  loading,
  onInputChange,
  onChange: onChangeProp,
}: TerminalAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [{ value, onBlur }, { error }, { setValue }] = useField({
    name,
    validate,
  });

  const nameError = get(error, 'name');
  const recentSelections = useRecentSelections();
  const recentOptions = useMemo(
    () =>
      recentSelections[
        name === 'pickup.venue' ? 'pickupVenues' : 'deliveryVenues'
      ] || [],

    [recentSelections, name],
  );

  const optionsWithActionButtons = useMemo(() => {
    if (options.length === 0 && query.length < 3) {
      return recentOptions;
    }

    return [
      ...options,
      { name: 'continue_button' },
      { name: 'save_as_new_button' },
    ];
  }, [options, query.length, recentOptions]);

  function handleChange(
    autocompleteValue: TerminalAutocompleteValue,
    reason: TerminalChangeReason,
  ) {
    setValue(autocompleteValue);
    onChangeProp(autocompleteValue, reason);
  }

  function handleSaveAsNew(reason: TerminalChangeReason) {
    setOpen(false);
    handleChange({ ...value, guid: null, name: query }, reason);
  }

  function handleContinue() {
    setOpen(false);
    handleChange({ ...value, name: query }, 'dont-save');
  }

  return (
    <Autocomplete
      open={open}
      onBlur={onBlur}
      popupIcon={null}
      options={optionsWithActionButtons}
      value={value}
      loading={loading}
      onInputChange={onInputChange}
      clearOnBlur={false}
      filterOptions={(data) => data}
      groupBy={() =>
        options.length === 0 && recentOptions.length > 0 ? 'Recents' : ''
      }
      onOpen={() => setOpen(true)}
      onClose={(_, reason) => {
        setOpen(false);

        if (
          query.length > 0 &&
          query !== value.name &&
          reason !== 'toggleInput' &&
          reason !== 'select-option'
        ) {
          handleSaveAsNew('close');
        }
      }}
      getOptionSelected={(option, selectedValue) =>
        option.name === selectedValue.name
      }
      getOptionLabel={(option) => option.name || ''}
      onChange={(_event, changedValue) => {
        if (changedValue?.name === 'continue_button') {
          handleContinue();
          return;
        }

        if (changedValue?.name === 'save_as_new_button') {
          handleSaveAsNew('save-as-new');
          return;
        }

        if (changedValue) {
          handleChange(changedValue, 'select-option');
        } else {
          handleChange({ business_type: value.business_type }, 'clear');
        }
      }}
      noOptionsText={
        query.trim().length < 3 ? 'Type at least 3 characters' : 'No option'
      }
      renderOption={(option: TerminalAutocompleteValue) => {
        if (option.name === 'continue_button') {
          return (
            <Button variant="text" startIcon={<ArrowForward />}>
              Continue without Saving
            </Button>
          );
        }

        if (option.name === 'save_as_new_button') {
          return (
            <Button variant="text" startIcon={<Add />}>
              Save as New Terminal
            </Button>
          );
        }

        return (
          <>
            {option.name}{' '}
            {isCustomerDTO(option) && (
              <Tag color="grey" variant="subtle">
                Customer
              </Tag>
            )}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={true}
          error={!!nameError}
          helperText={
            nameError ? (
              nameError
            ) : isNew && options.length > 0 ? (
              <Typography component="span">
                Similar terminal already exists.{' '}
                <Link href="#" color="primary" onClick={() => setOpen(true)}>
                  Review
                </Link>
              </Typography>
            ) : undefined
          }
          placeholder="Type at least 3 characters"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
