import { Order } from 'shared/types/order';
import { Step } from 'shared/types/step';
import { joinWords } from 'shared/utils/TextUtils';
import { OrderFormStep, OrderFormValues } from '../form/OrderForm';

export function validateOrder(order: Partial<Order> | OrderFormValues) {
  const isVehicleMakeMissing = order.vehicles?.some((vehicle) => !vehicle.make);
  const isVehicleModelMissing = order.vehicles?.some(
    (vehicle) => !vehicle.model,
  );
  const isVehicleYearMissing = order.vehicles?.some((vehicle) => !vehicle.year);
  const hasInvalidVehicleType = order.vehicles?.some((vehicle) => {
    if (!vehicle.type) {
      return true;
    }
    return ![
      'suv',
      '2_door_coupe',
      'sedan',
      'truck',
      'van',
      'pickup',
      '4_door_pickup',
    ].includes(vehicle.type);
  });

  // initial empty order
  if (
    (!order.vehicles?.length ||
      (order.vehicles.length === 1 &&
        isVehicleModelMissing &&
        isVehicleMakeMissing &&
        isVehicleYearMissing &&
        hasInvalidVehicleType)) &&
    !order.pickup?.venue?.state &&
    !order.pickup?.venue?.city &&
    !order.delivery?.venue?.state &&
    !order.delivery?.venue?.city
  ) {
    return 'Add Vehicle, Pickup, and Delivery info for the Recommended Price.';
  }

  const requiredFields = [
    !order.vehicles?.length &&
      'Vehicle Year, Type (choose Sedan, Coupe, SUV, Van, or Pickup)',
    isVehicleYearMissing && hasInvalidVehicleType
      ? 'Vehicle Year, Type (choose Sedan, Coupe, SUV, Van, or Pickup)'
      : hasInvalidVehicleType
      ? 'Vehicle Type (choose Sedan, Coupe, SUV, Van, or Pickup)'
      : isVehicleYearMissing
      ? 'Vehicle Year'
      : null,
    !order.pickup?.venue?.state && !order.pickup?.venue?.city
      ? 'Pickup City, State'
      : !order.pickup.venue.state
      ? 'Pickup State'
      : !order.pickup.venue.city
      ? 'Pickup City'
      : null,
    !order.delivery?.venue?.state && !order.delivery?.venue?.city
      ? 'Delivery City, State'
      : !order.delivery.venue.state
      ? 'Delivery State'
      : !order.delivery.venue.city
      ? 'Delivery City'
      : null,
  ].filter(Boolean);

  if (requiredFields.length) {
    return `Add ${joinWords(...requiredFields)} for Recommended Price.`;
  }

  if (isCanadianAddress(order.pickup) || isCanadianAddress(order.delivery)) {
    return "Recommended Price isn't available for Canadian address yet.";
  }

  if (order.transport_type === 'DRIVEAWAY') {
    return "Recommended Price isn't available for Driveaway transport type yet. Choose Open or Enclosed.";
  }

  return null;
}

export function isOrderBelowRecommendedPrice(
  order: Partial<Order> | OrderFormValues,
) {
  if (!order.recommended_price || !order.price) {
    return false;
  }

  if (!canShowBelowPriceForOrder(order)) {
    return false;
  }

  if (validateOrder(order)) {
    return false;
  }

  return order.price < order.recommended_price;
}

export function canShowBelowPriceForOrder(
  order: Partial<Order> | OrderFormValues,
) {
  const allowedStatuses = ['new', 'canceled', 'declined', 'order_canceled'];

  if (order.is_archived) {
    return false;
  }

  if (!order.status || allowedStatuses.includes(order.status)) {
    return true;
  }

  if (order.is_posted_to_loadboard) {
    return true;
  }

  return false;
}

export function isCanadianAddress(step?: Step | OrderFormStep) {
  const canadianProvinces = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'NT',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
  ];

  return (
    /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(step?.venue?.zip || '') ||
    canadianProvinces.includes(step?.venue?.state || '')
  );
}
