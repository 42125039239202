import {
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { SuperPayRepresentative } from 'shared/helpers/superpay/MoovHelpers';
import { OwnerFormFields } from './OwnerFormFields';
import { useOwnerEditForm } from './useOwnerEditForm';

interface OwnerEditDrawerProps {
  initialValues?: SuperPayRepresentative;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function OwnerEditDrawerContent({
  initialValues,
  onClose,
  onSubmitSuccess,
}: OwnerEditDrawerProps) {
  const formik = useOwnerEditForm({
    initialValues,
    onSubmitSuccess,
    onClose,
  });
  return (
    <FormikDrawerContent
      formik={formik}
      title="Edit Representative"
      actions={<FormikDrawerActions />}
    >
      <OwnerFormFields withControllerCheckbox={true} />
    </FormikDrawerContent>
  );
}
