import { Typography } from '@material-ui/core';
import * as React from 'react';
import Order from 'shared/types/order';
import { NoData } from './NoData';
import { OrderViewNote } from './OrderViewNote';

interface NotesFromShipperProps {
  order: Order;
}

export const OrderViewNotesFromShipper: React.ComponentType<NotesFromShipperProps> =
  ({ order: { customer } }) => (
    <>
      <Typography variant="h3">Notes from Shipper</Typography>

      {customer?.notes ? (
        <OrderViewNote mt={2} notes={customer.notes} />
      ) : (
        <NoData my={3} text="Shipper hasn't left any notes yet" />
      )}
    </>
  );
