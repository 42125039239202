import {
  CircularProgress,
  Divider,
  Hidden,
  IconButton,
  Link,
  Theme,
} from '@material-ui/core';
import { Delete, Edit, Help } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useValueObserver } from '@superdispatch/hooks';
import {
  Column,
  Columns,
  InfoCard,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useLayoutContext } from 'shared/layout/PageLayout';
import { CarrierFullInfo } from 'shared/types/carrier';
import styled, { css } from 'styled-components';
import { CarrierRating } from './CarrierRating';
import { CarrierRatingDeleteDialog } from './CarrierRatingDeleteDialog';
import { CarrierRatingsEmpty } from './CarrierRatingsEmpty';
import {
  useCarrierRatings,
  useCarrierRatingTagStats,
} from './data/CarrierRatingAPI';
import {
  CarrierPositiveRatingTag,
  CarrierRatingDTO,
  CarrierRatingTagStatsDTO,
} from './data/CarrierRatingDTO';

const CarrierRatingTagStatItem = styled.div(
  ({ theme }: { theme: Theme }) => css`
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.up('md')} {
      flex-direction: column;
      align-items: center;

      & > *:not(:first-child) {
        padding-top: 4px;
      }
    }
  `,
);

interface CarrierRatingTagStatProp {
  stats: CarrierRatingTagStatsDTO;
  tag: CarrierPositiveRatingTag;
}

function CarrierRatingTagStat({ tag, stats }: CarrierRatingTagStatProp) {
  const uid = useUID();
  const count = stats[tag];

  return (
    <CarrierRatingTagStatItem>
      <TextBox id={uid} color="secondary" align="center">
        {CarrierRatingDTO.formatRatingTag(tag)}
      </TextBox>

      {count ? (
        <TextBox variant="heading-4" color="primary" aria-labelledby={uid}>
          {count}
        </TextBox>
      ) : (
        <TextBox variant="heading-4" color="secondary" aria-labelledby={uid}>
          None
        </TextBox>
      )}
    </CarrierRatingTagStatItem>
  );
}

const VerticalDivider = styled(Divider).attrs({ orientation: 'vertical' })`
  height: 48px;
`;

const PaginationBox = styled(Box)`
  display: flex;
  justify-content: center;
`;
interface Props {
  onRate: () => void;
  carrier: CarrierFullInfo;
}

export function CarrierRatings({ carrier, onRate }: Props) {
  const [page, setPage] = useState(0);
  const { scrollTop } = useLayoutContext();
  const { data, isLoading } = useCarrierRatings(carrier.guid, page);
  const { data: ratingTagStats } = useCarrierRatingTagStats(carrier.guid);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const { user } = useUserState();

  useEffect(() => trackEvent('[STMS] Opened Carrier’s Ratings Received'), []);

  useValueObserver(page, () => {
    scrollTop();
  });

  return (
    <>
      <CarrierRatingDeleteDialog
        carrierGuid={carrier.guid}
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
      />

      <InfoCard size="large">
        <Stack space="medium">
          <Stack space="small">
            <Columns collapseBelow="tablet" space="xsmall">
              <Column>
                <TextBox variant="heading-3">Ratings Received</TextBox>
              </Column>

              <Column width="content">
                <Inline space="xxsmall" verticalAlign="center">
                  <Help fontSize="small" color="action" />

                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.superdispatch.com/en/articles/5716958-ratings-feature-on-super-dispatch"
                  >
                    How ratings work?
                  </Link>
                </Inline>
              </Column>
            </Columns>

            {ratingTagStats && (
              <Columns
                collapseBelow="desktop"
                space="xsmall"
                aria-label="tag statistics"
              >
                <Column>
                  <CarrierRatingTagStat
                    tag="ON_TIME_TRANSPORT"
                    stats={ratingTagStats}
                  />
                </Column>

                <Hidden smDown={true}>
                  <Column width="content">
                    <VerticalDivider />
                  </Column>
                </Hidden>

                <Column>
                  <CarrierRatingTagStat
                    tag="GOOD_COMMUNICATION"
                    stats={ratingTagStats}
                  />
                </Column>

                <Hidden smDown={true}>
                  <Column width="content">
                    <VerticalDivider />
                  </Column>
                </Hidden>

                <Column>
                  <CarrierRatingTagStat
                    tag="GOOD_CUSTOMER_SERVICE"
                    stats={ratingTagStats}
                  />
                </Column>
              </Columns>
            )}
          </Stack>

          <Stack space="large">
            {carrier.broker_records.carrier_rating?.rating && (
              <Stack space="small">
                <TextBox color="secondary" variant="heading-4">
                  Rating Given by You
                </TextBox>
                <CarrierRating
                  name={user?.shipper.name ?? 'Shipper'}
                  carrier={carrier}
                  rating={carrier.broker_records.carrier_rating}
                  ratingActions={
                    <Columns>
                      <Column width="content">
                        <IconButton onClick={onRate}>
                          <Edit color="action" />
                        </IconButton>
                      </Column>
                      {carrier.broker_records.carrier_rating.rating ===
                        'NEGATIVE' && (
                        <Column width="content">
                          <IconButton
                            aria-label="Delete rating"
                            onClick={() => setConfirmationDialog(true)}
                          >
                            <Delete color="action" />
                          </IconButton>
                        </Column>
                      )}
                    </Columns>
                  }
                />
              </Stack>
            )}

            {data?.pagination.total_objects !== 0 &&
              carrier.broker_records.carrier_rating?.rating && <Divider />}

            <Stack space="small" align={data ? 'left' : 'center'}>
              {!data ? (
                <CircularProgress />
              ) : data.pagination.total_objects > 0 ? (
                <Stack space="small">
                  <TextBox color="secondary" variant="heading-4">
                    Ratings by Shippers
                  </TextBox>
                  <Stack space="large">
                    {data.objects.map((rating) => (
                      <CarrierRating
                        key={rating.guid}
                        name="Shipper"
                        rating={rating}
                        showRatingDetails={rating.status !== 'PENDING'}
                        carrier={carrier}
                      />
                    ))}
                  </Stack>
                </Stack>
              ) : (
                carrier.broker_records.carrier_rating?.rating == null && (
                  <CarrierRatingsEmpty
                    onRate={onRate}
                    showRateButton={
                      carrier.broker_records.accepted_loads_count > 0
                    }
                  />
                )
              )}

              {data?.pagination && data.pagination.total_pages > 1 && (
                <PaginationBox paddingTop="small">
                  <Pagination
                    page={page + 1}
                    disabled={isLoading}
                    count={data.pagination.total_pages}
                    onChange={(_, value) => setPage(value - 1)}
                  />
                </PaginationBox>
              )}
            </Stack>
          </Stack>
        </Stack>
      </InfoCard>
    </>
  );
}
