import { Typography } from '@material-ui/core';
import { Flex, FlexProps } from '@rebass/grid';
import { Color } from '@superdispatch/ui';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  color: ${Color.Grey100};
`;

interface NoDataProps extends FlexProps {
  text: string;
}

export const NoData = ({ text, ...props }: NoDataProps) => (
  <Flex justifyContent="center" {...props}>
    <StyledTypography variant="h3">{text}</StyledTypography>
  </Flex>
);
