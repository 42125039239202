import { Divider, Link, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Stack,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  SectionHeader,
  SectionSwitch,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';
import { useSuperLoadboardSettingsChannel } from './data/SuperLoadboardChannel';

interface LoadboardSectionProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardInstantBooking({
  profile,
}: LoadboardSectionProps) {
  const id = useUID();
  const { addSnackbar } = useSnackbarStack();
  const isMinimumRequirementsEnabled = useFeatureToggle(
    'minimal-performance-requirements.enable.ui',
  );
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const { sendBroadcastMessage } = useSuperLoadboardSettingsChannel();

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      sendBroadcastMessage(undefined);
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Super Loadboard',
        is_instant_booking_allowed_for_verified_carriers:
          updatedValues.is_instant_booking_allowed_for_verified_carriers,
      });
      addSnackbar('Settings updated', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <SuperLoadboardSettingsSection data-intercom-target="instant booking settings">
      <SectionHeader>
        <Columns align="center">
          <Column width="fluid">
            <Stack space="xxsmall">
              <Typography variant="h4" id={id}>
                Instant Booking for Verified Carriers
              </Typography>
            </Stack>
          </Column>
          <Column width="content">
            <SectionSwitch
              aria-labelledby={id}
              disabled={!canUpdateProfile || formik.isSubmitting}
              checked={
                formik.values.is_instant_booking_allowed_for_verified_carriers
              }
              onChange={(_, checked) => {
                formik.setFieldValue(
                  'is_instant_booking_allowed_for_verified_carriers',
                  checked,
                );
                void formik.submitForm();
              }}
            />
          </Column>
        </Columns>
      </SectionHeader>
      <Divider />
      <Stack space="small">
        <Typography color="textSecondary">
          <Link
            color="inherit"
            target="_blank"
            rel="noreferrer"
            href="https://support.superdispatch.com/en/articles/6866037-verified-carrier-how-does-super-dispatch-monitor-carrier-compliance"
          >
            Verified Carriers
          </Link>{' '}
          meeting your{' '}
          <Link
            href="/profile/carrier-requirements/insurance/edit"
            rel="noreferrer"
            target="_blank"
            color="inherit"
          >
            cargo insurance minimum
          </Link>{' '}
          requirements and have not been blocklisted can instantly book your
          loads at the posted rate.
        </Typography>
        {isMinimumRequirementsEnabled && (
          <Typography color="textSecondary">
            To book instantly, carriers must have active USDOT authority,
            maintain an overall rating of at least 80%, and keep their
            cancellation rate below 10%.
          </Typography>
        )}
        <Typography color="textSecondary">
          When enabled, Instant Booking will apply to all posted orders by
          default, unless disabled for sensitive loads when creating a new
          order.
        </Typography>
      </Stack>
      <Divider />
      <TextBox color="secondary" variant="caption">
        By enabling this feature, you are confirming you have reviewed and agree
        to the Super Dispatch{' '}
        <Link
          color="inherit"
          target="_blank"
          rel="noreferrer"
          href="https://superdispatch.com/terms-of-service/"
        >
          Terms & Conditions
        </Link>
        .
      </TextBox>
    </SuperLoadboardSettingsSection>
  );
}
