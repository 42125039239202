import { useAPI } from 'shared/api/API';
import { useAPIMutation } from 'shared/api/APIMutation';
import { ChargebeeResponse } from 'shared/helpers/ChargebeeWeb';

export function useUpdatePaymentDetails({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: Error) => void;
}) {
  const { request } = useAPI();

  const { mutate: updatePaymentDetails } = useAPIMutation(
    (chargebeeResponse: ChargebeeResponse) => {
      return request('PUT /internal/billing/payment-method', {
        json: {
          token: chargebeeResponse.token,
          vault_token: chargebeeResponse.vaultToken,
        },
      });
    },
    {
      onSuccess,
      onError,
    },
  );

  return { updatePaymentDetails };
}
