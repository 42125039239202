import { DateRangeField, DateRangeFieldProps } from '@superdispatch/dates';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { endOfWorkDay, startOfWorkDay } from 'shared/helpers/DateTimeHelpers';

export interface FormikDateRangeFieldProps extends DateRangeFieldProps {
  name: string;
  validate?: FieldValidator;
}

export function FormikDateRangeField({
  name,
  validate,
  disabled,
  helperText,
  ...props
}: FormikDateRangeFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue }] = useField({
    name,
    validate,
  });
  const errorText = touched && error;

  return (
    <DateRangeField
      {...props}
      name={name}
      value={field.value}
      error={!!errorText}
      disabled={disabled || isSubmitting}
      helperText={errorText || helperText}
      onChange={({ dateValue: [start, end] }) =>
        setValue([start && startOfWorkDay(start), end && endOfWorkDay(end)])
      }
    />
  );
}
