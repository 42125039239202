import { ClickAwayListener, Tooltip, TooltipProps } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { useState } from 'react';

export function ResponsiveTooltip(props: TooltipProps) {
  const [tooltipIsOpen, setTooltipOpen] = useState(false);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');

  return platform === 'desktop' ? (
    <Tooltip {...props} />
  ) : (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <Tooltip
        {...props}
        open={tooltipIsOpen}
        disableFocusListener={true}
        disableHoverListener={false}
        disableTouchListener={true}
        onClick={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
      />
    </ClickAwayListener>
  );
}
