import { Box, Drawer, Fade, IconButton, Typography } from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Button,
  DrawerActions,
  DrawerTitle,
  Stack,
  useSnackbarStack,
  useUID,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Fragment, useEffect, useRef } from 'react';
import { useOrderCountsCache } from 'shared/api/OrderCountAPI';
import { useCanExecute } from 'shared/data/UserPermissions';
import Order from 'shared/types/order';
import { Divider } from 'shared/ui/Divider';
import styled from 'styled-components';
import {
  useOrderRequestCount,
  useOrderRequests,
  useOrderRequestsAPI,
} from '../../data/OrderRequestsAPI';
import { PricingRecommendationCarrierRequest } from '../pricing-insights/PricingRecommendationCarrierRequest';
import { OrderRequestCard } from './OrderRequestCard';
import { OrderRequestEventObserver } from './OrderRequestEventObserver';
import { OrderRequestsOrderCard } from './OrderRequestsOrderCard';
import { SMSNotificationLink } from './SMSNotificationLink';

interface OrderRequestsDrawerProps {
  onClose: () => void;
  order: Order | undefined;
  onSubmitSuccess: (order: Order) => void;
}

const OrderRequestsBox = styled.div`
  padding: 32px 0;
`;

const EmptyRequestsContainer = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: center;
`;

const OrderRequestPaddedBox = styled.div`
  padding: 0 32px;
`;

const StyledDrawer = styled(Drawer)`
  @media (min-width: 600px) {
    .MuiDrawer-paper {
      max-width: 460px;
      min-width: 460px;
    }
  }
`;

export function OrderRequestsDrawer({
  order,
  onClose,
  onSubmitSuccess,
}: OrderRequestsDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markRequestAsRead } = useOrderRequestsAPI();
  const canUpdateCompanyProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const uid = useUID();
  const {
    data: orderRequests,
    error: orderRequestsError,
    refetch: refetchOrderRequests,
  } = useOrderRequests(order?.guid);
  const { data: orderRequestCount, refetch: refetchOrderRequestCount } =
    useOrderRequestCount(order?.guid);

  const { invalidateOrderCounts } = useOrderCountsCache();

  const paperRef = useRef<HTMLDivElement>();

  const activeRequests =
    orderRequests?.objects.filter(
      (request) => request.status === 'REQUEST_PENDING',
    ) || [];

  const hasPrevCounterOffer = !!orderRequests?.objects.some(
    (request) => request.status === 'COUNTER_OFFER_SENT',
  );

  const { isSubmitting, handleSubmit } = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      return !order
        ? Promise.reject(new Error('Order not found'))
        : markRequestAsRead(order.guid);
    },
  });

  useEffect(() => {
    if (orderRequestCount?.has_new_load_request) {
      handleSubmit();
    }
  }, [orderRequestCount, handleSubmit]);

  useEffect(() => {
    if (orderRequestsError) {
      addSnackbar(orderRequestsError.message, { variant: 'error' });
    }
  }, [orderRequestsError, addSnackbar]);

  function onClickMoreRequests() {
    if (paperRef.current) {
      const scrollStep = 300;
      const scrollMargin =
        paperRef.current.scrollHeight - paperRef.current.clientHeight;

      const scrollBy =
        scrollMargin - paperRef.current.scrollTop > scrollStep
          ? scrollStep
          : scrollMargin - paperRef.current.scrollTop;

      paperRef.current.scrollBy({
        top: scrollBy,
        behavior: 'smooth',
      });
    }
  }

  return (
    <StyledDrawer
      open={!!order}
      PaperProps={{ ref: paperRef }}
      aria-label="Carrier Requests"
      onClose={isSubmitting ? undefined : onClose}
    >
      <DrawerTitle
        title="Carrier Requests"
        titleTypographyProps={{ id: uid }}
        endAction={
          <IconButton
            edge="end"
            aria-label="close"
            onClick={onClose}
            disabled={isSubmitting}
          >
            <Close />
          </IconButton>
        }
      />

      {order && (
        <Box>
          <OrderRequestsOrderCard order={order} />
        </Box>
      )}

      {orderRequests &&
        (orderRequests.objects.length === 0 ? (
          <EmptyRequestsContainer>
            <Typography variant="h4" color="textSecondary">
              No Carrier Requests Yet
            </Typography>
          </EmptyRequestsContainer>
        ) : (
          <OrderRequestsBox>
            <Stack space="small">
              {order?.recommended_price && (
                <Box padding="16px 32px" paddingTop="0">
                  <PricingRecommendationCarrierRequest order={order} />
                </Box>
              )}

              <OrderRequestPaddedBox>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4">
                    {activeRequests.length > 0
                      ? `${activeRequests.length} Active Requests`
                      : 'Requests'}
                  </Typography>
                  {canUpdateCompanyProfile && <SMSNotificationLink />}
                </Box>
              </OrderRequestPaddedBox>

              {orderRequests.objects
                .sort((request) => (request.status === 'OFFER_SENT' ? -1 : 1))
                .map((request, key) => {
                  const isLast = key === orderRequests.objects.length - 1;

                  if (!order) {
                    return undefined;
                  }
                  return (
                    <Fragment key={request.guid}>
                      <OrderRequestEventObserver
                        request={request}
                        order={order}
                        position={key}
                      >
                        {(ref) => (
                          <OrderRequestCard
                            ref={ref}
                            order={order}
                            request={request}
                            hasPrevCounterOffer={hasPrevCounterOffer}
                            onActionSuccess={(requestedOrder) => {
                              void refetchOrderRequests();
                              void refetchOrderRequestCount();
                              void invalidateOrderCounts();

                              onSubmitSuccess(requestedOrder);
                            }}
                          />
                        )}
                      </OrderRequestEventObserver>

                      {!isLast && <Divider />}
                    </Fragment>
                  );
                })}
            </Stack>
          </OrderRequestsBox>
        ))}

      <VisibilityObserver
        render={({ ref, visibility }) => (
          <>
            <div ref={ref} />

            <Fade unmountOnExit={true} in={visibility === 'invisible'}>
              <DrawerActions>
                <Box flex={1} display="flex" justifyContent="center">
                  <Button
                    variant="text"
                    startIcon={<ExpandMore />}
                    onClick={onClickMoreRequests}
                  >
                    More Requests
                  </Button>
                </Box>
              </DrawerActions>
            </Fade>
          </>
        )}
      />
    </StyledDrawer>
  );
}
