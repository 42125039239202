import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DamagedIcon = createSvgIcon(
  'Damaged',
  <>
    <path
      d="M5.3631 15.4258C5.1121 15.5868 4.8691 15.2908 5.0031 14.9858L6.2251 12.1478C6.3061 11.9498 6.2431 11.7338 6.0911 11.6798L2.1751 10.4678C1.9231 10.3778 1.9501 9.93777 2.2111 9.80277L6.2341 8.97677C6.3961 8.89677 6.4861 8.66277 6.4231 8.48277L4.9591 4.60277C4.8601 4.30677 5.1291 3.97377 5.3631 4.09977L8.0841 6.44377C8.2371 6.52477 8.4261 6.41677 8.4981 6.21977L9.7461 2.25877C9.8541 1.93577 10.2131 1.90877 10.2851 2.21377L10.7071 6.03977C10.7521 6.22877 10.9321 6.30977 11.0931 6.20977L13.4101 4.72977C13.6621 4.56777 13.9041 4.86477 13.7701 5.16977L12.8721 7.24477C10.8511 10.7648 8.8031 13.0378 5.3631 15.4268V15.4258Z"
      fill={Color.Red500}
    />
    <path
      d="M19.9221 8.52778C19.829 8.2641 19.6562 8.03595 19.4275 7.87502C19.1988 7.7141 18.9257 7.62838 18.6461 7.62978H13.8501C13.6531 8.06978 13.4281 8.51878 13.1591 8.97678H18.6371L19.9851 13.0188H10.0251C8.5331 14.5008 6.9711 15.5868 5.6151 16.1348V21.1018C5.61563 21.3398 5.71041 21.5679 5.8787 21.7362C6.04699 21.9045 6.2751 21.9992 6.5131 21.9998H7.4111C7.6491 21.9992 7.8772 21.9045 8.0455 21.7362C8.21379 21.5679 8.30857 21.3398 8.3091 21.1018V20.2038H19.0871V21.1018C19.0876 21.3398 19.1824 21.5679 19.3507 21.7362C19.519 21.9045 19.7471 21.9992 19.9851 21.9998H20.8831C21.1211 21.9992 21.3492 21.9045 21.5175 21.7362C21.6858 21.5679 21.7806 21.3398 21.7811 21.1018V13.9168L19.9211 8.52678L19.9221 8.52778ZM8.7671 17.5088C8.59013 17.509 8.41486 17.4744 8.25131 17.4068C8.08777 17.3392 7.93917 17.24 7.81404 17.1148C7.6889 16.9897 7.58969 16.8411 7.5221 16.6776C7.4545 16.514 7.41983 16.3387 7.4201 16.1618C7.4201 15.4168 8.0211 14.8148 8.7671 14.8148C9.5121 14.8148 10.1141 15.4168 10.1141 16.1618C10.1141 16.9078 9.5041 17.5088 8.7671 17.5088ZM18.6471 17.5088C18.47 17.5092 18.2947 17.4746 18.131 17.4071C17.9673 17.3395 17.8186 17.2403 17.6934 17.1152C17.5682 16.99 17.4689 16.8414 17.4012 16.6778C17.3335 16.5142 17.2988 16.3388 17.2991 16.1618C17.2991 15.4168 17.9011 14.8148 18.6461 14.8148C19.3921 14.8148 19.9941 15.4168 19.9941 16.1618C19.9941 16.9078 19.3831 17.5088 18.6461 17.5088H18.6471Z"
      fill={Color.Dark100}
    />
    <title>Delivery damage was marked by driver</title>
  </>,
);
