import {
  PaymentMethod,
  PAYMENT_TERMS,
  SUPERPAY_TERMS,
} from '@superdispatch/sdk';
import { SuperPayStatus } from 'shared/types/order';
import { DeliveryVerificationFailureReasonType } from './DeliveryVerificationDTO';

export type AllPaymentTerm = typeof ALL_PAYMENT_TERMS[number];
export const ALL_PAYMENT_TERMS = [...PAYMENT_TERMS, ...SUPERPAY_TERMS] as const;

export class CarrierPaymentDTO {
  tariff: number;

  deposit: number;

  amount: number;

  terms: AllPaymentTerm;

  notes: string;

  method: PaymentMethod;

  reference_number: string;

  sent_date: string;

  super_pay: {
    amount: number | null;
    status: SuperPayStatus;
    is_on_hold: true;
    expected_at: string | null;
    scheduled_for: string | null;
    paid_at: string;
    on_hold_reasons?: DeliveryVerificationFailureReasonType[];
  };
  superpay_canceled: boolean;
}
