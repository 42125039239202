import { Box, Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import {
  Children,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
} from 'react-virtualized';
import styled from 'styled-components';

const verticalGap = 16;
const bulletSize = 9;
const bulletMargin = 6;
const bulletTopOffset = 5;
const guideWidth = 1;
const branchVerticalGap = 4;
const branchBulletOffset = 6;

const TimelineItemContainer = styled.div`
  position: relative;
`;

const TimelineItemBullet = styled.div`
  position: absolute;
  top: ${verticalGap + bulletTopOffset}px;
  left: ${(bulletSize - guideWidth) / 2 + 16}px;
  width: ${bulletSize}px;
  height: ${bulletSize}px;
  background: ${Color.Silver400};
  border-radius: 50%;
`;

const TimelineItemGuide = styled.div`
  position: absolute;
  top: ${verticalGap + bulletSize + bulletMargin + bulletTopOffset}px;
  left: ${bulletSize - guideWidth + 16}px;
  bottom: -${verticalGap + bulletTopOffset - bulletMargin}px;
  border-left: 1px solid ${Color.Silver400};
  z-index: 1;
`;

const TimelineItemContent = styled.div`
  padding: ${verticalGap}px 0 6px 0;
`;

const TimelineBranchItemWrapper = styled.div`
  position: relative;
  padding-left: ${(guideWidth / 2 + 19) * 2}px;
`;

const TimelineBranchItemBullet = styled.div`
  position: absolute;
  top: ${branchVerticalGap + branchBulletOffset}px;
  left: 40px;
  width: 11px;
  height: 11px;

  border: 1px solid ${Color.Silver400};
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-left-radius: 3px;
`;

const TimelineBranchItemGuide = styled.div`
  position: absolute;
  top: ${branchVerticalGap}px;
  left: 40px;
  bottom: -${branchVerticalGap + branchBulletOffset + 1}px;
  border-left: 1px solid ${Color.Silver400};
`;

const TimelineBranchItemContent = styled.div`
  padding: ${branchVerticalGap}px 0 8px 20px;
`;

const TimelineItemHeading = styled.div`
  transition: all 0.3s linear;
  padding-right: 16px;
`;

export const TimelineItemPadded = styled.div`
  padding-left: ${((bulletSize - guideWidth) / 2 + 19) * 2}px;
`;

export const TimelineCardHeading = styled.div`
  padding: ${(bulletSize - guideWidth) / 2 + 16}px
    ${(bulletSize - guideWidth) / 2 + 16}px 0;
`;

interface TimelineBranchItemProps {
  children: ReactNode;
}

export function TimelineBranchItem({ children }: TimelineBranchItemProps) {
  return (
    <TimelineBranchItemWrapper>
      <TimelineBranchItemGuide />
      <TimelineBranchItemBullet />
      <TimelineBranchItemContent>{children}</TimelineBranchItemContent>
    </TimelineBranchItemWrapper>
  );
}

interface TimelineItemProps {
  title: ReactNode;
  subtitle: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

export function TimelineItem({
  title,
  subtitle,
  children,
  onClick,
}: TimelineItemProps) {
  return (
    <TimelineItemContainer aria-label="timeline item">
      <TimelineItemBullet onClick={onClick} />
      <TimelineItemGuide />
      <TimelineItemContent>
        <Stack space="none">
          <TimelineItemHeading onClick={onClick}>
            <Box display="flex" alignItems="center">
              <TimelineItemPadded>
                <Typography
                  variant="body1"
                  component="div"
                  aria-label="timeline title"
                >
                  {title}
                </Typography>
              </TimelineItemPadded>
            </Box>
          </TimelineItemHeading>
          <Box>
            <Box
              color={Color.Dark200}
              fontSize="12px"
              aria-label="timeline subtitle"
            >
              {subtitle}
            </Box>
            {children && <Box paddingTop="8px">{children}</Box>}
          </Box>
        </Stack>
      </TimelineItemContent>
    </TimelineItemContainer>
  );
}

const TimelineRoot = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  margin-bottom: 2px;

  .last-item ${TimelineBranchItemGuide}, .last-item ${TimelineItemGuide} {
    display: none;
  }
`;

const ListWithPadding = styled(List)`
  padding-right: 24px;
`;

interface TimelineProps {
  children:
    | Array<React.ReactElement<TimelineItemProps>>
    | React.ReactElement<TimelineItemProps>;
}

export function TimelineVirtualized({ children }: TimelineProps) {
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: false,
      defaultHeight: 182,
    });
  }, []);

  const elements = useMemo(() => {
    return Array.isArray(children) ? children : Children.toArray(children);
  }, [children]);

  const rowRenderer: ListRowRenderer = useCallback(
    ({ index, key, parent, style }) => {
      const element = elements[index];
      if (!isValidElement<TimelineItemProps>(element)) {
        return null;
      }

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <div
            style={style}
            className={index === elements.length - 1 ? 'last-item' : ''}
          >
            {element}
          </div>
        </CellMeasurer>
      );
    },
    [cache, elements],
  );

  return (
    <TimelineRoot aria-label="timeline">
      <AutoSizer>
        {({ height, width }) => (
          <ListWithPadding
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            rowCount={elements.length}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            overscanRowCount={15}
          />
        )}
      </AutoSizer>
    </TimelineRoot>
  );
}
