import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { WhoAmI } from 'shared/types/user';
import { useShipperProfile } from 'shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from 'shipper-profile/data/ShipperProfileDTO';
import { OwnerAddFormValues } from './OwnerAddFormValues';
import { OwnerFormFields } from './OwnerFormFields';
import { useOwnerAddForm } from './useOwnerAddForm';

interface SuperPayOwnerInitialFormProps {
  onSubmitSuccess: () => void;
}

export function OwnerAddInitialForm({
  onSubmitSuccess,
}: SuperPayOwnerInitialFormProps) {
  const isMobile = useResponsiveValue(true, false);
  const { user } = useUserState();
  const { data: profile } = useShipperProfile();

  const initialValues = useMemo(
    () => getDefaultOwnerValues(user, profile),
    [user, profile],
  );

  const formik = useOwnerAddForm({
    initialValues,
    onSubmitSuccess,
    isController: true,
  });
  const { isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Card square={isMobile}>
          <CardContent>
            <Stack space="medium">
              <Stack>
                <Typography variant="h3">Owner/Representative</Typography>
                <Typography color="textSecondary">
                  Please enter personal information and residential address of
                  the majority owner or a high-level manager such as the CEO,
                  CFO or COO.
                </Typography>
              </Stack>
              <OwnerFormFields />
              <Button
                type="submit"
                disabled={isSubmitting}
                pending={isSubmitting}
              >
                Continue
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Form>
    </FormikProvider>
  );
}

function getDefaultOwnerValues(
  user?: WhoAmI,
  profile?: ShipperProfileDTO,
): Partial<OwnerAddFormValues> {
  const { first_name, last_name } = user || {};
  const { contact_email } = profile || {};
  return {
    name: {
      firstName: first_name && first_name.length <= 64 ? first_name : '',
      lastName: last_name && last_name.length <= 64 ? last_name : '',
    },
    email: contact_email ?? '',
  };
}
