import { ReactNode } from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Column = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
`;

const BlurredColumn = styled(Column)<{ blur?: number }>`
  filter: ${({ blur }) => `blur(${blur || 2.5}px)`};
  opacity: 0.2;
  user-select: none;
`;

const FrontColumn = styled(Column)`
  align-self: center;
  z-index: 1;
`;

interface BlurredBackdropProps {
  blur?: number;
  blurredElements: ReactNode;
  children: ReactNode;
}

export function BlurredBackdrop({
  blur,
  blurredElements,
  children,
}: BlurredBackdropProps) {
  return (
    <Grid>
      <FrontColumn>{children}</FrontColumn>
      <BlurredColumn blur={blur}>{blurredElements}</BlurredColumn>
    </Grid>
  );
}
