import { Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { showIntercomDialog } from 'shared/helpers/IntercomHelpers';
import { useTrackContactComplianceEvent } from '../data/VerificationAnalyticsEvents';

export interface ShipperTypeHelpDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function ShipperTypeHelpDrawer({
  open,
  onClose,
}: ShipperTypeHelpDrawerProps) {
  const trackContactComplianceEvent = useTrackContactComplianceEvent();
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="xlarge">
            <Stack space="small">
              <Typography variant="h2">
                Why do we ask for your Shipper Type?
              </Typography>
              <Typography>
                Super Dispatch supports various shipper types, so this helps us
                identify the documentation our compliance team needs to verify
                your account.
              </Typography>
            </Stack>
            <Stack space="small">
              <Typography variant="h4">Can we help?</Typography>
              <Button
                variant="text"
                endIcon={<ChevronRight />}
                onClick={() => {
                  trackContactComplianceEvent('Shipper Type');
                  showIntercomDialog();
                }}
              >
                Contact our Compliance Team
              </Button>
            </Stack>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Ok
        </Button>
      </DrawerActions>
    </Drawer>
  );
}
