import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Color, Column, Columns } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';

export function ExpeditePaymentWarning() {
  return (
    <Box backgroundColor="Yellow50" padding="xsmall" borderRadius="small">
      <Columns space="xsmall" align="center">
        <Column width="content">
          <Warning fontSize="small" htmlColor={Color.Yellow300} />
        </Column>
        <Column>
          <Typography>This carrier doesn’t have SuperPay yet.</Typography>
        </Column>
      </Columns>
    </Box>
  );
}
