import { Drawer, IconButton, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  FormikMaxLengthTextField,
  FormikTextField,
} from '@superdispatch/forms';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import {
  MOOV_BUSINESS_TYPES,
  MOOV_BUSINESS_TYPE_DISPLAY_NAMES,
  MOOV_MCC_CODES,
  MOOV_MCC_CODE_DISPLAY_NAMES,
} from 'shared/helpers/superpay/MoovDTO';
import { BusinessDetailsDTO, businessDetailsSchema } from '../data/BusinessDTO';

export type Source = 'Add Link' | 'Edit Icon';

export interface BusinessDetailsEditDrawerProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: BusinessDetailsDTO) => void;
  values: BusinessDetailsDTO;
  source?: Source;
}

export function BusinessDetailsEditDrawer({
  open,
  onClose,
  onSave,
  values,
  source,
}: BusinessDetailsEditDrawerProps) {
  const formik = useAppFormik({
    initialValues: {
      legalBusinessName: values.legalBusinessName,
      businessType: values.businessType,
      einNumber: values.einNumber,
      mccNumber: values.mccNumber,
      description: values.description,
    },
    validationSchema: businessDetailsSchema,
    onSubmit: (data) => {
      onSave(data);
      onClose();
      trackEvent('Shipper Updated Business Details in Drawer', {
        utm_medium: source,
      });
    },
  });

  useEffect(() => {
    if (open) {
      trackEvent('Shipper Opened Business Details Drawer', {
        utm_medium: source,
      });
    }
  }, [source, open]);

  return (
    <Drawer open={open} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form>
          <Box width={['initial', '432px']}>
            <DrawerTitle
              title="Business Details"
              endAction={
                <IconButton edge="end" onClick={onClose}>
                  <Close />
                </IconButton>
              }
            />
            <DrawerContent>
              <Stack space="small">
                <FormikTextField
                  label="Legal Business Name"
                  name="legalBusinessName"
                  fullWidth={true}
                  disabled={true}
                />

                <FormikTextField
                  label="Business Type"
                  name="businessType"
                  placeholder="Select option"
                  select={true}
                  fullWidth={true}
                >
                  {MOOV_BUSINESS_TYPES.map((businessType) => (
                    <MenuItem key={businessType} value={businessType}>
                      {MOOV_BUSINESS_TYPE_DISPLAY_NAMES[businessType]}
                    </MenuItem>
                  ))}
                </FormikTextField>

                <FormikTextField
                  label="Employer Identification Number (EIN)"
                  name="einNumber"
                  fullWidth={true}
                  helperText="9 digits. You can find your business’s EIN on tax returns, credit reports, payroll paperwork, or licenses and permits."
                />

                <FormikTextField
                  label="Industry - MCC code"
                  name="mccNumber"
                  placeholder="Select option"
                  select={true}
                  fullWidth={true}
                >
                  {MOOV_MCC_CODES.map((mccCode) => (
                    <MenuItem key={mccCode} value={mccCode}>
                      {MOOV_MCC_CODE_DISPLAY_NAMES[mccCode]} – {mccCode}
                    </MenuItem>
                  ))}
                </FormikTextField>

                <FormikMaxLengthTextField
                  name="description"
                  label="Description"
                  maxLength={100}
                  minRows={3}
                  placeholder="Tell us about what your business does"
                  multiline={true}
                  fullWidth={true}
                />
              </Stack>
            </DrawerContent>

            <DrawerActions>
              <Button onClick={() => formik.handleSubmit()}>Save</Button>
            </DrawerActions>
          </Box>
        </Form>
      </FormikProvider>
    </Drawer>
  );
}
