import { Link, Typography } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { NumericFormat } from 'react-number-format';
import { SuperPayIcon } from 'shared/icons/SuperPayIcon';
import styled from 'styled-components';
import { useDynamicStatistics } from '../data/SuperPayAPI';
import { CarrierAvatars } from './CarrierAvatars';
import { GradientBox } from './GradientBox';

const CarrierContainer = styled(Box)`
  align-items: center;
  flex-wrap: nowrap;
  max-width: 240px;
  gap: 3px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    max-width: 100%;
  }
`;

const CarrierCount = styled(Typography)`
  white-space: nowrap;
`;

interface SuperPayRequestedBannerProps {
  source: 'Reports' | 'Manage Carriers' | 'Requests';
}

export function SuperPayRequestedBanner({
  source,
}: SuperPayRequestedBannerProps) {
  const { data } = useDynamicStatistics();
  const requestedStatistics = data?.superpay_requested_statistics;

  if (!requestedStatistics) {
    return null;
  }

  const { carriers_list, carriers_count, delivered_orders_count } =
    requestedStatistics;
  const [topCarrier] = carriers_list;
  const carriersCount = carriers_count && carriers_count - 1;

  return (
    <Link
      href={`/superpay?utm_medium=${source}&utm_content=Carrier Request SuperPay Banner`}
      color="inherit"
      target="_blank"
      rel="noreferrer"
      aria-label="SuperPay Requested Banner"
    >
      <GradientBox>
        <Inline verticalAlign="center" space="xxsmall">
          <CarrierAvatars carriersList={carriers_list} />
          <CarrierContainer display="flex">
            <Typography variant="body1" noWrap={true}>
              {topCarrier?.name}
            </Typography>
            {!!carriersCount && (
              <CarrierCount variant="body1">
                and {carriersCount} others
              </CarrierCount>
            )}
          </CarrierContainer>
          <Typography>have requested</Typography>
          <SuperPayIcon width={88} height={18} />
          <Typography>
            from you. Streamline payments on{' '}
            <NumericFormat
              displayType="text"
              value={delivered_orders_count}
              thousandSeparator={true}
            />{' '}
            orders now.
          </Typography>
          <Link
            href={`/superpay?utm_medium=${source}&utm_content=Carrier Request SuperPay Banner`}
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </Link>
        </Inline>
      </GradientBox>
    </Link>
  );
}
