import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { User } from 'shared/types/user';
import { OrderRevision } from '../types/OrderRevision';

interface OrderRevisions {
  revisions: OrderRevision[];
  users: User[];
}

export function useOrderRevisions(guid: string) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['orders', 'revisions', { guid }],
    () =>
      requestResource(
        '/internal/orders/guid/{guid}/revisions',
        (data) => data as OrderRevisions,
        { guid },
      ),
    {
      enabled: !!guid,
    },
  );
}
