import { useProductTiers } from 'shared/data/TiersUtils';
import { writeStorageItem } from 'shared/helpers/LocalStorage';
import SelectGroup from 'shared/ui/SelectGroup';
import { useSearchType } from './OrderListSearchType';

const fullCategories = [
  {
    label: 'Order',
    value: 'orderCategory',
    items: [
      { label: 'Order ID', value: 'number' },
      { label: 'PO Number', value: 'purchaseOrderNumber' },
    ],
  },
  {
    label: 'Vehicle',
    value: 'vehicleCategory',
    items: [
      { label: 'VIN', value: 'vin' },
      { label: 'Make', value: 'make' },
      { label: 'Model', value: 'model' },
      { label: 'Lot Number', value: 'lotNumber' },
    ],
  },
  {
    label: 'Location',
    value: 'locationCategory',
    items: [
      { label: 'Pickup City', value: 'pickup.venue.city' },
      { label: 'Delivery City', value: 'delivery.venue.city' },
      { label: 'Pickup Terminal', value: 'pickup.venue.name' },
      { label: 'Delivery Terminal', value: 'delivery.venue.name' },
    ],
  },
  {
    label: 'Customer',
    value: 'customerCategory',
    items: [
      { label: 'Name', value: 'customer.name' },
      { label: 'Contact', value: 'customer.contactName' },
    ],
  },
  {
    label: 'Carrier',
    value: 'carrierCategory',
    items: [{ label: 'Name', value: 'carrierName' }],
  },
  { label: 'All', value: 'all', items: [] },
];

const basicTierCategories = [
  {
    label: 'Order',
    value: 'orderCategory',
    items: [
      { label: 'Order ID', value: 'number' },
      { label: 'PO Number', value: 'purchaseOrderNumber' },
    ],
  },
  {
    label: 'Vehicle',
    value: 'vehicleCategory',
    items: [
      { label: 'VIN', value: 'vin' },
      { label: 'Make', value: 'make' },
      { label: 'Model', value: 'model' },
      { label: 'Lot Number', value: 'lotNumber' },
    ],
  },
  {
    label: 'Location',
    value: 'locationCategory',
    items: [
      { label: 'Pickup City', value: 'pickup.venue.city' },
      { label: 'Delivery City', value: 'delivery.venue.city' },
      { label: 'Pickup Terminal', value: 'pickup.venue.name' },
      { label: 'Delivery Terminal', value: 'delivery.venue.name' },
    ],
  },
  {
    label: 'Carrier',
    value: 'carrierCategory',
    items: [{ label: 'Name', value: 'carrierName' }],
  },
  { label: 'All', value: 'all', items: [] },
];

interface Props {
  fullWidth?: boolean;
}

export function OrderListSearchTypeCategoryField({ fullWidth }: Props) {
  const searchType = useSearchType();
  const { isAdvancedTier } = useProductTiers();

  const items = isAdvancedTier ? fullCategories : basicTierCategories;

  return (
    <SelectGroup
      persist={true}
      items={items}
      value={searchType}
      fullWidth={true}
      style={{ width: fullWidth ? '100%' : 180 }}
      onChange={(value) => {
        writeStorageItem('order_search_type', value);
      }}
    />
  );
}
