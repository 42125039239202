import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useUserState } from 'shared/data/AppUserState';
import { debounceBatchCalls } from 'shared/utils/FunctionUtils';
import { orderPaymentFlagSchema } from './dto/OrderPaymentFlagsDTO';

export function useOrderPaymentFlagsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const invalidateOrderPaymentFlags = () => {
      void queryClient.invalidateQueries(['orders', 'payment_flags']);
      void queryClient.invalidateQueries(['orders', 'payment_flag']);
    };

    return { invalidateOrderPaymentFlags };
  }, [queryClient]);
}

function useOrderListPaymentFlagsByOrderIds(orderIds: number[]) {
  const { user } = useUserState();
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['orders', 'payment_flags', { orderIds: orderIds.join(',') }],
    () =>
      requestPage(
        'GET /internal/orders/payment_flags{?orderIds}',
        (data) => orderPaymentFlagSchema.cast(data),
        {
          orderIds,
        },
      ),
    {
      enabled: !!orderIds.length && !!user?.shipper.had_superpay_access,
    },
  );
}

export function useOrderListPaymentFlags(orderIds: number[]) {
  const { data } = useOrderListPaymentFlagsByOrderIds(orderIds);
  return useMemo(() => data?.objects, [data?.objects]);
}

const batchOrderPaymentFlagCalls = debounceBatchCalls();
function useOrderPaymentFlagsList(orderId?: number) {
  const { user } = useUserState();
  const { requestPage } = useAPI();
  return useAPIPageQuery(
    ['orders', 'payment_flag', { orderIds: orderId }],
    () => {
      return batchOrderPaymentFlagCalls([String(orderId)], (param) =>
        requestPage(
          'GET /internal/orders/payment_flags{?orderIds}',
          (data) => orderPaymentFlagSchema.cast(data),
          { orderIds: param },
        ),
      );
    },
    { enabled: !!orderId && !!user?.shipper.had_superpay_access },
  );
}

export function useOrderPaymentFlags(orderId?: number) {
  const { data } = useOrderPaymentFlagsList(orderId);
  return useMemo(
    () => data?.objects.find((item) => item.order_id === orderId),
    [data?.objects, orderId],
  );
}
