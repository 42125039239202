import { useMemo } from 'react';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const usageDetailsSchema = yupObject({
  usages: yupArray(
    yupObject({
      name: yupString()
        .oneOf(['VIN_DELIVERED', 'VIN_POSTED', 'SUPERPAY_PAID'] as const)
        .required(),
      current_item_count: yupNumber().required(),
      max_item_count: yupNumber().required(),
      overage_price: yupNumber().required(),
    }),
  ).required(),
  current_term_start: yupString().nullable(),
  current_term_end: yupString().nullable(),
});

export type UsageDetails = InferType<typeof usageDetailsSchema>;
export type UsageName = UsageDetails['usages'][number]['name'];

const CACHE_KEY = ['subscription-details', 'plan-usages'] as const;

const usageNamesInOrder = [
  'VIN_POSTED',
  'VIN_DELIVERED',
  'SUPERPAY_PAID',
] as const;

function sortUsages(usages: UsageDetails['usages']) {
  return usages
    .slice(0)
    .sort(
      (a, b) =>
        usageNamesInOrder.indexOf(a.name) - usageNamesInOrder.indexOf(b.name),
    );
}

export function useUsageDetails() {
  const { requestResource } = useAPI();
  const { data, isLoading } = useAPIQuery(
    CACHE_KEY,
    () =>
      requestResource(
        'GET /internal/subscription/plan/usages',
        (rawData) => rawData as UsageDetails,
      ),
    { schema: usageDetailsSchema },
  );

  const usageDetailsWithSortedUsages = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return {
      ...data,
      usages: sortUsages(data.usages),
    };
  }, [data]);

  return {
    usageDetails: usageDetailsWithSortedUsages,
    isUsageDetailsLoading: isLoading,
  };
}
