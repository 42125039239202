import { IconButton, Link } from '@material-ui/core';
import { Add, Edit, Error } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { FeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import {
  useImportAndPostCDLoadsMutation,
  useImportAndPostCDLoadsStatus,
} from 'shipper-profile/data/ShipperProfileAPI';
import { useCentralDispatchCredentials } from '../../core/cental-dispatch/CentralDispatchAPI';
import { VerificationApplicationDialog } from '../core/VerificationApplicationDialog';
import { EmptySection, SectionLayout } from '../SectionLayout';
import { CentralDispatchIntegrationDrawer } from './CentralDispatchIntegrationDrawer';

export function CentralDispatchIntegration() {
  const navigate = useNavigate();
  const { tryBack } = useTryBack();
  const drawerMatch = useMatch('/profile/cd-integration/edit');
  const { data: credentials } = useCentralDispatchCredentials();
  const shouldShowWarning =
    !!credentials?.login && !credentials.is_valid_web_credentials;

  const openDrawer = () => navigate('/profile/cd-integration/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  return (
    <>
      {credentials && (
        <CentralDispatchIntegrationDrawer
          credentials={credentials}
          open={!!drawerMatch}
          onClose={closeDrawer}
          onSubmitSuccess={closeDrawer}
        />
      )}

      {!credentials?.uid && !credentials?.login ? (
        <EmptySection onClick={openDrawer} startIcon={<Add />}>
          Add Central Dispatch Integration
        </EmptySection>
      ) : (
        <SectionLayout
          title="Central Dispatch Integration"
          editComponent={
            <IconButton
              aria-label="edit cd integration"
              size="small"
              onClick={openDrawer}
            >
              <Edit />
            </IconButton>
          }
        >
          <Stack space="small">
            <DescriptionItem icon={<HashtagIcon />} label="UID">
              {credentials.uid}
            </DescriptionItem>

            <DescriptionItem icon={<HashtagIcon />} label="Username">
              {credentials.login}
            </DescriptionItem>

            {shouldShowWarning && (
              <CentralDispatchCredentialsWarning onClick={openDrawer} />
            )}

            {!shouldShowWarning && (
              <FeatureToggle name="cd-integration.import-post-listings">
                <ImportCentralDispatchLoads />
              </FeatureToggle>
            )}
          </Stack>
        </SectionLayout>
      )}
    </>
  );
}

interface CentralDispatchCredentialsWarningProps {
  onClick: () => void;
}

function CentralDispatchCredentialsWarning({
  onClick,
}: CentralDispatchCredentialsWarningProps) {
  return (
    <Box padding="xsmall" borderRadius="small" backgroundColor="Red50">
      <Columns space="xsmall" align="center">
        <Column width="content">
          <Error fontSize="small" htmlColor={Color.Red300} />
        </Column>
        <Column width="adaptive">
          <TextBox>
            Invalid Web credentials.{' '}
            <Link component="button" href="#" onClick={onClick}>
              Click here to update
            </Link>
          </TextBox>
        </Column>
      </Columns>
    </Box>
  );
}

function ImportCentralDispatchLoads() {
  const { user } = useUserState();
  const { addSnackbar } = useSnackbarStack();
  const [showVerifiedModal, $showVerifiedModal] = useState(false);

  const { data: importStatus } = useImportAndPostCDLoadsStatus();
  const importLoads = useImportAndPostCDLoadsMutation({
    onSuccess() {
      addSnackbar(
        'Your listings are being posted to Super Loadboard. This may take a few minutes.',
      );
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Stack space="xsmall">
      <VerificationApplicationDialog
        open={showVerifiedModal}
        onClose={() => $showVerifiedModal(false)}
      />

      <Button
        data-intercom-target="import cd listings"
        pending={importLoads.isLoading || importStatus?.can_request === false}
        onClick={() => {
          if (user?.shipper.verification_status === 'VERIFIED') {
            importLoads.mutate(undefined);
          } else {
            $showVerifiedModal(true);
          }
          trackEvent('Shipper Clicked Post My Listings to Super Loadboard');
        }}
      >
        Post My Listings to Super Loadboard
      </Button>
      <TextBox color="secondary">
        {importStatus?.can_request === false
          ? 'Your listings are being posted to Super Loadboard.  This may take a few minutes. We will notify you once done.'
          : 'Easily import listings to Super Loadboard. Your existing listings will continue to be displayed on Central Dispatch.'}
      </TextBox>
    </Stack>
  );
}
