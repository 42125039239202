import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { email } from 'shared/utils/ValidatorUtils';
import { useManageUsersAPI } from './data/ManageUsersAPI';

interface Props {
  userGuid: string;
  userNameHelper: string;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export function UserReactivateDialog({
  userGuid,
  userNameHelper,
  open,
  onClose,
  onUpdate,
}: Props) {
  const { reactivateUser } = useManageUsersAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: { username: '' },
    onSubmit: ({ username }) => reactivateUser(username, userGuid),
    getFormErrors: (error) => {
      return {
        username: error.message,
      };
    },
    onSubmitSuccess: () => {
      onClose();
      addSnackbar(
        'User reactivated. A password reset link is sent to the user.',
        { variant: 'success' },
      );
      onUpdate();
    },
  });
  return (
    <FormikProvider value={formik}>
      <ConfirmDialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => (formik.isSubmitting ? undefined : onClose())}
        title={`Reactivate ${userNameHelper}?`}
        cancelButtonProps={{
          children: 'Cancel',
          disabled: formik.isSubmitting,
          onClick: () => onClose(),
        }}
        confirmButtonProps={{
          children: 'Reactivate',
          disabled:
            formik.isSubmitting || !formik.values.username || !formik.isValid,
          isLoading: formik.isSubmitting,
          onClick: () => formik.handleSubmit(),
          autoFocus: false,
        }}
      >
        <Stack align="center">
          <FormikTextField
            label="Username"
            name="username"
            helperText="Enter username as email format to reactivate this user."
            fullWidth={true}
            validate={email}
          />
        </Stack>
      </ConfirmDialog>
    </FormikProvider>
  );
}
