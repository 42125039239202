import { DateString } from '@superdispatch/dates';
import { Exclude, Expose, Type } from 'class-transformer';
import { User, UserDTO } from './user';

/** @deprecated */
export interface Note {
  id: number;
  is_active?: boolean;
  changed_at: string;
  created_at: string;
  note: string;
  user: User;
  guid: string;
}

@Exclude()
export class NoteDTO {
  @Expose() id: number;
  @Expose() note: string;
  @Expose() created_at: DateString;
  @Expose() changed_at: DateString;
  @Expose() guid: string;

  @Expose()
  @Type(() => UserDTO)
  user: UserDTO;
}
