import { useEffect, useState } from 'react';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';

const SEND_OFFER_RECENT_TAB_STORAGE_KEY_UPDATED =
  'send_offer_recent_tab_updated';

export function useCarrierNetworkTab({
  hasPartnerCarriers,
}: {
  hasPartnerCarriers: boolean | undefined;
}) {
  const recentTab = useStorageValue(SEND_OFFER_RECENT_TAB_STORAGE_KEY_UPDATED);
  const selectedTab = recentTab
    ? recentTab === 'partner' && !hasPartnerCarriers
      ? 'previously_dispatched'
      : recentTab
    : 'suggested';

  const [tabValue, setTabValue] = useState(selectedTab);

  useEffect(() => {
    setTabValue(selectedTab);
  }, [selectedTab]);

  const changeTab = (newValue: string) => {
    setTabValue(newValue);
    writeStorageItem(SEND_OFFER_RECENT_TAB_STORAGE_KEY_UPDATED, newValue);
  };

  return { tabValue, changeTab };
}
