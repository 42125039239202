import {
  CircularProgress,
  Divider,
  Hidden,
  Link,
  Theme,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useValueObserver } from '@superdispatch/hooks';
import {
  Column,
  Columns,
  InfoCard,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { EmptyRatingIcon } from 'shared/icons/EmptyRatingIcon';
import { useLayoutContext } from 'shared/layout/PageLayout';
import styled, { css } from 'styled-components';
import { trackRatings } from './data/ShipperProfileAnalytics';
import {
  useShipperRatings,
  useShipperRatingTagStats,
} from './data/ShipperProfileAPI';
import { ShipperProfileDTO } from './data/ShipperProfileDTO';
import {
  ShipperPositiveRatingTag,
  ShipperRatingDTO,
  ShipperRatingTagStatsDTO,
} from './data/ShipperRatingDTO';
import { ShipperRating } from './ShipperRating';
import { ShipperRatingResponse } from './ShipperRatingResponse';

const ShipperRatingTagStatItem = styled.div(
  ({ theme }: { theme: Theme }) => css`
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.up('md')} {
      flex-direction: column;
      align-items: center;

      & > *:not(:first-child) {
        padding-top: 4px;
      }
    }
  `,
);

const PaginationBox = styled(Box)`
  display: flex;
  justify-content: center;
`;
interface ShipperRatingTagStatProp {
  stats: ShipperRatingTagStatsDTO;
  tag: ShipperPositiveRatingTag;
}

function ShipperRatingTagStat({ tag, stats }: ShipperRatingTagStatProp) {
  const uid = useUID();
  const count = stats[tag];

  return (
    <ShipperRatingTagStatItem>
      <TextBox id={uid} color="secondary" align="center">
        {ShipperRatingDTO.formatRatingTag(tag)}
      </TextBox>

      {count ? (
        <TextBox variant="heading-4" color="primary" aria-labelledby={uid}>
          {count}
        </TextBox>
      ) : (
        <TextBox variant="heading-4" color="secondary" aria-labelledby={uid}>
          None
        </TextBox>
      )}
    </ShipperRatingTagStatItem>
  );
}

const VerticalDivider = styled(Divider).attrs({ orientation: 'vertical' })`
  height: 48px;
`;
interface ShipperRatingsProps {
  shipper: ShipperProfileDTO;
}

export function ShipperRatings({ shipper }: ShipperRatingsProps) {
  const [page, setPage] = useState(0);
  const { scrollTop } = useLayoutContext();
  const { data, isLoading } = useShipperRatings(page);
  const { data: ratingTagStats } = useShipperRatingTagStats();

  useValueObserver(page, () => {
    scrollTop();
  });

  useEffect(() => trackRatings('[STMS] Opened Own Ratings'), []);

  return (
    <InfoCard size="large">
      <Stack space="medium">
        <Stack space="small">
          <Columns collapseBelow="tablet" space="xsmall">
            <Column>
              <TextBox variant="heading-3">Ratings Received</TextBox>
            </Column>

            <Column width="content">
              <Inline space="xxsmall" verticalAlign="center">
                <Help fontSize="small" color="action" />

                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.superdispatch.com/en/articles/5716958-ratings-feature-on-super-dispatch"
                >
                  How ratings work?
                </Link>
              </Inline>
            </Column>
          </Columns>

          {ratingTagStats && (
            <Columns
              collapseBelow="desktop"
              space="xsmall"
              aria-label="tag statistics"
            >
              <Column>
                <ShipperRatingTagStat
                  tag="ON_TIME_PAYMENT"
                  stats={ratingTagStats}
                />
              </Column>

              <Hidden smDown={true}>
                <Column width="content">
                  <VerticalDivider />
                </Column>
              </Hidden>

              <Column>
                <ShipperRatingTagStat
                  tag="GOOD_COMMUNICATION"
                  stats={ratingTagStats}
                />
              </Column>

              <Hidden smDown={true}>
                <Column width="content">
                  <VerticalDivider />
                </Column>
              </Hidden>

              <Column>
                <ShipperRatingTagStat
                  tag="ACCURATE_INFORMATION"
                  stats={ratingTagStats}
                />
              </Column>
            </Columns>
          )}
        </Stack>

        <Stack space="small" align={data ? 'left' : 'center'}>
          {!data ? (
            <CircularProgress />
          ) : data.pagination.total_objects > 0 ? (
            <Stack space="large">
              {data.objects.map((rating) => (
                <Stack key={rating.guid} space="small">
                  <ShipperRating rating={rating} />
                  {rating.rating === 'NEGATIVE' && (
                    <ShipperRatingResponse rating={rating} shipper={shipper} />
                  )}
                </Stack>
              ))}
            </Stack>
          ) : (
            <Stack space="small" align="center">
              <EmptyRatingIcon />

              <Stack align="center">
                <TextBox variant="heading-3" color="secondary">
                  No ratings
                </TextBox>
                <Stack space="none" align="center">
                  <TextBox color="secondary">Ask your Carriers to give</TextBox>
                  <TextBox color="secondary"> you a positive rating!</TextBox>
                </Stack>
              </Stack>
            </Stack>
          )}

          {data?.pagination && data.pagination.total_pages > 1 && (
            <PaginationBox paddingTop="small">
              <Pagination
                page={page + 1}
                disabled={isLoading}
                count={data.pagination.total_pages}
                onChange={(_, value) => setPage(value - 1)}
              />
            </PaginationBox>
          )}
        </Stack>
      </Stack>
    </InfoCard>
  );
}
