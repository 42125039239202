import { Link, MenuItem, Typography } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { CARRIER_APP_HOST } from 'shared/config/ServerConstants';
import { useUserState } from 'shared/data/AppUserState';
import { usePermission } from 'shared/data/UserPermissions';
import { WhoAmI } from 'shared/types/user';
import { List } from 'shared/ui/List';
import { ActionDivider } from '../orders/core/actions/OrderActionUtils';
import { trackEvent } from '../shared/helpers/AnalyticsHelpers';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import { SuperLoadboardSettingsSection } from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';

function getLoadboardLink(user: WhoAmI | undefined) {
  const shipperGUID = user?.shipper.guid || '';
  return `${CARRIER_APP_HOST}/loadboard/loads?shipper_guid=${shipperGUID}&current_tab=private-loadboard`;
}

interface LoadboardSectionProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardPrivateNetwork({
  profile,
}: LoadboardSectionProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const { user } = useUserState();
  const copyLink = getLoadboardLink(user);
  const canUpdateCompany = usePermission(
    'UPDATE_COMPANY_PROFILE',
    'canExecute',
  );

  const canUpdateDuration =
    canUpdateCompany && user?.shipper.is_private_network_enabled;

  const formik = useFormikEnhanced({
    initialValues: {
      ...profile,
      private_loadboard_duration: profile.private_loadboard_duration,
    },
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Private Network',
        private_loadboard_duration: updatedValues.private_loadboard_duration,
      });
      addSnackbar('Settings updated', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });
  return (
    <SuperLoadboardSettingsSection data-intercom-target="private network settings">
      <FormikProvider value={formik}>
        <Stack space="small">
          <Columns align="center" space="small">
            <Column width="content">
              <Typography variant="h4">Private Loadboard</Typography>
            </Column>

            <Column width="fluid">
              {!user?.shipper.is_private_network_enabled && (
                <Button
                  onClick={() => {
                    trackEvent(
                      'Shipper Clicked Request Private Loadboard Access',
                      {
                        utm_medium: 'Super Loadboard Settings',
                      },
                    );
                  }}
                  variant="neutral"
                  data-intercom-target="request private loadboard access"
                >
                  Request Access
                </Button>
              )}
            </Column>

            {user?.shipper.is_private_network_enabled && (
              <Column width="content">
                <Inline verticalAlign="center">
                  <LinkIcon color="action" />
                  <Link
                    href="#"
                    onClick={() => {
                      void navigator.clipboard.writeText(copyLink);
                      trackEvent('Shipper Copied Link for Carrier', {
                        utm_medium: 'Super Loadboard Settings',
                      });
                      addSnackbar('Link copied to clipboard', {
                        variant: 'success',
                      });
                    }}
                  >
                    Copy Link for Carrier
                  </Link>
                </Inline>
              </Column>
            )}
          </Columns>

          <Stack space="small">
            <Columns align="center">
              <Column width="3/5">
                <Stack space="xsmall">
                  <Typography color="textSecondary">
                    Auto-Post to Super Loadboard
                  </Typography>

                  <List>
                    <li>
                      <Typography color="textSecondary">
                        Set the default duration orders are posted exclusively
                        to your Private Network.
                      </Typography>
                    </li>
                    <li>
                      <Typography color="textSecondary">
                        Changes apply only to newly posted orders.
                      </Typography>
                    </li>
                  </List>
                </Stack>
              </Column>

              <Column width="fluid">
                <FormikTextField
                  fullWidth={true}
                  name="private_loadboard_duration"
                  select={true}
                  onChange={() => {
                    void formik.submitForm();
                  }}
                  disabled={!canUpdateDuration}
                  data-intercom-target={
                    canUpdateDuration && 'Auto-Post to SLB Setting'
                  }
                >
                  <MenuItem value="PT1H">After 1 hour</MenuItem>
                  <MenuItem value="PT2H">After 2 hours</MenuItem>
                  <MenuItem value="PT4H">After 4 hours</MenuItem>
                  <MenuItem value="PT8H">After 8 hours</MenuItem>
                  <MenuItem value="PT12H">After 12 hours</MenuItem>
                  <MenuItem value="PT24H">After 24 hours</MenuItem>
                  <ActionDivider key="divider-9" />
                  <MenuItem value="P30D">
                    Always keep in Private Loadboard
                  </MenuItem>
                </FormikTextField>
              </Column>
            </Columns>
          </Stack>
        </Stack>
      </FormikProvider>
    </SuperLoadboardSettingsSection>
  );
}
