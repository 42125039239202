import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIPageResponse, useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { setAPIQueryData } from 'shared/api/APIQuery';
import { valuesToPlain } from 'shared/utils/DataUtils';
import {
  APIMutationOptions,
  useAPIMutation,
} from '../../shared/api/APIMutation';
import { ReportDTO } from './ReportDTO';
import { ReportFieldGroupDTO } from './ReportFieldDTO';
import { ReportParamsDTO } from './ReportParamsDTO';

export function useReportsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function setSavedReports(fn: (reports: ReportDTO[]) => ReportDTO[]) {
      setAPIQueryData<APIPageResponse<ReportDTO>['data']>(
        queryClient,
        ['reports', 'saved-reports'],
        ({ pagination, objects }) => ({
          pagination,
          objects: fn(objects),
        }),
      );
    }

    function invalidateSavedReports() {
      void queryClient.invalidateQueries(['reports', 'saved-reports']);
    }

    return { setSavedReports, invalidateSavedReports };
  }, [queryClient]);
}

export function useReportsAPI() {
  const cache = useReportsCache();
  const { request, requestResource } = useAPI();

  return useMemo(() => {
    return {
      generateReport: (value: ReportParamsDTO) =>
        request('POST /internal/reports/generate', {
          json: valuesToPlain(ReportParamsDTO, value),
        }),
      createSavedReport: (value: ReportParamsDTO) =>
        requestResource(
          'POST /internal/saved_reports',
          (data) => plainToClass(ReportDTO, data),
          { json: valuesToPlain(ReportParamsDTO, value) },
        ).then((response) => {
          cache.setSavedReports((reports) => reports.concat(response));
          return response;
        }),
      removeSavedReport: (id: number) =>
        request<{ id: number }>('DELETE /internal/saved_reports/{id}', {
          id,
        }).then((response) => {
          cache.setSavedReports((reports) =>
            reports.filter((item) => item.id !== id),
          );
          return response;
        }),
      updateSavedReport: (report: ReportDTO, params: ReportParamsDTO) =>
        requestResource<ReportDTO, { id: number }>(
          'PUT /internal/saved_reports/{id}',
          (data) => plainToClass(ReportDTO, data),
          {
            id: report.id,
            json: valuesToPlain(ReportParamsDTO, params),
          },
        ).then((response) => {
          cache.setSavedReports((reports = []) =>
            reports.map((item) => (item.id === response.id ? response : item)),
          );
          return response;
        }),
    };
  }, [request, requestResource, cache]);
}

export function useReport(id: string | undefined) {
  const { data: reports } = useSavedReports();

  return useMemo(
    () => reports?.objects.find((item) => item.id === Number(id)),
    [id, reports],
  );
}

export function useSavedReports() {
  const { requestPage } = useAPI();
  return useAPIPageQuery(['reports', 'saved-reports'], () =>
    requestPage('GET /internal/saved_reports/list', (data) =>
      plainToClass(ReportDTO, data),
    ),
  );
}

export function useReportFieldGroup() {
  const { requestPage } = useAPI();
  return useAPIPageQuery(
    ['reports', 'fields'],
    () =>
      requestPage('GET /internal/reports/fields', (data) =>
        plainToClass(ReportFieldGroupDTO, data),
      ),
    { staleTime: Infinity },
  );
}

export function useReportDeleteMutation(options?: APIMutationOptions<number>) {
  const { removeSavedReport } = useReportsAPI();
  return useAPIMutation<number>((id) => removeSavedReport(id), options);
}
