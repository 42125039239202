import { useMemo } from 'react';
import {
  useSessionStorageValue,
  writeSessionStorageItem,
} from 'shared/helpers/SessionStorage';

export function useReportedCarriers(): [string[], (guid: string) => void] {
  const reportedCarriersValue = useSessionStorageValue('reportedCarriers');
  return useMemo(() => {
    const reportedCarriersGUIDs = reportedCarriersValue
      ? (JSON.parse(reportedCarriersValue) as string[])
      : [];

    const addReportedCarrierGUID = (carrier: string) => {
      writeSessionStorageItem(
        'reportedCarriers',
        JSON.stringify([...reportedCarriersGUIDs, carrier]),
      );
    };

    return [reportedCarriersGUIDs, addReportedCarrierGUID];
  }, [reportedCarriersValue]);
}
