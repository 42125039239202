import { createSvgIcon } from 'shared/icons/IconUtils';

export const InfoIcon = createSvgIcon(
  'Info',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.99998 1.33301C4.31998 1.33301 1.33331 4.31967 1.33331 7.99967C1.33331 11.6797 4.31998 14.6663 7.99998 14.6663C11.68 14.6663 14.6666 11.6797 14.6666 7.99967C14.6666 4.31967 11.68 1.33301 7.99998 1.33301ZM8.66665 11.333H7.33331V7.33301H8.66665V11.333ZM8.66665 5.99967H7.33331V4.66634H8.66665V5.99967Z"
    fill="#979CA4"
  />,
  { viewBox: '0 0 16 16' },
);
