import { Link, Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import styled from 'styled-components';
import privateCarrierIllustration1 from '../assets/private_carrier_illustration_1.png';
import privateCarrierIllustration2 from '../assets/private_carrier_illustration_2.png';
import privateCarrierIllustration3 from '../assets/private_carrier_illustration_3.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: ${Color.Silver200};
  height: 100%;
  margin: -16px;
  padding: 48px;
`;

const Steps = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
`;

const Step = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: white;
`;

export function PrivateNetworkEmpty() {
  return (
    <Container>
      <Stack align="center" space="medium">
        <Typography variant="h3" align="center">
          Adding trusted carriers <br /> to your Private Network is easy
        </Typography>

        <Steps>
          <Step>
            <Stack>
              <img
                width="220"
                alt="go to approved"
                src={privateCarrierIllustration1}
              />

              <Typography color="textSecondary" align="center">
                Go to the{' '}
                <Link
                  variant="body1"
                  target="_blank"
                  color="textSecondary"
                  href="/manage-carriers?carrier_type=approved"
                >
                  Approved
                </Link>{' '}
                <br /> or{' '}
                <Link
                  variant="body1"
                  target="_blank"
                  color="textSecondary"
                  href="/manage-carriers?carrier_type=preferred"
                >
                  Can Instantly Book
                </Link>{' '}
                tabs.
              </Typography>
            </Stack>
          </Step>

          <Step>
            <Stack>
              <img
                width="220"
                alt="select carrier"
                src={privateCarrierIllustration2}
              />

              <Typography color="textSecondary" align="center">
                Select carriers and click <br /> <b>Add to Private Network</b>.
              </Typography>
            </Stack>
          </Step>

          <Step>
            <Stack>
              <img
                width="220"
                alt="check private network"
                src={privateCarrierIllustration3}
              />

              <Typography color="textSecondary" align="center">
                Check the <b>Private Network</b> <br />
                or create a group, then save.
              </Typography>
            </Stack>
          </Step>
        </Steps>
      </Stack>
    </Container>
  );
}
