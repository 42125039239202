import { Link, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { CheckCircleRounded, MonetizationOn, Remove } from '@material-ui/icons';
import { Color, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { PrivateNetworkGroupDTO } from 'manage-carriers/data/PrivateNetworkDTO';
import { ReactElement, useMemo } from 'react';
import { ApprovedIcon } from 'shared/icons/ApprovedIcon';
import { CertificateIcon } from 'shared/icons/CertificateIcon';
import { ErrorInfoIcon } from 'shared/icons/ErrorInfoIcon';
import { FlashIcon } from 'shared/icons/FlashIcon';
import { IconBox } from 'shared/icons/IconBox';
import { PrivateNetworkIcon } from 'shared/icons/PrivateNetworkIcon';
import { VerifiedIconNew } from 'shared/icons/VerifiedIconNew';
import { CarrierBrokerPreferences } from 'shared/types/carrier';
import { List as CustomList } from 'shared/ui/List';
import styled from 'styled-components';
import { isCarrierInsuranceExpired } from './insurance/ExpirationDates';

const ListItemPadded = styled.div`
  margin-right: 8px;
`;

const NumberList = styled.ol`
  margin: 0 0 0 4px;
  padding: 0 0 0 16px;
`;

const VerifiedIcon = styled(VerifiedIconNew)`
  font-size: 14px;
  color: ${Color.Green500};
`;

export function VerifiedTag() {
  return (
    <Tooltip
      placement="top"
      interactive={true}
      title={
        <Stack space="small">
          <Stack space="xsmall">
            <Typography>
              Verified Carrier has following <br />
              documents available in profile:
            </Typography>

            <List disablePadding={true} dense={true}>
              <ListItem disableGutters={true}>
                <ListItemPadded>
                  <CheckCircleRounded htmlColor={Color.White} />
                </ListItemPadded>
                <Typography>W9</Typography>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemPadded>
                  <CheckCircleRounded htmlColor={Color.White} />
                </ListItemPadded>
                <Typography>USDOT Cert</Typography>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemPadded>
                  <CheckCircleRounded htmlColor={Color.White} />
                </ListItemPadded>
                <Typography>Cargo Insurance</Typography>
              </ListItem>
            </List>
          </Stack>

          <Box>
            <Typography>
              Status is monitored daily <br />
              and Revoked when:
            </Typography>
            <NumberList>
              <li>Carrier Authority is NOT active</li>
              <li>
                When insurance expiration date <br />
                on file is reached
              </li>
            </NumberList>
          </Box>

          <Link
            href="https://support.superdispatch.com/en/articles/6866037-how-does-super-dispatch-monitor-carrier-compliance"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            Learn more
          </Link>
        </Stack>
      }
    >
      <Tag color="green" variant="subtle" align="center">
        <Inline space="none">
          <IconBox>
            <VerifiedIcon aria-label="Verified" />
          </IconBox>
          Verified
        </Inline>
      </Tag>
    </Tooltip>
  );
}

export function UnverifiedTag() {
  return (
    <Tooltip
      placement="top"
      interactive={true}
      title={
        <>
          <Typography style={{ maxWidth: 222 }}>
            Carrier still needs to provide the following documents to Super:
          </Typography>

          <List dense={true}>
            <ListItem disableGutters={true}>
              <ListItemPadded>
                <Remove fontSize="small" htmlColor={Color.White} />
              </ListItemPadded>
              <Typography>W9</Typography>
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemPadded>
                <Remove fontSize="small" htmlColor={Color.White} />
              </ListItemPadded>
              <Typography>USDOT Cert</Typography>
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemPadded>
                <Remove fontSize="small" htmlColor={Color.White} />
              </ListItemPadded>
              <Typography>Cargo Insurance</Typography>
            </ListItem>
          </List>
          <Link
            href="https://support.superdispatch.com/en/articles/2644754-what-is-a-super-carrier"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            Learn more
          </Link>
        </>
      }
    >
      <Tag color="red" variant="subtle">
        Unverified
      </Tag>
    </Tooltip>
  );
}

export function ApprovedTag() {
  return (
    <Tooltip
      interactive={true}
      title={
        <Stack>
          <Typography>
            Approved Carrier is just a label that could help the shipper to
            differentiate carriers who they have verified, onboarded, and/or
            have done business with.{' '}
          </Typography>
          <Link
            href="https://support.superdispatch.com/en/articles/4165914-approved-carrier-status-for-carriers"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            Learn more
          </Link>
        </Stack>
      }
    >
      <Tag color="grey" variant="subtle">
        <Inline space="none" verticalAlign="center">
          <IconBox>
            <ApprovedIcon aria-label="approved carrier" />
          </IconBox>
          Approved
        </Inline>
      </Tag>
    </Tooltip>
  );
}

export function PrivateNetworkTag({
  groups,
  variant = 'horizontal',
}: {
  variant?: 'vertical' | 'horizontal';
  groups: PrivateNetworkGroupDTO[];
}): ReactElement {
  const Wrapper = variant === 'horizontal' ? Inline : Stack;
  const sortedGroups = useMemo(
    () => groups.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [groups],
  );

  if (groups.length === 0) {
    return (
      <Tooltip title="Carrier has access to loads posted on your Private Loadboard.">
        <Tag color="grey" variant="subtle">
          <IconBox>
            <PrivateNetworkIcon fontSize="small" aria-label="private network" />
          </IconBox>
          Private Network
        </Tag>
      </Tooltip>
    );
  }

  if (groups.length <= 2) {
    return (
      <Wrapper>
        {sortedGroups.map((group) => (
          <Tooltip
            key={group.guid}
            title="Carrier has access to loads posted on your Private Loadboard."
          >
            <Tag color="grey" variant="subtle">
              <IconBox>
                <PrivateNetworkIcon
                  fontSize="small"
                  aria-label="private network"
                />
              </IconBox>
              {group.name}
            </Tag>
          </Tooltip>
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Tooltip title="Carrier has access to loads posted on your Private Loadboard.">
        <Tag color="grey" variant="subtle">
          <IconBox>
            <PrivateNetworkIcon fontSize="small" aria-label="private network" />
          </IconBox>
          {sortedGroups[0]?.name}
        </Tag>
      </Tooltip>

      <Tooltip
        title={
          <CustomList color={Color.White}>
            {sortedGroups.slice(1).map((group) => (
              <li key={group.guid}>{group.name}</li>
            ))}
          </CustomList>
        }
      >
        <Tag color="grey" variant="subtle">
          <IconBox>
            <PrivateNetworkIcon fontSize="small" aria-label="private network" />
          </IconBox>
          +{groups.length - 1} groups
        </Tag>
      </Tooltip>
    </Wrapper>
  );
}

export function CanInstantlyBookTag() {
  return (
    <Tooltip
      title={
        <>
          This carrier can book your loads <br />
          24/7 on Super Loadboard.
        </>
      }
    >
      <Tag color="grey" variant="subtle">
        <Inline space="none" verticalAlign="center">
          <IconBox>
            <FlashIcon aria-label="preferred carrier" />
          </IconBox>
          Can Instantly Book
        </Inline>
      </Tag>
    </Tooltip>
  );
}

export function CanExpeditePaymentTag() {
  return (
    <Tooltip
      title={
        <>
          This carrier can Expedite Payment on <br />
          SuperPay Delivered Loads
        </>
      }
    >
      <Tag variant="subtle" color="grey">
        <IconBox>
          <MonetizationOn fontSize="small" color="primary" />
        </IconBox>
        Can Expedite Payment
      </Tag>
    </Tooltip>
  );
}

export function InsuranceCertificateHolderTag() {
  return (
    <Tag color="grey" variant="subtle">
      <Inline space="none" verticalAlign="center">
        <IconBox>
          <CertificateIcon aria-label="certificate holder" />
        </IconBox>
        Certificate Holder
      </Inline>
    </Tag>
  );
}

export function InsuranceExpiredTag() {
  return (
    <Tag color="grey" variant="subtle">
      <Inline space="none" verticalAlign="center">
        <IconBox>
          <ErrorInfoIcon aria-label="expired insurance" />
        </IconBox>
        Expired Insurance
      </Inline>
    </Tag>
  );
}

export function BlacklistedTag() {
  return (
    <Tag color="grey" variant="subtle">
      <Inline space="none" verticalAlign="center">
        <IconBox>
          <ErrorInfoIcon aria-label="blacklisted" />
        </IconBox>
        Blocklisted
      </Inline>
    </Tag>
  );
}

export function CarrierTags({
  carrierBrokerPreferences,
}: {
  carrierBrokerPreferences: CarrierBrokerPreferences;
}) {
  return (
    <Inline space="xsmall">
      {carrierBrokerPreferences.approved && <ApprovedTag />}

      {carrierBrokerPreferences.preferred && <CanInstantlyBookTag />}

      {carrierBrokerPreferences.is_in_private_network &&
        carrierBrokerPreferences.private_network_groups && (
          <PrivateNetworkTag
            groups={carrierBrokerPreferences.private_network_groups}
          />
        )}

      {carrierBrokerPreferences.insurance_certificate_holder && (
        <InsuranceCertificateHolderTag />
      )}

      {isCarrierInsuranceExpired(
        carrierBrokerPreferences.insurance_expires_at,
      ) && <InsuranceExpiredTag />}

      {carrierBrokerPreferences.in_blacklist && <BlacklistedTag />}
    </Inline>
  );
}
