import { GeneralSettingsDTO } from 'general-settings/data/GeneralSettingsDTO';
import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { setAPIQueryData } from 'shared/api/APIQuery';
import { trim } from 'shared/utils/StringUtils';
import {
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
} from 'shared/utils/YupUtils';
import { InferType, string } from 'yup';

export type OrderTagDTO = InferType<typeof orderTagSchema>;
export const orderTagSchema = yupObject({
  id: yupNumber().nullable(false),
  is_active: yupBoolean(),
  name: string()
    .transform(trim)
    .default('')
    .required('Tag name is required')
    .test(
      'only-alpha-numeric',
      'Tag must contain only letters and numbers.',
      (value) => /^[a-zA-Z0-9\s]+$/i.test(String(value)),
    )
    .max(20, 'Tag name must not exceed 20 characters'),
  color: yupEnum(
    ['grey', 'green', 'yellow', 'red', 'blue', 'teal', 'purple'],
    'grey',
  ),
});

export function useOrderTags() {
  const { requestPage } = useAPI();
  return useAPIPageQuery<OrderTagDTO>(
    ['orders', 'tags'],
    () => requestPage('/internal/orders/tags', (data) => data as OrderTagDTO),
    { schema: orderTagSchema },
  );
}

export function useOrderTagMap() {
  const queryResult = useOrderTags();
  const map = useMemo(
    () =>
      !queryResult.data
        ? undefined
        : new Map<number, OrderTagDTO>(
            queryResult.data.objects.map((x) => [x.id, x]),
          ),
    [queryResult],
  );
  return { ...queryResult, data: map };
}

export function useOrderTag(id: number) {
  const { data, ...queryResult } = useOrderTagMap();
  return { ...queryResult, data: data?.get(id) };
}

export type OrderTagPayloadDTO = InferType<typeof orderTagPayloadSchema>;
export const orderTagPayloadSchema = orderTagSchema.clone().shape({
  id: yupNumber(),
});

export function useOrderTagsMutation() {
  const { requestPage } = useAPI();
  const client = useQueryClient();
  return useMutation('update-orders-tags', (orderTags: OrderTagPayloadDTO[]) =>
    requestPage(
      'PUT /internal/shippers/profile_settings/order_tags',
      (data) => orderTagSchema.cast(data),
      { json: orderTags },
    ).then((response) => {
      setAPIQueryData(client, ['orders', 'tags'], () => response);
      setAPIQueryData(client, 'settings', (prev) => ({
        ...(prev as GeneralSettingsDTO),
        order_tag_settings: response.objects,
      }));
    }),
  );
}

export function useMaxNumberOfTagsMutation() {
  const { request } = useAPI();

  const client = useQueryClient();
  return useMutation('update-max-number-of-tags', (maxNumberOfTags: number) => {
    return request('PUT /internal/shippers/profile_settings/max_tags_number', {
      json: {
        value: maxNumberOfTags,
      },
    }).then((_) => {
      setAPIQueryData(client, 'settings', (prev: GeneralSettingsDTO) => ({
        ...prev,
        max_number_of_tags: maxNumberOfTags,
      }));
    });
  });
}
