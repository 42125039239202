import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { ReportParamsDTO } from './ReportParamsDTO';

interface TrackReportEvent {
  name: 'Saved Report' | 'Sent Report';
  params: ReportParamsDTO;
}

function formatParams({
  order_by,
  scheduled_delivery_begin,

  created_end,
  created_begin,

  delivery_end,
  delivery_begin,

  fields: {
    pickup_scheduled_at,
    pickup_schedule_at_by_carrier,
    pickup_scheduled_at_by_customer,
    pickup_schedule_ends_at_by_customer,
    pickup_schedule_ends_at,
    pickup_adjusted_date,
    pickup_completed_at,
    delivery_scheduled_at_by_customer,
    delivery_schedule_ends_at_by_customer,
    delivery_schedule_ends_at,
    delivery_adjusted_date,
    delivery_completed_at,
    delivery_scheduled_at_by_carrier,
    delivery_scheduled_at,
    ...otherFields
  },
  ...otherValues
}: ReportParamsDTO) {
  return {
    ...otherValues,
    creation_date_start: created_begin,
    creation_date_end: created_end,
    scheduled_delivery_start: scheduled_delivery_begin,
    delivered_on_start: delivery_begin,
    delivered_on_end: delivery_end,
    fields: {
      carrier_pickup_date_carrier_updated: pickup_schedule_at_by_carrier,
      carrier_pickup_scheduled_at: pickup_scheduled_at,
      customer_pickup_date: pickup_scheduled_at_by_customer,
      customer_pickup_ends_date: pickup_schedule_ends_at_by_customer,
      carrier_pickup_scheduled_ends_date: pickup_schedule_ends_at,
      adjusted_picked_up_date: pickup_adjusted_date,
      picked_up_on: pickup_completed_at,
      customer_delivery_date: delivery_scheduled_at_by_customer,
      customer_delivery_ends_date: delivery_schedule_ends_at_by_customer,
      carrier_delivery_scheduled_ends_date: delivery_schedule_ends_at,
      adjusted_delivery_date: delivery_adjusted_date,
      delivered_on: delivery_completed_at,
      carrier_delivery_date_carrier_updated: delivery_scheduled_at_by_carrier,
      carrier_delivery_scheduled_date: delivery_scheduled_at,
      ...otherFields,
    },
    order_by:
      order_by === 'delivery_date'
        ? 'carrier_delivery_date'
        : 'carrier_pickup_date',
  };
}

export function trackReportEvent({ name, params }: TrackReportEvent) {
  switch (name) {
    case 'Sent Report':
      trackEventLegacy(name, {
        ...formatParams(params),
        number_of_emails: params.send_report_to.split(',').length,
      });
      break;

    case 'Saved Report':
      trackEventLegacy(name, formatParams(params));
      break;
  }
}
