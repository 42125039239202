import { countryFieldOptions } from 'shared/form/CountryFieldOptions';
import { yupEnum, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type PaymentPlanType = typeof PAYMENT_PLAN_TYPES[number];
export const PAYMENT_PLAN_TYPES = ['TRIAL', 'BASIC', 'ADVANCED'] as const;

export const subscriptionStatusSchema = yupObject({
  plan: yupEnum(PAYMENT_PLAN_TYPES, null),
  expires_at: yupString().nullable(),
  chargebee: yupObject({
    status: yupEnum(
      [
        'ACTIVE',
        'IN_TRIAL',
        'FUTURE',
        'EXPIRED',
        'PAUSED',
        'CANCELED',
        'NON_RENEWING',
        'UNKNOWN',
      ] as const,
      null,
    ),
    client_token: yupString(),
    subscription_id: yupString(),
    customer_id: yupString(),
    trial_start: yupString().nullable(),
    trial_end: yupString().nullable(),
    trial_end_action: yupEnum(
      ['CANCEL_SUBSCRIPTION', 'ACTIVATE_SUBSCRIPTION'],
      null,
    ).nullable(),
    activated_at: yupString().nullable(),
    active_till_date: yupString().nullable(),
    cancelled_at: yupString().nullable(),
  }),
});

export type SubscriptionStatusDTO = InferType<typeof subscriptionStatusSchema>;

export type PaywallBillingAddressDTO = InferType<
  typeof paywallBillingAddressSchema
>;
export const paywallBillingAddressSchema = yupObject({
  address: yupString().required(),
  city: yupString().required(),
  country: yupEnum([null, ...countryFieldOptions.keys()], null).required(),
  state: yupString().required(),
  zip: yupString().required(),
});

const BILLING_STATUSES = ['PAID', 'PAYMENT_DUE', 'NOT_PAID'] as const;

export const billingStatusSchema = yupObject({
  status: yupEnum(BILLING_STATUSES, null).nullable(),
  due_date: yupString().nullable(),
  failure_reason: yupString().nullable(),
});

export type BillingStatusDTO = InferType<typeof billingStatusSchema>;
