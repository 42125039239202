import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useTerminalsNavigation } from 'terminals/TerminalsRouteHelpers';
import { useTerminalsListQuery } from '../data/TerminalsAPI';

export function useTerminals() {
  const {
    terminalsSearchParams: { query },
  } = useTerminalsNavigation();
  const debouncedQuery = useDebouncedValue(query, 500);
  return useTerminalsListQuery(debouncedQuery);
}
