import {
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { Description, Edit, PictureAsPdf } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import {
  Column,
  Columns,
  InfoCard,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { MouseEvent, useEffect } from 'react';
import { useCanExecute } from 'shared/data/UserPermissions';
import { trackAnchorClickLegacy } from 'shared/helpers/AnalyticsHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { CarrierFullInfo } from 'shared/types/carrier';
import { useCarrierAttachments } from '../data/ManageCarriersAPI';

interface CarrierInternalRecordsProps {
  carrier: CarrierFullInfo;
  onEdit: () => void;
}

export function CarrierInternalRecords({
  onEdit,
  carrier: { broker_records, guid },
}: CarrierInternalRecordsProps) {
  const { addSnackbar } = useSnackbarStack();

  const { data: attachments, error } = useCarrierAttachments(guid);

  const hasInternalRecords =
    broker_records.insurance_cert_holder_file_url ||
    broker_records.custom_external_id ||
    broker_records.insurance_expires_at ||
    attachments?.objects.length;

  const canUserUpdateRecords = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to load internal attachments', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  return (
    <InfoCard size="large">
      <Stack space="small">
        <Columns>
          <Column>
            <Typography variant="h3">Internal Records</Typography>
          </Column>

          {canUserUpdateRecords && (
            <Column width="content">
              <IconButton
                aria-label="edit internal records"
                size="small"
                onClick={onEdit}
              >
                <Edit />
              </IconButton>
            </Column>
          )}
        </Columns>

        {hasInternalRecords ? (
          <>
            {broker_records.insurance_cert_holder_file_url && (
              <DescriptionItem icon={<PictureAsPdf />}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="insurance certificate in internal records"
                  href={broker_records.insurance_cert_holder_file_url}
                  onClick={(event: MouseEvent<HTMLAnchorElement>) =>
                    trackAnchorClickLegacy(
                      'Clicked Carrier Insurance Certificate In Internal Records',
                      undefined,
                      event,
                    )
                  }
                >
                  Insurance Certificate
                </Link>
              </DescriptionItem>
            )}

            {(broker_records.custom_external_id ||
              broker_records.insurance_expires_at) && (
              <Stack space="xxsmall">
                {broker_records.custom_external_id && (
                  <DescriptionItem
                    icon={<HashtagIcon />}
                    label="Custom External ID"
                  >
                    {broker_records.custom_external_id}
                  </DescriptionItem>
                )}
                {broker_records.insurance_expires_at && (
                  <DescriptionItem
                    icon={<CalendarIcon />}
                    label="Insurance Expiration Date"
                  >
                    <FormattedDate
                      date={broker_records.insurance_expires_at}
                      variant="Date"
                      format="DateISO"
                    />
                  </DescriptionItem>
                )}
              </Stack>
            )}

            <Stack space="xsmall">
              <Typography variant="h6" color="textSecondary">
                Internal Attachments
              </Typography>

              {!attachments ? (
                <CircularProgress size={24} />
              ) : attachments.objects.length ? (
                <Stack space="xxsmall">
                  {attachments.objects.map(
                    ({ guid: attachmentGuid, name, url }, idx) => (
                      <DescriptionItem
                        key={attachmentGuid}
                        icon={<Description />}
                      >
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={url}
                          aria-label={`attachment-${idx + 1}`}
                          color="inherit"
                          onClick={(event: MouseEvent<HTMLAnchorElement>) =>
                            trackAnchorClickLegacy(
                              'Clicked Carrier Internal Records Attachment',
                              undefined,
                              event,
                            )
                          }
                        >
                          {name}
                        </Link>
                      </DescriptionItem>
                    ),
                  )}
                </Stack>
              ) : (
                <Typography color="textSecondary">
                  No Internal attachments
                </Typography>
              )}
            </Stack>
          </>
        ) : (
          <Typography color="textSecondary">No Information Provided</Typography>
        )}
      </Stack>
    </InfoCard>
  );
}
