import { DateType } from '@superdispatch/sdk';
import { Type } from 'class-transformer';
import { Step } from 'shared/types/step';

export class OrderStepVenueDTO {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
}

export class OrderStepDTO implements Step {
  scheduled_at: string | null;
  scheduled_ends_at: string | null;
  scheduled_at_by_customer: string | null;
  scheduled_ends_at_by_customer: string | null;
  scheduled_at_by_carrier: string | null;
  adjusted_date: string | null;
  notes: string;
  date_type: DateType;
  completed_at: string;
  latitude: number;
  longitude: number;
  estimated_date: boolean;
  not_earlier_than_date: boolean;
  not_later_than_date: boolean;
  exact_date: boolean;

  @Type(() => OrderStepVenueDTO)
  venue: OrderStepVenueDTO;
}
