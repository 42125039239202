import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const LocationIcon = createSvgIcon(
  'Location',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.07556 11.8964C6.56786 12.5978 7.14468 13.4195 7.81952 14.3978C8.0678 14.7565 8.59892 14.7565 8.84723 14.3978C9.52207 13.4195 10.0989 12.5978 10.5912 11.8964C12.8592 8.6654 13.3334 7.98991 13.3334 6.3335C13.3334 3.57206 11.0948 1.3335 8.33337 1.3335C5.57194 1.3335 3.33337 3.57206 3.33337 6.3335C3.33337 7.98991 3.80753 8.6654 6.07556 11.8964ZM10.4167 6.33349C10.4167 7.48409 9.48397 8.41683 8.33337 8.41683C7.18278 8.41683 6.25004 7.48409 6.25004 6.33349C6.25004 5.1829 7.18278 4.25016 8.33337 4.25016C9.48397 4.25016 10.4167 5.1829 10.4167 6.33349Z"
    fill={Color.Dark100}
  />,
  { viewBox: '0 0 16 16' },
);
