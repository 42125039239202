import { useEffect } from 'react';
import { getAppType } from 'shared/data/AppUtils';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { parseRedirectUrlQuery, useQuery } from 'shared/helpers/RouteHelpers';
import { BrokerLoginPage } from './BrokerLoginPage';
import { CustomerLoginPage } from './CustomerLoginPage';

export function LoginPage() {
  const [{ redirect_url = '' }] = useQuery();

  useEffect(() => {
    trackEvent(
      'Shipper Opened Sign In Page',
      parseRedirectUrlQuery(redirect_url),
    );
  }, [redirect_url]);

  if (getAppType() === 'customer-portal') {
    return <CustomerLoginPage />;
  }

  return <BrokerLoginPage />;
}
