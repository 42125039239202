import { MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { CUSTOMER_TYPES, formatCustomerType } from '@superdispatch/sdk';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { BrokerUser } from '../../shared/data/UserPermissions';
import { TerminalDTO } from '../../shared/dto/TerminalDTO';
import { required } from '../../shared/utils/ValidatorUtils';
import { TerminalEditDrawer } from './TerminalEditDrawer';

interface Props {
  open: boolean;
  terminal: TerminalDTO;
  onClose: () => void;
}

export function TerminalDetailsInfoDrawer({ open, terminal, onClose }: Props) {
  return (
    <TerminalEditDrawer
      open={open}
      terminal={terminal}
      title="Edit Terminal Info"
      onClose={onClose}
      onSubmitSuccess={onClose}
    >
      <Stack space="small">
        <FormikTextField
          name="name"
          validate={required}
          autoFocus={true}
          fullWidth={true}
          label={
            <Inline space="xxsmall">
              <TextBox>Business Name</TextBox>
              <TextBox color="secondary">(Required)</TextBox>
            </Inline>
          }
        />

        <FormikTextField
          select={true}
          label="Type"
          fullWidth={true}
          name="business_type"
        >
          {CUSTOMER_TYPES.map((value) => (
            <MenuItem key={value} value={value}>
              {formatCustomerType(value)}
            </MenuItem>
          ))}
        </FormikTextField>

        <FormikTextField label="Phone" name="phone" fullWidth={true} />

        <Box marginTop="small">
          <Typography variant="h4">Address</Typography>
        </Box>

        <FormikTextField
          label="Street Address"
          name="address"
          fullWidth={true}
        />

        <FormikTextField label="City" name="city" fullWidth={true} />

        <Columns space="xsmall">
          <Column width="1/2">
            <FormikTextField label="State" name="state" fullWidth={true} />
          </Column>

          <Column width="1/2">
            <FormikTextField label="ZIP Code" name="zip" fullWidth={true} />
          </Column>
        </Columns>

        <BrokerUser>
          <Box marginTop="small">
            <FormikTextField
              label="Custom External ID"
              name="custom_external_id"
              fullWidth={true}
            />
          </Box>
        </BrokerUser>
      </Stack>
    </TerminalEditDrawer>
  );
}
