import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';

interface Props {
  guid: string;
  open: boolean;
  onClose: () => void;
}

export function InvalidUsernameDialog({ guid, open, onClose }: Props) {
  const navigate = useNavigate();

  return (
    <ConfirmDialog
      open={open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => onClose()}
      title="Invalid Username Format"
      cancelButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose();
        },
      }}
      confirmButtonProps={{
        children: 'Change Username',
        onClick: () => {
          navigate(`/users/edit/${guid}`);
          onClose();
        },
        autoFocus: false,
      }}
    >
      Valid username must be created as email.
    </ConfirmDialog>
  );
}
