import { Link } from '@material-ui/core';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { CancelSubscriptionDrawer } from './CancelSubscriptionDrawer';

export function CancelSubscriptionButton({
  onCancelSuccess,
}: {
  onCancelSuccess?: () => void;
}) {
  const { data } = useSubscriptionDetails();
  const [
    isCancellationDrawerOpen,
    openCancellationDrawer,
    closeCancellationDrawer,
  ] = useBoolean(false);

  function handleCancelSuccess() {
    closeCancellationDrawer();
    onCancelSuccess?.();
  }

  return (
    <>
      <Link component="button" onClick={openCancellationDrawer}>
        {data?.chargebee.status === 'IN_TRIAL'
          ? 'Cancel Free Trial'
          : 'Cancel Subscription'}
      </Link>

      <CancelSubscriptionDrawer
        open={isCancellationDrawerOpen}
        onClose={closeCancellationDrawer}
        onCancelSuccess={handleCancelSuccess}
      />
    </>
  );
}
