import { createSvgIcon } from 'shared/icons/IconUtils';

export const ManageCarriersIcon = createSvgIcon(
  'ManageCarriersIcon',
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M20 7H16V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C8.89 3 8 3.89 8 5V7H4C2.89 7 2 7.89 2 9V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7ZM10 5H14V7H10V5ZM12 10C12.663 10 13.2989 10.2634 13.7678 10.7322C14.2366 11.2011 14.5 11.837 14.5 12.5C14.5 13.163 14.2366 13.7989 13.7678 14.2678C13.2989 14.7366 12.663 15 12 15C11.337 15 10.7011 14.7366 10.2322 14.2678C9.76339 13.7989 9.5 13.163 9.5 12.5C9.5 11.837 9.76339 11.2011 10.2322 10.7322C10.7011 10.2634 11.337 10 12 10ZM17 20H7V18.75C7 17.37 9.24 16.25 12 16.25C14.76 16.25 17 17.37 17 18.75V20Z"
    fill="currentColor"
  />,
);
