import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const WebIcon = createSvgIcon(
  'Web',
  <path
    d="M10.9067 9.3335C10.96 8.8935 11 8.4535 11 8.00016C11 7.54683 10.96 7.10683 10.9067 6.66683H13.16C13.2667 7.0935 13.3334 7.54016 13.3334 8.00016C13.3334 8.46016 13.2667 8.90683 13.16 9.3335H10.9067ZM9.72671 13.0402C10.1267 12.3002 10.4334 11.5002 10.6467 10.6668H12.6134C11.9734 11.7668 10.9534 12.6202 9.72671 13.0402ZM9.56004 9.3335H6.44004C6.37337 8.8935 6.33337 8.4535 6.33337 8.00016C6.33337 7.54683 6.37337 7.10016 6.44004 6.66683H9.56004C9.62004 7.10016 9.66671 7.54683 9.66671 8.00016C9.66671 8.4535 9.62004 8.8935 9.56004 9.3335ZM8.00004 13.3068C7.44671 12.5068 7.00004 11.6202 6.72671 10.6668H9.27337C9.00004 11.6202 8.55337 12.5068 8.00004 13.3068ZM5.33337 5.3335H3.38671C4.02004 4.22683 5.04671 3.3735 6.26671 2.96016C5.86671 3.70016 5.56671 4.50016 5.33337 5.3335ZM3.38671 10.6668H5.33337C5.56671 11.5002 5.86671 12.3002 6.26671 13.0402C5.04671 12.6202 4.02004 11.7668 3.38671 10.6668ZM2.84004 9.3335C2.73337 8.90683 2.66671 8.46016 2.66671 8.00016C2.66671 7.54016 2.73337 7.0935 2.84004 6.66683H5.09337C5.04004 7.10683 5.00004 7.54683 5.00004 8.00016C5.00004 8.4535 5.04004 8.8935 5.09337 9.3335H2.84004ZM8.00004 2.68683C8.55337 3.48683 9.00004 4.38016 9.27337 5.3335H6.72671C7.00004 4.38016 7.44671 3.48683 8.00004 2.68683ZM12.6134 5.3335H10.6467C10.4334 4.50016 10.1267 3.70016 9.72671 2.96016C10.9534 3.38016 11.9734 4.22683 12.6134 5.3335ZM8.00004 1.3335C4.31337 1.3335 1.33337 4.3335 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335Z"
    fill={Color.Dark100}
  />,
  { viewBox: '0 0 16 16' },
);
