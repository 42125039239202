import { Color, Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import { Order } from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../form/OrderForm';
import { validateOrder } from './PricingRecommendationHelpers';

const Container = styled.div`
  background-color: ${Color.Silver200};
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
`;

interface PricingRecommendationViewOnlyProps {
  order: Partial<Order> | OrderFormValues;
}

export function PricingRecommendationCarrierRequest({
  order,
}: PricingRecommendationViewOnlyProps) {
  const recommendedPrice = order.recommended_price;

  if (validateOrder(order)) {
    return null;
  }

  if (!recommendedPrice) {
    return null;
  }

  return (
    <Container style={{ width: '100%' }}>
      <Inline verticalAlign="center" noWrap={true}>
        <RecommendedPriceIcon />

        <TextBox variant="heading-5">
          {formatCurrency(recommendedPrice, { maximumFractionDigits: 0 })}{' '}
          Recommended Price
        </TextBox>
      </Inline>
    </Container>
  );
}
