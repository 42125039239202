import { IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useRef, useState } from 'react';
import { NumberFormatBase, usePatternFormat } from 'react-number-format';

interface SSNFieldProps {
  label: string;
  onChange: (ssnValue: string) => void;
  errorMessage?: string;
}

export function SSNField({ label, onChange, errorMessage }: SSNFieldProps) {
  const inputRef = useRef('');
  const [isTouched, setTouched] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const { format, removeFormatting } = usePatternFormat({
    format: '### ## ####',
    mask: 'X',
  });

  const setSSNValue = (formattedStr: string) => {
    const rawSSN = inputRef.current;
    const formattedValue = formattedStr.replaceAll(/\s|X/g, '');
    //Deleted the last character
    if (formattedValue.length < rawSSN.length) {
      inputRef.current = rawSSN.slice(0, -1);
      return;
    }
    //Added a character
    const lastChar = formattedValue[formattedValue.length - 1] || '';
    if (
      formattedValue.length > rawSSN.length &&
      Number.isInteger(Number(lastChar))
    ) {
      inputRef.current += lastChar;
    }
  };

  const formatSSN = (numericStr: string) => {
    if (isVisible) {
      return format?.(numericStr) || numericStr;
    }
    const formattedStr = '•'.repeat(numericStr.length);
    return format?.(formattedStr) || formattedStr;
  };

  const removeFormattingSSN = (formattedStr: string) => {
    setSSNValue(formattedStr);
    return removeFormatting?.(inputRef.current) || inputRef.current;
  };

  const setCaratBoundary = (formattedValue: string) => {
    const boundary = Array.from(
      { length: formattedValue.length + 1 },
      () => false,
    );
    let lastValueIndex = 0;
    for (let i = 0; i < formattedValue.length; i++) {
      if (Boolean(formattedValue[i]?.match(/\d|•/)) && i > lastValueIndex) {
        lastValueIndex = i;
      }
    }
    boundary[lastValueIndex + 1] = true;
    return boundary;
  };

  return (
    <NumberFormatBase
      label={label}
      fullWidth={true}
      customInput={TextField}
      placeholder="XXX XX XXXX"
      format={formatSSN}
      removeFormatting={removeFormattingSSN}
      getCaretBoundary={setCaratBoundary}
      error={isTouched && !!errorMessage}
      helperText={isTouched ? errorMessage : ''}
      onBlur={() => {
        setTouched(true);
      }}
      onValueChange={({ value }) => onChange(value)}
      InputProps={{
        endAdornment: (
          <IconButton size="small" onClick={() => setVisible(!isVisible)}>
            {isVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
      InputLabelProps={{
        htmlFor: 'ssn-input',
      }}
      inputProps={{
        id: 'ssn-input',
      }}
    />
  );
}
