import { Box } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import styled from 'styled-components';
import { PaywallHeader } from './core/PaywallHeader';
import { PaywallContent } from './PaywallContent';
import { PaywallTrialExpired } from './PaywallTrialExpired';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export function Paywall() {
  const isPaywallV2Enabled = useFeatureToggle('paywall.v2.ui');

  if (isPaywallV2Enabled) {
    return (
      <StyledRoot>
        <PaywallHeader />
        <PaywallContent />
      </StyledRoot>
    );
  }

  return (
    <>
      <PaywallHeader />
      <Box padding="large">
        <PaywallTrialExpired />
      </Box>
    </>
  );
}
