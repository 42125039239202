import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

export interface TextOverflowProps
  extends React.HTMLAttributes<HTMLDivElement> {
  size?: number | string;
  className?: string;
  children?: React.ReactNode;
  text?: string;
  title?: string;
}

const Text = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inherit;
`;

export const TextOverflow: React.ComponentType<TextOverflowProps> = ({
  children,
  text,
  title,
  size = 100,
  ...props
}) => (
  <Tooltip
    placement="top"
    title={title || text || (typeof children === 'string' ? children : '')}
  >
    <Text {...props} style={{ maxWidth: size }}>
      {text || children}
    </Text>
  </Tooltip>
);
