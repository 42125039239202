import { MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  DrawerContent,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { FormikCountryField } from 'shared/form/FormikCountryField';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import { USAStateOptions } from 'shared/types/USAState';
import { EditDrawerActions } from './EditDrawerActions';
import {
  BillingAddress,
  billingAddressSchema,
  usePaymentDetails,
} from './usePaymentDetails';

export function EditBillingAddressDrawerContent({
  onSubmitSuccess,
}: {
  onSubmitSuccess: () => void;
}) {
  const { billingAddress, updateBilingAddress } = usePaymentDetails();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced<BillingAddress, unknown>({
    initialValues: {
      country: billingAddress?.country ?? 'US',
      state: billingAddress?.state ?? '',
      zip: billingAddress?.zip ?? '',
      address: billingAddress?.address ?? '',
      address_second_line: billingAddress?.address_second_line ?? '',
      city: billingAddress?.city ?? '',
    },
    validationSchema: billingAddressSchema,
    onSubmit: (values) => updateBilingAddress(values),
    onSubmitSuccess: () => {
      addSnackbar('Billing Address updated', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const {
    values: { country, zip = '' },
    setFieldValue,
  } = formik;

  const isUSASelected = country === 'US';
  const query = useSearchInput(zip);
  const { data: places = [] } = usePredictions(query);

  function autoSetCityAndState() {
    if (!places.length || !isUSASelected) {
      return;
    }

    setFieldValue('city', places[0]?.place);
    setFieldValue('state', places[0]?.region);
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <DrawerContent>
          <Stack space="small">
            <Typography variant="h4">Billing Address</Typography>

            <FormikTextField
              label="Address Line 1"
              name="address"
              fullWidth={true}
            />

            <FormikTextField
              label={
                <Inline space="xxsmall">
                  Address Line 2
                  <Typography color="textSecondary">(Optional)</Typography>
                </Inline>
              }
              name="address_second_line"
              fullWidth={true}
            />

            <FormikTextField label="City" name="city" fullWidth={true} />

            <Columns space="small">
              <Column width="2/3">
                <FormikTextField
                  label="State"
                  name="state"
                  fullWidth={true}
                  select={isUSASelected}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">
                    <Typography color="textSecondary">
                      Select your billing state
                    </Typography>
                  </MenuItem>
                  {Array.from(USAStateOptions, (option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Column>

              <Column width="1/3">
                <FormikTextField
                  name="zip"
                  onChange={(event) => {
                    event.target.value = event.target.value.toUpperCase();
                  }}
                  onBlur={autoSetCityAndState}
                  label={
                    <Inline verticalAlign="center">
                      ZIP Code
                      <Tooltip
                        interactive={true}
                        placement="right"
                        title="Zip code of your card's billing address."
                      >
                        <Info fontSize="small" htmlColor={Color.Dark100} />
                      </Tooltip>
                    </Inline>
                  }
                  fullWidth={true}
                />
              </Column>
            </Columns>

            <FormikCountryField
              name="country"
              fullWidth={true}
              TextFieldProps={{
                fullWidth: true,
                label: 'Country',
              }}
            />
          </Stack>
        </DrawerContent>

        <EditDrawerActions
          primaryActionDisabled={!formik.dirty || !formik.isValid}
          primaryActionText="Save"
        />
      </Form>
    </FormikProvider>
  );
}
