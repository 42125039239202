const LINK_RELATIONSHIPS = ['prefetch', 'preload'] as const;
type Rel = typeof LINK_RELATIONSHIPS[number];

function isLinkRelSupported(rel: Rel): boolean {
  try {
    return document.createElement('link').relList.supports(rel);
  } catch {
    return false;
  }
}

function polyfill(rel: Rel) {
  const links = document.querySelectorAll(`link[rel="${rel}"][as="script"]`);

  for (const link of Array.from(links)) {
    const href = link.getAttribute('href');

    if (href) {
      const script = document.createElement('script');
      script.src = href;
      script.async = true;

      document.body.appendChild(script);
    }
  }
}

const TEN_SECONDS = 10 * 1000;

export function polyfillLinkRel(): void {
  for (const rel of LINK_RELATIONSHIPS) {
    if (!isLinkRelSupported(rel)) {
      setTimeout(() => {
        polyfill(rel);
      }, TEN_SECONDS);
    }
  }
}
