import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Color, Column, Columns } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';

export interface PriceNegotiationWarningProps {
  message: string;
}

export function PriceNegotiationWarning({
  message,
}: PriceNegotiationWarningProps) {
  return (
    <Box padding="xsmall" backgroundColor="Yellow50" borderRadius="medium">
      <Columns space="xsmall">
        <Column width="content">
          <Warning fontSize="small" htmlColor={Color.Yellow300} />
        </Column>

        <Column>
          <Typography>{message}</Typography>
        </Column>
      </Columns>
    </Box>
  );
}
