import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useCarrierRatingAPI } from './data/CarrierRatingAPI';

interface Props {
  carrierGuid: string;
  open: boolean;
  onClose: () => void;
}

export function CarrierRatingDeleteDialog({
  carrierGuid,
  open,
  onClose,
}: Props) {
  const { deleteRating } = useCarrierRatingAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: (_) => deleteRating(carrierGuid),
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: () => {
      onClose();
      addSnackbar('Rating deleted', { variant: 'success' });
    },
  });
  return (
    <ConfirmDialog
      open={open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => (formik.isSubmitting ? undefined : onClose())}
      title="Delete rating?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: formik.isSubmitting,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: 'Delete',
        isLoading: formik.isSubmitting,
        onClick: () => formik.handleSubmit(),
        autoFocus: false,
      }}
    />
  );
}
