import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useState } from 'react';
import { EditBillingAddressDrawer } from './EditBillingAddressDrawer';

export function EditBillingAddressButton() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <IconButton size="small" onClick={() => setDrawerOpen(true)}>
        <Edit />
      </IconButton>
      <EditBillingAddressDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}
