import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MoovAccountInfo } from 'shared/helpers/superpay/MoovAccountInfo';
import { MoovScope } from 'shared/helpers/superpay/MoovDTO';
import { MoovService } from 'shared/helpers/superpay/MoovHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { useGenerateMoovTokenOnce, useSuperPayAPI } from './data/SuperPayAPI';

interface MoovServiceContext {
  moovService: MoovService | null;
  moovAccountInfo: MoovAccountInfo | null;
  isContextLoading: boolean;
}

const Context = createContext<null | MoovServiceContext>(null);

export function useMoovServiceContext() {
  return useNullableContext('MoovService', Context);
}

interface Props {
  children?: ReactNode;
}

export function MoovServiceContextProvider({ children }: Props) {
  const { data, isLoading, error } = useGenerateMoovTokenOnce('account');
  const { getMoovToken } = useSuperPayAPI();

  const tokenFetcher = useCallback(
    async (scope: MoovScope) => {
      const tokenData = await getMoovToken(scope);

      return tokenData;
    },
    [getMoovToken],
  );

  const [{ moovAccountInfo, isAccountInfoLoading }, setMoovAccountInfo] =
    useState<{
      moovAccountInfo: MoovAccountInfo | null;
      isAccountInfoLoading: boolean;
    }>(() => ({
      moovAccountInfo: data
        ? new MoovAccountInfo('account', data, tokenFetcher)
        : null,
      isAccountInfoLoading: isLoading,
    }));

  const moovService = useMemo<MoovService | null>(
    () => (moovAccountInfo ? new MoovService(moovAccountInfo) : null),
    [moovAccountInfo],
  );

  const ctx = useMemo<MoovServiceContext>(
    () => ({
      moovService,
      moovAccountInfo,
      isContextLoading: isAccountInfoLoading,
    }),
    [isAccountInfoLoading, moovAccountInfo, moovService],
  );

  useErrorSnackbar(error);

  useEffect(() => {
    if (data && !moovAccountInfo) {
      setMoovAccountInfo({
        moovAccountInfo: new MoovAccountInfo('account', data, tokenFetcher),
        isAccountInfoLoading: false,
      });
    }
  }, [data, moovAccountInfo, tokenFetcher]);

  useEffect(() => {
    if (moovAccountInfo) {
      const unsubscribe = moovAccountInfo.subscribeToAccountDataChange(() => {
        // context should be re-rendered when there is an update
        setMoovAccountInfo({
          moovAccountInfo,
          isAccountInfoLoading: false,
        });
      });

      return unsubscribe;
    }

    return () => void 0;
  }, [moovAccountInfo]);

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
