import { IconButton, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  CardButton,
  CardButtonProps,
  Color,
  Column,
  Columns,
  InfoCard,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { ResponsiveTooltip } from '../shared/ui/ResponsiveTooltip';

const CardBackground = styled.div`
  background-color: ${Color.White};
`;

interface SectionLayoutProps {
  title: string;
  subtitle?: ReactElement;
  tooltip?: string;
  editComponent?: ReactElement;
}

export function SectionLayout({
  title,
  subtitle,
  tooltip,
  editComponent,
  children,
}: PropsWithChildren<SectionLayoutProps>) {
  const uid = useUID();
  return (
    <InfoCard aria-labelledby={uid} size="large">
      <Stack space="small">
        <Stack space="xxsmall">
          <Columns>
            <Column>
              <Inline space="xsmall" verticalAlign="center">
                <Typography id={uid} variant="h3">
                  {title}
                </Typography>

                {tooltip && (
                  <ResponsiveTooltip title={tooltip}>
                    <IconButton size="small">
                      <Info color="action" fontSize="small" />
                    </IconButton>
                  </ResponsiveTooltip>
                )}
              </Inline>
            </Column>

            <Column width="content">{editComponent}</Column>
          </Columns>

          {subtitle && <Typography>{subtitle}</Typography>}
        </Stack>

        {children}
      </Stack>
    </InfoCard>
  );
}

export function EmptySection(props: CardButtonProps) {
  return (
    <CardBackground>
      <CardButton {...props} />
    </CardBackground>
  );
}
