import { useFormikContext } from 'formik';
import { useEffect } from 'react';

/**
 * ScrollToError component is responsible for scrolling to the last error in the form.
 * It uses the `useFormikContext` hook to get the formik context.
 * It uses the `useEffect` hook to scroll to the first error when the form is submitting.
 * It works only with Material UI fields.
 */
export function ScrollToError() {
  const { isSubmitting } = useFormikContext();

  useEffect(() => {
    const elements = document.querySelectorAll('.Mui-error');
    const lastElement = elements[elements.length - 1];
    (lastElement?.parentElement ?? lastElement)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [isSubmitting]);

  return null;
}
