import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ShieldIcon = createSvgIcon(
  'Shield',
  <path
    d="M7.00033 9.99998H9.00033L8.61699 7.84998C8.83921 7.73887 9.01421 7.57776 9.14199 7.36665C9.26977 7.15554 9.33366 6.9222 9.33366 6.66665C9.33366 6.29998 9.2031 5.98609 8.94199 5.72498C8.68088 5.46387 8.36699 5.33331 8.00033 5.33331C7.63366 5.33331 7.31977 5.46387 7.05866 5.72498C6.79755 5.98609 6.66699 6.29998 6.66699 6.66665C6.66699 6.9222 6.73088 7.15554 6.85866 7.36665C6.98644 7.57776 7.16144 7.73887 7.38366 7.84998L7.00033 9.99998ZM8.00033 14.6666C6.45588 14.2778 5.18088 13.3916 4.17533 12.0083C3.16977 10.625 2.66699 9.08887 2.66699 7.39998V3.33331L8.00033 1.33331L13.3337 3.33331V7.39998C13.3337 9.08887 12.8309 10.625 11.8253 12.0083C10.8198 13.3916 9.54477 14.2778 8.00033 14.6666ZM8.00033 13.2666C9.15588 12.9 10.1114 12.1666 10.867 11.0666C11.6225 9.96665 12.0003 8.74442 12.0003 7.39998V4.24998L8.00033 2.74998L4.00033 4.24998V7.39998C4.00033 8.74442 4.3781 9.96665 5.13366 11.0666C5.88921 12.1666 6.84477 12.9 8.00033 13.2666Z"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px',
    htmlColor: Color.Dark100,
    fill: 'none',
  },
);
