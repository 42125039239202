import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { CarrierFullInfo, FmcsaInfo } from 'shared/types/carrier';
import { useAPI } from './API';
import { useAPIPageQuery } from './APIPageQuery';

export type SearchType = 'usdot' | 'name' | 'mcNumber' | 'email' | 'phone';

export function useCarrierSearch(
  query: string | undefined,
  searchType: SearchType,
  fmcsa?: boolean,
  isApproved?: boolean,
) {
  const { requestPage } = useAPI();

  const resource = fmcsa ? 'full_search_with_fmcsa' : 'full_search';
  const url = fmcsa
    ? `/internal/carriers/full_search_with_fmcsa{?${searchType},isApproved}`
    : `/internal/carriers/full_search{?${searchType},isApproved}`;

  return useAPIPageQuery(
    ['carriers', resource, { searchType, query, isApproved }],
    () =>
      requestPage(url, (data) => plainToClass(CarrierFullInfo, data), {
        [searchType]: query,
        isApproved,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!query,
    },
  );
}

export function useCarrierSearchAPI() {
  const { requestResource } = useAPI();

  return useMemo(
    () => ({
      parseAndFetchAuthority: (usdot: string) =>
        // Response can be undefined. Just ignore the error for empty response
        requestResource(
          '/internal/carriers/fmcsa/{usdot}/parse',
          (data) => plainToClass(FmcsaInfo, data),
          { usdot },
        ),
    }),
    [requestResource],
  );
}
