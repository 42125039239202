import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class NotificationDTO {
  @Expose() guid: string;
  @Expose() body: string;
  @Expose() title: string;
  @Expose() order_guid?: string;
  @Expose() link: string;
  @Expose() type:
    | 'load_request_created'
    | 'load_request_updated'
    | 'load_request_canceled'
    | 'super_pay_charge_failed';
}
