import { useSnackbarStack } from '@superdispatch/ui';
import { plainToClass } from 'class-transformer';
import { DictionaryCarrierDTO } from 'core/dictionary/data/DictionaryDTO';
import { useFormik } from 'formik';
import { FormikContextType } from 'formik/dist/types';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { ReportDTO } from './data/ReportDTO';
import { ReportParamsDTO } from './data/ReportParamsDTO';
import { useReportFieldGroup, useReportsAPI } from './data/ReportsAPI';
import { paymentStatuses, useReportsStatuses } from './ReportsStatus';

type FormStatus = 'update' | 'create' | 'send_report';

interface UseReportsFormOptions {
  report?: ReportDTO;
  onUpdate?: (report: ReportDTO) => void;
  onCreate: (report: ReportDTO) => void;
  onSendReport: (report: ReportParamsDTO) => void;
}

export function useReportsForm({
  report,
  onCreate,
  onUpdate,
  onSendReport,
}: UseReportsFormOptions) {
  const { data: group } = useReportFieldGroup();
  const { addSnackbar } = useSnackbarStack();
  const { generateReport, createSavedReport, updateSavedReport } =
    useReportsAPI();

  const { user } = useUserState();
  const statuses = useReportsStatuses();
  const payment_statuses = user?.shipper.had_superpay_access
    ? paymentStatuses
    : undefined;

  const defaultValues = useMemo<ReportParamsDTO>(
    () =>
      plainToClass(ReportParamsDTO, {
        statuses,
        payment_statuses,
        report_name: '',
        include: 'active',
        order_type: 'ALL',
        order_by: 'creation_date',
        send_report_to: user?.email || '',
        fields: group?.objects.flatMap((field) =>
          field.fields.map((x) => x.name),
        ),
      }),
    [group, user, statuses, payment_statuses],
  );

  const initialValues = useMemo<ReportParamsDTO>(() => {
    if (!report) {
      return defaultValues;
    }

    // Temporary measure util API accepts 'carriers'
    const reportClone = structuredClone(report);
    reportClone.report_params.carriers =
      report.report_params.carrier_names?.map((name) =>
        plainToClass(DictionaryCarrierDTO, {
          name,
        }),
      );
    return reportClone.report_params;
  }, [report, defaultValues]);

  const formik: FormikContextType<ReportParamsDTO> = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      return handleSubmit(formik.status, values).catch((error) => {
        addSnackbar(<APIErrorMessage error={error} />, {
          variant: 'error',
        });
      });
    },
  });

  function handleSubmit(mode: FormStatus, formValues: ReportParamsDTO) {
    // Temporary measure util API accepts 'carriers'
    const { carriers, ...values } = formValues;
    values.carrier_names = formValues.carriers?.map((x) => x.name);

    switch (mode) {
      case 'create':
        return createSavedReport(values).then(onCreate);

      case 'update':
        if (!report) {
          return Promise.reject(new Error('No report selected'));
        }
        return updateSavedReport(report, values).then(onUpdate);

      case 'send_report':
        return generateReport(values).then(() => {
          onSendReport(values);
        });
    }
  }

  function submitForm(mode: FormStatus) {
    formik.setStatus(mode);
    formik.handleSubmit();
  }

  function getButtonProps(mode: FormStatus) {
    const formStatus = formik.status as FormStatus;
    return {
      fullWidth: true,
      onClick: () => submitForm(mode),
      disabled: formStatus !== mode && formik.isSubmitting,
      isLoading: formStatus === mode && formik.isSubmitting,
    };
  }

  return { formik, getButtonProps };
}
