import { IconButton } from '@material-ui/core';
import { Add, Business, Edit, Person, Phone } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { TextBoxIcon } from 'shared/icons/Icons';
import { useVerificationApplication } from 'shipper-profile/data/VerificationApplicationAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { getInsuranceTypeLabel, isEmpty } from '../data/ShipperProfileUtils';
import { EmptySection, SectionLayout } from '../SectionLayout';
import { BondInsuranceEditDrawer } from './BondInsuranceEditDrawer';

interface BondInsuranceSectionProps {
  profile: ShipperProfileDTO;
  onUpdate?: () => void;
}

export function BondInsuranceSection({
  profile,
  onUpdate,
}: BondInsuranceSectionProps) {
  const { data: verificationApplication } = useVerificationApplication();
  const drawerMatch = useMatch('/profile/bond-insurance/edit');
  const isContentEmpty = useMemo(
    () =>
      isEmpty(profile, [
        'agent_phone',
        'bond_agent',
        'bond_company',
        'bond_expiration_date',
      ]),
    [profile],
  );

  const navigate = useNavigate();
  const { tryBack } = useTryBack();

  const openDrawer = () => navigate('/profile/bond-insurance/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  return (
    <>
      <BondInsuranceEditDrawer
        profile={profile}
        open={!!drawerMatch}
        onClose={closeDrawer}
        onUpdate={onUpdate}
      />

      {isContentEmpty ? (
        <EmptySection onClick={openDrawer} startIcon={<Add />}>
          Add Insurance
        </EmptySection>
      ) : (
        <SectionLayout
          title="Insurance"
          editComponent={
            <IconButton
              aria-label="edit insurance"
              size="small"
              onClick={openDrawer}
            >
              <Edit />
            </IconButton>
          }
        >
          <Stack space="small">
            <Stack space="xxsmall">
              {verificationApplication?.company_insurance_type && (
                <DescriptionItem
                  wrap={true}
                  icon={<TextBoxIcon />}
                  label="Type"
                >
                  {getInsuranceTypeLabel(
                    verificationApplication.company_insurance_type,
                  )}
                </DescriptionItem>
              )}
              {verificationApplication?.bond_company_name && (
                <DescriptionItem
                  wrap={true}
                  icon={<Business />}
                  label="Company"
                >
                  {verificationApplication.bond_company_name}
                </DescriptionItem>
              )}
              {verificationApplication?.bond_agent_name && (
                <DescriptionItem icon={<Person />} label="Agent">
                  {verificationApplication.bond_agent_name}
                </DescriptionItem>
              )}
              {verificationApplication?.bond_agent_phone && (
                <DescriptionItem icon={<Phone />}>
                  <SuspendedPhoneLink
                    phone={verificationApplication.bond_agent_phone}
                    fallback={verificationApplication.bond_agent_name}
                  />
                </DescriptionItem>
              )}
              {verificationApplication?.bond_expiration_date && (
                <DescriptionItem
                  icon={<CalendarIcon />}
                  label="Expiration Date"
                >
                  <FormattedDate
                    variant="Date"
                    format="DateISO"
                    date={verificationApplication.bond_expiration_date}
                  />
                </DescriptionItem>
              )}
            </Stack>
          </Stack>
        </SectionLayout>
      )}
    </>
  );
}
