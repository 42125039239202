import { useAPIQuery } from 'shared/api/APIQuery';
import { Carrier } from 'shared/types/carrier';
import { useAPI } from './API';
import { useAPIPageQuery } from './APIPageQuery';

export function useSuggestedCarriers(
  guid: string | undefined,
  params: {
    radius: number;
    number_of_days: number;
  },
) {
  const { requestPage } = useAPI();

  return useAPIQuery(
    ['load_matching', 'suggested', { guid, ...params }],
    () =>
      requestPage(
        'POST /internal/load_matching/suggested_carriers/{guid}',
        (data) => data as Carrier,
        { guid, json: params },
      ),
    { enabled: !!guid },
  );
}

export function usePreviouslyDispatchedCarriers(
  orderGuid: string,
  params: {
    search_radius_km: number;
    days: number;
  },
) {
  const { requestPage } = useAPI();

  return useAPIPageQuery<Carrier>(
    ['load_matching', 'internal_network', { orderGuid, ...params }],
    () =>
      requestPage(
        `/internal/load_matching/internal_network/${orderGuid}{?search_radius_km,days}`,
        (data) => data as Carrier,
        params,
      ),
  );
}
