import { formatCustomerType } from '@superdispatch/sdk';
import { inspectionTypeLabels } from 'core/InspectionType';
import { get, property } from 'lodash-es';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { OrderRevision, OrderRevisionStepVenue } from './types/OrderRevision';
import {
  OrderRevisionChangeType,
  OrderRevisionNormalizedChange,
  RevisionNormalizedChangeValueType,
} from './types/OrderRevisionNormalized';

export function isStepVenueChanged({
  city_modified,
  state_modified,
  zip_modified,
  address_modified,
}: OrderRevisionStepVenue) {
  return city_modified || state_modified || zip_modified || address_modified;
}

export function getStepVenueValue({
  city,
  state,
  zip,
  address,
}: OrderRevisionStepVenue) {
  return (
    (address ? `${address} ` : '') +
    (city ? `${city} ` : '') +
    (state ? `${state} ` : '') +
    (zip ? zip : '')
  );
}

export function getStandardPossibleChange(entity: 'order' | 'vehicle') {
  return (
    field: string,
    title: string,
    valueType?: RevisionNormalizedChangeValueType,
  ): OrderRevisionChangeType => {
    const getRevisionProperty = (path: string) =>
      property<OrderRevision, string | undefined>(path);
    return {
      title,
      valueType,
      getValue: (revision) => {
        if (revision.revision_type === 'DEL') {
          return '';
        }

        const value = getRevisionProperty(`${entity}.${field}`)(revision);

        //for permission value we need to preserve 'false' value
        // instead of returning empty string to render it correctly
        if (valueType === 'permission') {
          return value ?? '';
        }

        return value || '';
      },
      isExists: (revision) =>
        revision.revision_type === 'DEL'
          ? !!getRevisionProperty(`${entity}.${field}`)(revision)
          : !!getRevisionProperty(`${entity}.${field}_modified`)(revision),
    };
  };
}

type ScheduledDateKey =
  | 'scheduled_at'
  | 'scheduled_ends_at'
  | 'scheduled_at_by_customer'
  | 'scheduled_ends_at_by_customer';

function getScheduledDatePossibleChange(
  title: string,
  step: 'pickup' | 'delivery',
  fields: [ScheduledDateKey, ScheduledDateKey],
): OrderRevisionChangeType {
  return {
    title,
    valueType: 'date',
    isExists: ({ order }) =>
      fields.some((x) => !!get(order, `${step}.${x}_modified`)),
    getValue({ order }) {
      const revisionStep = order?.[step];
      const [scheduledAtName, scheduledEndsAtName] = fields;

      if (!revisionStep || !revisionStep[scheduledAtName]) {
        return '';
      }

      return revisionStep.date_type !== 'estimated'
        ? revisionStep[scheduledAtName]
        : [revisionStep[scheduledAtName], revisionStep[scheduledEndsAtName]];
    },
  };
}

export const revisionOrderPossibleChanges: OrderRevisionChangeType[] = [
  getStandardPossibleChange('order')('number', 'Order ID'),
  getStandardPossibleChange('order')('transport_type', 'Transport Type'),

  // Customer Payment
  getStandardPossibleChange('order')(
    'customer_payment.amount',
    'Customer Payment Amount',
    'currency',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.deposit',
    'Customer Payment Deposit',
    'currency',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.method',
    'Customer Payment Method',
    'paymentMethod',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.notes',
    'Customer Payment Notes',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.tariff',
    'Customer Payment Tariff',
    'currency',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.terms',
    'Customer Payment Terms',
    'paymentTerms',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.reference_number',
    'Customer Payment Reference Number',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer_payment.received_date',
    'Customer Payment Received Date',
    'date',
  ),

  // Inspection Type
  {
    title: 'Inspection Type',
    getValue: (revision) => {
      return revision.revision_type === 'DEL'
        ? ''
        : revision.order?.inspection_type
        ? inspectionTypeLabels[revision.order.inspection_type]
        : '';
    },
    isExists: (revision) =>
      revision.revision_type === 'DEL'
        ? !!revision.order?.inspection_type
        : !!revision.order?.inspection_type_modified,
  },

  // Instructions
  getStandardPossibleChange('order')(
    'order_instructions',
    'Order Instructions',
    'text',
  ),
  getStandardPossibleChange('order')(
    'loadboard_instructions',
    'Loadboard Instructions',
    'text',
  ),

  // Customer
  getStandardPossibleChange('order')(
    'customer.name',
    'Customer Business Name',
    'text',
  ),
  {
    title: 'Customer Business Type',
    isExists: ({ order }) =>
      (!!order?.customer?.business_type &&
        order.customer.business_type_modified) ||
      false,
    getValue: ({ order }) =>
      order?.customer?.business_type
        ? formatCustomerType(order.customer.business_type)
        : '',
  },
  getStandardPossibleChange('order')(
    'customer.address',
    'Customer Address',
    'text',
  ),
  getStandardPossibleChange('order')('customer.city', 'Customer City', 'text'),
  getStandardPossibleChange('order')(
    'customer.state',
    'Customer State',
    'text',
  ),
  getStandardPossibleChange('order')('customer.zip', 'Customer ZIP', 'text'),
  getStandardPossibleChange('order')(
    'customer.phone',
    'Customer Phone',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.email',
    'Customer Billing Email',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.contact_name',
    'Customer Contact Name',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.contact_title',
    'Customer Contact Title',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.contact_phone',
    'Customer Contact Phone',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.contact_mobile_phone',
    'Customer Contact Mobile Phone',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.contact_email',
    'Customer Contact Email',
    'text',
  ),
  getStandardPossibleChange('order')(
    'customer.notes',
    'Customer Notes',
    'text',
  ),

  // Pickup
  {
    title: 'Pickup Address',
    isExists: ({ order }) =>
      !!order?.pickup?.venue && isStepVenueChanged(order.pickup.venue),
    getValue: ({ order }) =>
      order?.pickup?.venue ? getStepVenueValue(order.pickup.venue) : '',
  },
  getScheduledDatePossibleChange('Customer Pickup Date', 'pickup', [
    'scheduled_at_by_customer',
    'scheduled_ends_at_by_customer',
  ]),
  getScheduledDatePossibleChange('Carrier Pickup Date', 'pickup', [
    'scheduled_at',
    'scheduled_ends_at',
  ]),
  getStandardPossibleChange('order')(
    'pickup.scheduled_at_by_carrier',
    'Carrier Updated Pickup Date',
    'date',
  ),
  getStandardPossibleChange('order')(
    'pickup.adjusted_date',
    'Pickup Adjusted Date',
    'date',
  ),
  getStandardPossibleChange('order')(
    'pickup.date_type',
    'Pickup Date Type',
    'dateType',
  ),
  getStandardPossibleChange('order')(
    'pickup.first_available_pickup_date',
    '1st Avail. Pickup Date',
    'date',
  ),
  getStandardPossibleChange('order')(
    'pickup.venue.contact_email',
    'Pickup Email',
    'email',
  ),
  getStandardPossibleChange('order')(
    'pickup.venue.contact_name',
    'Pickup Contact Name',
  ),
  getStandardPossibleChange('order')(
    'pickup.venue.contact_phone',
    'Pickup Phone',
    'phone',
  ),
  getStandardPossibleChange('order')(
    'pickup.venue.name',
    'Pickup Business Name',
  ),
  getStandardPossibleChange('order')('pickup.notes', 'Pickup Notes', 'text'),

  // Delivery
  {
    title: 'Delivery Address',
    isExists: ({ order }) =>
      !!order?.delivery?.venue && isStepVenueChanged(order.delivery.venue),
    getValue: ({ order }) =>
      order?.delivery?.venue ? getStepVenueValue(order.delivery.venue) : '',
  },
  getScheduledDatePossibleChange('Customer Delivery Date', 'delivery', [
    'scheduled_at_by_customer',
    'scheduled_ends_at_by_customer',
  ]),
  getScheduledDatePossibleChange('Carrier Delivery Date', 'delivery', [
    'scheduled_at',
    'scheduled_ends_at',
  ]),
  getStandardPossibleChange('order')(
    'delivery.scheduled_at_by_carrier',
    'Carrier Updated Delivery Date',
    'date',
  ),
  getStandardPossibleChange('order')(
    'delivery.adjusted_date',
    'Delivery Adjusted Date',
    'date',
  ),
  getStandardPossibleChange('order')(
    'delivery.date_type',
    'Delivery Date Type',
    'dateType',
  ),
  getStandardPossibleChange('order')(
    'delivery.venue.contact_email',
    'Delivery Email',
    'email',
  ),
  getStandardPossibleChange('order')(
    'delivery.venue.contact_name',
    'Delivery Contact Name',
  ),
  getStandardPossibleChange('order')(
    'delivery.venue.contact_phone',
    'Delivery Phone',
    'phone',
  ),
  getStandardPossibleChange('order')(
    'delivery.venue.name',
    'Delivery Business Name',
  ),
  getStandardPossibleChange('order')(
    'delivery.notes',
    'Delivery Notes',
    'text',
  ),

  // Payment
  getStandardPossibleChange('order')(
    'price',
    'Total Carrier Price',
    'currency',
  ),
  getStandardPossibleChange('order')(
    'payment.amount',
    'Carrier Payment Amount',
    'currency',
  ),
  getStandardPossibleChange('order')(
    'payment.method',
    'Carrier Payment Method',
    'paymentMethod',
  ),
  getStandardPossibleChange('order')(
    'payment.notes',
    'Carrier Payment Notes',
    'text',
  ),
  getStandardPossibleChange('order')(
    'payment.terms',
    'Carrier Payment Terms',
    'paymentTerms',
  ),

  // Dispatcher and Salesperson
  getStandardPossibleChange('order')('dispatcher_name', 'Dispatcher Name'),
  getStandardPossibleChange('order')(
    'sales_representative',
    'Sales Representative',
  ),

  //instant booking
  getStandardPossibleChange('order')(
    'can_be_instantly_booked',
    'Instant Booking by Verified Carriers',
    'permission',
  ),
];
export const revisionVehiclePossibleChanges: OrderRevisionChangeType[] = [
  getStandardPossibleChange('vehicle')('vin', 'VIN'),
  {
    title: 'Vehicle',
    isExists: ({ vehicle, revision_type }) =>
      revision_type === 'DEL' ||
      (!!vehicle &&
        (vehicle.year_modified ||
          vehicle.make_modified ||
          vehicle.model_modified)),
    getValue: ({ vehicle, revision_type }) => {
      if (!vehicle || revision_type === 'DEL') {
        return '';
      }
      const { year, make, model } = vehicle;

      return (
        (year ? `${year} ` : '') +
        (make ? `${make} ` : '') +
        (model ? `${model}` : '')
      );
    },
  },
  getStandardPossibleChange('vehicle')('type', 'Type', 'vehicleType'),
  getStandardPossibleChange('vehicle')('color', 'Color'),
  getStandardPossibleChange('vehicle')(
    'tariff',
    'Tariff per Vehicle',
    'currency',
  ),
  getStandardPossibleChange('vehicle')(
    'price',
    'Carrier Price per Vehicle',
    'currency',
  ),
  getStandardPossibleChange('vehicle')('inoperable', 'INOP', 'boolean'),

  {
    title: 'Weight',
    isExists: ({ vehicle, revision_type }) =>
      revision_type === 'DEL' || !!vehicle?.curb_weight_modified,
    getValue: ({ vehicle, revision_type }) => {
      if (!vehicle || !vehicle.curb_weight || revision_type === 'DEL') {
        return '';
      }
      const { curb_weight, curb_weight_unit } = vehicle;

      return `${formatNumber(parseFloat(curb_weight))} ${
        curb_weight_unit || 'lbs'
      }`;
    },
  },

  getStandardPossibleChange('vehicle')('length', 'Length', 'inch'),
  getStandardPossibleChange('vehicle')('lot_number', 'LOT Number'),
  getStandardPossibleChange('vehicle')('width', 'Width', 'inch'),
  getStandardPossibleChange('vehicle')('height', 'Height', 'inch'),
];

export function getRevisionPossibleChanges(
  revision: OrderRevision,
): OrderRevisionChangeType[] {
  switch (true) {
    case !!revision.order:
      return revisionOrderPossibleChanges;
    case !!revision.vehicle:
      return revisionVehiclePossibleChanges;
    default:
      return [];
  }
}

export function findPrevRevision(
  currRevision: OrderRevision,
  revisions: OrderRevision[],
) {
  return revisions.find(
    (revision) => currRevision.prev_rev_id === revision.rev_id,
  );
}

export function getRevisionChanges(
  revision: OrderRevision,
  revisions: OrderRevision[],
): OrderRevisionNormalizedChange[] {
  return getRevisionPossibleChanges(revision).map(
    ({ title, valueType, ...change }) => {
      const prevRevision = findPrevRevision(revision, revisions);
      const currValue = change.getValue(revision);
      const prevValue = prevRevision ? change.getValue(prevRevision) : '';
      const isExists =
        (!prevRevision && !!currValue) || change.isExists(revision);

      return { title, isExists, currValue, prevValue, valueType };
    },
  );
}
