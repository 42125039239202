import { VehicleType } from '@superdispatch/sdk';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ETAInformationDTO } from 'orders/data/dto/ETAInformationDTO';

@Exclude()
class VehicleVenueDTO {
  @Expose() completed_at: string | null;
}

@Exclude()
export class VehiclePhotoDTO {
  @Expose() id?: number;
  @Expose() guid?: string;
  @Expose() photo_url?: string;
  @Expose() thumbnail_url?: string;
  @Expose() photo_type: 'Sample' | 'Delivery' | 'Pickup';

  // Prevent Using File constructor for transforming
  @Type(() => class {})
  @Transform((_x, { photo_file }) => photo_file)
  @Expose({ toClassOnly: true })
  photo_file?: File;
}

@Exclude()
export class VehicleDTO {
  static formatVehicleModel({
    year,
    make,
    model,
  }: Pick<VehicleDTO, 'year' | 'make' | 'model'>) {
    return [year, make, model].filter(Boolean).join(' ');
  }

  @Expose() id: number;
  @Expose() vin?: string;
  @Expose() status:
    | 'new'
    | 'canceled'
    | 'declined'
    | 'pending'
    | 'accepted'
    | 'picked_up'
    | 'delivered'
    | 'invoiced'
    | 'paid'
    | 'submitted'
    | 'scheduled'
    | 'completed'
    | 'order_canceled';
  @Expose() guid: string;

  @Expose()
  @Transform((value) => !!value)
  is_modified?: boolean;

  @Expose() parent_vehicle_guid?: string;
  @Expose() is_inoperable?: boolean;
  @Expose() pickup?: VehicleVenueDTO;
  @Expose() delivery?: VehicleVenueDTO;
  @Expose() make?: string;
  @Expose() model?: string;
  @Expose() color?: string;
  @Expose() requires_enclosed_trailer?: boolean;
  @Expose() type?: VehicleType;
  @Expose() year?: string;
  @Expose() jtracker_id?: number;
  @Expose() lot_number?: string;
  @Expose() tariff?: number;
  @Expose() price?: number;
  @Expose() is_damaged?: boolean;
  @Expose() pdf_bol_url: string | null;
  @Expose() changed_at: string | null;
  @Expose() created_at: string | null;
  @Expose() inspection_type: string | null;
  @Expose() is_active: boolean | null;
  @Expose() is_driveaway: boolean | null;
  @Expose() last_revision_id: number | null;
  @Expose() vin_short: string | null;
  @Expose() eta_information?: ETAInformationDTO;

  @Expose()
  @Type(() => VehiclePhotoDTO)
  photos: VehiclePhotoDTO[] | null;

  @Expose() curb_weight?: number;
  @Expose() curb_weight_unit?: string;
  @Expose() width: string | null;
  @Expose() height: string | null;
  @Expose() length: string | null;
}
