import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Popover,
} from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';
import { Button, ButtonProps } from '@superdispatch/ui';
import * as React from 'react';
import { FunctionComponent, ReactNode, useState } from 'react';

type PartialBtnProps = Omit<Partial<ButtonProps>, 'onClick'>;
type PopoverOwnProps = Partial<
  Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'>
>;
interface ConfirmationButtonOwnProps {
  dialogTitle?: ReactNode;
  dialogContent?: ReactNode;
  cancelBtnProps?: PartialBtnProps;
  onCancel?: () => void;
  confirmBtnProps?: PartialBtnProps;
  onConfirm: () => void;
}

export type ConfirmationButtonProps = ConfirmationButtonOwnProps &
  PartialBtnProps &
  PopoverOwnProps;

/** @deprecated */
const ConfirmationButton: FunctionComponent<ConfirmationButtonProps> = ({
  dialogTitle,
  dialogContent,
  cancelBtnProps,
  onCancel,
  confirmBtnProps,
  onConfirm,
  isLoading,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const popoverProps: PopoverOwnProps = props;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
    onCancel?.();
  };

  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        isLoading={isLoading}
        {...props}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...popoverProps}
      >
        <Paper>
          {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
          {dialogContent && (
            <DialogContent>
              <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="primary"
              {...cancelBtnProps}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirm}
              color="primary"
              {...confirmBtnProps}
            >
              Confirm
            </Button>
          </DialogActions>
        </Paper>
      </Popover>
    </>
  );
};

export default ConfirmationButton;
