import { CircularProgress, Link } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { FileDropZone, toBytes } from '@superdispatch/ui-lab';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import styled from 'styled-components';
import { useCustomersAPI } from '../data/CustomerAPI';

const Wrapper = styled.div`
  width: 600px;

  & .SD-CardButton-root {
    height: 280px;
  }
`;

export function CustomersImport() {
  const { addSnackbar } = useSnackbarStack();
  const { importCustomers } = useCustomersAPI();

  const { setValues, handleSubmit, isSubmitting } = useFormikEnhanced<
    { file?: File },
    unknown
  >({
    initialValues: {},
    onSubmit({ file }) {
      if (!file) {
        return Promise.reject(new Error('No file selected'));
      }
      return importCustomers(file);
    },
    onSubmitSuccess() {
      addSnackbar('Customers imported', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
        autoHideDuration: 100 * 1000,
      });
    },
  });

  return (
    <Wrapper>
      <Stack space="small" align="center">
        <FileDropZone
          accept=".csv"
          disabled={isSubmitting}
          startIcon={<GetApp />}
          maxSize={toBytes(20, 'mb')}
          hintText="Drop or upload CSV file here."
          onDropAccepted={(acceptedFiles) => {
            setValues({ file: acceptedFiles[0] });
            handleSubmit();
          }}
        >
          {isSubmitting ? <CircularProgress /> : 'Import'}
        </FileDropZone>

        <Link
          href="https://storage.googleapis.com/prod-broker-files/sample_customers.csv"
          download={true}
        >
          Download sample file
        </Link>
      </Stack>
    </Wrapper>
  );
}
