import { CircularProgress } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { ReactNode, Suspense } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { AppNavbar } from 'shared/layout/AppNavbar';

export function AppSidebarLayout({ children }: { children: ReactNode }) {
  return (
    <AppNavbar>
      <AppBlankLayout>{children}</AppBlankLayout>
    </AppNavbar>
  );
}

export function AppBlankLayout({ children }: { children: ReactNode }) {
  const { authState } = useUserState();

  if (authState === 'pending') {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  return (
    <Suspense
      fallback={
        <Flex flex={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Flex>
      }
    >
      {children}
    </Suspense>
  );
}
