import { AuthenticatedRouteObject } from 'AppRouter';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { SubscriptionPlansExpressPage } from './express-subscription-plan/SubscriptionPlansExpressPage';
import { SubscriptionPlansProPage } from './pro-subscription-plan/SubscriptionPlansProPage';
import { SubscriptionPlansIndexPage } from './SubscriptionPlansIndexPage';

export const subscriptionPlansRoute: AuthenticatedRouteObject = {
  path: 'subscription-plans',
  element: <SubscriptionPlansContainer />,
  children: [
    {
      index: true,
      element: <SubscriptionPlansIndexPage />,
    },
    {
      path: 'shipper-express',
      element: <SubscriptionPlansExpressPage />,
    },
    {
      path: 'shipper-pro',
      element: <SubscriptionPlansProPage />,
    },
  ],
};

function SubscriptionPlansContainer() {
  const isSubscriptionEnabled = useFeatureToggle(
    'update-credit-card-info.enabled',
  );
  const { isAdmin, isSuperUser } = useUserState();
  const showPlans = isSubscriptionEnabled && (isAdmin || isSuperUser);

  if (!showPlans) {
    return <Navigate to="/orders" replace={true} />;
  }

  return <Outlet />;
}
