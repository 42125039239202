import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { SuperPayRepresentative } from 'shared/helpers/superpay/MoovHelpers';
import {
  useOwnersProvidedInfoMutation,
  useSuperPaySettingsCache,
} from 'superpay/data/SuperPayAPI';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { OwnersList } from './OwnersList';

interface SuperPayOwnersListPageProps {
  owners: SuperPayRepresentative[];
  refetchOwnersList: () => void;
}

export function OwnersListContainer({
  owners,
  refetchOwnersList,
}: SuperPayOwnersListPageProps) {
  const isMobile = useResponsiveValue(true, false);
  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();
  const { addSnackbar } = useSnackbarStack();
  const { logPaymentInfo } = usePaymentLogger();

  const { mutate: updateMoovAccount, isLoading } =
    useOwnersProvidedInfoMutation({
      onSuccess() {
        invalidateSuperPaySettings();
        trackEvent('Shipper Submitted Representatives', {
          representative_quantity: owners.length,
        });
        logPaymentInfo('Submitted Representatives', 'RepresentativesList');
      },
      onError(error) {
        addSnackbar(error.message, { variant: 'error' });
      },
    });

  return (
    <Card square={isMobile}>
      <CardContent>
        <Stack space="medium">
          <Stack>
            <Typography variant="h3">Owner/Representative</Typography>
            <Typography color="textSecondary">
              Please enter personal information and residential address of the
              majority owner or a high-level manager such as the CEO, CFO or
              COO.
            </Typography>
          </Stack>
          <Stack space="medium">
            <OwnersList owners={owners} refetchOwnersList={refetchOwnersList} />
          </Stack>
          <Stack space="small">
            <Typography>
              By clicking “Continue”, you certify that all business
              representatives are added.
            </Typography>
            <Button
              disabled={isLoading}
              pending={isLoading}
              onClick={() => {
                updateMoovAccount();
              }}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
