import { Link, Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import { getCurrentSource } from 'shared/helpers/SourceManagmentLegacy';
import { OrderActivityDTO } from '../../data/dto/OrderActivityDTO';
import { OrderViewNote } from '../OrderViewNote';
import { OrderActivityDetail } from './OrderActivityDetail';

interface OrderActivityCarrierInfoProps {
  activity: OrderActivityDTO;
}

export function OrderActivityCarrierInfo({
  activity: {
    carrier_guid,
    carrier_name,
    code,
    offer_decline_comment,
    offer_decline_reasons,
  },
}: OrderActivityCarrierInfoProps) {
  const [medium, content] = getCurrentSource() ?? [];
  return (
    <Stack space="xsmall">
      {carrier_guid ? (
        <Link
          href={parseURITemplate(
            `/manage-carriers/${carrier_guid}{?utm_medium,utm_content}`,
            {
              utm_medium: medium,
              utm_content: content,
            },
          )}
        >
          {carrier_name}
        </Link>
      ) : (
        <Typography variant="body2">{carrier_name}</Typography>
      )}

      {code === 'declined' && offer_decline_reasons && (
        <OrderActivityDetail label="Reasons" value={offer_decline_reasons} />
      )}

      {code === 'declined' && offer_decline_comment && (
        <OrderViewNote
          lines={3}
          bg={Color.Yellow50}
          notes={offer_decline_comment}
        />
      )}
    </Stack>
  );
}
