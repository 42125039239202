import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { getCurrentTab } from 'shared/layout/AppNavbar';
import Order from 'shared/types/order';
import { joinStrings } from 'shared/utils/StringUtils';
import { OrderActionSource } from '../OrderActionsAnalytics';

type Source = 'orders_list' | 'order_view_page' | OrderActionSource;

export function trackScheduleEvent(
  eventName: string,
  orders: Order[],
  source: Source,
) {
  const eventData = orders.reduce(
    (data, order) => {
      data.order_status = joinStrings(',', data.order_status, order.status);
      data.shipper_guid = joinStrings(',', data.shipper_guid, order.guid);
      data.payment_method = joinStrings(
        ',',
        data.payment_method,
        order.payment?.method || 'unknown',
      );
      data.payment_terms = joinStrings(
        ',',
        data.payment_terms,
        order.payment?.terms || 'unknown',
      );
      return data;
    },
    {
      order_status: '',
      shipper_guid: '',
      payment_method: '',
      payment_terms: '',
    },
  );

  trackEvent(eventName, {
    ...eventData,
    order_count: orders.length,
    tab: getCurrentTab(),
    utm_medium: source,
  });
}
