import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  SectionHeader,
  SectionSwitch,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';

export interface SuperLoadboardAutoRepostDeclinedProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardAutoRepostDeclined({
  profile,
}: SuperLoadboardAutoRepostDeclinedProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Auto Repost Declined Automatically',
        is_auto_repost_for_declined_offers_enabled:
          updatedValues.is_auto_repost_for_decline_offers_enabled,
      });
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  return (
    <SuperLoadboardSettingsSection aria-label="Auto Repost Declined">
      <FormikProvider value={formik}>
        <Stack space="small">
          <SectionHeader>
            <Columns align="center">
              <Column width="fluid">
                <Typography color="inherit" variant="h4">
                  Repost Declined Offers Automatically
                </Typography>
              </Column>
              <Column width="content">
                <SectionSwitch
                  disabled={!canUpdateProfile || formik.isSubmitting}
                  checked={
                    formik.values.is_auto_repost_for_decline_offers_enabled
                  }
                  onChange={(_, checked) => {
                    formik.setFieldValue(
                      'is_auto_repost_for_decline_offers_enabled',
                      checked,
                    );
                    void formik.submitForm();
                  }}
                />
              </Column>
            </Columns>
          </SectionHeader>
          <Typography color="textSecondary">
            Streamline operations and let us repost your declined load offers.
          </Typography>
        </Stack>
      </FormikProvider>
    </SuperLoadboardSettingsSection>
  );
}
