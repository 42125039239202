import { InfoTooltip } from 'shared/ui/InfoTooltip';
import { List } from 'shared/ui/List';

interface OrderViewInstantBookingProps {
  isBookable: boolean | null;
}
export function OrderViewInstantBooking({
  isBookable,
}: OrderViewInstantBookingProps) {
  return isBookable ? (
    <InfoTooltip
      placement="bottom"
      title={
        <List color="inherit">
          <li>Verified Carriers are allowed to instantly booking this load.</li>
          <li>
            Blocklisted carriers will not be allowed to book in Super Loadboard.
          </li>
        </List>
      }
    >
      Allowed
    </InfoTooltip>
  ) : (
    <InfoTooltip
      placement="bottom"
      title={
        <List color="inherit">
          <li>
            Verified Carriers were disabled from instantly booking this load.
          </li>
          <li>
            Instant booking is still available for carriers marked as ‘Can
            Instantly Book’.
          </li>
        </List>
      }
    >
      Disabled
    </InfoTooltip>
  );
}
