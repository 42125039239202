import { createSvgIcon } from 'shared/icons/IconUtils';

export const CheckCircleBigIcon = createSvgIcon(
  'CheckCircleBigIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M44.0002 80.6668C64.2506 80.6668 80.6668 64.2506 80.6668 44.0002C80.6668 23.7497 64.2506 7.3335 44.0002 7.3335C23.7497 7.3335 7.3335 23.7497 7.3335 44.0002C7.3335 64.2506 23.7497 80.6668 44.0002 80.6668ZM57.6844 29.6353L38.0621 48.9211L30.3388 41.323L25.5215 46.0288L38.0616 58.3655L62.4749 34.3482L57.6844 29.6353Z"
    fill="#1FA84D"
  />,
  {
    viewBox: '0 0 88 88',
    style: { height: '88px', width: '88px' },
  },
);
