import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export const privateNetworkCreateGroupSchema = yupObject({
  name: yupString()
    .min(3, 'Group name cannot be less than 3 characters')
    .max(18, 'Group name cannot be more than 18 characters')
    .required('Enter valid group name')
    .defined(),
});

export const privateNetworkGroupSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  carrier_count: yupNumber(),
});

export const privateNetworkPostedStatsSchema = yupObject({
  posted_count: yupNumber().nullable(false),
});

export const privateNetworkGroupsSchema = yupObject({
  all_private_network_carrier_count: yupNumber(),
  groups: yupArray<PrivateNetworkGroupDTO>(privateNetworkGroupSchema).defined(),
});

export type PrivateNetworkCreateGroupDTO = InferType<
  typeof privateNetworkCreateGroupSchema
>;

export type PrivateNetworkGroupsDTO = InferType<
  typeof privateNetworkGroupsSchema
>;

export type PrivateNetworkGroupDTO = InferType<
  typeof privateNetworkGroupSchema
>;

export type PrivateNetworkPostedStatsDTO = InferType<
  typeof privateNetworkPostedStatsSchema
>;

export function formatGroupNames(
  guids: string[],
  groups: PrivateNetworkGroupDTO[] = [],
) {
  const groupMap = new Map(groups.map((group) => [group.guid, group.name]));
  const names = guids
    .map((guid) => groupMap.get(guid))
    .filter(Boolean) as string[];

  names.sort((a, b) => a.localeCompare(b));
  return names;
}
