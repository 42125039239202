import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import {
  APIListQueryResult,
  findAPIListQueryItem,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  customerDTO,
  CustomerDTO,
  CustomerFormData,
} from 'shared/dto/CustomerDTO';

export function useCustomersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateCustomers() {
      void queryClient.invalidateQueries('customers');
    }

    function findCustomer(id: number) {
      return findAPIListQueryItem<CustomerDTO>(
        ['customers', 'page'],
        queryClient,
        (item) => item.id === id,
      );
    }

    function replaceCustomer(customer: CustomerDTO) {
      queryClient.setQueryData(
        ['customers', 'item', { id: customer.id }],
        customer,
      );
    }

    return { findCustomer, invalidateCustomers, replaceCustomer };
  }, [queryClient]);
}

export function useCustomersList(
  query: string | null,
): APIListQueryResult<CustomerDTO> {
  const { requestPage } = useAPI();
  return useAPIListQuery<CustomerDTO>(
    ['customers', 'page', { query }],
    (page) =>
      requestPage(
        '/internal/customers{?page,size,query}',
        (data) => customerDTO.cast(data),
        {
          page,
          size: 20,
          query: query && query.length > 2 ? query : undefined,
        },
      ),
    {},
  );
}

interface CustomerParams {
  id: number | null;
  guid: string | null;
}

export function useCustomer({ id, guid }: CustomerParams) {
  const { requestResource } = useAPI();
  const { findCustomer, replaceCustomer } = useCustomersCache();

  return useAPIQuery(
    ['customers', 'item', { id, guid }],
    () => {
      if (guid) {
        return requestResource(
          '/internal/customers/guid/{guid}',
          (data) => customerDTO.cast(data),
          { guid },
        ).then((response) => {
          replaceCustomer(response);
          return response;
        });
      }

      return requestResource(
        '/internal/customers/{id}',
        (data) => customerDTO.cast(data),
        { id },
      );
    },
    {
      enabled: !!id || !!guid,
      initialData: () => {
        if (id) {
          return findCustomer(id);
        }

        return undefined;
      },
      // Customers list doesn't have some fields
      // Need to refetch Customer to get them
      initialDataUpdatedAt: 0,
    },
  );
}

export function useCustomersAPI() {
  const cache = useCustomersCache();
  const { request, requestBlob, requestResource } = useAPI();

  return useMemo(() => {
    return {
      importCustomers(file: File) {
        const body = new FormData();
        body.append('file', file);

        return request('POST /internal/customers/import', { body }).then(() => {
          cache.invalidateCustomers();
        });
      },
      exportCustomers: (customerIDs: number[]) =>
        requestBlob('POST /internal/customers/export', { json: customerIDs }),
      deleteCustomer: (customerID: number) =>
        request<{ customerID: number }>(
          'DELETE /internal/customers/{customerID}',
          { customerID },
        ).then(() => {
          cache.invalidateCustomers();
        }),
      deleteCustomers: (customerIDs: number[]) =>
        request('DELETE /internal/customers/bulk_delete', {
          json: customerIDs,
        }).then(() => {
          cache.invalidateCustomers();
        }),
      createCustomer: (customerFormData: CustomerFormData) => {
        return requestResource(
          'POST /internal/customers',
          (data) => customerDTO.cast(data),
          { json: customerFormData },
        ).then((response) => {
          cache.invalidateCustomers();
          return response;
        });
      },
      updateCustomer: (customer: CustomerDTO) => {
        return requestResource<CustomerDTO, { customerId: number }>(
          'PUT /internal/customers/{customerId}',
          (data) => customerDTO.cast(data),
          {
            customerId: customer.id,
            json: customer,
          },
        ).then((response) => {
          cache.invalidateCustomers();
          cache.replaceCustomer(response);

          return response;
        });
      },
    };
  }, [cache, request, requestBlob, requestResource]);
}
