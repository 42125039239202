export function debounceBatchCalls(timeout = 50) {
  let timer: ReturnType<typeof setTimeout>;
  let lastArgs: string[] = [];
  let subscribers: Array<{ resolve: Function; reject: Function }> = [];

  return function main<R>(
    param: string[],
    fn: (param: string[]) => Promise<R>,
  ): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      lastArgs.push(...param);
      subscribers.push({ resolve, reject });
      clearTimeout(timer);

      timer = setTimeout(() => {
        fn(lastArgs)
          .then((response) => {
            subscribers.forEach((subscriber) => {
              subscriber.resolve(response);
            });
          })
          .catch((error) => {
            subscribers.forEach((subscriber) => {
              subscriber.reject(error);
            });
          });
        lastArgs = [];
      }, timeout);
    });
  };
}
