import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormikTextField, FormikTextFieldProps } from '@superdispatch/forms';
import { useState } from 'react';

export function FormikPasswordField(props: FormikTextFieldProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <FormikTextField
      type={isPasswordVisible ? 'text' : 'password'}
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <IconButton
            size="small"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
}
