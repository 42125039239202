export interface FileRules {
  allowFormats?: string[];
  formatMessage?: string;
  maxSize?: number;
  maxSizeMessage?: string;
  required?: boolean;
  requiredMessage?: string;
  isEmpty?: (value: unknown) => boolean;
}

export function validateFile(
  value: File,
  {
    allowFormats,
    formatMessage = 'Invalid format',
    maxSize,
    maxSizeMessage = 'File size exceeds the limit',
    required,
    requiredMessage = 'This field is required',
    isEmpty = (input) => input == null,
  }: FileRules,
): string | void {
  if (isEmpty(value)) {
    if (required) {
      return requiredMessage;
    }

    return;
  }

  if (maxSize && value.size > maxSize) {
    return maxSizeMessage;
  }

  if (allowFormats?.length && !allowFormats.includes(value.type)) {
    return formatMessage;
  }
}

export function validateFileList(
  value: File[],
  {
    allowFormats,
    formatMessage = 'Invalid format',
    maxSize,
    maxSizeMessage = 'File size exceeds the limit',
    required,
    requiredMessage = 'This field is required',
    isEmpty = (input) => input == null,
  }: FileRules,
): string | void {
  for (const file of value) {
    const message = validateFile(file, {
      allowFormats,
      formatMessage,
      maxSize,
      maxSizeMessage,
      required,
      requiredMessage,
      isEmpty,
    });

    if (message) {
      return message;
    }
  }
}
