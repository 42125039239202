import { Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import {
  terminalContactDTO,
  TerminalContactDTO,
} from 'shared/dto/TerminalContactDTO';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { getObjectChangeInfo } from 'shared/utils/DataUtils';
import { email, required } from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { useTerminalContactAPI } from '../data/TerminalContactAPI';

type TerminalContactFormData = Partial<TerminalContactDTO>;

interface TerminalContactDrawerContentProps {
  terminal: TerminalDTO;
  contact?: TerminalContactDTO;
  isPrimary: boolean;
  onSubmitSuccess: (contact: TerminalContactDTO) => void;
}

const ContactDrawerContentWrapper = styled.div`
  max-width: 100%;

  @media (min-width: 600px) {
    max-width: 432px;
  }
`;

export function TerminalContactDrawerContent({
  contact,
  terminal,
  isPrimary,
  onSubmitSuccess,
}: TerminalContactDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { createTerminalContact, updateTerminalContact } =
    useTerminalContactAPI();

  const initialValues = useMemo<TerminalContactFormData>(
    () => ({
      name: '',
      email: '',
      is_primary: isPrimary,
      ...contact,
    }),
    [isPrimary, contact],
  );

  const form = useFormikEnhanced<TerminalContactFormData, TerminalContactDTO>({
    initialValues,
    onSubmit(values) {
      const payload = terminalContactDTO.cast(values);

      return contact
        ? updateTerminalContact(terminal.id, contact.id, payload)
        : createTerminalContact(terminal.id, payload);
    },
    onSubmitSuccess(response, values) {
      onSubmitSuccess(response);

      if (contact) {
        trackEventLegacy('Updated Terminal Contact', {
          terminal,
          contact: getObjectChangeInfo(contact, values),
        });
        addSnackbar('Contact updated', { variant: 'success' });
      } else {
        trackEventLegacy('Added Terminal Contact', {
          terminal,
          contact: values,
        });
        addSnackbar('Contact created', { variant: 'success' });
      }
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikDrawerContent
      formik={form}
      actions={<FormikDrawerActions />}
      title={contact ? 'Edit Contact' : 'New Contact'}
    >
      <Stack space="small">
        <FormikTextField
          name="name"
          fullWidth={true}
          validate={required}
          label={
            <Inline space="xxsmall">
              <TextBox>Contact Name</TextBox>
              <TextBox color="secondary">(Required)</TextBox>
            </Inline>
          }
        />

        <FormikTextField label="Title" name="title" fullWidth={true} />

        <FormikCheckboxField
          label="Set as primary contact"
          name="is_primary"
          color="primary"
        />

        <Box marginTop="small">
          <Typography variant="h4">Contacts</Typography>
        </Box>

        <FormikTextField label="Phone" name="phone" fullWidth={true} />
        <FormikTextField
          label="Phone Extension"
          name="phone_extension"
          fullWidth={true}
        />

        <FormikTextField label="Mobile" name="mobile_phone" fullWidth={true} />

        <FormikTextField
          label="Email"
          name="email"
          fullWidth={true}
          validate={email}
        />
      </Stack>
    </FormikDrawerContent>
  );
}

interface TerminalContactFormProps extends TerminalContactDrawerContentProps {
  open: boolean;
  onClose: () => void;
}

export function TerminalContactForm({
  open,
  onClose,
  ...props
}: TerminalContactFormProps) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      <ContactDrawerContentWrapper>
        <TerminalContactDrawerContent {...props} />
      </ContactDrawerContentWrapper>
    </FormikDrawer>
  );
}
