import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  Order,
  SuperPayOrderStatus,
  superPayOrderStatuses,
} from '../types/order';
import { useAPI } from './API';

interface RequestsCount {
  load_request_count: number;
  has_new_load_request: boolean;
}

export type OrderCountKeys =
  | Order['status']
  | 'posted_to_cd'
  | 'posted_to_lb'
  | 'posted_to_sdlb'
  | 'flagged'
  | SuperPayOrderStatus;

export type OrderCountType = Record<OrderCountKeys, number>;

export function useOrderCountsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const invalidateOrderCounts = () => {
      return Promise.all([
        queryClient.invalidateQueries(['orders', 'count']),
        queryClient.invalidateQueries(['orders', 'requests/count']),
      ]);
    };

    return { invalidateOrderCounts };
  }, [queryClient]);
}

export function useOrderCounts() {
  const { requestResource } = useAPI();

  const {
    data: orders,
    refetch: refetchOrders,
    isLoading,
  } = useAPIQuery(
    ['orders', 'count'],
    () =>
      requestResource(
        '/internal/orders/count',
        (data) => data as OrderCountType,
      ),
    { staleTime: 0 },
  );

  const { data: requests, refetch: refetchRequests } = useAPIQuery(
    ['orders', 'requests/count'],
    () =>
      requestResource(
        '/internal/orders/requests/count',
        (data) => data as RequestsCount,
      ),
    { staleTime: 0 },
  );

  const data = useMemo(() => {
    if (!orders || !requests) {
      return undefined;
    }
    let total = 0;

    for (let key in orders) {
      if (
        key !== 'flagged' &&
        key !== 'posted_to_cd' &&
        key !== 'posted_to_sdlb' &&
        !superPayOrderStatuses.includes(key as SuperPayOrderStatus)
      ) {
        total += Number(orders[key]);
      }
    }

    return {
      ...orders,
      ...requests,

      total,
      new: (orders.new || 0) + (orders.canceled || 0),
      requests: requests.load_request_count,
    };
  }, [orders, requests]);

  return {
    data,
    isLoading,
    refetch: () => {
      void refetchOrders();
      void refetchRequests();
    },
  };
}
