import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { FocusInput } from 'shared/form/FocusInput';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { useCustomersAPI } from '../data/CustomerAPI';

interface CustomerEditDrawerContentProps {
  title: ReactNode;
  children: ReactNode;
  onUpdateSuccess: (customer: CustomerDTO) => void;

  customer: CustomerDTO;
  autoFocusField?: keyof CustomerDTO;
}

export function CustomerEditDrawerContent({
  title,
  children,
  customer,
  autoFocusField,
  onUpdateSuccess,
}: CustomerEditDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateCustomer } = useCustomersAPI();

  const form = useFormikEnhanced({
    initialValues: customer,
    onSubmit: (values) => updateCustomer(values),
    onSubmitSuccess(response: CustomerDTO) {
      addSnackbar('Customer updated', { variant: 'success' });

      onUpdateSuccess(response);
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikDrawerContent
      title={title}
      formik={form}
      actions={<FormikDrawerActions />}
    >
      {children}

      <FocusInput name={autoFocusField} />
    </FormikDrawerContent>
  );
}

export interface CustomerEditDrawerProps
  extends CustomerEditDrawerContentProps {
  open: boolean;
  onClose: () => void;
}

export function CustomerEditDrawer({
  open,
  onClose,
  ...props
}: CustomerEditDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      <CustomerEditDrawerContent {...props} />
    </FormikDrawer>
  );
}
