import { Link, Paper, Typography } from '@material-ui/core';
import { ErrorOutlined, Lock } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { FormikTextField } from '@superdispatch/forms';
import { Button, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAppToken } from 'shared/data/AppUserState';
import { getAppType } from 'shared/data/AppUtils';
import { useFeatureTogglePublic } from 'shared/data/FeatureToggle';
import { useAppFormik } from 'shared/form/AppFormik';
import { FormikPasswordField } from 'shared/form/FormikPasswordField';
import { useQuery } from 'shared/helpers/RouteHelpers';
import backgroundCircles from 'shared/icons/bg-circles.svg';
import styled from 'styled-components';
import { writeIsSubscriptionPaymentFailedAlertClosed } from 'subscription-details/SubscriptionPaymentFailedAlert';
import { getDeviceGuid } from './core/DeviceGuid';
import { useVerification } from './core/VerificationStorage';
import { useAuthAPI } from './data/LoginAPI';
import { loginFormSchema, LoginResponseDTO } from './data/LoginFormDTO';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Content = styled.div`
  width: 434px;
  max-width: 434px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -42px;
    left: -64px;
    right: -64px;
    height: 140px;
    background: url(${backgroundCircles});
    background-size: 20px 20px;
  }
`;

interface LoginFormProps {
  title: string;
  waiver: ReactNode;
  showSSOButton: boolean;
  onLoginSSOClick?: () => void;
}

export function LoginForm({
  title,
  waiver,
  showSSOButton,
  onLoginSSOClick,
}: LoginFormProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = location.search;
  const { data: smsLogin } = useFeatureTogglePublic(
    'frontend.two-factor-authentication.sms.enabled',
  );
  const isSMSVerificationEnabled = Boolean(smsLogin?.is_enabled);
  const { login } = useAuthAPI();
  const [{ errorDescription: error }] = useQuery();
  const { saveVerification } = useVerification();

  const form = useAppFormik({
    enableReinitialize: false,
    validationSchema: loginFormSchema,
    onSubmit: (values) =>
      login({ ...values, device_guid: getDeviceGuid(values.username) }),
    onSubmitSuccess(response: LoginResponseDTO) {
      writeIsSubscriptionPaymentFailedAlertClosed(false);

      if (response.token) {
        setAppToken(response.token);
      } else if (response.email) {
        saveVerification({
          username: form.values.username,
          email: response.email,
          phoneNumber: response.phone_number,
          attemptSessionGuid: response.attempt_session_guid,
          channel:
            response.mfa_channel === 'sms' &&
            !!response.phone_number &&
            isSMSVerificationEnabled
              ? 'sms'
              : 'email',
        });
        navigate({
          pathname: '/signin/verify',
          search: searchParams,
        });
      }
    },
    getErrorMessage(loginError: Error) {
      if (
        loginError.name === 'TypeError' &&
        loginError.message === 'Failed to fetch'
      ) {
        return 'You have exceeded the maximum number of login attempts per minute. Please retry later.';
      }
      return loginError.message;
    },
  });

  return (
    <Wrapper>
      <Content>
        <Stack space="small">
          <Paper>
            <FormikProvider value={form}>
              <Form autoComplete="off">
                <Box padding="medium">
                  <Stack space="large">
                    <Typography variant="h1" align="center">
                      {title}
                    </Typography>

                    <Stack space="small">
                      {error && (
                        <Alert
                          variant="outlined"
                          severity="error"
                          icon={<ErrorOutlined />}
                        >
                          <Typography color="error">{error}</Typography>
                        </Alert>
                      )}

                      <FormikTextField
                        fullWidth={true}
                        autoFocus={true}
                        name="username"
                        label="Username"
                        autoComplete="off"
                      />

                      <FormikPasswordField
                        fullWidth={true}
                        name="password"
                        label="Password"
                      />
                    </Stack>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                      isLoading={form.isSubmitting}
                    >
                      Log In
                    </Button>

                    {showSSOButton && (
                      <>
                        <Typography
                          color="textSecondary"
                          variant="h6"
                          align="center"
                        >
                          or Log In with
                        </Typography>

                        <Button
                          size="large"
                          fullWidth={true}
                          startIcon={<Lock color="primary" />}
                          onClick={() => {
                            onLoginSSOClick?.();
                          }}
                        >
                          Single Sign-On
                        </Button>
                      </>
                    )}

                    {getAppType() === 'broker' && (
                      <Inline horizontalAlign="center">
                        <Link color="primary" href="/forgot-password">
                          Forgot password?
                        </Link>
                      </Inline>
                    )}
                  </Stack>
                </Box>
              </Form>
            </FormikProvider>
          </Paper>

          <Box paddingRight="large" paddingLeft="large">
            {waiver}
          </Box>
        </Stack>
      </Content>
    </Wrapper>
  );
}
