import { useAPI } from 'shared/api/API';
import { valuesToPlain } from 'shared/utils/DataUtils';
import { OrderFieldsDTO } from './OrderFieldsDTO';

export function useOrderFieldsAPI() {
  const { request } = useAPI();

  return {
    updateFields: (values: OrderFieldsDTO) =>
      request('PUT /internal/shippers/order_form_settings', {
        json: {
          ...valuesToPlain(OrderFieldsDTO, values),
        },
      }),
  };
}
