import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function BOLIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H8zm4.89 5.351c.25-.225.59-.351.943-.351h1.334c.353 0 .692.126.943.351.25.226.39.53.39.849v3.6c0 .318-.14.623-.39.848-.25.226-.59.352-.943.352h-1.334c-.353 0-.692-.126-.943-.351-.25-.225-.39-.53-.39-.849V8.2c0-.318.14-.623.39-.849zm2.277.849h-1.334v3.6h1.334V8.2zm-3.667.9v-.9c0-.318-.14-.623-.39-.849A1.412 1.412 0 0010.167 7H7.5v6h2.667c.353 0 .692-.126.943-.351.25-.225.39-.53.39-.849v-.9c0-.48-.467-.9-1-.9.533 0 1-.42 1-.9zm-1.333 2.7H8.833v-1.2h1.334v1.2zm0-2.4H8.833V8.2h1.334v1.2zM21.1 13h-3.6V7h1.2v4.8h2.4V13z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
