import { CircularProgress } from '@material-ui/core';
import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { useEffect, useMemo } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import {
  useMoovAccount,
  useRepresentativesList,
  useSuperPaySettingsCache,
} from 'superpay/data/SuperPayAPI';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { SuperPayOnboardingPageLayout } from 'superpay/SuperPayOnboardingPageLayout';
import { OwnerAddInitialForm } from './OwnerAddInitialForm';
import { OwnersListContainer } from './OwnersListContainer';

export function OwnersListPage() {
  const { data: representatives, refetch, error } = useRepresentativesList();
  const { data: moovAccount } = useMoovAccount(!!representatives); // get moov account only after representatives are loaded
  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();
  const { logPaymentInfo } = usePaymentLogger();
  const businessType = moovAccount?.profile.business?.businessType;
  const { addSnackbar } = useSnackbarStack();

  const stepNumber = useMemo(
    () => (representatives && representatives.length > 0 ? 3 : 2),
    [representatives],
  );

  useEffect(() => {
    trackEvent('Shipper Opened Representatives');
    logPaymentInfo('Opened Representatives List', 'RepresentativesList');
  }, [logPaymentInfo]);

  useEffect(() => {
    if (error) {
      addSnackbar((error as Error).message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  return (
    <SuperPayOnboardingPageLayout stepNumber={stepNumber}>
      {!representatives ? (
        <Inline horizontalAlign="center">
          <CircularProgress />
        </Inline>
      ) : representatives.length > 0 ? (
        <OwnersListContainer
          owners={representatives}
          refetchOwnersList={refetch}
        />
      ) : (
        <OwnerAddInitialForm
          onSubmitSuccess={() => {
            if (
              businessType === 'publicCorporation' ||
              businessType === 'soleProprietorship'
            ) {
              invalidateSuperPaySettings();
            } else {
              void refetch();
            }
          }}
        />
      )}
    </SuperPayOnboardingPageLayout>
  );
}
