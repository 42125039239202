import { Link, MenuItem, Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { InfoCard, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useCallback, useMemo } from 'react';
import {
  CarrierBrokerStatus,
  CarrierFullInfo,
  CarrierStatus,
} from 'shared/types/carrier';
import { useCanExecute } from '../../shared/data/UserPermissions';
import { trackUpdatedCarrierStatus } from '../core/CarrierProfilePageAnalytics';
import { useCarrierProfileAPI } from '../data/ManageCarriersAPI';
import { NetworkDrawerCarrierStatus } from '../list/NetworkDrawerCarrierStatus';

const carrierStatusOptions = [
  {
    label: 'Blocklisted',
    value: 'IN_BLACKLIST',
  },
  {
    label: 'Default',
    value: 'DEFAULT',
  },
];

interface CarrierInternalRecordsProps {
  carrier: CarrierFullInfo;
}

export function CarrierInternalRecordsBasicTier({
  carrier: { broker_records, guid },
}: CarrierInternalRecordsProps) {
  const { addSnackbar } = useSnackbarStack();

  const canUserUpdateRecords = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  const renderCarrierStatusLabel = useCallback(
    (value: unknown) => (
      <NetworkDrawerCarrierStatus
        value={value}
        brokerRecords={broker_records || null}
      />
    ),
    [broker_records],
  );

  const carrierStatus: CarrierStatus = broker_records.in_blacklist
    ? 'IN_BLACKLIST'
    : 'DEFAULT';

  const initialValues = useMemo(
    () => ({
      status: carrierStatus,
    }),
    [broker_records],
  );

  const { updateBrokerStatus } = useCarrierProfileAPI();

  const formik = useFormikEnhanced<CarrierBrokerStatus, unknown>({
    initialValues,
    onSubmit: async ({ status }) => {
      if (!broker_records) {
        return Promise.reject(new Error('Carrier Profile is missing'));
      }
      return updateBrokerStatus(guid, {
        status,
      });
    },
    onSubmitSuccess: (_, values) => {
      trackUpdatedCarrierStatus(initialValues, values);
      addSnackbar('Successfully updated carrier status', {
        variant: 'success',
      });
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to updated carrier status', {
        variant: 'error',
      });
    },
  });

  return (
    <InfoCard size="large">
      <Stack space="small">
        <Typography variant="h3">Carrier Status</Typography>

        <FormikProvider value={formik}>
          <Form>
            <Stack space="xsmall">
              <FormikTextField
                name="status"
                fullWidth={true}
                select={true}
                disabled={!canUserUpdateRecords}
                SelectProps={{
                  renderValue: renderCarrierStatusLabel,
                }}
              >
                {carrierStatusOptions.map(({ label, value }, idx) => (
                  <MenuItem key={idx} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FormikTextField>

              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.superdispatch.com/en/articles/4165914-approved-carrier-status-for-carriers"
              >
                Learn More about Carrier Status
              </Link>
              {canUserUpdateRecords && (
                <Button type="submit" disabled={!guid || !formik.dirty}>
                  Save
                </Button>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Stack>
    </InfoCard>
  );
}
