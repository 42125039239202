import { SidebarContent } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { CustomersImport } from './core/CustomersImport';
import { useCustomersNavigation } from './CustomersRouteHelpers';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 96px;
`;

export function CustomersImportPage() {
  const { navigateToCustomers } = useCustomersNavigation();

  return (
    <SidebarContent
      title="Import Customers"
      openOnMount={true}
      onBack={() => {
        navigateToCustomers();
      }}
    >
      <Wrapper>
        <CustomersImport />
      </Wrapper>
    </SidebarContent>
  );
}
