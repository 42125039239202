import { CircularProgress } from '@material-ui/core';
import { DrawerContent, DrawerTitle, Inline, Stack } from '@superdispatch/ui';
import { usePriceNegotiationByGuid } from 'orders/data/PriceNegotiationAPI';
import { Order } from 'shared/types/order';
import { OrderActionSource } from '../OrderActionsAnalytics';
import { CreatePriceNegotiationDrawerForm } from './CreatePriceNegotiationDrawerForm';
import { UpdatePriceNegotiationDrawerForm } from './UpdatePriceNegotiationDrawerForm';

export interface PriceNegotiationDrawerContentProps {
  order: Order;
  source: OrderActionSource;
  onSubmitSuccess: (response: Order) => void;
}

export function PriceNegotiationDrawerContent({
  order,
  source,
  onSubmitSuccess,
}: PriceNegotiationDrawerContentProps) {
  const { isLoading, data } = usePriceNegotiationByGuid(order.guid);

  if (isLoading) {
    return (
      <Stack>
        <DrawerTitle title="Price Negotiation" />
        <DrawerContent>
          <Inline horizontalAlign="center" verticalAlign="center">
            <CircularProgress />
          </Inline>
        </DrawerContent>
      </Stack>
    );
  }

  return data ? (
    <UpdatePriceNegotiationDrawerForm
      order={order}
      source={source}
      priceNegotiation={data}
      onSubmitSuccess={onSubmitSuccess}
    />
  ) : (
    <CreatePriceNegotiationDrawerForm
      order={order}
      source={source}
      onSubmitSuccess={onSubmitSuccess}
    />
  );
}
