import { Link } from '@material-ui/core';
import { Info, Warning } from '@material-ui/icons';
import { FormattedDate, NullableDateString } from '@superdispatch/dates';
import { Color, Column, Columns } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import {
  expiresIn,
  isCarrierInsuranceExpired,
  isCarrierInsuranceExpiresIn14Days,
} from 'core/insurance/ExpirationDates';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { getCurrentSource } from 'shared/helpers/SourceManagmentLegacy';

interface CertificateExpirationProps {
  insuranceExpiresAt: NullableDateString;
  certificateFileURl: string | null;
}

export function CertificateExpirationDetails({
  insuranceExpiresAt,
  certificateFileURl,
}: CertificateExpirationProps) {
  return isCarrierInsuranceExpired(insuranceExpiresAt) ? (
    <CertificateExpirationAlert
      insuranceExpiresAt={insuranceExpiresAt}
      certificateFileURl={certificateFileURl}
    />
  ) : isCarrierInsuranceExpiresIn14Days(insuranceExpiresAt) ? (
    <CertificateExpirationWarning
      insuranceExpiresAt={insuranceExpiresAt}
      certificateFileURl={certificateFileURl}
    />
  ) : (
    <CertificateExpirationInfo
      insuranceExpiresAt={insuranceExpiresAt}
      certificateFileURl={certificateFileURl}
    />
  );
}

export function CertificateExpirationInfo({
  insuranceExpiresAt,
  certificateFileURl,
}: CertificateExpirationProps) {
  const [medium, content] = getCurrentSource() ?? [];
  return (
    <Box
      aria-label="Cargo insurance expiration info"
      padding="xsmall"
      borderRadius="small"
      backgroundColor="Blue50"
    >
      <Columns space="xxsmall" align="center">
        <Column width="content">
          <Info fontSize="small" htmlColor={Color.Blue500} />
        </Column>
        <Column width="adaptive">
          <TextBox>
            {certificateFileURl ? (
              <Link
                target="_blank"
                rel="noreferrer"
                href={certificateFileURl}
                onClick={() => {
                  trackEvent('Shipper Clicked Carrier’s Cargo Insurance', {
                    utm_medium: medium,
                    utm_content: content,
                  });
                }}
              >
                Cargo insurance
              </Link>
            ) : (
              <>Cargo insurance</>
            )}{' '}
            expires on{' '}
            <FormattedDate
              variant="Date"
              format="DateISO"
              date={insuranceExpiresAt}
            />
            .
          </TextBox>
        </Column>
      </Columns>
    </Box>
  );
}

export function CertificateExpirationWarning({
  insuranceExpiresAt,
  certificateFileURl,
}: CertificateExpirationProps) {
  const [medium, content] = getCurrentSource() ?? [];
  const daysLeft = expiresIn(insuranceExpiresAt);
  const formattedDaysLeft = formatPlural(
    daysLeft,
    'tomorrow',
    `in ${daysLeft}d`,
  );

  return (
    <Box
      aria-label="Cargo insurance expiration info"
      padding="xsmall"
      borderRadius="small"
      backgroundColor="Yellow50"
    >
      <Columns space="xxsmall" align="center">
        <Column width="content">
          <Warning fontSize="small" htmlColor={Color.Yellow500} />
        </Column>
        <Column width="adaptive">
          <TextBox>
            {certificateFileURl ? (
              <Link
                target="_blank"
                rel="noreferrer"
                href={certificateFileURl}
                onClick={() => {
                  trackEvent('Shipper Clicked Carrier’s Cargo Insurance', {
                    utm_medium: medium,
                    utm_content: content,
                  });
                }}
              >
                Cargo insurance
              </Link>
            ) : (
              <>Cargo insurance</>
            )}{' '}
            expires {formattedDaysLeft}, on{' '}
            <FormattedDate
              variant="Date"
              format="DateISO"
              date={insuranceExpiresAt}
            />
            .
          </TextBox>
        </Column>
      </Columns>
    </Box>
  );
}

export function CertificateExpirationAlert({
  insuranceExpiresAt,
  certificateFileURl,
}: CertificateExpirationProps) {
  const [medium, content] = getCurrentSource() ?? [];
  return (
    <Box
      aria-label="Cargo insurance expiration info"
      padding="xsmall"
      borderRadius="small"
      color="Red500"
      backgroundColor="Red50"
    >
      <Columns space="xxsmall" align="center">
        <Column width="content">
          <Info fontSize="small" />
        </Column>
        <Column width="adaptive">
          <TextBox color="inherit">
            {certificateFileURl ? (
              <Link
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href={certificateFileURl}
                onClick={() => {
                  trackEvent('Shipper Clicked Carrier’s Cargo Insurance', {
                    utm_medium: medium,
                    utm_content: content,
                  });
                }}
              >
                Cargo insurance
              </Link>
            ) : (
              <>Cargo insurance</>
            )}{' '}
            expired on{' '}
            <FormattedDate
              variant="Date"
              format="DateISO"
              date={insuranceExpiresAt}
            />
            .
          </TextBox>
        </Column>
      </Columns>
    </Box>
  );
}
