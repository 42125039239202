import { Typography } from '@material-ui/core';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  DrawerActions,
  ExitTransitionPlaceholder,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { FormikCheckboxArrayGroup } from 'shared/form/FormikCheckboxArray';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { CarrierBrokerPreferences } from 'shared/types/carrier';
import { Order } from 'shared/types/order';
import { CarrierRatingTagField } from './CarrierRatingDialog';
import { trackRatingSubmitEvent } from './data/CarrierRatingAnalytics';
import { useCarrierRatingAPI } from './data/CarrierRatingAPI';
import { RateCarrierPayloadDTO } from './data/CarrierRatingDTO';

interface CarrierRatingDrawerContentProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
  carrierGuid: string;
  carrierName: string | null | undefined;
}

function CarrierRatingDrawerContent({
  carrierGuid,
  carrierName,
  onClose,
  onSubmitSuccess,
}: CarrierRatingDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();

  const { rateCarrier } = useCarrierRatingAPI();

  const formik = useFormikEnhanced<
    RateCarrierPayloadDTO,
    CarrierBrokerPreferences
  >({
    initialValues: {
      rating: 'POSITIVE',
      comment: null,
      tags: [],
    },
    onSubmit: (values) => rateCarrier(carrierGuid, values),
    onSubmitSuccess(_, values) {
      trackRatingSubmitEvent(
        values,
        'What Went Well Drawer after Mark as Paid',
      );
      addSnackbar('Rating submitted', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
    validate: (values) => {
      if (values.tags.length < 1) {
        return { tags: 'To submit, please select what went well.' };
      }
      return {};
    },
  });

  const {
    values: { rating },
  } = formik;

  const hasTagsError = formik.touched.tags && formik.errors.tags;

  return (
    <FormikDrawerContent
      formik={formik}
      title={
        <>
          What went well with{' '}
          <Typography color="textSecondary" variant="inherit">
            {carrierName}
          </Typography>
          ?
        </>
      }
      actions={
        <DrawerActions>
          <Inline space="small">
            <Button
              disabled={!rating}
              variant="primary"
              pending={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              Submit Rating
            </Button>
            <Button variant="neutral" onClick={onClose}>
              Skip
            </Button>
          </Inline>
        </DrawerActions>
      }
    >
      <Stack space="small">
        {hasTagsError && (
          <TextBox color="red">
            To submit, please select what went well.
          </TextBox>
        )}

        <FormikCheckboxArrayGroup name="tags">
          <Stack space="none">
            <CarrierRatingTagField tag="ON_TIME_TRANSPORT" />
            <CarrierRatingTagField tag="GOOD_COMMUNICATION" />
            <CarrierRatingTagField tag="GOOD_CUSTOMER_SERVICE" />
          </Stack>
        </FormikCheckboxArrayGroup>

        <FormikMaxLengthTextField
          minRows={3}
          maxLength={280}
          name="comment"
          multiline={true}
          fullWidth={true}
          helperText="Publicly visible."
          label={<TextBox color="secondary">Tell more about it</TextBox>}
          placeholder="Driver was nice, demonstrated experience…"
        />
        <Typography color="textSecondary">
          Click &quot;Skip&quot;, to continue without giving a positive rating.
        </Typography>
      </Stack>
    </FormikDrawerContent>
  );
}

interface CarrierRatingDrawerProps
  extends Omit<CarrierRatingDrawerContentProps, 'carrierGuid' | 'carrierName'> {
  order: Order | undefined;
}

export function CarrierRatingDrawer({
  order,
  onClose,
  ...props
}: CarrierRatingDrawerProps) {
  const carrierGuid = order?.active_offer?.carrier_guid;
  const carrierName = order?.active_offer?.carrier_name;
  return (
    <FormikDrawer open={!!carrierGuid} onClose={onClose} confirmClose={true}>
      <ExitTransitionPlaceholder in={!!carrierGuid}>
        {!!carrierGuid && (
          <CarrierRatingDrawerContent
            {...props}
            carrierGuid={carrierGuid}
            carrierName={carrierName}
            onClose={onClose}
          />
        )}
      </ExitTransitionPlaceholder>
    </FormikDrawer>
  );
}
