import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';

const brokerStatuses = [
  'new',
  'canceled',
  'on_hold',
  'posted_to_lbs',
  'pending',
  'declined',
  'accepted',
  'picked_up',
  'delivered',
  'invoiced',
  'delivery_verified',
  'paid',
  'order_canceled',
];

const customerStatuses = [
  'new',
  'submitted',
  'scheduled',
  'picked_up',
  'delivered',
  'completed',
  'order_canceled',
];

export function useReportsStatuses() {
  const { user } = useUserState();

  return useMemo(() => {
    if (user?.shipper.shipper_type === 'CUSTOMER') {
      return customerStatuses;
    }

    if (!user?.shipper.had_superpay_access) {
      return brokerStatuses.filter((status) => status !== 'delivery_verified');
    }
    return brokerStatuses;
  }, [user]);
}

export const paymentStatuses = [
  'CHARGE_SCHEDULED',
  'SUPER_PAY_CHARGED',
  'PAYMENT_INITIATED',
  'PAYMENT_ON_HOLD',
  'CANCELED',
  'FAILED',
] as const;

export const getPaymentStatus = (
  status: typeof paymentStatuses[number],
): string => {
  switch (status) {
    case 'CHARGE_SCHEDULED':
      return 'Charge Scheduled';
    case 'SUPER_PAY_CHARGED':
      return 'SuperPay Charged';
    case 'PAYMENT_INITIATED':
      return 'Payment Initiated';
    case 'PAYMENT_ON_HOLD':
      return 'Payment On Hold';
    case 'CANCELED':
      return 'SuperPay Canceled';
    case 'FAILED':
      return 'Payment Failed';
  }
};
