import { Add } from '@material-ui/icons';
import { PaymentMethod } from '@superdispatch/sdk';
import { Button, Inline, Stack } from '@superdispatch/ui';
import { FieldArray, useFormikContext } from 'formik';
import { useRelatedLoads } from 'orders/data/OrderAPI';
import { useMemo, useState } from 'react';
import { AllPaymentTerm } from 'shared/dto/Order/CarrierPaymentDTO';
import Order from 'shared/types/order';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';
import { generateLoad } from './BuildLoadsDrawerUtils';
import { LoadForm } from './LoadForm';

interface ChooseRouteStepProps {
  paymentDefaultTerm: AllPaymentTerm;
  paymentDefaultMethod: PaymentMethod;
  order?: Order;
}

export function ChooseRouteStep({
  order,
  paymentDefaultTerm,
  paymentDefaultMethod,
}: ChooseRouteStepProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [indexOfLoadToRemove, setIndexOfLoadToRemove] = useState(-1);
  const { values } = useFormikContext<BuildLoadsFormValues>();

  const { data: { objects: relatedLoads = [] } = {} } = useRelatedLoads(
    order?.id,
    !!order?.id,
  );

  const loadNumbers = useMemo(() => {
    return [
      ...relatedLoads.map(({ number }) => number),
      ...values.loads.map(({ number = '' }) => number),
    ];
  }, [relatedLoads, values]);

  return (
    <FieldArray name="loads">
      {(arrayHelpers) => {
        return (
          <Stack space="large">
            <Stack space="xxsmall">
              {values.loads.map((load, index) => (
                <LoadForm
                  order={order}
                  index={index}
                  key={load.number}
                  onRemove={(indexToRemove: number) => {
                    setIndexOfLoadToRemove(indexToRemove);
                    setShowConfirmation(true);
                  }}
                />
              ))}
            </Stack>

            <Button
              aria-label="add another load"
              variant="text"
              size="small"
              onClick={() => {
                const lastDeliveryVenue =
                  values.loads[values.loads.length - 1]?.delivery?.venue;

                arrayHelpers.push(
                  generateLoad({
                    order,
                    loadNumbers,
                    pickupVenue: lastDeliveryVenue,
                    paymentDefaultTerm,
                    paymentDefaultMethod,
                  }),
                );
              }}
            >
              <Inline verticalAlign="center">
                <Add fontSize="small" />
                Add Another Load
              </Inline>
            </Button>

            <ConfirmDialog
              open={showConfirmation}
              onClose={() => {
                setShowConfirmation(false);
              }}
              title="Delete load?"
              cancelButtonProps={{ children: 'No' }}
              confirmButtonProps={{
                onClick: () => {
                  arrayHelpers.remove(indexOfLoadToRemove);
                  setShowConfirmation(false);
                  setIndexOfLoadToRemove(-1);
                },
                children: 'Yes, Delete',
                color: 'primary',
              }}
            />
          </Stack>
        );
      }}
    </FieldArray>
  );
}
