import { Inline } from '@superdispatch/ui';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { OrderTagDTO, useOrderTags } from './data/OrdersTagAPI';
import { OrderTag } from './OrderTag';

export function useOrderTagsUtils() {
  const { data: { objects = [] } = {} } = useOrderTags();

  function ensureTag(option: string | OrderTagDTO): OrderTagDTO | undefined {
    if (typeof option === 'string') {
      const tagId = Number(option);
      return objects.find((x) => x.id === tagId);
    }

    return option;
  }

  function formatOrderTagLabel(option: string | OrderTagDTO): string {
    const tag = ensureTag(option);
    return tag ? tag.name : '';
  }

  return { ensureTag, formatOrderTagLabel };
}
export interface OrderTagAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<string | OrderTagDTO>,
    'options' | 'onChange'
  > {
  onChange: (values: string[]) => void;
}

export function OrderTagsAutocomplete({
  onChange,
  ...props
}: OrderTagAutocompleteProps) {
  const { isLoading, data: { objects = [] } = {} } = useOrderTags();
  const { ensureTag, formatOrderTagLabel } = useOrderTagsUtils();

  function formatValue(option: string | OrderTagDTO): number {
    return typeof option === 'string' ? Number(option) : option.id;
  }

  return (
    <AutocompleteBase
      {...props}
      options={objects}
      loading={isLoading}
      getOptionLabel={(option) => formatOrderTagLabel(option)}
      getOptionSelected={(option, selectedValue) => {
        return formatValue(option) === formatValue(selectedValue);
      }}
      onChange={(_, option) => {
        onChange(option.map((x) => String(formatValue(x))));
      }}
      renderTags={(tags, getTagProps) => (
        <Inline space="xxsmall">
          {tags.map((tag, index: number) => (
            <OrderTag
              key={index}
              tagId={formatValue(tag)}
              hideIcon={true}
              {...getTagProps({ index })}
            />
          ))}
        </Inline>
      )}
      renderOption={(option) => {
        return <OrderTag tagId={formatValue(option)} hideIcon={true} />;
      }}
      filterOptions={(results, { inputValue }) =>
        results.filter(
          (x) =>
            ensureTag(x)?.is_active &&
            formatOrderTagLabel(x)
              .toLowerCase()
              .includes(inputValue.trim().toLowerCase()),
        )
      }
    />
  );
}
