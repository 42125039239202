import { startCase } from 'lodash-es';
import { useUserValue } from 'shared/data/UserPermissions';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';

const brokerStatuses = [
  'new',
  'canceled',
  'on_hold',
  'posted_to_sdlb',
  'posted_to_cd',
  'pending',
  'declined',
  'accepted',
  'picked_up',
  'delivered',
  'invoiced',
  'paid',
  'order_canceled',
];

const customerStatuses = [
  'new',
  'submitted',
  'scheduled',
  'picked_up',
  'delivered',
  'completed',
  'order_canceled',
];

export function formatStatus(status: string) {
  switch (status) {
    case 'canceled':
      return 'Offer Canceled';
    case 'posted_to_sdlb':
      return 'Posted to SLB';
    case 'posted_to_cd':
      return 'Posted to CD';
    case 'posted_to_lbs':
      return 'Posted to Load Boards';
    case 'order_canceled':
      return 'Canceled';
    case 'delivery_verified':
      return 'Delivery Verified';
    default:
      return startCase(status);
  }
}

interface StatusAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<string>,
    'options' | 'onChange' | 'onSelect'
  > {
  onChange: (values: string[]) => void;
}

export function StatusAutocomplete({
  value,
  onChange,
  onClose,
}: StatusAutocompleteProps) {
  const options = useUserValue(brokerStatuses, customerStatuses);

  return (
    <AutocompleteBase
      value={value}
      onClose={onClose}
      options={options}
      onChange={(_, option) => onChange(option)}
      getOptionLabel={(option: string) => formatStatus(option)}
      filterOptions={(results, { inputValue }) =>
        results.filter((x) =>
          x.toLowerCase().includes(inputValue.trim().toLowerCase()),
        )
      }
    />
  );
}
