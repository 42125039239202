import { User } from 'shared/types/user';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { useInternalUsers } from './data/DictionaryAPI';

function formatOption(option: string | User) {
  return typeof option === 'string'
    ? option
    : `${option.first_name || ''} ${option.last_name || ''}`.trim();
}

interface InternalUsersAutocompleteProps
  extends Omit<AutocompleteBaseProps<string | User>, 'options' | 'onChange'> {
  onChange: (values: string[]) => void;
}

export function InternalUsersAutocomplete({
  onChange,
  ...props
}: InternalUsersAutocompleteProps) {
  const { isLoading, data: { objects = [] } = {} } = useInternalUsers();

  return (
    <AutocompleteBase
      {...props}
      options={objects}
      loading={isLoading}
      getOptionLabel={(option) => formatOption(option)}
      onChange={(_, value) => onChange(value.map(formatOption))}
      filterOptions={(options, { inputValue }) =>
        options.filter((x) =>
          formatOption(x)
            .toLowerCase()
            .includes(inputValue.trim().toLowerCase()),
        )
      }
    />
  );
}
