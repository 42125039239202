export const FIREBASE_CONFIG =
  import.meta.env.VITE_TARGET === 'production'
    ? {
        apiKey: 'AIzaSyCW0Z6W4AIvuYtPnP0L3Yc60y014Y_vemg',
        authDomain: 'thematic-flash-786.firebaseapp.com',
        databaseURL: 'https://thematic-flash-786.firebaseio.com',
        projectId: 'thematic-flash-786',
        appId: '1:909133020380:web:74c6dd14aa9cb78b058819',
        storageBucket: 'thematic-flash-786.appspot.com',
        messagingSenderId: '909133020380',
        measurementId: 'G-DSTPJFF225',
      }
    : {
        apiKey: 'AIzaSyCW0Z6W4AIvuYtPnP0L3Yc60y014Y_vemg',
        authDomain: 'thematic-flash-786.firebaseapp.com',
        databaseURL: 'https://thematic-flash-786.firebaseio.com',
        projectId: 'thematic-flash-786',
        appId: '1:909133020380:web:b618f5d961dcf82e058819',
        storageBucket: 'thematic-flash-786.appspot.com',
        messagingSenderId: '909133020380',
        measurementId: 'G-BVVPBPPE96',
      };
