import { Box, Collapse, Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';
import { useSet } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';

const verticalGap = 16;
const bulletSize = 9;
const bulletMargin = 6;
const bulletTopOffset = 5;
const guideWidth = 1;

const TimelineItemContainer = styled.div`
  position: relative;
`;

const TimeLineTriangle = styled.div<{
  isHighlighted?: boolean;
  isExpanded?: boolean;
}>`
  width: 0;
  height: 0;
  border-style: solid;
  ${({ isHighlighted, isExpanded }) => {
    const color = isHighlighted ? Color.Blue300 : Color.Dark100;
    const topOffset = isExpanded ? 2 : 0;
    const rotate = isExpanded ? '90deg' : '0deg';
    return {
      borderColor: `transparent transparent transparent ${color}`,
      transform: `rotate(${rotate})`,
      left: `${(bulletSize - guideWidth) / 2 + 18}px`,
      top: `${verticalGap + bulletTopOffset + topOffset}px`,
    };
  }}
  border-width: 5.5px 0 5.5px 6px;
  position: absolute;
  cursor: pointer;
`;

const TimelineItemBullet = styled.div<{
  isHighlighted?: boolean;
}>`
  position: absolute;
  top: ${verticalGap + bulletTopOffset}px;
  left: ${(bulletSize - guideWidth) / 2 + 16}px;
  width: ${bulletSize}px;
  height: ${bulletSize}px;
  background: ${({ isHighlighted }) =>
    isHighlighted ? Color.Blue300 : Color.Silver400};
  border-radius: 50%;
`;

const TimelineItemGuide = styled.div<{
  isHighlighted?: boolean;
}>`
  position: absolute;
  top: ${verticalGap + bulletSize + bulletMargin + bulletTopOffset}px;
  left: ${bulletSize - guideWidth + 16}px;
  bottom: -${verticalGap + bulletTopOffset - bulletMargin}px;
  border-left: 1px solid
    ${({ isHighlighted }) => (isHighlighted ? Color.Blue300 : Color.Silver400)};
  z-index: 1;
`;

const TimelineItemContent = styled.div`
  padding: ${verticalGap}px 0 6px 0;
`;

const branchVerticalGap = 4;
const branchBulletOffset = 6;

const TimelineBranchItemWrapper = styled.div`
  position: relative;
  padding-left: ${(guideWidth / 2 + 19) * 2}px;
`;

const TimelineBranchItemBullet = styled.div`
  position: absolute;
  top: ${branchVerticalGap + branchBulletOffset}px;
  left: 40px;
  width: 11px;
  height: 11px;

  border: 1px solid ${Color.Silver400};
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-left-radius: 3px;
`;

const TimelineBranchItemGuide = styled.div`
  position: absolute;
  top: ${branchVerticalGap}px;
  left: 40px;
  bottom: -${branchVerticalGap + branchBulletOffset + 1}px;
  border-left: 1px solid ${Color.Silver400};
`;

const TimelineBranchItemContent = styled.div`
  padding: ${branchVerticalGap}px 0 8px 20px;
`;

export const TimelineBranch = styled.div`
  display: flex;
  flex-direction: column;

  ${TimelineBranchItemWrapper}:last-child ${TimelineBranchItemGuide} {
    display: none;
  }
`;

const TimelineItemHeading = styled.div<{
  isExpandable?: boolean;
}>`
  cursor: ${({ isExpandable }) => (isExpandable ? 'pointer' : undefined)};
  transition: all 0.3s linear;
  padding-right: 16px;

  &:hover {
    background: ${({ isExpandable }) =>
      isExpandable ? Color.Silver100 : undefined};
  }
`;

export const TimelineItemPadded = styled.div`
  padding-left: ${((bulletSize - guideWidth) / 2 + 19) * 2}px;
`;

export const TimelineCardHeading = styled.div`
  padding: ${(bulletSize - guideWidth) / 2 + 16}px
    ${(bulletSize - guideWidth) / 2 + 16}px 0;
`;

const TimelineItemCollapsedContent = styled.div`
  padding-top: 16px;
`;

interface TimelineBranchItemProps {
  children: ReactNode;
}

export function TimelineBranchItem({ children }: TimelineBranchItemProps) {
  return (
    <TimelineBranchItemWrapper>
      <TimelineBranchItemGuide />
      <TimelineBranchItemBullet />

      <TimelineBranchItemContent>{children}</TimelineBranchItemContent>
    </TimelineBranchItemWrapper>
  );
}

interface TimelineItemProps {
  title: ReactNode;
  subtitle: ReactNode;
  children?: ReactNode;

  isExpanded?: boolean;
  isExpandable?: boolean;
  expandContent?: ReactNode;

  onExpand?: (isExpanded: boolean) => void;
  onClick?: () => void;

  active?: boolean;
}

export function TimelineItem({
  title,
  subtitle,
  children,

  isExpanded,
  isExpandable,
  expandContent,
  onClick,

  active,
}: TimelineItemProps) {
  return (
    <TimelineItemContainer aria-label="timeline item">
      {isExpandable ? (
        <TimeLineTriangle
          onClick={onClick}
          isExpanded={isExpanded}
          isHighlighted={active}
        />
      ) : (
        <TimelineItemBullet onClick={onClick} isHighlighted={active} />
      )}

      <TimelineItemGuide isHighlighted={active} />

      <TimelineItemContent>
        <Stack space="none">
          <TimelineItemHeading onClick={onClick} isExpandable={isExpandable}>
            <Box display="flex" alignItems="center">
              <TimelineItemPadded>
                <Typography
                  variant="body1"
                  component="div"
                  aria-label="timeline title"
                >
                  {title}
                </Typography>
              </TimelineItemPadded>
            </Box>
          </TimelineItemHeading>
          <Box>
            <Box
              color={Color.Dark200}
              fontSize="12px"
              aria-label="timeline subtitle"
            >
              {subtitle}
            </Box>

            {children && <Box paddingTop="8px">{children}</Box>}

            {isExpandable && (
              <Collapse in={isExpanded}>
                <TimelineItemCollapsedContent>
                  <Stack space="small">{expandContent}</Stack>
                </TimelineItemCollapsedContent>
              </Collapse>
            )}
          </Box>
        </Stack>
      </TimelineItemContent>
    </TimelineItemContainer>
  );
}

const TimelineRoot = styled.div`
  display: flex;
  flex-direction: column;

  ${TimelineItemContainer}:last-child ${TimelineItemGuide} {
    display: none;
  }
`;

interface TimelineProps {
  activeIndex?: number;
  isActive?: (index: number) => boolean;
  children: ReactNode;
}

export function Timeline({
  children,
  isActive,
  activeIndex = -1,
}: TimelineProps) {
  const [expanded, addExpanded, removeExpanded] = useSet<number>();

  const elements = Array.isArray(children)
    ? children
    : Children.toArray(children);

  return (
    <TimelineRoot aria-label="timeline">
      {elements.map((element, index) => {
        if (!isValidElement<TimelineItemProps>(element)) {
          return null;
        }

        const isExpanded = expanded.has(index);

        return cloneElement(element, {
          isExpanded,
          active: isActive?.(index) || index <= activeIndex,
          key: element.key || index,
          onClick: () => {
            if (expanded.has(index)) {
              removeExpanded(index);
              element.props.onExpand?.(false);
            } else {
              addExpanded(index);
              element.props.onExpand?.(true);
            }
          },
        });
      })}
    </TimelineRoot>
  );
}
