import { Typography } from '@material-ui/core';
import { Lock, MonetizationOn } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem } from '@superdispatch/ui-lab';
import { FormikRoutingNumberField } from 'superpay/helpers/FormikRoutingNumberField';
import routingAccountIllustration from './assets/routing_account.png';

export function BankAccountForm() {
  return (
    <>
      <Stack space="small">
        <Stack space="xsmall">
          <Typography variant="h3">Bank Account</Typography>
          <DescriptionItem
            icon={<Lock />}
            label="Your personal data is encrypted and private."
            wrap={true}
          />
          <DescriptionItem
            icon={<MonetizationOn />}
            label="We will make three transactions into your bank account to verify it."
            wrap={true}
          />
        </Stack>

        <FormikTextField
          label="Account Holder Name"
          name="holderName"
          fullWidth={true}
        />

        <FormikRoutingNumberField
          label={
            <Inline>
              <Typography variant="body2">Routing Number</Typography>
              <Typography variant="body2" color="textSecondary">
                (US banks only)
              </Typography>
            </Inline>
          }
          name="routingNumber"
          fullWidth={true}
        />

        <Columns space="small" collapseBelow="tablet">
          <Column>
            <FormikTextField
              label="Account Number"
              name="accountNumber"
              helperText="A 5-17 digit number next to the routing number"
              fullWidth={true}
            />
          </Column>

          <Column>
            <FormikTextField
              label="Confirm Account Number"
              name="confirmAccountNumber"
              fullWidth={true}
            />
          </Column>
        </Columns>
      </Stack>

      <Box marginTop="large">
        <Stack>
          <Typography color="textSecondary">
            These numbers can be found at the bottom of a check or by contacting
            your bank.
          </Typography>

          <img
            src={routingAccountIllustration}
            width="292px"
            alt="routing account illustration"
          />
        </Stack>
      </Box>
    </>
  );
}
