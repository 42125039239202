import { useCanInstantDispatch } from 'orders/data/InstantDispatchAPI';
import { useEffect } from 'react';
import Order from 'shared/types/order';
import { useOrderActionsContext } from './OrderActionsContext';

interface Props {
  order?: Order;
}

export function InstantDispatchOpener({ order }: Props) {
  const { onAction } = useOrderActionsContext();
  const canInstantDispatch = useCanInstantDispatch(order);

  useEffect(() => {
    if (order && canInstantDispatch) {
      onAction('instant_dispatch', order);
    }
  }, [canInstantDispatch, onAction, order]);

  return null;
}
