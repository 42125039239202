import { Tooltip } from '@material-ui/core';
import { Box } from '@superdispatch/ui-lab';
import { FunctionComponent } from 'react';
import DriveawayIcon from 'shared/icons/DriveawayIcon.svg';
import { EnclosedIcon } from 'shared/icons/EnclosedIcon';
import Order from 'shared/types/order';
import styled from 'styled-components';

export const transportTypeLabels = {
  OPEN: 'Open',
  ENCLOSED: 'Enclosed',
  DRIVEAWAY: 'Driveaway',
};

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface TransportTypeComponentProps {
  order: Order;
}

export const renderTransportTypeIcons = (order: Order) => {
  const { transport_type } = order;
  return transport_type === 'ENCLOSED' ? (
    <Box paddingLeft="xxsmall">
      <Tooltip title="Requires Enclosed Trailer">
        <EnclosedIcon color="action" />
      </Tooltip>
    </Box>
  ) : transport_type === 'DRIVEAWAY' ? (
    <Box paddingLeft="xxsmall">
      <img
        key="driveaway"
        src={DriveawayIcon}
        alt="Driveaway"
        title="Driveaway"
      />
    </Box>
  ) : null;
};

export const TransportType: FunctionComponent<TransportTypeComponentProps> = (
  props,
) => {
  const { transport_type } = props.order;
  return (
    <StyledIconContainer>
      {renderTransportTypeIcons(props.order)}&nbsp;
      {transportTypeLabels[transport_type]}
    </StyledIconContainer>
  );
};
