import { Typography } from '@material-ui/core';
import { formatDateType } from '@superdispatch/sdk';
import Order from 'shared/types/order';
import { BulletTextItem } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';

interface OrderListCustomerDateProps {
  type: 'pickup' | 'delivery';
  order: Order;
}

export function OrderListCustomerDate({
  type,
  order,
}: OrderListCustomerDateProps) {
  const step = order[type];

  return (
    <BulletTextItem
      primary={
        step?.date_type !== 'estimated' ? (
          <DetailedFormattedDate
            variant="ShortDate"
            date={step?.scheduled_at_by_customer}
          />
        ) : (
          <DetailedFormattedDateRange
            range={[
              step.scheduled_at_by_customer,
              step.scheduled_ends_at_by_customer,
            ]}
          />
        )
      }
      secondary={
        step?.date_type && (
          <Typography color="textSecondary">
            {formatDateType(step.date_type)}
          </Typography>
        )
      }
    />
  );
}
