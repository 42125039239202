import { Tag } from '@superdispatch/ui';
import {
  VerificationStatusBusinessAccountType,
  VerificationStatusType,
} from '../data/SuperPayDTO';

export function VerificationBankStatusTag({
  status,
}: {
  status: VerificationStatusType | null;
}) {
  switch (status) {
    case 'NEW':
      return (
        <Tag color="blue" variant="subtle">
          New
        </Tag>
      );
    case 'PENDING':
      return (
        <Tag color="yellow" variant="subtle">
          Pending
        </Tag>
      );
    case 'VERIFIED':
      return (
        <Tag color="green" variant="subtle">
          Verified
        </Tag>
      );
    case 'VERIFICATION_LOCKED':
      return (
        <Tag color="red" variant="subtle">
          Verification Locked
        </Tag>
      );
    case 'VERIFICATION_FAILED':
      return (
        <Tag color="red" variant="subtle">
          Failed
        </Tag>
      );
    case 'ERRORED':
      return (
        <Tag color="red" variant="subtle">
          Errored
        </Tag>
      );
    default:
      return null;
  }
}

export function VerificationAccountStatusTag({
  status,
}: {
  status: VerificationStatusBusinessAccountType | null;
}) {
  switch (status) {
    case 'IN_REVIEW':
    case 'ACTION_REQUIRED':
    case 'FAILED':
      return (
        <Tag color="yellow" variant="subtle">
          In Review
        </Tag>
      );
    case 'VERIFIED':
      return (
        <Tag color="green" variant="subtle">
          Verified
        </Tag>
      );
    default:
      return null;
  }
}
