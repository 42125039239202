import { Box, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Flex } from '@rebass/grid';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import {
  composeValidators,
  email,
  required,
} from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  background: ${Color.Yellow50};
  border: 1px solid ${Color.Yellow500};
  border-radius: 4px;
`;

const StyledSuspendedFormikPhoneField = styled(SuspendedFormikPhoneField)`
  & .MuiInputBase-root {
    background: ${Color.White};
  }

  & .MuiFormLabel-root {
    color: ${Color.Grey500};
  }
`;

export function SendOfferNewCarrierFields() {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';
  return (
    <Flex flexDirection="column" mb={2}>
      <Wrapper>
        {!isMobile && (
          <Box mr={2}>
            <Warning style={{ color: Color.Yellow500 }} />
          </Box>
        )}

        <Box display="flex" flexWrap="wrap">
          <Box pb={2}>
            <Stack space="xxsmall">
              <Typography variant="h4">
                Invite this carrier to join Super Dispatch network
              </Typography>

              <Typography color="textSecondary">
                To invite this carrier, they will need load offer sent to them.
                Review email and phone below and then click Send Offer.
              </Typography>
            </Stack>
          </Box>

          <Columns collapseBelow="desktop" space="xsmall">
            <Column>
              <FormikTextField
                label="Email"
                name="carrier.email"
                InputLabelProps={{
                  style: {
                    color: Color.Grey500,
                  },
                }}
                InputProps={{
                  style: {
                    background: Color.White,
                  },
                }}
                fullWidth={true}
                validate={composeValidators(required, email)}
              />
            </Column>
            <Column>
              <StyledSuspendedFormikPhoneField
                name="carrier.phone_numbers"
                label="Phone"
                fullWidth={true}
                validate={composeValidators(required)}
              />
            </Column>
          </Columns>
        </Box>
      </Wrapper>
    </Flex>
  );
}
