import { IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import {
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';

interface CentralDispatchVerificationCodeDrawerContentProps {
  onGoBack: () => void;
  onSubmitSuccess: () => void;
}

export function CentralDispatchVerificationCodeDrawerContent({
  onGoBack,
  onSubmitSuccess,
}: CentralDispatchVerificationCodeDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: { verification_code: '' },
    onSubmit: () => null, // will be implemented later
    onSubmitSuccess: () => {
      addSnackbar('Profile updated successfully', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title={
        <Inline verticalAlign="center">
          <IconButton onClick={onGoBack}>
            <ArrowBack color="action" />
          </IconButton>
          <Typography variant="h3">Enter Verification Code</Typography>
        </Inline>
      }
      actions={
        <FormikDrawerActions isDisabled={!formik.dirty || !formik.isValid} />
      }
    >
      <Box maxWidth="368px" marginTop="-small">
        <Stack space="large">
          <Typography color="textSecondary">
            You should receive 2FA code to your email used for Central Dispatch.
            Enter the code below to finish the integration
          </Typography>

          <FormikTextField
            name="verification_code"
            fullWidth={true}
            label="CD Verification Code"
          />
        </Stack>
      </Box>
    </FormikDrawerContent>
  );
}
