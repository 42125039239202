export type MoovScope =
  | 'account'
  | 'representative'
  | 'bank_account'
  | 'micro_deposit';

export type MoovBusinessType = typeof MOOV_BUSINESS_TYPES[number];
export const MOOV_BUSINESS_TYPES = [
  'llc',
  'privateCorporation',
  'soleProprietorship',
  'partnership',
  'publicCorporation',
];

export const MOOV_BUSINESS_TYPE_DISPLAY_NAMES: Record<
  MoovBusinessType,
  string
> = {
  llc: 'LLC',
  privateCorporation: 'Private Corporation',
  soleProprietorship: 'Sole Proprietorship',
  partnership: 'Partnership',
  publicCorporation: 'Public Corporation',
};

export type MoovMCCCode = typeof MOOV_MCC_CODES[number];
export const MOOV_MCC_CODES = [
  '4789',
  '7531',
  '5511',
  '5521',
  '7512',
  '5734',
  '7394',
  '6012',
  '4214',
] as const;

export const MOOV_MCC_CODE_DISPLAY_NAMES: Record<MoovMCCCode, string> = {
  4789: 'Transportation Services', // (Default)
  7531: 'Auto Body Repair',
  5511: 'Automobile Dealers (New & Used)',
  5521: 'Automobile Dealers (Used Only)',
  7512: 'Car Rental Agencies',
  5734: 'Computer Software',
  7394: 'Equipment Rental',
  6012: 'Financial Institutions',
  4214: 'Freight Carriers, Trucking',
};

export interface MoovTokenResponse {
  access_token: string;
  moov_account_id: string | null; // null for account scope
  moov_bank_account_id: string | null; // not null for micro_deposit scope
}

export type JobTitle = typeof JOB_TITLES[number];
export const JOB_TITLES = [
  'manager',
  'logisticsCoordinator',
  'billingManager',
  'cfo',
  'coo',
  'ceo',
  'owner',
] as const;

export const JOB_TITLE_DISPLAY_NAMES: Record<JobTitle, string> = {
  manager: 'Manager',
  logisticsCoordinator: 'Logistics Coordinator',
  billingManager: 'Billing manager',
  cfo: 'CFO',
  coo: 'COO',
  ceo: 'CEO',
  owner: 'Owner',
};

export type USA_STATE = typeof USA_STATES[number];
export const USA_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const;
