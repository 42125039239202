import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { stringifyDate } from '@superdispatch/dates';
import {
  FormikDateField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { formatPaymentMethod } from '@superdispatch/sdk';
import { Button, useSnackbarStack } from '@superdispatch/ui';
import { usePaymentOptions } from 'core/SuperPayUtils';
import { Form, FormikProvider } from 'formik';
import countBy from 'lodash/countBy';
import { DateTime } from 'luxon';
import { ComponentType, useMemo } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import Order from 'shared/types/order';
import { Drawer } from 'shared/ui/Drawer';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';

interface ConfirmationDialogProps {
  cancelChanges: () => void;
  hideConfirmation: () => void;
  isConfirmationVisible: boolean;
}

const ConfirmationDialog: ComponentType<ConfirmationDialogProps> = ({
  cancelChanges,
  hideConfirmation,
  isConfirmationVisible,
}) => (
  <Dialog maxWidth="xs" open={isConfirmationVisible} onClose={hideConfirmation}>
    <DialogTitle>
      <Typography>Cancel Without Sending?</Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Typography>
          Changes have not been saved. Leaving will result in unsaved changes
          being lost.
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="outlined" onClick={hideConfirmation}>
        No
      </Button>
      <Button
        color="error"
        variant="contained"
        autoFocus={true}
        onClick={cancelChanges}
      >
        Yes, Cancel Changes
      </Button>
    </DialogActions>
  </Dialog>
);

interface MarkAsCarrierPaidDrawerFormValues {
  amount: number | null;
  method: string;
  notes: string;
  reference_number: string;
  sent_date: string | null;
}

interface MarkAsCarrierPaidDrawerProps {
  orders: Order[] | undefined;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function BulkMarkAsCarrierPaidDrawer({
  orders,
  onClose,
  onSubmitSuccess,
}: MarkAsCarrierPaidDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkMarkAsCarrierPaid } = useBulkOrderActionAPI();
  const [isConfirmationVisible, showConfirmation, hideConfirmation] =
    useBoolean(false);
  const { paymentMethods } = usePaymentOptions(null);

  const initialValues = useMemo<MarkAsCarrierPaidDrawerFormValues>(() => {
    return {
      amount: null,
      method: '',
      notes: '',
      reference_number: '',
      sent_date: stringifyDate(startOfWorkDay(DateTime.local()), {
        format: 'JodaISO',
      }),
    };
  }, []);

  const form = useFormikEnhanced<MarkAsCarrierPaidDrawerFormValues, Response>({
    key: !!orders,
    initialValues,
    onSubmit(values) {
      if (orders) {
        const ids = orders.map((x) => x.id);
        return bulkMarkAsCarrierPaid(ids, values);
      }

      return Promise.reject(new Error('Orders not selected'));
    },
    onSubmitSuccess(_, values) {
      if (orders) {
        addSnackbar('Order(s) marked as carrier paid', { variant: 'success' });
        trackEventLegacy('Bulk Mark as Carrier Paid', {
          count: orders.length,
          payment_method: values.method,
          orders: countBy(orders, 'status'),
        });
        onSubmitSuccess();
      }
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  function handleClose() {
    if (form.dirty) {
      showConfirmation();
    } else {
      onClose();
    }
  }

  function handleCancel() {
    hideConfirmation();
    onClose();
  }

  return (
    <>
      <Drawer
        header={
          <div>
            <Typography variant="h3">Mark as Carrier Paid</Typography>
            <Typography>{orders?.length} orders</Typography>
          </div>
        }
        isOpen={!!orders}
        onClose={form.isSubmitting ? undefined : handleClose}
      >
        <FormikProvider value={form}>
          <Form>
            <Box mx={4} my={2}>
              <FormikTextField
                select={true}
                fullWidth={true}
                name="method"
                label="Payment Method"
                placeholder="Select method"
              >
                {paymentMethods.map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatPaymentMethod(value)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Box>

            <Box mx={4} my={2}>
              <FormikTextField
                fullWidth={true}
                name="reference_number"
                label="Reference Number"
                disabled={form.values.method === 'superpay'}
              />
            </Box>

            <Box mx={4} my={2}>
              <FormikDateField
                fullWidth={true}
                label="Sent Date"
                name="sent_date"
              />
            </Box>

            <Box mx={4} my={2}>
              <FormikTextField
                rows={3}
                name="notes"
                type="textarea"
                fullWidth={true}
                multiline={true}
                label="Payment Notes"
                inputProps={{ maxLength: 3000 }}
              />
            </Box>

            <Box mx={4} my={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                isLoading={form.isSubmitting}
              >
                Done
              </Button>
            </Box>
          </Form>
        </FormikProvider>
      </Drawer>

      <ConfirmationDialog
        cancelChanges={handleCancel}
        hideConfirmation={hideConfirmation}
        isConfirmationVisible={isConfirmationVisible}
      />
    </>
  );
}
