import { FieldValidator, useField, useFormikContext } from 'formik';
import { AttachmentDTO } from 'shared/types/attachment';
import { AttachmentList, AttachmentListProps } from './AttachmentList';

interface FormikAttachmentListProps
  extends Omit<AttachmentListProps, 'attachments'> {
  name: string;
  validate?: FieldValidator;
}

export function FormikAttachmentList({
  name,
  accept,
  disabled,
  validate,
  ...rest
}: FormikAttachmentListProps) {
  const { isSubmitting, dirty } = useFormikContext();
  const [field, { error }, { setValue }] = useField<
    Array<AttachmentDTO | File>
  >({
    name,
    validate,
  });

  const errorMessage = dirty && error;

  return (
    <AttachmentList
      accept={accept}
      attachments={field.value}
      onChange={(value) => setValue(value)}
      disabled={disabled || isSubmitting}
      error={!!errorMessage}
      helperText={errorMessage}
      {...rest}
    />
  );
}
