import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import {
  terminalContactDTO,
  TerminalContactDTO,
} from 'shared/dto/TerminalContactDTO';

export function useContactCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateContacts() {
      void queryClient.invalidateQueries('terminal-contacts');
    }

    return { invalidateContacts };
  }, [queryClient]);
}

export function useTerminalContacts(terminalID: number | undefined) {
  const { requestPage } = useAPI();
  return useAPIPageQuery(['terminal-contacts', 'list', { terminalID }], () =>
    requestPage(
      '/internal/terminals/{terminalID}/contacts',
      (data) => terminalContactDTO.cast(data),
      { terminalID },
    ),
  );
}

export function useTerminalContactAPI() {
  const cache = useContactCache();
  const { request, requestResource } = useAPI();

  return useMemo(
    () => ({
      createTerminalContact(terminalID: number, payload: TerminalContactDTO) {
        return requestResource(
          'POST /internal/terminals/{terminalID}/contacts',
          (data) => terminalContactDTO.cast(data),
          { terminalID, json: payload },
        ).then((response) => {
          cache.invalidateContacts();
          return response;
        });
      },
      updateTerminalContact(
        terminalID: number,
        contactID: number,
        payload: TerminalContactDTO,
      ) {
        return requestResource<
          TerminalContactDTO,
          { terminalID: number; contactID: number }
        >(
          'PUT /internal/terminals/{terminalID}/contacts/{contactID}',
          (data) => terminalContactDTO.cast(data),
          { terminalID, contactID, json: payload },
        ).then((response) => {
          cache.invalidateContacts();
          return response;
        });
      },
      markTerminalContactAsPrimary: (terminalID: number, contactID: number) =>
        request(
          'PUT /internal/terminals/{terminalID}/contacts/{contactID}/mark_as_primary',
          { terminalID, contactID },
        ).then((response) => {
          cache.invalidateContacts();
          return response;
        }),
      deleteTerminalContact: (terminalID: number, contactID: number) =>
        request(
          'DELETE /internal/terminals/{terminalID}/contacts/{contactID}',
          { terminalID, contactID },
        ).then((response) => {
          cache.invalidateContacts();
          return response;
        }),
    }),
    [cache, request, requestResource],
  );
}
