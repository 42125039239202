import { NullableDateString } from '@superdispatch/dates';
import { Carrier } from 'shared/types/carrier';

interface InsuranceInfo {
  insuranceExpiresAt: NullableDateString;
  insuranceCertFileUrl: string | null;
}

export function getInsuranceInfo(
  carrier: Carrier | null | undefined,
): InsuranceInfo {
  const brokerRecords = carrier?.broker_records;

  if (brokerRecords?.insurance_certificate_holder) {
    return {
      insuranceExpiresAt: brokerRecords.insurance_expires_at,
      insuranceCertFileUrl:
        brokerRecords.insurance_cert_holder_file_url ?? null,
    };
  }

  return {
    insuranceExpiresAt: carrier?.cargo_policy?.policy_expiration_date,
    insuranceCertFileUrl: carrier?.cargo_insurance ?? null,
  };
}
