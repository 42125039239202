import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CardButton, useUID } from '@superdispatch/ui';
import { Fragment } from 'react';
import { usePermission } from 'shared/data/UserPermissions';
import { Divider } from 'shared/ui/Divider';
import { CustomerDTO } from '../../shared/dto/CustomerDTO';
import { LabelInfo } from './CustomerDetails';
import { CustomerDetailsCard } from './CustomerDetailsCard';

export const loadboardInstructionsHint =
  'Visible to carrier inside the Super Loadboard.';
export const orderInstructionsHint =
  'Visible to carrier before and after accepting the load offer.';

interface Props {
  customer: CustomerDTO;
  onEdit: () => void;
  onAddOrderInstruction: () => void;
  onAddLoadboardInstruction: () => void;
}

export function CustomerDetailsInstructions({
  onEdit,
  customer,
  onAddOrderInstruction,
  onAddLoadboardInstruction,
}: Props) {
  const orderInstructionsId = useUID();
  const loadboardInstructionsId = useUID();

  const { loadboard_instructions, order_instructions } = customer;

  const canManageCustomer = usePermission('CUSTOMER_MANAGEMENT', 'canExecute');
  return (
    <CustomerDetailsCard
      onEdit={onEdit}
      title={
        !!customer.order_instructions || !!customer.loadboard_instructions
          ? 'Instructions'
          : undefined
      }
    >
      {loadboard_instructions ? (
        <>
          <Box mt={2}>
            <LabelInfo
              text="Loadboard Instructions"
              tooltip={loadboardInstructionsHint}
              TextProps={{ id: loadboardInstructionsId }}
            />
          </Box>

          <Box mt={1} aria-labelledby={loadboardInstructionsId}>
            {loadboard_instructions.split('\n').map((item, key) => (
              <Fragment key={key}>
                {item}

                <br />
              </Fragment>
            ))}
          </Box>
        </>
      ) : (
        <CardButton
          size="large"
          startIcon={canManageCustomer ? <Add /> : null}
          hint={loadboardInstructionsHint}
          disabled={!canManageCustomer}
          onClick={canManageCustomer ? onAddLoadboardInstruction : () => {}}
        >
          {canManageCustomer ? 'Add Load Board Instructions' : ''}
        </CardButton>
      )}

      {!!loadboard_instructions || !!order_instructions ? (
        <Divider my={3} />
      ) : (
        <Box my={2} />
      )}

      {order_instructions ? (
        <>
          <Box mt={2}>
            <LabelInfo
              text="Order Instructions"
              tooltip={orderInstructionsHint}
              TextProps={{ id: orderInstructionsId }}
            />
          </Box>

          <Box mt={1} aria-labelledby={orderInstructionsId}>
            {order_instructions.split('\n').map((item, key) => (
              <Fragment key={key}>
                {item}

                <br />
              </Fragment>
            ))}
          </Box>
        </>
      ) : (
        <CardButton
          size="large"
          startIcon={canManageCustomer ? <Add /> : null}
          hint={orderInstructionsHint}
          disabled={!canManageCustomer}
          onClick={canManageCustomer ? onAddOrderInstruction : () => {}}
        >
          {canManageCustomer ? 'Add Order Instructions' : ''}
        </CardButton>
      )}
    </CustomerDetailsCard>
  );
}
