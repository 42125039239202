import { Typography } from '@material-ui/core';
import {
  NullableDateInput,
  NullableDateRangeInput,
  parseDate,
} from '@superdispatch/dates';
import { formatDateType } from '@superdispatch/sdk';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { CurrentUser } from 'shared/types/user';
import { BulletTextItem } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';
import {
  Timeline,
  TimelineCardHeading,
  TimelineItem,
  TimelineItemPadded,
} from 'shared/ui/Timeline';

export function normalizeScheduledDate(
  startDate: NullableDateInput,
  endDate: NullableDateInput,
): NullableDateInput | NullableDateRangeInput {
  const start = parseDate(startDate, { format: 'JodaISO' });
  const end = parseDate(endDate, { format: 'JodaISO' });

  return !end.isValid || start.equals(end) ? startDate : [startDate, endDate];
}

export function isDatePast(date: NullableDateInput) {
  return parseDate(date, { format: 'JodaISO' }) < DateTime.local();
}

export function showCarrierScheduledDate(order: Order) {
  return (
    order.status !== 'new' &&
    order.status !== 'declined' &&
    order.status !== 'canceled' &&
    !order.is_on_hold &&
    !order.is_posted_to_loadboard &&
    !order.is_posted_to_centraldispatch
  );
}

interface TimelineItem {
  title: string;
  active: boolean;
  content: Array<{
    label?: string;
    date?: NullableDateInput | NullableDateRangeInput;
  }>;
}

function getTimeline(
  order: Order,
  user: CurrentUser | undefined,
): TimelineItem[] {
  const timeline: TimelineItem[] = [
    {
      title: 'Created',
      active: true,
      content: [{ date: order.created_at }],
    },
  ];

  if (user?.shipper.shipper_type === 'CUSTOMER') {
    if (
      order.status !== 'submitted' &&
      showCarrierScheduledDate(order) &&
      (!!order.pickup?.scheduled_at || !!order.pickup?.scheduled_ends_at)
    ) {
      timeline.push({
        title: 'Pickup Date',
        active: isDatePast(order.pickup.scheduled_at),
        content: [
          {
            label: formatDateType(order.pickup.date_type),
            date: normalizeScheduledDate(
              order.pickup.scheduled_at,
              order.pickup.scheduled_ends_at,
            ),
          },
        ],
      });
    }

    if (!!order.pickup?.adjusted_date || !!order.pickup?.completed_at) {
      timeline.push({
        title: 'Picked Up On',
        active: !!order.pickup.adjusted_date || !!order.pickup.completed_at,
        content: [
          { date: order.pickup.adjusted_date || order.pickup.completed_at },
        ],
      });
    }

    if (
      order.status !== 'submitted' &&
      showCarrierScheduledDate(order) &&
      (!!order.delivery?.scheduled_at || !!order.delivery?.scheduled_ends_at)
    ) {
      timeline.push({
        title: 'Delivery Date',
        active: isDatePast(order.delivery.scheduled_at),
        content: [
          {
            label: formatDateType(order.delivery.date_type),
            date: normalizeScheduledDate(
              order.delivery.scheduled_at,
              order.delivery.scheduled_ends_at,
            ),
          },
        ],
      });
    }

    if (!!order.delivery?.completed_at || !!order.delivery?.adjusted_date) {
      timeline.push({
        title: 'Delivered On',
        active: !!order.delivery.adjusted_date || !!order.delivery.completed_at,
        content: [
          {
            date: order.delivery.adjusted_date || order.delivery.completed_at,
          },
        ],
      });
    }
  }

  return timeline;
}

interface OrderViewDatesProps {
  order: Order;
}

export function OrderViewDates({ order }: OrderViewDatesProps) {
  const { user } = useUserState();
  const timeline = useMemo(() => getTimeline(order, user), [order, user]);

  const activeIndex = useMemo(
    () => timeline.map(({ active }) => active).lastIndexOf(true),
    [timeline],
  );

  return (
    <div aria-label="order dates">
      <TimelineCardHeading>
        <Typography variant="h3">Dates</Typography>
      </TimelineCardHeading>

      <Timeline activeIndex={activeIndex}>
        {timeline.map(({ title, content }) => (
          <TimelineItem
            key={title}
            title={title}
            subtitle={content.map(({ label, date }, index) => {
              const dateElement = Array.isArray(date) ? (
                <TimelineItemPadded key={index}>
                  <DetailedFormattedDateRange range={date} />
                </TimelineItemPadded>
              ) : (
                <TimelineItemPadded key={index}>
                  <DetailedFormattedDate date={date} variant="ShortDate" />
                </TimelineItemPadded>
              );

              return !date ? null : !label ? (
                dateElement
              ) : (
                <BulletTextItem
                  key={label}
                  secondary={label}
                  primary={dateElement}
                />
              );
            })}
          />
        ))}
      </Timeline>
    </div>
  );
}
