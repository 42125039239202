import { Color } from '@superdispatch/ui';

export function LocationCardIcon() {
  return (
    <svg
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="118"
        height="78"
        rx="7"
        stroke={Color.Silver500}
        strokeWidth="2"
        strokeDasharray="6 6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2265 51.6888C32.7035 53.7928 34.4339 56.258 36.4584 59.193C37.2033 60.269 38.7966 60.2691 39.5416 59.193C41.5661 56.258 43.2965 53.7928 44.7734 51.6888C51.5775 41.9957 53 39.9692 53 35C53 26.7157 46.2843 20 38 20C29.7157 20 23 26.7157 23 35C23 39.9692 24.4225 41.9957 31.2265 51.6888ZM44.25 35C44.25 38.4518 41.4518 41.25 38 41.25C34.5482 41.25 31.75 38.4518 31.75 35C31.75 31.5482 34.5482 28.75 38 28.75C41.4518 28.75 44.25 31.5482 44.25 35Z"
        fill={Color.Silver400}
      />
      <rect
        x="66.25"
        y="22.5"
        width="20"
        height="5"
        rx="2.5"
        fill={Color.Silver400}
      />
      <rect
        x="66.25"
        y="37.5"
        width="33.75"
        height="5"
        rx="2.5"
        fill={Color.Silver400}
      />
      <rect
        x="66.25"
        y="52.5"
        width="33.75"
        height="5"
        rx="2.5"
        fill={Color.Silver400}
      />
    </svg>
  );
}
