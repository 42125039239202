import { useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import {
  UpdatedExpeditePaymentDTO,
  updatedExpeditePaymentSchema,
} from './ExpeditePaymentDTO';

export function useRemoveExpeditePayment(
  options: APIMutationOptions<string[], UpdatedExpeditePaymentDTO>,
) {
  const { requestResource } = useAPI();
  return useAPIMutation(
    (carrier_guids: string[]) =>
      requestResource(
        'POST /internal/carriers/can_expedite_payment/bulk_remove',
        (data) => updatedExpeditePaymentSchema.cast(data),
        { json: { carrier_guids } },
      ),
    options,
  );
}

export function useMarkExpeditePayment(
  options: APIMutationOptions<string[], UpdatedExpeditePaymentDTO>,
) {
  const { requestResource } = useAPI();
  return useAPIMutation(
    (carrier_guids: string[]) =>
      requestResource(
        'POST /internal/carriers/can_expedite_payment/bulk_add',
        (data) => updatedExpeditePaymentSchema.cast(data),
        { json: { carrier_guids } },
      ),
    options,
  );
}
