import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import Order from 'shared/types/order';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';

interface CancelOfferDialogProps {
  order?: Order;
  onCancel: () => void;
  onSubmitSuccess: (updatedOrder: Order) => void;
}

export function CancelOfferDialog({
  order,
  onCancel,
  onSubmitSuccess,
}: CancelOfferDialogProps) {
  const { bulkCancelOffer } = useBulkOrderActionAPI();
  const { addSnackbar } = useSnackbarStack();
  const hasSuperPayStatus = !!order?.payment?.super_pay?.status;
  const hasExpeditedPayFeeAmount =
    !!order?.payment?.super_pay?.expedited_pay_fee_amount;

  const { isSubmitting, handleSubmit } = useFormikEnhanced<{}, unknown>({
    initialValues: {},
    onSubmit() {
      if (!order) {
        return Promise.reject(new Error('No order found'));
      }
      // pending order may have multiple pending offers but not active offer
      if (order.status !== 'pending' && !order.active_offer) {
        return Promise.reject(new Error('No active offer found'));
      }
      return bulkCancelOffer([order.id]);
    },
    onSubmitSuccess() {
      if (order) {
        addSnackbar('Offer Canceled', { variant: 'success' });

        onSubmitSuccess({
          ...order,
          status: 'canceled',
          active_offer: undefined,
          vehicles: order.vehicles?.map((x) => ({
            ...x,
            status: 'canceled',
          })),
        });
      }
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmDialog
      open={!!order}
      onClose={isSubmitting ? undefined : onCancel}
      title={
        hasExpeditedPayFeeAmount
          ? 'Cancel Offer & SuperPay?'
          : 'Cancel this offer?'
      }
      cancelButtonProps={{ children: 'No', disabled: isSubmitting }}
      confirmButtonProps={{
        children: 'Yes, Cancel',
        isLoading: isSubmitting,
        onClick: () => handleSubmit(),
      }}
    >
      {hasSuperPayStatus ? (
        hasExpeditedPayFeeAmount ? (
          <Typography>
            Since expedited payment has been initiated for this order,
            cancelling the offer will also cancel SuperPay and trigger a refund.
            You won&apos;t be able to use SuperPay for this order.
          </Typography>
        ) : (
          <Typography>
            SuperPay payment will be put on hold. It will resume once the new
            carrier delivers the load.
          </Typography>
        )
      ) : null}
    </ConfirmDialog>
  );
}
