import styled from 'styled-components';
import superPayLabel from './assets/superpay.png';

const StyledImg = styled.img`
  vertical-align: middle;
`;

export function SuperPayIcon({
  height = 14,
  width = 70,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <StyledImg
      height={height}
      width={width}
      src={superPayLabel}
      aria-label="SuperPay label"
    />
  );
}
