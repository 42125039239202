import { Dialog, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import loadboardIllustration from './assets/loadboard_settings_illustration.png';
import { useCentralDispatchCredentials } from './cental-dispatch/CentralDispatchAPI';

const DIALOG_CLOSED_LS_KEY = 'is_loadboard_issues_dialog_closed';

export default function LoadboardSettingsIssues() {
  const { user } = useUserState();
  const isCustomer = user?.shipper.shipper_type === 'CUSTOMER';
  const hasClosedDialog = useStorageValue(DIALOG_CLOSED_LS_KEY);
  const isEnabled = useFeatureToggle('cx-loadboard-issues-dialog.enabled');

  if (isCustomer || !isEnabled || hasClosedDialog) {
    return null;
  }

  return <LoadboardSettingsIssuesDialog />;
}

function LoadboardSettingsIssuesDialog() {
  const platform = useResponsiveValue('mobile', 'other');
  const { data: credentials } = useCentralDispatchCredentials();
  const hasCDCredentials = Boolean(credentials?.uid || credentials?.login);

  if (!!credentials && !hasCDCredentials) {
    return null;
  }

  const handleClose = () => {
    writeStorageItem(DIALOG_CLOSED_LS_KEY, String(true));
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullScreen={platform === 'mobile'}
    >
      <Box padding="large" maxWidth="600px">
        <Stack space="large" align="center">
          <img width="238px" src={loadboardIllustration} />
          <Stack space="small">
            <Typography>
              We recognize that the recent changes on the other load board may
              be causing some disruptions to your workflow, and we want to
              reassure you that we are here to support you every step of the
              way.
            </Typography>
            <Typography>
              Our dedicated engineering team is already diligently working to
              address these challenges, ensuring that you can continue to
              operate your business smoothly and on your own terms.
            </Typography>
            <Typography>
              We appreciate your patience and are committed to implementing
              effective solutions that support you in maintaining control of
              your operations.
            </Typography>
          </Stack>

          <Button onClick={handleClose}>Got it</Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
