import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoRows: 'minmax(0, auto)',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "pickup       pickup"
      "delivery     delivery"
      "customer     customer"
      "payment      payment"
      "carrier      carrier"
      "instructions instructions"
      "inernalNotes inernalNotes"
      "dates        dates"
      "activity     activity"
      "attachments  attachments"`,
  },
  gridWrapperNoCustomer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoRows: 'minmax(0, auto)',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "pickup       pickup"
      "delivery     delivery"
      "carrier      carrier"
      "payment      payment"
      "instructions instructions"
      "inernalNotes inernalNotes"
      "dates        dates"
      "activity     activity"
      "attachments  attachments"`,
  },
  gridWrapperNoInstructions: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoRows: 'minmax(0, auto)',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "pickup       pickup"
      "delivery     delivery"
      "customer     customer"
      "carrier      carrier"
      "payment      payment"
      "inernalNotes inernalNotes"
      "dates        dates"
      "activity     activity"
      "attachments  attachments"`,
  },
  gridWrapperNoCustomerNoInstructions: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoRows: 'minmax(0, auto)',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "pickup       pickup"
      "delivery     delivery"
      "carrier      carrier"
      "payment      payment"
      "inernalNotes inernalNotes"
      "dates        dates"
      "activity     activity"
      "attachments  attachments"`,
  },
  customer: {
    gridArea: 'customer',
  },
  pickup: {
    gridArea: 'pickup',
  },
  delivery: {
    gridArea: 'delivery',
  },
  payment: {
    gridArea: 'payment',
  },
  carrier: {
    gridArea: 'carrier',
  },
  instructions: {
    gridArea: 'instructions',
  },
  activity: {
    gridArea: 'activity',
  },
  dates: {
    gridArea: 'dates',
  },
  inernalNotes: {
    gridArea: 'inernalNotes',
  },
  attachments: {
    gridArea: 'attachments',
  },
  '@media (min-width: 700px)': {
    gridWrapper: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "pickup       delivery"
        "customer     payment"
        "carrier      ......."
        "instructions instructions"
        "inernalNotes inernalNotes"
        "dates        activity"
        "attachments  ........"
        `,
    },

    gridWrapperNoCustomer: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
      "pickup       delivery"
      "carrier      payment"
      "instructions instructions"
      "inernalNotes inernalNotes"
      "dates        activity"
      "attachments  ........"`,
    },

    gridWrapperNoInstructions: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
      "pickup       delivery"
        "customer     payment"
        "carrier      ......."
        "inernalNotes inernalNotes"
        "dates        activity"
        "attachments  ........"`,
    },

    gridWrapperNoCustomerNoInstructions: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
      "pickup       delivery"
      "carrier     payment"
      "inernalNotes inernalNotes"
      "dates        activity"
      "attachments  ........"`,
    },
  },
  '@media (min-width: 1280px)': {
    gridWrapper: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        "customer            pickup       delivery     payment"
        "customer            pickup       delivery     payment"
        "carrier             instructions instructions activity"
        "dates               instructions instructions activity"
        "dates               inernalNotes inernalNotes activity"
        "dates               inernalNotes inernalNotes activity"
        "attachments         inernalNotes inernalNotes activity"
        "attachments         inernalNotes inernalNotes activity"
        "................... inernalNotes inernalNotes ........"`,
    },

    gridWrapperNoCustomer: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        "carrier             pickup       delivery     payment"
        "carrier             pickup       delivery     payment"
        "dates               instructions instructions activity"
        "dates               instructions instructions activity"
        "dates               inernalNotes inernalNotes activity"
        "attachments         inernalNotes inernalNotes activity"
        "attachments         inernalNotes inernalNotes activity"
        "................... inernalNotes inernalNotes activity"
        "................... inernalNotes inernalNotes ........"`,
    },

    gridWrapperNoInstructions: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
      "customer            pickup       delivery     payment"
      "customer            pickup       delivery     payment"
      "carrier             inernalNotes inernalNotes activity"
      "dates               inernalNotes inernalNotes activity"
      "dates               inernalNotes inernalNotes activity"
      "dates               inernalNotes inernalNotes activity"
      "attachments         inernalNotes inernalNotes activity"
      "attachments         inernalNotes inernalNotes activity"
      "................... inernalNotes inernalNotes ........"`,
    },

    gridWrapperNoCustomerNoInstructions: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        "carrier             pickup       delivery     payment"
        "carrier             pickup       delivery     payment"
        "dates               inernalNotes inernalNotes activity"
        "dates               inernalNotes inernalNotes activity"
        "dates               inernalNotes inernalNotes activity"
        "attachments         inernalNotes inernalNotes activity"
        "attachments         inernalNotes inernalNotes activity"
        "................... inernalNotes inernalNotes activity"
        "................... inernalNotes inernalNotes ........"`,
    },
  },
}));

interface TemplateProps {
  pickup: React.ReactNode;
  delivery: React.ReactNode;
  payment: React.ReactNode;
  dates: React.ReactNode;
  carrier: React.ReactNode;
  customer: React.ReactNode;
  instructions: React.ReactNode;
  internalNotes: React.ReactNode;
  attachments: React.ReactNode;
  activities: React.ReactNode;
  hasInstructions: boolean;
  hasCustomer: boolean;
}

type GridClassName =
  | 'gridWrapper'
  | 'gridWrapperNoCustomer'
  | 'gridWrapperNoInstructions'
  | 'gridWrapperNoCustomerNoInstructions';

const gridRules: Array<{
  condition: (hasCustomer: boolean, hasInstructions: boolean) => boolean;
  getClassName: () => GridClassName;
}> = [
  {
    condition: (hasCustomer: boolean, hasInstructions: boolean) =>
      hasCustomer && hasInstructions,
    getClassName: () => 'gridWrapper',
  },

  {
    condition: (hasCustomer: boolean, hasInstructions: boolean) =>
      !hasCustomer && hasInstructions,
    getClassName: () => 'gridWrapperNoCustomer',
  },

  {
    condition: (hasCustomer: boolean, hasInstructions: boolean) =>
      hasCustomer && !hasInstructions,
    getClassName: () => 'gridWrapperNoInstructions',
  },

  {
    condition: (hasCustomer: boolean, hasInstructions: boolean) =>
      !hasCustomer && !hasInstructions,
    getClassName: () => 'gridWrapperNoCustomerNoInstructions',
  },
];

const getGridClassName = (
  hasCustomer: boolean,
  hasInstructions: boolean,
): GridClassName => {
  const rule = gridRules.find(({ condition }) =>
    condition(hasCustomer, hasInstructions),
  );

  return rule?.getClassName() || 'gridWrapper';
};

export const BrokerTemplate: React.ComponentType<TemplateProps> = ({
  pickup,
  delivery,
  payment,
  dates,
  carrier,
  customer,
  instructions,
  hasInstructions,
  internalNotes,
  attachments,
  activities,
  hasCustomer,
}) => {
  const classes = useStyles();
  const gridClassName = getGridClassName(hasCustomer, hasInstructions);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  const cardStyles = isMobile
    ? {
        borderRadius: '0',
        borderLeft: 'none',
        borderRight: 'none',
      }
    : {};

  return (
    <Box
      pt={3}
      px={isMobile ? 0 : 3}
      pb={isMobile ? 7 : 3}
      className={classes[gridClassName]}
    >
      {hasCustomer && (
        <Box className={classes.customer}>
          <Card style={cardStyles}>
            <CardContent>{customer}</CardContent>
          </Card>
        </Box>
      )}

      <Box className={classes.pickup} data-intercom-target="pickup section">
        <Card style={cardStyles}>
          <CardContent>{pickup}</CardContent>
        </Card>
      </Box>
      <Box className={classes.delivery} data-intercom-target="delivery section">
        <Card style={cardStyles}>
          <CardContent>{delivery}</CardContent>
        </Card>
      </Box>
      <Box className={classes.payment} data-intercom-target="payment section">
        <Card style={cardStyles}>
          <CardContent>{payment}</CardContent>
        </Card>
      </Box>

      <Box className={classes.carrier}>
        <Card style={cardStyles}>
          <CardContent>{carrier}</CardContent>
        </Card>
      </Box>

      {hasInstructions && (
        <Box className={classes.instructions}>
          <Card style={cardStyles}>
            <CardContent>{instructions}</CardContent>
          </Card>
        </Box>
      )}

      <Box className={classes.activity} overflow="hidden">
        <Card style={cardStyles}>{activities}</Card>
      </Box>
      <Box className={classes.dates}>
        <Card style={cardStyles}>{dates}</Card>
      </Box>
      <Box className={classes.inernalNotes}>
        <Card style={cardStyles}>
          <CardContent>{internalNotes}</CardContent>
        </Card>
      </Box>
      <Box className={classes.attachments} overflow="hidden">
        <Card style={cardStyles}>
          <CardContent>{attachments}</CardContent>
        </Card>
      </Box>
    </Box>
  );
};
