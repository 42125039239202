import { TableCell, TableRow, Typography } from '@material-ui/core';
import { formatVehicleType } from '@superdispatch/sdk';
import { Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { OrderVehicleStatus } from 'orders/core/OrderVehicleStatus';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useUserState } from 'shared/data/AppUserState';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { DamagedIcon } from 'shared/icons/DamagedIcon';
import { InoperableVehicleIcon } from 'shared/icons/InoperableVehicleIcon';
import Order from 'shared/types/order';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';
import { ResponsiveCopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import { DeliveryETA } from 'shared/ui/DeliveryETA';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { PhotoViewer } from 'shared/ui/PhotoViewer';
import { PickupETA } from 'shared/ui/PickupETA';
import styled from 'styled-components';
import { useProductTiers } from '../../shared/data/TiersUtils';
import { mapVehiclePhotoToSlide } from './galleryUtils';
import { OrderViewVehicleBOL } from './OrderViewVehicleBOL';
import { formatDimensions } from './OrderViewVehicles';

const deliveryDateAvailableStatuses = [
  'delivered',
  'invoiced',
  'delivery_verified',
  'paid',
  'submitted',
  'completed',
];

const pickupDateAvailableStatuses = [
  'accepted',
  'picked_up',
  'delivered',
  'invoiced',
  'delivery_verified',
  'paid',
  'submitted',
  'completed',
];

interface OrderViewVehiclesOnlyProps {
  order: Order;
  vehicles: VehicleDTO[];
  hiddenFields: Set<string>;
}

const StyledTableRow = styled(TableRow)`
  :last-child {
    border-bottom: hidden;
  }
`;

export function OrderViewVehiclesOnly({
  order,
  vehicles,
  hiddenFields,
}: OrderViewVehiclesOnlyProps) {
  const hasLotValue = vehicles.some((item) => item.lot_number);

  return (
    <>
      {vehicles.map((vehicle, index) => (
        <OrderViewVehicle
          order={order}
          key={index}
          index={index}
          vehicle={vehicle}
          hiddenFields={hiddenFields}
          hasLotValue={hasLotValue}
        />
      ))}
    </>
  );
}

interface OrderViewVehicleProps {
  order: Order;
  index: number;
  vehicle: VehicleDTO;
  hiddenFields: Set<string>;
  hasLotValue: boolean;
}

function OrderViewVehicle({
  order,
  index,
  vehicle,
  hiddenFields,
  hasLotValue,
}: OrderViewVehicleProps) {
  const { user } = useUserState();

  const shipperType = user?.shipper.shipper_type;
  const { isAdvancedTier } = useProductTiers();
  const isManuallyChanged =
    order.status === 'invoiced'
      ? !!order.invoice?.adjusted_invoice_date
      : order.is_status_changed_manually;

  const {
    id,
    vin,
    year,
    make,
    model,
    type,
    color,
    price,
    tariff,
    status,
    pickup,
    photos,
    delivery,
    curb_weight,
    curb_weight_unit,
    is_damaged: isDamaged,
    lot_number: lotNumber,
    is_inoperable: isInoperable,
    eta_information,
  } = vehicle;

  const unit = curb_weight_unit || 'lbs';
  const dimensions = formatDimensions(vehicle);

  const samplePhotos = useMemo(
    () => photos?.filter((x) => x.photo_type === 'Sample') ?? [],
    [photos],
  );

  const [thumbnail] = samplePhotos;

  const info = [year, make, model].filter(Boolean).join(' ');

  const vehicleDeliveryDate =
    !isManuallyChanged || !deliveryDateAvailableStatuses.includes(order.status)
      ? delivery?.completed_at
      : delivery?.completed_at || order.delivery?.adjusted_date;

  const vehicleDeliveryETA = eta_information?.delivery_eta;

  const vehiclePickupDate =
    !isManuallyChanged || !pickupDateAvailableStatuses.includes(order.status)
      ? pickup?.completed_at
      : pickup?.completed_at || order.pickup?.adjusted_date;

  const vehiclePickupETA = eta_information?.pickup_eta;

  const slides = mapVehiclePhotoToSlide(samplePhotos);

  return (
    <StyledTableRow key={id}>
      <TableCell>
        <TextBox aria-label="vehicle number">{index + 1}</TextBox>
      </TableCell>

      <TableCell>
        <Inline verticalAlign="center" space="xsmall">
          {isInoperable && (
            <InoperableVehicleIcon aria-label="vehicle inoperable" />
          )}

          {isDamaged && shipperType === 'BROKER' && (
            <DamagedIcon aria-label="vehicle damaged" />
          )}
        </Inline>
      </TableCell>

      {!hiddenFields.has('status') && (
        <TableCell>
          <OrderVehicleStatus status={status} />
        </TableCell>
      )}

      {!hiddenFields.has('pickupDate') && (
        <TableCell>
          {!pickup?.completed_at && vehiclePickupETA ? (
            <PickupETA pickupETA={vehiclePickupETA} showEmpty={true} />
          ) : vehiclePickupDate ? (
            <DetailedFormattedDate
              date={vehiclePickupDate}
              ContainerProps={{
                'aria-label': 'vehicle pickup date',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>
      )}

      {!hiddenFields.has('deliveryDate') && (
        <TableCell>
          {vehicleDeliveryDate ? (
            <DetailedFormattedDate
              date={vehicleDeliveryDate}
              ContainerProps={{
                'aria-label': 'vehicle delivery date',
              }}
            />
          ) : (
            <DeliveryETA
              delivery={order.delivery}
              key={vehicle.guid}
              deliveryETA={vehicleDeliveryETA}
              showEmpty={true}
            />
          )}
        </TableCell>
      )}

      <TableCell>
        {thumbnail?.photo_url && (
          <PhotoViewer
            imageUrl={thumbnail.photo_url}
            thumbnailUrl={thumbnail.thumbnail_url}
            FullImageProps={{ alt: info }}
            slides={slides}
            ThumbnailImageProps={{
              alt: info,
              width: '48',
              height: '36',
            }}
          />
        )}
      </TableCell>

      <TableCell>
        <ResponsiveCopyToClipboardTooltip
          text={info}
          title="Copy Info"
          onCopy={() => {
            trackEvent('Shipper Copied Vehicle Info', {
              utm_medium: 'Order View',
            });
          }}
        >
          <Typography gutterBottom={true} aria-label="vehicle info">
            {info}
          </Typography>
        </ResponsiveCopyToClipboardTooltip>

        {vin && (
          <ResponsiveCopyToClipboardTooltip
            text={vin}
            title="Copy VIN"
            onCopy={() => {
              trackEvent('Shipper Copied VIN', { utm_medium: 'Order View' });
            }}
          >
            <Typography color="textSecondary" aria-label="vehicle vin">
              {vin}
            </Typography>
          </ResponsiveCopyToClipboardTooltip>
        )}
      </TableCell>

      {!hiddenFields.has('vehicleDetails') && (
        <TableCell>
          <Stack space="xxsmall">
            {(type || curb_weight || color) && (
              <InlineBulletItems aria-label="vehicle details">
                {type && (
                  <TextBox color="secondary">{formatVehicleType(type)}</TextBox>
                )}

                {((user?.order_form_settings.vehicle.is_color_visible &&
                  color) ||
                  color) && (
                  <TextBox color="secondary" aria-label="vehicle color">
                    {color}
                  </TextBox>
                )}

                {curb_weight && (
                  <TextBox color="secondary">
                    <NumericFormat
                      displayType="text"
                      value={curb_weight}
                      suffix={` ${unit}`}
                      thousandSeparator={true}
                    />
                  </TextBox>
                )}
              </InlineBulletItems>
            )}

            {dimensions.length > 0 && (
              <TextBox color="secondary">{dimensions}</TextBox>
            )}
          </Stack>
        </TableCell>
      )}

      {!hiddenFields.has('lotNumber') &&
        (user?.order_form_settings.vehicle.is_lot_number_visible ||
          hasLotValue) && (
          <TableCell>
            {!lotNumber ? (
              '-'
            ) : (
              <TextBox color="secondary" aria-label="vehicle lot number">
                {lotNumber}
              </TextBox>
            )}
          </TableCell>
        )}

      {isAdvancedTier && (
        <TableCell align="right">
          {!tariff ? '-' : <Price value={tariff} aria-label="vehicle tariff" />}
        </TableCell>
      )}

      {shipperType === 'BROKER' && (
        <TableCell align="right">
          {!price ? '-' : <Price value={price} aria-label="vehicle price" />}
        </TableCell>
      )}

      <TableCell align="center">
        <OrderViewVehicleBOL vehicle={vehicle} />
      </TableCell>
    </StyledTableRow>
  );
}
