import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export const Price: React.ComponentType<NumericFormatProps> = ({
  prefix = '$',
  thousandSeparator = true,
  decimalScale = 2,
  value,
  ...props
}) => (
  <NumericFormat
    displayType="text"
    prefix={prefix}
    thousandSeparator={thousandSeparator}
    decimalScale={decimalScale}
    value={value}
    {...props}
  />
);
