import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { startCase } from 'lodash-es';
import { forwardRef, memo, ReactNode } from 'react';

export function createSvgIcon(
  displayName: string,
  path: ReactNode,
  defaultProps?: SvgIconProps,
) {
  const Icon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon
      aria-label={`${startCase(displayName)} icon`}
      {...defaultProps}
      {...props}
      ref={ref}
    >
      {typeof path === 'string' ? <path d={path} /> : path}
    </SvgIcon>
  ));

  const Component = memo(Icon);

  if (import.meta.env.MODE !== 'production') {
    Icon.displayName = `${displayName}Icon`;
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
}
