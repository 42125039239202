import { Link, Typography } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Banner, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useQuery } from 'shared/helpers/RouteHelpers';
import Order from 'shared/types/order';
import { CarrierRatingDialog } from './CarrierRatingDialog';
import { useCarrierLastRatedAt } from './data/CarrierRatingAPI';

interface RatingBannerProps {
  order: Order;
}

export function RatingBanner({ order }: RatingBannerProps) {
  const [_, setQuery] = useQuery();
  const [open, setOpen] = useState(false);
  const { data: lastRatedAt } = useCarrierLastRatedAt(
    order.active_offer?.carrier_guid,
  );

  const showBanner =
    order.status === 'delivered' &&
    order.active_offer?.carrier_type !== 'BROKER' &&
    lastRatedAt != null &&
    lastRatedAt.rated_at == null;

  return (
    <>
      {order.active_offer && (
        <CarrierRatingDialog
          open={open}
          carrierGuid={order.active_offer.carrier_guid}
          onClose={() => {
            setOpen(false);
            setQuery({ utm_medium: undefined });
          }}
          onSubmitSuccess={() => {
            setOpen(false);
            setQuery({ utm_medium: undefined });
          }}
        />
      )}

      <Banner
        in={showBanner}
        border="bottom"
        mountOnEnter={true}
        unmountOnExit={true}
        aria-label="Rating Banner"
      >
        <Inline verticalAlign="center" horizontalAlign="center" space="small">
          <Inline
            space="xxsmall"
            verticalAlign="center"
            horizontalAlign="center"
          >
            <Typography color="inherit">
              Rate your recent experience with{' '}
            </Typography>

            {order.active_offer && (
              <Link
                target="_blank"
                rel="noreferrer"
                color="inherit"
                href={`/manage-carriers/${order.active_offer.carrier_guid}?utm_medium=Order View&utm_content=Rating Banner`}
              >
                {order.active_offer.carrier_name}
              </Link>
            )}
          </Inline>

          <Button
            size="small"
            variant="neutral"
            aria-label="Rate Button"
            onClick={() => {
              setOpen(true);
              setQuery({ utm_medium: 'Order View' });
            }}
          >
            Rate Carrier
          </Button>
        </Inline>
      </Banner>
    </>
  );
}
