import { Expose, Transform } from 'class-transformer';
import { CargoPolicy, Carrier } from '../types/carrier';

export class LinkedBrokerDTO implements Carrier {
  fax?: string | null | undefined;
  website?: string | null | undefined;
  mc_number: null;
  terms_and_conditions: '';
  logo: string | null;
  @Expose() is_order_submit_allowed: boolean;
  @Expose() guid: string;
  @Expose() name: string;
  @Expose() email: string | null;
  @Expose() us_dot: string | null;
  @Expose() phone_numbers: string | null;
  @Expose() carrier_type: 'BROKER';
  @Expose() contact_name: string | null;
  @Expose() address: string | null;
  @Expose() city: string | null;
  @Expose() state: string | null;
  @Expose() zip: string | null;
  @Expose() cargo_insurance: null;
  @Expose() dba_name: null;
  @Expose() usdot_certificate: null;
  @Expose() w9: null;
  @Expose() broker_records: undefined;

  @Expose()
  @Transform(() => false)
  is_preferred: false;

  @Expose()
  @Transform(() => false)
  is_super: false;
  @Expose()
  @Transform(() => false)
  is_verified: false;
  @Expose() has_signed_up: boolean;
  @Expose() is_super_pay_enabled: boolean;

  @Expose()
  @Transform(
    (): CargoPolicy => ({
      agent_name: null,
      agent_phone: null,
      deductible: null,
      limit: null,
      policy_expiration_date: null,
      auto_policy_number: null,
      cargo_policy_number: null,
    }),
  )
  cargo_policy: CargoPolicy;
}
