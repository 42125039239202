export function isNotificationSupported() {
  return typeof window !== 'undefined' && 'Notification' in window;
}

export function requestNotificationPermission() {
  return new Promise((resolve, reject) => {
    if (Notification.permission === 'granted') {
      resolve(Notification.permission);
      return;
    }

    // handle deprecated callback version
    const promise = Notification.requestPermission(resolve);

    // handle promise version
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (promise) {
      promise.then(resolve).catch(reject);
    }
  });
}
