import { updatedDiff } from 'deep-object-diff';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import {
  CarrierBrokerPreferencesUpdatePayload,
  CarrierBrokerStatusUpdatePayload,
} from 'shared/types/carrier';

export function trackUpdatedInternalRecords(
  oldValues: CarrierBrokerPreferencesUpdatePayload,
  newValues: CarrierBrokerPreferencesUpdatePayload,
) {
  trackEventLegacy(
    'Updated Carrier Profile Internal Records',
    updatedDiff(oldValues, newValues),
  );
}

export function trackUpdatedCarrierStatus(
  oldValues: CarrierBrokerStatusUpdatePayload,
  newValues: CarrierBrokerStatusUpdatePayload,
) {
  trackEventLegacy('Updated Carrier Status', updatedDiff(oldValues, newValues));
}
