import { useMemo } from 'react';
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query';
import { QueryFilters } from 'react-query/types/core/utils';
import { APIQueryInput } from 'shared/api/APIQuery';
import { Schema } from 'yup';
import { APIPageResponse } from './API';

export type BaseAPIListQueryOptions<TData> = Omit<
  UseInfiniteQueryOptions<APIPageResponse<TData>['data'], Error>,
  | 'queryFn'
  | 'queryKey'
  | 'queryHash'
  | 'queryKeyHashFn'
  | 'isDataEqual'
  | 'getNextPageParam'
  | 'getPreviousPageParam'
>;

export interface APIListQueryOptions<TData>
  extends BaseAPIListQueryOptions<TData> {
  schema?: Schema<TData>;
  normalize?: (response: unknown) => TData;
}

export type APIListQueryData<TData = unknown> = InfiniteData<
  APIPageResponse<TData>['data']
>;

export type APIListQueryResult<TData> = UseInfiniteQueryResult<
  APIPageResponse<TData>['data'],
  Error
> & {
  list: TData[] | undefined;
};

export function useAPIListQuery<TData>(
  input: APIQueryInput,
  query: (page: number) => Promise<APIPageResponse<TData>['data']>,
  {
    schema,
    normalize,
    refetchOnWindowFocus = false,
    ...options
  }: APIListQueryOptions<TData>,
): APIListQueryResult<TData> {
  const result = useInfiniteQuery<APIPageResponse<TData>['data'], Error>(
    input,
    ({ pageParam }) =>
      query(pageParam).then((response) => {
        if (schema || normalize) {
          response.objects = response.objects.map((entry) => {
            if (schema) {
              entry = schema.cast(entry);
            }

            if (normalize) {
              entry = normalize(entry);
            }

            return entry;
          });
        }

        return response;
      }),
    {
      ...options,
      refetchOnWindowFocus,
      getNextPageParam: (lastPage) => {
        const { page, total_pages } = lastPage.pagination;

        if (page + 1 < total_pages) {
          return page + 1;
        }

        return undefined;
      },
    },
  );

  const list = useMemo(() => {
    return result.data?.pages.flatMap((page) => page.objects);
  }, [result.data]);

  return { ...result, list };
}

export function forEachAPIListQueryItem<TData>(
  input: APIQueryInput,
  queryClient: QueryClient,
  fn: (item: TData) => void | false,
  filters?: QueryFilters,
): void {
  for (const { state } of queryClient.getQueryCache().findAll(input, filters)) {
    if (state.data) {
      const { pages } = state.data as APIListQueryData<TData>;

      for (const page of pages) {
        for (const item of page.objects) {
          if (fn(item) === false) return;
        }
      }
    }
  }
}

export function findAPIListQueryItem<TData>(
  input: APIQueryInput,
  queryClient: QueryClient,
  fn: (item: TData) => boolean,
  filters?: QueryFilters,
): undefined | TData {
  let found: undefined | TData = undefined;

  forEachAPIListQueryItem<TData>(
    input,
    queryClient,
    (item) => {
      if (!fn(item)) return;
      found = item;
      return false;
    },
    filters,
  );

  return found;
}
