import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography/Typography';
import { Check } from '@material-ui/icons';
import { Color, Column, Columns } from '@superdispatch/ui';
import { ReactNode } from 'react';

interface RequirementItemProps {
  text: ReactNode;
  textColor?: TypographyProps['color'];
  iconColor?: Color;
}

export function RequirementItem({
  text,
  textColor = 'textPrimary',
  iconColor = Color.Dark100,
}: RequirementItemProps) {
  return (
    <Columns space="xsmall" align="center">
      <Column width="content">
        <Check fontSize="small" htmlColor={iconColor} />
      </Column>
      <Column width="fluid">
        {typeof text === 'string' ? (
          <Typography color={textColor}>{text}</Typography>
        ) : (
          text
        )}
      </Column>
    </Columns>
  );
}
