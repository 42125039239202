import { FieldValidator, useField, useFormikContext } from 'formik';
import {
  ATTACHMENT_ACCEPT_TYPES,
  ATTACHMENT_ALLOWED_MIME_TYPES,
  ATTACHMENT_MAX_FILE_SIZE,
} from '../config/AttachmentConstants';
import { formatNumber } from '../helpers/IntlHelpers';
import { validateFile } from '../helpers/ValidationHelpers';
import { FileField, FileFieldProps } from './FileField';

function validateFileMeta(value: unknown) {
  return value instanceof File
    ? validateFile(value, {
        allowFormats: ATTACHMENT_ALLOWED_MIME_TYPES,
        maxSize: ATTACHMENT_MAX_FILE_SIZE,
        maxSizeMessage: `File size should be less than ${formatNumber(
          ATTACHMENT_MAX_FILE_SIZE / (1024 * 1024),
        )} MB`,
      })
    : undefined;
}

interface FormikFileFieldProps extends FileFieldProps {
  validate?: FieldValidator;
}

export function FormikFileField({
  name,
  disabled,
  validate = validateFileMeta,
  accept = ATTACHMENT_ACCEPT_TYPES,
  helperText,
  ...rest
}: FormikFileFieldProps) {
  const { isSubmitting, dirty } = useFormikContext();
  const [field, { error }, { setValue }] = useField<string | undefined | File>({
    name,
    validate,
  });

  const errorMessage = dirty && error;

  return (
    <FileField
      name={name}
      value={field.value || ''}
      onChange={setValue}
      disabled={disabled || isSubmitting}
      error={!!errorMessage}
      helperText={errorMessage || helperText}
      accept={accept}
      {...rest}
    />
  );
}
