import { Link, TextField, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Button } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { get } from 'lodash-es';
import { useCustomerList } from 'orders/data/CounterpartAPI';
import { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { useCanExecute } from 'shared/data/UserPermissions';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useRecentSelections } from 'shared/helpers/RecentSelectionsHelpers';

export function useCustomerAutocompleteProps() {
  const [query, setQuery] = useState<string>('');

  const debouncedQuery = useDebouncedValue(query, 500);
  const { data, isLoading } = useCustomerList(
    debouncedQuery.trim().length < 3
      ? undefined
      : { query: debouncedQuery, sort: 'name,ASC' },
  );

  const options = useMemo(() => (data ? data.objects : []), [data]);

  return {
    query,
    options,
    loading: isLoading,
    onInputChange: (_event: ChangeEvent<{}>, input: string) => setQuery(input),
  };
}

export interface CustomerContact {
  title: string | null;
  name: string | null;
  phone: string | null;
  email: string | null;
  mobile_phone: string | null;
}

export interface TerminalAutocompleteValue
  extends Partial<Omit<CustomerDTO, 'guid'>> {
  guid?: string | null;
  contact?: CustomerContact;
}

interface AutocompleteButtonOption {
  name: string;
}

type CustomerAutocompleteOption =
  | TerminalAutocompleteValue
  | AutocompleteButtonOption;

export type CustomerChangeReason =
  | 'select-option'
  | 'save-as-new'
  | 'close'
  | 'clear';

interface CustomerAutocompleteProps {
  name: string;
  label: ReactNode;
  query: string;
  isNew: boolean;
  onChange: (
    value: TerminalAutocompleteValue,
    reason: CustomerChangeReason,
  ) => void;
  validate?: (value: TerminalAutocompleteValue) => void;
  options: CustomerAutocompleteOption[];
  loading: boolean;
  onInputChange: (event: ChangeEvent<{}>, value: string) => void;
}

export function CustomerAutocomplete({
  name,
  query,
  label,
  validate,
  isNew,
  options,
  loading,
  onInputChange,
  onChange: onChangeProp,
}: CustomerAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const canManageCustomer = useCanExecute('CUSTOMER_MANAGEMENT');
  const { customers: recentCustomers = [] } = useRecentSelections();

  const [{ value, onBlur }, { error }, { setValue }] = useField({
    name,
    validate,
  });

  const nameError = get(error, 'name');

  function handleChange(
    autocompleteValue: TerminalAutocompleteValue,
    reason: CustomerChangeReason,
  ) {
    // Done during https://superdispatch.atlassian.net/browse/STMS-1660 task
    // Problem is same 'note' field used in CustomerDTO and OrderDTO
    // which causes problems with initial data
    const { notes, ...withoutNotes } = autocompleteValue;
    setValue(withoutNotes);
    onChangeProp(withoutNotes, reason);
  }

  function handleSaveAsNew(reason: CustomerChangeReason) {
    setOpen(false);
    handleChange({ ...value, guid: null, name: query }, reason);
  }

  const optionsWithSaveAsNewButton = useMemo(() => {
    if (options.length === 0 && query.length < 3) {
      return recentCustomers;
    }

    if (canManageCustomer) {
      return [...options, { name: 'save_as_new_button' }];
    }

    return options;
  }, [canManageCustomer, options, query, recentCustomers]);

  return (
    <Autocomplete
      open={open}
      onBlur={onBlur}
      popupIcon={null}
      options={optionsWithSaveAsNewButton}
      value={value}
      loading={loading}
      onInputChange={onInputChange}
      clearOnBlur={!canManageCustomer}
      filterOptions={(data) => data}
      onOpen={() => setOpen(true)}
      onClose={(_, reason) => {
        setOpen(false);

        if (
          query.length > 0 &&
          canManageCustomer &&
          query !== value.name &&
          reason !== 'toggleInput' &&
          reason !== 'select-option'
        ) {
          handleSaveAsNew('close');
        }
      }}
      getOptionSelected={(option, selectedValue) =>
        option.name === selectedValue.name
      }
      getOptionLabel={(option) => option.name || ''}
      onChange={(_event, changedValue) => {
        if (changedValue?.name === 'save_as_new_button') {
          handleSaveAsNew('save-as-new');
          return;
        }

        if (changedValue) {
          handleChange(changedValue, 'select-option');
        } else {
          handleChange({ business_type: value.business_type }, 'clear');
        }
      }}
      noOptionsText={
        query.length < 3 ? 'Type at least 3 characters' : 'No option'
      }
      renderOption={(option: CustomerAutocompleteOption) => {
        if (option.name === 'save_as_new_button') {
          return (
            <Button variant="text" startIcon={<Add />}>
              Save as New Customer
            </Button>
          );
        }
        return option.name;
      }}
      groupBy={() =>
        options.length === 0 && recentCustomers.length > 0 ? 'Recents' : ''
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={true}
          error={!!nameError}
          helperText={
            nameError ? (
              nameError
            ) : isNew && options.length > 0 ? (
              <Typography component="span">
                Similar customer already exists.{' '}
                <Link href="#" color="primary" onClick={() => setOpen(true)}>
                  Review
                </Link>
              </Typography>
            ) : undefined
          }
          placeholder="Type at least 3 characters"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
