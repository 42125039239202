import { FormikMaxLengthTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { Divider } from '../../shared/ui/Divider';
import { internalNotesHint, notesForCarrierHint } from './CustomerDetailsNotes';
import {
  CustomerEditDrawer,
  CustomerEditDrawerProps,
} from './CustomerEditDrawer';

type Props = Omit<CustomerEditDrawerProps, 'title' | 'children'>;

export function CustomerDetailsNotesEdit({ customer, ...props }: Props) {
  return (
    <CustomerEditDrawer
      {...props}
      customer={customer}
      title={
        customer.notes || customer.internal_notes ? 'Edit Notes' : 'Add Notes'
      }
    >
      <Stack space="small">
        <FormikMaxLengthTextField
          label="Notes for Carrier"
          name="notes"
          maxLength={2000}
          multiline={true}
          helperText={notesForCarrierHint}
        />

        <Divider />

        <FormikMaxLengthTextField
          maxLength={2000}
          multiline={true}
          label="Internal Notes"
          name="internal_notes"
          helperText={internalNotesHint}
        />
      </Stack>
    </CustomerEditDrawer>
  );
}
