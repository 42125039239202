import { createSvgIcon } from './IconUtils';

export const FaxIcon = createSvgIcon(
  'Fax',
  <path
    d="M7.33331 4H10.6666V5.33333H7.33331V4ZM5.33331 6V2H12.6666V6C13.1971 6 13.7058 6.21071 14.0809 6.58579C14.4559 6.96086 14.6666 7.46957 14.6666 8V12H12.6666V14H5.33331V12H4.66665V6H5.33331ZM6.66665 3.33333V6H11.3333V3.33333H6.66665ZM6.66665 10V12.6667H11.3333V10H6.66665ZM12.6666 7.33333C12.4898 7.33333 12.3203 7.40357 12.1952 7.5286C12.0702 7.65362 12 7.82319 12 8C12 8.17681 12.0702 8.34638 12.1952 8.4714C12.3203 8.59643 12.4898 8.66667 12.6666 8.66667C12.8435 8.66667 13.013 8.59643 13.1381 8.4714C13.2631 8.34638 13.3333 8.17681 13.3333 8C13.3333 7.82319 13.2631 7.65362 13.1381 7.5286C13.013 7.40357 12.8435 7.33333 12.6666 7.33333ZM2.66665 6H3.33331C3.51012 6 3.67969 6.07024 3.80472 6.19526C3.92974 6.32029 3.99998 6.48986 3.99998 6.66667V11.3333C3.99998 11.5101 3.92974 11.6797 3.80472 11.8047C3.67969 11.9298 3.51012 12 3.33331 12H2.66665C2.31302 12 1.97389 11.8595 1.72384 11.6095C1.47379 11.3594 1.33331 11.0203 1.33331 10.6667V7.33333C1.33331 6.97971 1.47379 6.64057 1.72384 6.39052C1.97389 6.14048 2.31302 6 2.66665 6Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
