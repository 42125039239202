import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import styled from 'styled-components';
import { useOrderImportAPI } from './data/OrderImportAPI';
import { OrderImportInfoDTO } from './data/OrderImportInfoDTO';
import { OrderImportDropZone } from './OrderImportDropZone';
import { OrderImportInstructions } from './OrderImportInstructions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
interface OrderImportFormProps {
  onImportSuccess: (data: OrderImportInfoDTO) => void;
}

export function OrderImportForm({ onImportSuccess }: OrderImportFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { importOrders } = useOrderImportAPI();

  const importForm = useFormikEnhanced<{ file?: File }, OrderImportInfoDTO>({
    initialValues: {},
    onSubmit({ file }) {
      if (!file) {
        return Promise.reject(new Error('No file selected'));
      }

      return importOrders(file);
    },
    onSubmitSuccess(response) {
      onImportSuccess(response);
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  return (
    <Wrapper>
      <OrderImportDropZone
        isUploading={importForm.isSubmitting}
        isRejected={importForm.status.type === 'rejected'}
        onDrop={(file) => {
          importForm.setFieldValue('file', file);
          importForm.handleSubmit();
        }}
      />
      <OrderImportInstructions />
    </Wrapper>
  );
}
