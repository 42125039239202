import { Container } from '@material-ui/core';
import { ProgressStep, ProgressStepper } from 'shared/ui/ProgressStepper';
import styled from 'styled-components';

export const StepsContainer = styled(Container)`
  max-width: 500px;
  padding: 0;
`;

interface PlanProStepperProps {
  step: 1 | 2;
  onStepChange: (step: 1 | 2) => void;
}

export function PlanProStepper({ step, onStepChange }: PlanProStepperProps) {
  return (
    <StepsContainer>
      <ProgressStepper>
        <ProgressStep
          title="Step 1"
          subtitle="Choose Your Package"
          isActive={step === 1}
          isCompleted={false}
          onClick={() => onStepChange(1)}
        />
        <ProgressStep
          title="Step 2"
          subtitle="Add Payment Info"
          isActive={step === 2}
          isCompleted={false}
          onClick={() => onStepChange(2)}
        />
      </ProgressStepper>
    </StepsContainer>
  );
}
