import { Color } from '@superdispatch/ui';

export function ErrorInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00001 0.333252C3.32001 0.333252 0.333344 3.31992 0.333344 6.99992C0.333344 10.6799 3.32001 13.6666 7.00001 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 7.00001 0.333252ZM7.66668 10.3333H6.33334V6.33325H7.66668V10.3333ZM7.66668 4.99992H6.33334V3.66659H7.66668V4.99992Z"
        fill={Color.Red500}
      />
    </svg>
  );
}
