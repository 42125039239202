import { useSearchInputState } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { useCarriers } from './data/DictionaryAPI';
import { DictionaryCarrierDTO } from './data/DictionaryDTO';

function formatOption(option: DictionaryCarrierDTO): string {
  return option.name;
}

const StyledWrapper = styled.div`
  div {
    color: #6f7681;
  }
`;

interface CarrierAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<DictionaryCarrierDTO>,
    'options' | 'onChange'
  > {
  onChange: (values: DictionaryCarrierDTO[]) => void;
}

export function CarrierAutocomplete({
  onChange,
  ...props
}: CarrierAutocompleteProps) {
  const { input, query, setInput } = useSearchInputState();
  const { isLoading, data: { objects = [] } = {} } = useCarriers(query);

  return (
    <AutocompleteBase
      {...props}
      placeholder="3 symbols to search"
      options={objects}
      inputValue={input}
      loading={isLoading}
      onInputChange={(_, text) => setInput(text)}
      getOptionLabel={(option) => formatOption(option)}
      onChange={(_, option) => onChange(option)}
      renderOption={(option) =>
        typeof option === 'string' ? (
          option
        ) : (
          <StyledWrapper>
            {option.name}
            {option.usdot_number && <div>USDOT: {option.usdot_number}</div>}
            {option.phone && <div>{option.phone}</div>}
            {option.email && <div>{option.email}</div>}
          </StyledWrapper>
        )
      }
    />
  );
}
