import { Box, CardContent, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { formatDateType } from '@superdispatch/sdk';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';
import { Waypoints } from 'shared/ui/Waypoints';
import { makeCityStateAddress } from 'shared/utils/AddressUtils';
import styled from 'styled-components';
import { Step } from '../../../shared/types/step';
import { calculatePriceNegotiationInfo } from '../../data/dto/PriceNegotiationDTO';
import { usePriceNegotiation } from '../../data/PriceNegotiationAPI';

const OrderRequestInfoBox = styled.div`
  padding-left: 16px;
  background: ${Color.Silver200};
  border-bottom: 1px solid #eaebec;
  border-top: 1px solid #eaebec;
`;

interface Props {
  order: Order;
}

function formatWaypoints(step: Step) {
  if (
    step.date_type === 'estimated' &&
    (step.scheduled_at || step.scheduled_ends_at)
  ) {
    return {
      title: `${makeCityStateAddress(step.venue)} `,
      subtitle: (
        <Inline space="xxsmall">
          <FormattedDate
            variant="ShortDate"
            format="JodaISO"
            date={step.scheduled_at}
            fallback="..."
          />
          -
          <FormattedDate
            variant="ShortDate"
            format="JodaISO"
            date={step.scheduled_ends_at}
            fallback="..."
          />
        </Inline>
      ),
    };
  }

  if (
    step.date_type === 'not_earlier_than' ||
    step.date_type === 'not_later_than'
  ) {
    return {
      title: `${makeCityStateAddress(step.venue)} `,
      subtitle: (
        <Inline space="xxsmall">
          <FormattedDate
            variant="ShortDate"
            format="JodaISO"
            date={step.scheduled_at}
            fallback="No date available"
          />
          · {formatDateType(step.date_type)}
        </Inline>
      ),
    };
  }
  return {
    title: `${makeCityStateAddress(step.venue)} `,
    subtitle: step.scheduled_at ? (
      <FormattedDate
        variant="ShortDate"
        format="JodaISO"
        date={step.scheduled_at}
      />
    ) : (
      'No Date'
    ),
  };
}

export function OrderRequestsOrderCard({ order }: Props) {
  const { data: priceNegotiation } = usePriceNegotiation(order);
  const priceNegotiationInfo = useMemo(
    () =>
      priceNegotiation?.active
        ? calculatePriceNegotiationInfo(order.price, priceNegotiation)
        : null,
    [order.price, priceNegotiation],
  );
  const today = DateTime.local().startOf('day');

  const firstAvailableVehicleInfo =
    (order.vehicles?.[0] && VehicleDTO.formatVehicleModel(order.vehicles[0])) ||
    '';

  const moreVehiclesTitle =
    (order.vehicles &&
      order.vehicles.length > 1 &&
      `+${order.vehicles.length - 1} more`) ||
    '';

  return (
    <OrderRequestInfoBox aria-label="order info">
      <CardContent>
        <Stack space="small">
          <Typography
            aria-label="Order number"
            variant="h4"
            color="textSecondary"
          >
            {order.number}
          </Typography>

          <Columns space="xsmall" collapseBelow="tablet">
            {order.price != null && (
              <Column width="content">
                <Inline space="xxsmall">
                  <Stack space="xsmall">
                    <InlineBulletItems>
                      <Typography aria-label="Order price">
                        <NumericFormat
                          displayType="text"
                          value={order.price}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </Typography>

                      <Typography variant="caption" color="textSecondary">
                        Total Carrier Price
                      </Typography>
                    </InlineBulletItems>

                    {priceNegotiationInfo && (
                      <InlineBulletItems>
                        <Typography>
                          <NumericFormat
                            prefix="$"
                            displayType="text"
                            thousandSeparator={true}
                            value={
                              priceNegotiationInfo.currentIteration > 0
                                ? formatCurrency(
                                    priceNegotiationInfo.currentPrice,
                                    {
                                      maximumFractionDigits: 0,
                                    },
                                  )
                                : order.price
                            }
                          />
                        </Typography>

                        <Typography variant="caption" color="textSecondary">
                          {priceNegotiationInfo.currentIteration > 0 &&
                          priceNegotiation?.increase_by_percent &&
                          priceNegotiation.increase_every
                            ? `Day ${
                                priceNegotiationInfo.currentDay
                              } Acceptable bid${
                                priceNegotiationInfo.maxDate.startOf('day') <=
                                today
                                  ? ', Max bid reached'
                                  : ''
                              }`
                            : 'Acceptable bid'}
                        </Typography>
                      </InlineBulletItems>
                    )}
                  </Stack>
                </Inline>
              </Column>
            )}

            {order.customer_payment?.tariff != null &&
              order.customer_payment.tariff > 0 && (
                <Column width="content">
                  <InlineBulletItems>
                    <Typography aria-label="Order tariff">
                      <NumericFormat
                        prefix="$"
                        displayType="text"
                        thousandSeparator={true}
                        value={order.customer_payment.tariff}
                      />
                    </Typography>

                    <Typography variant="caption" color="textSecondary">
                      Total Tariff
                    </Typography>
                  </InlineBulletItems>
                </Column>
              )}
          </Columns>

          {order.pickup && order.delivery && (
            <Waypoints
              from={formatWaypoints(order.pickup)}
              to={formatWaypoints(order.delivery)}
            />
          )}

          <Box>
            <Typography gutterBottom={false}>
              {firstAvailableVehicleInfo}
            </Typography>

            <Typography color="textSecondary">{moreVehiclesTitle}</Typography>
          </Box>
        </Stack>
      </CardContent>
    </OrderRequestInfoBox>
  );
}
