import { Box, Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDate, FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { useState } from 'react';
import {
  useInvalidateSubscriptionDetails,
  useSubscriptionDetails,
} from 'shared/errors/paywall/data/PaywallAPI';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { ErrorState } from 'shared/ui/ErrorState';
import { Waypoints2 } from 'shared/ui/Waypoints2';
import { CancelSubscriptionButton } from './CancelSubscriptionButton';
import { CancelSuccessDialog } from './CancelSuccessDialog';
import { DisplayCard } from './DisplayCard';
import { useReactivateSubscription } from './useReactivateSubscription';
import { useSubscriptionPlan } from './useSubscriptionPlan';

export function SubscriptionPlanInformationCard() {
  const { plan, planError, isPlanLoading } = useSubscriptionPlan();
  const { data } = useSubscriptionDetails();
  const { reactivateSubscription, isReactivatingSubscription } =
    useReactivateSubscription();
  const isTrial = data?.chargebee.status === 'IN_TRIAL';
  const isCanceled = data?.chargebee.status === 'NON_RENEWING';
  const showCancelButton =
    (!isTrial && !isCanceled) ||
    (isTrial &&
      data.chargebee.trial_end_action === 'ACTIVATE_SUBSCRIPTION' &&
      !data.chargebee.cancelled_at);
  const showReactivateButton =
    data?.chargebee.status === 'NON_RENEWING' && data.chargebee.cancelled_at;
  const showRenewalDate = !isTrial && !isCanceled && plan?.renewalDate;

  if (plan?.isNonStandard) {
    return null;
  }

  return (
    <DisplayCard title="Plan Information">
      {isPlanLoading ? (
        <CardSkeletonContent />
      ) : planError ? (
        <ErrorState error={planError} />
      ) : plan ? (
        <Stack space="small">
          <Stack space="xsmall">
            <Stack space="xxsmall">
              <Columns>
                <Column width="fluid">
                  <Typography variant="h4">{plan.plan}</Typography>
                </Column>
                <Column width="content">
                  {plan.monthlyPlanPrice !== null && (
                    <>
                      <Typography component="span" variant="h4">
                        {formatCurrency(plan.monthlyPlanPrice, {
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                      <Typography
                        component="span"
                        variant="h5"
                        color="textSecondary"
                      >
                        /monthly
                      </Typography>
                    </>
                  )}
                </Column>
              </Columns>
              <Typography color="textSecondary">Billed monthly</Typography>
            </Stack>
            <Divider />
            <Box component="ul" padding={0} paddingLeft="14px" margin={0}>
              <Typography component="li">
                Up to {plan.maxPlanVehicles} VINs (month)
              </Typography>
              <Typography component="li">
                {plan.perVehiclePrice !== null &&
                  formatCurrency(plan.perVehiclePrice, {
                    maximumFractionDigits: 2,
                  })}{' '}
                per VIN additional usage
              </Typography>
            </Box>
          </Stack>
          {showReactivateButton && (
            <Box marginTop="small">
              <Button
                fullWidth={true}
                variant="primary"
                size="large"
                disabled={isReactivatingSubscription}
                onClick={() => {
                  reactivateSubscription();
                }}
              >
                Reactivate
              </Button>
            </Box>
          )}

          {isTrial && (
            <Waypoints2
              startPrimaryText="Today"
              startSecondaryText="Trial"
              startLeadingText="Free Trial"
              endPrimaryText={
                <FormattedDate
                  date={data.chargebee.trial_end}
                  variant="Date"
                  format="DateTimeISO"
                  fallback="N/A"
                />
              }
              endSecondaryText={plan.plan}
              endLeadingPrimaryText={
                <Inline space="xxsmall">
                  <Price value={plan.monthlyPlanPrice} />
                  <Typography color="textSecondary">
                    + Applicable taxes
                  </Typography>
                </Inline>
              }
              endLeadingSecondaryText="Every Month"
            />
          )}

          {showRenewalDate && (
            <Typography>
              Renews on {formatDate(plan.renewalDate, { variant: 'Date' })}
            </Typography>
          )}

          {showCancelButton && (
            <CancelSubscriptionButtonWithSuccessDialog isTrial={isTrial} />
          )}
        </Stack>
      ) : null}
    </DisplayCard>
  );
}

function CancelSubscriptionButtonWithSuccessDialog({
  isTrial,
}: {
  isTrial: boolean;
}) {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const invalidateSubscriptionDetails = useInvalidateSubscriptionDetails();

  function handleCancelSuccess() {
    if (isTrial) {
      invalidateSubscriptionDetails();
      return;
    }
    setIsSuccessDialogOpen(true);
  }

  return (
    <>
      <CancelSubscriptionButton onCancelSuccess={handleCancelSuccess} />
      <CancelSuccessDialog
        open={isSuccessDialogOpen}
        onClose={() => {
          invalidateSubscriptionDetails();
          setIsSuccessDialogOpen(false);
        }}
      />
    </>
  );
}

function CardSkeletonContent() {
  return (
    <Stack space="small">
      <Skeleton width={328} />
      <Skeleton width={328} />
      <Skeleton width={328} />
      <Skeleton width={328} />
    </Stack>
  );
}
