import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { FormattedRelativeTime, parseDate } from '@superdispatch/dates';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import { isSuperpayPayment } from 'core/SuperPayUtils';
import { DateTime } from 'luxon';
import {
  useOrderInspectionPhotoIssues,
  useRelatedLegsMap,
  useRelatedLoadBasicInfo,
} from 'orders/data/OrderAPI';
import { OrderDiagram } from 'orders/diagram/OrderDiagram';
import * as React from 'react';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { getAppType } from 'shared/data/AppUtils';
import { useProductTiers } from 'shared/data/TiersUtils';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import {
  CarrierRequestedSuperPay,
  useSuperPayPaymentDueOn,
} from 'shared/helpers/superpay/SuperPayHelpers';
import { LoadBasicInfo, OrderWithLoadsDTO } from 'shared/types/load';
import Order from 'shared/types/order';
import { ShipperType } from 'shared/types/user';
import { DeliveryETA } from 'shared/ui/DeliveryETA';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { PickupETA } from 'shared/ui/PickupETA';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { Waypoints } from 'shared/ui/Waypoints';
import { makeCityStateZip } from 'shared/utils/AddressUtils';
import styled from 'styled-components';
import {
  OrderListPayment,
  OrderListPrice,
  OrderListVehicles,
  OrderListVehiclesVins,
} from '.';
import { getOnlineBOLUrl } from '../actions/OrderActionUtils';
import { isOwnDemoOrder } from '../DemoOrderUtils';
import { OrderStatus } from '../index';
import { OrderInspectionPhotoIssues } from '../OrderInspectionPhotoIssues';
import { ViewOrderRoute } from '../ViewOrderRoute';
import { GrossProfit } from './GrossProfit';
import { SortKey, SortOrder } from './OrderTable';
import { OrderWaypointInfo } from './OrderWaypointInfo';

interface OrderListCardViewProps {
  orders: Order[];
  isLoading: boolean;
  selectedOrders: Order[];
  renderActions?: (order: Order) => React.ReactNode;
  renderNumber?: (order: Order) => React.ReactNode;
  searchQuery?: string;
  toggleSelectOrder: (id: number) => void;
  disableSelectable?: boolean;
}

interface ListCardProps {
  order: Order;
  isFirst?: boolean;
  parentLoadInfo?: LoadBasicInfo;
  relatedLoads: LoadBasicInfo[];
  relatedLegs?: OrderWithLoadsDTO['loads'];
  hasUnassignedVehicles?: OrderWithLoadsDTO['has_unassigned_vehicles'];
  renderActions?: (order: Order) => React.ReactNode;
  renderNumber?: (order: Order) => React.ReactNode;
  searchQuery?: string;
  shipperType?: ShipperType;
  selected?: boolean;
  toggleSelectOrder: (id: number) => void;
  disableSelectable?: boolean;
  inspectionPhotoIssuesCount?: number;
}

interface OrderListCardSortBarProps {
  disableSelectable?: boolean;
  selectedOrders: Order[];
  orders: Order[];
  isLoading: boolean;
  sortKey?: SortKey;
  sortOrder?: SortOrder;
  onSelectAllOrders: () => void;
  onUnselectAllOrders: () => void;
  onSort?: (sortKey: SortKey, sortOrder: SortOrder) => void;
}

const ORDER_VIEW_SORT_GROUPS = [
  [
    {
      field: 'number',
      value: 'Order ID',
    },
  ],
  [
    {
      field: 'changedAt',
      value: 'Updated Date',
    },
    {
      field: 'createdAt',
      value: 'Creation Date',
    },
    {
      field: 'pickup.scheduledAt',
      value: 'Carrier Pickup Date',
    },
    {
      field: 'delivery.scheduledAt',
      value: 'Carrier Delivery Date',
    },
  ],

  [
    {
      field: 'pickup.venue.city',
      value: 'Pickup City',
    },
    {
      field: 'pickup.venue.name',
      value: 'Pickup Business Name',
    },
    {
      field: 'delivery.venue.city',
      value: 'Delivery City',
    },
    {
      field: 'delivery.venue.name',
      value: 'Delivery Business Name',
    },
  ],

  [
    {
      field: 'activeOffer.carrierName',
      value: 'Carrier Name',
    },
    {
      field: 'customer.name',
      value: 'Customer Name',
    },
  ],
];

const BASIC_TIER_ORDER_VIEW_SORT_GROUPS = [
  [
    {
      field: 'number',
      value: 'Order ID',
    },
  ],
  [
    {
      field: 'changedAt',
      value: 'Updated Date',
    },
    {
      field: 'createdAt',
      value: 'Creation Date',
    },
    {
      field: 'pickup.scheduledAt',
      value: 'Carrier Pickup Date',
    },
    {
      field: 'delivery.scheduledAt',
      value: 'Carrier Delivery Date',
    },
  ],

  [
    {
      field: 'pickup.venue.city',
      value: 'Pickup City',
    },
    {
      field: 'pickup.venue.name',
      value: 'Pickup Business Name',
    },
    {
      field: 'delivery.venue.city',
      value: 'Delivery City',
    },
    {
      field: 'delivery.venue.name',
      value: 'Delivery Business Name',
    },
  ],

  [
    {
      field: 'activeOffer.carrierName',
      value: 'Carrier Name',
    },
  ],
];

const StyledFormControl = styled(FormControl)`
  min-width: 120px;
`;

const StyledColumnsWrap = styled(Columns)`
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledOrderListVehiclesVins = styled.div`
  color: ${Color.Grey200};
`;

const DemoOrderBackground = styled.div`
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-52%, -133%) rotate(-8.632deg);

  font-size: 125px;
  font-family: Inter;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  text-wrap: nowrap;
`;

const StyledBox = styled(Box)`
  width: 100%;
  transition: all 0.3s ease;
  height: initial;
  background-color: ${Color.Silver200};
  z-index: 2;
  padding: 16px;
  box-sizing: border-box;
`;

const StyledWrap = styled.div`
  display: contents;
  & > div {
    z-index: 1;
  }
`;

function ListCard({
  order,
  isFirst,
  parentLoadInfo,
  relatedLoads,
  relatedLegs,
  hasUnassignedVehicles,
  renderActions,
  renderNumber,
  searchQuery,
  shipperType,
  selected,
  toggleSelectOrder,
  disableSelectable,
  inspectionPhotoIssuesCount,
}: ListCardProps) {
  const { user } = useUserState();
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const { isShowPaymentDueOn, isDateLessCurrent, paymentExpectedDate } =
    useSuperPayPaymentDueOn(order);
  const isMobile = platform === 'mobile';
  const isTablet = platform === 'tablet';
  const isBroker = shipperType === 'BROKER';
  const pickupAddress = makeCityStateZip(order.pickup?.venue);
  const deliveryAddress = makeCityStateZip(order.delivery?.venue);

  const showCarrierRequestedSuperPayNudging =
    isBroker &&
    !!order.invoice?.is_carrier_requested_superpay &&
    !isSuperpayPayment(order.payment) &&
    (order.status === 'invoiced' || order.status === 'delivery_verified');

  const hasPickupInfo = pickupAddress || order.pickup?.scheduled_at;
  const hasDeliveryInfo = deliveryAddress || order.delivery?.scheduled_at;

  const isCustomerVisible = user
    ? user.order_form_settings.is_customer_block_visible || order.customer?.name
    : false;

  const pickupETADate = parseDate(
    order.eta_information?.pickup_eta?.date_until,
    {
      format: 'JodaISO',
    },
  );

  const now = DateTime.local();

  const showPickupETA =
    !order.pickup?.completed_at &&
    pickupETADate.toMillis() > now.toMillis() &&
    order.eta_information?.pickup_eta?.date_until;

  const { isAdvancedTier } = useProductTiers();

  const viewRouteLink =
    !!order.pickup?.venue && !!order.delivery?.venue ? (
      <Box pt="8px">
        <ViewOrderRoute
          origin={order.pickup.venue}
          destination={order.delivery.venue}
        />
      </Box>
    ) : null;

  return (
    <Box
      px={2}
      py={2}
      bgcolor={Color.White}
      m={isMobile || isTablet ? 0 : 2}
      borderRadius={4}
      border={`1px solid ${Color.Silver400}`}
      style={{ marginTop: isFirst ? 0 : 16 }}
      position="relative"
      data-intercom-target={
        isOwnDemoOrder(order, user) ? 'demo order' : undefined
      }
      aria-label={`order-number-${order.number}`}
    >
      {order.is_demo && <DemoOrderBackground>Demo</DemoOrderBackground>}
      <Columns align="top" space="xsmall">
        {!isMobile && !disableSelectable && (
          <Column width="content">
            <Box mt="2px">
              <Checkbox
                onChange={() => toggleSelectOrder(order.id)}
                checked={!!selected}
              />
            </Box>
          </Column>
        )}
        <Column>
          <Stack space="small">
            <Columns space="small">
              <StyledWrap>
                <Column width="fluid">
                  <Box mt="4px">
                    <Stack space="xxsmall">
                      <Inline verticalAlign="center">
                        <Link
                          component={RouterLink}
                          to={
                            order.is_active
                              ? `/orders/view/${order.guid}`
                              : `/orders/inactive/view/${order.guid}`
                          }
                          data-intercom-target="order number"
                        >
                          <Typography variant="h3" component="div">
                            <TextOverflow size={isMobile ? 260 : 320}>
                              {order.number}
                            </TextOverflow>
                          </Typography>
                        </Link>
                        <OrderStatus
                          order={order}
                          showDelays={true}
                          inline={true}
                          hasUnassignedVehicles={hasUnassignedVehicles}
                        />
                        {parentLoadInfo && order.is_load && (
                          <Inline space="xxsmall">
                            <Typography color="textSecondary">
                              Relates to
                            </Typography>
                            <Link
                              component={RouterLink}
                              to={`/orders/view/${parentLoadInfo.related_order_guid}#related`}
                            >
                              {parentLoadInfo.related_order_number}
                            </Link>
                          </Inline>
                        )}
                        {relatedLoads.length > 0 && order.has_loads && (
                          <Inline space="xxsmall" verticalAlign="center">
                            <Typography color="textSecondary">
                              Consists of
                            </Typography>
                            <Link
                              component={RouterLink}
                              to={
                                order.is_active
                                  ? `/orders/view/${order.guid}#related`
                                  : `/orders/inactive/view/${order.guid}#related`
                              }
                            >
                              {relatedLoads.length}{' '}
                              {formatPlural(
                                relatedLoads.length,
                                'load',
                                'loads',
                              )}
                            </Link>
                          </Inline>
                        )}
                        {renderNumber?.(order)}
                      </Inline>

                      <Inline verticalAlign="center">
                        {showPickupETA && order.eta_information?.pickup_eta && (
                          <PickupETA
                            pickupETA={order.eta_information.pickup_eta}
                            inline={true}
                          />
                        )}

                        <DeliveryETA
                          delivery={order.delivery}
                          deliveryETA={order.eta_information?.delivery_eta}
                          inline={true}
                        />

                        <OrderInspectionPhotoIssues
                          onClick={() => {
                            trackEvent(
                              'Shipper Clicked Non-vehicle Photo Issues',
                              { utm_medium: 'order_list_card_view' },
                            );
                          }}
                          online_bol_url={getOnlineBOLUrl(
                            order,
                            'Inspection Photo Issues Link',
                          )}
                          inspection_photo_issues_count={
                            inspectionPhotoIssuesCount
                          }
                        />
                      </Inline>
                    </Stack>
                  </Box>
                </Column>
              </StyledWrap>
              <Column width="content">
                <Box alignSelf="flex-start">
                  <Inline verticalAlign="center">
                    {renderActions?.(order)}
                  </Inline>
                </Box>
              </Column>
            </Columns>

            {!!relatedLegs && (
              <Columns>
                <Column width={isMobile ? 'fluid' : isTablet ? '4/5' : '2/5'}>
                  <OrderDiagram
                    hasUnassignedVehicles={hasUnassignedVehicles}
                    loads={relatedLegs}
                    order={order}
                  />
                </Column>
              </Columns>
            )}
            <Columns collapseBelow="desktop" space="medium">
              {relatedLegs ? (
                <Column width="1/2">
                  <Columns>
                    <Column width="fluid">
                      {hasPickupInfo ? (
                        <OrderWaypointInfo order={order} type="pickup" />
                      ) : (
                        <Typography color="textSecondary">
                          No pickup info
                        </Typography>
                      )}
                    </Column>
                    <Column width="fluid">
                      {hasDeliveryInfo ? (
                        <OrderWaypointInfo order={order} type="delivery" />
                      ) : (
                        <Typography color="textSecondary">
                          No delivery info
                        </Typography>
                      )}
                    </Column>
                  </Columns>
                  {viewRouteLink}
                </Column>
              ) : (
                <Column>
                  <Waypoints
                    from={{
                      title: <OrderWaypointInfo order={order} type="pickup" />,
                    }}
                    to={{
                      title: (
                        <OrderWaypointInfo order={order} type="delivery" />
                      ),
                    }}
                    multilineTitle={true}
                    fullWidthTitle={true}
                  />
                  {viewRouteLink}
                </Column>
              )}
              <Column>
                <Stack space="xxsmall">
                  <Stack space="small">
                    {order.vehicles?.length && order.vehicles.length > 0 ? (
                      <Stack space="none">
                        <OrderListVehicles
                          order={order}
                          shipperType={shipperType}
                          vinSearchText={searchQuery}
                          compactView={true}
                        />
                        <StyledOrderListVehiclesVins>
                          <OrderListVehiclesVins
                            order={order}
                            vinSearchText={searchQuery}
                            compactView={true}
                          />
                        </StyledOrderListVehiclesVins>
                      </Stack>
                    ) : (
                      <Typography color="textSecondary">
                        No vehicles info
                      </Typography>
                    )}

                    {order.active_offer?.carrier_name ? (
                      isMobile ? (
                        order.active_offer.carrier_phone ? (
                          <Stack space="xxsmall">
                            <Columns>
                              <Column width="content">
                                {isBroker && order.active_offer.carrier_guid ? (
                                  <Link
                                    href={parseURITemplate(
                                      `/manage-carriers/${order.active_offer.carrier_guid}{?utm_medium,utm_content}`,
                                      {
                                        utm_medium: 'Orders List',
                                        utm_content: `${order.status}_order_status`,
                                      },
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <TextOverflow size={215}>
                                      {order.active_offer.carrier_name}
                                    </TextOverflow>
                                  </Link>
                                ) : (
                                  <TextOverflow size={215}>
                                    {order.active_offer.carrier_name}
                                  </TextOverflow>
                                )}
                              </Column>
                              <Column>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  · Carrier
                                </Typography>
                              </Column>
                            </Columns>

                            <Link
                              href={`tel:${order.active_offer.carrier_phone}`}
                              aria-label="contact phone"
                            >
                              {order.active_offer.carrier_phone}
                            </Link>
                          </Stack>
                        ) : (
                          <Columns>
                            <Column width="content">
                              {isBroker && order.active_offer.carrier_guid ? (
                                <Link
                                  href={parseURITemplate(
                                    `/manage-carriers/${order.active_offer.carrier_guid}{?utm_medium,utm_content}`,
                                    {
                                      utm_medium: 'Orders List',
                                      utm_content: `${order.status}_order_status`,
                                    },
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <TextOverflow size={215}>
                                    {order.active_offer.carrier_name}
                                  </TextOverflow>
                                </Link>
                              ) : (
                                <TextOverflow size={215}>
                                  {order.active_offer.carrier_name}
                                </TextOverflow>
                              )}
                            </Column>
                            <Column>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                · Carrier
                              </Typography>
                            </Column>
                          </Columns>
                        )
                      ) : (
                        <Stack space="none">
                          <Typography variant="caption" color="textSecondary">
                            Carrier
                          </Typography>
                          <Inline>
                            {isBroker && order.active_offer.carrier_guid ? (
                              <Link
                                href={parseURITemplate(
                                  `/manage-carriers/${order.active_offer.carrier_guid}{?utm_medium,utm_content}`,
                                  {
                                    utm_medium: 'Orders List',
                                    utm_content: `${order.status}_order_status`,
                                  },
                                )}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <TextOverflow size={355}>
                                  {order.active_offer.carrier_name}
                                </TextOverflow>
                              </Link>
                            ) : (
                              <TextOverflow size={355}>
                                {order.active_offer.carrier_name}
                              </TextOverflow>
                            )}
                          </Inline>
                          {order.active_offer.carrier_phone && (
                            <Link
                              href={`tel:${order.active_offer.carrier_phone}`}
                              aria-label="contact phone"
                            >
                              {order.active_offer.carrier_phone}
                            </Link>
                          )}
                        </Stack>
                      )
                    ) : (
                      <Typography color="textSecondary">
                        No carrier info
                      </Typography>
                    )}
                  </Stack>
                  {showCarrierRequestedSuperPayNudging && (
                    <CarrierRequestedSuperPay
                      source="Order List"
                      order={order}
                    />
                  )}
                </Stack>
              </Column>

              <Column>
                {order.customer_payment?.tariff ? (
                  <Stack space="xsmall">
                    <Typography variant="body1" component="div">
                      <OrderListPayment
                        amount={order.customer_payment.tariff}
                        terms={order.customer_payment.terms}
                        tooltip="Tariff"
                      />
                    </Typography>
                    <Stack space="small">
                      <StyledColumnsWrap space="none" collapseBelow="desktop">
                        <Column width="content">
                          {order.price ? (
                            <OrderListPrice
                              order={order}
                              title="Carrier Price"
                            />
                          ) : (
                            <Typography color="textSecondary">
                              No payment info
                            </Typography>
                          )}
                        </Column>
                        <Column width="content">
                          {order.customer_payment.tariff != null &&
                            order.price != null && (
                              <Stack space="none">
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Gross Profit
                                </Typography>
                                <GrossProfit
                                  tariff={order.customer_payment.tariff}
                                  price={order.price}
                                  useBrackets={true}
                                />
                              </Stack>
                            )}
                        </Column>
                      </StyledColumnsWrap>
                      {isBroker && isCustomerVisible && isAdvancedTier && (
                        <Columns>
                          <Column width="1/2">
                            {order.customer?.name ? (
                              isMobile ? (
                                <Columns space="xxsmall">
                                  <Column width="content">
                                    <Inline>
                                      {order.customer.counterparty_guid ? (
                                        <Link
                                          href={`/customers?current_customer_guid=${order.customer.counterparty_guid}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <TextOverflow size={200}>
                                            {order.customer.name}
                                          </TextOverflow>
                                        </Link>
                                      ) : (
                                        <TextOverflow size={200}>
                                          {order.customer.name}
                                        </TextOverflow>
                                      )}
                                    </Inline>
                                  </Column>
                                  <Column width="content">
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      · Customer
                                    </Typography>
                                  </Column>
                                </Columns>
                              ) : (
                                <Stack space="none">
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Customer
                                  </Typography>
                                  <Inline>
                                    {isBroker &&
                                    order.customer.counterparty_guid ? (
                                      <Link
                                        href={`/customers?current_customer_guid=${order.customer.counterparty_guid}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <TextOverflow size={350}>
                                          {order.customer.name}
                                        </TextOverflow>
                                      </Link>
                                    ) : (
                                      <TextOverflow size={350}>
                                        {order.customer.name}
                                      </TextOverflow>
                                    )}
                                  </Inline>
                                </Stack>
                              )
                            ) : (
                              <Typography color="textSecondary">
                                No customer info
                              </Typography>
                            )}
                          </Column>
                        </Columns>
                      )}
                    </Stack>
                  </Stack>
                ) : (
                  <Stack space="small">
                    <StyledColumnsWrap space="none" collapseBelow="desktop">
                      <Column width="content">
                        {order.price ? (
                          <OrderListPrice order={order} title="Carrier Price" />
                        ) : (
                          <Typography color="textSecondary">
                            No payment info
                          </Typography>
                        )}
                      </Column>
                      <Column width="content">
                        {order.customer_payment?.tariff != null &&
                          order.price != null && (
                            <Stack space="none">
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Gross Profit
                              </Typography>
                              <GrossProfit
                                tariff={order.customer_payment.tariff}
                                price={order.price}
                                useBrackets={true}
                              />
                            </Stack>
                          )}
                      </Column>
                    </StyledColumnsWrap>
                    {isBroker && isCustomerVisible && isAdvancedTier && (
                      <Columns>
                        <Column width="1/2">
                          {order.customer?.name ? (
                            isMobile ? (
                              <Columns space="xxsmall">
                                <Column width="content">
                                  {order.customer.counterparty_guid ? (
                                    <Link
                                      href={`/customers?current_customer_guid=${order.customer.counterparty_guid}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <TextOverflow>
                                        {order.customer.name}
                                      </TextOverflow>
                                    </Link>
                                  ) : (
                                    <TextOverflow>
                                      {order.customer.name}
                                    </TextOverflow>
                                  )}
                                </Column>
                                <Column width="content">
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    · Customer
                                  </Typography>
                                </Column>
                              </Columns>
                            ) : (
                              <Stack space="none">
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Customer
                                </Typography>
                                <Inline>
                                  {isBroker &&
                                  order.customer.counterparty_guid ? (
                                    <Link
                                      href={`/customers?current_customer_guid=${order.customer.counterparty_guid}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <TextOverflow size={350}>
                                        {order.customer.name}
                                      </TextOverflow>
                                    </Link>
                                  ) : (
                                    <TextOverflow size={350}>
                                      {order.customer.name}
                                    </TextOverflow>
                                  )}
                                </Inline>
                              </Stack>
                            )
                          ) : (
                            <Typography color="textSecondary">
                              No customer info
                            </Typography>
                          )}
                        </Column>
                      </Columns>
                    )}
                  </Stack>
                )}
              </Column>
            </Columns>

            {!isMobile && (
              <Inline>
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {order.created_at && (
                    <>
                      {'Created '}
                      <DetailedFormattedDate
                        date={order.created_at}
                        variant="ShortDate"
                      />
                      {' ('}
                      <FormattedRelativeTime
                        format="JodaISO"
                        date={order.created_at}
                      />
                      ) ·
                    </>
                  )}
                  {order.changed_at && (
                    <>
                      {' Order updated '}
                      <DetailedFormattedDate
                        date={order.changed_at}
                        variant="ShortDate"
                      />
                    </>
                  )}

                  {order.pickup?.first_available_pickup_date && (
                    <>
                      {' · 1st Avail. Pickup Date is '}
                      <DetailedFormattedDate
                        date={order.pickup.first_available_pickup_date}
                        variant="ShortDate"
                      />
                    </>
                  )}

                  {isShowPaymentDueOn &&
                    !isDateLessCurrent &&
                    ` · Carrier Payment Due on ${paymentExpectedDate} `}
                </Typography>
              </Inline>
            )}
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
}

export function OrderListCardSortBar({
  disableSelectable,
  selectedOrders,
  orders,
  isLoading,
  sortKey = 'changedAt',
  sortOrder = 'DESC',
  onUnselectAllOrders,
  onSelectAllOrders,
  onSort,
}: OrderListCardSortBarProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  const { isAdvancedTier } = useProductTiers();

  const listOfFilters = isAdvancedTier
    ? ORDER_VIEW_SORT_GROUPS
    : BASIC_TIER_ORDER_VIEW_SORT_GROUPS;

  const indexOfLastSortGroup: number = listOfFilters.length - 1;

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let key = event.target.value;

    onSort?.(String(key), String(sortOrder));
  };

  const handleOrderChange = (order: string) => {
    onSort?.(sortKey, order);
  };

  return (
    <StyledBox>
      <Inline verticalAlign="center">
        {!isMobile && !disableSelectable && (
          <Checkbox
            checked={selectedOrders.length === orders.length && !isLoading}
            onChange={() =>
              selectedOrders.length === orders.length
                ? onUnselectAllOrders()
                : onSelectAllOrders()
            }
          />
        )}

        <Inline space="xxsmall" verticalAlign="center">
          <StyledFormControl variant="outlined">
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={sortKey}
              style={{ background: Color.White }}
              renderValue={(field) => {
                let value = '';

                listOfFilters.forEach((group) => {
                  let option = group.find(
                    (groupOption) => groupOption.field === field,
                  );

                  if (option) {
                    value = option.value;
                  }
                });

                return (
                  <Inline verticalAlign="center">
                    <Typography variant="body1" color="textSecondary">
                      Sort by
                    </Typography>
                    {value}
                  </Inline>
                );
              }}
              onChange={handleSortChange}
              label="Sort By"
            >
              {listOfFilters.map((group, index) => [
                group.map((option) => (
                  <MenuItem key={index} value={option.field}>
                    {option.value}
                  </MenuItem>
                )),
                index !== indexOfLastSortGroup && (
                  <Divider style={{ margin: '8px 0' }} />
                ),
              ])}
            </Select>
          </StyledFormControl>

          {sortOrder === 'ASC' ? (
            <IconButton onClick={() => handleOrderChange('DESC')}>
              <ArrowDownward htmlColor={Color.Grey200} />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleOrderChange('ASC')}>
              <ArrowUpward htmlColor={Color.Grey200} />
            </IconButton>
          )}
        </Inline>
      </Inline>
    </StyledBox>
  );
}

export function OrdersListCardView({
  orders,
  isLoading,
  selectedOrders,
  renderActions,
  renderNumber,
  searchQuery,
  toggleSelectOrder,
  disableSelectable,
}: OrderListCardViewProps) {
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const orderWithLoadIds = useMemo(
    () =>
      orders
        .filter((order) => order.is_load || order.has_loads)
        .map((order) => order.id),
    [orders],
  );
  const orderIds = useMemo(() => orders.map((o) => o.id), [orders]);
  const orderInspectionPhotoIssues = useOrderInspectionPhotoIssues(orderIds, {
    enabled: orderIds.length > 0 && getAppType() === 'broker',
  });
  const { data: relatedLoadsData } = useRelatedLoadBasicInfo(orderWithLoadIds);
  const { objects: relatedLoadIdsInfo = [] } = relatedLoadsData || {};

  const orderIDsWithLegs = useMemo(
    () => orders.filter(({ has_loads }) => has_loads).map((x) => x.id),
    [orders],
  );

  const relatedLegsMap = useRelatedLegsMap(orderIDsWithLegs);

  return !isLoading ? (
    <>
      {orders.map((order, index) => {
        return (
          <ListCard
            key={order.id}
            isFirst={index === 0}
            order={order}
            renderActions={renderActions}
            renderNumber={renderNumber}
            parentLoadInfo={relatedLoadIdsInfo.find(
              (load) => load.order_id === order.id,
            )}
            relatedLoads={relatedLoadIdsInfo.filter(
              (load) => load.order_id === order.id,
            )}
            relatedLegs={relatedLegsMap[order.id]?.loads}
            hasUnassignedVehicles={
              relatedLegsMap[order.id]?.has_unassigned_vehicles
            }
            searchQuery={searchQuery}
            shipperType={shipperType}
            selected={selectedOrders.some((x) => x.id === order.id)}
            toggleSelectOrder={toggleSelectOrder}
            disableSelectable={disableSelectable}
            inspectionPhotoIssuesCount={
              orderInspectionPhotoIssues.data?.[order.id]
            }
          />
        );
      })}
    </>
  ) : (
    <div style={{ textAlign: 'center', margin: '2rem' }}>
      <CircularProgress variant="indeterminate" size={40} />
    </div>
  );
}
