import { Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { CustomerContactDTO } from 'shared/dto/CustomerContactDTO';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { getObjectChangeInfo } from 'shared/utils/DataUtils';
import { email, required } from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { ExitTransitionPlaceholder } from '../../shared/ui/ExitTransitionPlaceholder';
import { useContactAPI, useCustomerContacts } from '../data/CustomerContactAPI';
import { LabelRequired } from './CustomerDetails';

const ContactDrawerContentWrapper = styled.div`
  max-width: 100%;

  @media (min-width: 600px) {
    max-width: 432px;
  }
`;

export function CustomerContactForm() {
  return (
    <Stack>
      <FormikTextField
        label={<LabelRequired text="Contact Name" />}
        name="name"
        fullWidth={true}
        validate={required}
      />

      <FormikTextField label="Title" name="title" fullWidth={true} />

      <FormikCheckboxField
        name="is_primary"
        color="primary"
        label="Set as primary contact"
      />

      <Box marginTop="small">
        <Typography variant="h4">Contacts</Typography>
      </Box>

      <FormikTextField label="Phone" name="phone" fullWidth={true} />

      <FormikTextField
        label="Phone Extension"
        name="phone_extension"
        fullWidth={true}
      />

      <FormikTextField label="Mobile" name="mobile_phone" fullWidth={true} />

      <FormikTextField
        label="Email"
        name="email"
        fullWidth={true}
        validate={email}
      />
    </Stack>
  );
}

interface ContactCreateDrawerContentProps {
  customer: CustomerDTO;
  onSuccess: () => void;
}

export function ContactCreateDrawerContent({
  customer,
  onSuccess,
}: ContactCreateDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { createContact } = useContactAPI();
  const { data: contacts } = useCustomerContacts(customer.id);

  const initialValues = useMemo(
    () => ({ is_primary: !contacts || contacts.objects.length === 0 }),
    [contacts],
  );

  const form = useFormikEnhanced({
    initialValues,
    onSubmit(values) {
      return createContact(customer.id, values);
    },
    onSubmitSuccess(_, values) {
      addSnackbar('Contact created', { variant: 'success' });
      trackEventLegacy('Added Customer Contact', {
        customer,
        contact: values,
      });

      onSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
      });
    },
  });

  return (
    <FormikDrawerContent
      formik={form}
      title="New Contact"
      actions={<FormikDrawerActions />}
    >
      <CustomerContactForm />
    </FormikDrawerContent>
  );
}

interface ContactEditDrawerContentProps {
  customer: CustomerDTO;
  contact: CustomerContactDTO;
  onSuccess: () => void;
}

export function ContactEditDrawerContent({
  contact,
  customer,
  onSuccess,
}: ContactEditDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateContact } = useContactAPI();

  const form = useFormikEnhanced({
    initialValues: contact,
    onSubmit(values) {
      return updateContact(customer.id, contact.id, values);
    },
    onSubmitSuccess(_, values) {
      addSnackbar('Contact updated', { variant: 'success' });
      trackEventLegacy('Updated Customer Contact', {
        customer,
        contact: getObjectChangeInfo(contact, values),
      });

      onSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
      });
    },
  });

  return (
    <FormikDrawerContent
      formik={form}
      title="Edit Contact"
      actions={<FormikDrawerActions />}
    >
      <CustomerContactForm />
    </FormikDrawerContent>
  );
}

interface CustomerContactEditDrawer
  extends Omit<ContactEditDrawerContentProps, 'contact'> {
  open: boolean;
  onClose: () => void;
  contact?: CustomerContactDTO;
}

export function ContactEditDrawer({
  open,
  onClose,
  contact,
  ...props
}: CustomerContactEditDrawer) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      <ExitTransitionPlaceholder in={!!contact}>
        {contact && <ContactEditDrawerContent {...props} contact={contact} />}
      </ExitTransitionPlaceholder>
    </FormikDrawer>
  );
}

interface ContactCreateDrawer extends ContactCreateDrawerContentProps {
  open: boolean;
  onClose: () => void;
}

export function ContactCreateDrawer({
  open,
  onClose,
  ...props
}: ContactCreateDrawer) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      <ContactDrawerContentWrapper>
        <ContactCreateDrawerContent {...props} />
      </ContactDrawerContentWrapper>
    </FormikDrawer>
  );
}
