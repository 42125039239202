import { classToPlain, plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  CarrierBrokerPreferences,
  CarrierFullInfo,
} from 'shared/types/carrier';
import {
  CarrierRatingDTO,
  CarrierRatingTagStatsDTO,
  LastRatedAtDTO,
  RateCarrierPayloadDTO,
  UnratedCarriersCountDTO,
} from './CarrierRatingDTO';

function useCarriersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateCarriers() {
      void queryClient.invalidateQueries('carriers');
    }

    return { invalidateCarriers };
  }, [queryClient]);
}

export function useCarrierRatingAPI() {
  const cache = useCarriersCache();
  const { requestResource, request } = useAPI();

  return useMemo(
    () => ({
      rateCarrier: (guid: string, payload: RateCarrierPayloadDTO) =>
        requestResource<CarrierBrokerPreferences>(
          `POST /internal/carriers/${guid}/ratings`,
          (data) => plainToClass(CarrierBrokerPreferences, data),
          { json: classToPlain(payload) },
        ).then((response) => {
          cache.invalidateCarriers();
          return response;
        }),

      deleteRating: (guid: string) =>
        request(`DELETE /internal/carriers/${guid}/ratings`).then(
          (response) => {
            cache.invalidateCarriers();
            return response;
          },
        ),
    }),
    [requestResource, cache, request],
  );
}

export function useCarrierRatings(guid: string, page: number) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(['carriers', 'ratings', { guid, page }], () =>
    requestPage(
      '/internal/carriers/{guid}/ratings{?size,page}',
      (data) => plainToClass(CarrierRatingDTO, data),
      { guid, page, size: 10 },
    ),
  );
}

export function useCarrier(guid: string) {
  const { requestResource } = useAPI();

  return useAPIQuery(['carriers', 'item', { guid }], () =>
    requestResource(
      'GET /internal/carriers/{guid}',
      (data) => plainToClass(CarrierFullInfo, data),
      { guid },
    ),
  );
}

export function useCarrierLastRatedAt(guid: string | undefined) {
  const { requestResource } = useAPI();
  return useAPIQuery(
    ['carriers', 'last_rated_at', { guid }],
    () =>
      requestResource(
        'GET /internal/carriers/{guid}/last_rated_at',
        (data) => plainToClass(LastRatedAtDTO, data),
        { guid },
      ),
    { enabled: !!guid },
  );
}

export function useCarrierRatingTagStats(guid: string) {
  const { requestResource } = useAPI();
  return useAPIQuery(['carriers', 'ratings', { guid }], () =>
    requestResource(
      'GET /internal/carriers/{guid}/ratings/statistics',
      (data) => plainToClass(CarrierRatingTagStatsDTO, data),
      { guid },
    ),
  );
}

export function useUnratedCarriersCount() {
  const { requestResource } = useAPI();
  return useAPIQuery(['ratings', 'unrated-carriers-count'], () =>
    requestResource('GET /internal/carriers/unrated_count', (data) =>
      plainToClass(UnratedCarriersCountDTO, data),
    ),
  );
}
