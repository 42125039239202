import { Dialog, Link, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useUserState } from 'shared/data/AppUserState';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { usePaymentDetails } from 'subscription-details/usePaymentDetails';
import { CheckCircleBigIcon } from 'subscription-plans/assets/CheckCircleBigIcon';

const DIALOG_CLOSED_LS_KEY = 'is_trial_started_dialog_closed';

export function TrialStartedDialog() {
  const hasClosedDialog = useStorageValue(DIALOG_CLOSED_LS_KEY);
  const [open, _, closeDialog] = useBoolean(!hasClosedDialog);
  const { user } = useUserState();
  const { data } = useSubscriptionDetails();
  const { paymentMethod } = usePaymentDetails();
  const platform = useResponsiveValue('mobile', 'other');

  if (
    !user?.shipper.is_self_serve ||
    !paymentMethod ||
    hasClosedDialog ||
    data?.chargebee.status !== 'IN_TRIAL'
  ) {
    return null;
  }

  const handleClose = () => {
    writeStorageItem(DIALOG_CLOSED_LS_KEY, String(true));
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={platform === 'mobile'}
    >
      <Box padding="large" maxWidth="523px">
        <Stack space="large" align="center">
          <CheckCircleBigIcon />
          <Stack space="xsmall" align="center">
            <Typography variant="h2" align="center">
              Your free trial has started!
            </Typography>
            <Stack space="medium">
              <Typography align="center">
                You won&apos;t see any charges until your trial ends on{' '}
                <b>
                  {formatDate(data.chargebee.trial_end, {
                    variant: 'Date',
                  })}
                </b>
                .<br />
                After that, you&apos;ll be billed $129 per month + applicable
                tax.
              </Typography>
              <Typography align="center">
                Thank you for choosing Super Dispatch! If you have any
                questions, please contact us at{' '}
                <Link color="primary" href="mailto:support@superdispatch.com">
                  support@superdispatch.com
                </Link>{' '}
                or click the chat icon in the bottom right corner.
              </Typography>
            </Stack>
          </Stack>

          <Button onClick={handleClose}>Got it</Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
