import { Avatar } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';

// TODO add white-space option to TextBox
const RawText = styled(TextBox)`
  white-space: break-spaces;
`;

const ResponseBox = styled(Box)`
  padding-left: 56px;
`;

interface RatingResponseProps {
  name: string;
  logo: string | null;
  headerText: string;
  response: string;
  responseDate: string;
  actions?: ReactNode;
}
export function RatingResponse({
  name,
  logo,
  headerText,
  response,
  responseDate,
  actions,
}: RatingResponseProps) {
  return (
    <ResponseBox>
      <Columns space="xsmall">
        <Column width="content">
          <Avatar src={logo || undefined}>{getInitials(name)}</Avatar>
        </Column>
        <Column>
          <Stack space="none">
            <Columns>
              <Column>
                <TextBox> {headerText}</TextBox>
                <Box paddingBottom="xsmall">
                  <TextBox
                    display="block"
                    variant="caption"
                    color="secondary"
                    aria-label="response date"
                  >
                    <FormattedDate
                      variant="Date"
                      format="JodaISO"
                      date={responseDate}
                    />
                  </TextBox>
                </Box>
              </Column>
              <Column width="content">{actions}</Column>
            </Columns>

            <Box maxWidth="680px">
              <RawText color="secondary" aria-label="response">
                {response}
              </RawText>
            </Box>
          </Stack>
        </Column>
      </Columns>
    </ResponseBox>
  );
}
