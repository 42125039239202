import { CentralDispatchCredentialsDTO } from 'core/cental-dispatch/CentralDispatchCredentialsDTO';
import { ComponentType, useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { CentralDispatchIntegrationDrawerContent } from './CentralDispatchIntegrationDrawerContent';
import { CentralDispatchVerificationCodeDrawerContent } from './CentralDispatchVerificationCodeDrawerContent';

interface CentralIntegrationEditProps {
  open: boolean;
  credentials: CentralDispatchCredentialsDTO;
  onSubmitSuccess: () => void;
  onClose: () => void;
}

export const CentralDispatchIntegrationDrawer: ComponentType<CentralIntegrationEditProps> =
  ({ open, credentials, onSubmitSuccess, onClose }) => {
    const isCD2FAEnabled = useFeatureToggle('cd-integration-web-2fa.enabled');
    const { currentStep, handleNext, handlePrevious } =
      useCentralDispatchFormSteps();

    return (
      <FormikDrawer open={open} onClose={onClose}>
        {currentStep === 'initial_credentials' ? (
          <CentralDispatchIntegrationDrawerContent
            credentials={credentials}
            onSubmitSuccess={() =>
              isCD2FAEnabled ? handleNext() : onSubmitSuccess()
            }
          />
        ) : (
          <CentralDispatchVerificationCodeDrawerContent
            onGoBack={handlePrevious}
            onSubmitSuccess={onSubmitSuccess}
          />
        )}
      </FormikDrawer>
    );
  };

export function useCentralDispatchFormSteps() {
  const [currentStep, setCurrentStep] = useState<
    'initial_credentials' | 'verification_code'
  >('initial_credentials');

  const handlePrevious = () => {
    setCurrentStep('initial_credentials');
  };

  const handleNext = () => {
    setCurrentStep('verification_code');
  };

  return { currentStep, handleNext, handlePrevious };
}
