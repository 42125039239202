import { IconButton, Link, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordAPI } from './data/ResetPasswordAPI';
import {
  SendResetPasswordLinkDTO,
  sendResetPasswordLinkSchema,
} from './data/ResetPasswordDTO';
import { LoginLayout } from './LoginLayout';

export function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const { sendResetPasswordLink } = useResetPasswordAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced<SendResetPasswordLinkDTO, unknown>({
    initialValues: { username: '' },
    validationSchema: sendResetPasswordLinkSchema,
    onSubmit: ({ username }) => sendResetPasswordLink(username),
    onSubmitSuccess: () => {
      setIsLinkSent(true);
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <LoginLayout>
      <FormikProvider value={formik}>
        <Form>
          <Box padding="large">
            <Stack space="large">
              <Stack space="small">
                <Inline space="xsmall" verticalAlign="center">
                  <IconButton
                    size="small"
                    aria-label="go back"
                    onClick={() => navigate('/signin')}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h2">
                    {isLinkSent ? 'Check Your Email' : 'Forgot Password?'}
                  </Typography>
                </Inline>

                {isLinkSent ? (
                  <Typography>
                    We&apos;ve sent an email to{' '}
                    <b>{maskEmail(formik.values.username)}</b>. Click the link
                    in the email to change your password.
                  </Typography>
                ) : (
                  <Typography>
                    Enter account username below. We will send you a link to
                    reset your password.
                  </Typography>
                )}
              </Stack>

              {isLinkSent ? (
                <>
                  <Typography>
                    If you can&apos;t find your email, check your spam, junk, or
                    other folders.
                  </Typography>
                  <Link
                    component="button"
                    color="primary"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsLinkSent(false);
                    }}
                  >
                    I didn&apos;t receive the email
                  </Link>
                </>
              ) : (
                <>
                  <FormikTextField
                    label="Username"
                    name="username"
                    fullWidth={true}
                    placeholder="john.smith@example.com"
                  />

                  <Button
                    type="submit"
                    size="large"
                    fullWidth={true}
                    disabled={!formik.dirty || !formik.isValid}
                    pending={formik.isSubmitting}
                  >
                    Send Me Reset Link
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </LoginLayout>
  );
}

function maskEmail(email: string) {
  const [name, domain] = email.split('@');
  if (!name || !domain) return email;

  const len = name.length;
  if (len <= 4) return '*'.repeat(len) + '@' + domain;
  return name.slice(0, len - 4) + '****' + '@' + domain;
}
