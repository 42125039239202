import { useFormikEnhanced } from '@superdispatch/forms';
import { DrawerActions, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CarrierAutocompleteValue } from 'core/CarrierAutocomplete';
import { trackUpdatedInternalRecords } from 'manage-carriers/core/CarrierProfilePageAnalytics';
import {
  useCarrier,
  useCarrierAttachments,
  useCarrierProfileAPI,
} from 'manage-carriers/data/ManageCarriersAPI';
import { useEffect, useMemo, useState } from 'react';
import { DrawerForm } from 'shared/form/DrawerForm';
import {
  CarrierBrokerPreferencesEdit,
  CarrierStatus,
} from 'shared/types/carrier';
import styled from 'styled-components';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { NetworkDrawerContent } from './NetworkDrawerContent';

const StyledFormikDrawer = styled(DrawerForm)`
  & .MuiDrawer-paper {
    width: 100%;
  }
`;

interface AddToNetworkDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const AddToNetworkDrawer = ({
  open,
  onClose,
}: AddToNetworkDrawerProps) => {
  const { addSnackbar } = useSnackbarStack();
  const {
    removeAttachment,
    removeInsurance,
    uploadAttachment,
    uploadInsurance,
    updateInternalBrokerRecords,
  } = useCarrierProfileAPI();
  const [carrierValue, setCarrierValue] =
    useState<CarrierAutocompleteValue>(null);

  const { error, data: carrier } = useCarrier({
    guid: carrierValue?.guid || '',
    usdot: carrierValue?.us_dot || '',
  });

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  const { data: attachments } = useCarrierAttachments(carrier?.guid);

  const carrier_type = carrier?.carrier_type;
  const broker_records = carrier?.broker_records;

  const isCarrierInternal = carrier_type === 'INTERNAL';
  const carrierStatus: CarrierStatus = broker_records?.approved
    ? 'APPROVED'
    : broker_records?.in_blacklist
    ? 'IN_BLACKLIST'
    : 'DEFAULT';

  const initialValues = useMemo(
    () => ({
      attachments: carrierValue ? Array.from(attachments?.objects || []) : [],
      custom_external_id: broker_records?.custom_external_id || '',
      status: carrierStatus,
      preferred: broker_records?.preferred || null,
      can_expedite_payment: broker_records?.can_expedite_payment || null,
      is_in_private_network: broker_records?.is_in_private_network || null,
      private_network_group_guids:
        broker_records?.private_network_groups?.map((x) => x.guid) || [],
      insurance_certificate_holder:
        broker_records?.insurance_certificate_holder || null,
      insurance_expires_at: broker_records?.insurance_expires_at,
      insurance_cert_holder_file_url:
        broker_records?.insurance_cert_holder_file_url || '',
    }),
    [attachments, broker_records, open],
  );

  const handleClose = () => {
    setCarrierValue(null);
    onClose();
  };

  const guid = carrier?.guid || '';
  const formik = useFormikEnhanced<CarrierBrokerPreferencesEdit, unknown>({
    key: open,
    initialValues,
    onSubmit: async ({
      attachments: submittedAttachments,
      insurance_cert_holder_file_url,
      ...values
    }) => {
      if (insurance_cert_holder_file_url instanceof File) {
        await uploadInsurance(guid, insurance_cert_holder_file_url);
      } else if (
        initialValues.insurance_cert_holder_file_url &&
        insurance_cert_holder_file_url == null
      ) {
        await removeInsurance(guid);
      }

      await Promise.all(
        submittedAttachments.map(async (attachment) => {
          if (attachment instanceof File) {
            await uploadAttachment(guid, attachment);
          }
        }),
      );

      await Promise.all(
        initialValues.attachments.map(async (attachment) => {
          if (!submittedAttachments.includes(attachment)) {
            await removeAttachment(guid, attachment.id);
          }
        }),
      );

      return updateInternalBrokerRecords(guid, {
        ...values,
        preferred:
          isCarrierInternal || values.status !== 'APPROVED'
            ? false
            : values.preferred,
      });
    },
    onSubmitSuccess: (_, values) => {
      trackUpdatedInternalRecords(initialValues, values);
      if (
        !initialValues.is_in_private_network &&
        values.is_in_private_network
      ) {
        trackEvent('Shipper Added Carrier to Private Network', {
          utm_medium: 'Add Carrier to the Network Drawer',
        });
      }
      if (
        initialValues.is_in_private_network === true &&
        !values.is_in_private_network
      ) {
        trackEvent('Shipper Removed Carrier from Private Network', {
          utm_medium: 'Add Carrier to the Network Drawer',
        });
      }
      addSnackbar('Successfully updated internal records', {
        variant: 'success',
      });
      handleClose();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to updated internal records', {
        variant: 'error',
      });
    },
  });

  return (
    <StyledFormikDrawer
      title="Add Carrier to the Network"
      aria-label="Add Carrier to the Network"
      open={open}
      isDirty={formik.dirty}
      onClose={handleClose}
      onSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={false}
      actions={
        <DrawerActions>
          <Button type="submit" disabled={!guid}>
            Save
          </Button>
        </DrawerActions>
      }
    >
      <NetworkDrawerContent
        formik={formik}
        brokerRecords={broker_records}
        onCarrierChange={setCarrierValue}
        carrier={carrierValue}
        onClose={handleClose}
        isCarrierInternal={isCarrierInternal}
      />
    </StyledFormikDrawer>
  );
};
