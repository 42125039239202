import { classToPlain, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import { detailedDiff } from 'deep-object-diff';
import { isDate, merge } from 'lodash-es';
import { DateTime } from 'luxon';

export function ensureClass<T extends object>(cls: ClassType<T>, value: T): T {
  // check for direct parent
  return value.constructor === value ? value : plainToClass(cls, value);
}

export function valuesToPlain<T extends object>(
  cls: ClassType<T>,
  value: T | T[],
): object {
  return classToPlain(ensureClass(cls, value));
}

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
type DeepValueAssign<T, K> = {
  [P in keyof T]?: T[P] extends object ? DeepValueAssign<T[P], K> : K;
};

export interface DetailedUpdateDiff<T> {
  added: DeepPartial<T>;
  deleted: DeepPartial<T>;
  updated: DeepPartial<T>;
}

function annotateObjectChanges<T extends object, K>(
  object: T,
  update: (value: unknown) => K,
): DeepValueAssign<T, K> {
  const result = {};

  for (const [key, value] of Object.entries(object)) {
    if (value != null && typeof value === 'object') {
      result[key] = annotateObjectChanges<T, K>(value, update);
    } else {
      result[key] = update(value);
    }
  }

  return result;
}

function isEmpty(value: unknown) {
  if (value == null) {
    return true;
  }
  if (typeof value === 'string') {
    return value.length === 0;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return false;
}

export function getObjectChangeInfo<T extends object>(
  oldValues: T,
  newValues: T,
) {
  const { added, updated, deleted } = detailedDiff(
    oldValues,
    newValues,
  ) as DetailedUpdateDiff<T>;
  return merge(
    annotateObjectChanges(added, () => 'updated'),
    annotateObjectChanges(deleted, () => 'removed'),
    annotateObjectChanges(updated, (value) =>
      isEmpty(value) ? 'removed' : 'updated',
    ),
  );
}

export function objectFlatten(obj: object, sep = '.', pfx = '', agg = {}) {
  const primitives = ['boolean', 'number', 'string'];

  Object.entries(obj).forEach(([key, val]) => {
    const path = pfx ? `${pfx}${sep}${key}` : key;

    if (primitives.includes(typeof val) || Array.isArray(val) || isDate(val)) {
      agg[path] = val;
      return;
    }
    if (typeof val === 'object' && val != null) {
      objectFlatten(val, sep, path, agg);
    }
  });

  return agg;
}

export function getDateWithOffset(dateString?: string, offsetInMinutes = 0) {
  let rawDate;

  if (dateString) {
    rawDate = DateTime.fromISO(dateString, { zone: 'utc' });
  }

  return rawDate?.set({
    hour: rawDate.get('hour') + offsetInMinutes / 60,
  });
}

export function tryParseJSON<T>(json: string): T | undefined {
  try {
    return JSON.parse(json) as T;
  } catch {
    return undefined;
  }
}

export function tryStringifyJSON<T>(data: T): string | undefined {
  try {
    return JSON.stringify(data);
  } catch {
    return undefined;
  }
}
