import { MenuItem, Tab, Tabs, Typography } from '@material-ui/core';
import {
  FormikDateField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { formatPaymentMethod } from '@superdispatch/sdk';
import {
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { plainToClass } from 'class-transformer';
import { isSuperpayPayment, usePaymentOptions } from 'core/SuperPayUtils';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { OrderDTO } from 'shared/dto/Order/OrderDTO';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { FormikCurrencyField } from 'shared/form/FormikCurrencyField';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { CarrierRequestedSuperPay } from 'shared/helpers/superpay/SuperPayHelpers';
import Order from 'shared/types/order';
import {
  MarkAsCustomerPaidFormDTO,
  MarkAsCustomerPaidPayloadDTO,
  MarkAsPaidFormDTO,
  MarkAsPaidPayloadDTO,
} from '../../data/dto/MarkAsPaidPayloadDTO';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';

export interface MarkAsPaidDrawerProps {
  order?: Order;
  onCancel: () => void;
  onSubmitSuccess: (updatedOrder: Order) => void;
}

export function MarkAsPaidDrawer({
  order,
  onCancel,
  onSubmitSuccess,
}: MarkAsPaidDrawerProps) {
  return (
    <FormikDrawer open={!!order} onClose={onCancel} confirmClose={true}>
      <ExitTransitionPlaceholder in={!!order}>
        {!!order && (
          <MarkAsPaidDrawerContent
            order={order}
            onSubmitSuccess={onSubmitSuccess}
          />
        )}
      </ExitTransitionPlaceholder>
    </FormikDrawer>
  );
}

interface MarkAsPaidDrawerContentProps {
  order?: Order;
  onSubmitSuccess: (updatedOrder: Order) => void;
}

function MarkAsPaidDrawerContent({
  order,
  onSubmitSuccess,
}: MarkAsPaidDrawerContentProps) {
  const { user } = useUserState();
  const { addSnackbar } = useSnackbarStack();
  const { markAsPaid, markAsCustomerPaid } = useSingleOrderActionAPI();
  const isCarrierDisabled = isSuperpayPayment(order?.payment);
  const [paymentType, setPaymentType] = useState(
    isCarrierDisabled ? 'customer' : 'carrier',
  );
  const { paymentMethods } = usePaymentOptions(
    order?.payment?.method,
    !isSuperpayPayment(order?.payment),
  );

  const showCarrierRequestedSuperPayNudging =
    paymentType === 'carrier' &&
    user?.shipper.shipper_type === 'BROKER' &&
    !!order &&
    !!order.invoice?.is_carrier_requested_superpay &&
    !isSuperpayPayment(order.payment) &&
    (order.status === 'invoiced' || order.status === 'delivery_verified');

  const initialValues = useMemo<
    MarkAsPaidFormDTO | MarkAsCustomerPaidFormDTO
  >(() => {
    if (!order) {
      return plainToClass(MarkAsPaidFormDTO, {});
    }

    if (paymentType === 'carrier') {
      return plainToClass(MarkAsPaidFormDTO, {
        ...order.payment,
        amount: order.payment?.amount || order.price,
      });
    }

    return plainToClass(MarkAsCustomerPaidFormDTO, {
      ...order.customer_payment,
    });
  }, [order, paymentType]);

  const form = useFormikEnhanced<
    MarkAsPaidFormDTO | MarkAsCustomerPaidFormDTO,
    OrderDTO
  >({
    key: order,
    initialValues,
    onSubmit(values) {
      if (!order) {
        return Promise.reject(new Error('Order not found'));
      }

      return paymentType === 'carrier'
        ? markAsPaid(order.id, plainToClass(MarkAsPaidPayloadDTO, values))
        : markAsCustomerPaid(
            order.id,
            plainToClass(MarkAsCustomerPaidPayloadDTO, values),
          );
    },
    onSubmitSuccess(updatedOrder) {
      addSnackbar('Payment added', { variant: 'success' });
      onSubmitSuccess(updatedOrder);
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  const getTabTextColor = (type: 'carrier' | 'customer') =>
    type === paymentType ? 'primary' : 'textSecondary';

  return (
    <FormikDrawerContent
      formik={form}
      title="Mark order as paid"
      actions={<FormikDrawerActions submitButtonLabel="Continue" />}
    >
      <Box
        marginLeft="-large"
        marginRight="-large"
        paddingLeft="large"
        paddingRight="large"
        marginBottom="small"
        borderBottomWidth="small"
        borderBottomColor="Silver400"
      >
        <Tabs value={paymentType}>
          <Tab
            value="carrier"
            label={
              <Typography color={getTabTextColor('carrier')}>
                To Carrier
              </Typography>
            }
            onClick={() => setPaymentType('carrier')}
            disabled={isCarrierDisabled}
          />

          <Tab
            value="customer"
            label={
              <Typography color={getTabTextColor('customer')}>
                From Customer
              </Typography>
            }
            onClick={() => setPaymentType('customer')}
          />
        </Tabs>
      </Box>

      <Stack space="small">
        {showCarrierRequestedSuperPayNudging && (
          <CarrierRequestedSuperPay
            order={order}
            source="Mark Order as Paid Drawer"
          />
        )}
        <FormikCurrencyField
          name="amount"
          label="Paid Amount"
          fullWidth={true}
        />

        <FormikTextField
          name="method"
          select={true}
          fullWidth={true}
          label="Payment Method"
        >
          <MenuItem value="" />

          {paymentMethods.map((value) => (
            <MenuItem key={value} value={value}>
              {formatPaymentMethod(value)}
            </MenuItem>
          ))}
        </FormikTextField>

        <Columns space="small" collapseBelow="tablet">
          <Column>
            {paymentType === 'carrier' ? (
              <FormikDateField
                fullWidth={true}
                name="sent_date"
                label="Sent Date"
                CalendarProps={{
                  initialTime: startOfWorkDay(DateTime.local()),
                }}
              />
            ) : (
              <FormikDateField
                fullWidth={true}
                name="received_date"
                label="Received Date"
                CalendarProps={{
                  initialTime: startOfWorkDay(DateTime.local()),
                }}
              />
            )}
          </Column>
          <Column>
            <FormikTextField
              fullWidth={true}
              name="reference_number"
              label="Reference Number"
            />
          </Column>
        </Columns>

        <FormikTextField
          name="notes"
          fullWidth={true}
          multiline={true}
          label="Payment Notes"
          type="textarea"
        />
      </Stack>
    </FormikDrawerContent>
  );
}
