import { Button, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import * as React from 'react';
import { useRef } from 'react';
import { AttachmentDTO } from 'shared/types/attachment';
import { AttachmentListItem } from './AttachmentListItem';

export interface AttachmentListProps {
  attachments: Array<AttachmentDTO | File>;
  accept?: HTMLInputElement['accept'];
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: (attachment: Array<AttachmentDTO | File>) => void;
}

export function AttachmentList({
  attachments,
  accept,
  disabled,
  error,
  helperText,
  onChange,
}: AttachmentListProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  function openInput() {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }

  return (
    <>
      <Stack space="small">
        <Typography variant="h4">Internal Attachments</Typography>

        {attachments.map((attachment, index) => (
          <AttachmentListItem
            key={index}
            disabled={disabled}
            attachment={attachment}
            onDelete={() =>
              onChange?.(attachments.filter((_, i) => i !== index))
            }
          />
        ))}

        {error && <Typography color="error">{helperText}</Typography>}

        <Button disabled={disabled} onClick={openInput}>
          Upload
        </Button>
      </Stack>

      <input
        ref={inputRef}
        type="file"
        accept={accept}
        hidden={true}
        onChange={({ target: { files } }) => {
          if (files?.[0]) {
            onChange?.([...attachments, files[0]]);
          }
        }}
      />
    </>
  );
}
