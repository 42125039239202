import { useSubscriptionPlans } from '../shared/subscription-plans/useSubscriptionPlans';
import { useBillingPeriod } from './useBillingPeriod';
import { usePlanGroupCode } from './usePlanGroupCode';

export function useSelectedSubscriptionPlan() {
  const { subscriptionPlanGroups, isSubscriptionPlansLoading } =
    useSubscriptionPlans();
  const [planGroupCode] = usePlanGroupCode();
  const [billingPeriod] = useBillingPeriod();
  const subscriptionPlanGroup = subscriptionPlanGroups?.find(
    (plan) => plan.plan_group_code === planGroupCode,
  );

  if (!subscriptionPlanGroup) {
    return {
      subscriptionPlan: null,
      isSubscriptionPlansLoading,
    };
  }

  const { monthly, yearly } = subscriptionPlanGroup.plans;
  const subscriptionPlan = billingPeriod === 'monthly' ? monthly : yearly;

  if (monthly === undefined && billingPeriod === 'monthly') {
    return {
      subscriptionPlan: null,
      isSubscriptionPlansLoading,
    };
  }

  return {
    subscriptionPlan,
    subscriptionPlanGroup,
    isSubscriptionPlansLoading,
  };
}
