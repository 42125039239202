import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { AccountVerifiedIcon } from 'shared/icons/AccountVerifiedIcon';
import styled from 'styled-components';

const Verified = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;

  padding-left: 8px;
  padding-right: 2px;
  border-radius: 58px;

  background-color: ${Color.Green50};
  color: ${Color.Green300};
  border: 2px solid ${Color.Green75};

  font-size: 14px;
`;

const VerifiedIcon = styled(AccountVerifiedIcon)`
  background-color: ${Color.White};
  border-radius: 50%;
  font-size: 1.2em;
`;

interface VerifiedBadgeProps {
  className?: string;
}

export function VerifiedBadge({ className }: VerifiedBadgeProps) {
  return (
    <Verified className={className}>
      <Box>Verified</Box>
      <VerifiedIcon htmlColor={Color.Green300} fontSize="inherit" />
    </Verified>
  );
}
