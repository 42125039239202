import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { mdiContentCopy } from '@mdi/js';
import { useUID } from '@superdispatch/ui';
import { useClipboard } from '../helpers/DomHelpers';

interface Props
  extends Omit<TextFieldProps, 'InputProps' | 'inputRef' | 'value'> {
  value: string;
}

export function TextFieldCopy({ value, ...props }: Props) {
  const uid = useUID();
  const { copy } = useClipboard();

  return (
    <TextField
      {...props}
      id={uid}
      value={value}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="copy"
              onClick={() => {
                copy(value);
              }}
            >
              <SvgIcon>
                <path d={mdiContentCopy} />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
