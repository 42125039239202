import { Box } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const MultilineText = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;

interface MultilineTextViewProps {
  bgcolor?: string;
  my?: number;
  p?: number;
}

export const MultilineTextView = ({
  bgcolor,
  children,
  my,
  p,
}: PropsWithChildren<MultilineTextViewProps>) => {
  return (
    <Box bgcolor={bgcolor} my={my} p={p} borderRadius={3}>
      <MultilineText>{children}</MultilineText>
    </Box>
  );
};
