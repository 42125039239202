import { Box, MenuItem, Typography } from '@material-ui/core';
import { FormikCheckboxField, FormikTextField } from '@superdispatch/forms';
import { CUSTOMER_TYPES, formatCustomerType } from '@superdispatch/sdk';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { email, required } from '../../shared/utils/ValidatorUtils';
import { LabelRequired } from './CustomerDetails';
import {
  CustomerEditDrawer,
  CustomerEditDrawerProps,
} from './CustomerEditDrawer';

type Props = Omit<CustomerEditDrawerProps, 'title' | 'children'>;

export function CustomerDetailsInfoEdit(props: Props) {
  return (
    <CustomerEditDrawer {...props} title="Edit Customer Info">
      <Stack space="small">
        <FormikTextField
          name="name"
          label={<LabelRequired text="Business Name" />}
          fullWidth={true}
          validate={required}
          autoFocus={true}
        />

        <FormikTextField
          label="Type"
          select={true}
          fullWidth={true}
          name="business_type"
        >
          {CUSTOMER_TYPES.map((value) => (
            <MenuItem key={value} value={value}>
              {formatCustomerType(value)}
            </MenuItem>
          ))}
        </FormikTextField>

        <Box mt={2}>
          <Typography variant="h4">Address</Typography>
        </Box>

        <FormikTextField
          label="Street Address"
          name="address"
          fullWidth={true}
        />

        <FormikTextField label="City" name="city" fullWidth={true} />

        <Columns space="xsmall">
          <Column width="1/2">
            <FormikTextField label="State" name="state" />
          </Column>

          <Column width="1/2">
            <FormikTextField label="ZIP Code" name="zip" />
          </Column>
        </Columns>

        <Box mt={2}>
          <Typography variant="h4">Additional Info</Typography>
        </Box>

        <FormikTextField label="Phone" name="phone" fullWidth={true} />

        <FormikTextField label="Fax" name="fax" fullWidth={true} />

        <FormikTextField
          fullWidth={true}
          label="Billing Email"
          name="email"
          validate={email}
        />

        <FormikTextField
          fullWidth={true}
          label="Website"
          name="personal_page_url"
        />

        <FormikTextField
          fullWidth={true}
          label="Custom External ID"
          name="custom_external_id"
        />

        <Box mt={2}>
          <Typography variant="h4">Billing</Typography>
        </Box>

        <FormikCheckboxField
          aria-label="customer edit bill the delivery"
          label="Bill the Delivery"
          name="bill_the_delivery"
          helperText="Bill the Delivery Location instead of the customer"
        />

        <Box mt={2}>
          <Typography variant="h4">Order ID</Typography>
        </Box>

        <FormikCheckboxField
          aria-label="customer edit use broker load id"
          label="Auto generate Order ID in Customer Portal"
          name="use_broker_load_id"
          helperText="Allow orders submitted from Customer Portal to take the next available Order ID from Shipper TMS"
        />
      </Stack>
    </CustomerEditDrawer>
  );
}
