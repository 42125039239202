import {
  EMPTY_ERROR_MESSAGE,
  FormikEnhancedStatus,
} from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { useRef } from 'react';
import { createAPIError } from 'shared/errors/APIError';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { customFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { yupNumber, yupObject } from 'shared/utils/YupUtils';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { useSuperPayValidationIncrement } from '../data/SuperPayAPI';
import { BankAccountDTO } from '../data/SuperPayDTO';
import { useMoovServiceContext } from '../MoovServiceContext';

const isStaging = import.meta.env.VITE_TARGET === 'staging';

interface StatusError {
  isExceeded: boolean;
  isIncorrect: boolean;
  isIncorrectTotalSum: boolean;
  isIncorrectLastValues: boolean;
  isStatusError: boolean;
}

export function getStatusErrors(
  status: Partial<FormikEnhancedStatus<unknown>>,
): StatusError {
  const payload = (status.payload as Error) || {};
  const message = payload.message || '';
  const isExceeded = message.includes('exceeded'); // Moov user exceeded quote
  const isIncorrect = message.includes('incorrect'); // Moov wrong deposit amounts
  const isIncorrectTotalSum = message.includes('warning_total_sum'); // The amounts are incorrect
  const isIncorrectLastValues = message.includes('warning_repeated_entry'); // Current values are marked as incorrect

  return {
    isExceeded,
    isIncorrect,
    isIncorrectTotalSum,
    isIncorrectLastValues,
    isStatusError:
      isExceeded || isIncorrect || isIncorrectTotalSum || isIncorrectLastValues,
  };
}

function sendVerificationEvent(status?: StatusError) {
  trackEvent('Shipper Submitted Bank Account Verification', {
    utm_medium: 'Verify Bank Account',
    verification_response: status
      ? getVerificationErrorText(status)
      : 'Verified',
  });
}

function getVerificationErrorText(errors: StatusError): string {
  const {
    isIncorrectTotalSum,
    isIncorrectLastValues,
    isIncorrect,
    isExceeded,
  } = errors;

  switch (true) {
    case isIncorrectTotalSum:
      return 'Incorrect Total Sum';
    case isIncorrectLastValues:
      return 'Repeated Incorrect Entry';
    case isIncorrect:
      return 'Incorrect';
    case isExceeded:
      return 'Exceeded';
    default:
      return 'Failed';
  }
}

function isCorrectAmountsValue(values: [number, number, number]) {
  const [first, second, third] = values.sort(
    (firstValue, secondValue) => firstValue - secondValue,
  );
  return {
    isCorrectAmounts: first + second === third,
    minValues: [first, second],
  };
}

const depositAmountsValidationSchema = yupObject({
  firstDeposit: yupNumber()
    .test(
      'isNumberValid',
      'This field is required',
      // Zeros are allowed for staging
      (value) => isStaging || !!value,
    )
    .required(),
  secondDeposit: yupNumber()
    .test(
      'isNumberValid',
      'This field is required',
      (value) => isStaging || !!value,
    )
    .required(),
  thirdDeposit: yupNumber()
    .test(
      'isNumberValid',
      'This field is required',
      (value) => isStaging || !!value,
    )
    .required(),
});

const errorsMessage = {
  firstDeposit: EMPTY_ERROR_MESSAGE,
  secondDeposit: EMPTY_ERROR_MESSAGE,
  thirdDeposit: EMPTY_ERROR_MESSAGE,
};

interface BankVerificationFormProps {
  onClose: () => void;
  onComplete: () => void;
  bankAccount: BankAccountDTO;
}

export function useBankVerificationForm({
  onClose,
  onComplete,
  bankAccount,
}: BankVerificationFormProps) {
  const { mutateAsync: upSuperPayValidationIncrement } =
    useSuperPayValidationIncrement();
  const { moovService } = useMoovServiceContext();
  const { addSnackbar } = useSnackbarStack();
  const { logPaymentInfo, logPaymentWarning, logPaymentError } =
    usePaymentLogger();
  const lastValues = useRef<number[] | null>();

  function isCorrectLastValues(values: number[]) {
    if (!lastValues.current) {
      return true;
    }
    const lastValuesSorted = lastValues.current
      .slice()
      .sort((firstValue, secondValue) => firstValue - secondValue);

    const valuesSorted = values.sort(
      (firstValue, secondValue) => firstValue - secondValue,
    );

    return lastValuesSorted.join('') !== valuesSorted.join('');
  }

  const formik = useAppFormik({
    validationSchema: depositAmountsValidationSchema,
    getFormErrors: customFormErrorsGetter,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (!moovService) {
        return Promise.reject(
          new Error('Please try again or reload the page.'),
        );
      }

      const { firstDeposit, secondDeposit, thirdDeposit } = values;

      const isCorrectLastValue = isCorrectLastValues([
        firstDeposit,
        secondDeposit,
        thirdDeposit,
      ]);

      if (!isCorrectLastValue) {
        return Promise.reject(
          createAPIError({
            message: 'warning_repeated_entry',
            context: errorsMessage,
          }),
        );
      }

      const { isCorrectAmounts, minValues } = isCorrectAmountsValue([
        firstDeposit,
        secondDeposit,
        thirdDeposit,
      ]);

      if (!isCorrectAmounts) {
        return Promise.reject(
          createAPIError({
            message: 'warning_total_sum',
            context: errorsMessage,
          }),
        );
      }

      return moovService.completeMicroDepositVerification(minValues);
    },
    onSubmitSuccess: () => {
      lastValues.current = null;
      addSnackbar('Bank account verified');
      sendVerificationEvent();
      onComplete();
      onClose();
      logPaymentInfo('Verified Bank Account', 'VerifyBankAccountForm');
    },
    onSubmitFailure: async (error, values) => {
      const { firstDeposit, secondDeposit, thirdDeposit } =
        depositAmountsValidationSchema.cast(values);
      lastValues.current = [firstDeposit, secondDeposit, thirdDeposit];

      const statusErrors = getStatusErrors({
        payload: error,
      });

      sendVerificationEvent(statusErrors);

      const { isIncorrect, isExceeded, isStatusError } = statusErrors;

      if (isStatusError) {
        formik.setErrors(errorsMessage);
        logPaymentWarning(
          `Micro Deposit Verification: ${getVerificationErrorText(
            statusErrors,
          )}`,
          'MoovAPI.completeMicroDepositVerification',
          {
            amounts: values,
            error,
          },
        );
      } else {
        logPaymentError(error, 'MoovAPI.completeMicroDepositVerification', {
          amounts: values,
        });
      }
      if (isIncorrect || isExceeded) {
        await upSuperPayValidationIncrement(bankAccount.guid);
        onComplete();
      }
    },
    getErrorMessage: (error) => {
      const { isStatusError } = getStatusErrors({
        payload: error,
      });
      return isStatusError ? null : error.message;
    },
  });

  return formik;
}
