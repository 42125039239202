import { BillingAddress, PaymentMethod } from './usePaymentDetails';

export function getBillingAddressText(billingAddress?: BillingAddress) {
  if (!billingAddress) return '';
  const {
    address = '',
    city = '',
    state = '',
    zip = '',
    country = '',
  } = billingAddress;
  return `${address} ${city}, ${state} ${zip}, ${country}`;
}

export function getExpirationDateText(paymentMethod?: PaymentMethod) {
  if (!paymentMethod?.expiry_month || !paymentMethod.expiry_year) return '';

  const { expiry_month: month, expiry_year: year } = paymentMethod;
  return `${month < 10 ? `0${month}` : month} / ${year.toString().slice(-2)}`;
}
