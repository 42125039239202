import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useAPI } from 'shared/api/API';
import { OrderImportInfoDTO } from './OrderImportInfoDTO';

export function useOrderImportAPI() {
  const { request, requestResource } = useAPI();

  return useMemo(
    () => ({
      deleteImportedOrders: (batchID: string) =>
        request('DELETE /internal/orders_import/orders_by_batch/{batchID}', {
          batchID,
        }),
      importOrders: (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return requestResource(
          'POST /internal/orders_import',
          (data) => plainToClass(OrderImportInfoDTO, data),
          { body: formData },
        );
      },
    }),
    [request, requestResource],
  );
}
