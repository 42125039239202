import { Exclude, Expose } from 'class-transformer';
import { RatingStatsDTO } from 'core/ratings/data/CarrierRatingDTO';
import {
  CarrierBrokerPreferences,
  CarrierLogistics,
} from 'shared/types/carrier';

@Exclude()
export class OrderRequestDTO {
  @Expose() broker_records?: CarrierBrokerPreferences;
  @Expose() id: string | null;
  @Expose() created_at: string;
  @Expose() changed_at: string;
  @Expose() pickup_date: string | null;
  @Expose() delivery_date: string | null;

  @Expose() is_insurance_expired: boolean;
  @Expose() is_super_carrier: boolean;
  @Expose() is_book_request: boolean;
  @Expose() is_super_pay_enabled: boolean;

  @Expose() status:
    | 'REQUEST_PENDING'
    | 'REQUEST_DECLINED_BY_BROKER'
    | 'REQUEST_CANCELED'
    | 'OFFER_SENT'
    | 'COUNTER_OFFER_SENT'
    | 'OFFER_ACCEPTED'
    | 'OFFER_CANCELED'
    | 'OFFER_DECLINED'
    | 'UNPOSTED';
  @Expose() unposted_date: string | null;
  @Expose() request_declined_date: string | null;
  @Expose() request_canceled_date: string | null;

  @Expose() offer_sent_date: string | null;
  @Expose() offer_accepted_date: string | null;
  @Expose() offer_declined_date: string | null;
  @Expose() offer_canceled_date: string | null;
  @Expose() counter_offer_price: number | null;

  @Expose() carrier_guid: string;
  @Expose() carrier_email: string;
  @Expose() carrier_phone: string;
  @Expose() carrier_name: string;
  @Expose() carrier_contact_name: string;
  @Expose() carrier_address: string | null;
  @Expose() carrier_city: string | null;
  @Expose() carrier_state: string | null;
  @Expose() carrier_zip: string | null;
  @Expose() carrier_usdot: string | null;
  @Expose() cargo_insurance: string | null;
  @Expose() cargo_limit: number | null;
  @Expose() cargo_deductible: number | null;
  @Expose() largest_trailer_capacity: number | null;
  @Expose() insurance_requirements_met: boolean;
  @Expose() logistics_type: CarrierLogistics | null;

  @Expose() load_guid: string | null;
  @Expose() guid: string;
  @Expose() price: number;

  @Expose()
  carrier_rating_stats?: RatingStatsDTO | null;
}
