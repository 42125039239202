import { parseDate } from '@superdispatch/dates';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { apiInstance } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { usePermission } from 'shared/data/UserPermissions';
import { MoovScope, MoovTokenResponse } from 'shared/helpers/superpay/MoovDTO';
import { useMoovServiceContext } from '../MoovServiceContext';
import { BusinessContactsDTO } from './BusinessDTO';
import {
  addendumSchema,
  alertSchema,
  disableDatesExpectedChargeSchema,
  dynamicStatisticsSchema,
  expectedChargeDateScheduleSchema,
  nowPreviewSchema,
  requestedCarriersSchema,
  SuperPayEditDTO,
  SuperPaySettingsDTO,
  superPaySettingsSchema,
  SuperPayTermsConditionsDTO,
  superPayTermsConditionsSchema,
  SuperPayUpdateSettingsDTO,
} from './SuperPayDTO';

export function useSuperPayAPI() {
  const { requestResource } = apiInstance;

  return useMemo(
    () => ({
      getMoovToken: (scope: MoovScope) => {
        return requestResource(
          'POST /internal/payments/moov/generate-token',
          (data) => data as MoovTokenResponse,
          {
            json: {
              scope: scope.toUpperCase(), // TBD, this should be consistent across CTMS and STMS
            },
          },
        );
      },
    }),
    [requestResource],
  );
}

export function useGenerateMoovTokenOnce(
  initialScope: MoovScope,
): APIQueryResult<MoovTokenResponse> {
  const { getMoovToken } = useSuperPayAPI();
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  return useAPIQuery('generate-moov-token', () => getMoovToken(initialScope), {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: canManageSuperPay,
  });
}

export function useRepresentativesList() {
  const { moovService } = useMoovServiceContext();
  const queryKey = moovService ? 'representatives' : 'representatives loading';
  return useQuery(queryKey, () =>
    moovService
      ?.getRepresentativesList()
      .then((response) =>
        response.sort((a, b) =>
          parseDate(a.createdOn, { format: 'DateTimeISO' }) >
          parseDate(b.createdOn, { format: 'DateTimeISO' })
            ? 1
            : -1,
        ),
      ),
  );
}

export function useMoovAccount(enabled: boolean) {
  const { moovService } = useMoovServiceContext();
  const queryKey = moovService ? ['moov_account'] : ['moov_account_loading'];
  return useQuery(
    queryKey,
    () => moovService?.getMoovAccount().then((response) => response),
    { enabled },
  );
}

export function useSuperPayValidationIncrement() {
  const { requestResource } = apiInstance;
  return useAPIMutation(
    (bank_account_guid: string) =>
      requestResource(
        'POST /internal/payments/increment_fail_count',
        () => true,
        { json: { bank_account_guid } },
      ).catch(() => undefined), // the fall of the method should not affect
  );
}

export function useSuperPaySettings(
  options?: APIQueryOptions<SuperPaySettingsDTO>,
): APIQueryResult<SuperPaySettingsDTO> {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'settings'],
    () =>
      requestResource('GET /internal/payments/super_pay_settings', (data) =>
        superPaySettingsSchema.cast(data),
      ),
    options,
  );
}

export function useSuperPaySettingsCache() {
  const queryClient = useQueryClient();

  function invalidateSuperPaySettings() {
    void queryClient.invalidateQueries(['super-pay', 'settings']);
  }

  return { invalidateSuperPaySettings };
}

export function useSuperPayEdit(orderId: number) {
  const { requestResource } = apiInstance;
  return useAPIMutation((params: SuperPayEditDTO) =>
    requestResource(
      'PUT /internal/orders/{orderId}/update_payment',
      (data) => data,
      { orderId, json: params },
    ),
  );
}

export function useOwnersProvidedInfoMutation(
  options?: APIMutationOptions<void, string>,
) {
  const { moovService } = useMoovServiceContext();
  return useAPIMutation<void, string>(
    () =>
      moovService
        ? moovService.updateOwnersProvidedInfo()
        : Promise.reject(new Error('Please try again or reload the page.')),
    options,
  );
}

export function useSyncSuperPayOnboardingStep() {
  const { request } = apiInstance;
  return useAPIMutation(
    (values: {
      moov_account_id: string;
      moov_bank_account_id: string;
      scope: MoovScope;
    }) =>
      request('POST /internal/payments/sync_onboarding_step', {
        json: values,
      }),
  );
}

export function useUpdateSuperPaySettings() {
  const { requestResource } = apiInstance;
  return useAPIMutation((params: SuperPayUpdateSettingsDTO) =>
    requestResource(
      'PUT /internal/payments/super_pay_settings',
      (data) => data,
      { json: params },
    ),
  );
}

export function useUpdateSuperPayTermsConditionsAccept() {
  const { request } = apiInstance;
  return useAPIMutation((termsConditionsToken: string) => {
    const params = {
      accepted_date: new Date().toISOString(),
      acceptance_token: termsConditionsToken,
    };
    return request('PUT /internal/payments/accept_superpay_addendum', {
      json: params,
    });
  });
}

export function useSuperPayTermsConditionsToken() {
  const { requestResource } = apiInstance;
  return useAPIMutation<void, SuperPayTermsConditionsDTO>(() => {
    return requestResource('GET /internal/payments/acceptance_token', (data) =>
      superPayTermsConditionsSchema.cast(data),
    );
  });
}

export function useExpectedChargeDateSchedule(expectedDate: string | null) {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'expected-charge-date', { expectedDate }],
    () =>
      requestResource(
        'POST /internal/payments/expected_charge_date',
        (data) => expectedChargeDateScheduleSchema.cast(data),
        { json: { expected_date: expectedDate } },
      ),
    {
      enabled: !!expectedDate,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
}

export function useDisableDatesExpectedCharge() {
  const { requestResource } = apiInstance;
  return useAPIQuery(['super-pay', 'expected-disable-charge-date'], () =>
    requestResource('GET /internal/payments/disabled_dates', (data) =>
      disableDatesExpectedChargeSchema.cast(data),
    ),
  );
}

export function useDynamicStatistics() {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'dynamic-statistics-promo'],
    () =>
      requestResource(
        'GET /internal/payments/pre_onboarding_statistics',
        (data) => dynamicStatisticsSchema.cast(data),
      ),
    { refetchOnWindowFocus: false },
  );
}

export function useSuperPayRequestedCarriers() {
  const { requestPage } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'requested-carriers'],
    () =>
      requestPage(
        'GET /internal/payments/superpay_requested_carriers',
        (data) => requestedCarriersSchema.cast(data),
      ),
    { refetchOnWindowFocus: false },
  );
}

export function useSuperPayAlert(enabled = true) {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'alerts'],
    () =>
      requestResource('GET /internal/payments/alert', (data) =>
        alertSchema.cast(data),
      ),
    { enabled },
  );
}

export function useSuperPayAddendum() {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'addendum'],
    () =>
      requestResource('GET /internal/payments/setup_settings', (data) =>
        addendumSchema.cast(data),
      ),
    { refetchOnWindowFocus: false },
  );
}

export function useUpdateBusinessContact() {
  const { request } = apiInstance;
  return useAPIMutation((params: BusinessContactsDTO) =>
    request('PUT /internal/shippers/business_details', {
      json: {
        address: params.streetAddress,
        city: params.city,
        state: params.state,
        zip: params.zip,
        contact_phone: params.phoneNumber,
        personal_page_url: params.website,
      },
    }),
  );
}

export function useSuperPayNowPreview(order_guids: string[]) {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'now-preview'],
    () =>
      requestResource(
        'GET /internal/orders/payments/superpay_now_preview{?order_guids}',
        (data) => nowPreviewSchema.cast(data),
        { order_guids },
      ),
    { refetchOnWindowFocus: false },
  );
}
