import { Box, Card, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import { Button, CardButton, Color, Inline } from '@superdispatch/ui';
import { useState } from 'react';
import { CustomerContactDTO } from 'shared/dto/CustomerContactDTO';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { useCustomerContacts } from '../data/CustomerContactAPI';
import { CustomerDetailsContactsItem } from './CustomerDetailsContactsItem';

interface CustomerDetailsContactsProps {
  customer: CustomerDTO;
  onCreate: () => void;
  onEdit: (contact: CustomerContactDTO) => void;
}

export function CustomerDetailsContacts({
  customer,
  onCreate,
  onEdit,
}: CustomerDetailsContactsProps) {
  const [page, setPage] = useState<number>(0);
  const { data: contacts } = useCustomerContacts(customer.id, page);

  const totalPages = contacts?.pagination.total_pages;

  if (!contacts) {
    return (
      <Card>
        <Skeleton variant="rect" height="104px" />
      </Card>
    );
  }

  if (contacts.objects.length === 0) {
    return (
      <Box bgcolor={Color.White}>
        <CardButton startIcon={<Add />} onClick={onCreate}>
          Add Contact
        </CardButton>
      </Box>
    );
  }

  return (
    <Card>
      <Box my={1}>
        <Box
          py={1}
          px={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">Contacts</Typography>

          <Box my="-2px">
            <Button
              variant="text"
              color="primary"
              startIcon={<Add />}
              onClick={onCreate}
            >
              Add New
            </Button>
          </Box>
        </Box>

        {contacts.objects.map((contact) => (
          <CustomerDetailsContactsItem
            key={contact.id}
            contact={contact}
            customer={customer}
            onEdit={() => onEdit(contact)}
          />
        ))}

        {!!totalPages && totalPages > 1 && (
          <Inline horizontalAlign="center">
            <Pagination
              page={page + 1}
              count={totalPages}
              onChange={(_, nextPage) => setPage(nextPage - 1)}
            />
          </Inline>
        )}
      </Box>
    </Card>
  );
}
