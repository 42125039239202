import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { useUserState } from 'shared/data/AppUserState';
import { isAPIError } from 'shared/errors/APIError';
import { centralDispatchCredentialsSchema } from './CentralDispatchCredentialsDTO';

export function useCentralDispatchCredentials() {
  const { requestResource } = useAPI();
  const { user } = useUserState();
  const isShipper = user?.shipper.shipper_type === 'BROKER';

  return useAPIQuery(
    ['central-dispatch', 'credentials'],
    () =>
      requestResource('/internal/central-dispatch/credentials', (data) =>
        centralDispatchCredentialsSchema.cast(data),
      ).catch((error) => {
        if (
          isAPIError(error) &&
          error.error_id === 'CENTRAL_DISPATCH_CREDENTIALS_NOT_FOUND'
        ) {
          return centralDispatchCredentialsSchema.cast({
            login: null,
            uid: null,
            is_valid_web_credentials: false,
          });
        }

        throw error;
      }),
    { enabled: isShipper },
  );
}
