import { Exclude, Expose } from 'class-transformer';

export type ShipperRatingType = 'POSITIVE' | 'NEGATIVE';
export type ShipperRatingStatus = 'PENDING' | 'PUBLISHED';

export type ShipperPositiveRatingTag =
  | 'ON_TIME_PAYMENT'
  | 'GOOD_COMMUNICATION'
  | 'ACCURATE_INFORMATION';

export type ShipperNegativeRatingTag =
  | 'LATE_PAYMENT'
  | 'NON_PAYMENT'
  | 'PARTIAL_PAYMENT'
  | 'INACCURATE_INFORMATION'
  | 'VEHICLE_NOT_READY_MISSING';

export type ShipperRatingTag =
  | ShipperPositiveRatingTag
  | ShipperNegativeRatingTag;

@Exclude()
export class ShipperRatingDTO {
  static formatRatingTag(tag: ShipperRatingTag) {
    switch (tag) {
      case 'ON_TIME_PAYMENT':
        return 'On-Time Payment';
      case 'GOOD_COMMUNICATION':
        return 'Good Communication';
      case 'ACCURATE_INFORMATION':
        return 'Accurate Information';
      case 'LATE_PAYMENT':
        return 'Late Payment';
      case 'NON_PAYMENT':
        return 'Non Payment';
      case 'PARTIAL_PAYMENT':
        return 'Partial Payment';
      case 'INACCURATE_INFORMATION':
        return 'Inaccurate Information';
      case 'VEHICLE_NOT_READY_MISSING':
        return 'Vehicle Not Ready/Missing';
    }
  }

  @Expose() id: number;
  @Expose() name: string;
  @Expose() guid: string;
  @Expose() carrier_guid: string;
  @Expose() rated_at: string | null;
  @Expose() comment: string | null;
  @Expose() rating: ShipperRatingType | null;
  @Expose() tags: ShipperRatingTag[];
  @Expose() status: ShipperRatingStatus;
  @Expose() response: string | null;
  @Expose() response_date: string | null;
}

@Exclude()
export class ShipperRatingTagStatsDTO {
  @Expose() ON_TIME_PAYMENT: number;
  @Expose() GOOD_COMMUNICATION: number;
  @Expose() ACCURATE_INFORMATION: number;
}

@Exclude()
export class ProfileUnratedCarrierDTO {
  @Expose() guid: string;
  @Expose() name: string;
  @Expose() logo: string;
  @Expose() accepted_loads_count: number;
  @Expose() total_rating_count: number;
  @Expose() overall_rating: number;
}
