import { Box, Card, MenuItem, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { CUSTOMER_TYPES, formatCustomerType } from '@superdispatch/sdk';
import { useSnackbarStack } from '@superdispatch/ui';
import { Field, Form, FormikProvider } from 'formik';
import { useEffect, useMemo } from 'react';
import { email, required } from 'shared/utils/ValidatorUtils';
import {
  CustomerDTO,
  CustomerWithContactFormData,
} from '../../shared/dto/CustomerDTO';
import { useCustomersAPI } from '../data/CustomerAPI';
import { useContactAPI } from '../data/CustomerContactAPI';
import { LabelRequired } from '../details/CustomerDetails';

interface CustomerFormProps {
  showRequiredFieldsOnly: boolean;
  onSubmitSuccess: (customer: CustomerDTO) => void;
  onStateChange: (state: { isSubmitting: boolean }) => void;
}

export function CustomerCreateForm({
  onStateChange,
  onSubmitSuccess,
  showRequiredFieldsOnly,
}: CustomerFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { createCustomer } = useCustomersAPI();
  const { createContact } = useContactAPI();

  const initialValues = useMemo<CustomerWithContactFormData>(
    () => ({
      customer: { name: '', business_type: 'BUSINESS' },
      contact: { email: '', is_primary: true },
    }),
    [],
  );

  const form = useFormikEnhanced<CustomerWithContactFormData, CustomerDTO>({
    initialValues,
    onSubmit: async ({ contact, customer }) => {
      const createdCustomer = await createCustomer(customer);

      if (contact?.name) {
        await createContact(createdCustomer.id, contact);
      }

      return createdCustomer;
    },
    onSubmitSuccess(createdCustomer) {
      addSnackbar('Customer created', { variant: 'success' });
      onSubmitSuccess(createdCustomer);
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  useEffect(() => {
    onStateChange({ isSubmitting: form.isSubmitting });
  }, [form.isSubmitting, onStateChange]);

  return (
    <FormikProvider value={form}>
      <Box width={440} mx="auto">
        <Form id="customers-create-form">
          <Card>
            <Box display="flex" flexWrap="wrap" p={1}>
              <Box width={1} p={1}>
                <Typography variant="h3">Customer Info</Typography>
              </Box>

              <Box width={1} p={1}>
                <FormikTextField
                  label={<LabelRequired text="Business Name" />}
                  name="customer.name"
                  validate={required}
                  fullWidth={true}
                />
              </Box>

              <Box width={1 / 2} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Type"
                  name="customer.business_type"
                  select={true}
                  fullWidth={true}
                >
                  {CUSTOMER_TYPES.map((value) => (
                    <MenuItem key={value} value={value}>
                      {formatCustomerType(value)}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Box>

              <Box width={1 / 2} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Phone"
                  name="customer.phone"
                  fullWidth={true}
                />
              </Box>

              <Box width={1} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Billing Email"
                  name="customer.email"
                  validate={email}
                  fullWidth={true}
                />
              </Box>

              <Box width={1} p={1} mt={2} hidden={showRequiredFieldsOnly}>
                <Typography variant="h4">Address</Typography>
              </Box>

              <Box width={1} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Street Address"
                  name="customer.address"
                  fullWidth={true}
                />
              </Box>

              <Box width={1 / 2} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="City"
                  name="customer.city"
                  fullWidth={true}
                />
              </Box>

              <Box width={1 / 4} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="State"
                  name="customer.state"
                  fullWidth={true}
                />
              </Box>

              <Box width={1 / 4} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="ZIP Code"
                  name="customer.zip"
                  fullWidth={true}
                />
              </Box>

              <Box width={1} p={1} mt={2} hidden={showRequiredFieldsOnly}>
                <Typography variant="h4">Contact</Typography>
              </Box>

              <Box width={1} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Contact Name"
                  name="contact.name"
                  fullWidth={true}
                />
              </Box>

              <Box width={1} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Title"
                  name="contact.title"
                  fullWidth={true}
                />
              </Box>

              <Box width={1 / 2} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Phone"
                  name="contact.phone"
                  fullWidth={true}
                />
              </Box>

              <Box width={1 / 2} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Mobile"
                  name="contact.mobile_phone"
                  fullWidth={true}
                />
              </Box>

              <Box width={1} p={1} hidden={showRequiredFieldsOnly}>
                <FormikTextField
                  label="Email"
                  name="contact.email"
                  fullWidth={true}
                  validate={email}
                />
              </Box>

              <Field
                component="input"
                type="checkbox"
                name="contact.is_primary"
                hidden={true}
              />

              <Box width={1} p={1} hidden={showRequiredFieldsOnly}>
                <FormikCheckboxField
                  aria-label="customer create use broker load id"
                  label="Auto generate Order ID in Customer Portal"
                  name="customer.use_broker_load_id"
                  helperText="Allow orders submitted from Customer Portal to take the next available Order ID from Shipper TMS"
                />
              </Box>
            </Box>
          </Card>
        </Form>
      </Box>
    </FormikProvider>
  );
}
