import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { useTerminalsAPI } from '../data/TerminalsAPI';

interface TerminalEditDrawerContentProps {
  terminal: TerminalDTO;
  title: ReactNode;
  children: ReactNode;
  onSubmitSuccess: (response: TerminalDTO) => void;
}

export function TerminalEditDrawerContent({
  title,
  children,
  terminal,
  onSubmitSuccess,
}: TerminalEditDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateTerminal } = useTerminalsAPI();

  const form = useFormikEnhanced({
    initialValues: terminal,
    onSubmit(values) {
      return updateTerminal(values);
    },
    onSubmitSuccess(response: TerminalDTO) {
      addSnackbar('Terminal updated', { variant: 'success' });
      onSubmitSuccess(response);
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikDrawerContent
      title={title}
      formik={form}
      actions={<FormikDrawerActions />}
    >
      {children}
    </FormikDrawerContent>
  );
}

interface TerminalEditDrawerProps extends TerminalEditDrawerContentProps {
  open: boolean;
  onClose: () => void;
}

export function TerminalEditDrawer({
  open,
  onClose,
  ...props
}: TerminalEditDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose} confirmClose={true}>
      <TerminalEditDrawerContent {...props} />
    </FormikDrawer>
  );
}
