import {
  Collapse,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Column, Columns } from '@superdispatch/ui';
import { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useStorageValue, writeStorageItem } from '../helpers/LocalStorage';

interface Props extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: string;
  persist?: boolean;
  onChange: (value: string) => void;
  items: Array<{
    value: string;
    label: string;
    items: Array<{ value: string; label: string }>;
  }>;
}

export default function SelectGroup({
  value: selected,
  items,
  persist,
  onChange,
  ...props
}: Props) {
  const flatItems = useMemo(() => {
    return items.flatMap(({ value, label, items: innerItems }) => [
      { value, label },
      ...innerItems,
    ]);
  }, [items]);
  const selectedItem = useMemo(() => {
    return flatItems.find((x) => x.value === selected);
  }, [flatItems, selected]);

  return (
    <TextField
      {...props}
      select={true}
      value={selectedItem?.value}
      SelectProps={{
        fullWidth: true,
        renderValue: () => selectedItem?.label,
      }}
    >
      {items.map(({ value, label, items: innerItems }, index) => (
        <div key={value}>
          {index !== 0 && <Divider key={value} />}

          <SelectGroupMenuItem
            persist={persist}
            header={label}
            items={innerItems}
            value={value}
            selected={selected}
            onSelect={(selectedValue) => {
              onChange(selectedValue);
            }}
          />
        </div>
      ))}
    </TextField>
  );
}

const StyledMenuItem = styled(MenuItem)`
  padding-left: 8px;
`;

interface SelectGroupMenuItemProps {
  persist?: boolean;
  items: Array<{ value: string; label: string }>;
  header: ReactNode;
  value: string;
  selected: string;
  onSelect: (value: string) => void;
}

function SelectGroupMenuItem({
  persist,
  header,
  value,
  selected,
  items,
  onSelect,
}: SelectGroupMenuItemProps) {
  const expandedItems = useStorageValue('SelectGroupMenuItem__expandedItems');
  const expandedSet = new Set(expandedItems?.split(','));
  const [open, setOpen] = useState(() => persist && expandedSet.has(value));

  return (
    <>
      <StyledMenuItem
        selected={value === selected}
        onClick={() => {
          onSelect(value);
        }}
      >
        <Columns space="xsmall">
          <Column width="1/5">
            {items.length > 0 && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(!open);

                  if (persist) {
                    persistState(expandedSet, value, !open);
                  }
                }}
              >
                {open ? (
                  <ExpandLess fontSize="small" color="action" />
                ) : (
                  <ExpandMore fontSize="small" color="action" />
                )}
              </IconButton>
            )}
          </Column>

          <Column>{header}</Column>
        </Columns>
      </StyledMenuItem>
      <Collapse in={open}>
        {items.map(({ value: innerValue, label }) => (
          <MenuItem
            key={innerValue}
            value={innerValue}
            selected={innerValue === selected || value === selected}
            onClick={() => {
              onSelect(innerValue);
            }}
          >
            <Columns space="xsmall">
              <Column width="1/5" />

              <Column> {label}</Column>
            </Columns>
          </MenuItem>
        ))}
      </Collapse>
    </>
  );
}

function persistState(expandedSet: Set<string>, value: string, open: boolean) {
  const nextExpandedSet = new Set(expandedSet);
  if (open) {
    nextExpandedSet.add(value);
  } else {
    nextExpandedSet.delete(value);
  }

  writeStorageItem(
    'SelectGroupMenuItem__expandedItems',
    Array.from(nextExpandedSet).join(','),
  );
}
