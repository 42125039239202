import { Stack } from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import { showNewMessage } from 'shared/helpers/IntercomHelpers';

export function SuperLoadboardContactsDisabledDescription() {
  return (
    <Alert severity="info">
      <Stack space="xsmall">
        Setting Unavailable: Your company cross-posts loads to multiple load
        boards.
        <Button
          variant="neutral"
          size="small"
          onClick={() => {
            showNewMessage(
              "Hi, I'm requesting access to the 'Reduce the risk of Double-Brokering' setting on Super Loadboard; please inform my customer success manager to facilitate this.",
            );
          }}
        >
          Request Access
        </Button>
      </Stack>
    </Alert>
  );
}
