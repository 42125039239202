import { useSearchInputState } from 'shared/helpers/ReactHelpers';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { useCustomerContacts } from './data/DictionaryAPI';

interface ContactAutocompleteProps
  extends Omit<AutocompleteBaseProps<string>, 'options' | 'onChange'> {
  onChange: (values: string[]) => void;
}

export function ContactAutocomplete({
  onChange,
  ...props
}: ContactAutocompleteProps) {
  const { input, query, setInput } = useSearchInputState();
  const { isLoading, data: { objects = [] } = {} } = useCustomerContacts(query);

  return (
    <AutocompleteBase
      {...props}
      placeholder="3 symbols to search"
      options={objects}
      inputValue={input}
      loading={isLoading}
      getOptionLabel={(option) => option}
      onChange={(_, option) => onChange(option)}
      onInputChange={(_, text) => setInput(text)}
    />
  );
}
