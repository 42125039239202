import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { ReactNode, useMemo } from 'react';

export type AutocompleteBaseProps<T> = Omit<
  AutocompleteProps<T, true, false, false>,
  | 'renderInput'
  | 'multiple'
  | 'clearOnEscape'
  | 'disableCloseOnSelect'
  | 'filterSelectedOptions'
> & {
  label?: ReactNode;
  autoFocus?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string | string[] | undefined;
};

export function AutocompleteBase<T>({
  filterOptions = (item) => item,
  value: valueProp,
  label,
  openOnFocus = true,
  autoFocus = true,
  placeholder = '',
  error = false,
  helperText = '',
  ...props
}: AutocompleteBaseProps<T>) {
  const value = useMemo(() => valueProp || [], [valueProp]);
  return (
    <Autocomplete
      {...props}
      value={value}
      multiple={true}
      clearOnEscape={true}
      openOnFocus={openOnFocus}
      disableCloseOnSelect={true}
      filterOptions={filterOptions}
      filterSelectedOptions={true}
      renderInput={({ inputProps, ...rest }) => (
        <TextField
          {...rest}
          label={label}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          inputProps={{
            ...inputProps,
            'aria-label': 'dictionary autocomplete',
            placeholder,
          }}
        />
      )}
    />
  );
}
