import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { useCallback, useMemo } from 'react';
import Order from 'shared/types/order';
import { useValidateOrderActionAPI } from '../../data/OrderActionAPI';

export type ValidateActionType =
  | 'send_offer'
  | 'post_to_cd'
  | 'post_to_sdlb'
  | 'post_to_all'
  | 'send_invoice'
  | 'combine_orders'
  | 'submit_to_broker'
  | 'mark_as_on_hold'
  | 'mark_as_accepted'
  | 'mark_as_picked_up'
  | 'mark_as_delivered';

export function isValidateAction(type: string): type is ValidateActionType {
  return [
    'send_offer',
    'post_to_cd',
    'post_to_sdlb',
    'post_to_all',
    'send_invoice',
    'combine_orders',
    'submit_to_broker',
    'mark_as_on_hold',
    'mark_as_accepted',
    'mark_as_picked_up',
    'mark_as_delivered',
  ].includes(type);
}

function getValidateActionConfig(type: ValidateActionType): {
  validate: (
    api: ReturnType<typeof useValidateOrderActionAPI>,
    ids: number[],
    orders: Order[],
  ) => Promise<Response>;
} {
  switch (type) {
    case 'post_to_cd':
    case 'post_to_sdlb':
    case 'post_to_all':
      return {
        validate: (api, ids) =>
          api.validatePostToLoadboard(
            ids,
            type === 'post_to_all'
              ? ['sdlb', 'cd']
              : type === 'post_to_sdlb'
              ? ['sdlb']
              : ['cd'],
          ),
      };
    case 'combine_orders':
      return {
        validate: (api, _ids, orders) => {
          const guids = orders.map((x) => x.guid);
          return api.validateCombineOrders(guids);
        },
      };
    case 'submit_to_broker':
      return {
        validate: (api, ids) => api.validateSubmitToBroker(ids),
      };
    case 'mark_as_on_hold':
      return {
        validate: (api, ids) => api.validateMarkAsOnHold(ids),
      };
    case 'mark_as_accepted':
      return {
        validate: (api, ids) => api.validateMarkAsAccepted(ids),
      };
    case 'mark_as_delivered':
      return {
        validate: (api, ids) => api.validateMarkAsDelivered(ids),
      };
    case 'mark_as_picked_up':
      return {
        validate: (api, ids) => api.validateMarkAsPickedUp(ids),
      };
    case 'send_invoice':
      return {
        validate: (api, ids) => api.validateSendInvoices(ids),
      };
    case 'send_offer':
      return {
        validate: (api, ids) => api.validateSendOffers(ids),
      };
  }
}

interface OrderValidateActionOptions {
  onComplete: (type: ValidateActionType) => void;
  onFailure: (error: unknown) => void;
}

export function useOrderValidateAction({
  onComplete,
  onFailure,
}: OrderValidateActionOptions) {
  const { addSnackbar } = useSnackbarStack();
  const validateActionAPI = useValidateOrderActionAPI();

  const { isSubmitting, handleSubmit, setValues } = useFormikEnhanced<
    { type?: ValidateActionType; orders?: Order[] },
    Response
  >({
    initialValues: {},
    onSubmit({ type, orders }) {
      if (type && orders) {
        const ids = orders.map((x) => x.id);
        const config = getValidateActionConfig(type);
        return config.validate(validateActionAPI, ids, orders);
      }

      return Promise.reject(new Error('Order not found'));
    },
    onSubmitSuccess(_, { type, orders }) {
      if (type && orders) {
        onComplete(type);
      }
    },
    onSubmitFailure(error) {
      if ('details' in error) {
        onFailure(error);
      } else {
        addSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const onValidate = useCallback(
    (type: ValidateActionType, orders: Order[]) => {
      setValues({ type, orders });
      handleSubmit();
    },
    [setValues, handleSubmit],
  );

  return useMemo(
    () => ({ onValidate, isValidating: isSubmitting }),
    [isSubmitting, onValidate],
  );
}
