import { useCallback } from 'react';
import {
  logError,
  logInfo,
  logWarning,
} from 'shared/helpers/MonitoringService';
import { useMoovServiceContext } from '../MoovServiceContext';

interface LogExtraInfoProps extends Record<string, unknown> {
  moovAccountId?: string;
}

export function logPaymentError(
  error: unknown,
  source: string,
  { moovAccountId, ...extraInfo }: LogExtraInfoProps = {},
) {
  logError(error, `shipper.payments.onboarding.${source}`, {
    moov_account_id: moovAccountId,
    ...extraInfo,
  });
}

export function usePaymentLogger() {
  const { moovAccountInfo } = useMoovServiceContext();
  const moovAccountId = moovAccountInfo?.getAccount()?.accountID;
  const infoLog = useCallback(
    (action: string, source: string, extraInfo?: Record<string, unknown>) => {
      logInfo(action, {
        namespace: `shipper.payments.onboarding.${source}`,
        moov_account_id: moovAccountId,
        ...extraInfo,
      });
    },
    [moovAccountId],
  );

  const warningLog = useCallback(
    (action: string, source: string, extraInfo?: Record<string, unknown>) => {
      logWarning(action, {
        namespace: `shipper.payments.onboarding.${source}`,
        moov_account_id: moovAccountId,
        ...extraInfo,
      });
    },
    [moovAccountId],
  );

  const errorLog = useCallback(
    (error: Error, source: string, extraInfo?: Record<string, unknown>) => {
      logPaymentError(error, source, {
        moovAccountId,
        ...extraInfo,
      });
    },
    [moovAccountId],
  );
  return {
    logPaymentInfo: infoLog,
    logPaymentWarning: warningLog,
    logPaymentError: errorLog,
  };
}
