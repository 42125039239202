import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { FormikCurrencyField } from 'shared/form/FormikCurrencyField';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import {
  CarrierInsuranceRequirementsDTO,
  ShipperProfileDTO,
} from '../../data/ShipperProfileDTO';

export interface InsuranceLimitRowProps {
  index: number;
  isLast: boolean;
  onLimitDelete?: () => void;
}

export function InsuranceLimitRow({
  onLimitDelete,
  index,
  isLast,
}: InsuranceLimitRowProps) {
  const isDeleteHidden = !isLast || index === 0;
  return (
    <Columns aria-label={`trailer size cargo limit ${index + 1}`} space="small">
      <Column width="1/4">
        <TextField
          value={`${index + 1}${isLast ? '+' : ''}`}
          disabled={true}
          label={index === 0 ? 'Trailer' : ''}
          InputProps={{
            inputProps: { 'aria-label': 'Trailer' },
            endAdornment: (
              <InputAdornment position="end">
                {formatPlural(index + 1, 'unit', 'units')}
              </InputAdornment>
            ),
          }}
        />
      </Column>
      <Column width="fluid">
        <FormikCurrencyField
          fullWidth={true}
          label={index === 0 ? 'Coverage Amount' : ''}
          inputProps={{
            'aria-label': 'Cargo Limit',
            decimalScale: 0,
            thousandSeparator: true,
            maxLength: 11,
          }}
          name={`carrier_insurance_requirements[${index}].cargo_limit`}
        />
      </Column>
      <Column width="content">
        <IconButton
          aria-label="delete cargo limit"
          onClick={onLimitDelete}
          disabled={isDeleteHidden}
          size="small"
        >
          <DeleteOutline visibility={isDeleteHidden ? 'hidden' : 'visible'} />
        </IconButton>
      </Column>
    </Columns>
  );
}

export function InsuranceRequirementsMinimumFields() {
  const formik = useFormikContext<
    ShipperProfileDTO & {
      carrier_insurance_requirements: CarrierInsuranceRequirementsDTO[];
    }
  >();

  const insuranceLimits = formik.values.carrier_insurance_requirements;
  const isLastLimitEmpty =
    insuranceLimits[insuranceLimits.length - 1]?.cargo_limit == null;

  function addRow(arrayHelpers: FieldArrayRenderProps) {
    const lastIndex = insuranceLimits.length - 1;
    const fieldName = `carrier_insurance_requirements[${lastIndex}].cargo_limit`;
    formik.setFieldTouched(fieldName, true);

    void formik.validateForm().then((errors) => {
      const insuranceErrors = errors.carrier_insurance_requirements;
      //add row if there are no errors in last row or error list is undefined
      if (
        !insuranceErrors ||
        (Array.isArray(insuranceErrors) &&
          insuranceErrors[insuranceErrors.length - 1] === undefined)
      ) {
        arrayHelpers.push({
          trailer_size: insuranceLimits.length + 1,
          cargo_limit: null,
        });
      }
    });
  }

  return (
    <FieldArray name="carrier_insurance_requirements">
      {(arrayHelpers) => (
        <Stack space="small">
          <Stack space="xxsmall">
            <Typography variant="h4">Cargo Insurance Minimum</Typography>
            <Typography variant="caption" color="textSecondary">
              Enter a limit for all type of car haulers or specify by trailer
              capacity.
            </Typography>
          </Stack>
          {insuranceLimits.map((limit, index, allRequirements) => (
            <InsuranceLimitRow
              key={limit.trailer_size}
              index={index}
              isLast={allRequirements.length === index + 1}
              onLimitDelete={() => arrayHelpers.remove(index)}
            />
          ))}
          {insuranceLimits.length < 9 ? (
            <ResponsiveTooltip
              title={isLastLimitEmpty ? 'Enter Cargo Limit' : ''}
            >
              <Button
                variant="text"
                startIcon={<Add />}
                onClick={() => addRow(arrayHelpers)}
              >
                Add Cargo Coverage Minimum
              </Button>
            </ResponsiveTooltip>
          ) : (
            <Box paddingBottom="large" />
          )}
        </Stack>
      )}
    </FieldArray>
  );
}
