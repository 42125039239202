import { FormikMaxLengthTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { Divider } from 'shared/ui/Divider';
import {
  loadboardInstructionsHint,
  orderInstructionsHint,
} from './CustomerDetailsInstructions';
import {
  CustomerEditDrawer,
  CustomerEditDrawerProps,
} from './CustomerEditDrawer';

type Props = Omit<CustomerEditDrawerProps, 'title' | 'children'>;

export function CustomerDetailsInstructionsEdit({ customer, ...props }: Props) {
  return (
    <CustomerEditDrawer
      {...props}
      customer={customer}
      title={
        customer.order_instructions || customer.loadboard_instructions
          ? 'Edit Instructions'
          : 'Add Instructions'
      }
    >
      <Stack space="small">
        <FormikMaxLengthTextField
          label="Loadboard Instructions"
          maxLength={60}
          fullWidth={true}
          multiline={true}
          name="loadboard_instructions"
          helperText={loadboardInstructionsHint}
        />

        <Divider />

        <FormikMaxLengthTextField
          fullWidth={true}
          label="Order Instructions"
          name="order_instructions"
          multiline={true}
          maxLength={10000}
          helperText={orderInstructionsHint}
        />
      </Stack>
    </CustomerEditDrawer>
  );
}
