import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const PaymentIcon = createSvgIcon(
  'PaymentIcon',
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2L8.00003 6H11V10H13V6H16L12 2ZM4 22C2.89543 22 2 21.1045 2 20V9.99998C2 8.89541 2.89543 7.99998 4 7.99998L9 7.99998V9.99998L6.00003 9.99998C6.00002 11.1045 5.10459 12 4.00003 12V9.99998H4L4 20L20 20L18 20C18 18.8954 18.8954 18 20 18L20 12C18.8955 11.9999 18 11.1045 18 9.99998L15 9.99998V7.99998H20C21.1046 7.99998 22 8.89541 22 9.99998V20C22 21.1045 21.1046 22 20 22H4ZM4.00003 20L4.00003 18C5.1046 18 6.00003 18.8954 6.00003 20H4.00003ZM12 12C10.3432 12 9.00003 13.3431 9.00003 15C9.00003 16.6569 10.3432 18 12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12Z"
      fill={Color.Silver500}
    />
  </svg>,
);
