import { IconButton, Link, Typography } from '@material-ui/core';
import {
  Add,
  Edit,
  Mail,
  Payment,
  Person,
  Phone,
  Star,
} from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import {
  formatPaymentMethod,
  formatPaymentTerm,
  PaymentMethod,
} from '@superdispatch/sdk';
import { DescriptionList, DescriptionListItem, Stack } from '@superdispatch/ui';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { EmptySection, SectionLayout } from 'shipper-profile/SectionLayout';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { isEmpty } from '../data/ShipperProfileUtils';
import { PaymentInfoEditDrawer } from './PaymentInfoEditDrawer';

const getPaymentMethodsContent = (values: PaymentMethod[]) => {
  return values.map((value) => formatPaymentMethod(value)).join(', ');
};
export const isEmptyPaymentInfo = (profile: ShipperProfileDTO) => {
  return isEmpty(profile, [
    'alternative_payment_methods',
    'billing_contact_name',
    'billing_email',
    'billing_phone',
    'preferred_payment_methods',
    'default_payment_terms',
  ]);
};
interface PaymenInfoProps {
  profile: ShipperProfileDTO;
}
export function PaymentInfoSection({ profile }: PaymenInfoProps) {
  const {
    alternative_payment_methods,
    billing_contact_name,
    billing_email,
    billing_phone,
    preferred_payment_methods,
    default_payment_terms,
    default_payment_method,
  } = profile;
  const preferredPaymentMethods = useMemo(
    () =>
      preferred_payment_methods &&
      getPaymentMethodsContent(preferred_payment_methods),
    [preferred_payment_methods],
  );
  const alternativePaymentMethods = useMemo(
    () =>
      alternative_payment_methods &&
      getPaymentMethodsContent(alternative_payment_methods),
    [alternative_payment_methods],
  );
  const defaultPaymentTerms = useMemo(
    () => default_payment_terms && formatPaymentTerm(default_payment_terms),
    [default_payment_terms],
  );
  const defaultPaymentMethod = useMemo(
    () => default_payment_method && formatPaymentMethod(default_payment_method),
    [default_payment_method],
  );
  const billingInfoExists =
    billing_contact_name || billing_email || billing_phone;
  const paymentMethodsExist =
    alternativePaymentMethods || preferredPaymentMethods;
  const navigate = useNavigate();
  const { tryBack } = useTryBack();
  const drawerMatch = useMatch('/profile/payment-info/edit');
  const openDrawer = () => navigate('/profile/payment-info/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  return (
    <>
      <PaymentInfoEditDrawer
        profile={profile}
        open={!!drawerMatch}
        onClose={closeDrawer}
        onUpdate={() => {
          closeDrawer();
        }}
      />

      {isEmptyPaymentInfo(profile) ? (
        <EmptySection onClick={openDrawer} startIcon={<Add />}>
          Add Payment Info
        </EmptySection>
      ) : (
        <SectionLayout
          title="Payment Info"
          editComponent={
            <IconButton
              aria-label="edit payment info"
              size="small"
              onClick={openDrawer}
            >
              <Edit />
            </IconButton>
          }
        >
          <Stack space="small">
            {billingInfoExists && (
              <Stack space="xsmall">
                <Typography color="textSecondary" variant="h6">
                  Billing
                </Typography>

                <DescriptionList size="small">
                  {billing_contact_name && (
                    <DescriptionListItem
                      icon={<Person />}
                      content={billing_contact_name}
                    />
                  )}
                  {billing_email && (
                    <DescriptionListItem
                      icon={<Mail />}
                      content={
                        <Link href={`mailto:${billing_email}`}>
                          {billing_email}
                        </Link>
                      }
                    />
                  )}
                  {billing_phone && (
                    <DescriptionListItem
                      icon={<Phone />}
                      content={
                        <SuspendedPhoneLink
                          phone={billing_phone}
                          fallback={billing_phone}
                        />
                      }
                    />
                  )}
                </DescriptionList>
              </Stack>
            )}

            {(defaultPaymentTerms || defaultPaymentMethod) && (
              <Stack space="xsmall">
                <Stack space="none">
                  <Typography color="textSecondary" variant="h6">
                    Payment Defaults
                  </Typography>
                  <Typography color="textSecondary">
                    Set by default in new orders
                  </Typography>
                </Stack>

                <DescriptionList size="small">
                  {defaultPaymentMethod && (
                    <DescriptionListItem
                      icon={<Star />}
                      label="Method"
                      content={defaultPaymentMethod}
                    />
                  )}
                </DescriptionList>

                <DescriptionList size="small">
                  {defaultPaymentTerms && (
                    <DescriptionListItem
                      icon={<Star />}
                      label="Terms"
                      content={defaultPaymentTerms}
                    />
                  )}
                </DescriptionList>
              </Stack>
            )}
            {paymentMethodsExist && (
              <Stack space="xsmall">
                <Typography color="textSecondary" variant="h6">
                  Payment methods
                </Typography>

                <DescriptionList size="small">
                  {preferredPaymentMethods && (
                    <DescriptionListItem
                      icon={<Star />}
                      label="Preferred"
                      content={preferredPaymentMethods}
                    />
                  )}
                  {alternativePaymentMethods && (
                    <DescriptionListItem
                      icon={<Payment />}
                      label="Alternatives"
                      content={alternativePaymentMethods}
                    />
                  )}
                </DescriptionList>
              </Stack>
            )}
          </Stack>
        </SectionLayout>
      )}
    </>
  );
}
