import { Dialog, DialogTitle, Typography } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { TrialUpgradeDialogContent } from './TrialUpgradeDialogContent';

export function TrialUpgradeDialog({
  open,
  onClose,
  onSubmitSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}) {
  const platform = useResponsiveValue('mobile', 'other');

  return (
    <Dialog open={open} onClose={onClose} fullScreen={platform === 'mobile'}>
      <DialogTitle>
        <Box paddingTop="small">
          <Typography variant="h1" align="center">
            Changes to Your
            <br />
            Subscription
          </Typography>
        </Box>
      </DialogTitle>
      <TrialUpgradeDialogContent
        onClose={onClose}
        onSubmitSuccess={onSubmitSuccess}
      />
    </Dialog>
  );
}
