import {
  IconButton,
  SvgIconProps,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { ResponsiveTooltip } from './ResponsiveTooltip';

const Root = styled.div`
  display: flex;
  align-items: center;
`;

interface InfoTooltipProps extends Omit<TooltipProps, 'children'> {
  title: NonNullable<ReactNode>;
  children?: ReactNode;
  IconProps?: SvgIconProps;
  TextProps?: TypographyProps;
}

export function InfoTooltip({
  title,
  children,
  IconProps,
  TextProps,
  ...props
}: InfoTooltipProps) {
  const tooltip = (
    <ResponsiveTooltip
      title={title}
      placement="top"
      disableFocusListener={true}
      disableTouchListener={true}
      {...props}
    >
      <IconButton size="small">
        <Info color="action" fontSize="small" {...IconProps} />
      </IconButton>
    </ResponsiveTooltip>
  );

  if (!children) return tooltip;

  return (
    <Root>
      <Typography {...TextProps}>{children}</Typography>
      {tooltip}
    </Root>
  );
}
