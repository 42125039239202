import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class ReportFieldDTO {
  @Expose({ name: 'column_name' })
  name: string;

  @Expose({ name: 'column_header' })
  label: string;
}

@Exclude()
export class ReportFieldGroupDTO {
  @Expose() title: string;

  @Expose()
  @Type(() => ReportFieldDTO)
  fields: ReportFieldDTO[];
}
