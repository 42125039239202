import {
  PaymentMethod,
  PAYMENT_METHODS,
  PAYMENT_TERMS,
  SUPERPAY_TERMS,
} from '@superdispatch/sdk';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { CarrierPaymentDTO } from 'shared/dto/Order/CarrierPaymentDTO';
import Order, { OrderPayment } from 'shared/types/order';
import { OrderFormValues } from '../orders/core/form/OrderForm';
import { useShipperProfile } from '../shipper-profile/data/ShipperProfileAPI';

export function isSuperpayPayment(payment?: CarrierPaymentDTO | OrderPayment) {
  return payment?.method === 'superpay';
}

export function isSuperPayStatusAvailable(
  values: OrderFormValues | Order | undefined,
) {
  if (!values || !values.payment || !values.guid) {
    return false;
  }

  const { payment } = values;
  return payment.method === 'superpay' && !!payment.super_pay?.status;
}

export function usePaymentOptions(
  method?: PaymentMethod | null,
  disableSuperPay?: boolean,
) {
  const { user } = useUserState();

  return useMemo(() => {
    const excludeSuperPayFromTerms = (value: string) => value !== 'superpay';
    const excludeSuperPayFromMethods = (value: string) =>
      disableSuperPay && value === 'superpay'
        ? !disableSuperPay
        : user?.shipper.is_super_pay_enabled || value !== 'superpay';

    return {
      paymentTerms:
        method === 'superpay'
          ? SUPERPAY_TERMS
          : PAYMENT_TERMS.filter(excludeSuperPayFromTerms),
      paymentMethods: PAYMENT_METHODS.filter(excludeSuperPayFromMethods),
    };
  }, [method, user?.shipper.is_super_pay_enabled, disableSuperPay]);
}

export function usePaymentDefault() {
  const { data: shipper } = useShipperProfile({ refetchOnWindowFocus: false });

  const paymentDefaultMethod = shipper?.default_payment_method || 'other';

  const paymentDefaultTerm = useMemo(() => {
    if (
      paymentDefaultMethod === 'superpay' &&
      !shipper?.default_payment_terms
    ) {
      return '1_3_days';
    }

    return shipper?.default_payment_terms || 'other';
  }, [paymentDefaultMethod, shipper?.default_payment_terms]);

  return { paymentDefaultMethod, paymentDefaultTerm };
}
