import type {
  AccountType,
  Address,
  BusinessProfile,
  BusinessType,
  FedSearchResult,
  IndividualProfile,
  Moov,
  NewAccount,
  NewRepresentative,
  Representative,
} from '@moovio/moov-js';
import { useMemo } from 'react';
import {
  useSuperPayTermsConditionsToken,
  useSyncSuperPayOnboardingStep,
  useUpdateSuperPayTermsConditionsAccept,
} from '../../../superpay/data/SuperPayAPI';
import { logPaymentError } from '../../../superpay/helpers/PaymentLogger';
import { useMoovServiceContext } from '../../../superpay/MoovServiceContext';
import { MoovAccountInfo } from './MoovAccountInfo';
import { MoovAPI } from './MoovAPI';
import { JobTitle, MoovBusinessType, MoovScope } from './MoovDTO';

export interface NewShipperMoovAccount {
  businessType: MoovBusinessType;
  legalBusinessName: string;
  description: string;
  shipperGuid: string;
  einNumber: string;
  mccNumber: string;
  phoneNumber: string;
  phoneCountryCode: string;
  website?: string;
  country: string;
  streetAddress: string;
  zip: string;
  city: string;
  state: string;
  termsConditionsAccept?: boolean;
}

interface NewBankAccount {
  holderName: string;
  accountNumber: string;
  routingNumber: string;
}

//Custom type was created because
//Response type of moov.accounts.representatives.list() is Representative[]
//But in actuality it is different
export interface SuperPayRepresentative {
  representativeID: string;
  name: {
    firstName: string;
    lastName: string;
  };
  email: string;
  phone: {
    number: string;
    countryCode: string;
  };
  address: {
    addressLine1: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
  };
  birthDateProvided: boolean;
  governmentIDProvided: boolean;
  responsibilities: {
    isController: boolean;
    isOwner: boolean;
    ownershipPercentage: number;
    jobTitle: JobTitle;
  };
  createdOn: string;
  updatedOn: string;
  disabledOn?: string;
}

//Business Type from Moov SDK was different from actual business types
type SuperPayBusinessType =
  | 'llc'
  | 'privateCorporation'
  | 'soleProprietorship'
  | 'partnership'
  | 'publicCorporation';

interface SuperPayBusinessProfile {
  legalBusinessName: string;
  doingBusinessAs?: string;
  businessType: SuperPayBusinessType;
  address?: Address;
  phone?: {
    number: string;
    countryCode: string;
  };
  email?: string;
  website?: string;
  description?: string;
  taxID?: {
    ein: {
      number: string;
    };
  };
  ownersProvided?: boolean;
  industryDetails?: {
    naics?: string;
    sic?: string;
    mcc?: string;
  };
}
export interface SuperPayMoovAccount {
  accountID: string;
  foreignID?: string;
  accountType: AccountType;
  displayName: string;
  profile: {
    individual?: IndividualProfile;
    business?: SuperPayBusinessProfile;
  };
  metadata?: Record<string, unknown>;
  termsOfService?: {
    acceptedIP: string;
    acceptedDate: string;
  };
}

interface MoovRequestError {
  statusCode?: number;
  error?: unknown;
}

interface SearchRoutingNumberResponse extends FedSearchResult {
  // actual result contains more fields
  result?: Array<{
    routingNumber: string;
    newRoutingNumber: string;
    customerName: string;
    cleanName: string;
  }>;
}

function isMoovRequestError(error: unknown): error is MoovRequestError {
  return (
    error instanceof Object &&
    !!(
      (error as MoovRequestError).statusCode ||
      (error as MoovRequestError).error
    )
  );
}

export class MoovService {
  private moov: Moov | null = null;
  private moovAccountInfo: MoovAccountInfo;

  constructor(moovAccountInfo: MoovAccountInfo) {
    this.moovAccountInfo = moovAccountInfo;
  }

  private logError(error: Error, source: string) {
    logPaymentError(error, source, {
      moov_account_id: this.moovAccountInfo.getAccount()?.accountID,
    });
  }

  private async getMoov() {
    // Load Moov.js if not loaded yet
    if (this.moov == null) {
      const { loadMoov } = await import('@moovio/moov-js');
      this.moov = await loadMoov(this.moovAccountInfo.getCurrentToken());
    }

    // Throw error if `loadMoov` returns null
    if (this.moov == null) {
      this.logError(new Error('Failed to load Moov.js'), 'MoovService.getMoov');
      throw new Error('Failed to load Moov.js');
    }

    return this.moov;
  }

  async setScope(scope: MoovScope) {
    const scopeToken = await this.moovAccountInfo.setScope(scope);
    const moov = await this.getMoov();
    this.moov = moov.setToken(scopeToken);
  }

  private async tryAction<T>(action: (moov: Moov) => Promise<T>) {
    try {
      const moov = await this.getMoov();

      return await action(moov);
    } catch (reason: unknown) {
      if (!isMoovRequestError(reason)) throw reason;

      const { statusCode, error } = reason;
      const message = typeof error === 'string' ? error : undefined;
      const errorCode = statusCode ? `Error code: ${statusCode}` : undefined;

      throw new Error(
        message && errorCode
          ? `${message}. ${errorCode}`
          : message || errorCode,
      );
    }
  }

  async getMoovAccount(): Promise<SuperPayMoovAccount> {
    await this.setScope('account');

    return this.tryAction(async (moov) => {
      const moovAccount = await moov.accounts.get({
        accountID: this.moovAccountInfo.getAccount()?.accountID as string,
      });
      return moovAccount as SuperPayMoovAccount;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.getMoovAccount');
      throw error;
    });
  }

  async getTOSToken(): Promise<string> {
    return this.tryAction(async () => {
      const response = await fetch('https://api.moov.io/tos-token');
      const { token } = (await response.json()) as { token: string };
      return token;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.getTOSToken');
      throw error;
    });
  }

  async createMoovAccount(data: NewShipperMoovAccount) {
    await this.setScope('account');

    if (this.moovAccountInfo.getAccount()?.accountID) {
      throw new Error('Moov account already exists');
    }

    return this.tryAction(async (moov) => {
      const termsOfServiceToken = await this.getTOSToken();
      const account = await moov.accounts.create({
        accountType: 'business',
        termsOfService: { token: termsOfServiceToken },
        profile: {
          business: {
            businessType: data.businessType as BusinessType,
            description: data.description,
            legalBusinessName: data.legalBusinessName,
            taxID: { ein: { number: data.einNumber } },
            industryDetails: {
              mcc: data.mccNumber,
            },
            address: {
              country: data.country,
              addressLine1: data.streetAddress,
              city: data.city,
              postalCode: data.zip,
              stateOrProvince: data.state,
            },
            phone: {
              countryCode: data.phoneCountryCode,
              number: data.phoneNumber,
            },
            website: data.website,
          },
        },
        metadata: {
          guid: data.shipperGuid,
          type: 'shipper',
        },
        foreignID: data.shipperGuid,
        capabilities: ['transfers', 'send-funds'],
      } as NewAccount);

      this.moovAccountInfo.setAccount(account);

      return account;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.createMoovAccount');
      throw error;
    });
  }

  async linkBankAccount({
    accountNumber,
    holderName,
    routingNumber,
  }: NewBankAccount) {
    await this.setScope('bank_account');

    return this.tryAction(async (moov) => {
      const bankAccount = await moov.accounts.bankAccounts.link({
        accountID: this.moovAccountInfo.getAccount()?.accountID as string,
        bankAccount: {
          routingNumber,
          accountNumber,
          holderName,
          holderType: 'business',
          bankAccountType: 'checking',
        },
      });

      this.moovAccountInfo.setBankAccount(bankAccount);

      return bankAccount;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.linkBankAccount');
      throw error;
    });
  }

  async completeMicroDepositVerification(amounts: number[]) {
    await this.setScope('micro_deposit');

    return this.tryAction(async (moov) => {
      const result =
        await moov.accounts.bankAccounts.completeMicroDepositVerification({
          accountID: this.moovAccountInfo.getAccount()?.accountID as string,
          bankAccountID: this.moovAccountInfo.getBankAccount()
            ?.bankAccountID as string,
          amounts,
        });

      this.moovAccountInfo.setBankAccount({
        ...this.moovAccountInfo.getBankAccount(),
        status: 'validated',
      });

      return result as unknown;
    });
  }

  async getBankNameByRoutingNumber(routingNumber: string) {
    return this.tryAction(async (moov) => {
      const response: SearchRoutingNumberResponse =
        await moov.institutions.lookupByRoutingNumber(routingNumber);

      return response.result?.[0]?.customerName;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.getBankNameByRoutingNumber');
      throw error;
    });
  }

  async isAvailable() {
    try {
      await this.getMoov();

      return true;
    } catch (reason: unknown) {
      return false;
    }
  }

  async addRepresentative(data: NewRepresentative) {
    await this.setScope('representative');
    return this.tryAction(async (moov) => {
      const response = await moov.accounts.representatives.create({
        accountID: this.moovAccountInfo.getAccount()?.accountID as string,
        representative: data,
      });

      return response;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.addRepresentative');
      throw error;
    });
  }

  async getRepresentativesList() {
    await this.setScope('representative');
    return this.tryAction(async (moov) => {
      const response = await moov.accounts.representatives.list({
        accountID: this.moovAccountInfo.getAccount()?.accountID as string,
      });
      //Casting response type because response type is Representative[]
      //But in actuality it is different
      return response as unknown as SuperPayRepresentative[];
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.getRepresentativesList');
      throw error;
    });
  }

  async deleteRepresentative(representativeID: string) {
    await this.setScope('representative');
    return this.tryAction(async (moov) => {
      const response = await moov.accounts.representatives.delete({
        accountID: this.moovAccountInfo.getAccount()?.accountID as string,
        representativeID,
      });
      return response;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.deleteRepresentative');
      throw error;
    });
  }

  async editRepresentative(
    representativeID: string,
    data: Partial<Representative>,
  ) {
    return this.tryAction(async () => {
      const scopeToken = await this.moovAccountInfo.setScope('representative');
      const accountID = this.moovAccountInfo.getAccount()?.accountID as string;

      return new MoovAPI().editRepresentative(
        scopeToken,
        accountID,
        representativeID,
        data,
      );
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.editRepresentative');
      throw error;
    });
  }

  async updateOwnersProvidedInfo() {
    await this.setScope('account');

    return this.tryAction(async (moov) => {
      const account = await moov.accounts.patch({
        accountID: this.moovAccountInfo.getAccount()?.accountID as string,
        profile: {
          business: {
            ownersProvided: true,
          } as BusinessProfile,
        },
      } as Partial<NewAccount>);
      return account;
    }).catch((error: Error) => {
      this.logError(error, 'MoovService.updateOwnersProvidedInfo');
      throw error;
    });
  }
}

export function useMoovSyncAPI() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();
  const { moovService, moovAccountInfo } = useMoovServiceContext();
  const errorMessage = 'Please try again or reload the page.';

  // We can skip errors, because there will be synchronization between moov and our backend,
  // creating account in moov, this is the most important for us.
  return useMemo(
    () => ({
      createSyncMoovAccount: (moovAccount: NewShipperMoovAccount) => {
        if (!moovService) {
          throw new Error(errorMessage);
        }

        return moovService.createMoovAccount(moovAccount).then((account) => {
          return syncSuperPay({
            scope: 'account',
            moov_bank_account_id: '',
            moov_account_id: account.accountID,
          }).finally(() => account);
        });
      },

      linkSyncMoovBankAccount: (newBankAccountData: NewBankAccount) => {
        if (!moovService || !moovAccountInfo) {
          throw new Error(errorMessage);
        }

        return moovService
          .linkBankAccount(newBankAccountData)
          .then((bankAccount) => {
            const account = moovAccountInfo.getAccount();
            return syncSuperPay({
              scope: 'bank_account',
              moov_bank_account_id: bankAccount.bankAccountID,
              moov_account_id: account?.accountID || '',
            }).finally(() => bankAccount);
          });
      },

      addSyncRepresentative: (data: NewRepresentative) => {
        if (!moovService || !moovAccountInfo) {
          throw new Error(errorMessage);
        }

        return moovService.addRepresentative(data).then((representative) => {
          const account = moovAccountInfo.getAccount();
          const bankAccount = moovAccountInfo.getBankAccount();
          return syncSuperPay({
            scope: 'representative',
            moov_bank_account_id: bankAccount?.bankAccountID || '',
            moov_account_id: account?.accountID || '',
          }).finally(() => representative);
        });
      },
    }),
    [moovAccountInfo, moovService, syncSuperPay],
  );
}

export function useMoovAcceptAPI() {
  const { mutateAsync: termsConditionsToken } =
    useSuperPayTermsConditionsToken();
  const { mutateAsync: termsConditionsAccept } =
    useUpdateSuperPayTermsConditionsAccept();
  const { createSyncMoovAccount } = useMoovSyncAPI();

  return useMemo(
    () => ({
      createAcceptMoovAccount: async (moovAccount: NewShipperMoovAccount) => {
        const termsConditionsUserToken = await termsConditionsToken();
        if (!termsConditionsUserToken.acceptance_token) {
          throw new Error('Please try again or reload the page.');
        }

        await termsConditionsAccept(termsConditionsUserToken.acceptance_token);
        return createSyncMoovAccount(moovAccount);
      },
    }),
    [createSyncMoovAccount, termsConditionsAccept, termsConditionsToken],
  );
}
