import { Grid, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useUserState } from 'shared/data/AppUserState';
import { CounterpartyContactDTO } from 'shared/dto/CounterpartyContactDTO';
import { Load } from 'shared/types/load';
import { required } from 'shared/utils/ValidatorUtils';
import {
  CounterpartyContactAutocomplete,
  CounterpartyContactChangeReason,
} from './CounterpartyContactAutocomplete';

function validateContact(value: CounterpartyContactDTO) {
  const error = required(value.name);
  if (error) return { name: error };
  return undefined;
}

interface LoadCounterpartyContactFieldsProps {
  name: string;
  stepName?: string;
  isNew: boolean;
  showContinue?: boolean;
  contacts: CounterpartyContactDTO[] | undefined;
  values?: Load;
  onChange?: (
    value: Partial<CounterpartyContactDTO>,
    reason: CounterpartyContactChangeReason,
  ) => void;
}

export function LoadCounterpartyContactFields({
  name,
  stepName,
  isNew,
  onChange,
  showContinue,
  contacts = [],
  values,
}: LoadCounterpartyContactFieldsProps) {
  const { user } = useUserState();

  const styleLabelText = (text: string) => (
    <Typography color="textPrimary">{text}</Typography>
  );

  const isContactTitleVisible = stepName
    ? user?.order_form_settings[stepName].venue.is_contact_title_visible ||
      values?.[stepName].venue?.contact_title
    : user?.order_form_settings.customer.is_contact_title_visible;

  const isContactMobileVisible = stepName
    ? user?.order_form_settings[stepName].venue
        .is_contact_mobile_phone_visible ||
      values?.[stepName].venue?.contact_mobile_phone
    : user?.order_form_settings.customer.is_contact_mobile_visible;

  const isContactEmailVisible = stepName
    ? user?.order_form_settings[stepName].venue.is_contact_email_visible ||
      values?.[stepName].venue?.contact_email
    : user?.order_form_settings.customer.is_contact_email_visible;

  return (
    <Stack space="small">
      <Grid container={true} spacing={2} wrap="wrap">
        <Grid item={true} xs={12} md={8}>
          <CounterpartyContactAutocomplete
            options={contacts}
            isNew={isNew}
            onChange={onChange}
            name={`${name}.contact`}
            showContinue={showContinue}
            validate={isNew ? validateContact : undefined}
          />
        </Grid>

        {isContactTitleVisible && (
          <Grid item={true} xs={12} md={4}>
            <FormikTextField
              label={styleLabelText('Title')}
              fullWidth={true}
              inputProps={{
                maxLength: 100,
              }}
              name={`${name}.contact.title`}
            />
          </Grid>
        )}
      </Grid>

      <Grid container={true} spacing={2} wrap="wrap">
        <Grid item={true} xs={12} md={4}>
          <FormikTextField
            label={styleLabelText('Phone')}
            fullWidth={true}
            type="phone"
            inputProps={{
              maxLength: 150,
            }}
            name={`${name}.contact.phone`}
          />
        </Grid>

        {isContactMobileVisible && (
          <Grid item={true} xs={12} md={4}>
            <FormikTextField
              label={styleLabelText('Mobile')}
              type="phone"
              fullWidth={true}
              inputProps={{
                maxLength: 150,
              }}
              name={`${name}.contact.mobile_phone`}
            />
          </Grid>
        )}

        {isContactEmailVisible && (
          <Grid item={true} xs={12} md={4}>
            <FormikTextField
              type="email"
              fullWidth={true}
              label={styleLabelText('Email')}
              inputProps={{
                maxLength: 150,
              }}
              name={`${name}.contact.email`}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
