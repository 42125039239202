import { Typography } from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Button,
  DrawerActions,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useMemo } from 'react';
import { DrawerForm } from 'shared/form/DrawerForm';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { Referral } from '../data/ShipperProfileDTO';

interface ReferralEditDrawerProps {
  open: boolean;
  referrals: Referral[];
  editReferralIndex?: number;
  onClose: () => void;
  onUpdate?: () => void;
}

// Max number of references is 3. Given index should be in this boundary
export const isValidIndex = (index?: number): index is number =>
  typeof index === 'number' && index > -1 && index < 3;

export function ReferralEditDrawer({
  open,
  editReferralIndex,
  referrals,
  onClose,
  onUpdate,
}: ReferralEditDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateReferrals } = useShipperProfileAPI();

  const editingReferral = useMemo(() => {
    if (isValidIndex(editReferralIndex)) {
      const editReferral = referrals[editReferralIndex];

      return {
        contact_name: editReferral?.contact_name ?? '',
        name: editReferral?.name ?? '',
        phone: editReferral?.phone ?? '',
      };
    }

    return undefined;
  }, [editReferralIndex, referrals]);

  const currentReferral = editingReferral || {
    contact_name: '',
    name: '',
    phone: '',
  };

  const formik = useFormikEnhanced({
    key: open,
    initialValues: currentReferral,
    onSubmit: (values) => {
      if (editingReferral) {
        const updatedReferrals = referrals.slice();
        updatedReferrals.splice(editReferralIndex as number, 1, values);

        return updateReferrals(updatedReferrals);
      } else if (referrals.length < 3) {
        return updateReferrals([...referrals, values]);
      }

      return Promise.reject();
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('References', updatedValues);
      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to add reference', { variant: 'error' });
    },
  });

  const { dirty, isSubmitting, isValid, status, handleSubmit } = formik;

  if (typeof editReferralIndex === 'number' && !editingReferral) {
    return (
      <DrawerForm
        title="Edit Reference"
        open={open}
        onClose={onClose}
        isDirty={false}
        isSubmitting={false}
        disableCloseOnNavigation={true}
      >
        <Typography color="textSecondary" align="center">
          Reference not found
        </Typography>
      </DrawerForm>
    );
  }

  return (
    <DrawerForm
      title={editingReferral ? 'Edit Reference' : 'New Reference'}
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
      actions={
        <DrawerActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <Stack>
          <FormikTextField name="name" label="Company Name" fullWidth={true} />

          <FormikTextField
            name="contact_name"
            label="Contact Name"
            fullWidth={true}
          />

          <SuspendedFormikPhoneField
            name="phone"
            label="Phone"
            fullWidth={true}
          />
        </Stack>
      </FormikProvider>
    </DrawerForm>
  );
}
