import { formatDate } from '@superdispatch/dates';
import { CarrierBrokerPreferences, CarrierStatus } from 'shared/types/carrier';

interface CarrierStatusLabelProps {
  value: unknown;
  brokerRecords: CarrierBrokerPreferences | null;
}

export const NetworkDrawerCarrierStatus = ({
  value,
  brokerRecords,
}: CarrierStatusLabelProps) => {
  const approved_since = brokerRecords?.approved_since;
  const in_blacklist_since = brokerRecords?.in_blacklist_since;

  switch (value as CarrierStatus) {
    case 'APPROVED':
      return (
        <>
          Approved
          {approved_since &&
            ` (on ${formatDate(approved_since, { variant: 'Date' })})`}
        </>
      );

    case 'IN_BLACKLIST':
      return (
        <>
          Blocklisted
          {in_blacklist_since &&
            ` (on ${formatDate(in_blacklist_since, { variant: 'Date' })})`}
        </>
      );

    default:
      return <>Default</>;
  }
};
