import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useGeneralSettingsAPI } from './data/GeneralSettingsAPI';

interface Props {
  SSOState: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function DisableSingleSignOnDialog({
  SSOState,
  open,
  onClose,
  onSubmit,
}: Props) {
  const { setSSOState } = useGeneralSettingsAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: (_) => setSSOState(SSOState),
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: () => {
      onSubmit();
      onClose();
      addSnackbar('Single Sign-On configuration changed', {
        variant: 'success',
      });
    },
  });
  return (
    <ConfirmDialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => (formik.isSubmitting ? undefined : onClose())}
      title={SSOState ? 'Enable Single Sign-On?' : 'Disable Single Sign-On?'}
      cancelButtonProps={{
        children: 'Cancel',
        disabled: formik.isSubmitting,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: SSOState ? 'Enable' : 'Disable',
        isLoading: formik.isSubmitting,
        onClick: () => formik.handleSubmit(),
        autoFocus: false,
      }}
    >
      {!SSOState && (
        <Typography>
          If you disable the SSO, its configuration will need to be started
          again. Are you sure you want to disable SSO?
        </Typography>
      )}
    </ConfirmDialog>
  );
}
