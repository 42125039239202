import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Add, ArrowBack } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import {
  Column,
  Columns,
  InfoTooltip,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import {
  CarrierAutocomplete,
  CarrierAutocompleteValue,
} from 'core/CarrierAutocomplete';
import { useState } from 'react';
import Protect from 'shared/data/UserPermissions';
import { useSourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';

interface ManageCarriersListHeaderProps {
  carrier: CarrierAutocompleteValue;
  onSelectCarrier: (value: CarrierAutocompleteValue) => void;
  onAddToNetwork: () => void;
}

export function ManageCarriersListHeader({
  carrier,
  onSelectCarrier,
  onAddToNetwork,
}: ManageCarriersListHeaderProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const [headerCollapsed, setHeaderCollapsed] = useState(true);

  useSourceManagerLegacy('Manage Carriers', 'Carrier Search');

  if (platform === 'mobile') {
    return headerCollapsed ? (
      <AppBar elevation={1}>
        <Toolbar>
          <Columns align="center">
            <Column width="fluid">
              <Typography variant="h3">Manage Carriers</Typography>
            </Column>
            <Column width="content">
              <IconButton onClick={() => setHeaderCollapsed(!headerCollapsed)}>
                <SearchIcon fontSize="small" />
              </IconButton>
            </Column>
            <Protect
              entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
              permission="canExecute"
            >
              <Button variant="primary" size="small" onClick={onAddToNetwork}>
                <Add fontSize="small" />
              </Button>
            </Protect>
          </Columns>
        </Toolbar>
      </AppBar>
    ) : (
      <AppBar elevation={1}>
        <Toolbar>
          <Box paddingTop="xsmall" paddingBottom="xsmall" width="100%">
            <Columns align="center">
              <Column width="content">
                <IconButton onClick={() => setHeaderCollapsed(true)}>
                  <ArrowBack fontSize="small" />
                </IconButton>
              </Column>
              <Column width="fluid">
                <CarrierAutocomplete
                  hideLabel={true}
                  withFMCSA={true}
                  value={carrier}
                  onChange={onSelectCarrier}
                />
              </Column>
            </Columns>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar elevation={1}>
      <Toolbar>
        <Box paddingTop="xsmall" paddingBottom="xsmall" width="100%">
          {platform === 'tablet' ? (
            <Stack space="xsmall">
              <Columns>
                <Column width="fluid">
                  <Typography variant="h3">Manage Carriers</Typography>
                </Column>
                <Column width="content">
                  <Protect
                    entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
                    permission="canExecute"
                  >
                    <Inline
                      horizontalAlign="right"
                      noWrap={true}
                      verticalAlign="center"
                    >
                      <Button onClick={onAddToNetwork}>Add to Network</Button>
                      <InfoTooltip
                        title={
                          <>
                            You can add the Carrier to your network for quick
                            access in the future.
                            <br />
                            Added Carriers will be sorted based on their status.
                          </>
                        }
                      />
                    </Inline>
                  </Protect>
                </Column>
              </Columns>
              <CarrierAutocomplete
                hideLabel={true}
                withFMCSA={true}
                value={carrier}
                onChange={onSelectCarrier}
                collapse={false}
              />
            </Stack>
          ) : (
            <Columns align="center">
              <Column width="1/3">
                <Typography variant="h3">Manage Carriers</Typography>
              </Column>
              <Column width="1/3">
                <CarrierAutocomplete
                  hideLabel={true}
                  withFMCSA={true}
                  value={carrier}
                  onChange={onSelectCarrier}
                  collapse={false}
                />
              </Column>
              <Column width="1/3">
                <Protect
                  entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
                  permission="canExecute"
                >
                  <Inline
                    horizontalAlign="right"
                    noWrap={true}
                    verticalAlign="center"
                  >
                    <Button onClick={onAddToNetwork}>Add to Network</Button>
                    <InfoTooltip
                      title={
                        <>
                          You can add the Carrier to your network for quick
                          access in the future.
                          <br />
                          Added Carriers will be sorted based on their status.
                        </>
                      }
                    />
                  </Inline>
                </Protect>
              </Column>
            </Columns>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
