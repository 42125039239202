import { trackEvent as originalTrackEvent } from 'shared/helpers/AnalyticsHelpers';

function trackEvent(
  name: string,
  data: Parameters<typeof originalTrackEvent>[1] = {},
  callback?: () => void,
) {
  originalTrackEvent(
    name,
    {
      utm_medium: 'Paywall Page',
      ...data,
    },
    callback,
  );
}

export function trackClickedContactSupportEmail() {
  trackEvent('Shipper Clicked Contact Support Email');
}

export function trackClickedSubscribeNow() {
  trackEvent('Shipper Clicked Subscribe Now');
}

export function trackClickedGoToOrders(callback?: () => void) {
  trackEvent('Shipper Clicked Go to Orders', {}, callback);
}

export function trackClickedLogout(callback?: () => void) {
  trackEvent('Shipper Clicked Log Out', {}, callback);
}
