import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { SubscriptionEditPaymentDetailsDrawer } from './EditPaymentDetailsDrawer';

export function EditPaymentDetailsButton() {
  const [isOpen, handleOpen, handleClose] = useBoolean(false);

  return (
    <>
      <IconButton size="small" onClick={handleOpen}>
        <Edit />
      </IconButton>

      <SubscriptionEditPaymentDetailsDrawer
        open={isOpen}
        onClose={handleClose}
      />
    </>
  );
}
