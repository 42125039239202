import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Add, Close, Edit, Info } from '@material-ui/icons';
import {
  CardButton,
  Color,
  Column,
  Columns,
  DrawerContent,
  DrawerTitle,
  InfoCard,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { ComponentType, useMemo } from 'react';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';
import { ShipperProfileDTO } from './data/ShipperProfileDTO';

const CardBackground = styled.div`
  background-color: ${Color.White};
`;

export interface EditComponentProps {
  profile: ShipperProfileDTO;
  onSave: () => void;
}

interface SectionProps {
  title: string;
  tooltip?: string;
  profile: ShipperProfileDTO;
  isEmpty: (profile: ShipperProfileDTO) => boolean;
  viewComponent: ComponentType<ShipperProfileDTO>;
  editComponent?: ComponentType<EditComponentProps>;
}

export const Section: ComponentType<SectionProps> = ({
  title,
  tooltip,
  profile,
  isEmpty,
  viewComponent: ViewComponent,
  editComponent: EditComponent,
}) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useBoolean(false);
  const isContentEmpty = useMemo(() => isEmpty(profile), [profile]);

  const handleSave = () => {
    closeDrawer();
  };

  return (
    <>
      {isContentEmpty ? (
        <CardBackground>
          <CardButton onClick={openDrawer} startIcon={<Add />}>
            Add {title}
          </CardButton>
        </CardBackground>
      ) : (
        <InfoCard size="large">
          <Stack space="small">
            <Columns>
              <Column>
                <Inline space="xsmall" verticalAlign="center">
                  <Typography variant="h3">{title}</Typography>
                  {tooltip && (
                    <ResponsiveTooltip title={tooltip}>
                      <IconButton size="small">
                        <Info color="action" fontSize="small" />
                      </IconButton>
                    </ResponsiveTooltip>
                  )}
                </Inline>
              </Column>

              {EditComponent && (
                <Column width="content">
                  <IconButton
                    aria-label={`Edit ${title}`}
                    size="small"
                    onClick={openDrawer}
                  >
                    <Edit />
                  </IconButton>
                </Column>
              )}
            </Columns>
            <ViewComponent {...profile} />
          </Stack>
        </InfoCard>
      )}

      {EditComponent && (
        <Drawer open={isDrawerOpen} onClose={closeDrawer}>
          <DrawerTitle
            title={title}
            endAction={
              <IconButton edge="end" onClick={closeDrawer}>
                <Close />
              </IconButton>
            }
          />
          <DrawerContent
            style={{
              width: '432px', // FIXME: Refactor every EditComponent to fit its content to this width
            }}
          >
            <EditComponent profile={profile} onSave={handleSave} />
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
