import { renderChildren } from '@superdispatch/ui';
import { ReactNode, useEffect, useState } from 'react';

interface ExitTransitionPlaceholderProps {
  in: boolean;
  children: ReactNode;
}

export function ExitTransitionPlaceholder({
  in: inProp,
  children: childrenProp,
}: ExitTransitionPlaceholderProps) {
  const [children, setChildren] = useState(childrenProp);

  useEffect(() => {
    if (inProp) {
      setChildren(childrenProp);
    }
  }, [inProp, childrenProp]);

  return renderChildren(children);
}
