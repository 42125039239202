import {
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { setSearchPeriod, useSearchPeriod } from './OrderListSearchPeriod';

interface OrderListSearchPeriodFieldProps {
  fullWidth?: boolean;
}

export function OrderListSearchPeriodField({
  fullWidth,
}: OrderListSearchPeriodFieldProps) {
  const searchPeriod = useSearchPeriod();

  return (
    <TextField
      select={true}
      variant="outlined"
      fullWidth={true}
      value={searchPeriod}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography color="textSecondary">Created</Typography>
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        setSearchPeriod(event.target.value);
      }}
      style={{ width: fullWidth ? '100%' : 197 }}
    >
      <MenuItem value="P1M">1 month ago</MenuItem>
      <MenuItem value="P3M">3 months ago</MenuItem>
      <MenuItem value="P6M">6 months ago</MenuItem>
      <MenuItem value="P1Y">1 year ago</MenuItem>
      <MenuItem value="all">All time</MenuItem>
    </TextField>
  );
}
