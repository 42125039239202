import type { Account, BankAccount } from '@moovio/moov-js';
import { tryParseJSON, tryStringifyJSON } from 'shared/utils/DataUtils';
import { logPaymentError } from '../../../superpay/helpers/PaymentLogger';
import {
  readStorageItem,
  removeStorageItem,
  writeStorageItem,
} from '../LocalStorage';
import { MoovScope, MoovTokenResponse } from './MoovDTO';

const MOOV_ACCOUNT_KEY = 'MOOV_ACCOUNT_KEY';
const MOOV_BANK_ACCOUNT_KEY = 'MOOV_BANK_ACCOUNT_KEY';
const TEN_MINUTES = 10 * 60 * 1000;

export type TokenDataFetcher = (scope: MoovScope) => Promise<MoovTokenResponse>;
type Subscriber = () => void;

export class MoovAccountInfo {
  private currentScope: MoovScope;
  private currentToken: string;
  private account: Partial<Account> | null = null;
  private bankAccount: Partial<BankAccount> | null = null;
  private tokenUpdatedAt: number;
  private subscribers = new Set<Subscriber>();
  private getMoovToken: TokenDataFetcher;

  constructor(
    initialScope: MoovScope,
    initialData: MoovTokenResponse,
    getMoovToken: TokenDataFetcher,
  ) {
    this.getMoovToken = getMoovToken;
    this.setTokenData(initialScope, initialData);
  }

  private setTokenData(scope: MoovScope, data: MoovTokenResponse) {
    this.currentToken = data.access_token;
    this.currentScope = scope;
    this.tokenUpdatedAt = Date.now();

    if (data.moov_account_id) {
      this.setAccount({ accountID: data.moov_account_id });
      removeStorageItem(MOOV_ACCOUNT_KEY); // remove localStorage data when it is available in backend
    }

    if (data.moov_bank_account_id) {
      this.setBankAccount({ bankAccountID: data.moov_bank_account_id });
      removeStorageItem(MOOV_BANK_ACCOUNT_KEY); // remove localStorage data when it is available in backend
    }
  }

  getCurrentToken() {
    return this.currentToken;
  }

  /**
   * Each scope consists of particular permissions.
   * That's why when the scope is updated, a new token
   * with the required permisisons is requested.
   */
  async setScope(scope: MoovScope) {
    const isTokenExpired = Date.now() - this.tokenUpdatedAt > TEN_MINUTES;

    if (this.currentScope === scope && !isTokenExpired) {
      return this.currentToken;
    }

    const data = await this.getMoovToken(scope);
    this.setTokenData(scope, data);

    return this.currentToken;
  }

  setAccount(newData: Partial<Account>) {
    this.account = newData;
    writeStorageItem(MOOV_ACCOUNT_KEY, tryStringifyJSON(newData) as string);
    this.emitAccountDataChange();
  }

  setBankAccount(newData: Partial<BankAccount>) {
    this.bankAccount = newData;
    writeStorageItem(
      MOOV_BANK_ACCOUNT_KEY,
      tryStringifyJSON(newData) as string,
    );
    this.emitAccountDataChange();
  }

  getAccount() {
    return (
      this.account || tryParseJSON(readStorageItem(MOOV_ACCOUNT_KEY) as string) // `null` value is parsed as `null`
    );
  }

  getBankAccount() {
    return (
      this.bankAccount ||
      tryParseJSON(readStorageItem(MOOV_BANK_ACCOUNT_KEY) as string) // `null` value is parsed as `null`
    );
  }

  static flushStorageData() {
    removeStorageItem(MOOV_ACCOUNT_KEY);
    removeStorageItem(MOOV_BANK_ACCOUNT_KEY);
  }

  /**
   * Subscriber function is not provided with updated account data.
   * Account data should be retrieved by `getAccount` and `getBankAccount`. */
  subscribeToAccountDataChange(subscriber: Subscriber) {
    this.subscribers.add(subscriber);

    try {
      subscriber();
    } catch (error: unknown) {
      logPaymentError(error, 'MoovServiceSubscribe', {
        moovAccountId: this.account?.accountID,
      });
    }

    return () => {
      this.subscribers.delete(subscriber);
    };
  }

  private emitAccountDataChange() {
    for (const subscriber of this.subscribers) {
      try {
        subscriber();
      } catch (error: unknown) {
        logPaymentError(error, 'MoovServiceEmitChange', {
          moovAccountId: this.account?.accountID,
        });
      }
    }
  }
}
