import { useUserState } from './AppUserState';

export function useIsBasicTier(): boolean {
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const shipperSubscription = user?.shipper.subscription_plan;

  return shipperType === 'BROKER' && shipperSubscription === 'BASIC';
}

export function useProductTiers() {
  const isBasicTier = useIsBasicTier();

  return { isBasicTier, isAdvancedTier: !isBasicTier };
}
