import { Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, DescriptionLineItem } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { getBillingAddressText } from 'subscription-details/PaymentDetailsHelpers';
import { usePaymentDetails } from 'subscription-details/usePaymentDetails';
import { useSelectedSubscriptionPlan } from 'subscription-plans/useSelectedSubscriptionPlan';
import { useReactivateSubscriptionMutation } from './data/PaywallAPI';

export function TrialUpgradeDialogContent({
  onClose,
  onSubmitSuccess,
}: {
  onClose: () => void;
  onSubmitSuccess: () => void;
}) {
  return (
    <Box padding="large" maxWidth="492px">
      <Stack space="large" align="center">
        <Stack space="small">
          <PlanDetails />
          <PaymentDetails />
          <PaymentSummary />
        </Stack>
        <TrialUpgradeDialogActions
          onClose={onClose}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Stack>
    </Box>
  );
}

function TrialUpgradeDialogActions({
  onClose,
  onSubmitSuccess,
}: {
  onClose: () => void;
  onSubmitSuccess: () => void;
}) {
  const { addSnackbar } = useSnackbarStack();

  const { mutate: reactivateMutation, isLoading } =
    useReactivateSubscriptionMutation({
      onSuccess() {
        onClose();
        onSubmitSuccess();
      },
      onError(error) {
        addSnackbar(error.message, { variant: 'error' });
      },
    });

  function handleSubmit() {
    reactivateMutation({ plan_code: 'express-monthly' });
  }

  return (
    <Inline space="small">
      <Button variant="neutral" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
        Confirm Changes
      </Button>
    </Inline>
  );
}

function PlanDetails() {
  const { subscriptionPlan, isSubscriptionPlansLoading } =
    useSelectedSubscriptionPlan();

  if (isSubscriptionPlansLoading) {
    return <LoadingState />;
  }

  if (!subscriptionPlan) {
    return null;
  }

  return (
    <Stack space="small">
      <Columns align="center" space="small">
        <Column width="fluid">
          <Stack space="xxsmall">
            <Typography variant="h6" color="textSecondary">
              PLAN
            </Typography>
            <Typography variant="body1">Shipper Express</Typography>
          </Stack>
        </Column>
        <Column width="content">
          <Stack space="none">
            <Inline verticalAlign="bottom" space="none">
              <Typography variant="h1">
                {formatCurrency(subscriptionPlan.monthly_price, {
                  maximumFractionDigits: 0,
                })}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                /month
              </Typography>
            </Inline>
            <Typography color="textSecondary">Billed monthly</Typography>
          </Stack>
        </Column>
      </Columns>
      <Divider />
      <Columns align="center" space="small">
        <Column width="fluid">
          <Stack space="xxsmall">
            <Typography variant="h6" color="textSecondary">
              Package
            </Typography>
            <Typography variant="body1">0-50</Typography>
          </Stack>
        </Column>
        <Column width="content">
          <ul>
            <li>
              Up to {subscriptionPlan.volume_max} delivered and{' '}
              {subscriptionPlan.max_monthly_posted_vins} posted VINs
            </li>
            <li>
              {formatCurrency(subscriptionPlan.overage_fee, {
                maximumFractionDigits: 0,
              })}
              per additional VIN
            </li>
          </ul>
        </Column>
      </Columns>
      <Divider />
    </Stack>
  );
}

function PaymentDetails() {
  const {
    billingAddress,
    isBillingAddressLoading,
    paymentMethod,
    isPaymentMethodLoading,
  } = usePaymentDetails();

  if (isBillingAddressLoading || isPaymentMethodLoading) {
    return <LoadingState />;
  }

  if (!paymentMethod || !billingAddress) {
    return null;
  }

  return (
    <Stack space="small">
      <Columns space="small" align="top">
        <Column width="1/2">
          <Stack space="xxsmall">
            <Typography variant="h6" color="textSecondary">
              Payment details
            </Typography>
            <Typography variant="body1">
              {paymentMethod.card_number_masked}
            </Typography>
          </Stack>
        </Column>
        <Column>
          <Stack space="xxsmall">
            <Typography variant="h6" color="textSecondary">
              Billing Address
            </Typography>
          </Stack>
          <Typography>{getBillingAddressText(billingAddress)}</Typography>
        </Column>
      </Columns>
      <Divider />
    </Stack>
  );
}

function PaymentSummary() {
  const { subscriptionPlan, isSubscriptionPlansLoading } =
    useSelectedSubscriptionPlan();

  if (isSubscriptionPlansLoading) {
    return <LoadingState />;
  }

  if (!subscriptionPlan) {
    return null;
  }

  return (
    <Box paddingTop="xsmall" paddingBottom="xsmall">
      <Stack space="xsmall">
        <Typography variant="body1">Effective Today</Typography>
        <Stack space="xxsmall">
          <DescriptionLineItem title="Total">
            <Typography variant="h4">
              {formatCurrency(subscriptionPlan.monthly_price, {
                maximumFractionDigits: 0,
              })}
            </Typography>
          </DescriptionLineItem>
          <Typography align="right">+ Tax if applicable</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

function LoadingState() {
  return (
    <Columns>
      <Column width="1/2">
        <Skeleton width={78} />
      </Column>
      <Column>
        <Skeleton width={78} />
      </Column>
    </Columns>
  );
}
