import { UsageDetails } from './useUsageDetails';

export function calculateOveragesTotalPrice(
  usages: UsageDetails['usages'],
): number {
  return usages.reduce((acc, usage) => {
    const overage = usage.current_item_count - usage.max_item_count;

    if (overage <= 0) {
      return acc;
    }

    return acc + usage.overage_price * overage;
  }, 0);
}
