import { useSnackbarStack } from '@superdispatch/ui';
import { useMutation } from 'react-query';
import { useAPI } from 'shared/api/API';
import { APIError } from 'shared/errors/APIError';
import { useInvalidateSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';

export function useReactivateSubscription({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: APIError) => void;
} = {}) {
  const { request } = useAPI();
  const { addSnackbar } = useSnackbarStack();
  const invalidateSubscriptionDetails = useInvalidateSubscriptionDetails();

  const {
    mutate: reactivateSubscription,
    isLoading: isReactivatingSubscription,
  } = useMutation<unknown, APIError>({
    mutationFn: () =>
      request('POST /internal/subscription/remove-scheduled-cancellation'),
    onSuccess: () => {
      addSnackbar('Subscription reactivated.', { variant: 'success' });
      invalidateSubscriptionDetails();
      onSuccess?.();
    },
    onError: (error) => {
      addSnackbar(error.message, { variant: 'error' });
      onError?.(error);
    },
  });

  return { reactivateSubscription, isReactivatingSubscription };
}
