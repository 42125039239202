import { Link, MenuItem, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikPhoneField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { DrawerForm } from 'shared/form/DrawerForm';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import {
  composeValidators,
  email,
  maxLength,
  required,
} from 'shared/utils/ValidatorUtils';
import { NormalizeWidth } from 'shipper-profile/sections/AboutUsEditDrawer';
import { useManageUsersAPI, useUsersRolesList } from './data/ManageUsersAPI';
import { UserDTO } from './data/UserDTO';
import { UserRoleDTO } from './data/UserRoleDTO';

interface UserEditDrawerProps {
  open: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  user?: UserDTO;
}

export function UserEditDrawer({
  open,
  onClose,
  onUpdate,
  user,
}: UserEditDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { editUser } = useManageUsersAPI();
  const { data } = useUsersRolesList();
  const { objects: userRoles = [] } = data || {};
  const formik = useFormikEnhanced({
    key: open,

    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      role_id: user
        ? userRoles.find((role) => role.name === user.role)?.id || ''
        : '',
      username: user?.username || '',
      email: user?.email || '',
      phone: user?.phone || '',
      display_user_contact_to_carriers:
        user?.display_user_contact_to_carriers || false,
    },
    onSubmit: (values) => {
      if (values.display_user_contact_to_carriers) {
        trackEvent('[STMS] Display user contact to carriers checked', {
          utm_medium: 'User Edit',
          utm_content: 'Checkbox',
        });
      }

      return editUser(values, user?.guid);
    },
    onSubmitSuccess: (_) => {
      addSnackbar('User updated', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
  });
  const { dirty, isValid, isSubmitting, status, handleSubmit } = formik;

  return (
    <DrawerForm
      title="Edit User"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
    >
      <FormikProvider value={formik}>
        <NormalizeWidth>
          <Stack space="large">
            <Stack space="small">
              <FormikTextField
                name="first_name"
                label="First Name"
                validate={composeValidators(required, maxLength)}
                fullWidth={true}
              />

              <FormikTextField
                name="last_name"
                label="Last Name"
                validate={composeValidators(required, maxLength)}
                fullWidth={true}
              />

              <FormikTextField
                name="username"
                label="Username"
                validate={composeValidators(required, maxLength)}
                fullWidth={true}
              />

              <FormikTextField
                name="email"
                label="Email"
                validate={composeValidators(required, email, maxLength)}
                fullWidth={true}
              />

              <FormikPhoneField name="phone" label="Phone" fullWidth={true} />

              <FormikTextField
                name="role_id"
                label="User Role"
                select={true}
                fullWidth={true}
                validate={required}
                formatError={() => 'User Role is required'}
              >
                {userRoles.map((role: UserRoleDTO) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </FormikTextField>

              <Stack space="xxsmall">
                <FormikCheckboxField
                  label="Display user contact info to carriers"
                  name="display_user_contact_to_carriers"
                />
                <Typography color="textSecondary">
                  Carriers will see the user&apos;s contact info instead of the
                  company&apos;s on Super Loadboard, Carrier TMS, and dispatch
                  sheets.{' '}
                  <Link href="https://support.superdispatch.com/en/articles/7935598-how-to-display-a-user-s-contact-information-to-a-carrier">
                    Learn More
                  </Link>
                </Typography>
              </Stack>
            </Stack>

            <Button
              type="submit"
              variant="primary"
              disabled={!dirty || !isValid || isSubmitting}
            >
              Save
            </Button>
          </Stack>
        </NormalizeWidth>
      </FormikProvider>
    </DrawerForm>
  );
}
