import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ReportParamsDTO } from './ReportParamsDTO';

@Exclude()
export class ReportDTO {
  @Expose()
  id: number;

  @Expose()
  name: string;

  @Expose()
  @Transform((raw) => ReportParamsDTO.create(raw))
  @Type(() => ReportParamsDTO)
  report_params: ReportParamsDTO;
}
