import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const TrackBackIcon = createSvgIcon(
  'TrackBackIcon',
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1667 16.3C9.79979 16.3 9.44794 16.1543 9.18851 15.8948C8.92909 15.6354 8.78334 15.2836 8.78334 14.9167C8.78334 14.5498 8.92909 14.1979 9.18851 13.9385C9.44794 13.6791 9.79979 13.5333 10.1667 13.5333C10.5336 13.5333 10.8854 13.6791 11.1448 13.9385C11.4043 14.1979 11.55 14.5498 11.55 14.9167C11.55 15.2836 11.4043 15.6354 11.1448 15.8948C10.8854 16.1543 10.5336 16.3 10.1667 16.3ZM10.1667 11.4583C9.24947 11.4583 8.36983 11.8227 7.72127 12.4713C7.0727 13.1198 6.70834 13.9995 6.70834 14.9167C6.70834 15.8339 7.0727 16.7135 7.72127 17.3621C8.36983 18.0107 9.24947 18.375 10.1667 18.375C11.0839 18.375 11.9635 18.0107 12.6121 17.3621C13.2607 16.7135 13.625 15.8339 13.625 14.9167C13.625 13.9995 13.2607 13.1198 12.6121 12.4713C11.9635 11.8227 11.0839 11.4583 10.1667 11.4583Z"
      fill={Color.Dark100}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8036 13.5333H24V8H1.86667V13.5333H5.5325C5.83162 12.5359 6.44436 11.6614 7.27978 11.0398C8.1152 10.4181 9.12879 10.0824 10.1701 10.0824C11.2115 10.0824 12.2251 10.4181 13.0605 11.0398C13.8959 11.6614 14.5086 12.5359 14.8078 13.5333H14.8036Z"
      fill={Color.Dark100}
    />
  </svg>,
);
