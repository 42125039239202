import {
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { formatVehicleType } from '@superdispatch/sdk';
import { Color, Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { OrderVehicleStatus } from 'orders/core/OrderVehicleStatus';
import { useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { DamagedIcon } from 'shared/icons/DamagedIcon';
import { InoperableVehicleIcon } from 'shared/icons/InoperableVehicleIcon';
import Order from 'shared/types/order';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { ResponsiveCopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import { DeliveryETA } from 'shared/ui/DeliveryETA';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { InlineWaypoints } from 'shared/ui/InlineWaypoints';
import { PhotoViewer } from 'shared/ui/PhotoViewer';
import { PickupETA } from 'shared/ui/PickupETA';
import { Waypoints } from 'shared/ui/Waypoints';
import { makeCityStateZip } from 'shared/utils/AddressUtils';
import styled from 'styled-components';
import { ViewOrderRoute } from '../core/ViewOrderRoute';
import { mapVehiclePhotoToSlide } from './galleryUtils';
import { OrderViewVehicleBOL } from './OrderViewVehicleBOL';
import { formatDimensions } from './OrderViewVehicles';

const deliveryDateAvailableStatuses = [
  'delivered',
  'invoiced',
  'delivery_verified',
  'paid',
  'submitted',
  'completed',
];

const pickupDateAvailableStatuses = [
  'accepted',
  'picked_up',
  'delivered',
  'invoiced',
  'delivery_verified',
  'paid',
  'submitted',
  'completed',
];

const StyledTableRow = styled(TableRow)`
  :last-child {
    border-bottom: hidden;
  }
`;

const NoBorderTableRow = styled(TableRow)`
  border-bottom: hidden;
`;

const StyledTableCell = styled(TableCell)`
  padding-bottom: 0;
`;

const DeleteIcon = styled(Delete)`
  color: ${Color.Silver500};
`;

interface OrderViewRelatedVehiclesProps {
  order: Order;
  loads: Order[];
  hiddenFields: Set<string>;
  handleRemoveRelations: (id: number) => void;
}

export function OrderViewRelatedVehicles({
  order,
  loads,
  hiddenFields,
  handleRemoveRelations,
}: OrderViewRelatedVehiclesProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isDesktop = platform === 'desktop';
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loadIdToRemoveRelations, setLoadIdToRemoveRelations] =
    useState<number>(0);

  return (
    <>
      {loads.map((load) => {
        const {
          id,
          is_active,
          guid,
          number,
          pickup,
          delivery,
          vehicles = [],
        } = load;

        const pickupAddress = makeCityStateZip(pickup?.venue);
        const deliveryAddress = makeCityStateZip(delivery?.venue);

        const pickupWaypoints = {
          title: (
            <Inline verticalAlign="center">
              {pickupAddress ? (
                <>
                  <Typography variant="body1" component="div">
                    {pickupAddress}
                  </Typography>

                  {order.pickup?.venue?.business_type && (
                    <Typography color="textSecondary">
                      {order.pickup.venue.business_type}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Pickup address not specified
                </Typography>
              )}
            </Inline>
          ),
        };

        const deliveryWaypoints = {
          title: (
            <Inline verticalAlign="center">
              {deliveryAddress ? (
                <>
                  <Typography variant="body1" component="div">
                    {deliveryAddress}
                  </Typography>

                  {order.delivery?.venue?.business_type && (
                    <Typography color="textSecondary">
                      {order.delivery.venue.business_type}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Delivery address not specified
                </Typography>
              )}
            </Inline>
          ),
        };

        return (
          <>
            <NoBorderTableRow key={id}>
              <StyledTableCell colSpan={9}>
                <Stack space="small">
                  <Inline verticalAlign="center">
                    <Link
                      component={RouterLink}
                      to={
                        is_active
                          ? `/orders/view/${guid}`
                          : `/orders/inactive/view/${guid}`
                      }
                    >
                      <Typography variant="h4" component="span">
                        {number}
                      </Typography>
                    </Link>

                    <IconButton
                      size="small"
                      aria-label="remove relations"
                      onClick={() => {
                        setLoadIdToRemoveRelations(id);
                        setShowConfirmation(true);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Inline>

                  {isDesktop ? (
                    <Inline verticalAlign="center">
                      <InlineWaypoints
                        from={pickupWaypoints}
                        to={deliveryWaypoints}
                      />

                      {pickup?.venue && delivery?.venue && (
                        <ViewOrderRoute
                          origin={pickup.venue}
                          destination={delivery.venue}
                        />
                      )}
                    </Inline>
                  ) : (
                    <Stack space="xsmall">
                      <Waypoints
                        from={pickupWaypoints}
                        to={deliveryWaypoints}
                      />

                      {pickup?.venue && delivery?.venue && (
                        <ViewOrderRoute
                          origin={pickup.venue}
                          destination={delivery.venue}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              </StyledTableCell>
            </NoBorderTableRow>

            {vehicles.map((vehicle, index) => (
              <OrderViewRelatedVehicle
                order={order}
                key={index}
                index={index}
                loads={loads}
                vehicle={vehicle}
                hiddenFields={hiddenFields}
              />
            ))}
          </>
        );
      })}

      <ConfirmDialog
        open={showConfirmation}
        onClose={() => {
          setShowConfirmation(false);
          setLoadIdToRemoveRelations(0);
        }}
        title="Remove relation between order and load?"
        cancelButtonProps={{ children: 'No' }}
        confirmButtonProps={{
          onClick: () => {
            setShowConfirmation(false);
            handleRemoveRelations(loadIdToRemoveRelations);
          },
          children: 'Yes, Remove',
          color: 'primary',
        }}
      />
    </>
  );
}

interface OrderViewRelatedVehicleProps {
  order: Order;
  index: number;
  loads: Order[];
  vehicle: VehicleDTO;
  hiddenFields: Set<string>;
}

function OrderViewRelatedVehicle({
  order,
  index,
  loads,
  hiddenFields,
  vehicle,
}: OrderViewRelatedVehicleProps) {
  const isManuallyChanged =
    order.status === 'invoiced'
      ? !!order.invoice?.adjusted_invoice_date
      : order.is_status_changed_manually;

  const {
    id: vehicleId,
    vin,
    year,
    make,
    model,
    type,
    color,
    price,
    tariff,
    status,
    pickup: vehiclePickup,
    photos,
    delivery: vehicleDelivery,
    curb_weight,
    curb_weight_unit,
    is_damaged: isDamaged,
    lot_number: lotNumber,
    is_inoperable: isInoperable,
    eta_information,
  } = vehicle;

  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const hasLotValue = loads.some((load) =>
    load.vehicles?.some((item) => item.lot_number),
  );

  const unit = curb_weight_unit || 'lbs';
  const dimensions = formatDimensions(vehicle);

  const samplePhotos = useMemo(
    () => photos?.filter((x) => x.photo_type === 'Sample') ?? [],
    [photos],
  );

  const [thumbnail] = samplePhotos;

  const info = [year, make, model].filter(Boolean).join(' ');

  const vehicleDeliveryDate =
    !isManuallyChanged || !deliveryDateAvailableStatuses.includes(order.status)
      ? vehicleDelivery?.completed_at
      : vehicleDelivery?.completed_at || order.delivery?.adjusted_date;

  const vehicleDeliveryETA = eta_information?.delivery_eta;

  const vehiclePickupDate =
    !isManuallyChanged || !pickupDateAvailableStatuses.includes(order.status)
      ? vehiclePickup?.completed_at
      : vehiclePickup?.completed_at || order.pickup?.adjusted_date;

  const vehiclePickupETA = eta_information?.pickup_eta;

  const slides = mapVehiclePhotoToSlide(samplePhotos);

  return (
    <StyledTableRow key={vehicleId}>
      <TableCell>
        <TextBox aria-label="vehicle number">{index + 1}</TextBox>
      </TableCell>

      <TableCell>
        <Inline verticalAlign="center" space="xsmall">
          {isInoperable && (
            <InoperableVehicleIcon aria-label="vehicle inoperable" />
          )}

          {isDamaged && shipperType === 'BROKER' && (
            <DamagedIcon aria-label="vehicle damaged" />
          )}
        </Inline>
      </TableCell>

      {!hiddenFields.has('status') && (
        <TableCell>
          <OrderVehicleStatus status={status} />
        </TableCell>
      )}

      {!hiddenFields.has('pickupDate') && (
        <TableCell>
          {!vehiclePickup?.completed_at && vehiclePickupETA ? (
            <PickupETA pickupETA={vehiclePickupETA} showEmpty={true} />
          ) : vehiclePickupDate ? (
            <DetailedFormattedDate
              date={vehiclePickupDate}
              ContainerProps={{
                'aria-label': 'vehicle pickup date',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>
      )}

      {!hiddenFields.has('deliveryDate') && (
        <TableCell>
          {vehicleDeliveryDate ? (
            <DetailedFormattedDate
              date={vehicleDeliveryDate}
              ContainerProps={{
                'aria-label': 'vehicle delivery date',
              }}
            />
          ) : (
            <DeliveryETA
              delivery={order.delivery}
              key={vehicle.guid}
              deliveryETA={vehicleDeliveryETA}
              showEmpty={true}
            />
          )}
        </TableCell>
      )}

      <TableCell>
        {thumbnail?.photo_url && (
          <PhotoViewer
            imageUrl={thumbnail.photo_url}
            thumbnailUrl={thumbnail.thumbnail_url}
            FullImageProps={{ alt: info }}
            slides={slides}
            ThumbnailImageProps={{
              alt: info,
              width: '48',
              height: '36',
            }}
          />
        )}
      </TableCell>

      <TableCell>
        <ResponsiveCopyToClipboardTooltip
          text={info}
          title="Copy Info"
          onCopy={() => {
            trackEvent('Shipper Copied Vehicle Info', {
              utm_medium: 'Order View',
            });
          }}
        >
          <Typography gutterBottom={true} aria-label="vehicle info">
            {info}
          </Typography>
        </ResponsiveCopyToClipboardTooltip>

        {vin && (
          <ResponsiveCopyToClipboardTooltip
            text={vin}
            title="Copy VIN"
            onCopy={() => {
              trackEvent('Shipper Copied VIN', { utm_medium: 'Order View' });
            }}
          >
            <Typography color="textSecondary" aria-label="vehicle vin">
              {vin}
            </Typography>
          </ResponsiveCopyToClipboardTooltip>
        )}
      </TableCell>

      {!hiddenFields.has('vehicleDetails') && (
        <TableCell>
          <Stack space="xxsmall">
            {(type || curb_weight || color) && (
              <InlineBulletItems aria-label="vehicle details">
                {type && (
                  <TextBox color="secondary">{formatVehicleType(type)}</TextBox>
                )}

                {((user?.order_form_settings.vehicle.is_color_visible &&
                  color) ||
                  color) && (
                  <TextBox color="secondary" aria-label="vehicle color">
                    {color}
                  </TextBox>
                )}

                {curb_weight && (
                  <TextBox color="secondary">
                    <NumericFormat
                      displayType="text"
                      value={curb_weight}
                      suffix={` ${unit}`}
                      thousandSeparator={true}
                    />
                  </TextBox>
                )}
              </InlineBulletItems>
            )}

            {dimensions.length > 0 && (
              <TextBox color="secondary">{dimensions}</TextBox>
            )}
          </Stack>
        </TableCell>
      )}

      {!hiddenFields.has('lotNumber') &&
        (user?.order_form_settings.vehicle.is_lot_number_visible ||
          hasLotValue) && (
          <TableCell>
            {!lotNumber ? (
              '-'
            ) : (
              <TextBox color="secondary" aria-label="vehicle lot number">
                {lotNumber}
              </TextBox>
            )}
          </TableCell>
        )}

      <TableCell align="right">
        {!tariff ? '-' : <Price value={tariff} aria-label="vehicle tariff" />}
      </TableCell>

      {shipperType === 'BROKER' && (
        <TableCell align="right">
          {!price ? '-' : <Price value={price} aria-label="vehicle price" />}
        </TableCell>
      )}

      <TableCell align="center">
        <OrderViewVehicleBOL vehicle={vehicle} />
      </TableCell>
    </StyledTableRow>
  );
}
