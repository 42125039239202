import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Confirmation } from 'shared/ui/Confirmation';
import { useTerminalContactAPI } from '../data/TerminalContactAPI';

interface Props {
  terminalId: number;
  contactId: number;
}

export function TerminalContactDeleteConfirmation({
  terminalId,
  contactId,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { deleteTerminalContact } = useTerminalContactAPI();

  const { handleSubmit, isSubmitting } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => deleteTerminalContact(terminalId, contactId),
    onSubmitSuccess() {
      addSnackbar('Contact deleted');
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Confirmation
      title="Delete this contact?"
      cancelText="No"
      processText="Yes, Delete"
      onProcess={() => {
        handleSubmit();
      }}
      isProcessing={isSubmitting}
      trigger={({ showConfirmation }) => (
        <IconButton
          size="small"
          onClick={showConfirmation}
          aria-label="delete contact"
        >
          <Delete fontSize="small" />
        </IconButton>
      )}
    />
  );
}
