import { ExitTransitionPlaceholder } from '@superdispatch/ui';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { Order } from 'shared/types/order';
import {
  PriceNegotiationDrawerContent,
  PriceNegotiationDrawerContentProps,
} from './PriceNegotiationDrawerContent';

interface PriceNegotiationDrawerProps
  extends Omit<PriceNegotiationDrawerContentProps, 'order'> {
  order: Order | undefined;
  onClose: () => void;
}

export function PriceNegotiationDrawer({
  order,
  onClose,
  ...props
}: PriceNegotiationDrawerProps) {
  return (
    <FormikDrawer open={!!order} onClose={onClose} confirmClose={true}>
      <ExitTransitionPlaceholder in={!!order}>
        {!!order && <PriceNegotiationDrawerContent order={order} {...props} />}
      </ExitTransitionPlaceholder>
    </FormikDrawer>
  );
}
