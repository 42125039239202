import { Context, useContext } from 'react';

export function useNullableContext<T>(name: string, context: Context<T>) {
  const value = useContext(context);

  if (import.meta.env.MODE !== 'production') {
    context.displayName = `${name}Context`;
  }

  if (value == null) {
    throw new Error(`Used outside of "${name}ContextProvider"`);
  }

  return value as NonNullable<T>;
}
