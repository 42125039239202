import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { DisplayCard } from './DisplayCard';
import { EditBillingAddressButton } from './EditBillingAddressButton';
import { EditPaymentDetailsButton } from './EditPaymentDetailsButton';
import {
  getBillingAddressText,
  getExpirationDateText,
} from './PaymentDetailsHelpers';
import { usePaymentDetails } from './usePaymentDetails';

export function SubscriptionPaymentInformationCard() {
  const {
    billingAddress,
    isBillingAddressLoading,
    paymentMethod,
    isPaymentMethodLoading,
  } = usePaymentDetails();

  return (
    <DisplayCard title="Payment Information">
      {isPaymentMethodLoading ? (
        <Stack space="medium">
          <Skeleton />
          <Skeleton />
        </Stack>
      ) : (
        <Columns align="top" space="small">
          <Column>
            <Stack space="xxsmall">
              <Typography variant="h4">Card Details</Typography>
              <Inline space="xxsmall">
                <Typography color="textSecondary">Card Number:</Typography>
                <Typography>{paymentMethod?.card_number_masked}</Typography>
              </Inline>
              <Inline space="xxsmall">
                <Typography color="textSecondary">Expiration Date:</Typography>
                <Typography>{getExpirationDateText(paymentMethod)}</Typography>
              </Inline>
            </Stack>
          </Column>
          <Column width="content">
            <EditPaymentDetailsButton />
          </Column>
        </Columns>
      )}
      {isBillingAddressLoading ? (
        <Stack space="medium">
          <Skeleton />
          <Skeleton />
        </Stack>
      ) : (
        <Columns align="top" space="small">
          <Column>
            <Stack space="xxsmall">
              <Typography variant="h4">Billing Address</Typography>
              <Typography color="textSecondary">
                {getBillingAddressText(billingAddress)}
              </Typography>
            </Stack>
          </Column>
          <Column width="content">
            <EditBillingAddressButton />
          </Column>
        </Columns>
      )}
    </DisplayCard>
  );
}
