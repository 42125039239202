import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class VehiclesFieldsDTO {
  @Expose() is_color_visible: boolean;
  @Expose() is_lot_number_visible: boolean;
}

@Exclude()
export class CustomerFieldsDTO {
  @Expose() is_business_type_visible: boolean;
  @Expose() is_address_visible: boolean;
  @Expose() is_phone_visible: boolean;
  @Expose() is_email_visible: boolean;
  @Expose() is_notes_visible: boolean;
  @Expose() is_contact_title_visible: boolean;
  @Expose() is_contact_mobile_visible: boolean;
  @Expose() is_contact_email_visible: boolean;
}

@Exclude()
export class VenueFieldsDTO {
  @Expose() is_business_type_visible: boolean;
  @Expose() is_contact_title_visible: boolean;
  @Expose() is_contact_email_visible: boolean;
  @Expose() is_contact_mobile_phone_visible: boolean;
}

@Exclude()
export class PickupFieldsDTO {
  @Expose() venue: VenueFieldsDTO;

  @Expose() is_first_available_pickup_date_visible: boolean;
  @Expose() is_scheduled_at_by_customer_visible: boolean;
  @Expose() is_adjusted_date_visible: boolean;
}

@Exclude()
export class DeliveryFieldsDTO {
  @Expose() venue: VenueFieldsDTO;

  @Expose() is_scheduled_at_by_customer_visible: boolean;
  @Expose() is_adjusted_date_visible: boolean;
}

@Exclude()
export class PaymentFieldsDTO {
  @Expose() is_broker_fee_visible: boolean;
  @Expose() is_amount_visible: boolean;
  @Expose() is_reference_number_visible: boolean;
  @Expose() is_sent_date_visible: boolean;
}

@Exclude()
export class CustomerPaymentFieldsDTO {
  @Expose() is_deposit_visible: boolean;
  @Expose() is_cod_cop_amount_visible: boolean;
  @Expose() is_amount_visible: boolean;
  @Expose() is_method_visible: boolean;
  @Expose() is_reference_number_visible: boolean;
  @Expose() is_received_date_visible: boolean;
}

@Exclude()
export class OrderFieldsDTO {
  @Expose() is_purchase_order_number_visible: boolean;
  @Expose() is_transport_type_visible: boolean;

  @Expose() vehicle: VehiclesFieldsDTO;

  @Expose() is_loadboard_instructions_visible: boolean;
  @Expose() is_instructions_visible: boolean;
  @Expose() is_customer_block_visible: boolean;

  @Expose() customer: CustomerFieldsDTO;

  @Expose() pickup: PickupFieldsDTO;

  @Expose() delivery: DeliveryFieldsDTO;

  @Expose() payment: PaymentFieldsDTO;

  @Expose() customer_payment: CustomerPaymentFieldsDTO;
}
