import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { DescriptionLineItem } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { UsageDetails, UsageName } from './useUsageDetails';

export function PricingSectionOverages({
  usages,
}: {
  usages: UsageDetails['usages'];
}) {
  return (
    <Stack>
      {usages.map((usage) => {
        const overage = usage.current_item_count - usage.max_item_count;

        if (overage <= 0) {
          return null;
        }

        return (
          <DescriptionLineItem
            key={usage.name}
            title={getUsageTitle(usage.name, usage.overage_price)}
          >
            <Typography variant="h4">
              {formatCurrency(usage.overage_price * overage, {
                maximumFractionDigits: 2,
              })}
            </Typography>
          </DescriptionLineItem>
        );
      })}
    </Stack>
  );
}

function getUsageTitle(usage: UsageName, overagePrice: number) {
  if (usage === 'VIN_DELIVERED') {
    return (
      <>
        Additional Delivered VINs{' '}
        <Typography component="span" color="textSecondary">
          (
          {formatCurrency(overagePrice, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}{' '}
          per additional delivered VIN)
        </Typography>
      </>
    );
  }

  if (usage === 'VIN_POSTED') {
    return (
      <>
        Additional Posted VINs{' '}
        <Typography component="span" color="textSecondary">
          (
          {formatCurrency(overagePrice, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}{' '}
          per additional posted VIN)
        </Typography>
      </>
    );
  }

  return (
    <>
      Total of SuperPay Transactions{' '}
      <Typography component="span" color="textSecondary">
        (
        {formatCurrency(overagePrice, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}{' '}
        per transaction)
      </Typography>
    </>
  );
}
