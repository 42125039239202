import { Exclude, Expose } from 'class-transformer';

@Exclude()
class OrderFormDecodedVehicleVenueDTO {
  @Expose() completed_at: string | null;
}

@Exclude()
export class DecodedOrderVehicleDTO {
  @Expose() id?: number;
  @Expose() vin?: string;
  @Expose() status?: null;
  @Expose() guid?: string;
  @Expose() is_inoperable?: boolean;
  @Expose() pickup?: OrderFormDecodedVehicleVenueDTO;
  @Expose() delivery?: OrderFormDecodedVehicleVenueDTO;
  @Expose() make: string;
  @Expose() model: string;
  @Expose() color?: string;
  @Expose() requires_enclosed_trailer?: boolean;
  @Expose() type?: string;
  @Expose() year?: string;
  @Expose() jtracker_id?: number;
  @Expose() lot_number?: string;
  @Expose() tariff?: number;
  @Expose() price?: number;
  @Expose() is_damaged?: boolean;

  @Expose() curb_weight?: number;
  @Expose() curb_weight_unit?: string;
  @Expose() length: string | null;
  @Expose() width: string | null;
  @Expose() height: string | null;
}
