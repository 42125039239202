import { Link } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo } from 'react';
import { addSourceParams } from 'shared/helpers/SourceManagmentLegacy';
import { RatingStatsDTO } from './data/CarrierRatingDTO';

interface RatingStatsProps {
  carrierGuid: string;
  source?: 'Shipper Profile' | 'Carrier Requests Drawer' | 'Instant Dispatch';
  sourceContent?: 'Carrier Profile Link';
  ratingStats: RatingStatsDTO | undefined | null;
}

export function RatingStats({
  source,
  sourceContent,
  ratingStats,
  carrierGuid,
}: RatingStatsProps) {
  const link = useMemo(() => {
    //use source derived from the parent components
    if (source) {
      return parseURITemplate(
        '/manage-carriers/{carrierGuid}{?tab,utm_medium,utm_content}',
        {
          carrierGuid,
          tab: 'ratings',
          utm_medium: source,
          utm_content: sourceContent,
        },
      );
    }

    //or source from source manager
    return addSourceParams(`/manage-carriers/${carrierGuid}?tab=ratings`);
  }, [carrierGuid, source, sourceContent]);

  return (
    <Inline space="xxsmall" verticalAlign="center" aria-label="rating-stats">
      <ThumbUp color="action" fontSize="small" />
      <Link href={link} rel="noreferrer" target="_blank">
        {ratingStats?.overall_rating ?? 0}%
      </Link>

      {!!ratingStats?.total_rating_count && (
        <TextBox color="secondary">({ratingStats.total_rating_count})</TextBox>
      )}
    </Inline>
  );
}
