import type { DateString } from '@superdispatch/dates';
import { Exclude, Expose } from 'class-transformer';
import { OrderFieldsDTO } from 'manage-fields/data/OrderFieldsDTO';
import { UserRoleDTO } from 'shared/types/permission';

export interface User {
  created_at: string;
  email: string | null;
  id: number;
  first_name: string;
  guid: string;
  last_name: string;
  username: string;
  is_superuser: boolean;
}

@Exclude()
export class UserDTO {
  @Expose() created_at: DateString;
  @Expose() changed_at: DateString;
  @Expose() email: string;
  @Expose() id: number;
  @Expose() first_name: string;
  @Expose() guid: string;
  @Expose() last_name: string;
  @Expose() username: string;
  @Expose() is_superuser: boolean;
  @Expose() is_active: boolean;
  @Expose() is_email_notifications_enabled: boolean;
}

export type ShipperType = 'BROKER' | 'CUSTOMER';

export interface WhoAmILinkedBroker {
  is_order_submit_allowed: boolean;
  broker_guid: string;
}

export interface WhoAmI extends User {
  user_roles: UserRoleDTO[];
  user_hash: string;
  shipper: {
    id: string;
    guid: string;
    name: string;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
    time_zone: string | null;
    zone_offset_minutes: number;
    default_bol_template: string | null;
    default_inspection_type: string | null;
    shipper_type: ShipperType;
    usdot_number: string;
    carrier_guid: string;
    logo_url: string;
    is_test_account: boolean;
    is_instant_booking_allowed: boolean;
    is_super_pay_enabled: boolean;
    had_superpay_access: boolean;
    is_private_network_enabled: boolean;
    is_onboarding_completed: boolean;
    is_self_serve: boolean;
    super_pay_settings?: {
      on_boarding_status:
        | 'STARTED'
        | 'OWNERS_PROVIDED'
        | 'PENDING'
        | 'COMPLETED';
      suspended_account: boolean;
      superpay_requested_banner_available: boolean;
      can_expedite_payment_tab_available: boolean;
      superpay_flow: 'MANUAL' | 'AUTOMATIC' | null;
      alert_status: 'ERROR' | 'WARNING' | null;
    };
    subscription_plan: 'TRIAL' | 'BASIC' | 'ADVANCED';
    verification_status:
      | 'NOT_SUBMITTED'
      | 'PENDING'
      | 'WAITING_FOR_CUSTOMER'
      | 'INTERNATIONAL_SHIPPER'
      | 'REJECTED'
      | 'REVOKED'
      | 'VERIFIED';
  };
  linked_brokers?: WhoAmILinkedBroker[];
  feature_toggles?: Record<string, boolean>;
  order_form_settings: OrderFieldsDTO;
}

/** @deprecated */
export type CurrentUser = WhoAmI;
