import { Link, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useOrderActionsContext } from 'orders/core/actions/OrderActionsContext';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { getCurrentTab } from 'shared/layout/AppNavbar';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { useSuperPaySettings } from 'superpay/data/SuperPayAPI';
import { useOrderPaymentFlags } from '../../../orders/data/OrderPaymentFlagsAPI';
import { AllPaymentTerm } from '../../dto/Order/CarrierPaymentDTO';
import { SuperPayIcon } from '../../icons/SuperPayIcon';
import { trackEvent } from '../AnalyticsHelpers';

const SuperPayNowLink = styled(Link)`
  cursor: pointer;
`;

function useSuperPayOnboardingCompleted() {
  const { user } = useUserState();
  return user?.shipper.super_pay_settings?.on_boarding_status === 'COMPLETED';
}

export function useSuperPaySuspend() {
  const { user } = useUserState();
  return user?.shipper.super_pay_settings?.suspended_account;
}

export function useSuperPayEnabled() {
  const { user } = useUserState();
  return user?.shipper.is_super_pay_enabled;
}

type Source =
  | 'New Order'
  | 'Shipper Profile'
  | 'Order Edit'
  | 'Order Create'
  | 'Order View'
  | 'Order List'
  | 'Order List Bulk'
  | 'Order Requests'
  | 'Dashboard Order List'
  | 'Build Load'
  | 'Import Order'
  | 'SuperPay Now Dialog'
  | 'Mark Order as Paid Drawer';

interface SelectSuperPayProps {
  source: Source;
  onClick: () => void;
}

export function SelectSuperPayHelperText({
  source,
  onClick,
}: SelectSuperPayProps) {
  const isCompleted = useSuperPayOnboardingCompleted();

  return (
    <Box>
      <Link
        component="button"
        color="textSecondary"
        variant="body2"
        onClick={(event) => {
          event.preventDefault();
          if (isCompleted) {
            onClick();
          } else {
            window.open(
              `/superpay?utm_medium=${source}&utm_content=Set up Link from Method`,
              '_blank',
            );
          }
        }}
      >
        {isCompleted ? 'Select' : 'Set up'}
      </Link>{' '}
      <SuperPayIcon width={66} height={13} /> to streamline pay
    </Box>
  );
}

interface SelectSuperPayTermsHelperProps extends SelectSuperPayProps {
  terms: AllPaymentTerm;
}

export function SelectSuperPayTermsHelperText({
  source,
  onClick,
  terms,
}: SelectSuperPayTermsHelperProps) {
  const isCompleted = useSuperPayOnboardingCompleted();
  const termsDay = terms.replace(/\D/g, '');

  return (
    <Box>
      <Link
        component="button"
        color="textSecondary"
        variant="body2"
        onClick={(event) => {
          event.preventDefault();
          if (isCompleted) {
            onClick();
          } else {
            window.open(
              `/superpay?utm_medium=${source}&utm_content=Set up Link from Terms`,
              '_blank',
            );
          }
        }}
      >
        {isCompleted ? `Switch to ${termsDay} BD` : 'Set up'}
      </Link>{' '}
      {isCompleted ? (
        <>
          via <SuperPayIcon width={66} height={13} /> to streamline pay
        </>
      ) : (
        <>
          <SuperPayIcon width={66} height={13} /> with ${termsDay} BD to
          streamline pay
        </>
      )}
    </Box>
  );
}

interface CarrierRequestedSuperPayProps {
  order: Order;
  source: Source;
}

export function CarrierRequestedSuperPay({
  order,
  source,
}: CarrierRequestedSuperPayProps) {
  const { data: superPaySettings } = useSuperPaySettings();
  const { onAction } = useOrderActionsContext();
  const isOnboardingCompleted = useSuperPayOnboardingCompleted();
  const orderPaymentFlags = useOrderPaymentFlags(order.id);
  const showNoAction =
    superPaySettings?.superpay_flow === 'MANUAL' && order.status === 'invoiced';

  const showSuperPayNowAction =
    isOnboardingCompleted &&
    ((superPaySettings?.superpay_flow === 'MANUAL' &&
      order.status === 'delivery_verified') ||
      (superPaySettings?.superpay_flow === 'AUTOMATIC' &&
        order.status === 'invoiced'));

  return order.payment?.superpay_canceled ? null : (
    <Box
      paddingTop="xxsmall"
      paddingBottom="xxsmall"
      paddingLeft="xsmall"
      paddingRight="xsmall"
      borderRadius="small"
      backgroundColor="Yellow50"
      data-intercom-target="request payment via superpay"
    >
      <Stack space="xxsmall">
        <Typography variant="caption">
          Carrier requests payment via SuperPay for easy tracking and no status
          calls.
        </Typography>
        {showNoAction ? null : showSuperPayNowAction ? (
          orderPaymentFlags?.can_superpay_now && (
            <SuperPayNowLink
              variant="caption"
              color="primary"
              underline="none"
              onClick={(event) => {
                onAction('superpay_now', order, event.currentTarget);
                trackEvent('Shipper Clicked SuperPay Now', {
                  utm_medium: source,
                  superPay_workflow: superPaySettings.superpay_flow,
                  payment_method: order.payment?.method,
                  order_status: order.status,
                  tab: getCurrentTab(),
                  utm_content: 'Carrier Request to Pay via SuperPay',
                });
              }}
            >
              SuperPay Now
            </SuperPayNowLink>
          )
        ) : (
          <Link
            href={`/superpay?utm_medium=${source}&utm_content=Carrier Request to Pay via SuperPay`}
            target="_blank"
            rel="noreferrer"
            variant="caption"
            color="primary"
            underline="none"
          >
            Pay via SuperPay
          </Link>
        )}
      </Stack>
    </Box>
  );
}

export function useSuperPayPaymentDueOn(order: Order) {
  return useMemo(() => {
    const expectedAt = order.payment?.super_pay?.expected_at || '';
    const isSuperPayCreatedStatus =
      order.payment?.super_pay?.status === 'CREATED';
    const isShow = !!(expectedAt && isSuperPayCreatedStatus);

    return {
      isShowPaymentDueOn: isShow,
      isDateLessCurrent: !!(
        expectedAt && new Date(expectedAt).getTime() < new Date().getTime()
      ),
      paymentExpectedDate:
        expectedAt && formatDate(expectedAt, { variant: 'ShortDate' }),
    };
  }, [order.payment?.super_pay?.expected_at, order.payment?.super_pay?.status]);
}
