import { Card, CardContent, Container, Switch } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { AriaAttributes, ReactNode } from 'react';
import styled from 'styled-components';

export const SectionContainer = styled(Container)`
  max-width: 624px;
  padding: 0;
`;

export const SectionHeader = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${Color.Silver400};
`;

export const SectionSwitch = styled(Switch)`
  margin: -12px;
`;

interface SuperLoadboardSettingsSectionProps extends AriaAttributes {
  children: ReactNode;
}

export function SuperLoadboardSettingsSection({
  children,
  ...props
}: SuperLoadboardSettingsSectionProps) {
  return (
    <SectionContainer {...props}>
      <Card>
        <CardContent>
          <Stack space="small">{children}</Stack>
        </CardContent>
      </Card>
    </SectionContainer>
  );
}
