import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { ShieldAlertIcon } from 'shared/icons/Icons';
import { CarrierFullInfo } from 'shared/types/carrier';
import { ReportCarrierDrawer } from '../core/ReportCarrierDrawer';
import { useReportedCarriers } from '../core/ReportedCarriers';

interface CarrierReportingProps {
  carrier: CarrierFullInfo;
}

export function CarrierReporting({ carrier }: CarrierReportingProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reportedCarriersGUIDs] = useReportedCarriers();

  return (
    <>
      <ReportCarrierDrawer
        carrier={carrier}
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      />
      <Button
        fullWidth={true}
        variant="neutral"
        size="large"
        endIcon={<ShieldAlertIcon />}
        disabled={reportedCarriersGUIDs.includes(carrier.guid)}
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      >
        Report Carrier
      </Button>
    </>
  );
}
