import { Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import styled from 'styled-components';
import { NotificationDTO } from '../data/notifications/NotificationDTO';

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    border: 2px solid ${Color.Dark500};
    top: 2px;
  }

  & .MuiBadge-dot {
    margin-top: 2px;
    margin-right: 2px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
  }
`;

interface SidebarNotificationsIconProps {
  notifications: NotificationDTO[];
}

export function SidebarNotificationsIcon({
  notifications,
}: SidebarNotificationsIconProps) {
  return notifications.length > 0 ? (
    <StyledBadge color="error" variant="dot">
      <Notifications htmlColor={Color.Silver500} />
    </StyledBadge>
  ) : (
    <Notifications htmlColor={Color.Silver500} />
  );
}
