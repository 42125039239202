import { formatPaymentTerm } from '@superdispatch/sdk';
import { Color, Inline } from '@superdispatch/ui';
import { SuperPayIcon } from 'shared/icons/SuperPayIcon';
import styled from 'styled-components';

const GradientBorderTerm = styled.div`
  background: linear-gradient(${Color.Dark500} 0 0) padding-box,
    linear-gradient(90deg, ${Color.Blue500} 0%, #fe4a49 100%) border-box;
  padding: 1px 2px 1px 2px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

interface Props {
  terms: string;
  method?: string | null;
}

export function PostingPaymentTerm({ method, terms }: Props) {
  return method === 'superpay' ? (
    <Inline noWrap={false} verticalAlign="center" space="xxsmall">
      <SuperPayIcon />
      <GradientBorderTerm>
        {formatPaymentTerm(terms, { fallback: terms })}
      </GradientBorderTerm>
    </Inline>
  ) : (
    <span>{formatPaymentTerm(terms, { fallback: terms, short: true })}</span>
  );
}
