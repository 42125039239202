import { Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline } from '@superdispatch/ui';

interface StatusProps {
  preferred: boolean;
  preferredSince: string | null;
}

export const NetworkDrawerStatus = ({
  preferred,
  preferredSince,
}: StatusProps) => {
  return (
    <FormikCheckboxField
      name="preferred"
      label={
        <Inline verticalAlign="center">
          <Typography>Can Instantly Book</Typography>

          <Tooltip
            title={
              <>
                This carrier can book your loads <br />
                24/7 on Super Loadboard.
              </>
            }
          >
            <Info fontSize="small" color="action" />
          </Tooltip>

          {preferred && (
            <Typography variant="caption" color="textSecondary">
              on{' '}
              <FormattedDate
                variant="Date"
                date={preferredSince}
                format="DateISO"
              />
            </Typography>
          )}
        </Inline>
      }
    />
  );
};
