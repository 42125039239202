import { Link, Typography } from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { CheckIconRounded } from 'shared/icons/CheckIconRounded';
import { WarningIconRounded } from 'shared/icons/WarningIconRounded';
import { CarrierFullInfo, isCarrierHasValidUSDOT } from 'shared/types/carrier';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';

const VerifiedDocumentIcon = styled(CheckIconRounded)`
  height: 20px;
  width: 20px;
`;

const UnverifiedDocumentIcon = styled(WarningIconRounded)`
  height: 20px;
  width: 20px;
`;

interface CarrierDocumentLinkProps {
  isVerified: boolean;
  link: string | null;
  'aria-label'?: string;
  label: string;
  emptyLabel: string;
  onClick?: () => void;
}

function DocumentLink({
  isVerified,
  'aria-label': ariaLabel,
  link,
  label,
  emptyLabel,
  onClick,
}: CarrierDocumentLinkProps) {
  return (
    <Columns aria-label={ariaLabel} space="xsmall" align="center">
      <Column width="content">
        {isVerified ? (
          <VerifiedDocumentIcon />
        ) : (
          <ResponsiveTooltip placement="top" title="Unverified document">
            <UnverifiedDocumentIcon />
          </ResponsiveTooltip>
        )}
      </Column>
      <Column>
        {link ? (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            onClick={onClick}
          >
            {label}
          </Link>
        ) : (
          <Typography component="span">{emptyLabel}</Typography>
        )}
      </Column>
    </Columns>
  );
}

interface CarrierDocumentationProps {
  carrier: CarrierFullInfo;
}

export function CarrierDocumentation({ carrier }: CarrierDocumentationProps) {
  const hasUSDOT = isCarrierHasValidUSDOT(carrier);
  const autoTowing =
    carrier.logistics_type === 'AUTO_HAULER' ||
    carrier.logistics_type === 'TOWING';
  const driveaway = carrier.logistics_type === 'DRIVEAWAY';

  return (
    <Stack space="xsmall">
      <DocumentLink
        isVerified={carrier.is_verified}
        link={carrier.w9}
        aria-label="carrier w9 certificate"
        label="W9 Cert"
        emptyLabel="No W9 Cert"
        onClick={() => trackEvent('[STMS] Clicked Carrier W9 Certificate')}
      />
      {hasUSDOT && autoTowing && (
        <DocumentLink
          isVerified={carrier.is_verified}
          link={carrier.usdot_certificate}
          aria-label="carrier usdot certificate"
          label="USDOT Cert"
          emptyLabel="No USDOT Cert"
          onClick={() => trackEvent('[STMS] Clicked Carrier USDOT Certificate')}
        />
      )}
      {hasUSDOT && driveaway && (
        <>
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.usdot_certificate}
            aria-label="carrier usdot certificate"
            label="USDOT Cert"
            emptyLabel="No USDOT Cert"
            onClick={() =>
              trackEvent('[STMS] Clicked Carrier USDOT Certificate')
            }
          />
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.transporter_plate_registration}
            aria-label="transporter plate registration"
            label="Transporter Plate Registration"
            emptyLabel="No Transporter Plate Registration"
            onClick={() =>
              trackEvent(
                '[STMS] Clicked Carrier Transporter Plate Registration',
              )
            }
          />
        </>
      )}
      {!hasUSDOT && autoTowing && (
        <DocumentLink
          isVerified={carrier.is_verified}
          link={carrier.state_motor_carrier_permit}
          aria-label="state motor carrier permit"
          label="State Motor Carrier Permit"
          emptyLabel="No State Motor Carrier Permit"
          onClick={() =>
            trackEvent('[STMS] Clicked Carrier State Motor Carrier Permit')
          }
        />
      )}
      {!hasUSDOT && driveaway && (
        <>
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.transporter_plate_registration}
            aria-label="transporter plate registration"
            label="Transporter Plate Registration"
            emptyLabel="No Transporter Plate Registration"
            onClick={() =>
              trackEvent(
                '[STMS] Clicked Carrier Transporter Plate Registration',
              )
            }
          />
          <DocumentLink
            isVerified={carrier.is_verified}
            link={carrier.business_license}
            aria-label="business license"
            label="Business License"
            emptyLabel="No Business License"
            onClick={() =>
              trackEvent('[STMS] Clicked Carrier Business License')
            }
          />
        </>
      )}
      <DocumentLink
        isVerified={carrier.is_verified}
        link={carrier.cargo_insurance}
        aria-label="carrier cargo insurance"
        label="Cargo Insurance"
        emptyLabel="No Cargo Insurance"
        onClick={() => trackEvent('[STMS] Clicked Carrier Cargo Insurance')}
      />
    </Stack>
  );
}
