import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { useUserState } from 'shared/data/AppUserState';
import { useCarriersCache } from './ManageCarriersAPI';
import {
  PrivateNetworkGroupsDTO,
  privateNetworkGroupsSchema,
  PrivateNetworkPostedStatsDTO,
  privateNetworkPostedStatsSchema,
} from './PrivateNetworkDTO';

export function usePrivateNetworkCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidatePrivateNetwork() {
      void queryClient.invalidateQueries('private-network');
    }

    return { invalidatePrivateNetwork };
  }, [queryClient]);
}

export function usePrivateNetworkGroups() {
  const { user } = useUserState();
  const { requestResource } = useAPI();
  return useAPIQuery<PrivateNetworkGroupsDTO>(
    ['private-network', 'groups'],
    () =>
      requestResource('GET /internal/private-network-groups', (data) => {
        const response = privateNetworkGroupsSchema.cast(data);
        response.groups.sort((a, b) => a.name.localeCompare(b.name));

        return response;
      }),
    {
      staleTime: Infinity,
      enabled: !!user?.shipper.is_private_network_enabled,
    },
  );
}

export function usePrivateNetworkPostedCount(groupGuid: string) {
  const { requestResource } = useAPI();
  return useAPIQuery<PrivateNetworkPostedStatsDTO>(
    ['private-network', 'posted-stats'],
    () =>
      requestResource(
        `GET /internal/private-network-groups/${groupGuid}/load-stats`,
        (data) => privateNetworkPostedStatsSchema.cast(data),
      ),
    { staleTime: 0, cacheTime: 0 },
  );
}

export function usePrivateNetworkAPIs() {
  const { request } = useAPI();
  const { invalidateCarriers } = useCarriersCache();
  const { invalidatePrivateNetwork } = usePrivateNetworkCache();

  return useMemo(
    () => ({
      addCarrierToPrivateNetworkGroups: (
        carrierGuids: string[],
        groupGuids: string[],
      ) =>
        request('POST /internal/private-network-groups/carriers/bulk_add', {
          json: { carrier_guids: carrierGuids, group_guids: groupGuids },
        }).then(() => {
          invalidateCarriers();
          invalidatePrivateNetwork();
        }),
      addCarrierToPrivateNetwork: (carrierGuids: string[]) =>
        request('POST /internal/carriers/private-network/bulk_add', {
          json: { carrier_guids: carrierGuids },
        }).then(() => {
          invalidateCarriers();
          invalidatePrivateNetwork();
        }),

      createPrivateNetworkGroup(name: string) {
        return request('POST /internal/private-network-groups', {
          json: { name },
        }).then(() => {
          invalidatePrivateNetwork();
        });
      },
      renamePrivateNetworkGroup(name: string, groupGuid: string) {
        return request(`PUT /internal/private-network-groups/${groupGuid}`, {
          json: { name },
        }).then(() => {
          invalidatePrivateNetwork();
        });
      },
      deletePrivateNetworkGroup(groupGuid: string) {
        return request(
          `DELETE /internal/private-network-groups/${groupGuid}`,
        ).then(() => {
          invalidatePrivateNetwork();
        });
      },

      removeCarrierFromPrivateNetwork: (carrierGuids: string[]) =>
        request('POST /internal/carriers/private-network/bulk_remove', {
          json: { carrier_guids: carrierGuids },
        }).then(() => {
          invalidateCarriers();
          invalidatePrivateNetwork();
        }),
      removeCarrierFromPrivateNetworkGroup: (
        groupGuid: string,
        carrierGuids: string[],
      ) =>
        request(
          'POST /internal/private-network-groups/{groupGuid}/carriers/bulk_remove',
          {
            groupGuid,
            json: { carrier_guids: carrierGuids },
          },
        ).then(() => {
          invalidateCarriers();
          invalidatePrivateNetwork();
        }),
    }),
    [invalidateCarriers, invalidatePrivateNetwork, request],
  );
}
