import { useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useCallback } from 'react';

interface SnackbarNotificationOptions {
  key?: string;
  body: string;
  actionText: string;
  onClick: () => void;
  onClose: () => void;
}

export function useSnackbarNotification() {
  const { addSnackbar } = useSnackbarStack();

  return useCallback(
    (
      title: string,
      { key, body, actionText, onClick, onClose }: SnackbarNotificationOptions,
    ) =>
      addSnackbar(
        <Box paddingRight="small">
          <b>{title}</b>
          <br />
          {body}
        </Box>,
        {
          key,
          onClose,
          autoHideDuration: 1000000,
          ContentProps: { style: { maxWidth: 660, width: 'auto' } },
          action: (
            <Button variant="inverted" size="small" onClick={onClick}>
              {actionText}
            </Button>
          ),
        },
      ),
    [addSnackbar],
  );
}
