import {
  NavigateFunction,
  NavigateOptions,
  unstable_usePrompt,
  useNavigate,
} from 'react-router-dom';
import { ArgumentsType } from 'vitest';

type PromptProps = ArgumentsType<typeof unstable_usePrompt>[0];

export function Prompt({ when, message }: PromptProps) {
  unstable_usePrompt({
    message,
    when: 'Cypress' in window ? false : when,
  });

  return null;
}

/**
 * Same as useNavigate, but the navigation is deferred to the next event loop
 * tick.
 *
 * It should be used in cases where <Prompt> is triggered unexpectedly after a
 * programmatic navigation due to stale `when` prop value.
 *
 * This is a temporary solution, and should be removed in favor of default
 * navigate function when the issue is resolved in a non-hacky way.
 */
export function usePromptNavigate(): NavigateFunction {
  const navigate = useNavigate();

  return (to, options?) => {
    setTimeout(() => {
      if (typeof to === 'number') {
        navigate(to);
      } else {
        navigate(to, options as NavigateOptions);
      }
    }, 0);
  };
}
