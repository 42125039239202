import { Stack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { EmptyRatingIcon } from 'shared/icons/EmptyRatingIcon';

interface Props {
  onRate: () => void;
  showRateButton: boolean;
}

export function CarrierRatingsEmpty({ onRate, showRateButton }: Props) {
  return (
    <Stack space="small" align="center">
      <EmptyRatingIcon />

      <Stack align="center">
        <TextBox variant="heading-3" color="secondary">
          No ratings
        </TextBox>
        {showRateButton && (
          <Stack space="none" align="center">
            <TextBox color="secondary">
              Be the first to share your experience
            </TextBox>
            <TextBox color="secondary">
              with other Super Dispatch users.
            </TextBox>
          </Stack>
        )}
      </Stack>
      {showRateButton && (
        <Button variant="primary" onClick={onRate}>
          Rate Carrier
        </Button>
      )}
    </Stack>
  );
}
