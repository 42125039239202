import {
  lazyAndStripEmpty,
  yupBoolean,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CounterpartyContactDTO = InferType<typeof counterpartyContactDTO>;

export const counterpartyContactDTO = yupObject({
  id: lazyAndStripEmpty(),
  guid: lazyAndStripEmpty(),
  email: yupString().nullable(),
  mobile_phone: yupString().nullable(),
  name: yupString(),
  phone: yupString().nullable(),
  phone_extension: yupString().nullable().optional(),
  title: yupString().nullable(),
  bill_the_delivery: yupBoolean().optional().strip(true),
  is_primary: yupBoolean().optional(),
});
