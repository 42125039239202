import { Card, CardContent, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikDateField,
  FormikRadioGroupField,
  FormikTextField,
} from '@superdispatch/forms';
import {
  CheckboxField,
  Column,
  Columns,
  RadioField,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { formatStatus } from 'core/dictionary/StatusAutocomplete';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { BrokerUser } from 'shared/data/UserPermissions';
import { FormikCheckboxController } from 'shared/form/FormikCheckboxController';
import {
  composeValidators,
  emails,
  required,
} from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { ReportFilterField } from './ReportFilterField';
import {
  getPaymentStatus,
  paymentStatuses,
  useReportsStatuses,
} from './ReportsStatus';

const StyledCard = styled(Card)`
  overflow: visible;
`;

export function ReportsFilters() {
  const { user } = useUserState();
  const deliveryID = useUID();
  const pickupID = useUID();
  const createDateID = useUID();
  const statuses = useReportsStatuses();

  const buildLoadsEnabled = useFeatureToggle('build-loads.enabled.ui');

  return (
    <StyledCard>
      <CardContent>
        <Stack space="large">
          <Stack space="small">
            <Typography variant="h5">Send Report to</Typography>
            <FormikTextField
              name="send_report_to"
              label="Email Addresses"
              fullWidth={true}
              validate={composeValidators(required, emails)}
              helperText="Separate multiple emails with comma."
            />
          </Stack>

          <Stack aria-labelledby={createDateID} space="small">
            <Typography id={createDateID} variant="h5">
              Filter by Creation Date
            </Typography>
            <Stack space="small">
              <Columns space="xsmall" collapseBelow="desktop">
                <Column>
                  <FormikDateField
                    name="created_begin"
                    label="Start"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>

                <Column>
                  <FormikDateField
                    name="created_end"
                    label="End"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>
              </Columns>
            </Stack>
          </Stack>

          <BrokerUser>
            <Stack space="small">
              <Typography variant="h5">Filter by Accepted Date</Typography>
              <Columns space="xsmall" collapseBelow="desktop">
                <Column>
                  <FormikDateField
                    name="accepted_begin"
                    label="Start"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>

                <Column>
                  <FormikDateField
                    name="accepted_end"
                    label="End"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>
              </Columns>
            </Stack>
          </BrokerUser>

          <Stack space="small" aria-labelledby={pickupID}>
            <Typography id={pickupID} variant="h5">
              Filter by Scheduled Pickup Date
            </Typography>
            <Stack space="large">
              <Columns space="xsmall" collapseBelow="desktop">
                <Column>
                  <FormikDateField
                    name="scheduled_pickup_begin"
                    label="Start"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>
                <Column>
                  <FormikDateField
                    name="scheduled_pickup_end"
                    label="End"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>
              </Columns>
              <Typography variant="h5">Filter by Picked up on Date</Typography>
              <Stack space="small" aria-label="Filter by Picked up on Date">
                <Columns space="xsmall" collapseBelow="desktop">
                  <Column>
                    <FormikDateField
                      name="pickup_begin"
                      label="Start"
                      fullWidth={true}
                      enableClearable={true}
                    />
                  </Column>
                  <Column>
                    <FormikDateField
                      name="pickup_end"
                      label="End"
                      fullWidth={true}
                      enableClearable={true}
                    />
                  </Column>
                </Columns>
              </Stack>
            </Stack>
          </Stack>

          <Stack space="small" aria-labelledby={deliveryID}>
            <Typography id={deliveryID} variant="h5">
              Filter by Scheduled Delivery Date
            </Typography>
            <Stack space="large">
              <Columns space="xsmall" collapseBelow="desktop">
                <Column>
                  <FormikDateField
                    name="scheduled_delivery_begin"
                    label="Start"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>
                <Column>
                  <FormikDateField
                    name="scheduled_delivery_end"
                    label="End"
                    fullWidth={true}
                    enableClearable={true}
                  />
                </Column>
              </Columns>
              <Typography id={deliveryID} variant="h5">
                Filter by Delivered on Date
              </Typography>
              <Stack space="small" aria-label="Filter by Delivered on Date">
                <Columns space="xsmall" collapseBelow="desktop">
                  <Column>
                    <FormikDateField
                      name="delivery_begin"
                      label="Start"
                      fullWidth={true}
                      enableClearable={true}
                    />
                  </Column>
                  <Column>
                    <FormikDateField
                      name="delivery_end"
                      label="End"
                      fullWidth={true}
                      enableClearable={true}
                    />
                  </Column>
                </Columns>
              </Stack>
            </Stack>
          </Stack>

          <FormikRadioGroupField name="order_by" label="Sort by">
            <RadioField label="Creation Date" value="creation_date" />
            <RadioField label="Carrier Delivery Date" value="delivery_date" />
            <RadioField label="Carrier Pickup Date" value="pickup_date" />
          </FormikRadioGroupField>

          <BrokerUser>
            {buildLoadsEnabled && (
              <FormikRadioGroupField name="order_type" label="Filter by">
                <RadioField label="Orders" value="ORDERS" />
                <RadioField label="Loads" value="LOADS" />
                <RadioField label="All" value="ALL" />
              </FormikRadioGroupField>
            )}
          </BrokerUser>

          <BrokerUser>
            <FormikRadioGroupField
              name="include"
              label="Active/Archived Status"
            >
              <RadioField label="Active" value="active" />
              <RadioField label="Archived" value="archived" />
              <RadioField label="All" value="all" />
            </FormikRadioGroupField>
          </BrokerUser>

          <Stack space="small">
            <Typography variant="h5">Status</Typography>

            <Stack space="xsmall">
              <FormikCheckboxController
                name="statuses"
                fields={statuses}
                render={(props) => <CheckboxField {...props} label="All" />}
              />

              {statuses.map((status) => (
                <FormikCheckboxField
                  key={status}
                  label={formatStatus(status)}
                  name={`statuses.${status}`}
                />
              ))}
            </Stack>

            {user?.shipper.had_superpay_access && (
              <>
                <Typography variant="h6" color="textSecondary">
                  Payment Status
                </Typography>
                <Stack space="xsmall">
                  <FormikCheckboxController
                    name="payment_statuses"
                    fields={paymentStatuses}
                    render={(props) => <CheckboxField {...props} label="All" />}
                  />

                  {paymentStatuses.map((status) => (
                    <FormikCheckboxField
                      key={status}
                      label={getPaymentStatus(status)}
                      name={`payment_statuses.${status}`}
                    />
                  ))}
                </Stack>
              </>
            )}
          </Stack>

          <ReportFilterField />
        </Stack>
      </CardContent>
    </StyledCard>
  );
}
