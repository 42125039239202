export function getInitials(
  name: string,
  { delimiter = ' ', maxLength = 2 } = {},
) {
  return name
    .trim()
    .split(delimiter)
    .slice(0, maxLength)
    .map((x) => x.charAt(0))
    .join('')
    .toUpperCase();
}

export function joinWords(...args: Array<string | null | undefined | boolean>) {
  const words = args.filter(Boolean) as string[];

  if (words.length > 2) {
    return words.slice(0, -1).join(', ') + ', and ' + words.slice(-1).join();
  }

  return words.join(' and ');
}
