import { Representative } from '@moovio/moov-js';

//When updating representative info using the method provided by Moov.js, getting 403 error.
//Reason might be it triggers PUT api call instead of PATCH api call mentioned in the official documentation.
//Until this issues is solved on Moov.js side, we are directly calling update API (PATCH)
export class MoovAPI {
  async editRepresentative(
    scopeToken: string,
    accountID: string,
    representativeID: string,
    data: Partial<Representative>,
  ) {
    const response = await fetch(
      `https://api.moov.io/accounts/${accountID}/representatives/${representativeID}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${scopeToken}`,
        },
        body: JSON.stringify(data),
      },
    );
    return response;
  }
}
