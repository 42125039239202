import { get } from 'lodash-es';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import { customerDTO, CustomerDTO } from 'shared/dto/CustomerDTO';
import { terminalDTO, TerminalDTO } from 'shared/dto/TerminalDTO';

interface FetchCustomersParams {
  query?: string;
  size?: number;
  sort?: string;
  withContacts?: boolean;
}

export function useCustomerList({
  query,
  sort,
  withContacts = true,
}: FetchCustomersParams = {}) {
  const { requestPage } = useAPI();
  return useAPIPageQuery<CustomerDTO>(
    ['customers', 'list', { query }],
    () => {
      return requestPage(
        '/internal/customers{?query,size,sort,withContacts}',
        (data) => customerDTO.cast(data),
        { sort, query, size: 20, withContacts },
      );
    },
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
    },
  );
}

export function useCustomersWithTerminalList({
  query,
}: FetchCustomersParams = {}) {
  const { requestResource } = useAPI();

  return useAPIQuery<{
    customers: CustomerDTO[];
    terminals: TerminalDTO[];
  }>(
    ['customers', 'searchWithTerminals', { query }],
    () => {
      return requestResource(
        '/internal/customers/searchWithTerminals{?query,size}',
        (data) => {
          const customers = get(data, 'customers') as CustomerDTO[] | null;
          const terminals = get(data, 'terminals') as TerminalDTO[] | null;
          return {
            customers: customers?.map((el) => customerDTO.cast(el)) || [],
            terminals: terminals?.map((el) => terminalDTO.cast(el)) || [],
          };
        },
        { query, size: 30 },
      );
    },
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
    },
  );
}
