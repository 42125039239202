import { Box, BoxProps, Paper, Typography } from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import * as React from 'react';
import { ReactNode } from 'react';

interface PaperBoxProps extends Omit<BoxProps, 'title'> {
  title?: ReactNode;
}

export const PaperBox: React.ComponentType<PaperBoxProps> = ({
  children,
  title,
  ...props
}) => {
  const uid = useUID();

  return (
    <Box p={2} component={Paper} aria-labelledby={uid} {...props}>
      {typeof title === 'string' ? (
        <Box id={uid} mb={2}>
          <Typography variant="h3">{title}</Typography>
        </Box>
      ) : (
        <Box id={uid}>{title}</Box>
      )}

      {children}
    </Box>
  );
};
