import {
  NormalizedPermission,
  NormalizedPermissions,
  RolePermissionDTO,
  UserRoleDTO,
} from 'shared/types/permission';

const CREATE = 1; //1
const READ = 1 << 1; //2
const UPDATE = 1 << 2; //4
const DELETE = 1 << 3; //8
const EXECUTE = 1 << 4; //16

const canCreate = (mask: number) => (mask & CREATE) > 0;
const canRead = (mask: number) => (mask & READ) > 0;
const canUpdate = (mask: number) => (mask & UPDATE) > 0;
const canDelete = (mask: number) => (mask & DELETE) > 0;
const canExecute = (mask: number) => (mask & EXECUTE) > 0;

const toPermission = (mask: number, permission?: NormalizedPermission) => {
  if (permission) {
    permission.canCreate = permission.canCreate || canCreate(mask);
    permission.canRead = permission.canRead || canRead(mask);
    permission.canUpdate = permission.canUpdate || canUpdate(mask);
    permission.canDelete = permission.canDelete || canDelete(mask);
    permission.canExecute = permission.canExecute || canExecute(mask);

    return permission;
  }
  return {
    canCreate: canCreate(mask),
    canRead: canRead(mask),
    canUpdate: canUpdate(mask),
    canDelete: canDelete(mask),
    canExecute: canExecute(mask),
  };
};

const normalizeRoles = (
  userRoles: UserRoleDTO[],
): Partial<NormalizedPermissions> => {
  const merged: RolePermissionDTO[] = [];
  const normalized = {};

  userRoles.forEach((userRole: UserRoleDTO) => {
    merged.push(...userRole.role.permissions);
  });

  merged.forEach((permission: RolePermissionDTO) => {
    const item = normalized[permission.id.permission_object];
    normalized[permission.id.permission_object] = toPermission(
      permission.mask,
      item,
    );
  });

  return normalized;
};

export default normalizeRoles;
