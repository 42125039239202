import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIListQuery } from 'shared/api/APIListQuery';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import { valuesToPlain } from 'shared/utils/DataUtils';
import { yupBoolean, yupEnum, yupObject } from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import {
  CentralIntegrationFormDTO,
  Referral,
  ShipperProfileDTO,
} from './ShipperProfileDTO';
import {
  ProfileUnratedCarrierDTO,
  ShipperRatingDTO,
  ShipperRatingTagStatsDTO,
} from './ShipperRatingDTO';

export function useShippersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateShippers() {
      void queryClient.invalidateQueries('shippers');
    }
    function invalidateShipperRatings() {
      void queryClient.invalidateQueries(['shippers', 'ratings']);
    }
    function invalidateUnratedCarriersCount() {
      void queryClient.invalidateQueries(['ratings', 'unrated-carriers-count']);
    }
    return {
      invalidateShippers,
      invalidateShipperRatings,
      invalidateUnratedCarriersCount,
    };
  }, [queryClient]);
}

export function useShipperProfile(
  options?: UseAPIQueryOptions<ShipperProfileDTO>,
) {
  const { requestResource } = useAPI();
  return useAPIQuery(
    ['shippers', 'profile'],
    () =>
      requestResource('GET /internal/shippers', (data) =>
        plainToClass(ShipperProfileDTO, data),
      ),
    options,
  );
}

export function useShipperRatings(page: number) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(['shippers', 'ratings', { page }], () =>
    requestPage(
      'GET /internal/shippers/ratings{?size,page}',
      (data) => plainToClass(ShipperRatingDTO, data),
      { page, size: 10 },
    ),
  );
}
export function useUnratedCarriers() {
  const { requestPage } = useAPI();
  const cache = useShippersCache();

  return useAPIListQuery(
    ['ratings', 'unrated-carriers'],
    (page) =>
      requestPage(
        'GET /internal/carriers/unrated{?page,size}',
        (data) => plainToClass(ProfileUnratedCarrierDTO, data),
        { page, size: 10 },
      ).then((response) => {
        cache.invalidateUnratedCarriersCount();
        return response;
      }),
    {},
  );
}

export function useShipperRatingTagStats() {
  const { requestResource } = useAPI();
  return useAPIQuery(['shippers', 'ratings'], () =>
    requestResource('GET /internal/shippers/ratings/statistics', (data) =>
      plainToClass(ShipperRatingTagStatsDTO, data),
    ),
  );
}

export function useShipperRatingResponse() {
  const cache = useShippersCache();
  const { request } = useAPI();
  return useMemo(
    () => ({
      updateRatingResponse: (
        ratingGuid: string,
        values: { response: string },
      ) =>
        request(`PUT /internal/shippers/ratings/${ratingGuid}/response`, {
          json: values,
        }).then((resp) => {
          cache.invalidateShipperRatings();
          return resp;
        }),
      deleteRatingResponse: (ratingGuid: string) =>
        request(
          `DELETE /internal/shippers/ratings/${ratingGuid}/response`,
        ).then((response) => {
          cache.invalidateShipperRatings();
          return response;
        }),
    }),
    [request, cache],
  );
}

export function useShipperProfileAPI() {
  const cache = useShippersCache();
  const { request } = useAPI();

  return useMemo(
    () => ({
      updatePaymentInfo: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/payment_info', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateInternalUserInfo: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/internal_use_info', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateLoadboardSettings: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/loadboard_settings', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateCentralDispatchInfo: (values: CentralIntegrationFormDTO) =>
        request('PUT /internal/shippers/central_dispatch_info', {
          json: values,
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateTermsAndConditions: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/terms_and_conditions', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateAboutsUs: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/about_us', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateContactInfo: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/contact_info', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateAdditionalCarrierRequirements: (
        values: Partial<ShipperProfileDTO>,
      ) =>
        request('PUT /internal/shippers/carrier_requirements', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateBondInsurance: (values: ShipperProfileDTO) =>
        request('PUT /internal/shippers/bond_info', {
          json: valuesToPlain(ShipperProfileDTO, values),
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      updateReferrals: (values: Referral[]) =>
        request('PUT /internal/shippers/referrals', {
          json: values,
        }).then((response) => {
          cache.invalidateShippers();
          return response;
        }),
      uploadShipperProfileLogo: (file: File) => {
        const form = new FormData();
        form.append('file', file);

        return request('POST /internal/shippers/logo', { body: form }).then(
          (response) => {
            cache.invalidateShippers();
            return response;
          },
        );
      },
      removeShipperProfileLogo: () =>
        request('DELETE /internal/shippers/logo').then((response) => {
          cache.invalidateShippers();
          return response;
        }),
    }),
    [request, cache],
  );
}

type ImportCDStatusDTO = InferType<typeof importCDStatusSchema>;
const importCDStatusSchema = yupObject({
  status: yupEnum(['NEW', 'ACTIVE', 'FAILED', 'SUCCESS'], 'NEW'),
  can_request: yupBoolean(),
});

export function useImportAndPostCDLoadsStatus() {
  const { requestResource } = useAPI();
  return useAPIQuery(['shipper', 'cd-import'], () =>
    requestResource<ImportCDStatusDTO>(
      'GET /internal/shippers/central-dispatch/import-post-listings/status',
      (data) => importCDStatusSchema.cast(data),
    ),
  );
}

export function useImportAndPostCDLoadsMutation(options: APIMutationOptions) {
  const { request } = useAPI();
  const client = useQueryClient();

  return useAPIMutation(
    () =>
      request(
        'POST /internal/shippers/central-dispatch/import-post-listings',
      ).then(() => {
        client.setQueryData(['shipper', 'cd-import'], {
          status: 'ACTIVE',
          can_request: false,
        });
      }),
    options,
  );
}
