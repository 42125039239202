import { Typography } from '@material-ui/core';
import { Color, Inline } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { PasswordStrength } from 'shared/utils/PasswordUtils';
import styled from 'styled-components';
import { TickDoneIcon } from '../../auth/assets/TickDoneIcon';

const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${Color.Blue300};
  border-radius: 100px;
`;

const TickBox = styled(Box)`
  width: 13.33px;
  height: 13.33px;
  border-radius: 15px;
  background-color: ${Color.Blue75};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckPasswordItemText = styled(Typography)<{ isDone?: boolean }>`
  text-decoration: ${({ isDone }) => (isDone ? 'line-through' : 'inherit')};
  color: ${({ isDone }) => (isDone ? Color.Dark100 : Color.Dark500)};
  margin-left: 6px;
`;

export function CheckPasswordItem({
  isDone,
  text,
}: {
  isDone: boolean;
  text: string;
}) {
  return (
    <ListItem>
      <Box minWidth="16px">
        <Inline verticalAlign="center" horizontalAlign="center">
          {isDone ? (
            <TickBox>
              <TickDoneIcon />
            </TickBox>
          ) : (
            <Dot />
          )}
        </Inline>
      </Box>
      <CheckPasswordItemText isDone={isDone} variant="body2">
        {text}
      </CheckPasswordItemText>
    </ListItem>
  );
}

export const Stepper = styled.div`
  height: 5px;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 4px;
`;

export const StepperItem = styled.div<{
  isActive: boolean;
  passwordStrength?: PasswordStrength;
}>`
  height: 2px;
  background-color: ${({ isActive, passwordStrength }) =>
    getStepperItemColor(isActive, passwordStrength)};
  flex: 1;
  border-radius: 100px;
  &:not(:last-child) {
    margin-right: 10px;
    flex: 1;
  }
`;

function getStepperItemColor(
  isActive: boolean,
  passwordStrength?: PasswordStrength,
) {
  if (!isActive || !passwordStrength) return Color.Silver400;

  switch (isActive) {
    case passwordStrength === 'strong':
      return Color.Green500;
    case passwordStrength === 'weak':
      return Color.Red400;
    case passwordStrength === 'average':
      return Color.Yellow500;
    case passwordStrength === 'good':
      return Color.Green500;
    default:
      return Color.Silver400;
  }
}
