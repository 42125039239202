import { Link, Typography } from '@material-ui/core';
import { Mail, Person, Phone } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { InfoCard, Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { FaxIcon } from 'shared/icons/FaxIcon';
import { CarrierFullInfo } from 'shared/types/carrier';

interface CarrierContactInfoProps {
  carrier: CarrierFullInfo;
}

export function CarrierContactInfo({ carrier }: CarrierContactInfoProps) {
  const hasContactInfo =
    carrier.contact_name ||
    carrier.email ||
    carrier.phone_numbers ||
    carrier.fax;

  return (
    <InfoCard size="large">
      <Stack space="small">
        <Typography variant="h3">Contact Info</Typography>

        {hasContactInfo ? (
          <Stack space="xxsmall">
            {carrier.contact_name && (
              <DescriptionItem icon={<Person />}>
                <Typography aria-label="carrier contact name">
                  {carrier.contact_name}
                </Typography>
              </DescriptionItem>
            )}
            {carrier.email && (
              <DescriptionItem icon={<Mail />}>
                <Link
                  href={`mailto:${carrier.email}`}
                  aria-label="carrier email"
                  onClick={() => {
                    trackEvent('Shipper Clicked Carrier Contact Info', {
                      contact_type: 'email',
                    });
                  }}
                >
                  {carrier.email}
                </Link>
              </DescriptionItem>
            )}
            {carrier.phone_numbers && (
              <DescriptionItem icon={<Phone />}>
                <SuspendedPhoneLink
                  aria-label="carrier phone number"
                  phone={carrier.phone_numbers}
                  fallback={carrier.phone_numbers}
                  onClick={() => {
                    trackEvent('Shipper Clicked Carrier Contact Info', {
                      contact_type: 'phone',
                    });
                  }}
                />
              </DescriptionItem>
            )}
            {carrier.fax && (
              <DescriptionItem icon={<FaxIcon />}>
                <SuspendedPhoneLink
                  aria-label="carrier fax"
                  phone={carrier.fax}
                  fallback={carrier.fax}
                />
              </DescriptionItem>
            )}
          </Stack>
        ) : (
          <Typography color="textSecondary">No Information Provided</Typography>
        )}
      </Stack>
    </InfoCard>
  );
}
