import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  FormikCurrencyField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Button, Inline, Stack, useUID } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import APIErrorAlert from 'shared/api/utils/APIErrorAlert';
import { useUserState } from 'shared/data/AppUserState';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { DecodedOrderVehicleDTO } from '../data/DecodedOrderVehicleDTO';
import { useVehicleAPI } from '../data/OrderVehicleAPI';

function parseVins(text: string) {
  const splitChars = /\r|\n|\s|,/g;
  const vins = text.split(splitChars).map((vin) => vin.trim());
  return Array.from(new Set(vins.filter((vin) => vin.length >= 16)));
}

export interface BulkVinDecodeProps {
  isOpen: boolean;
  onCancel: () => void;
  onDecoded: (
    uniqueVins: string[],
    decodedVehicles: DecodedOrderVehicleDTO[],
  ) => void;
}

export function BulkVinDecode({
  isOpen,
  onCancel,
  onDecoded,
}: BulkVinDecodeProps) {
  const uid = useUID();
  const { user } = useUserState();
  const { bulkDecode } = useVehicleAPI();

  const form = useFormikEnhanced<
    { vin_numbers: string; tariff?: number; price?: number },
    { uniqueVins: string[]; decodedVehicles: DecodedOrderVehicleDTO[] }
  >({
    validateOnMount: true,
    initialValues: { vin_numbers: '' },
    onSubmit({ vin_numbers, price, tariff }) {
      const uniqueVins = parseVins(vin_numbers);

      return bulkDecode(uniqueVins).then((decodedVehicles) => {
        return {
          uniqueVins,
          decodedVehicles: decodedVehicles.map((x) => ({
            ...x,
            price,
            tariff,
          })),
        };
      });
    },
    onSubmitSuccess({ uniqueVins, decodedVehicles }, { price, tariff }) {
      if (price != null || tariff != null) {
        trackEventLegacy('Added Prices per Vehicle in Bulk Decode', {
          price,
          tariff,
          vehicleCount: decodedVehicles.length,
        });
      }
      onDecoded(uniqueVins, decodedVehicles);
      form.resetForm();
    },
    onSubmitFailure(error) {
      APIErrorAlert(error);
    },
  });

  function onClose() {
    form.resetForm();
    onCancel();
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby={uid}
      onClose={form.isSubmitting ? undefined : onClose}
    >
      <FormikProvider value={form}>
        <Form>
          <DialogTitle disableTypography={true}>
            <Typography id={uid} variant="h3">
              Bulk VIN Decode
            </Typography>
          </DialogTitle>

          <DialogContent style={{ overflowY: 'initial' }}>
            <Stack space="small">
              <FormikTextField
                rows={5}
                autoFocus={true}
                name="vin_numbers"
                label="VIN Codes (Required)"
                multiline={true}
                fullWidth={true}
                validate={(value) =>
                  !value || value.length <= 16 ? 'Invalid vin' : undefined
                }
                placeholder="To bulk decode, please enter individual VINs on each line or separate VINs with a comma (,)"
              />

              {user?.shipper.shipper_type === 'BROKER' && (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <FormikCurrencyField
                      name="tariff"
                      fullWidth={true}
                      label="Tariff per vehicle"
                      inputProps={{
                        decimalScale: 2,
                        fixedDecimalScale: true,
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <FormikCurrencyField
                      name="price"
                      fullWidth={true}
                      label="Carrier Price per vehicle"
                      inputProps={{
                        decimalScale: 2,
                        fixedDecimalScale: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Inline space="xsmall" horizontalAlign="right">
              <Button
                color="primary"
                variant="outlined"
                type="button"
                disabled={form.isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                disabled={!form.isValid}
                isLoading={form.isSubmitting}
                color="primary"
                variant="contained"
                type="submit"
              >
                Decode
              </Button>
            </Inline>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
