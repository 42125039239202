import { useEffect } from 'react';
import { DictionaryKey, formatDictionary } from './data/DictionaryDTO';
import { InputControl, InputControlProps } from './InputControl';

interface Props extends Omit<InputControlProps, 'label'> {
  type: DictionaryKey;
  value?: string[];
  onChange: (value: string[]) => void;
}

export function SingleBooleanFilter(props: Props) {
  const { type, value, onChange, onSelect, ...rest } = props;
  useEffect(() => {
    if (!value) {
      // This component doesn't provide options, so we simulate selection
      onChange([String(true)]);
      onSelect();
    }
  }, [onChange, onSelect, value]);

  return (
    <InputControl
      {...rest}
      active={false}
      label={formatDictionary(type)}
      onSelect={onSelect}
    />
  );
}
