import { Grid, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { FC, memo, useEffect, useState } from 'react';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInput } from 'shared/helpers/ReactHelpers';

export interface CounterpartyAddressProps {
  name: string;
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) => void;
}

export const CounterpartyAddress: FC<CounterpartyAddressProps> = memo(
  ({ name, disabled = false, setFieldValue }) => {
    const [input, setInput] = useState('');
    const query = useSearchInput(input);
    const { isFetching, data: places = [] } = usePredictions(query);

    useEffect(() => {
      if (places.length > 0) {
        setFieldValue(`${name}.city`, places[0]?.place);
        setFieldValue(`${name}.state`, places[0]?.region_short);

        const [step] = name.split('.');
        if (step) {
          setFieldValue(`${step}.latitude`, places[0]?.latitude);
          setFieldValue(`${step}.longitude`, places[0]?.longitude);
        }
      }
    }, [places, name, setFieldValue]);

    function resetGeolocationFields() {
      const [step] = name.split('.');
      if (step) {
        setFieldValue(`${step}.latitude`, null);
        setFieldValue(`${step}.longitude`, null);
      }
    }

    const styleLabelText = (text: string) => (
      <Typography color="textPrimary">{text}</Typography>
    );
    return (
      <Grid
        container={true}
        spacing={2}
        wrap="wrap"
        justifyContent="space-between"
        data-intercom-target={`${name} section`}
      >
        <Grid item={true} sm={12} xs={12} lg={4}>
          <FormikTextField
            name={`${name}.address`}
            label={styleLabelText('Address')}
            fullWidth={true}
            inputProps={{
              maxLength: 500,
            }}
            disabled={disabled}
          />
        </Grid>

        <Grid item={true} xs={12} sm={12} lg={4}>
          <FormikTextField
            name={`${name}.city`}
            label={styleLabelText('City')}
            helperText={name !== 'customer' ? 'Required for LBs' : ''}
            fullWidth={true}
            inputProps={{
              maxLength: 100,
            }}
            disabled={disabled || isFetching}
            onChange={() => {
              // coordinates gets stale when city is changed
              resetGeolocationFields();
            }}
          />
        </Grid>

        <Grid item={true} xs={12} sm={12} md={6} lg={2}>
          <FormikTextField
            name={`${name}.state`}
            fullWidth={true}
            label={styleLabelText('State')}
            helperText={name !== 'customer' ? 'Required for LBs' : ''}
            disabled={disabled || isFetching}
            onChange={() => {
              // coordinates gets stale when state is changed
              resetGeolocationFields();
            }}
          />
        </Grid>

        <Grid item={true} xs={12} sm={12} md={6} lg={2}>
          <FormikTextField
            type="text"
            name={`${name}.zip`}
            fullWidth={true}
            label={styleLabelText('ZIP Code')}
            helperText={name !== 'customer' ? 'Required for LBs' : ''}
            inputProps={{
              maxLength: 10,
            }}
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length >= 3) {
                setInput(event.target.value);
              }

              return event.target.value;
            }}
          />
        </Grid>
      </Grid>
    );
  },
);

CounterpartyAddress.displayName = 'CounterpartyAddress';
