import { useMemo } from 'react';
import { useAPI } from 'shared/api/API';

export function useResetPasswordAPI() {
  const { request } = useAPI();

  return useMemo(
    () => ({
      sendResetPasswordLink: (username: string) =>
        request('POST /reset-password/send-link', {
          json: { username },
        }).then((response) => {
          return response;
        }),
      resetPassword: ({
        password,
        token,
      }: {
        password: string;
        token?: string;
      }) =>
        request('PUT /reset-password', {
          json: { password, token },
        }),
    }),
    [request],
  );
}
