import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import createGeocodingClient, {
  GeocodeFeature,
  GeocodeProperties,
  GeocodeResponse,
} from '@mapbox/mapbox-sdk/services/geocoding';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { useAPIQuery } from 'shared/api/APIQuery';

export interface MapboxGeocoding {
  place?: string;
  region?: string;
  region_short?: string;
  country?: string;
  country_short?: string;
  postcode?: string;
  latitude?: number;
  longitude?: number;
}

const ACCESS_TOKEN =
  'pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjazhwa2VhbTkxN3phM2dvMmcxZ2xwNXdvIn0.I3ydaXUdJkjnxjuRep4IBA';

const geocodingClient = createGeocodingClient({ accessToken: ACCESS_TOKEN });

type PlaceType = 'region' | 'place' | 'country' | 'postcode';

function isGeocodeFeature(value: unknown): value is GeocodeFeature {
  return get(value, 'type') === 'Feature';
}

function getPlaceType({ id }: GeocodeFeature): PlaceType | undefined {
  switch (true) {
    case id.startsWith('country'):
      return 'country';
    case id.startsWith('place'):
      return 'place';
    case id.startsWith('postcode'):
      return 'postcode';
    case id.startsWith('region'):
      return 'region';
    default:
      return undefined;
  }
}

export function parseGeocodeFeature(
  geocodeFeature: GeocodeFeature,
): MapboxGeocoding {
  const geocoding: MapboxGeocoding = {
    longitude: geocodeFeature.geometry.coordinates[0],
    latitude: geocodeFeature.geometry.coordinates[1],
  };

  geocodeFeature.context
    .concat(geocodeFeature)
    .forEach((item: GeocodeProperties | GeocodeFeature) => {
      const placeType = getPlaceType(item);
      const { short_code } = isGeocodeFeature(item) ? item.properties : item;

      if (placeType) {
        geocoding[placeType] = item.text;
      }

      if (short_code) {
        if (placeType === 'country') {
          geocoding.country_short = short_code;
        }

        if (placeType === 'region') {
          geocoding.region_short = short_code
            .replace('US-', '')
            .replace('CA-', '');
        }
      }
    });

  return geocoding;
}

export const fetchPredictions = (query = '') =>
  geocodingClient
    .forwardGeocode({
      query,
      mode: 'mapbox.places',
      autocomplete: true,
      types: ['place', 'region', 'postcode'],
      countries: ['US', 'CA'],
    })
    .send()
    .then((res: MapiResponse) => res.body as GeocodeResponse);

export function usePredictions(query: string | undefined) {
  const { data, isFetching, error } = useAPIQuery(
    ['mapbox', 'predictions', { query }],
    () => fetchPredictions(query),
    {
      refetchOnWindowFocus: false,
      enabled: !!query,
      staleTime: Infinity,
    },
  );

  return useMemo(() => {
    const places = data?.features.map((feature: GeocodeFeature) =>
      parseGeocodeFeature(feature),
    );
    return { data: places, isFetching, error };
  }, [data, isFetching, error]);
}
