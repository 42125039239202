import { DateTime } from 'luxon';

export function startOfWorkDay(date: DateTime) {
  return date.startOf('day').set({ hour: 9 });
}

export function endOfWorkDay(date: DateTime) {
  return date
    .endOf('day')
    .set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
}
