import { Box, Link, Typography } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';
import IconContactPlaceholder from './assets/selected-customers.png';

const BounceInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
`;

const Item = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  animation: ${BounceInAnimation} 500ms;

  & img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin: 80px auto;
  position: relative;
  width: 400px;
  height: 334px;

  & div:nth-of-type(1) img {
    z-index: 10;
  }

  & div:nth-of-type(2) img {
    z-index: 5;
    transform: rotate(5deg);
  }

  & div:nth-of-type(3) img {
    transform: rotate(-5deg);
  }
`;

interface CustomersSelectionProps {
  count: number;
  onClear: () => void;
}

export function CustomersSelection({
  count,
  onClear,
}: CustomersSelectionProps) {
  return (
    <Box flex={1} textAlign="center">
      <Box mt={12}>
        <Typography variant="h2">{count} customers selected</Typography>
      </Box>

      <Box mt={1}>
        <Link
          component="button"
          color="textPrimary"
          onClick={onClear}
          variant="body2"
        >
          Clear selection
        </Link>
      </Box>

      <Wrapper>
        {Array.from({ length: Math.min(count, 3) }, (_, index) => (
          <Item key={index}>
            <img alt="Contact" src={IconContactPlaceholder} />
          </Item>
        ))}
      </Wrapper>
    </Box>
  );
}
