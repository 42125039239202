import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import {
  Card,
  Collapse,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { PaywallChargebeeCard } from 'shared/errors/paywall/core/PaywallChargebeeCard';
import { ChargebeeEmptyPlaceholder } from 'shared/errors/paywall/core/PaywallPaymentForm';
import { FormikCountryField } from 'shared/form/FormikCountryField';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import { ShieldIcon } from 'shared/icons/ShieldIcon';
import { USAStateOptions } from 'shared/types/USAState';

export function PaymentDetailsCard({
  isChargebeeAvailable = false,
  cardRef,
}: {
  isChargebeeAvailable?: boolean;
  cardRef?: React.RefObject<ChargebeeComponents | null>;
}) {
  const [focus, setFocus] = useState<'number' | 'expiry' | 'cvv'>();
  const { setFieldValue } = useFormikContext();

  const [country] = useField('country');
  const [city] = useField('city');
  const [state] = useField('state');
  const [zip] = useField('zip');

  const isUSASelected = country.value === 'US';

  const input = !city.value || !state.value ? zip.value : '';
  const query = useSearchInput(input);
  const { data: places = [] } = usePredictions(query);

  useEffect(() => {
    if (!places.length) {
      return;
    }

    if (!city.value) {
      setFieldValue('city', places[0]?.place);
    }

    if (!state.value) {
      setFieldValue('state', places[0]?.region);
    }
  }, [places, city, state, setFieldValue]);

  return (
    <Card>
      <Box padding="large" width="464px">
        <Stack space="medium">
          <Typography variant="h3">Add Payment Information</Typography>
          <Stack space="small">
            <Typography variant="h5" color="textSecondary">
              Card Details
            </Typography>
            <Stack space="xsmall">
              <Columns align="top" space="xxsmall">
                <Column width="content">
                  <Box paddingTop="xxsmall">
                    <ShieldIcon fontSize="small" />
                  </Box>
                </Column>
                <Column>
                  <Typography variant="caption" color="textSecondary">
                    We use industry-standard encryption and security protocols
                    to safeguard your information.
                  </Typography>
                </Column>
              </Columns>

              <Columns align="top" space="xxsmall">
                <Column width="content">
                  <Box paddingTop="xxsmall">
                    <Check color="action" fontSize="small" />
                  </Box>
                </Column>
                <Column>
                  <Typography variant="caption" color="textSecondary">
                    We accept all major credit cards.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </Stack>

          {isChargebeeAvailable ? (
            <PaywallChargebeeCard
              ref={cardRef}
              onFocus={setFocus}
              focus={focus}
            />
          ) : (
            <ChargebeeEmptyPlaceholder />
          )}

          <Stack space="small">
            <Typography variant="h4">Billing Address</Typography>

            <FormikTextField
              label="Address Line 1"
              name="address"
              fullWidth={true}
            />

            <FormikTextField
              label={
                <Inline space="xxsmall">
                  Address Line 2
                  <Typography color="textSecondary">(Optional)</Typography>
                </Inline>
              }
              name="address_second_line"
              fullWidth={true}
            />

            <FormikTextField label="City" name="city" fullWidth={true} />

            <Collapse
              in={isUSASelected}
              mountOnEnter={true}
              unmountOnExit={true}
              timeout={300}
            >
              <Columns space="small">
                <Column width="2/3">
                  <FormikTextField
                    label="State"
                    name="state"
                    fullWidth={true}
                    select={isUSASelected}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">
                      <Typography color="textSecondary">
                        Select your billing state
                      </Typography>
                    </MenuItem>
                    {Array.from(USAStateOptions, (option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Column>

                <Column width="1/3">
                  <FormikTextField
                    name="zip"
                    parse={(event) => event.target.value.replace(/\D/g, '')}
                    label={
                      <Inline verticalAlign="center">
                        ZIP Code
                        <Tooltip
                          interactive={true}
                          placement="right"
                          title="Zip code of your card's billing address."
                        >
                          <Info fontSize="small" htmlColor={Color.Dark100} />
                        </Tooltip>
                      </Inline>
                    }
                    fullWidth={true}
                  />
                </Column>
              </Columns>
            </Collapse>

            <FormikCountryField
              name="country"
              fullWidth={true}
              TextFieldProps={{
                fullWidth: true,
                label: 'Country',
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
}
