import { useAPI } from 'shared/api/API';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import { WhoAmI } from 'shared/types/user';

export function useUser(
  token = '',
  shipperId = '',
  uniqueKey = '',
  options: UseAPIQueryOptions<WhoAmI> = {},
) {
  const { requestResource } = useAPI();

  const key = `${token}${shipperId}${uniqueKey}`;

  return useAPIQuery(
    ['auth', 'whoami', { key }],
    () => requestResource('/whoami', (data) => data as WhoAmI, { key }),
    {
      refetchOnWindowFocus: false,
      enabled: !!token,
      keepPreviousData: true,
      ...options,
    },
  );
}
