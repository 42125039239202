import {
  Card,
  CardContent,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  FormikCurrencyField,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import {
  formatPaymentMethod,
  formatPaymentTerm,
  isValidPaymentTerm,
  isValidSuperPayTerm,
} from '@superdispatch/sdk';
import { Column, Columns, Stack, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { plainToClass } from 'class-transformer';
import {
  isSuperPayStatusAvailable,
  usePaymentOptions,
} from 'core/SuperPayUtils';
import { PricingInsightsButtonWithDrawer } from 'orders/core/pricing-insights/PricingInsightsButtonWithDrawer';
import { useOrderPaymentFlags } from 'orders/data/OrderPaymentFlagsAPI';
import { ChangeEvent, FC, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { AllPaymentTerm } from 'shared/dto/Order/CarrierPaymentDTO';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import {
  SelectSuperPayHelperText,
  SelectSuperPayTermsHelperText,
  useSuperPayEnabled,
  useSuperPaySuspend,
} from 'shared/helpers/superpay/SuperPayHelpers';
import { Order } from 'shared/types/order';
import { useShipperProfile } from 'shipper-profile/data/ShipperProfileAPI';
import styled from 'styled-components';
import {
  EditSuperPayButton,
  EditSuperPayDisabledTooltip,
  EditSuperPayOrderBanner,
  EditSuperPayOrderTooltip,
} from '../EditSuperPayBanner';
import { PriceRecommendationOrderForm } from '../pricing-insights/PriceRecommendationOrderForm';
import { ScrollToTarget } from './helpers/ScrollToTarget';
import { OrderFormValues } from './OrderForm';

type sourceType = 'New Order' | 'Order Edit';

function getAvailableTermsHelper(terms?: AllPaymentTerm) {
  const availableTerms = ['5_days', '10_days', '15_days', '20_days', '30_days'];
  return !!(terms && availableTerms.includes(terms));
}

export interface HelperProps {
  source: sourceType;
  status: OrderFormValues['status'];
  payment: OrderFormValues['payment'];
  onValueChange: (field: string, value: unknown) => void;
}

const TermsHelper: FC<HelperProps> = memo(
  ({ source, status, payment, onValueChange }) => {
    const isSuperPaySuspend = useSuperPaySuspend();

    const isHiddenStatus = ['delivered', 'invoiced'].includes(status);

    if (payment?.method === 'superpay' || !payment?.terms || isHiddenStatus) {
      return null;
    }

    return !isSuperPaySuspend && getAvailableTermsHelper(payment.terms) ? (
      <SelectSuperPayTermsHelperText
        terms={payment.terms}
        source={source}
        onClick={() => {
          onValueChange('payment.method', 'superpay');
          trackEvent('Shipper Clicked Select SuperPay', {
            utm_medium: source,
            utm_content: 'Selected from Terms',
          });
        }}
      />
    ) : null;
  },
);
TermsHelper.displayName = 'TermsHelper';

const MethodHelper: FC<HelperProps> = memo(
  ({ source, status, payment, onValueChange }) => {
    const isSuperPaySuspend = useSuperPaySuspend();
    const isSuperPayEnabled = useSuperPayEnabled();
    const { data: shipper } = useShipperProfile({
      refetchOnWindowFocus: false,
    });

    const isAvailable =
      !status ||
      ['new', 'pending', 'accepted', 'declined', 'canceled'].includes(status);

    if (!isAvailable) {
      return !shipper?.default_payment_method ? (
        <Link
          to="/profile/payment-info/edit?source=order_form"
          target="_blank"
          rel="noopener noreferrer"
          component={RouterLink}
          color="textSecondary"
          variant="body2"
        >
          Set default method
        </Link>
      ) : null;
    }

    if (payment?.method === 'superpay') {
      return <>Advanced inspection will be applied</>;
    }

    return !isSuperPaySuspend &&
      !getAvailableTermsHelper(payment?.terms) &&
      isSuperPayEnabled ? (
      <SelectSuperPayHelperText
        source={source}
        onClick={() => {
          onValueChange('payment.method', 'superpay');
          onValueChange('payment.terms', '1_3_days');
          trackEvent('Shipper Clicked Select SuperPay', {
            utm_medium: source,
            utm_content: 'Selected from Method',
          });
        }}
      />
    ) : null;
  },
);
MethodHelper.displayName = 'MethodHelper';

function FieldLabel({ fieldName }: { fieldName: string }) {
  return <Typography color="textPrimary">{fieldName}</Typography>;
}

const TotalPriceFormikField = styled(FormikCurrencyField)`
  max-width: 256px;
`;

export interface CarrierPaymentProps {
  order: Partial<Order>;
  source: sourceType;
  values: OrderFormValues;
  onValueChange: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const CarrierPayment: FC<CarrierPaymentProps> = memo(
  ({ source, order, values, onValueChange }) => {
    const uid = useUID();
    const { user } = useUserState();
    const orderPaymentFlags = useOrderPaymentFlags(order.id);

    const { pickup, delivery, vehicles, payment, broker_fee, status } = values;

    const disabledSuperPayOption =
      payment?.method !== 'superpay' &&
      (status === 'invoiced' ||
        status === 'delivered' ||
        status === 'delivery_verified');

    const { paymentMethods, paymentTerms } = usePaymentOptions(
      payment?.method,
      disabledSuperPayOption,
    );

    const hasLoadMatchingAccess = useFeatureToggle(
      'price-prediction.access.ui',
    );

    const onPaymentMethodChange = ({
      target,
    }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target;

      if (value === 'superpay' && !isValidSuperPayTerm(payment?.terms)) {
        onValueChange('payment.terms', '1_3_days');
      } else if (isValidPaymentTerm(payment?.terms) && payment?.terms) {
        onValueChange('payment.terms', payment.terms);
      } else {
        onValueChange('payment.terms', 'other');
      }
    };

    return (
      <Card
        aria-labelledby={uid}
        data-intercom-target="carrier payment section"
      >
        <CardContent>
          <Stack space="small">
            <Columns space="small">
              <Column width="content">
                <Typography id={uid} variant="h3">
                  Carrier Payment
                </Typography>
              </Column>
              {hasLoadMatchingAccess && (
                <Column width="content">
                  <PricingInsightsButtonWithDrawer
                    order={{
                      ...values,
                      pickup,
                      delivery,
                      vehicles: vehicles.map((item) =>
                        plainToClass(VehicleDTO, item),
                      ),
                    }}
                  />
                </Column>
              )}
              {isSuperPayStatusAvailable(values) && (
                <Column width="content">
                  <EditSuperPayButton order={values} />
                </Column>
              )}
            </Columns>

            {orderPaymentFlags?.can_be_edited && (
              <EditSuperPayOrderBanner
                orderGuid={values.guid}
                source="payment"
              />
            )}

            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Columns space="small" align="bottom">
                  <Column width="content">
                    <EditSuperPayOrderTooltip
                      order={values}
                      source="total_price"
                    >
                      <TotalPriceFormikField
                        name="price"
                        disabled={isSuperPayStatusAvailable(values)}
                        label={
                          <Typography display="inline" color="textPrimary">
                            Total Carrier Price
                          </Typography>
                        }
                        fullWidth={true}
                      />
                    </EditSuperPayOrderTooltip>
                  </Column>

                  <Column>
                    <PriceRecommendationOrderForm
                      order={values}
                      onPriceRecommendation={(price) => {
                        onValueChange('recommended_price', price);
                      }}
                      onPriceClick={(price) => {
                        onValueChange('price', price);
                      }}
                      source={source}
                    />
                  </Column>
                </Columns>
              </Grid>

              <Grid item={true} xs={12} sm={12} md={6} xl={4}>
                <ScrollToTarget targetValue="carrier-payment-method">
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    name="payment.method"
                    disabled={isSuperPayStatusAvailable(values)}
                    label={<FieldLabel fieldName="Payment Method" />}
                    unstableOnKeyDownSelection={true}
                    helperText={
                      <MethodHelper
                        source={source}
                        status={values.status}
                        payment={values.payment}
                        onValueChange={onValueChange}
                      />
                    }
                    onChange={onPaymentMethodChange}
                  >
                    {paymentMethods.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatPaymentMethod(value)}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </ScrollToTarget>
              </Grid>

              <Grid item={true} xs={12} sm={12} md={6} xl={4}>
                <EditSuperPayDisabledTooltip
                  order={values}
                  source="payment_terms"
                >
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    defaultValue="other"
                    name="payment.terms"
                    disabled={isSuperPayStatusAvailable(values)}
                    unstableOnKeyDownSelection={true}
                    helperText={
                      <TermsHelper
                        source={source}
                        status={values.status}
                        payment={values.payment}
                        onValueChange={onValueChange}
                      />
                    }
                    label={<FieldLabel fieldName="Terms" />}
                  >
                    {paymentTerms.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatPaymentTerm(value)}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </EditSuperPayDisabledTooltip>
              </Grid>

              {(user?.order_form_settings.payment.is_broker_fee_visible ||
                broker_fee) && (
                <Grid item={true} xs={12} sm={12} md={6} xl={3}>
                  <FormikCurrencyField
                    name="broker_fee"
                    label={<FieldLabel fieldName="Due from Carrier" />}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
              )}

              {(user?.order_form_settings.payment.is_amount_visible ||
                !!payment?.amount) && (
                <Grid item={true} xs={12} sm={12} md={6} xl={3}>
                  <FormikCurrencyField
                    name="payment.amount"
                    label={<FieldLabel fieldName="Paid Amount" />}
                    fullWidth={true}
                  />
                </Grid>
              )}

              {(user?.order_form_settings.payment.is_sent_date_visible ||
                payment?.sent_date) && (
                <Grid item={true} xs={12} sm={12} md={5} xl={3}>
                  <FormikDateField
                    name="payment.sent_date"
                    fullWidth={true}
                    label={<FieldLabel fieldName="Sent Date" />}
                  />
                </Grid>
              )}

              {(user?.order_form_settings.payment.is_reference_number_visible ||
                payment?.reference_number) && (
                <Grid item={true} xs={12} sm={12} md={6} xl={3}>
                  <FormikTextField
                    name="payment.reference_number"
                    label={<FieldLabel fieldName="Reference Number" />}
                    disabled={values.payment?.method === 'superpay'}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
              )}

              <Grid item={true} xs={12}>
                <FormikTextField
                  name="payment.notes"
                  label={
                    <Columns>
                      <Column>
                        <FieldLabel fieldName="Notes" />
                      </Column>
                      <Column width="content">
                        <TextBox color="secondary">
                          {`${payment?.notes?.length || 0} of 6000`}
                        </TextBox>
                      </Column>
                    </Columns>
                  }
                  fullWidth={true}
                  multiline={true}
                  inputProps={{
                    maxLength: 6000,
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    );
  },
);

CarrierPayment.displayName = 'CarrierPayment';
