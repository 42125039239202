import { Add, Delete, Edit } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import {
  Box,
  Button,
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
  SidebarMenuItemAction,
  SidebarSubheader,
  TextBox,
} from '@superdispatch/ui-lab';
import { useState } from 'react';
import Protect from 'shared/data/UserPermissions';
import styled from 'styled-components';
import { usePrivateNetworkGroups } from '../data/PrivateNetworkAPI';
import { PrivateNetworkGroupDTO } from '../data/PrivateNetworkDTO';
import { PrivateNetworkDeleteGroupDialog } from './PrivateNetworkDeleteGroupDialog';
import { PrivateNetworkDialog } from './PrivateNetworkDialog';

const Container = styled.div`
  height: 100%;
  overflow: hidden;

  & aside {
    height: 100%;
  }
`;

export interface PrivateNetworkGroupsSidebarProps {
  children?: React.ReactNode;
  activeGroup?: string;
  onSelect: (group: PrivateNetworkGroupDTO | undefined) => void;
}

export function PrivateNetworkGroupsSidebar({
  children,
  onSelect,
  activeGroup,
}: PrivateNetworkGroupsSidebarProps) {
  const { data } = usePrivateNetworkGroups();
  const [isPrivateNetworkDialogOpen, setIsPrivateNetworkDialogOpen] =
    useState(false);
  const [
    isPrivateNetworkDeleteDialogOpen,
    setIsPrivateNetworkDeleteDialogOpen,
  ] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<
    PrivateNetworkGroupDTO | undefined
  >(undefined);
  const isDisabled = data && data.groups.length >= 10;

  return (
    <Container>
      <SidebarContainer
        sidebar={
          <Sidebar>
            <SidebarMenuItem
              selected={!activeGroup}
              badge={data?.all_private_network_carrier_count}
              onClick={() => {
                onSelect(undefined);
              }}
            >
              All Private Network
            </SidebarMenuItem>

            <SidebarSubheader>GROUPS</SidebarSubheader>

            {data?.groups.map((group) => (
              <SidebarMenuItem
                key={group.guid}
                badge={group.carrier_count}
                selected={activeGroup === group.guid}
                onClick={() => {
                  onSelect(group);
                }}
                secondaryActions={
                  <Protect
                    permission="canExecute"
                    entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
                  >
                    <SidebarMenuItemAction title="Rename">
                      <Edit
                        fontSize="small"
                        onClick={() => {
                          setIsPrivateNetworkDialogOpen(true);
                          setCurrentGroup(group);
                        }}
                      />
                    </SidebarMenuItemAction>
                    <SidebarMenuItemAction title="Delete">
                      <Delete
                        fontSize="small"
                        onClick={() => {
                          setIsPrivateNetworkDeleteDialogOpen(true);
                          setCurrentGroup(group);
                        }}
                      />
                    </SidebarMenuItemAction>
                  </Protect>
                }
              >
                {group.name}
              </SidebarMenuItem>
            ))}

            <Protect
              permission="canExecute"
              entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
            >
              <Box
                bottom={0}
                position="sticky"
                paddingTop="small"
                paddingBottom="small"
                paddingLeft="medium"
                paddingRight="medium"
              >
                <Stack align="center">
                  <Button
                    fullWidth={true}
                    variant="neutral"
                    disabled={isDisabled}
                    onClick={() => setIsPrivateNetworkDialogOpen(true)}
                    startIcon={
                      <Add color={isDisabled ? 'disabled' : 'action'} />
                    }
                  >
                    Create Group
                  </Button>

                  {isDisabled && (
                    <TextBox variant="caption" color="secondary">
                      Max 10 carrier groups allowed.
                    </TextBox>
                  )}
                </Stack>
              </Box>
            </Protect>
          </Sidebar>
        }
      >
        <Box height="100%" backgroundColor="Silver200" overflow="hidden">
          {children}
        </Box>

        <PrivateNetworkDialog
          source="Private Network Tab"
          group={currentGroup}
          open={isPrivateNetworkDialogOpen}
          onClose={() => {
            setIsPrivateNetworkDialogOpen(false);
            setCurrentGroup(undefined);
          }}
        />

        <PrivateNetworkDeleteGroupDialog
          group={currentGroup}
          open={isPrivateNetworkDeleteDialogOpen}
          onClose={() => {
            setIsPrivateNetworkDeleteDialogOpen(false);
            setCurrentGroup(undefined);
          }}
        />
      </SidebarContainer>
    </Container>
  );
}
