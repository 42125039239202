import { ComponentType, ReactNode } from 'react';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';

export const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

interface ListTemplateProps {
  actions?: ReactNode;
  content?: ReactNode;
  filter?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  tabs?: ReactNode;
}

export const OrderListTemplate: ComponentType<ListTemplateProps> = ({
  actions,
  content,
  filter,
  footer,
  header,
  tabs,
}) => {
  return (
    <PageLayout header={header}>
      <Root>
        {actions}
        {filter}
        {tabs}
        {content}
        {footer}
      </Root>
    </PageLayout>
  );
};
