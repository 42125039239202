import { createSvgIcon } from 'shared/icons/IconUtils';

export const CaretDownIcon = createSvgIcon(
  'CaretDown',
  <path
    d="M0.666687 0.666656L4.00002 3.99999L7.33335 0.666656H0.666687Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 8 4' },
);
