import { useFormikEnhanced } from '@superdispatch/forms';
import { isEqual } from 'lodash-es';
import {
  useCarrierAttachments,
  useCarrierProfileAPI,
} from 'manage-carriers/data/ManageCarriersAPI';
import { useMemo } from 'react';
import {
  CarrierBrokerPreferencesEdit,
  CarrierFullInfo,
  CarrierStatus,
} from 'shared/types/carrier';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { trackUpdatedInternalRecords } from './CarrierProfilePageAnalytics';

interface CarrierInternalRecordsFormProps {
  onSubmitSuccess: () => void;
  onSubmitFailure: () => void;
  carrier: CarrierFullInfo;
}

export function useCarrierInternalRecordsEditForm({
  onSubmitSuccess,
  onSubmitFailure,
  carrier: { guid, broker_records, carrier_type, name },
}: CarrierInternalRecordsFormProps) {
  const {
    removeAttachment,
    removeInsurance,
    uploadAttachment,
    uploadInsurance,
    updateInternalBrokerRecords,
  } = useCarrierProfileAPI();
  const { data: attachments } = useCarrierAttachments(guid);

  const isCarrierInternal = carrier_type === 'INTERNAL';
  const carrierStatus: CarrierStatus = broker_records.approved
    ? 'APPROVED'
    : broker_records.in_blacklist
    ? 'IN_BLACKLIST'
    : 'DEFAULT';

  const initialValues = useMemo(
    () => ({
      attachments: Array.from(attachments?.objects || []),
      custom_external_id: broker_records.custom_external_id || '',
      status: carrierStatus,
      preferred: broker_records.preferred,
      can_expedite_payment: broker_records.can_expedite_payment,
      is_in_private_network: broker_records.is_in_private_network,
      private_network_group_guids:
        broker_records.private_network_groups?.map((x) => x.guid) || [],
      insurance_certificate_holder: broker_records.insurance_certificate_holder,
      insurance_expires_at: broker_records.insurance_expires_at,
      insurance_cert_holder_file_url:
        broker_records.insurance_cert_holder_file_url,
    }),
    [attachments, broker_records],
  );

  return useFormikEnhanced<CarrierBrokerPreferencesEdit, unknown>({
    initialValues,
    onSubmit: async ({
      attachments: submitAttachments,
      insurance_cert_holder_file_url,
      ...values
    }) => {
      if (insurance_cert_holder_file_url instanceof File) {
        await uploadInsurance(guid, insurance_cert_holder_file_url);
      } else if (
        initialValues.insurance_cert_holder_file_url &&
        insurance_cert_holder_file_url == null
      ) {
        await removeInsurance(guid);
      }

      await Promise.all(
        submitAttachments.map(async (attachment) => {
          if (attachment instanceof File) {
            await uploadAttachment(guid, attachment);
          }
        }),
      );

      await Promise.all(
        initialValues.attachments.map(async (attachment) => {
          if (
            !submitAttachments.some((submitAttachment) =>
              isEqual(submitAttachment, attachment),
            )
          ) {
            await removeAttachment(guid, attachment.id);
          }
        }),
      );

      const shouldResetFlags =
        isCarrierInternal || values.status !== 'APPROVED';

      if (shouldResetFlags) {
        values.preferred = false;
        values.is_in_private_network = false;
        values.private_network_group_guids = [];
      }

      const isBlackList = values.status === 'IN_BLACKLIST';
      if (isBlackList) {
        values.can_expedite_payment = false;
      }

      return updateInternalBrokerRecords(guid, values);
    },
    onSubmitSuccess: (_, values) => {
      trackUpdatedInternalRecords(initialValues, values);
      if (
        initialValues.is_in_private_network === false &&
        values.is_in_private_network
      ) {
        trackEvent('Shipper Added Carrier to Private Network', {
          utm_medium: 'Internal Records Drawer',
        });
      }
      if (
        initialValues.is_in_private_network === true &&
        !values.is_in_private_network
      ) {
        trackEvent('Shipper Removed Carrier from Private Network', {
          utm_medium: 'Internal Records Drawer',
        });
      }

      if (initialValues.can_expedite_payment !== values.can_expedite_payment) {
        const eventName = values.can_expedite_payment
          ? 'Shipper Marked Carrier as Can Expedite Payment'
          : 'Shipper Unmarked Carrier as Can Expedite Payment';
        trackEvent(eventName, {
          carrier: name,
        });
      }

      onSubmitSuccess();
    },
    onSubmitFailure: () => onSubmitFailure(),
  });
}
