import { Box, Typography } from '@material-ui/core';
import { InfoTooltip, useUID } from '@superdispatch/ui';
import * as React from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { Divider } from 'shared/ui/Divider';
import styled from 'styled-components';
import { NoData } from './NoData';

interface InstructionsItemProps {
  title: string;
  hint: string;
  text?: string | null;
}

const StyledFlex = styled(Box)`
  overflow-wrap: anywhere;
`;

function InstructionsItem({ title, hint, text }: InstructionsItemProps) {
  const uid = useUID();

  if (!text) {
    return null;
  }

  return (
    <>
      <Box mt={2} alignItems="center">
        <InfoTooltip
          title={hint}
          placement="top"
          TextProps={{ id: uid, variant: 'h4' }}
        >
          {title}
        </InfoTooltip>
      </Box>

      <StyledFlex mt={1}>
        <Typography variant="body2" aria-labelledby={uid}>
          {text.split('\n').map((item, key) => {
            return (
              <React.Fragment key={key}>
                {item}
                <br />
              </React.Fragment>
            );
          })}
        </Typography>
      </StyledFlex>
    </>
  );
}

interface InstructionsProps {
  order: Order;
}

export const OrderViewInstructions: React.ComponentType<InstructionsProps> = ({
  order: { instructions, loadboard_instructions: loadboardInstructions },
}) => {
  const { user } = useUserState();

  return (
    <>
      <Typography variant="h3">Instructions</Typography>

      {((user?.order_form_settings.is_loadboard_instructions_visible &&
        loadboardInstructions) ||
        loadboardInstructions) && (
        <InstructionsItem
          title="Loadboard Instructions"
          hint="Visible to carrier inside the Super Loadboard"
          text={loadboardInstructions}
        />
      )}

      {user?.order_form_settings.is_instructions_visible &&
        instructions &&
        user.order_form_settings.is_loadboard_instructions_visible &&
        loadboardInstructions && <Divider mt={3} />}

      {((user?.order_form_settings.is_instructions_visible && instructions) ||
        instructions) && (
        <InstructionsItem
          title="Order Instructions"
          hint="Visible to carrier before and after accepting the load offer"
          text={instructions}
        />
      )}

      {!instructions && !loadboardInstructions && (
        <NoData my={3} text="No instructions yet" />
      )}
    </>
  );
};
