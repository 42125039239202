import { Grid } from '@material-ui/core';
import { Color, OverflowText, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { startCase } from 'lodash-es';
import { formatMiles } from 'shared/utils/MeasurementUtils';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { PricingInsightsRecentLoadDTO } from '../../data/dto/PricingInsightsRecentPostings';

const GutterRoot = styled.div`
  height: 100%;
  padding: 4px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;

  &[data-start='true'] {
    background: #ffcb47;
  }

  &[data-middle='true'] {
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid ${Color.Dark100};
  }

  &[data-end='true'] {
    background: #1fa84d;
  }
`;

const GutterLine = styled.div`
  flex: 1;
  width: 2px;
  background: repeating-linear-gradient(
    ${Color.Dark100},
    ${Color.Dark100} 3px,
    transparent 3px,
    transparent 5px
  );
`;

export function formatPostingAddress(
  city: string | null,
  state: string | null,
  zip: string | null,
): string {
  const cityAndState = joinStrings(
    ', ',
    startCase(city?.toLowerCase()),
    state?.toUpperCase(),
  );

  return joinStrings(' ', cityAndState, zip);
}

function Gutter() {
  return (
    <GutterRoot>
      <GutterPoint data-start="true" />
      <GutterLine />
      <GutterPoint data-end="true" />
    </GutterRoot>
  );
}

export interface PostingWaypointsProps {
  load: PricingInsightsRecentLoadDTO;
}

export function LoadWaypoints({ load }: PostingWaypointsProps) {
  return (
    <Stack space="xxsmall">
      <Grid container={true} spacing={1} wrap="nowrap">
        <Grid item={true}>
          <Gutter />
        </Grid>

        <Grid item={true} xs={11}>
          <Stack space="xsmall">
            <OverflowText aria-label="Pickup address" disableUnderline={true}>
              {formatPostingAddress(
                load.pickup_city,
                load.pickup_state,
                load.pickup_zip,
              )}
            </OverflowText>

            <OverflowText aria-label="Delivery address" disableUnderline={true}>
              {formatPostingAddress(
                load.delivery_city,
                load.delivery_state,
                load.delivery_zip,
              )}
            </OverflowText>
          </Stack>
        </Grid>
      </Grid>

      {load.distance_miles != null && (
        <TextBox
          noWrap={true}
          wrapOverflow={true}
          color="secondary"
          aria-label="Delivery address"
        >
          {formatMiles(load.distance_miles, 2)}
        </TextBox>
      )}
    </Stack>
  );
}
