import { useCallback, useEffect, useState } from 'react';

export function useSet<T>(
  initialState?: Set<T>,
): [Set<T>, (key: T) => void, (key: T) => void, () => void] {
  const [state, setState] = useState(() => initialState || new Set<T>());

  const add = useCallback(
    (key: T) => {
      setState((prev) => {
        const next = new Set(prev);
        next.add(key);
        return next;
      });
    },
    [setState],
  );

  const remove = useCallback(
    (key: T) => {
      setState((prev) => {
        const next = new Set(prev);
        next.delete(key);
        return next;
      });
    },
    [setState],
  );

  const clear = useCallback(() => {
    setState(new Set());
  }, [setState]);

  return [state, add, remove, clear];
}

export function useMap<V>(
  initialState?: Map<string, V>,
): [
  Map<string, V>,
  (key: string, value: V) => void,
  (key: string) => void,
  () => void,
] {
  const [state, setState] = useState(
    () => initialState || new Map<string, V>(),
  );

  const add = useCallback(
    (key: string, value: V) => {
      setState((prev) => {
        const next = new Map(prev);
        next.set(key, value);
        return next;
      });
    },
    [setState],
  );

  const remove = useCallback(
    (key: string) => {
      setState((prev) => {
        const next = new Map(prev);
        next.delete(key);
        return next;
      });
    },
    [setState],
  );

  const clear = useCallback(() => {
    setState(new Map());
  }, [setState]);

  return [state, add, remove, clear];
}

/** @deprecated */
export function useLocalStorage(key: string) {
  const [state, setState] = useState<string | null>(() =>
    localStorage.getItem(key),
  );

  const updateStorage = useCallback(
    (value: string | null) => {
      setState(value);

      if (value == null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }
    },
    [setState, key],
  );

  return [state, updateStorage] as const;
}

export const useBoolean = (
  initialValue: boolean,
): [boolean, () => void, () => void] => {
  const [value, setValue] = useState(initialValue);

  const setTrue = () => {
    setValue(true);
  };
  const setFalse = () => {
    setValue(false);
  };
  return [value, setTrue, setFalse];
};

export function useDebouncedValue<T>(value: T, wait: number): T {
  const [debounced, setDebounced] = useState<T>(value);

  useEffect(() => {
    if (wait <= 0) {
      setDebounced(value);
      return;
    }

    const timeout = setTimeout(() => setDebounced(value), wait);

    return () => clearTimeout(timeout);
  }, [value, wait, debounced]);

  return debounced;
}

interface SearchInputOptions {
  timeout?: number;
  minLength?: number;
}

export function useSearchInput(
  input: string,
  { timeout = 200, minLength = 3 }: SearchInputOptions = {},
): string | undefined {
  const value = useDebouncedValue(input, timeout);
  return value.length < minLength ? undefined : value;
}

export function useSearchInputState(options?: SearchInputOptions) {
  const [input, setInput] = useState('');
  const query: string | undefined = useSearchInput(input, options);

  return { query, input, setInput } as const;
}
