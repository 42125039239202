import * as APIService from 'shared/api/utils/APIService';
import { APIResponse } from 'shared/types/APIResponse';
import { Attachment } from 'shared/types/attachment';
import { Note } from 'shared/types/note';
import Order from 'shared/types/order';
import { Pagination } from 'shared/types/pagination';

export const ENDPOINT_URL = 'internal/orders';

export const findDuplicateVins = (vinNumbers: string[]) => {
  return APIService.post(`${ENDPOINT_URL}/find_by_vin`, vinNumbers);
};

export const getOrderAttachmentSignedURL = (
  orderID: Order['id'],
  attachmentID: Attachment['id'],
) =>
  APIService.getText(
    `${ENDPOINT_URL}/${orderID}/attachments/${attachmentID}/signedUrl`,
  );

export const createInternalNote = (orderID: Order['id'], note: string) =>
  APIService.post(`${ENDPOINT_URL}/${orderID}/comments`, { note }).then(
    ({ data }: APIResponse<Note>) => data.object,
  );

export const fetchInternalNotes = (
  orderID: Order['id'],
  pagination: Pagination,
) =>
  APIService.get(`${ENDPOINT_URL}/${orderID}/comments`, pagination).then(
    ({ data }: APIResponse<Note[]>) => ({
      notes: data.objects,
      pagination: data.pagination,
    }),
  );
