import { renderChildren } from '@superdispatch/ui';
import { ReactNode } from 'react';
import {
  NormalizedPermission,
  NormalizedPermissions,
  PermissionType,
} from 'shared/types/permission';
import { WhoAmI } from 'shared/types/user';
import { useUserState } from './AppUserState';

const hasPermission = (
  entity: PermissionType,
  permission: keyof NormalizedPermission,
  user: WhoAmI | undefined,
  permissions: Partial<NormalizedPermissions> | undefined,
) => {
  if (user) {
    if (user.is_superuser) {
      return true;
    }
    const hasEntity = permissions?.[entity];
    return Boolean(hasEntity?.[permission]);
  }
  return false;
};

export const usePermission = (
  permission: PermissionType,
  action: keyof NormalizedPermission,
) => {
  const { user, permissions } = useUserState();
  return hasPermission(permission, action, user, permissions);
};

export const useCanExecute = (permission: PermissionType) =>
  usePermission(permission, 'canExecute');

export const useCanDelete = (permission: PermissionType) =>
  usePermission(permission, 'canDelete');

export const useCanUpdate = (permission: PermissionType) =>
  usePermission(permission, 'canUpdate');

interface ProtectProps {
  entity: PermissionType;
  permission: keyof NormalizedPermission;
  children: ReactNode;
}

export function Protect({ entity, permission, children }: ProtectProps) {
  const hasAccess = usePermission(entity, permission);

  return hasAccess ? renderChildren(children) : null;
}

export default Protect;

export function canOrderSubmitAllowed(user: WhoAmI | undefined): boolean {
  return (
    !!user?.linked_brokers &&
    user.linked_brokers.length === 1 &&
    !!user.linked_brokers[0]?.is_order_submit_allowed
  );
}

interface ExecutePermissionProps {
  entity: PermissionType;
  children: ReactNode;
  fallback?: ReactNode;
}

export function ExecutePermission({
  entity,
  children,
  fallback,
}: ExecutePermissionProps) {
  const canExecute = usePermission(entity, 'canExecute');

  return renderChildren(canExecute ? children : fallback);
}

interface BrokerUserProps {
  children: ReactNode;
  fallback?: ReactNode;
}

export function BrokerUser({ children, fallback }: BrokerUserProps) {
  const { user } = useUserState();

  return renderChildren(
    user?.shipper.shipper_type === 'BROKER' ? children : fallback,
  );
}

export function useUserValue<T>(broker: T, customer: T): T {
  const { user } = useUserState();

  return user?.shipper.shipper_type === 'BROKER' ? broker : customer;
}
