import {
  Drawer,
  IconButton,
  PaperProps as PaperPropsType,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { useEventHandler } from '@superdispatch/hooks';
import {
  DrawerActions,
  DrawerContent,
  DrawerContentProps,
  DrawerTitle,
  useUID,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider, FormikValues, useFormikContext } from 'formik';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Prompt } from 'shared/routing/Prompt';

interface FormikDrawerFormState {
  dirty: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
}

interface FormikDrawerContext {
  handleClose?: () => void;
  setFormState?: (state: FormikDrawerFormState) => void;
}

const context = createContext<FormikDrawerContext>({});

export interface FormikDrawerProps {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  confirmClose?: boolean;
  PaperProps?: PaperPropsType;
  className?: string;
}

export function FormikDrawer({
  onClose,
  children,
  open = false,
  confirmClose = false,
  PaperProps,
  className,
}: FormikDrawerProps) {
  const closeHandler = useEventHandler(onClose);
  const [{ dirty, isSubmitting, isSubmitted }, setFormState] = useState({
    dirty: false,
    isSubmitting: false,
    isSubmitted: false,
  });

  const handleClose = useCallback(() => {
    if (isSubmitting) {
      return;
    }

    if (confirmClose && dirty && !isSubmitted) {
      if (
        window.confirm(
          'Changes have not been saved. Leaving will result in unsaved changes being lost.',
        )
      ) {
        closeHandler(null);
      }
    } else {
      closeHandler(null);
    }
  }, [isSubmitting, confirmClose, dirty, isSubmitted, closeHandler]);

  useEffect(() => {
    if (!open) {
      setFormState({
        dirty: false,
        isSubmitted: false,
        isSubmitting: false,
      });
    }
  }, [open]);

  const ctx = useMemo<FormikDrawerContext>(
    () => ({ setFormState, handleClose }),
    [handleClose],
  );

  return (
    <Drawer
      open={open}
      anchor="right"
      className={className}
      onClose={handleClose}
      PaperProps={PaperProps}
    >
      <Prompt
        message="Changes have not been saved. Leaving will result in unsaved changes being lost."
        when={isSubmitting || (dirty && !isSubmitted)}
      />
      <context.Provider value={ctx}>{children}</context.Provider>
    </Drawer>
  );
}

export interface BaseFormikDrawerContentProps<V extends FormikValues, R> {
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  formik: FormikContextTypeEnhanced<V, R>;
  DrawerContentProps?: DrawerContentProps;
}

export function FormikDrawerContent<V, R>({
  formik,

  title,
  subtitle,
  actions,
  children,
  DrawerContentProps: drawerContentProps,
}: BaseFormikDrawerContentProps<V, R>) {
  const uid = useUID();
  const iconID = `${uid}-icon`;
  const labelID = `${uid}-label`;
  const { setFormState, handleClose } = useContext(context);

  const { dirty, status, isSubmitting } = formik;
  const isSubmitted = status.type === 'submitted';

  useEffect(() => {
    setFormState?.({ isSubmitting, dirty, isSubmitted });
  }, [dirty, isSubmitted, isSubmitting, setFormState]);

  return (
    <FormikProvider value={formik}>
      {!!title && (
        <DrawerTitle
          title={title}
          subtitle={subtitle}
          titleTypographyProps={{ id: labelID }}
          endAction={
            <IconButton
              edge="end"
              onClick={handleClose}
              disabled={isSubmitting}
              aria-labelledby={`${iconID} ${labelID}`}
            >
              <Close id={iconID} aria-label="close" />
            </IconButton>
          }
        />
      )}

      <Form aria-labelledby={labelID}>
        <DrawerContent {...drawerContentProps}>{children}</DrawerContent>

        {actions}
      </Form>
    </FormikProvider>
  );
}

export interface FormikDrawerActionsProps {
  submitButtonLabel?: ReactNode;
  isDisabled?: boolean;
}

export function FormikDrawerActions({
  submitButtonLabel = 'Save',
  isDisabled = false,
}: FormikDrawerActionsProps) {
  const { isSubmitting } = useFormikContext();
  return (
    <DrawerActions>
      <Button type="submit" pending={isSubmitting} disabled={isDisabled}>
        {submitButtonLabel}
      </Button>
    </DrawerActions>
  );
}
