import { useMemo } from 'react';
import { logError } from 'shared/helpers/MonitoringService';
import { useBillingPeriod } from '../../subscription-plans/useBillingPeriod';
import {
  SubscriptionPlanGroupCode,
  useSubscriptionPlans,
} from './useSubscriptionPlans';

export function useSubscriptionPlanPrices<T extends SubscriptionPlanGroupCode>(
  planGroupCodes: T[],
) {
  const { subscriptionPlanGroups, isSubscriptionPlansLoading } =
    useSubscriptionPlans();
  const [billingPeriod] = useBillingPeriod();
  const isMonthlyBilling = billingPeriod === 'monthly';

  const prices = useMemo(() => {
    if (subscriptionPlanGroups === undefined) {
      return undefined;
    }

    return planGroupCodes.reduce((result, planGroupCode) => {
      const targetGroup = subscriptionPlanGroups.find(
        (plan) => plan.plan_group_code === planGroupCode,
      );

      if (!targetGroup) {
        logError(
          `"${planGroupCode}" plan not found or missing price, check API.`,
          'useSubscriptionPlanPricesWithBillingPeriod',
        );
        return result;
      }

      const monthlyPrice = targetGroup.plans.monthly?.monthly_price;
      const yearlyPrice =
        targetGroup.plans.yearly?.monthly_price ?? monthlyPrice;

      result[planGroupCode] = isMonthlyBilling ? monthlyPrice : yearlyPrice;

      return result;

      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    }, {} as Record<T, number | undefined>);
  }, [subscriptionPlanGroups, isMonthlyBilling, planGroupCodes]);

  return { prices, isSubscriptionPlansLoading };
}
