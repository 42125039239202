import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDate } from '@superdispatch/dates';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, DescriptionLineItem } from '@superdispatch/ui-lab';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';
import { CancelSubscriptionButton } from './CancelSubscriptionButton';
import { CancelSuccessDialog } from './CancelSuccessDialog';
import { usePaymentDetails } from './usePaymentDetails';
import { useReactivateSubscription } from './useReactivateSubscription';
import { useSubscriptionPlan } from './useSubscriptionPlan';

const StyledMonthlyPriceAmount = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
`;

export function SubscriptionPlanCard() {
  const { plan, isPlanLoading } = useSubscriptionPlan();
  const { data } = useSubscriptionDetails();
  const isSubscriptionNonRenewing = data?.chargebee.status === 'NON_RENEWING';
  const { billingAddress } = usePaymentDetails();
  const { reactivateSubscription, isReactivatingSubscription } =
    useReactivateSubscription();
  const [
    isCancelSuccessDialogOpen,
    openCancelSuccessDialog,
    closeCancelSuccessDialog,
  ] = useBoolean(false);

  if (isPlanLoading) {
    return <CardSkeleton />;
  }

  if (!plan) {
    return null;
  }

  return (
    <Box width={['100%', '360px']}>
      <Card>
        <CardContent>
          <Stack space={isSubscriptionNonRenewing ? 'none' : 'xxsmall'}>
            <Columns space="small" align="center">
              <Column width="fluid">
                <Stack space="xxsmall">
                  <Typography variant="h6" color="textSecondary">
                    Plan
                  </Typography>
                  <Typography variant="body1" component="span">
                    {plan.plan}
                  </Typography>
                  <Typography color="textSecondary">Billed monthly</Typography>
                </Stack>
              </Column>

              <Column width="content">
                <Inline verticalAlign="bottom" space="none">
                  <StyledMonthlyPriceAmount>
                    {plan.monthlyPlanPrice !== null &&
                      formatCurrency(plan.monthlyPlanPrice)}
                    {isPlanLoading && <Skeleton width={80} />}
                  </StyledMonthlyPriceAmount>
                  <Typography variant="h5" color="textSecondary">
                    /month
                  </Typography>
                </Inline>
              </Column>
            </Columns>

            <CancelSuccessDialog
              open={isCancelSuccessDialogOpen}
              onClose={closeCancelSuccessDialog}
            />

            {isSubscriptionNonRenewing ? (
              <Box marginTop="small">
                <Button
                  fullWidth={true}
                  variant="primary"
                  size="large"
                  disabled={isReactivatingSubscription}
                  onClick={() => {
                    reactivateSubscription();
                  }}
                >
                  Reactivate
                </Button>
              </Box>
            ) : (
              <>
                <Divider />

                <Stack space="small">
                  <div>
                    You will be charged{' '}
                    <Typography variant="body1" component="span">
                      {plan.perVehiclePrice !== null &&
                        formatCurrency(plan.perVehiclePrice)}
                    </Typography>{' '}
                    per vehicle over {plan.maxPlanVehicles}
                  </div>

                  <div>
                    Renews on{' '}
                    {formatDate(plan.renewalDate, { variant: 'Date' })}
                  </div>

                  <Stack>
                    <DescriptionLineItem title="Total">
                      <Typography variant="h4">
                        {plan.totalPrice !== null &&
                          formatCurrency(plan.totalPrice)}
                      </Typography>
                    </DescriptionLineItem>

                    <Typography align="right">
                      {plan.taxPercent === null
                        ? '+ Taxes if applicable'
                        : `
                          Considering ${billingAddress?.state ?? 'state'} tax (
                          ${plan.taxPercent.toFixed(1)}%)
                        `}
                    </Typography>
                  </Stack>

                  <CancelSubscriptionButton
                    onCancelSuccess={openCancelSuccessDialog}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

function CardSkeleton() {
  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Skeleton width={328} />
          <Skeleton width={328} />
          <Skeleton width={328} />
          <Skeleton width={328} />
        </Stack>
      </CardContent>
    </Card>
  );
}
