import { APIErrorImportDetails, APIErrorValidationDetails } from '../api/API';

export interface APIError<TContext = unknown> extends Error {
  error_id?: string;
  details?: TContext;
  responseError?: Response;
  context?: TContext;
}

export function createAPIError<TContext>({
  message,
  ...options
}: Partial<Omit<APIError<TContext>, 'name'>>): APIError<TContext> {
  const error = new Error(message);

  error.name = 'APIError';
  Object.assign(error, options);

  return error as APIError<TContext>;
}

export function isAPIError(error: unknown): error is APIError {
  return error instanceof Error && error.name === 'APIError';
}

export function isAPIImportError(
  error: APIError,
): error is APIError<APIErrorImportDetails> {
  return !!error.error_id?.includes('IMPORT_ERROR') && !!error.details;
}

export function isAPIValidationError(
  error: APIError,
): error is APIError<APIErrorValidationDetails> {
  return (
    (error.error_id === 'VALIDATION_ERROR' ||
      error.error_id === 'CONSTRAINT_VIOLATION_ERROR') &&
    !!error.details
  );
}
