import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

const ProfileContainer = styled.div`
  width: 100%;
  margin: auto;
  max-width: calc(816px + 16px); /** MAIN_CONTENT + SPACING */
`;

interface Props {
  header: ReactNode;
  children?: ReactNode;
}

export function ShipperProfileLayout({ header, children }: Props) {
  return (
    <Box
      paddingTop={['none', 'medium']}
      paddingBottom={['large', 'medium']}
      paddingLeft={['none', 'large']}
      paddingRight={['none', 'large']}
      marginBottom={['xlarge', 'none']}
    >
      <ProfileContainer>
        <Stack space="small">
          {header}

          {children}
        </Stack>
      </ProfileContainer>
    </Box>
  );
}
