import { useSnackbarStack } from '@superdispatch/ui';
import { CustomerContactDTO } from 'shared/dto/CustomerContactDTO';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { ConfirmPopover } from 'shared/ui/ConfirmationPopup';
import { useDeleteContactMutation } from '../data/CustomerContactAPI';

interface Props {
  anchor: HTMLElement | null;
  customer: CustomerDTO;
  contact: CustomerContactDTO;
  onClose: () => void;
}

export function ContactDeleteDialog({
  anchor,
  contact,
  customer,
  onClose,
}: Props) {
  const { addSnackbar } = useSnackbarStack();

  const { mutate: deleteContact, isLoading } = useDeleteContactMutation({
    onSuccess() {
      addSnackbar('Contact deleted', { variant: 'success' });
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmPopover
      open={!!anchor}
      anchorEl={anchor}
      onClose={onClose}
      title="Delete this contact?"
      cancelButtonProps={{
        children: 'No',
        disabled: isLoading,
      }}
      confirmButtonProps={{
        isLoading,
        children: 'Yes, Delete',
        onClick: () =>
          deleteContact({ contactID: contact.id, customerID: customer.id }),
      }}
    />
  );
}
