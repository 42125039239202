import './outdated-browser.css';

if (import.meta.env.VITE_BROWSERSLIST_REGEX) {
  const regex = new RegExp(import.meta.env.VITE_BROWSERSLIST_REGEX);

  if (regex.test(window.navigator.userAgent)) {
    const element = document.getElementById('outdated-browser');
    element?.removeAttribute('hidden');
  }
}
