import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { CarrierInsuranceRequirementsDTO } from '../../data/ShipperProfileDTO';

const currencyOptions = {
  minimumFractionDigits: 0,
};

export interface CargoInsuranceMinimumDetailsProps {
  limits: CarrierInsuranceRequirementsDTO[];
}

export function CargoInsuranceMinimumDetails({
  limits,
}: CargoInsuranceMinimumDetailsProps) {
  const [first] = limits;
  const last = limits.length > 1 ? limits[limits.length - 1] : null;
  return (
    <Stack aria-label="Cargo insurance minimum details" space="xxsmall">
      {first && last ? (
        <>
          <Typography>
            {formatCurrency(first.cargo_limit || 0, currencyOptions)}
            <TextBox color="secondary">
              {' '}
              for {first.trailer_size} unit trailer
            </TextBox>
          </Typography>
          <Typography>
            {formatCurrency(last.cargo_limit || 0, currencyOptions)}
            <TextBox color="secondary">
              {' '}
              for {last.trailer_size}+ unit trailers
            </TextBox>
          </Typography>
        </>
      ) : (
        <Typography>
          {formatCurrency(first?.cargo_limit || 0, currencyOptions)}
        </Typography>
      )}
    </Stack>
  );
}
