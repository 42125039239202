import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const VerifiedIcon = createSvgIcon(
  'VerifiedIcon',
  <>
    <path
      d="M8.24693 13.5726L6.37129 15.2469L5.58409 12.8591L3.12258 13.3713L3.63476 10.9098L1.24693 10.1226L2.92125 8.24693L1.24693 6.37129L3.63476 5.58409L3.12258 3.12258L5.58409 3.63476L6.37129 1.24693L8.24693 2.92125L10.1226 1.24693L10.9098 3.63476L13.3713 3.12258L12.8591 5.58409L15.2469 6.37129L13.5726 8.24693L15.2469 10.1226L12.8591 10.9098L13.3713 13.3713L10.9098 12.8591L10.1226 15.2469L8.24693 13.5726Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4497 5.9136L7.08898 9.18434L5.80097 7.95413L5.13574 8.63139L7.06914 10.5204H7.07745L11.1246 6.57705L10.4497 5.9136Z"
      fill="white"
      stroke="white"
      strokeWidth="0.2"
    />
  </>,
  { htmlColor: Color.Green300, viewBox: '0 0 16 16' },
);
