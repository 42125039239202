import { Color } from '@superdispatch/ui';

export function ContactCardIcon() {
  return (
    <svg
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="118"
        height="78"
        rx="7"
        stroke={Color.Silver500}
        strokeWidth="2"
        strokeDasharray="6 6"
      />
      <path
        d="M38.0752 40.1699C43.7923 40.1699 48.427 35.6547 48.427 30.085C48.427 24.5152 43.7923 20 38.0752 20C32.358 20 27.7234 24.5152 27.7234 30.085C27.7234 35.6547 32.358 40.1699 38.0752 40.1699Z"
        fill={Color.Silver400}
      />
      <path
        d="M38.0752 44.8544C34.403 44.7683 30.7768 45.6638 27.5903 47.4439C24.4037 49.2239 21.7786 51.8204 20 54.9515V60H56.25V54.9515C54.4635 51.8055 51.8227 49.1993 48.6169 47.4183C45.4111 45.6373 41.764 44.7503 38.0752 44.8544Z"
        fill={Color.Silver400}
      />
      <rect
        x="66.25"
        y="22.5"
        width="20"
        height="5"
        rx="2.5"
        fill={Color.Silver400}
      />
      <rect
        x="66.25"
        y="37.5"
        width="33.75"
        height="5"
        rx="2.5"
        fill={Color.Silver400}
      />
      <rect
        x="66.25"
        y="52.5"
        width="33.75"
        height="5"
        rx="2.5"
        fill={Color.Silver400}
      />
    </svg>
  );
}
