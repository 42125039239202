import {
  Box,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Add, GetApp } from '@material-ui/icons';
import {
  Button,
  Color,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { startCase } from 'lodash-es';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { canOrderSubmitAllowed, Protect } from 'shared/data/UserPermissions';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { Divider } from 'shared/ui/Divider';
import styled from 'styled-components';
import { OrderListHeaderCompact } from './OrderListHeaderCompact';
import { OrderListHeaderSearchCompact } from './OrderListHeaderSearchCompact';
import { OrderListSearchPeriod } from './OrderListSearchPeriod';
import { OrderListSearchType } from './OrderListSearchType';

export const StyledViewTitle = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export function getOrderStatusLabel(status?: string) {
  switch (status) {
    case undefined:
      return 'All Orders';
    case 'posted_to_sdlb':
      return 'Posted to SLB Orders';
    case 'posted_to_private_loadboard':
      return 'Posted to Private Loadboard';
    case 'posted_to_lb':
      return 'Posted to Loadboards';
    case 'posted_to_cd':
      return 'Posted to CD Orders';
    case 'order_canceled':
      return 'Canceled Orders';
    case 'inactive':
      return 'Deleted Orders';
    default:
      return `${startCase(status)} Orders`;
  }
}

export function getSuperPayStatusLabel(status: string) {
  switch (status) {
    case 'superpay_delivery_verified':
      return 'Delivery Verified';
    case 'superpay_charged':
      return 'SuperPay Charged';
    case 'superpay_on_hold':
      return 'Payment on Hold';
    case 'superpay_initiated':
      return 'Payment Initiated';
    case 'superpay_charge_scheduled':
      return 'Charge Scheduled';
    case 'superpay_failed':
      return 'Payment Failed';
    case 'superpay_paid':
      return 'Paid';
    default:
      return startCase(status);
  }
}

export function getOrdersListHeaderText(status?: string) {
  return status?.startsWith('superpay')
    ? getSuperPayStatusLabel(status)
    : getOrderStatusLabel(status);
}

export interface OrdersHeaderProps {
  status?: string;
  title?: string;
  query?: string;
  onSearch?: (query: string) => void;
}

// TODO split to OrdersListHeader and OrdersListSearchHeader
export function OrdersListHeader({
  status,
  title,
  onSearch,
  query,
}: OrdersHeaderProps) {
  const navigate = useNavigate();
  const { user } = useUserState();
  const platform = useResponsiveValue(
    'mobile',
    'tablet',
    'desktop-md',
    'desktop',
  );
  const isDesktopMd = platform === 'desktop-md';
  const isDesktop = platform === 'desktop';
  const [searchVisibility, setSearchVisibility] = useState(false);
  const showSearchPeriod = query && query.length > 2;

  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    onSearch?.(value);
  }

  return (
    <>
      <Toolbar>
        {isDesktopMd && onSearch ? (
          <Box py={2} width="100%">
            <Stack space="small">
              <StyledViewTitle variant="h2">
                {title || getOrdersListHeaderText(status)}
              </StyledViewTitle>

              <Columns space="xsmall" align="center">
                <Column width="content">
                  <OrderListSearchType />
                </Column>

                <Column width="fluid">
                  <TextField
                    placeholder="3 symbols to search"
                    value={query}
                    fullWidth={true}
                    type="search"
                    autoFocus={true}
                    onChange={handleChange}
                  />
                </Column>

                {showSearchPeriod && <OrderListSearchPeriod isColumn={true} />}

                <Column width="content">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {(user?.shipper.shipper_type === 'BROKER' ||
                      canOrderSubmitAllowed(user)) && (
                      <Protect permission="canCreate" entity="ORDER">
                        {isDesktop || isDesktopMd ? (
                          <Box ml={1}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => navigate('/orders/import')}
                            >
                              Import Orders
                            </Button>
                          </Box>
                        ) : (
                          <IconButton>
                            <GetApp
                              fontSize="small"
                              style={{ color: Color.Grey100 }}
                              onClick={() => navigate('/orders/import')}
                            />
                          </IconButton>
                        )}
                      </Protect>
                    )}

                    {(user?.shipper.shipper_type === 'BROKER' ||
                      canOrderSubmitAllowed(user)) && (
                      <Protect permission="canCreate" entity="ORDER">
                        {isDesktop || isDesktopMd ? (
                          <Box ml={1}>
                            <Button
                              variant="contained"
                              color="primary"
                              data-intercom-target="create order"
                              onClick={() => {
                                trackEventLegacy('Clicked Create Order');
                                navigate('/orders/create');
                              }}
                            >
                              Create Order
                            </Button>
                          </Box>
                        ) : (
                          <Box ml={1}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                trackEventLegacy('Clicked Create Order');
                                navigate('/orders/create');
                              }}
                            >
                              <Add fontSize="small" />
                            </Button>
                          </Box>
                        )}
                      </Protect>
                    )}
                  </Box>
                </Column>
              </Columns>
            </Stack>
          </Box>
        ) : (
          <OrderListHeaderCompact
            title={title}
            status={status}
            query={query}
            onSearch={onSearch}
            setSearchVisibility={setSearchVisibility}
          />
        )}

        {!isDesktop && searchVisibility && (
          <OrderListHeaderSearchCompact
            query={query}
            setSearchVisibility={setSearchVisibility}
            onSearch={onSearch}
          />
        )}
      </Toolbar>

      <Divider />
    </>
  );
}
