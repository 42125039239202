import { Card, CardContent } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Button, CardButton, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { TerminalContactDTO } from 'shared/dto/TerminalContactDTO';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { useTerminalContacts } from '../data/TerminalContactAPI';
import { TerminalContactForm } from './TerminalContactForm';
import { TerminalContactItem } from './TerminalContactItem';

interface Props {
  terminal: TerminalDTO;
}

export function TerminalContactList({ terminal }: Props) {
  const [formState, setFormState] = useState<{
    open: boolean;
    contact?: TerminalContactDTO;
  }>();

  const { data: contacts } = useTerminalContacts(terminal.id);

  const form = (
    <TerminalContactForm
      terminal={terminal}
      open={!!formState?.open}
      contact={formState?.contact}
      isPrimary={!contacts || contacts.objects.length === 0}
      onClose={() => {
        setFormState(undefined);
      }}
      onSubmitSuccess={() => {
        setFormState(undefined);
      }}
    />
  );

  if (!contacts) {
    return (
      <Card>
        <Skeleton variant="rect" height="104px" />
      </Card>
    );
  }

  if (contacts.objects.length === 0) {
    return (
      <Box backgroundColor="White">
        <CardButton
          startIcon={<Add />}
          onClick={() => setFormState({ open: true })}
        >
          Add Contact
        </CardButton>

        {form}
      </Box>
    );
  }

  return (
    <Card>
      {form}

      <CardContent>
        <Stack space="small">
          <Columns align="center">
            <Column>
              <TextBox variant="heading-3">Contacts</TextBox>
            </Column>

            <Column width="content">
              <Button
                variant="text"
                color="primary"
                startIcon={<Add />}
                onClick={() => setFormState({ open: true })}
              >
                Add New
              </Button>
            </Column>
          </Columns>

          {contacts.objects.map((contact) => (
            <TerminalContactItem
              key={contact.id}
              terminal={terminal}
              contact={contact}
              onEdit={() => {
                setFormState({ open: true, contact });
              }}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
