import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { getFCMCurrentToken } from 'shared/helpers/FirebaseHelpers';
import { valuesToPlain } from 'shared/utils/DataUtils';
import { NotificationSettingsDTO } from './NotificationSettingsDTO';

function getMutatedNotificationSettings(
  email: string,
  notificationSettings?: NotificationSettingsDTO,
): Partial<NotificationSettingsDTO> | undefined {
  const additionalEmail =
    notificationSettings?.additional_email_preferences &&
    notificationSettings.additional_email_preferences.find(
      (val) => val.email === email,
    )?.email;

  if (notificationSettings?.company_email === email) {
    return { is_company_email_unsubscribed: false };
  }
  if (notificationSettings?.personal_email === email) {
    return { is_personal_email_unsubscribed: false };
  }
  if (additionalEmail) {
    return {
      additional_email_preferences:
        notificationSettings.additional_email_preferences.map((val) =>
          val.email === additionalEmail
            ? { ...val, is_unsubscribed: false }
            : val,
        ),
    };
  }
  return undefined;
}

export function useNotificationSettings(
  sendGridSubscription = true,
  skip = false,
) {
  const { requestResource } = useAPI();
  const clientToken = getFCMCurrentToken();

  return useAPIQuery(
    ['notification', 'settings', { clientToken, sendGridSubscription }],
    () =>
      requestResource(
        '/internal/notification_settings{?sendGridSubscription,clientToken}',
        (data) => plainToClass(NotificationSettingsDTO, data),
        {
          sendGridSubscription,
          clientToken,
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !skip,
    },
  );
}

type NotificationSettingsDTOUpdater =
  | ((prev: NotificationSettingsDTO | undefined) => NotificationSettingsDTO)
  | NotificationSettingsDTO;

export function useNotificationSettingsCache() {
  const queryClient = useQueryClient();
  const clientToken = getFCMCurrentToken();

  return useMemo(() => {
    const setQueryData = (data: NotificationSettingsDTOUpdater) => {
      queryClient.setQueryData(
        ['notification', 'settings', { clientToken }],
        data,
      );
    };

    const getQueryData = (): NotificationSettingsDTO | undefined => {
      return queryClient.getQueryData<NotificationSettingsDTO>([
        'notification',
        'settings',
        { clientToken },
      ]);
    };

    return { setQueryData, getQueryData };
  }, [queryClient, clientToken]);
}

export function useNotificationSettingsAPI() {
  const { request } = useAPI();
  const {
    setQueryData: setNotificationSettingsData,
    getQueryData: getNotificationSettingsData,
  } = useNotificationSettingsCache();

  return {
    updateNotificationSettings: (values: NotificationSettingsDTO) =>
      request('POST /internal/notification_settings', {
        json: valuesToPlain(NotificationSettingsDTO, values),
      }),
    resubscribeEmail: (email: string) =>
      request('POST /internal/notification_settings/resubscribe/{email}', {
        email,
      }).then(() => {
        const prev = getNotificationSettingsData();
        const mutatedNotificationSettings = getMutatedNotificationSettings(
          email,
          prev,
        );

        if (prev && mutatedNotificationSettings) {
          setNotificationSettingsData({
            ...prev,
            ...mutatedNotificationSettings,
          });
        }
      }),
  };
}
