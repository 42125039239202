import backgroundBound from 'auth/assets/bg-bound.svg';
import { useEffect } from 'react';
import { hideIntercomButton } from 'shared/helpers/IntercomHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { LoginForm } from './LoginForm';
import { TermsServiceLink } from './TermsServiceLink';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-size: 100% 100%;
  background: url(${backgroundBound}) no-repeat;
`;

export function CustomerLoginPage() {
  useEffect(hideIntercomButton, []);

  return (
    <PageLayout>
      <Wrapper>
        <LoginForm
          title="Customer Portal"
          showSSOButton={false}
          waiver={<TermsServiceLink type="customer" />}
        />
      </Wrapper>
    </PageLayout>
  );
}
