import { IconButton, Link, Typography } from '@material-ui/core';
import { ArrowForward, CheckCircle, Close, Help } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import styled from 'styled-components';
import { useSuperPaySettings } from '../data/SuperPayAPI';
import { usePaymentLogger } from '../helpers/PaymentLogger';
import { BankAccountInformation } from './BankAccountInformation';
import { BankAccountVerify } from './BankAccountVerify';
import { BusinessAccountInformation } from './BusinessAccountInformation';

const CarrierRequestedSuperPayWrapper = styled.div<{ isDisable: boolean }>`
  opacity: ${({ isDisable }) => (isDisable ? '.6' : '1')};
`;

const ALERT_STORAGE_KEY = 'superpay_bank_alert_verification';
function VerificationBanner() {
  const navigate = useNavigate();

  const { data } = useSuperPaySettings();
  const isCloseVerificationAlert = !!useStorageValue(ALERT_STORAGE_KEY);

  if (isCloseVerificationAlert || !data) {
    return null;
  }

  return (
    <Box
      margin="auto"
      maxWidth="720px"
      borderWidth="small"
      borderRadius="small"
      borderColor="Green500"
      color="Green500"
      backgroundColor="Green50"
      padding="small"
      position="relative"
    >
      <Stack>
        <Columns align="center" space="small">
          <Column width="content">
            <CarrierRequestedSuperPayWrapper
              isDisable={data.has_carrier_requested_superpay_orders}
            >
              <CheckCircle />
            </CarrierRequestedSuperPayWrapper>
          </Column>
          <Column>
            <CarrierRequestedSuperPayWrapper
              isDisable={data.has_carrier_requested_superpay_orders}
            >
              Accounts verified. You may start using SuperPay.
            </CarrierRequestedSuperPayWrapper>
          </Column>
          <Column width="content">
            <IconButton
              aria-label="Close"
              size="small"
              onClick={() => writeStorageItem(ALERT_STORAGE_KEY, 'true')}
            >
              <Close htmlColor={Color.Green500} />
            </IconButton>
          </Column>
        </Columns>

        {data.has_carrier_requested_superpay_orders && (
          <Columns align="top" space="small">
            <Column width="content">
              <ArrowForward />
            </Column>
            <Column>
              <Stack>
                <Box>Next, process SuperPay orders requested by carriers.</Box>
                <Button
                  variant="neutral"
                  onClick={() =>
                    navigate(
                      '/orders/invoiced?is_carrier_requested_superpay%5B0%5D=true&page=1',
                    )
                  }
                >
                  View Orders with SuperPay Requests
                </Button>
              </Stack>
            </Column>
          </Columns>
        )}
      </Stack>
    </Box>
  );
}

function LinkInstructionSuperPay() {
  return (
    <Box paddingRight={['small', 'none']}>
      <Inline verticalAlign="center" space="xxsmall" horizontalAlign="right">
        <Help fontSize="small" color="action" />
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://support.superdispatch.com/en/collections/3785518-superpay-for-shippers"
        >
          How SuperPay works
        </Link>
      </Inline>
    </Box>
  );
}

export function BankAccountDetails() {
  const { data: superPaySettings, isLoading, refetch } = useSuperPaySettings();
  const { logPaymentInfo } = usePaymentLogger();
  const bankAccount = superPaySettings?.bank_accounts[0];

  useEffect(() => {
    logPaymentInfo('Opened SuperPay Settings Page', 'BankAccountDetailsPage');
  }, [logPaymentInfo]);

  if (!superPaySettings || !bankAccount) {
    return (
      <Box
        margin="auto"
        maxWidth="532px"
        padding="small"
        backgroundColor="White"
      >
        <Stack>
          {isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              <Typography variant="h3">Bank Account</Typography>
              <Typography color="textSecondary">No data</Typography>
            </>
          )}
        </Stack>
      </Box>
    );
  }

  return (
    <Stack space="small">
      {superPaySettings.status === 'VERIFIED' &&
        bankAccount.verification_status === 'VERIFIED' && (
          <VerificationBanner />
        )}
      <Box margin="auto" maxWidth="532px">
        <Stack space="small">
          <LinkInstructionSuperPay />
          {(bankAccount.verification_status === 'NEW' ||
            bankAccount.verification_status === 'PENDING') && (
            <BankAccountVerify
              onCompleteVerification={refetch}
              bankAccount={bankAccount}
            />
          )}
          <BankAccountInformation
            bankAccount={bankAccount}
            hasAlert={!!superPaySettings.alert_status}
          />
          <BusinessAccountInformation superPaySettings={superPaySettings} />
        </Stack>
      </Box>
    </Stack>
  );
}
