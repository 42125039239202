import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CentralDispatchCredentialsDTO = InferType<
  typeof centralDispatchCredentialsSchema
>;
export const centralDispatchCredentialsSchema = yupObject({
  login: yupString().nullable(),
  uid: yupString().nullable(),
  is_valid_web_credentials: yupBoolean(),
});
