import { Typography } from '@material-ui/core';
import { OrderPricePerMile } from 'core/PricePerMile';
import { NumericFormat } from 'react-number-format';
import Order from 'shared/types/order';
import { kmToMile } from 'shared/utils/MeasurementUtils';

interface OrderViewDistanceWithPrice {
  order: Order;
}

export function OrderViewDistanceWithPrice({
  order,
}: OrderViewDistanceWithPrice) {
  if (!order.price || !order.distance_meters) return null;

  const distanceMiles = kmToMile(order.distance_meters / 1000);

  return (
    <Typography noWrap={true} variant="body2" color="textSecondary">
      (
      <OrderPricePerMile
        order={order}
        TypographyProps={{ variant: 'inherit' }}
      />
      {', '}
      <NumericFormat
        displayType="text"
        suffix=" miles"
        decimalScale={0}
        thousandSeparator={true}
        value={distanceMiles}
      />
      )
    </Typography>
  );
}
