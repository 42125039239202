import {
  Box,
  Fade,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { VisibilityObserver } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarMenuItem,
  SidebarMenuItemAvatar,
} from '@superdispatch/ui-lab';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { ContactCardIcon } from 'shared/icons/ContactCardIcon';
import { useCustomersNavigation } from '../CustomersRouteHelpers';
import { CustomersListActions } from './CustomersListActions';

export function SidebarLoadingItem() {
  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circle" width={32} height={32} />
      </ListItemIcon>
      <ListItemText primary={<Skeleton />} />
    </ListItem>
  );
}

function EmptyList() {
  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb={2}>
        <ContactCardIcon />
      </Box>

      <Typography variant="body2" color="textSecondary" align="center">
        This section shows
        <br /> list of customers once
        <br /> they are added
      </Typography>
    </Box>
  );
}

interface Props {
  total: number;
  customers: CustomerDTO[];
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  selectedCustomers: Set<number>;
  onSelectionChange: (selected: Set<number>) => void;
}

export function CustomersList({
  total,
  customers,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  selectedCustomers,
  onSelectionChange,
}: Props) {
  const { customersSearchParams, navigateToCustomers } =
    useCustomersNavigation();

  const { query, current_customer_id } = customersSearchParams;

  const handleChangeSelection =
    (customer: CustomerDTO) => (checked: boolean) => {
      const next = new Set(selectedCustomers);

      if (checked) {
        next.add(customer.id);
      } else {
        next.delete(customer.id);
      }

      onSelectionChange(next);
    };

  return (
    <Sidebar
      id="customers-list"
      title="Customers"
      count={total}
      header={
        <CustomersListActions
          customers={customers || []}
          selectedCustomers={selectedCustomers}
          onDeleteSuccess={() => {
            navigateToCustomers({
              query,
              current_customer_id,
            });
            onSelectionChange(new Set());
          }}
          onSelectAll={() => {
            onSelectionChange(
              new Set(customers.map((customer) => customer.id)),
            );
          }}
          onDeselectAll={() => {
            onSelectionChange(new Set());
          }}
        />
      }
    >
      {!customers ? (
        <SidebarLoadingItem />
      ) : customers.length === 0 ? (
        query ? (
          <Typography align="center" color="textSecondary">
            No search results
          </Typography>
        ) : (
          <EmptyList />
        )
      ) : (
        customers.map((customer) => (
          <SidebarMenuItem
            key={customer.id}
            openContentOnClick={true}
            selected={current_customer_id === customer.id}
            onClick={() =>
              navigateToCustomers({
                query,
                current_customer_id: customer.id as number,
              })
            }
            avatar={
              <SidebarMenuItemAvatar
                value={selectedCustomers.has(customer.id)}
                onChange={handleChangeSelection(customer)}
              >
                {customer.name}
              </SidebarMenuItemAvatar>
            }
          >
            {customer.name}
          </SidebarMenuItem>
        ))
      )}

      {hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (!isFetchingNextPage && visibility === 'visible') {
              fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <Fade in={isFetchingNextPage}>
              <div ref={ref}>
                {Array.from({ length: 3 }, (_, key) => (
                  <SidebarLoadingItem key={key} />
                ))}
              </div>
            </Fade>
          )}
        />
      )}
    </Sidebar>
  );
}
