import { NullableDateString, parseDate } from '@superdispatch/dates';
import { DateTime } from 'luxon';

export function isCarrierInsuranceExpired(expires_at: NullableDateString) {
  const parsedDate = parseDate(expires_at, { format: 'DateISO' });
  const currentDate = DateTime.local().startOf('day');

  return parsedDate <= currentDate;
}

export function isCarrierInsuranceExpiresInMonth(
  expires_at: NullableDateString,
) {
  const parsedDate = parseDate(expires_at, { format: 'DateISO' });
  const nextMonth = DateTime.local().plus({ month: 1 }).startOf('day');

  return parsedDate <= nextMonth;
}

export function isCarrierInsuranceExpiresIn14Days(
  expires_at: NullableDateString,
) {
  const expiresInDays = expiresIn(expires_at);
  return expiresInDays <= 14 && expiresInDays >= 1;
}

export function expiresIn(expires_at: NullableDateString) {
  const parsedDate = parseDate(expires_at, { format: 'DateISO' });
  return Math.ceil(parsedDate.diffNow('days').as('days'));
}
