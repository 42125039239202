import { Link } from '@material-ui/core';
import { Box, Flex } from '@rebass/grid';
import { RequestedLoadsButton } from 'orders/core';
import { Link as RouterLink } from 'react-router-dom';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';

export interface NumberProps {
  order: Order;
  onClick: () => void;
}

export function OrderRequestNumber({ order, onClick }: NumberProps) {
  return (
    <Flex flexDirection="column">
      <Box>
        <Link to={`/orders/view/${order.guid}`} component={RouterLink}>
          <TextOverflow size={142}>{order.number}</TextOverflow>
        </Link>
      </Box>

      <Box mt={2}>
        <RequestedLoadsButton
          order={order}
          onClick={onClick}
          revalidate={false}
        />
      </Box>
    </Flex>
  );
}
