import { Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { APIErrorImportDetails } from '../api/API';
import { APIError } from './APIError';

interface APIImportErrorMessageProps {
  error: APIError<APIErrorImportDetails>;
}

export function APIImportErrorMessage({
  error: { details = {} },
}: APIImportErrorMessageProps) {
  return (
    <Stack>
      <Typography>Import Error:</Typography>

      <Stack>
        {Object.entries(details).map(([row, detail], index) => (
          <Inline key={index}>
            <Typography>Row: {row}</Typography>

            <Stack space="xsmall">
              {Object.entries(detail).map(([key, value]) => (
                <Typography key={key}>{`${key}: ${value}`}</Typography>
              ))}
            </Stack>
          </Inline>
        ))}
      </Stack>
    </Stack>
  );
}
