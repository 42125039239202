import { set } from 'lodash-es';
import { ValidationError } from 'yup';

export function mapYupErrorsToFormik(validateCallback: () => unknown) {
  try {
    validateCallback();
    return undefined;
  } catch (validationError: unknown) {
    let errors = {};
    if (
      ValidationError.isError(validationError) &&
      validationError.inner.length > 0
    ) {
      validationError.inner.forEach((error) => {
        set(errors, error.path, error.message);
      });
    }
    return errors;
  }
}
