import { toBytes } from '@superdispatch/ui-lab';
import { FormikAttachmentList } from 'shared/form/FormikAttachmentList';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { validateFileList } from 'shared/helpers/ValidationHelpers';

const ATTACHMENT_MAX_FILE_SIZE = toBytes(20, 'mb');
const ATTACHMENT_ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/jpg',
  'application/pdf',
];
const ATTACHMENT_ACCEPT_TYPES = ATTACHMENT_ALLOWED_MIME_TYPES.join();

export const NetworkDrawerAttachments = () => {
  return (
    <FormikAttachmentList
      name="attachments"
      accept={ATTACHMENT_ACCEPT_TYPES}
      validate={(value) =>
        validateFileList(
          value.filter((x: unknown) => x instanceof File),
          {
            allowFormats: ATTACHMENT_ALLOWED_MIME_TYPES,
            maxSize: ATTACHMENT_MAX_FILE_SIZE,
            maxSizeMessage: `File size should be less than ${formatNumber(
              ATTACHMENT_MAX_FILE_SIZE / (1024 * 1024),
            )} MB`,
          },
        )
      }
    />
  );
};
