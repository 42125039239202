import { CircularProgress, IconButton } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { useMutation } from 'react-query';
import { TerminalContactDTO } from 'shared/dto/TerminalContactDTO';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { useTerminalContactAPI } from '../data/TerminalContactAPI';

interface Props {
  icon: ReactNode;
  terminal: TerminalDTO;
  contact: TerminalContactDTO;
}

export function TerminalContactMarkAsPrimary({
  icon,
  terminal,
  contact,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { markTerminalContactAsPrimary } = useTerminalContactAPI();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => markTerminalContactAsPrimary(terminal.id, contact.id),
    onSuccess: () =>
      void addSnackbar('Marked as primary', { variant: 'success' }),
    onError: (error: Error) =>
      void addSnackbar(error.message, { variant: 'error' }),
  });

  return (
    <IconButton
      size="small"
      onClick={() => mutate()}
      disabled={isLoading}
      aria-label="mark as primary"
    >
      {isLoading ? <CircularProgress size="16px" /> : icon}
    </IconButton>
  );
}
