import { Dialog } from '@material-ui/core';
import { ExitTransitionPlaceholder } from '@superdispatch/ui';
import { PrivateNetworkGroupDTO } from '../data/PrivateNetworkDTO';
import { PrivateNetworkDialogContent } from './PrivateNetworkGroupDialogContent';

export interface PrivateNetworkCreateGroupDialogProps {
  open: boolean;
  group?: PrivateNetworkGroupDTO | undefined;
  source: string;
  onClose: () => void;
}

export function PrivateNetworkDialog({
  open,
  group,
  source,
  onClose,
}: PrivateNetworkCreateGroupDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <ExitTransitionPlaceholder in={open}>
        <PrivateNetworkDialogContent
          source={source}
          onClose={onClose}
          group={group}
        />
      </ExitTransitionPlaceholder>
    </Dialog>
  );
}
