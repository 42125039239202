import { Column } from '@superdispatch/ui';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { ORDER_RECENT_SEARCH_PERIOD_KEY } from 'shared/helpers/RecentSelectionsHelpers';
import { OrderListSearchPeriodField } from './OrderListSearchPeriodField';

interface OrderListSearchType {
  isColumn?: boolean;
  fullWidth?: boolean;
}

export const defaultSearchPeriod = 'P6M';

export function useSearchPeriod(): string {
  const searchPeriodStorageValue = useStorageValue(
    ORDER_RECENT_SEARCH_PERIOD_KEY,
  );

  return searchPeriodStorageValue || defaultSearchPeriod;
}

export function setSearchPeriod(value: string) {
  writeStorageItem(ORDER_RECENT_SEARCH_PERIOD_KEY, value);
}

export function OrderListSearchPeriod({
  isColumn,
  fullWidth,
}: OrderListSearchType) {
  return isColumn ? (
    <Column width="content">
      <OrderListSearchPeriodField />
    </Column>
  ) : (
    <OrderListSearchPeriodField fullWidth={fullWidth} />
  );
}
