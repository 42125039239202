import { Link } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { RatingStatsDTO } from './data/ShipperProfileDTO';

interface Props {
  onClick: () => void;
  ratingStats: RatingStatsDTO | null | undefined;
}

export function ShipperRatingStatistics({ onClick, ratingStats }: Props) {
  return (
    <Inline space="xxsmall" verticalAlign="center" aria-label="rating-stats">
      <ThumbUp color="action" fontSize="small" />

      <Link href="#" variant="h3" onClick={onClick}>
        {ratingStats?.overall_rating ?? 0}%
      </Link>

      {ratingStats?.total_rating_count != null &&
        ratingStats.total_rating_count > 0 && (
          <TextBox color="secondary">
            ({ratingStats.total_rating_count})
          </TextBox>
        )}
    </Inline>
  );
}
