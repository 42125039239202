import { Link, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useAuthAPI } from 'auth/data/LoginAPI';
import {
  resendCodeSchema,
  ResendVerificationResponseDTO,
} from 'auth/data/LoginFormDTO';
import { useEffect, useState } from 'react';
import { useFeatureTogglePublic } from 'shared/data/FeatureToggle';
import { useVerification } from './VerificationStorage';

interface VerificationActionsProps {
  channel: 'email' | 'sms';
  setChannel: (channel: 'email' | 'sms') => void;
  children?: React.ReactNode;
}

export function VerificationActions({
  channel,
  setChannel,
  children,
}: VerificationActionsProps) {
  const { resendVerificationCode } = useAuthAPI();
  const { addSnackbar } = useSnackbarStack();
  const { timeLeft, startCountDown } = useCountDown();
  const {
    phoneNumber,
    username = '',
    attemptSessionGuid = '',
    saveVerificationCodeChannel,
  } = useVerification();
  const { data } = useFeatureTogglePublic(
    'frontend.two-factor-authentication.sms.enabled',
  );
  const isSMSVerificationEnabled = Boolean(data?.is_enabled);

  const formik = useFormikEnhanced({
    initialValues: {
      username,
      channel,
      attempt_session_guid: attemptSessionGuid,
    },
    validationSchema: resendCodeSchema,
    onSubmit: () =>
      resendVerificationCode({
        username,
        channel,
        attempt_session_guid: attemptSessionGuid,
      }),
    onSubmitSuccess: (response: ResendVerificationResponseDTO) => {
      const mfaChannel = response.mfa_channel as 'email' | 'sms' | undefined;
      if (mfaChannel) {
        setChannel(mfaChannel);
        saveVerificationCodeChannel(mfaChannel);
      }
      addSnackbar('Two-Factor Authentication code successfully sent.', {
        variant: 'success',
      });
      startCountDown(20);
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Stack space="large">
      {timeLeft > 0 ? (
        <Stack space="none">
          <Typography color="textSecondary">
            Didn&#8217;t receive a code?
            {channel === 'email' && ' Check your spam folder.'}
          </Typography>
          <Typography color="textSecondary">
            Resend code in {timeLeft} sec.
          </Typography>
        </Stack>
      ) : (
        <Stack space="none">
          {channel === 'email' && (
            <Typography color="textSecondary">
              Didn&#8217;t receive a code? Check your spam folder.
            </Typography>
          )}
          <Typography color="textSecondary">
            {channel === 'email'
              ? 'If you can’t find it, you can'
              : 'Didn’t receive a code?'}{' '}
            <Link
              href="#"
              underline="none"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              Resend code.
            </Link>
          </Typography>
        </Stack>
      )}

      <Stack space="small">
        {children}

        {channel === 'email' ? (
          isSMSVerificationEnabled &&
          !!phoneNumber && (
            <Button
              fullWidth={true}
              variant="text"
              disabled={formik.isSubmitting}
              onClick={(e) => {
                setChannel('sms');
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              Receive Code via SMS(Text Message)
            </Button>
          )
        ) : (
          <Button
            fullWidth={true}
            variant="text"
            disabled={formik.isSubmitting}
            onClick={(e) => {
              setChannel('email');
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            Receive Code via Email
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export function useCountDown() {
  const [timeLeft, setTimeLeft] = useState(20);

  useEffect(() => {
    if (timeLeft <= 0) {
      return;
    }
    const countdownInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => {
      clearInterval(countdownInterval);
    };
  }, [timeLeft]);

  const startCountDown = (seconds: number) => {
    setTimeLeft(seconds);
  };

  return { startCountDown, timeLeft };
}
