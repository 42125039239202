import { Typography } from '@material-ui/core';
import {
  NullableDateInput,
  NullableDateRangeInput,
} from '@superdispatch/dates';
import { formatDateType } from '@superdispatch/sdk';
import { Stack } from '@superdispatch/ui';
import { useMemo, useState } from 'react';
import Order from 'shared/types/order';
import { BulletTextItem } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';
import {
  Timeline,
  TimelineCardHeading,
  TimelineItem,
  TimelineItemPadded,
} from 'shared/ui/Timeline';
import { isDatePast, normalizeScheduledDate } from './OrderViewDates';

interface OrderViewDatesProps {
  order: Order;
}

interface ITimelineItem {
  title: string;
  active: boolean;
  content: Array<{
    label?: string;
    date?: NullableDateInput | NullableDateRangeInput;
  }>;
}

interface ITimelineGroup {
  title: string;
  active: boolean;
  items: ITimelineItem[];
}

interface ITimelineGroups {
  created: ITimelineGroup;
  pickup: ITimelineGroup;
  delivery: ITimelineGroup;
}

export const getGrouppedTimeline = (order: Order) => {
  let groups: ITimelineGroups = {
    created: {
      title: 'Created',
      active: true,
      items: [
        {
          title: 'Created',
          active: true,
          content: [
            {
              date: order.created_at as
                | NullableDateInput
                | NullableDateRangeInput,
              label: '',
            },
          ],
        },
      ],
    },

    pickup: {
      title: 'Pickup',
      active: false,
      items: [] as ITimelineItem[],
    },

    delivery: {
      title: 'Delivery',
      active: false,
      items: [] as ITimelineItem[],
    },
  };

  const isPickedUp =
    !!order.pickup?.adjusted_date || !!order.pickup?.completed_at;
  const isDelivered =
    !!order.delivery?.adjusted_date || !!order.delivery?.completed_at;

  if (
    !!order.pickup?.first_available_pickup_date ||
    !!order.pickup?.scheduled_at_by_customer ||
    !!order.pickup?.scheduled_ends_at_by_customer
  ) {
    groups.pickup.items.push({
      title: 'Customer Pickup Date',
      active:
        isDatePast(order.pickup.scheduled_at_by_customer) ||
        isDatePast(order.pickup.first_available_pickup_date),
      content: [
        {
          label: formatDateType(order.pickup.date_type),
          date: normalizeScheduledDate(
            order.pickup.scheduled_at_by_customer,
            order.pickup.scheduled_ends_at_by_customer,
          ),
        },
        {
          label: '1st Avail. Pickup',
          date: order.pickup.first_available_pickup_date,
        },
      ],
    });
  }

  if (!!order.pickup?.scheduled_at || !!order.pickup?.scheduled_ends_at) {
    groups.pickup.items.push({
      title: 'Carrier Pickup Date',
      active: isDatePast(order.pickup.scheduled_at),
      content: [
        {
          label: formatDateType(order.pickup.date_type),
          date: normalizeScheduledDate(
            order.pickup.scheduled_at,
            order.pickup.scheduled_ends_at,
          ),
        },
        {
          label: 'Carrier Updated',
          date: order.pickup.scheduled_at_by_carrier,
        },
      ],
    });
  }

  if (!!order.pickup?.completed_at || !!order.pickup?.adjusted_date) {
    groups.pickup.items.push({
      title: 'Picked Up On',
      active: isPickedUp,
      content: [
        {
          label: 'By Carrier',
          date: order.pickup.completed_at,
        },
        {
          label: 'Shipper Adjusted',
          date: order.pickup.adjusted_date,
        },
      ],
    });
  }

  if (
    !!order.delivery?.scheduled_at_by_customer ||
    !!order.delivery?.scheduled_ends_at_by_customer
  ) {
    groups.delivery.items.push({
      title: 'Customer Delivery Date',
      active: isDatePast(order.delivery.scheduled_at_by_customer),
      content: [
        {
          label: formatDateType(order.delivery.date_type),
          date: normalizeScheduledDate(
            order.delivery.scheduled_at_by_customer,
            order.delivery.scheduled_ends_at_by_customer,
          ),
        },
      ],
    });
  }

  if (!!order.delivery?.scheduled_at || !!order.delivery?.scheduled_ends_at) {
    groups.delivery.items.push({
      title: 'Carrier Delivery Date',
      active: isDatePast(order.delivery.scheduled_at),
      content: [
        {
          label: formatDateType(order.delivery.date_type),
          date: normalizeScheduledDate(
            order.delivery.scheduled_at,
            order.delivery.scheduled_ends_at,
          ),
        },
        {
          label: 'Carrier Updated',
          date: order.delivery.scheduled_at_by_carrier,
        },
      ],
    });
  }

  if (!!order.delivery?.completed_at || !!order.delivery?.adjusted_date) {
    groups.delivery.items.push({
      title: 'Delivered On',
      active: isDelivered,
      content: [
        {
          label: 'By Carrier',
          date: order.delivery.completed_at,
        },
        {
          label: 'Shipper Adjusted',
          date: order.delivery.adjusted_date,
        },
      ],
    });
  }

  groups.pickup.active = groups.pickup.items.length > 0 && isPickedUp;
  groups.delivery.active = groups.delivery.items.length > 0 && isDelivered;

  return groups;
};

export function OrderViewGrouppedDates({ order }: OrderViewDatesProps) {
  const timeline = useMemo(() => getGrouppedTimeline(order), [order]);

  const activeIndexesMap = useMemo(
    () => Object.values(timeline).map(({ active }) => active),
    [timeline],
  );

  const renderTimelineDateItem = (
    item: { label?: string; date?: NullableDateInput | NullableDateRangeInput },
    index: number,
  ) => {
    const dateElement = Array.isArray(item.date) ? (
      <TimelineItemPadded key={index}>
        <Typography color="textPrimary">
          <DetailedFormattedDateRange range={item.date} />
        </Typography>
      </TimelineItemPadded>
    ) : (
      <TimelineItemPadded key={index}>
        <Typography color="textPrimary">
          <DetailedFormattedDate date={item.date} variant="ShortDate" />
        </Typography>
      </TimelineItemPadded>
    );

    return !item.date ? null : !item.label ? (
      dateElement
    ) : (
      <BulletTextItem
        key={item.label}
        secondary={item.label}
        primary={dateElement}
      />
    );
  };

  const renderTimelineItem = (item: ITimelineItem | undefined) => (
    <Stack space="xxsmall" key={item?.title}>
      <TimelineItemPadded>
        <Typography
          variant="body2"
          component="div"
          color="textSecondary"
          aria-label="timeline item subtitle"
        >
          {item?.title}
        </Typography>
      </TimelineItemPadded>
      <Stack space="none">{item?.content.map(renderTimelineDateItem)}</Stack>
    </Stack>
  );

  const [isPickupExpanded, setIsPickupExpanded] = useState(false);
  const indexOfPickupSubtitle = !isPickupExpanded
    ? timeline.pickup.items.length - 1
    : 0;

  const [isDeliveryExpanded, setIsDeliveryExpanded] = useState(false);
  const indexOfDeliverySubtitle = !isDeliveryExpanded
    ? timeline.delivery.items.length - 1
    : 0;

  const isActive = (index: number) => {
    return activeIndexesMap[index];
  };

  return (
    <div aria-label="order dates">
      <TimelineCardHeading>
        <Typography variant="h3">Dates</Typography>
      </TimelineCardHeading>

      <Timeline isActive={isActive}>
        <TimelineItem
          key={timeline.created.title}
          title={timeline.created.title}
          subtitle={timeline.created.items[0]?.content.map(
            renderTimelineDateItem,
          )}
        />

        {timeline.pickup.items.length > 0 && (
          <TimelineItem
            key={timeline.pickup.title}
            title={timeline.pickup.title}
            subtitle={
              timeline.pickup.items[indexOfPickupSubtitle] &&
              renderTimelineItem(timeline.pickup.items[indexOfPickupSubtitle])
            }
            isExpandable={timeline.pickup.items.length > 1}
            onExpand={(isExpanded: boolean) => {
              setIsPickupExpanded(isExpanded);
            }}
            expandContent={
              !isPickupExpanded
                ? timeline.pickup.items
                    .slice(0, indexOfPickupSubtitle)
                    .map(renderTimelineItem)
                : timeline.pickup.items.slice(1).map(renderTimelineItem)
            }
          />
        )}

        {timeline.delivery.items.length > 0 && (
          <TimelineItem
            key={timeline.delivery.title}
            title={timeline.delivery.title}
            subtitle={
              timeline.delivery.items[indexOfDeliverySubtitle] &&
              renderTimelineItem(
                timeline.delivery.items[indexOfDeliverySubtitle],
              )
            }
            isExpandable={timeline.delivery.items.length > 1}
            onExpand={(isExpanded: boolean) => {
              setIsDeliveryExpanded(isExpanded);
            }}
            expandContent={
              !isDeliveryExpanded
                ? timeline.delivery.items
                    .slice(0, indexOfDeliverySubtitle)
                    .map(renderTimelineItem)
                : timeline.delivery.items.slice(1).map(renderTimelineItem)
            }
          />
        )}
      </Timeline>
    </div>
  );
}
