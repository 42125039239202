import { createSvgIcon } from './IconUtils';

export const CalendarOut = createSvgIcon(
  'PostingFilterDeliver',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7792 13.4168V4.25016C13.7792 3.56006 13.2246 3.00016 12.5411 3.00016H11.303V1.646C11.303 1.47412 11.1637 1.3335 10.9935 1.3335H9.96173C9.79149 1.3335 9.6522 1.47412 9.6522 1.646V3.00016H6.35062V1.646C6.35062 1.47412 6.21133 1.3335 6.04109 1.3335H5.00935C4.83911 1.3335 4.69982 1.47412 4.69982 1.646V3.00016H3.46173C2.7782 3.00016 2.22363 3.56006 2.22363 4.25016V13.4168C2.22363 14.1069 2.7782 14.6668 3.46173 14.6668H12.5411C13.2246 14.6668 13.7792 14.1069 13.7792 13.4168ZM12.5411 5.50016V13.2606C12.5411 13.3465 12.4714 13.4168 12.3863 13.4168H3.61646C3.53134 13.4168 3.4617 13.3465 3.4617 13.2606V5.50016H12.5411Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7198 11.287L9.69937 11.287L9.70609 8.74935L6.0473 12.4081L5.33233 11.6932L8.99111 8.03438L6.45346 8.04109L6.45346 7.02066L10.7198 7.02402V11.287Z"
      fill="currentColor"
    />
  </>,
  { viewBox: '0 0 16 16' },
);
