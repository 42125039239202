import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';
import { Color, Stack, SuperDispatchTheme } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import React from 'react';
import styled, { css } from 'styled-components';
import { SuperPayProgressBar } from 'superpay/SuperPayProgressBar';

interface SuperPayPageLayoutProps {
  stepNumber?: number;
  children: NonNullable<React.ReactNode>;
}

const PageLayoutHeader = styled(AppBar)`
  min-width: 0;
  max-width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const StyledContainer = styled(Container)(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    padding-left: 32px;
    padding-right: 32px;
    max-width: calc(816px + 16px);
    margin-bottom: 32px;
    ${theme.breakpoints.down('md')} {
      padding: 0;
    }
  `,
);

export function SuperPayOnboardingPageLayout({
  children,
  stepNumber = 1,
}: SuperPayPageLayoutProps) {
  return (
    <Stack space="small">
      <PageLayoutHeader>
        <Toolbar>
          <TextBox variant="heading-2">Setup SuperPay</TextBox>
        </Toolbar>
        <SuperPayProgressBar
          total={5}
          count={stepNumber}
          color={Color.Blue300}
        />
      </PageLayoutHeader>
      <StyledContainer maxWidth="md">
        <Stack space="small">
          <Box marginLeft="xsmall" marginRight="xsmall">
            <Typography color="textSecondary">
              To enable payments, we are legally required to collect information
              below. This information is not stored by Super Dispatch and only
              used to verify the business.
            </Typography>
          </Box>
          {children}
        </Stack>
      </StyledContainer>
    </Stack>
  );
}
