import {
  Box,
  Card,
  CardContent,
  Fade,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { ReactNode, useState } from 'react';
import { ExecutePermission } from 'shared/data/UserPermissions';

interface Props {
  title?: string;
  onEdit: () => void;
  children: ReactNode;
}

export function CustomerDetailsCard({ title, onEdit, children }: Props) {
  const [isHovered, setHovered] = useState(false);

  return (
    <Card
      aria-label={title}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <CardContent>
        <Stack>
          {title && (
            <Box display="flex" alignItems="center">
              <Box
                flex={1}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Typography variant="h3" display="inline">
                  {title}
                </Typography>
              </Box>

              <ExecutePermission entity="CUSTOMER_MANAGEMENT">
                <Fade in={isHovered}>
                  <Box my="-1px">
                    <IconButton
                      size="small"
                      onClick={onEdit}
                      aria-label="edit customer info"
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Fade>
              </ExecutePermission>
            </Box>
          )}

          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
