import { useValidateToken } from 'general-settings/data/GeneralSettingsAPI';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { ExpiredLinkPage } from './ExpiredLinkPage';
import { LoginLayout } from './LoginLayout';
import { ResetPasswordForm } from './ResetPasswordForm';

export function ResetPasswordPage() {
  const [{ token = '' }] = useQuery();
  const { data: isValid, isLoading } = useValidateToken(token);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return isValid ? (
    <LoginLayout>
      <ResetPasswordForm />
    </LoginLayout>
  ) : (
    <ExpiredLinkPage />
  );
}
