import { hasOnlyDigits } from 'shared/utils/StringUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { ref } from 'yup';

export const bankAccountValidationSchema = yupObject({
  holderName: yupString().required('Enter account business name.').defined(),
  routingNumber: yupString()
    .required('Invalid routing number')
    .length(9, 'Routing number must have 9 digits')
    .test('only digits', 'Invalid routing number', hasOnlyDigits)
    .defined(),
  accountNumber: yupString()
    .required('Enter account number')
    .min(5, 'Account number must have more than 4 digits')
    .max(17, 'Account number must have 17 or less digits')
    .test('only digits', 'Invalid bank account number', hasOnlyDigits)
    .defined(),
  confirmAccountNumber: yupString()
    .oneOf([ref('accountNumber')], 'Numbers don’t match')
    .required('Numbers don’t match')
    .defined(),
});
