import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Color, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { ExpressSubscriptionPlanCard } from '../shared/subscription-plans/ExpressSubscriptionPlanCard';
import { BillingPeriodToggle } from './BillingPeriodToggle';
import { ComparePlansTable } from './ComparePlansTable';
import { FaqList } from './FaqList';
import { ProSubscriptionPlanCard } from './pro-subscription-plan/ProSubscriptionPlanCard';

const StyledRoot = styled.div`
  position: relative;
  z-index: 0;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const StyledContainer = styled.div`
  max-width: 976px;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
`;

const CardsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(360px, 1fr));
  gap: 24px;
`;

const StyledGradientBackground = styled.div`
  height: 784px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(
    270deg,
    rgba(0, 99, 219, 0.2) -0.59%,
    rgba(204, 238, 253, 0.2) 51.28%,
    rgba(254, 74, 73, 0.2) 100%
  );
`;

const FAQContainer = styled.div`
  margin-top: 80px;
`;

export function SubscriptionPlansIndexPage() {
  const navigate = useNavigate();

  return (
    <PageLayout
      contentBackground={Color.White}
      header={
        <Toolbar>
          <IconButton aria-label="go back" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>

          <Typography variant="h2">Pricing Plans</Typography>
        </Toolbar>
      }
    >
      <StyledRoot>
        <StyledGradientBackground />

        <StyledContainer>
          <Stack space="xlarge" align="center">
            <Box maxWidth="643px">
              <Stack space="small">
                <Typography variant="h1" align="center">
                  Transparent Pricing, Unrivaled Value
                </Typography>
                <Typography variant="h3" color="textSecondary" align="center">
                  Discover the perfect plan for your unique business needs,
                  whether you’re a Shipper, Broker, Dealer, Auction, or Fleet
                  Leasing business.
                </Typography>
              </Stack>
            </Box>
            <BillingPeriodToggle />

            <CardsContainer>
              <ExpressSubscriptionPlanCard
                onCtaClick={() =>
                  navigate('/subscription-plans/shipper-express')
                }
              />
              <ProSubscriptionPlanCard />
            </CardsContainer>

            <ComparePlansTable />
          </Stack>

          <FAQContainer>
            <Stack space="xlarge">
              <Typography align="center" variant="h1">
                Frequently Asked Questions
              </Typography>
              <FaqList />
            </Stack>
          </FAQContainer>
        </StyledContainer>
      </StyledRoot>
    </PageLayout>
  );
}
