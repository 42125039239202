import {
  BroadcastChannelHandler,
  useBroadcastChannel,
} from '../../shared/helpers/BroadcastChannel';

export function useNotificationSettingsChannel(
  handler?: BroadcastChannelHandler<undefined>,
) {
  return useBroadcastChannel<undefined>('notifications-settings', handler);
}
