import { Box, Link, LinkProps } from '@material-ui/core';
import * as React from 'react';
import { useMemo } from 'react';
import routeLogo from 'shared/icons/routeLogo.svg';
import { stringifySearchQuery } from '../helpers/RouteHelpers';

export function generateGoogleMapDirectionLink(
  origin: string,
  destination: string,
) {
  const search = stringifySearchQuery({
    api: 1,
    origin,
    destination,
    travelmode: 'driving',
  });

  return `https://www.google.com/maps/dir/?${search}`;
}

interface ViewRouteProps {
  origin: string;
  destination: string;
  textStyle?: React.CSSProperties;
}

export function ViewRoute({ origin, destination, textStyle }: ViewRouteProps) {
  return (
    <Box display="flex" flexWrap="wrap">
      <Box pt="2px" pr={1}>
        <img style={{ objectFit: 'contain' }} src={routeLogo} alt="" />
      </Box>
      <Box>
        <ViewRouteLink
          style={textStyle}
          aria-label="venue route"
          origin={origin}
          destination={destination}
        />
      </Box>
    </Box>
  );
}

interface ViewRouteLinkProps extends LinkProps {
  origin: string;
  destination: string;
}

export function ViewRouteLink({
  origin,
  destination,
  ...props
}: ViewRouteLinkProps) {
  const googleMapDirectionLink = useMemo(() => {
    return generateGoogleMapDirectionLink(origin, destination);
  }, [origin, destination]);

  return (
    <Link
      href={googleMapDirectionLink}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      View Route
    </Link>
  );
}
