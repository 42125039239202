import { Box, Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SidebarContent } from '@superdispatch/ui-lab';

export function CustomerDetailsLoading() {
  return (
    <SidebarContent title={<Skeleton width="130px" />}>
      <Box display="flex" flexWrap="wrap">
        <Box width={{ sm: 1, md: 1 / 2, lg: 1 / 3 }}>
          <Box p={1}>
            <Card>
              <Skeleton variant="rect" height="332px" />
            </Card>
          </Box>

          <Box p={1}>
            <Card>
              <Skeleton variant="rect" height="104px" />
            </Card>
          </Box>
        </Box>

        <Box width={{ sm: 1, md: 1 / 2, lg: 2 / 3 }}>
          <Box p={1}>
            <Card>
              <Skeleton variant="rect" height="330px" />
            </Card>
          </Box>

          <Box p={1}>
            <Card>
              <Skeleton variant="rect" height="330px" />
            </Card>
          </Box>
        </Box>
      </Box>
    </SidebarContent>
  );
}
