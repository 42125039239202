import { Tab, Tabs } from '@material-ui/core';
import { Color, useSnackbarStack } from '@superdispatch/ui';
import { useUnratedCarriersCount } from 'core/ratings/data/CarrierRatingAPI';
import { useEffect } from 'react';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import styled from 'styled-components';
import { useShipperProfile } from './data/ShipperProfileAPI';
import { ShipperProfileHeader } from './ShipperProfileHeader';
import { ShipperProfileLayout } from './ShipperProfileLayout';
import { ShipperProfileOverview } from './ShipperProfileOverview';
import { ShipperRatings } from './ShipperRatings';
import { ShipperRatingStatistics } from './ShipperRatingStatistics';
import { UnratedCarriers } from './UnratedCarriers';

// Use `iconPosition` prop instead after migrating to material-ui v5
const StyledTab = styled(Tab)`
  & .MuiTab-wrapper {
    flex-direction: row-reverse;
    align-items: center;

    & > *:first-child {
      margin-bottom: 0;
      margin-left: 8px;
    }
  }
  min-height: 48px;
`;

const Badge = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${Color.Blue300};
`;

export function ShipperProfilePage() {
  const { addSnackbar } = useSnackbarStack();
  const [{ tab = 'overview' }, setQuery] = useQuery();
  const { data: profile, error } = useShipperProfile();
  const { data } = useUnratedCarriersCount();

  useTrackEvent('[STMS] Viewed Shipper Profile Page');

  useDocumentTitle('Profile');
  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  if (!profile) {
    return <LoadingContainer />;
  }

  return (
    <PageLayout>
      <ShipperProfileLayout
        header={
          <ShipperProfileHeader
            shipper={profile}
            stats={
              <ShipperRatingStatistics
                ratingStats={profile.rating_stats}
                onClick={() => {
                  setQuery({ tab: 'ratings' });
                }}
              />
            }
          >
            <Tabs value={tab} onChange={(_, value) => setQuery({ tab: value })}>
              <Tab value="overview" label="Overview" />
              <Tab value="ratings" label="Ratings" />
              <StyledTab
                value="ratingsToGive"
                label="Ratings to Give"
                icon={data?.unrated_count ? <Badge /> : undefined}
              />
            </Tabs>
          </ShipperProfileHeader>
        }
      >
        {tab === 'overview' ? (
          <ShipperProfileOverview profile={profile} />
        ) : tab === 'ratings' ? (
          <ShipperRatings shipper={profile} />
        ) : (
          <UnratedCarriers />
        )}
      </ShipperProfileLayout>
    </PageLayout>
  );
}
