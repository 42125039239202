import { useMemo } from 'react';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInputState } from 'shared/helpers/ReactHelpers';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { DictionaryPlaceDTO } from './data/DictionaryDTO';

export function formatPlace({ city, country, state, zip }: DictionaryPlaceDTO) {
  return [city, state, zip, country].filter(Boolean).join(', ');
}

interface PlaceAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<DictionaryPlaceDTO>,
    'options' | 'onChange' | 'onSelect'
  > {
  onChange: (values: DictionaryPlaceDTO[]) => void;
}

export function PlaceAutocomplete({
  onChange,
  ...props
}: PlaceAutocompleteProps) {
  const { input, query, setInput } = useSearchInputState();
  const { isFetching, data = [] } = usePredictions(query);

  const places = useMemo(
    () => data.map(DictionaryPlaceDTO.fromGeocode),
    [data],
  );

  return (
    <AutocompleteBase
      {...props}
      placeholder="3 symbols to search"
      options={places}
      inputValue={input}
      loading={isFetching}
      onChange={(_, value) => onChange(value)}
      onInputChange={(_, value) => setInput(value)}
      getOptionLabel={(option) => formatPlace(option)}
    />
  );
}
