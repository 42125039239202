import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { mapYupErrorsToFormik } from 'shared/form/MapYupErrorsToFormik';
import { camelCaseFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { Prompt } from 'shared/routing/Prompt';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { IdentificationForm } from '../core/IdentificationForm';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  useVerificationApplication,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import {
  VerificationIdentificationDTO,
  verificationIdentificationSchema,
} from '../data/VerificationApplicationDTO';
import {
  VerificationApplicationStepsBox,
  VerificationApplicationStepsWrapper,
} from './VerificationApplicationLayout';
import { VerificationStepper } from './VerificationStepper';

interface VerificationIdentificationForm {
  usdot: string | null | undefined;
  w9_certificate_url: string | File | null;
  dealer_license_url: string | File | null | undefined;
}

interface VerificationApplicationIdentificationProps {
  onSubmitSuccess: () => void;
}

export function VerificationApplicationIdentification({
  onSubmitSuccess,
}: VerificationApplicationIdentificationProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const { updateIdentification, uploadFile } = useVerificationApplicationAPI();

  const initialValues = useInitialValues(
    verificationIdentificationSchema,
    data,
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: verificationIdentificationSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    validate(values) {
      return mapYupErrorsToFormik(() =>
        verificationIdentificationSchema.validateSync(values, {
          abortEarly: false,
          context: {
            companyType: data?.company_type,
          },
        }),
      );
    },
    async onSubmit(values: VerificationIdentificationForm) {
      if (values.w9_certificate_url instanceof File) {
        values.w9_certificate_url = await uploadFile(values.w9_certificate_url);
      }

      if (values.dealer_license_url instanceof File) {
        values.dealer_license_url = await uploadFile(values.dealer_license_url);
      }

      return updateIdentification(values as VerificationIdentificationDTO);
    },
    onSubmitSuccess() {
      onSubmitSuccess();
    },
    onSubmitFailure() {
      addSnackbar(
        'Please ensure all required fields are filled out correctly',
        { variant: 'error' },
      );
    },
  });

  return (
    <FormikProvider value={formik}>
      <Prompt
        when={
          formik.status.type !== 'submitted' &&
          (formik.dirty || formik.isSubmitting)
        }
        message="Changes have not been saved. Leaving will result in unsaved changes being lost."
      />

      <VerificationApplicationStepsWrapper
        step={2}
        onGoBack={() => {
          trackVerificationApplicationEvent({
            name: 'Shipper Clicked Back',
            utm_content: 'Identification',
          });
        }}
      >
        <Stack space="xlarge">
          <VerificationStepper step={2} />
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <Form>
              <VerificationApplicationStepsBox>
                <IdentificationForm />
              </VerificationApplicationStepsBox>
            </Form>
          )}
        </Stack>
      </VerificationApplicationStepsWrapper>
    </FormikProvider>
  );
}
