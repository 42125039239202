import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import { RequirementItem } from 'shared/ui/RequirementItem';
import styled from 'styled-components';
import verifySHipper from '../assets/verify-shipper.png';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';

const VerifyShipperDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 700px;
  }
`;

const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Illustration = styled.img`
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    max-height: 120px;
  }
`;

interface VerificationApplicationDialogContentProps {
  onClose: () => void;
  uid: string;
}

function VerificationApplicationDialogContent({
  onClose,
  uid,
}: VerificationApplicationDialogContentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserState();

  const dialogLocation = location.pathname.includes('profile')
    ? 'Profile'
    : 'Orders';

  useEffect(() => {
    trackVerificationApplicationEvent({
      name: 'Shipper Opened Verification Dialog',
      utm_medium: dialogLocation,
    });
  }, []);

  return (
    <>
      <DialogContent>
        <Stack space="xlarge">
          <Stack space="xsmall" align="center">
            <Typography id={uid} variant="h1" align="center">
              Verify your Shipper Account today!
            </Typography>
            <Typography variant="h4" align="center">
              The first step to getting your vehicles on the road, is
              <br /> to become a Super Dispatch verified shipper.
            </Typography>
          </Stack>
          <Columns space="xsmall" align="center" collapseBelow="tablet">
            <Column width="fluid">
              <IllustrationContainer>
                <Illustration src={verifySHipper} alt="verify shipper" />
              </IllustrationContainer>
            </Column>
            <Column>
              <Stack space="small">
                <RequirementItem
                  iconColor={Color.Green300}
                  text={
                    <Typography variant="h4">
                      Post orders to the Super Loadboard
                    </Typography>
                  }
                />
                <RequirementItem
                  iconColor={Color.Green300}
                  text={
                    <Typography variant="h4">
                      Access the Verified Carrier Network
                    </Typography>
                  }
                />
                <RequirementItem
                  iconColor={Color.Green300}
                  text={
                    <Typography variant="h4">
                      Move cars faster and easier
                    </Typography>
                  }
                />
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack>
          <Inline horizontalAlign="center" space="small">
            <Button variant="neutral" onClick={onClose}>
              Do it Later
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {
                if (user?.shipper.verification_status === 'NOT_SUBMITTED') {
                  navigate({
                    pathname: '/verification-application',
                    search: `?back_url=${
                      location.pathname + location.search
                    }&utm_medium=${dialogLocation}&utm_content=Verification Application Dialog`,
                  });
                } else {
                  navigate({
                    pathname: '/profile',
                    search: `?utm_medium=${dialogLocation}&utm_content=Verification Application Dialog`,
                  });
                }
                onClose();
              }}
            >
              Get Verified Now
            </Button>
          </Inline>
        </Stack>
      </DialogActions>
    </>
  );
}

interface VerificationApplicationDialogProps {
  open: boolean;
  onClose: () => void;
}

export function VerificationApplicationDialog({
  open,
  onClose,
}: VerificationApplicationDialogProps) {
  const uid = useUID();
  return (
    <VerifyShipperDialog
      aria-labelledby={uid}
      open={open}
      onClose={onClose}
      fullWidth={true}
    >
      <ExitTransitionPlaceholder in={open}>
        {open && (
          <VerificationApplicationDialogContent onClose={onClose} uid={uid} />
        )}
      </ExitTransitionPlaceholder>
    </VerifyShipperDialog>
  );
}
