import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Column, Columns } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { RatingResponse } from 'core/ratings/RatingResponse';
import { RatingResponseDeleteDialog } from 'core/ratings/RatingResponseDeleteDialog';
import { useState } from 'react';
import styled from 'styled-components';
import { ShipperProfileDTO } from './data/ShipperProfileDTO';
import { ShipperRatingDTO } from './data/ShipperRatingDTO';
import { ShipperRatingResponseDialog } from './ShipperRatingResponseDialog';

const ResponseBox = styled(Box)`
  padding-left: 56px;
`;
interface Props {
  rating: ShipperRatingDTO;
  shipper: ShipperProfileDTO;
}
export function ShipperRatingResponse({ rating, shipper }: Props) {
  const [dialogs, setDialogs] = useState<'response' | 'delete' | undefined>(
    undefined,
  );

  return (
    <>
      <ShipperRatingResponseDialog
        open={dialogs === 'response'}
        rating={rating}
        onClose={() => setDialogs(undefined)}
      />
      <RatingResponseDeleteDialog
        ratingGuid={rating.guid}
        open={dialogs === 'delete'}
        onClose={() => setDialogs(undefined)}
      />
      {rating.response && rating.response_date ? (
        <Columns align="top" space="none">
          <Column>
            <RatingResponse
              name={shipper.name}
              logo={shipper.logo_url}
              response={rating.response}
              headerText="Response from you"
              responseDate={rating.response_date}
              actions={
                <Columns>
                  <Column width="content">
                    <IconButton
                      onClick={() => setDialogs('response')}
                      aria-label="Update Response"
                    >
                      <Edit color="action" />
                    </IconButton>
                  </Column>

                  <Column width="content">
                    <IconButton
                      aria-label="Delete Response"
                      onClick={() => setDialogs('delete')}
                    >
                      <Delete color="action" />
                    </IconButton>
                  </Column>
                </Columns>
              }
            />
          </Column>
        </Columns>
      ) : (
        <ResponseBox>
          <Button
            aria-label="Add Response"
            onClick={() => setDialogs('response')}
          >
            Add Response
          </Button>
        </ResponseBox>
      )}
    </>
  );
}
