import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Column, Columns } from '@superdispatch/ui';
import { useField, useFormikContext } from 'formik';
import { Info } from 'luxon';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  & .MuiSelect-selectMenu.MuiSelect-selectMenu span:before {
    content: 'Select Month';
    opacity: 0.42;
  }
`;

const getYearMonth = (str: string): [string, string] => {
  const result = /(\d{0,4}).(\d{0,2})/.exec(str); //str is in this format MM.YYYY
  if (result === null) {
    return ['', ''];
  }
  const [, year, month] = result;
  return [String(year), String(month)];
};

function validate(value: string) {
  if (/^\d{4}.$/.test(value)) {
    return 'required:month';
  }
  if (/^.\d{2}$/.test(value)) {
    return 'required:year';
  }
  if (!value) {
    return 'required';
  }
  return undefined;
}

interface FormikBusinessSinceFieldProps {
  name: string;
  label: ReactNode;
}

export function FormikBusinessSinceField({
  name,
  label,
}: FormikBusinessSinceFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [{ value }, { error }, { setValue }] = useField<string | null>({
    name,
    validate,
  });
  const errorMonth = error === 'required:month' || error === 'required';
  const errorYear = error === 'required:year' || error === 'required';
  const [year, month] = getYearMonth(value || '');

  const setFieldValue = (updatedYear: string, updatedMonth: string) => {
    setValue(
      !updatedYear && !updatedMonth ? null : `${updatedYear}.${updatedMonth}`,
    );
  };

  return (
    <FormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Columns space="xsmall">
        <Column width="1/2">
          <StyledTextField
            data-testid="in business since month"
            value={month}
            select={true}
            fullWidth={true}
            error={errorMonth}
            helperText={errorMonth ? 'Select Month' : ''}
            disabled={isSubmitting}
            onChange={(event) => setFieldValue(year, event.target.value)}
          >
            {Info.months('long').map((monthName, index) => (
              <MenuItem key={index} value={String(index + 1).padStart(2, '0')}>
                {monthName}
              </MenuItem>
            ))}
          </StyledTextField>
        </Column>
        <Column width="1/2">
          <TextField
            data-testid="in business since year"
            value={year}
            placeholder="Enter Year"
            InputProps={{
              inputProps: { min: 1900, max: new Date().getFullYear() },
            }}
            type="number"
            fullWidth={true}
            error={errorYear}
            helperText={errorYear ? 'Enter Year' : ''}
            disabled={isSubmitting}
            onChange={(event) =>
              event.target.value.length < 5
                ? setFieldValue(event.target.value, month)
                : null
            }
          />
        </Column>
      </Columns>
    </FormControl>
  );
}
