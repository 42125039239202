import { Box, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FormikCheckboxField } from '@superdispatch/forms';
import { CheckboxField } from '@superdispatch/ui';
import { Fragment, useMemo } from 'react';
import { FormikCheckboxController } from 'shared/form/FormikCheckboxController';
import { Divider } from 'shared/ui/Divider';
import styled from 'styled-components';
import { ReportFieldGroupDTO } from './data/ReportFieldDTO';
import { useReportFieldGroup } from './data/ReportsAPI';

const StyledCard = styled(Card)`
  overflow: visible;
`;
const getFieldNames = (fields?: ReportFieldGroupDTO[]) =>
  fields ? fields.flatMap((x) => x.fields.map((field) => field.name)) : [];

export function ReportsFields() {
  const { data: group, isLoading } = useReportFieldGroup();
  const fieldNames = useMemo(
    () => getFieldNames(group?.objects),
    [group?.objects],
  );
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" flexDirection="column" pb={3}>
          <Box pb="4px">
            {isLoading ? (
              <Skeleton width="150px" />
            ) : (
              <FormikCheckboxController
                name="fields"
                fields={fieldNames}
                render={(props) => (
                  <CheckboxField {...props} label="Report Content" />
                )}
              />
            )}
          </Box>
        </Box>

        {group?.objects.map(({ title, fields }) => (
          <div aria-label={`${title} fields`} key={title}>
            <Divider />

            <Box display="flex" flexDirection="column" py={3}>
              <Box mb={2}>
                <FormikCheckboxController
                  name="fields"
                  fields={fields.map((x) => x.name)}
                  render={(props) => <CheckboxField {...props} label={title} />}
                />
              </Box>

              <Box display="flex" flexWrap="wrap">
                {fields.map(({ label, name }) => (
                  <Box key={name} width={1 / 2} py="4px">
                    <FormikCheckboxField
                      label={label}
                      name={`fields.${name}`}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </div>
        ))}

        {isLoading &&
          Array.from({ length: 8 }, (_, idx) => (
            <Fragment key={idx}>
              <Divider />

              <Box display="flex" flexDirection="column" py={3}>
                <Box mb={2}>
                  <Skeleton width="150px" />
                </Box>

                <Box display="flex" flexWrap="wrap">
                  {Array.from({ length: 8 }, (__, index) => (
                    <Box key={index} width={1 / 2} my="10px">
                      <div>
                        <Skeleton width="200px" />
                      </div>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Fragment>
          ))}
      </CardContent>
    </StyledCard>
  );
}
