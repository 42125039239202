import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type PaymentOnHoldDTO = InferType<typeof paymentOnHoldSchema>;

export const paymentOnHoldSchema = yupObject({
  custom_on_hold_reason: yupString().default(''),
});

export type MarkAsDeliveredDTO = InferType<typeof markAsDeliveredSchema>;

export const markAsDeliveredSchema = yupObject({
  custom_on_hold_reason: yupString().default(''),
  adjusted_pickup_date: yupString().nullable(),
  adjusted_delivery_date: yupString().nullable(),
  initiate_payment: yupBoolean().nullable(),
});
