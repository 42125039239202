import { Link, Typography } from '@material-ui/core';
import { Box, Flex, FlexProps } from '@rebass/grid';
import * as React from 'react';
import { DriveawayIcon as DriverIcon } from 'shared/icons/DriveawayIcon';

interface DriverProps extends Omit<FlexProps, 'name'> {
  name?: string | null;
  phone?: string | null;
}

export const OrderViewDriverInfo: React.ComponentType<DriverProps> = ({
  name,
  phone,
  ...props
}) => (
  <Flex {...props}>
    <Box mr={2}>
      <DriverIcon fontSize="small" />
    </Box>

    <Box>
      <Typography variant="body1">Driver</Typography>

      <Flex mt={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          aria-label="driver name"
        >
          {name}
        </Typography>
      </Flex>

      {phone && (
        <Flex mt={1}>
          <Link href={`tel:${phone}`} aria-label="driver phone">
            {phone}
          </Link>
        </Flex>
      )}
    </Box>
  </Flex>
);
