import { OrderFormValues } from 'orders/core/form/OrderForm';
import { useAPI } from 'shared/api/API';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import Order from 'shared/types/order';
import { yupNumber, yupObject } from 'shared/utils/YupUtils';

function mapOrderToPricingInsights(order: Partial<Order> | OrderFormValues) {
  return {
    trailer_type: order.transport_type,
    origin: {
      zip: order.pickup?.venue?.zip,
      city: order.pickup?.venue?.city,
      state: order.pickup?.venue?.state,
    },
    destination: {
      zip: order.delivery?.venue?.zip,
      city: order.delivery?.venue?.city,
      state: order.delivery?.venue?.state,
    },
    vehicles: order.vehicles?.map((vehicle) => ({
      type: vehicle.type,
      year: vehicle.year,
      is_inoperable: !!vehicle.is_inoperable,
    })),
  };
}

const recommendedPriceSchema = yupObject({
  total_price: yupNumber(),
  price_per_mile: yupNumber(),
  distance_miles: yupNumber(),
});

export function usePricingRecommendation(
  order?: Partial<Order> | OrderFormValues,
  options?: UseAPIQueryOptions<number | null>,
) {
  const payload = order ? mapOrderToPricingInsights(order) : undefined;
  const json = useDebouncedValue(payload, 350);
  const { requestResource } = useAPI();
  return useAPIQuery(
    ['order', 'pricing-recommendation', json],
    async () => {
      if (!json) {
        throw new Error('Invalid order');
      }

      return requestResource(
        'POST /internal/recommended_price',
        (response) => recommendedPriceSchema.cast(response),
        { json },
      ).then((response) => response.total_price);
    },
    { enabled: !!json, refetchOnWindowFocus: false, ...options },
  );
}
