import { Exclude, Expose } from 'class-transformer';
import { CarrierType } from './ManageCarriersAPI';

@Exclude()
export class CarriersPageParamsDTO {
  @Expose() sort: string[];
  @Expose() page: number;
  @Expose() page_size: number;
  @Expose() carrier_type?: CarrierType;
  @Expose() group_guid?: string;
}
