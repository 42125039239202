import {
  Card,
  CardContent,
  Divider,
  Link,
  Switch,
  Typography,
} from '@material-ui/core';
import { FormikNumberField, FormikRadioGroupField } from '@superdispatch/forms';
import { RadioField, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import styled from 'styled-components';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { usePermission } from '../../shared/data/UserPermissions';
import { SuperPayUpdateSettingsDTO } from '../data/SuperPayDTO';

const SpaceBetweenWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export function ExpeditedPaySettings() {
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const isCarrierEligibility = useFeatureToggle(
    'payments.expedited.pay.carrier.eligibility.access.ui',
  );
  const [{ value: expeditedPayValue }, , { setValue }] = useField<
    SuperPayUpdateSettingsDTO['expedited_pay_available']
  >('expedited_pay_available');
  const isMobile = useResponsiveValue(true, false);
  return (
    <Card square={isMobile} aria-label="expedited pay">
      <CardContent>
        <Stack>
          <SpaceBetweenWrapper>
            <Typography
              variant="h3"
              color={expeditedPayValue ? 'initial' : 'textSecondary'}
            >
              Expedite Payment
            </Typography>
            <Switch
              disabled={!canManageSuperPay}
              checked={!!expeditedPayValue}
              onChange={(event) => setValue(event.target.checked)}
            />
          </SpaceBetweenWrapper>
          <Stack space="medium">
            <Typography color={expeditedPayValue ? 'initial' : 'textSecondary'}>
              Allow carriers to initiate faster payments after delivery for an
              additional fee.
            </Typography>
            {expeditedPayValue && (
              <Stack space="medium">
                <Stack space="small">
                  <SpaceBetweenWrapper>
                    <Typography variant="h5" color="textSecondary">
                      Payment Terms
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                      Carrier Fee
                    </Typography>
                  </SpaceBetweenWrapper>
                  <SpaceBetweenWrapper>
                    <Typography>1-3 Business Days</Typography>
                    <Box width="100px">
                      <FormikNumberField
                        disabled={!canManageSuperPay}
                        name="expedited_pay_fee"
                        fullWidth={true}
                        inputProps={{ decimalScale: 0 }}
                        InputProps={{
                          endAdornment: (
                            <TextBox color="secondary" variant="body-semibold">
                              %
                            </TextBox>
                          ),
                        }}
                      />
                    </Box>
                  </SpaceBetweenWrapper>
                </Stack>
                {isCarrierEligibility && (
                  <>
                    <Divider />
                    <FormikRadioGroupField name="expedited_pay_eligibility">
                      <RadioField
                        disabled={!canManageSuperPay}
                        value="ALL_ALLOWED"
                        label="Allow All Eligible Carriers to Expedite Payment"
                        helperText="All SuperPay orders will be eligible for faster payments. Blocklisted carriers are not eligible to book or initiate expedited payments."
                      />
                      <RadioField
                        disabled={!canManageSuperPay}
                        value="CHOSEN_ALLOWED"
                        label="Customize Carrier Eligibility to Expedite Payment"
                        helperText={
                          <span>
                            To enable expedited payments, select a carrier in{' '}
                            <Link
                              href="/manage-carriers"
                              target="_blank"
                              rel="noreferrer"
                              color="inherit"
                            >
                              Manage Carriers
                            </Link>{' '}
                            and manage eligibility in their Internal Records.
                          </span>
                        }
                      />
                    </FormikRadioGroupField>
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
