import {
  Box,
  Button as LinkButton,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { WorkOutline } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import {
  CUSTOMER_TYPES,
  formatCustomerType,
  formatDateType,
  listDateTypes,
} from '@superdispatch/sdk';
import {
  Column,
  Columns,
  InfoTooltip,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { CounterpartyAddress } from 'orders/core/form/counterparty/CounterpartyAddress';
import { LoadCounterpartyContactFields } from 'orders/core/form/counterparty/LoadCounterpartyContactFields';
import {
  TerminalAutocomplete,
  TerminalAutocompleteValue,
  TerminalChangeReason,
  useTerminalAutocompleteProps,
} from 'orders/core/form/counterparty/TerminalAutocomplete';
import { useEffect, useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { TerminalContactDTO } from 'shared/dto/TerminalContactDTO';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { OrderContactDTO } from 'shared/types/order';
import { AnyObject } from 'shared/types/Utils';
import { required } from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';
import { LoadStepScheduledDateField } from './LoadStepScheduledDateField';

const StepTitle = styled(Typography)`
  text-transform: capitalize;
`;

const styleLabelText = (text: string) => (
  <Typography color="textPrimary">{text}</Typography>
);

function validateCounterparty(value: TerminalAutocompleteValue | undefined) {
  const error = required(value?.name);
  if (error) return { name: error };
  return undefined;
}

export function LoadStepCounterpartyFields({
  name,
  title,
  order,
  index,
}: {
  name: string;
  title: 'pickup' | 'delivery';
  order?: AnyObject;
  index: number;
}) {
  const { values, setFieldValue } = useFormikContext<BuildLoadsFormValues>();
  const { user } = useUserState();

  const isNewCounterParty =
    get(values, `${name}..save_as_new`) &&
    !get(values, `${name}.save_as_recent`);

  const isNewContact = get(values, `${name}.save_as_new_contact`);

  const counterpartyGuid = get(
    values.loads[index],
    `${title}.counterparty_guid`,
  );

  const adjustedName = `${name}.venue`;

  const autocompleteProps = useTerminalAutocompleteProps();

  const contacts = useMemo(() => {
    const option = autocompleteProps.options.find(
      (x) => x.guid === counterpartyGuid,
    );

    return option?.contacts;
  }, [autocompleteProps.options, counterpartyGuid]);

  const isCustomerVisible = user
    ? user.order_form_settings.is_customer_block_visible ||
      (user.order_form_settings.customer.is_address_visible &&
        order?.customer?.address) ||
      (user.order_form_settings.customer.is_address_visible &&
        order?.customer?.city) ||
      (user.order_form_settings.customer.is_address_visible &&
        order?.customer?.state) ||
      (user.order_form_settings.customer.is_address_visible &&
        order?.customer?.zip) ||
      order?.customer?.name ||
      (user.order_form_settings.customer.is_notes_visible &&
        order?.customer?.notes)
    : false;

  const showSameAsCustomerButton =
    order?.customer &&
    user?.shipper.shipper_type === 'BROKER' &&
    isCustomerVisible;

  const { isAdvancedTier } = useProductTiers();

  useEffect(() => {
    const counterparty = autocompleteProps.options.find(
      (x) => x.guid === counterpartyGuid,
    );

    if (counterparty && !!autocompleteProps.loading) {
      handleCounterpartyChange(counterparty, 'select-option');
    }
  }, [autocompleteProps.options, counterpartyGuid]);

  function handleCounterpartyChange(
    counterparty: TerminalAutocompleteValue,
    reason: TerminalChangeReason,
  ) {
    if (reason === 'clear') {
      setFieldValue(`${name}.notes`, null);
      setFieldValue(`${name}.save_as_new`, false);
      setFieldValue(`${name}.save_as_new_contact`, false);
      setFieldValue(`${name}.counterparty_guid`, null);
    } else if (reason === 'dont-save') {
      setFieldValue(`${name}.save_as_new`, false);
    } else {
      setFieldValue(`${name}.counterparty_guid`, counterparty.guid);
      setFieldValue(`${name}.notes`, counterparty.notes);
      setFieldValue(`${name}.save_as_new`, reason === 'save-as-new');

      setFieldValue(`${adjustedName}.address`, counterparty.address);
      setFieldValue(`${adjustedName}.city`, counterparty.city);
      setFieldValue(
        `${adjustedName}.business_type`,
        user?.order_form_settings[title].venue.is_business_type_visible
          ? counterparty.business_type
          : null,
      );
      setFieldValue(`${adjustedName}.state`, counterparty.state);
      setFieldValue(`${adjustedName}.zip`, counterparty.zip);

      setFieldValue(
        `${adjustedName}.selected_from_recent`,
        counterparty.save_as_recent,
      );

      if (
        typeof counterparty.save_as_recent !== 'undefined' &&
        !get(values, `${adjustedName}.save_as_recent`)
      ) {
        setFieldValue(`${adjustedName}.save_as_new`, false);
      }

      const { contacts: _contacts = [] } = counterparty;

      if (_contacts.length > 0) {
        const contact = (_contacts.find(({ is_primary }) => is_primary) ||
          _contacts[0]) as TerminalContactDTO;

        const {
          is_contact_mobile_phone_visible,
          is_contact_email_visible,
          is_contact_title_visible,
        } = user?.order_form_settings[title].venue ?? {};

        setFieldValue(`${adjustedName}.contact`, {
          ...contact,
          mobile_phone: is_contact_mobile_phone_visible
            ? contact.mobile_phone
            : '',
          email: is_contact_email_visible ? contact.email : '',
          title: is_contact_title_visible ? contact.title : '',
        });
      }
    }
  }

  function createCounterpartyFromCustomer(
    customer: OrderContactDTO,
  ): TerminalAutocompleteValue {
    return {
      name: customer.name,
      address: customer.address,
      city: customer.city,
      state: customer.state,
      zip: customer.zip,
      business_type: customer.business_type,
      guid: customer.counterparty_guid,
      notes: customer.notes,
      contacts: [
        {
          name: customer.contact_name ?? '',
          phone: customer.contact_name ?? '',
          email: customer.contact_name ?? '',
          mobile_phone: customer.contact_name ?? '',
          title: customer.contact_name ?? '',
        },
      ],
    };
  }

  function handleSameAsCustomerClick() {
    if (!order?.customer) {
      return;
    }

    handleCounterpartyChange(
      createCounterpartyFromCustomer(order.customer),
      'select-option',
    );
  }

  function handleSameAsOriginalStepClick() {
    const originalTerminal = order?.[title];

    if (!originalTerminal) {
      return;
    }

    setFieldValue(name, originalTerminal);

    const {
      is_contact_mobile_phone_visible,
      is_contact_email_visible,
      is_contact_title_visible,
    } = user?.order_form_settings[title].venue ?? {};

    const {
      contact_title,
      contact_email,
      contact_name,
      contact_phone,
      contact_mobile_phone,
    } = originalTerminal?.venue ?? {};

    const contact = {
      title: is_contact_title_visible ? contact_title : '',
      email: is_contact_email_visible ? contact_email : '',
      name: contact_name,
      phone: contact_phone,
      mobile_phone: is_contact_mobile_phone_visible ? contact_mobile_phone : '',
    };

    setFieldValue(`${adjustedName}.contact`, contact);

    trackEventLegacy(`[Build Loads] Clicked same as ${title}`);
  }

  return (
    <Box>
      <Stack space="medium">
        <Columns align="center">
          <Column width="fluid">
            <StepTitle variant="h3">{title}</StepTitle>
          </Column>
          <Column width="content">
            <LinkButton
              color="primary"
              variant="text"
              type="button"
              startIcon={<WorkOutline />}
              onClick={handleSameAsOriginalStepClick}
            >
              Same as Original {title === 'pickup' ? 'Pickup' : 'Delivery'}
            </LinkButton>
          </Column>
        </Columns>

        <Stack space="small">
          <Grid container={true} spacing={2} wrap="wrap">
            <Grid item={true} xs={12} md={8}>
              <TerminalAutocomplete
                {...autocompleteProps}
                name={adjustedName}
                isNew={isNewCounterParty}
                onChange={handleCounterpartyChange}
                validate={
                  isNewCounterParty || isNewContact
                    ? validateCounterparty
                    : undefined
                }
                label={
                  <Box display="flex" justifyContent="space-between">
                    <Inline>
                      <Typography>Business Name</Typography>

                      {isNewCounterParty && (
                        <Tag variant="subtle" color="blue">
                          New Terminal
                        </Tag>
                      )}
                    </Inline>

                    {showSameAsCustomerButton && isAdvancedTier && (
                      <Link onClick={handleSameAsCustomerClick}>
                        Same as Customer
                      </Link>
                    )}
                  </Box>
                }
              />
            </Grid>

            {user?.order_form_settings[title].venue
              .is_business_type_visible && (
              <Grid item={true} xs={12} md={4}>
                <FormikTextField
                  label="Type"
                  fullWidth={true}
                  select={true}
                  name={`${adjustedName}.business_type`}
                >
                  {CUSTOMER_TYPES.map((value) => (
                    <MenuItem key={value} value={value}>
                      {formatCustomerType(value)}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Grid>
            )}
          </Grid>

          <CounterpartyAddress
            name={adjustedName}
            setFieldValue={setFieldValue}
          />

          {values.loads[index] && (
            <LoadCounterpartyContactFields
              contacts={contacts}
              isNew={isNewContact}
              name={adjustedName}
              stepName={title}
              showContinue={true}
              values={values.loads[index]}
              onChange={(_, reason) => {
                setFieldValue(
                  `${adjustedName}.save_as_new_contact`,
                  reason === 'save-as-new',
                );
              }}
            />
          )}

          <Grid container={true} spacing={2} wrap="wrap">
            <Grid item={true} xs={12} md={6}>
              <FormikTextField
                select={true}
                fullWidth={true}
                label={styleLabelText('Date type')}
                name={`${name}.date_type`}
              >
                {listDateTypes().map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatDateType(value)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LoadStepScheduledDateField
                values={values.loads[index]}
                type={title}
                scheduledAtName={`${name}.scheduled_at`}
                scheduledEndsAtName={`${name}.scheduled_ends_at`}
                enableClearable={true}
                label={
                  user?.shipper.shipper_type === 'BROKER'
                    ? title === 'pickup'
                      ? 'Carrier Pickup Date'
                      : 'Carrier Delivery Date'
                    : title === 'pickup'
                    ? 'Pickup Date'
                    : 'Delivery Date'
                }
              />
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <FormikTextField
              name={`${name}.notes`}
              label={
                <Box display="flex" justifyContent="space-between">
                  <InfoTooltip
                    title="Visible to carrier after accepting the load offer"
                    TextProps={{ color: 'textPrimary' }}
                  >
                    Notes
                  </InfoTooltip>

                  <TextBox color="secondary">
                    {`${
                      values.loads[index]?.[title].notes?.length || 0
                    } of 2000`}
                  </TextBox>
                </Box>
              }
              multiline={true}
              fullWidth={true}
              inputProps={{
                maxLength: 2000,
              }}
            />
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}
