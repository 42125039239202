import { Box } from '@superdispatch/ui-lab';
import { ReactNode, useEffect, useRef } from 'react';
import { useQuery } from 'shared/helpers/RouteHelpers';

interface ScrollToTargetProps {
  children: ReactNode;
  targetKey?: string;
  targetValue: string;
}

export function ScrollToTarget({
  children,
  targetKey = 'target',
  targetValue,
}: ScrollToTargetProps) {
  const [queryParam] = useQuery();
  const isScrollIntoView = queryParam[targetKey] === targetValue;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current && isScrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isScrollIntoView]);

  return <Box ref={ref}>{children}</Box>;
}
