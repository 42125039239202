import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Button, ButtonProps } from '@superdispatch/ui';
import { ReactNode } from 'react';

export interface ConfirmDialogProps
  extends Omit<DialogProps, 'title' | 'onClose'> {
  onClose?: () => void;

  title?: ReactNode;
  cancelButtonProps?: Partial<ButtonProps>;
  confirmButtonProps?: Partial<ButtonProps>;
}

export function ConfirmDialog({
  onClose,
  title,
  children,
  cancelButtonProps,
  confirmButtonProps,

  maxWidth = 'xs',
  fullWidth = true,

  ...props
}: ConfirmDialogProps) {
  return (
    <Dialog
      {...props}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {title && (
        <DialogTitle disableTypography={true}>
          <Typography variant="h3">{title}</Typography>
        </DialogTitle>
      )}

      {children && <DialogContent>{children}</DialogContent>}

      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
          {...cancelButtonProps}
        >
          {cancelButtonProps?.children || 'Cancel'}
        </Button>
        <Button
          autoFocus={true}
          color="primary"
          variant="contained"
          {...confirmButtonProps}
        >
          {confirmButtonProps?.children || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
