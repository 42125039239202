import { plainToClass } from 'class-transformer';
import { useOrderCache, useOrdersCache } from 'orders/data/OrderAPI';
import { useEffect } from 'react';
import {
  getFCMCurrentToken,
  setFCMCurrentToken,
  useFirebaseMessaging,
} from 'shared/helpers/FirebaseHelpers';
import { useNotificationSettings } from '../../../notification-settings/data/NotificationSettingsAPI';
import { useUserState } from '../AppUserState';
import {
  useNotificationAPI,
  useUnreadNotificationsCache,
} from './NotificationAPI';
import { NotificationDTO } from './NotificationDTO';

interface FirebaseMessagePayload {
  title: string;
  body: string;
  click_action: string;
  event_type: string;
  icon: string;
  link: string;
  order_guid: string;
}

export function useNotificationHandler() {
  const messaging = useFirebaseMessaging();
  const { authState } = useUserState();
  const { resubscribeToPushNotification, subscribeToPushNotification } =
    useNotificationAPI();
  const { data: notificationSettings } = useNotificationSettings(
    false,
    authState !== 'authorized',
  );

  const { invalidateOrders } = useOrdersCache();
  const { invalidateOrder } = useOrderCache();

  useEffect(() => {
    if (
      messaging &&
      authState === 'authorized' &&
      notificationSettings?.is_personal_push_notifications_enabled
    ) {
      void messaging.requestAndGetToken().then((token) => {
        const currentToken = getFCMCurrentToken();

        // Resubscribe in case backend is not synced
        if (currentToken) {
          return resubscribeToPushNotification(currentToken, token).then(() => {
            setFCMCurrentToken(token);
          });
        }

        // Subscribe for first FCM token generation
        return resubscribeToPushNotification(token, null).then(() => {
          setFCMCurrentToken(token);
        });
      });
    }
  }, [
    authState,
    messaging,
    resubscribeToPushNotification,
    subscribeToPushNotification,
    notificationSettings?.is_personal_push_notifications_enabled,
  ]);

  const { setQueryData: setUnreadNotificationsData } =
    useUnreadNotificationsCache();

  useEffect(() => {
    if (!messaging || authState !== 'authorized') {
      return;
    }

    return messaging.onMessage(({ data }) => {
      if (!data) {
        return;
      }
      const { body, click_action, event_type, link, order_guid, title } =
        data as unknown as FirebaseMessagePayload;

      const guid = new URL(link).searchParams.get('notification_guid');

      setUnreadNotificationsData((prev) => ({
        ...prev,
        objects: [
          plainToClass(NotificationDTO, {
            link,
            body,
            title,
            guid,
            order_guid,
            type: event_type,
          }),
          ...prev.objects,
        ],
      }));

      void invalidateOrders();
      void invalidateOrder(order_guid);

      void navigator.serviceWorker.ready.then((registration) => {
        return registration.showNotification(title, {
          body,
          data: { click_action },
          icon: new URL('/logo.png', window.location.origin).href,
        });
      });
    });
  }, [authState, messaging]);
}
