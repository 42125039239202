import { Stack } from '@superdispatch/ui';
import { DisplayCard } from '../DisplayCard';
import { useSubscriptionPlan } from '../useSubscriptionPlan';
import { OverviewSection } from './OverviewSection';
import { PricingSection } from './PricingSection';

export function SubscriptionMonthlyUsageCard() {
  const { plan } = useSubscriptionPlan();

  if (!plan || plan.isNonStandard) {
    return null;
  }

  return (
    <DisplayCard title="Monthly Usage">
      <Stack space="large">
        <OverviewSection />

        {plan.monthlyPlanPrice !== null && (
          <PricingSection
            maxPlanVehicles={plan.maxPlanVehicles}
            monthlyPlanPrice={plan.monthlyPlanPrice}
            planName={plan.plan}
          />
        )}
      </Stack>
    </DisplayCard>
  );
}
