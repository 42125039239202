import { Box, Tooltip, Typography, TypographyProps } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import * as React from 'react';
import { CustomerContactDTO } from 'shared/dto/CustomerContactDTO';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { useModalState } from 'shared/helpers/ModalHelpers';
import { ContactCreateDrawer, ContactEditDrawer } from './CustomerContactForm';
import { CustomerDetailsContacts } from './CustomerDetailsContacts';
import { CustomerDetailsInfo } from './CustomerDetailsInfo';
import { CustomerDetailsInfoEdit } from './CustomerDetailsInfoEdit';
import { CustomerDetailsInstructions } from './CustomerDetailsInstructions';
import { CustomerDetailsInstructionsEdit } from './CustomerDetailsInstructionsEdit';
import { CustomerDetailsNotes } from './CustomerDetailsNotes';
import { CustomerDetailsNotesEdit } from './CustomerDetailsNotesEdit';

export const LabelRequired = ({ text }: { text: string }) => (
  <Box display="flex">
    <Box mr="4px">{text}</Box>
    <Box color={Color.Grey100}>(Required)</Box>
  </Box>
);

export const LabelInfo = ({
  text,
  tooltip,
  TextProps,
}: {
  text: React.ReactNode;
  tooltip: string;
  TextProps?: TypographyProps;
}) => (
  <Box display="flex" alignItems="center">
    <Box mr="4px">
      <Typography variant="h4" {...TextProps}>
        {text}
      </Typography>
    </Box>

    <Tooltip title={tooltip}>
      <Info htmlColor="#979ca4" fontSize="small" />
    </Tooltip>
  </Box>
);

type ModalState =
  | { type: 'add-contact'; payload?: never }
  | { type: 'customer-info'; payload?: never }
  | { type: 'notes'; payload?: keyof CustomerDTO }
  | { type: 'instructions'; payload?: keyof CustomerDTO }
  | { type: 'edit-contact'; payload?: CustomerContactDTO };

interface Props {
  customer: CustomerDTO;
}

export function CustomerDetails({ customer }: Props) {
  const { modal, closeModal, getPayload, setModal } =
    useModalState<ModalState>();

  return (
    <>
      <ContactCreateDrawer
        customer={customer}
        onClose={closeModal}
        onSuccess={closeModal}
        open={modal?.type === 'add-contact'}
      />

      <ContactEditDrawer
        customer={customer}
        onClose={closeModal}
        onSuccess={closeModal}
        contact={getPayload('edit-contact')}
        open={modal?.type === 'edit-contact'}
      />

      <CustomerDetailsInstructionsEdit
        customer={customer}
        onClose={closeModal}
        onUpdateSuccess={closeModal}
        open={modal?.type === 'instructions'}
        autoFocusField={getPayload('instructions')}
      />

      <CustomerDetailsNotesEdit
        customer={customer}
        onClose={closeModal}
        onUpdateSuccess={closeModal}
        open={modal?.type === 'notes'}
        autoFocusField={getPayload('instructions')}
      />

      <CustomerDetailsInfoEdit
        customer={customer}
        onClose={closeModal}
        onUpdateSuccess={closeModal}
        open={modal?.type === 'customer-info'}
      />

      <Columns collapseBelow="desktop" space="small">
        <Column width="1/3">
          <Stack space="small">
            <CustomerDetailsInfo
              customer={customer}
              onEdit={() => {
                setModal({ type: 'customer-info' });
              }}
            />

            <CustomerDetailsContacts
              customer={customer}
              onCreate={() => {
                setModal({ type: 'add-contact' });
              }}
              onEdit={(contact) => {
                setModal({ type: 'edit-contact', payload: contact });
              }}
            />
          </Stack>
        </Column>

        <Column width="2/3">
          <Stack space="small">
            <CustomerDetailsNotes
              customer={customer}
              onEdit={() => setModal({ type: 'notes' })}
              onAddNotes={() => {
                setModal({ type: 'notes', payload: 'notes' });
              }}
              onAddInternalNotes={() => {
                setModal({ type: 'notes', payload: 'internal_notes' });
              }}
            />

            <CustomerDetailsInstructions
              customer={customer}
              onEdit={() => setModal({ type: 'instructions' })}
              onAddOrderInstruction={() => {
                setModal({
                  type: 'instructions',
                  payload: 'order_instructions',
                });
              }}
              onAddLoadboardInstruction={() => {
                setModal({
                  type: 'instructions',
                  payload: 'loadboard_instructions',
                });
              }}
            />
          </Stack>
        </Column>
      </Columns>
    </>
  );
}
