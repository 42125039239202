import { Flex, FlexProps } from '@rebass/grid';
import styled from 'styled-components';

interface DividerProps extends FlexProps {
  isVertical?: boolean;
}

/** @deprecated */
export const Divider = styled(Flex)`
  ${({ isVertical }: DividerProps) =>
    isVertical ? 'border-right' : 'border-bottom'}: solid 1px #eaebec;
`;
