import { ReactElement, useMemo } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { Order } from 'shared/types/order';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import {
  calculatePriceNegotiationInfo,
  PriceNegotiationDTO,
} from '../../../data/dto/PriceNegotiationDTO';

interface PriceNegotiationTagProps {
  order: Order;
  priceNegotiation: PriceNegotiationDTO;
  children: ReactElement;
}

export function PriceNegotiationTooltip({
  order,
  children,
  priceNegotiation,
}: PriceNegotiationTagProps) {
  const { currentPrice, currentIteration, currentDay } = useMemo(
    () => calculatePriceNegotiationInfo(order.price, priceNegotiation),
    [order.price, priceNegotiation],
  );
  const formattedCurrentPrice = formatCurrency(currentPrice, {
    maximumFractionDigits: 0,
  });
  return (
    <ResponsiveTooltip
      title={
        !priceNegotiation.increase_by_percent || currentIteration === 0 ? (
          <>Acceptable Bid - {formattedCurrentPrice}</>
        ) : (
          <>
            <b>Acceptable Bid</b> <br />
            Day {currentDay} - {formattedCurrentPrice}
          </>
        )
      }
    >
      {children}
    </ResponsiveTooltip>
  );
}
