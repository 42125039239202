import { NullableDateString } from '@superdispatch/dates';
import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIMutation } from 'shared/api/APIMutation';
import { MarkAsDeliveredDTO, PaymentOnHoldDTO } from 'shared/dto/ActionsDTO';
import { OrderDTO } from 'shared/dto/Order/OrderDTO';
import { CreateOfferPayload, Offer, OfferPayload } from 'shared/types/offer';
import { valuesToPlain } from 'shared/utils/DataUtils';
import { InvoiceDTO } from './dto/InvoiceDTO';
import {
  MarkAsCustomerPaidPayloadDTO,
  MarkAsPaidPayloadDTO,
} from './dto/MarkAsPaidPayloadDTO';
import { useOrdersCache } from './OrderAPI';
import { useOrderPaymentFlagsCache } from './OrderPaymentFlagsAPI';

type LoadboardTarget = 'cd' | 'sdlb';

export function useSingleOrderActionAPI() {
  const queryClient = useQueryClient();
  const { request, requestResource } = useAPI();

  const { invalidateOrders } = useOrdersCache();
  const { invalidateOrderPaymentFlags } = useOrderPaymentFlagsCache();

  return useMemo(() => {
    const invalidateAfterResponse = <T>(responsePromise: Promise<T>) => {
      return responsePromise.then((data) => {
        void invalidateOrders();
        return data;
      });
    };

    return {
      postToLoadboards: (orderGuid: string, loadboards: LoadboardTarget[]) =>
        request('POST /internal/loadboard/orders/{orderGuid}{?lb}', {
          orderGuid,
          lb: loadboards.join(','),
        }),
      postToPrivateNetwork: (orderGuid: string, groupGuid?: string) =>
        request('POST /internal/loadboard/orders/{orderGuid}/private-network', {
          orderGuid,
          json: { private_network_groups: groupGuid ? [groupGuid] : [] },
        }),
      unpostFromLoadboards: (
        orderGuid: string,
        loadboards: LoadboardTarget[],
      ) =>
        request('DELETE /internal/loadboard/orders/{orderGuid}{?lb}', {
          orderGuid,
          lb: loadboards.join(','),
        }),
      submitToBroker: (orderId: number) =>
        requestResource(
          'POST /internal/orders/{orderId}/submit_to_broker',
          (data) => plainToClass(OrderDTO, data),
          { orderId },
        ),
      markAsCarrierInvoiced: (
        orderId: number,
        data: { adjusted_invoice_date: NullableDateString },
      ) =>
        requestResource(
          'PUT /internal/orders/{orderId}/mark_as_carrier_invoiced',
          (responseData) => plainToClass(OrderDTO, responseData),
          { orderId, json: data },
        ),
      markAsOnHold: (orderId: number) =>
        requestResource(
          'PUT /internal/orders/{orderId}/mark_as_on_hold',
          (data) => plainToClass(OrderDTO, data),
          { orderId },
        ),
      markAsPaymentOnHold: (orderId: number, values: PaymentOnHoldDTO) =>
        requestResource(
          'PUT /internal/orders/{orderId}/mark_as_payment_on_hold',
          (data) => plainToClass(OrderDTO, data),
          { orderId, json: values },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      markAsPending: (orderId: number, offer?: OfferPayload) =>
        requestResource(
          'PUT /internal/orders/{orderId}/mark_as_pending',
          (data) => plainToClass(OrderDTO, data),
          { orderId, json: offer },
        ),
      markAsAccepted: (orderId: number, offer?: OfferPayload) =>
        requestResource(
          'PUT /internal/orders/{orderId}/mark_as_accepted',
          (data) => plainToClass(OrderDTO, data),
          { orderId, json: offer },
        ),
      flagOrder: (orderID: number) =>
        request('PUT /internal/orders/{orderID}/flag', { orderID }),
      unflagOrder: (orderID: number) =>
        request('PUT /internal/orders/{orderID}/unflag', { orderID }),
      duplicateOrder: (orderGUID: string) =>
        invalidateAfterResponse<OrderDTO>(
          requestResource(
            'POST /internal/orders/{orderGUID}/create_duplicate',
            (data) => plainToClass(OrderDTO, data),
            { orderGUID },
          ),
        ),
      archiveOrder: (orderID: number) =>
        request('PUT /internal/orders/{orderID}/archive', { orderID }),
      unarchiveOrder: (orderID: number) =>
        request('PUT /internal/orders/{orderID}/unarchive', { orderID }),
      sendInvoice: (orderID: number, invoice: InvoiceDTO) =>
        requestResource(
          'PUT /internal/orders/{orderID}/send_customer_invoice',
          (data) => plainToClass(OrderDTO, data),
          { orderID, json: invoice },
        ),
      rollbackManualStatusChange: (
        orderID: number,
        values?: PaymentOnHoldDTO,
      ) =>
        requestResource(
          'PUT /internal/orders/{orderID}/rollback_manual_status_change',
          (data) => plainToClass(OrderDTO, data),
          { orderID, json: values },
        ),
      markAsPickedUp: (
        orderID: number,
        data: { adjusted_date: NullableDateString },
      ): Promise<OrderDTO> =>
        requestResource(
          'PUT /internal/orders/{orderID}/mark_as_picked_up',
          (responseData) => plainToClass(OrderDTO, responseData),
          { orderID, json: data },
        ),
      markAsDelivered: (
        orderID: number,
        data: MarkAsDeliveredDTO,
      ): Promise<OrderDTO> =>
        requestResource(
          'PUT /internal/orders/{orderID}/mark_as_delivered',
          (responseData) => plainToClass(OrderDTO, responseData),
          { orderID, json: data },
        ),
      markAsPaid: (orderID: number, paymentInfo: MarkAsPaidPayloadDTO) =>
        invalidateAfterResponse<OrderDTO>(
          requestResource(
            'PUT /internal/orders/{orderID}/mark_as_paid',
            (data) => plainToClass(OrderDTO, data),
            { orderID, json: valuesToPlain(MarkAsPaidPayloadDTO, paymentInfo) },
          ),
        ),
      unmarkAsPaid: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/unmark_as_paid',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ),
      unmarkAsCarrierInvoiced: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/unmark_as_carrier_invoiced',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ),
      markAsCustomerPaid: (
        orderID: number,
        paymentInfo: MarkAsCustomerPaidPayloadDTO,
      ) =>
        requestResource(
          'PUT /internal/orders/{orderID}/mark_as_customer_paid',
          (data) => plainToClass(OrderDTO, data),
          {
            orderID,
            json: valuesToPlain(MarkAsCustomerPaidPayloadDTO, paymentInfo),
          },
        ),
      markAsNew: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/mark_as_new',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ),
      cancelOrder: (orderID: number) =>
        request('POST /internal/orders/{orderID}/cancel_order', { orderID }),
      deleteOrder: (orderID: number) =>
        request('DELETE /internal/orders/{orderID}', {
          orderID,
        }),
      restoreOrder: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/restore_deleted',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ),
      sendOffer: (orderID: number, offer: CreateOfferPayload) =>
        requestResource(
          'POST /internal/orders/{orderID}/offers',
          (data) => data as Offer,
          { json: offer, orderID },
        ),
      instantDispatch: (
        orderGuid: string,
        carrierGuid: string,
        laneGuid: string,
        expectedPrice: number,
      ) =>
        request(
          'POST /internal/orders/{orderGuid}/instant_dispatch/carriers/{carrierGuid}',
          {
            orderGuid,
            carrierGuid,
            json: {
              carrier_lane_guid: laneGuid,
              expected_price: expectedPrice,
            },
          },
        ).then(() => {
          void queryClient.invalidateQueries([
            'order',
            'instant-dispatch-carriers',
          ]);
        }),
      resumePayment: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/resume_payment',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      initiatePaymentNow: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/initiate_payment_now',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      superPayNow: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/{orderID}/superpay_now',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      superPaySchedule: (orderID: number, expectedDate: string) =>
        requestResource(
          'PUT /internal/orders/{orderID}/schedule_superpay',
          (data) => plainToClass(OrderDTO, data),
          { orderID, json: { expected_date: expectedDate } },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      markAsDeliveryVerification: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/delivery_verification/{orderID}/mark_as_verified',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      unmarkAsDeliveryVerification: (orderID: number) =>
        requestResource(
          'PUT /internal/orders/delivery_verification/{orderID}/unmark_as_verified',
          (data) => plainToClass(OrderDTO, data),
          { orderID },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
    };
  }, [queryClient, request, requestResource]);
}

export function useBulkOrderActionAPI() {
  const { request } = useAPI();
  const { invalidateOrderPaymentFlags } = useOrderPaymentFlagsCache();

  return useMemo(
    () => ({
      bulkMarkAsCarrierInvoiced: (
        orderIds: number[],
        data: { adjusted_invoice_date?: NullableDateString },
      ) =>
        request(
          'PUT /internal/orders/bulk_mark_as_carrier_invoiced{?orderIds}',
          {
            orderIds: orderIds.join(','),
            json: data,
          },
        ),
      bulkMarkAsCarrierPaid: (
        orderIds: number[],
        data: {
          amount: number | null;
          method: string;
          notes: string;
          reference_number: string;
          sent_date: string | null;
        },
      ) =>
        request('PUT /internal/orders/bulk_mark_as_paid{?orderIds}', {
          orderIds: orderIds.join(','),
          json: data,
        }),
      bulkUnmarkAsCarrierInvoiced: (orderIds: number[]) =>
        request(
          'PUT /internal/orders/bulk_unmark_as_carrier_invoiced{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      bulkUnmarkAsCarrierPaid: (orderIds: number[]) =>
        request('PUT /internal/orders/bulk_unmark_as_paid{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkMarkAsAccepted: (orderIds: number[], offer?: OfferPayload) =>
        request('PUT /internal/orders/bulk_mark_as_accepted{?orderIds}', {
          orderIds: orderIds.join(','),
          json: offer,
        }),
      bulkMarkAsOnHold: (orderIds: number[]) =>
        request('PUT /internal/orders/bulk_mark_as_on_hold{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkMarkAsPaymentOnHold: (
        orderIds: number[],
        values?: PaymentOnHoldDTO,
      ) =>
        request(
          'PUT /internal/orders/bulk_mark_as_payment_on_hold{?orderIds}',
          {
            orderIds: orderIds.join(','),
            json: values,
          },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkResumePaymentGroupButtons: (orderIds: number[]) =>
        request('PUT /internal/orders/bulk_resume_payment{?orderIds}', {
          orderIds: orderIds.join(','),
        }).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkInitiatePaymentNow: (orderIds: number[]) =>
        request('PUT /internal/orders/bulk_initiate_payment_now{?orderIds}', {
          orderIds: orderIds.join(','),
        }).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkMarkAsDeliveryVerification: (orderIds: number[]) =>
        request(
          'PUT /internal/orders/delivery_verification/bulk_mark_as_verified{?orderIds}',
          {
            orderIds: orderIds.join(','),
          },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkUnmarkAsDeliveryVerification: (orderIds: number[]) =>
        request(
          'PUT /internal/orders/delivery_verification/bulk_unmark_as_verified{?orderIds}',
          {
            orderIds: orderIds.join(','),
          },
        ).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkSuperPayNow: (orderIds: number[]) =>
        request('PUT /internal/orders/bulk_superpay_now{?orderIds}', {
          orderIds: orderIds.join(','),
        }).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkScheduleSuperPayNow: (orderIds: number[], expectedDate: string) =>
        request('PUT /internal/orders/bulk_schedule_superpay{?orderIds}', {
          orderIds: orderIds.join(','),
          json: { expected_date: expectedDate },
        }).then((data) => {
          invalidateOrderPaymentFlags();
          return data;
        }),
      bulkMarkAsPickedUp: (
        orderIds: number[],
        data: { adjusted_date: NullableDateString },
      ) =>
        request('PUT /internal/orders/bulk_mark_as_picked_up{?orderIds}', {
          orderIds: orderIds.join(','),
          json: data,
        }),
      bulkMarkAsDelivered: (orderIds: number[], data: MarkAsDeliveredDTO) =>
        request('PUT /internal/orders/bulk_mark_as_delivered{?orderIds}', {
          orderIds: orderIds.join(','),
          json: data,
        }),
      sendBulkInvoices: (orderIds: number[], invoice: InvoiceDTO) =>
        request('PUT /internal/orders/bulk_send_customer_invoice{?orderIds}', {
          orderIds: orderIds.join(','),
          json: invoice,
        }),
      bulkSubmitToBroker: (orderIds: number[]) =>
        request('POST /internal/orders/bulk_submit_to_broker{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkDeleteOrders: (orderIds: number[]) =>
        request('DELETE /internal/orders{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkRestoreOrders: (orderIds: number[]) =>
        request('PUT /internal/orders/restore_deleted{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkPostToLoadboard: (orderIds: number[], targets: LoadboardTarget[]) =>
        request('POST /internal/loadboard/orders/bulk_post{?lb}', {
          lb: targets.join(','),
          json: orderIds,
        }),
      bulkPostToPrivateLoadboard: (orderIds: number[], groupGuid?: string) =>
        request('POST /internal/loadboard/orders/bulk_post/private-network', {
          json: {
            order_ids: orderIds,
            private_network_groups: groupGuid ? [groupGuid] : [],
          },
        }),
      bulkUnpostFromLoadboard: (
        orderIds: number[],
        targets: LoadboardTarget[],
      ) =>
        request('POST /internal/loadboard/orders/bulk_unpost{?lb}', {
          lb: targets.join(','),
          json: orderIds,
        }),
      bulkArchive: (orderIds: number[]) =>
        request('PUT /internal/orders/archive{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkUnarchive: (orderIds: number[]) =>
        request('PUT /internal/orders/unarchive{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkCancelOffer: (orderIds: number[]) =>
        request('PUT /internal/orders/offers/cancel{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      bulkCarrierPriceUpdate: (orderIds: number[], increment: number) =>
        request('PUT /internal/orders/bulk_increase_price{?orderIds}', {
          orderIds: orderIds.join(','),
          json: { increment },
        }),
      bulkUpdateFirstAvailPickupDate: (
        orderIds: number[],
        payload: {
          first_available_pickup_date: string | null;
        },
      ) =>
        request(
          'PATCH /internal/orders/bulk_update_first_available_date{?orderIds}',
          {
            orderIds: orderIds.join(','),
            json: payload,
          },
        ),
      bulkUpdateDateRanges: (
        orderIds: number[],
        payload: {
          pickup_start_date: string | null;
          pickup_ends_date: string | null;
          delivery_start_date: string | null;
          delivery_ends_date: string | null;
        },
      ) =>
        request('PATCH /internal/orders/bulk_date_range{?orderIds}', {
          orderIds: orderIds.join(','),
          json: payload,
        }),
      bulkSendOffer: (orderIds: number[], offer: Partial<Offer>) =>
        request('POST /internal/orders/offers{?orderIds}', {
          orderIds: orderIds.join(','),
          json: offer,
        }),
    }),
    [request],
  );
}

export function useValidateOrderActionAPI() {
  const { request } = useAPI();

  return useMemo(
    () => ({
      validateSendOffers: (orderIds: number[]) =>
        request('GET /internal/orders/offers/validate_send_offer{?orderIds}', {
          orderIds: orderIds.join(','),
        }),
      validateMarkAsOnHold: (orderIds: number[]) =>
        request(
          'PUT /internal/orders/bulk_validate_mark_as_on_hold{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      validateMarkAsAccepted: (orderIds: number[]) =>
        request(
          'GET /internal/orders/bulk_validate_mark_as_accepted{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      validateMarkAsPickedUp: (orderIds: number[]) =>
        request(
          'GET /internal/orders/bulk_validate_mark_as_picked_up{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      validateMarkAsDelivered: (orderIds: number[]) =>
        request(
          'GET /internal/orders/bulk_validate_mark_as_delivered{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      validateSendInvoices: (orderIds: number[]) =>
        request(
          'GET /internal/orders/bulk_validate_send_customer_invoices{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      validateSubmitToBroker: (orderIds: number[]) =>
        request(
          'POST /internal/orders/bulk_validate_submit_to_broker{?orderIds}',
          { orderIds: orderIds.join(',') },
        ),
      validateCombineOrders: (guids: string[]) =>
        request('POST /internal/orders/combine/validate{?guids}', {
          guids: guids.join(','),
        }),
      validatePostToLoadboard: (
        orderIds: number[],
        targets: LoadboardTarget[],
      ) =>
        request(
          'GET /internal/loadboard/orders/validate_bulk_post_slb{?lb,orderIds}',
          {
            lb: targets.join(','),
            orderIds: orderIds.join(','),
          },
        ),
    }),
    [request],
  );
}

export function usePostSDLoadboardMutation(onSuccess: () => void) {
  const { request } = useAPI();
  return useAPIMutation(
    (orderGuid: string) =>
      request('POST /internal/loadboard/orders/{orderGuid}{?lb}', {
        orderGuid,
        lb: 'sdlb',
      }),
    { onSuccess },
  );
}
