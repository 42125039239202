import { Address } from 'shared/types/address';
import { joinStrings } from 'shared/utils/StringUtils';

export function makeCityStateZip({ city, state, zip }: Partial<Address> = {}) {
  return joinStrings(', ', city, joinStrings(' ', state, zip));
}

export function makeAddressCityStateZip(
  { address, city, state, zip }: Partial<Address> = {},
  divider = ' ',
) {
  const cityStateZip = makeCityStateZip({ city, state, zip });

  return joinStrings(divider, address, cityStateZip);
}

export function makeCityStateAddress({ city, state }: Partial<Address> = {}) {
  return joinStrings(', ', city, state);
}
