import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { DescriptionLineItem } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';

export function PricingSectionPlanPrice({
  planName,
  monthlyPrice,
  maxVehicles,
}: {
  planName: string;
  monthlyPrice: number;
  maxVehicles: number;
}) {
  return (
    <Stack space="xsmall">
      <DescriptionLineItem title={planName}>
        <Typography variant="h4">
          {formatCurrency(monthlyPrice, {
            maximumFractionDigits: 0,
          })}
        </Typography>
      </DescriptionLineItem>

      <Typography color="textSecondary">
        {[
          formatCurrency(monthlyPrice, {
            maximumFractionDigits: 0,
          }),
          'month',
          `${maxVehicles} VINs`,
        ].join(' / ')}
      </Typography>
    </Stack>
  );
}
