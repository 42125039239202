import { FormikMaxLengthTextField } from '@superdispatch/forms';
import { TerminalDTO } from '../../shared/dto/TerminalDTO';
import { TerminalEditDrawer } from './TerminalEditDrawer';

interface Props {
  open: boolean;
  terminal: TerminalDTO;
  onClose: () => void;
}

export function TerminalDetailsNotesDrawer({ open, terminal, onClose }: Props) {
  return (
    <TerminalEditDrawer
      open={open}
      terminal={terminal}
      onClose={onClose}
      onSubmitSuccess={onClose}
      title={
        terminal.notes ? 'Edit Notes for Carrier' : 'Add Notes for Carrier'
      }
    >
      <FormikMaxLengthTextField
        name="notes"
        multiline={true}
        autoFocus={true}
        fullWidth={true}
        maxLength={2000}
        helperText="Instructions or notes that will be displayed to a carrier inside the order once the load offer is accepted."
      />
    </TerminalEditDrawer>
  );
}
