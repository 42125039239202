import {
  CircularProgress,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactElement, useMemo } from 'react';
import { formatCurrency, formatNumber } from 'shared/helpers/IntlHelpers';
import {
  SubscriptionPlan,
  SubscriptionPlanGroupCode,
  useSubscriptionPlans,
} from '../shared/subscription-plans/useSubscriptionPlans';
import { useBillingPeriod } from './useBillingPeriod';

const headers = [
  'Vehicles Delivered Monthly',
  'Monthly Price',
  'VINs Posted Monthly',
  'Overage Fee',
] as const;

interface SubscriptionPackagesTableProps {
  value?: SubscriptionPlanGroupCode;
  onChange?: (value: SubscriptionPlanGroupCode) => void;
}

export function SubscriptionPackagesTable({
  value,
  onChange,
}: SubscriptionPackagesTableProps) {
  const { subscriptionPlanGroups, isSubscriptionPlansLoading } =
    useSubscriptionPlans();
  const [billingPeriod] = useBillingPeriod();

  const planGroupsWithoutExpress = useMemo(() => {
    if (!subscriptionPlanGroups) return [];

    return subscriptionPlanGroups.filter(
      (group) => group.plan_group_code !== 'express',
    );
  }, [subscriptionPlanGroups]);

  const isMonthlyBilling = billingPeriod === 'monthly';

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isSubscriptionPlansLoading && (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <Inline horizontalAlign="center">
                  <CircularProgress />
                </Inline>
              </TableCell>
            </TableRow>
          )}
          {planGroupsWithoutExpress.map((group) => {
            // there is always either monthly or yearly plan
            // so we can safely cast it to SubscriptionPlan
            const plan = group.plans[
              isMonthlyBilling ? 'monthly' : 'yearly'
            ] as SubscriptionPlan;

            function getPlanFieldWithYearlyFallback<
              T extends keyof SubscriptionPlan,
            >(field: T) {
              return (group.plans.monthly?.[field] ??
                group.plans.yearly?.[field]) as NonNullable<
                SubscriptionPlan[T]
              >;
            }

            const maxMonthlyPostedVins = getPlanFieldWithYearlyFallback(
              'max_monthly_posted_vins',
            );
            const volumeMin = getPlanFieldWithYearlyFallback('volume_min');
            const volumeMax = getPlanFieldWithYearlyFallback('volume_max');

            return (
              <TableRow key={group.plan_group_code}>
                <TableCell
                  style={{ cursor: onChange ? 'pointer' : 'default' }}
                  onClick={() => {
                    onChange?.(group.plan_group_code);
                  }}
                >
                  <Inline space="none" verticalAlign="center">
                    {onChange && (
                      <Radio checked={group.plan_group_code === value} />
                    )}
                    {formatRange(volumeMin, volumeMax)}
                  </Inline>
                </TableCell>
                <TableCell>
                  {maybeFormatCurrency(
                    plan.monthly_price,
                    <Typography color="textSecondary">
                      Annual Billing
                    </Typography>,
                  )}
                </TableCell>
                <TableCell>
                  up to {formatNumber(maxMonthlyPostedVins)}
                </TableCell>
                <TableCell>
                  {maybeFormatCurrency(plan.overage_fee, '—')}
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell>
              <Box paddingLeft="xlarge">{formatNumber(10001)} +</Box>
            </TableCell>
            <TableCell>Call Us Now at 816-281-0007</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function formatRange(min: number, max: number) {
  return `${formatNumber(min)} - ${formatNumber(max)}`;
}

function maybeFormatCurrency(
  value: number | null | undefined,
  fallback: string | ReactElement,
) {
  if (value === null || value === undefined) {
    return fallback;
  }

  return formatCurrency(value);
}
