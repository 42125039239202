import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { parseSearchQuery } from 'shared/helpers/RouteHelpers';
import { RateCarrierPayloadDTO } from './CarrierRatingDTO';

export function trackRatingSubmitEvent(
  { rating, tags, comment }: RateCarrierPayloadDTO,
  utmMedium?: string,
) {
  const tagRecord: Record<string, boolean> = {};
  const {
    utm_medium = utmMedium,
    utm_source,
    utm_content,
  } = parseSearchQuery(window.location.search);

  for (const tag of tags) {
    tagRecord[`is_${tag.toLowerCase()}`] = true;
  }

  trackEvent('Shipper Submitted Rating', {
    comment,
    rating: rating.toLowerCase(),
    ...tagRecord,

    utm_source: utm_source ?? 'Web STMS',
    utm_medium: utm_medium ?? 'Carrier Profile',
    utm_content,
  });
}
