import { createSvgIcon } from './IconUtils';

export const HashtagIcon = createSvgIcon(
  'Hashtag',
  <path
    fill="currentColor"
    d="M3.60666 14L4.08 11.3333H1.41333L1.64666 10H4.31333L5.02 6H2.35333L2.58666 4.66667H5.25333L5.72666 2H7.06L6.58666 4.66667H10.5867L11.06 2H12.3933L11.92 4.66667H14.5867L14.3533 6H11.6867L10.98 10H13.6467L13.4133 11.3333H10.7467L10.2733 14H8.94L9.41333 11.3333H5.41333L4.94 14H3.60666ZM6.35333 6L5.64666 10H9.64666L10.3533 6H6.35333Z"
  />,
  { viewBox: '0 0 16 16' },
);
