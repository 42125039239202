import Typography from '@material-ui/core/Typography';
import { upperFirst } from 'lodash-es';
import { objectFlatten } from 'shared/utils/DataUtils';

function parseJsonSafe(input: unknown) {
  if (typeof input !== 'string') {
    return null;
  }
  try {
    return JSON.parse(input);
  } catch (error) {
    return null;
  }
}

interface OrderActivityErrorMessagesProps {
  errorMessage: string | null;
  errorDetails?: string | null;
}

export function OrderActivityErrorMessages({
  errorMessage,
  errorDetails,
}: OrderActivityErrorMessagesProps) {
  const parsedErrorDetails = parseJsonSafe(errorDetails);

  if (
    !parsedErrorDetails ||
    (Array.isArray(parsedErrorDetails) && parsedErrorDetails.length === 0)
  ) {
    if (errorMessage == null) {
      return null;
    }

    if (Array.isArray(errorMessage)) {
      return (
        <OrderActivityErrorMessages errorMessage={errorMessage.join('; ')} />
      );
    }

    return (
      <Typography color="error" aria-label="error message">
        {upperFirst(errorMessage)}
      </Typography>
    );
  }

  if (
    Array.isArray(parsedErrorDetails.details) &&
    parsedErrorDetails.details.length > 0
  ) {
    return (
      <>
        {parsedErrorDetails.details.map((detail: string) => (
          <OrderActivityErrorMessages key={detail} errorMessage={detail} />
        ))}
      </>
    );
  }

  if (typeof parsedErrorDetails === 'object' && parsedErrorDetails !== null) {
    return (
      <>
        {Object.entries(objectFlatten(parsedErrorDetails, ' ')).map(
          ([key, value]) => (
            <OrderActivityErrorMessages
              key={key}
              errorMessage={`${key}: ${String(value)}`}
            />
          ),
        )}
      </>
    );
  }

  return null;
}
