import { FormattedRelativeTime } from '@superdispatch/dates';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';

export interface OrderListItemAgeProps {
  order: Order;
}

export function OrderListItemAge({ order }: OrderListItemAgeProps) {
  const { user } = useUserState();

  return (
    <>
      <DetailedFormattedDate date={order.created_at} />

      {user?.shipper.shipper_type === 'BROKER' && (
        <div>
          <FormattedRelativeTime format="JodaISO" date={order.created_at} />
        </div>
      )}
    </>
  );
}
