import { QueryClient, useQuery } from 'react-query';
import {
  APIQueryInput,
  APIQueryOptions,
  APIQueryResult,
} from 'shared/api/APIQuery';
import { Schema } from 'yup';
import { APIPageResponse } from './API';

export interface APIPageQueryOptions<TData>
  extends APIQueryOptions<APIPageResponse<TData>['data']> {
  schema?: Schema<TData>;
  normalize?: (response: unknown) => TData;
}

export type APIPageQueryResult<TData> = APIQueryResult<
  APIPageResponse<TData>['data']
>;

export function useAPIPageQuery<TData>(
  input: APIQueryInput,
  query: () => Promise<APIPageResponse<TData>['data']>,
  {
    schema,
    normalize,
    keepPreviousData = true,
    staleTime = 30 * 1000,
    ...options
  }: APIPageQueryOptions<TData> = {},
): APIPageQueryResult<TData> {
  return useQuery<APIPageResponse<TData>['data'], Error>(
    input,
    () =>
      query().then((response) => {
        if (schema || normalize) {
          response.objects = response.objects.map((data) => {
            if (schema) {
              data = schema.cast(data);
            }

            if (normalize) {
              data = normalize(data);
            }

            return data;
          });
        }

        return response;
      }),
    { ...options, staleTime, keepPreviousData },
  );
}

export function findAPIPageQueryItem<TData>(
  input: APIQueryInput,
  queryClient: QueryClient,
  fn: (item: TData) => boolean,
): undefined | TData {
  for (const { state } of queryClient.getQueryCache().findAll(input)) {
    if (state.data) {
      const { objects } = state.data as APIPageResponse<TData>['data'];

      for (const item of objects) {
        if (fn(item)) {
          return item;
        }
      }
    }
  }

  return undefined;
}
