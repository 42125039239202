import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { useValueObserver } from '@superdispatch/hooks';
import { formatVehicleType } from '@superdispatch/sdk';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { getVehicleTitle } from 'orders/core/list';
import React, { useEffect, useRef, useState } from 'react';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';
import { LoadStepCounterpartyFields } from './LoadStepCounterpartyFields';
import { LoadStepInstructions } from './LoadStepInstructions';

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: none;
  }

  &.MuiAccordion-root:not(:first-child) {
    border-top: 0;
  }

  & .MuiAccordionSummary-root {
    min-height: 56px;
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 16px 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 8px 0 16px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
  }

  & .MuiAccordionSummary-content,
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 4px 0;
  }
`;

const StyledDelete = styled(Delete)`
  color: ${Color.Silver500};
`;

export function LoadForm({
  order,
  index,
  onRemove,
}: {
  order?: Order;
  index: number;
  onRemove?: (index: number) => void;
}) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';
  const { values } = useFormikContext<BuildLoadsFormValues>();
  const [visibleVehicles, restVehicles] = [
    order?.vehicles?.slice(0, 1),
    order?.vehicles?.slice(1),
  ];

  const [expanded, setExpanded] = useState<boolean>(true);

  useValueObserver(values.loads, (prevValues) => {
    const isNewLoad = index === values.loads.length - 1;

    if (values.loads.length > prevValues.length && !isNewLoad) {
      setExpanded(false);
    }
  });

  const formContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (formContainerRef.current) {
      formContainerRef.current.scrollIntoView();
    }
  }, []);

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      {values.loads.length > 1 && (
        <StyledAccordionSummary
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Inline verticalAlign="center">
            <Typography variant="h3">{values.loads[index]?.number}</Typography>
            <IconButton
              size="small"
              aria-label="remove load"
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                onRemove?.(index);
              }}
            >
              <StyledDelete />
            </IconButton>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Inline>
        </StyledAccordionSummary>
      )}

      <StyledAccordionDetails ref={formContainerRef}>
        <Stack space="large">
          <Columns
            space={['small', 'medium']}
            align="top"
            collapseBelow="tablet"
          >
            <Column>
              <FormikTextField
                name={`loads[${index}].number`}
                fullWidth={true}
                inputProps={{
                  maxLength: '50',
                }}
                label={
                  <>
                    <Typography display="inline" color="textPrimary">
                      Load ID
                    </Typography>{' '}
                    <Typography display="inline" color="textSecondary">
                      (Required)
                    </Typography>
                  </>
                }
                autoFocus={true}
              />
            </Column>

            <Column>
              <Box marginTop={isMobile ? 0 : 3}>
                <Stack space="xxsmall">
                  {visibleVehicles?.map((vehicle) => (
                    <Stack space="none" key={vehicle.id}>
                      <Inline verticalAlign="center" space="xxsmall">
                        <Typography>{getVehicleTitle(vehicle)}</Typography>
                        <Typography color="textSecondary">
                          {formatVehicleType(vehicle.type)}
                        </Typography>
                      </Inline>

                      {vehicle.vin && (
                        <Typography color="textSecondary">
                          {vehicle.vin}
                        </Typography>
                      )}
                    </Stack>
                  ))}

                  {restVehicles && restVehicles.length > 0 && (
                    <Typography
                      title={restVehicles
                        .map((vehicle) => vehicle.vin || 'N/A')
                        .join('\n')}
                    >
                      +{restVehicles.length} more
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Column>
          </Columns>

          <LoadStepInstructions order={order} prefix={`loads[${index}]`} />

          <LoadStepCounterpartyFields
            title="pickup"
            name={`loads[${index}].pickup`}
            order={order}
            index={index}
          />

          <LoadStepCounterpartyFields
            title="delivery"
            name={`loads[${index}].delivery`}
            order={order}
            index={index}
          />
        </Stack>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
