import {
  Box,
  ButtonGroupProps as ButtonGroupPropsType,
  MenuList,
  MenuListProps as MenuListPropsType,
  Popover,
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Button, ButtonProps, Color } from '@superdispatch/ui';
import * as React from 'react';
import { MouseEvent, ReactNode } from 'react';
import { CaretDownIcon } from 'shared/icons/CaretDownIcon';
import styled from 'styled-components';

const DropDownButton = styled(Button)`
  width: auto;
  ${({ variant }) =>
    variant === 'contained' && `border-left: 1px solid ${Color.Blue500}`};
`;

interface ButtonGroupDropdownProps extends Omit<ButtonProps, 'children'> {
  label?: ReactNode;
  children: ReactNode;
  MenuListProps?: MenuListPropsType;
  ButtonGroupProps?: ButtonGroupPropsType;
  DropDownButtonProps?: ButtonProps;
}

export function ButtonGroupDropdown({
  MenuListProps,
  ButtonGroupProps,
  DropDownButtonProps,

  children,
  isLoading,
  onClick,
  label,
  ...buttonProps
}: ButtonGroupDropdownProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setOpen(false);
    onClick?.(event);
  }

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: React.MouseEvent<Document>) {
    if (anchorRef.current?.contains(event.currentTarget)) {
      return;
    }
    setOpen(false);
  }

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        fullWidth={buttonProps.fullWidth}
        {...ButtonGroupProps}
      >
        <Button
          onClick={handleClick}
          disabled={isLoading}
          isLoading={isLoading}
          {...buttonProps}
        >
          {label}
        </Button>

        <DropDownButton
          {...DropDownButtonProps}
          aria-label="drop down"
          onClick={handleToggle}
          disabled={isLoading}
          color={buttonProps.color}
          variant={buttonProps.variant}
        >
          <CaretDownIcon style={{ width: '8', height: '4' }} />
        </DropDownButton>
      </ButtonGroup>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box minWidth={anchorRef.current?.clientWidth}>
          <MenuList
            {...MenuListProps}
            onClick={() => {
              setOpen(false);
            }}
          >
            {children}
          </MenuList>
        </Box>
      </Popover>
    </>
  );
}

export default ButtonGroupDropdown;
