import { useMemo } from 'react';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { CreateUserDTO, createUserDTO } from './CreateUserDTO';
import { EditUserDTO, editUserDTO } from './EditUserDTO';
import { userDTO } from './UserDTO';
import { userRoleDTO } from './UserRoleDTO';

export function useUsersList(page: number, size: number, query?: string) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(['console', 'users', { page, size, query }], () =>
    requestPage(
      '/internal/console/users{?page,size,query}',
      (data) => userDTO.cast(data),
      { page, size, query },
    ),
  );
}

export function useUsersRolesList() {
  const { requestPage } = useAPI();

  return useAPIPageQuery(['console', 'roles'], () =>
    requestPage('/internal/console/roles', (data) => userRoleDTO.cast(data)),
  );
}

export function useManageUsersAPI() {
  const { request } = useAPI();

  return useMemo(
    () => ({
      createUser: (values: CreateUserDTO) =>
        request('POST /internal/console/users', {
          json: createUserDTO.cast(values),
        }).then((response) => {
          return response;
        }),
      editUser: (values: EditUserDTO, guid?: string) =>
        request(`PUT /internal/console/users/${guid || ''}`, {
          json: editUserDTO.cast(values),
        }).then((response) => {
          return response;
        }),
      resetPassword: ({
        password,
        guid,
      }: {
        password: string;
        guid?: string;
      }) =>
        request(`PUT /internal/console/users/${guid || ''}/reset_password`, {
          json: { password },
        }).then((response) => {
          return response;
        }),
      sendResetPasswordLink: ({ username }: { username: string }) =>
        request('POST /internal/reset-password/send-link', {
          json: { username },
        }).then((response) => {
          return response;
        }),
      deactivateUser: (guid?: string) =>
        request(`DELETE /internal/console/users/${guid || ''}`).then(
          (response) => {
            return response;
          },
        ),
      reactivateUser: (username: string, guid: string) =>
        request(`PUT /internal/console/users/${guid || ''}/activate`, {
          json: { username },
        }),
      reactivateUserLegacy: (guid?: string) =>
        request(`PUT /internal/console/users/${guid || ''}/activate`),
    }),
    [request],
  );
}
