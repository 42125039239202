import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { plainToClass } from 'class-transformer';
import { useInternalUsers } from 'core/dictionary/data/DictionaryAPI';
import { FormikProvider } from 'formik';
import { useGeneralSettings } from 'general-settings/data/GeneralSettingsAPI';
import { set } from 'lodash-es';
import { createEmptyOrder } from 'orders/core/form/helpers/createEmptyOrder';
import {
  getOrderAction,
  modifyOrderToTrack,
  OrderFormType,
} from 'orders/core/form/helpers/orderFormHelpers';
import { transformToOrder } from 'orders/core/form/helpers/transformOrder';
import { OrderFormSchema } from 'orders/core/form/OrderFormValidation';
import { useOrderFormInitialValues } from 'orders/core/form/useOrderFormInitialValues';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { isAPIValidationError } from 'shared/errors/APIError';
import { APIValidationErrorMessage } from 'shared/errors/APIValidationErrorMessage';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { AttachmentWithFile } from 'shared/types/attachment';
import Order from 'shared/types/order';
import { getObjectChangeInfo } from 'shared/utils/DataUtils';
import { ValidationError } from 'yup';
import { OrderForm, OrderFormValues } from '../core/form/OrderForm';
import { useOrder, useOrderAPI, useOrdersCache } from '../data/OrderAPI';
import {
  useOrderPaymentFlags,
  useOrderPaymentFlagsCache,
} from '../data/OrderPaymentFlagsAPI';

export function OrderEditPage() {
  const formAction = useRef<{
    type: OrderFormType;
    args?: Record<string, unknown>;
  } | null>(null);
  const { user } = useUserState();
  const navigate = useNavigate();
  const { guid: orderGUID = '' } = useParams<{ guid: string }>();
  const { addSnackbar } = useSnackbarStack();

  const { data: order } = useOrder(orderGUID, true, {
    refetchOnWindowFocus: false,
  });

  const orderPaymentFlags = useOrderPaymentFlags(order?.id);
  const { invalidateOrderPaymentFlags } = useOrderPaymentFlagsCache();
  const { invalidateOrders } = useOrdersCache();

  const { data: { objects: users = [] } = {} } = useInternalUsers();
  const initialOrder = useMemo(() => order || createEmptyOrder(), [order]);
  const { data: settings } = useGeneralSettings();

  const {
    updateOrder,
    uploadOrderVehiclePhoto,
    removeOrderVehiclePhoto,
    createOrderAttachment,
    editOrderAttachment,
    removeOrderAttachment,
  } = useOrderAPI();

  const { initialValues } = useOrderFormInitialValues(
    initialOrder,
    'order_edit_page',
  );

  const validateForm = async (values: OrderFormValues) => {
    try {
      await OrderFormSchema.validate(values, {
        abortEarly: false,
        context: {
          maxNumberOfTags: settings?.max_number_of_tags,
          orderPaymentFlags,
          formType: formAction.current?.type,
          isSuperPaySuspend:
            user?.shipper.super_pay_settings?.suspended_account,
        },
      });
      return null;
    } catch (validationError: unknown) {
      let errors = {};
      if (
        ValidationError.isError(validationError) &&
        validationError.inner.length > 0
      ) {
        validationError.inner.forEach((error) => {
          set(errors, error.path, error.message);
        });
      }
      return errors;
    }
  };

  const formik = useFormikEnhanced<OrderFormValues, Order>({
    key: order,
    initialValues,
    validate: validateForm,
    validateOnBlur: true,
    validateOnChange: false,
    async onSubmit(formValues) {
      if (!order) return Promise.reject(Error('Order not found'));

      const payload = transformToOrder(formValues, users);

      const { vehicles: prevVehicles = [], attachments: prevAttachments = [] } =
        order;
      const { attachments: nextAttachments = [], vehicles: nextVehicles = [] } =
        payload;

      const editedAttachments = nextAttachments.filter(
        (attachment: AttachmentWithFile) => attachment.changed && attachment.id,
      );

      if (editedAttachments.length > 0) {
        await Promise.all(
          editedAttachments.map((attachment: AttachmentWithFile) => {
            return editOrderAttachment(
              order.id,
              {
                is_shared_with_carrier: !!attachment.is_shared_with_carrier,
                is_shared_with_customer: !!attachment.is_shared_with_customer,
              },
              attachment.id,
            );
          }),
        );
      }

      await Promise.all(
        prevAttachments.map((attachment) => {
          if (!nextAttachments.find((x) => x.id === attachment.id)) {
            return removeOrderAttachment(order.id, attachment.id);
          }

          return undefined;
        }),
      );

      await Promise.all(
        nextAttachments.map((attachment: AttachmentWithFile) => {
          if (attachment.file instanceof File) {
            return createOrderAttachment(
              order.id,
              attachment.file,
              attachment.is_shared_with_carrier,
              attachment.is_shared_with_customer,
            );
          }

          return undefined;
        }),
      );

      const updatedOrder = await updateOrder(order.id, payload);

      // updating
      await Promise.all(
        nextVehicles.map((vehicle, index) => {
          const photo = vehicle.photos?.find(
            (x) => x.photo_file instanceof File,
          );
          const updatedVehicle = updatedOrder.vehicles?.[index];

          if (photo?.photo_file && updatedVehicle) {
            return uploadOrderVehiclePhoto(
              order.id,
              updatedVehicle.id,
              photo.photo_file,
            );
          }

          return undefined;
        }),
      );

      await Promise.all(
        prevVehicles.map((prevVehicle) => {
          const nextVehicle = nextVehicles.find((x) => x.id === prevVehicle.id);
          const prevPhoto = prevVehicle.photos?.find(
            (x) => x.photo_type === 'Sample',
          );
          const nextPhoto = nextVehicle?.photos?.find(
            (x) => x.photo_type === 'Sample',
          );

          if (prevPhoto && nextVehicle && !nextPhoto) {
            return removeOrderVehiclePhoto(order.id, prevVehicle.id);
          }

          return undefined;
        }),
      );

      return updatedOrder;
    },
    onSubmitSuccess(_, formValues) {
      if (!order) return;
      trackEventLegacy(
        'Edited Order',
        getObjectChangeInfo(
          modifyOrderToTrack(order),
          modifyOrderToTrack({
            ...formValues,
            vehicles: plainToClass(VehicleDTO, formValues.vehicles),
          }),
        ),
      );

      addSnackbar('Updated', { variant: 'success' });

      navigate(
        {
          pathname: `/orders/view/${order.guid}`,
        },
        {
          state: {
            back_url: '/orders',
            action: getOrderAction(formAction.current),
          },
        },
      );
      invalidateOrderPaymentFlags();
      void invalidateOrders();
    },
    onSubmitFailure(error) {
      if (isAPIValidationError(error)) {
        addSnackbar(
          <Stack>
            The following information is missing. Edit the order and provide
            this information:
            <APIValidationErrorMessage error={error} />
          </Stack>,
          {
            variant: 'error',
          },
        );
      } else {
        addSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useDocumentTitle('Edit Order');

  const handleSave = useCallback(
    (type: OrderFormType, args?: Record<string, unknown>) => {
      formAction.current = { type, args };
      formik.handleSubmit();
    },
    [formik],
  );

  const onCancelForm = useCallback(() => {
    navigate('/orders', { replace: true });
  }, [navigate]);

  return (
    <FormikProvider value={formik}>
      <SourceManager primarySource="Edit Order">
        <OrderForm
          title="Edit Order"
          source="order_edit_page"
          order={initialOrder}
          users={users}
          onSave={handleSave}
          onCancelForm={onCancelForm}
          isLoading={!order}
          isSubmitDisabled={formik.isSubmitting}
          isFormEdited={formik.dirty}
        />
      </SourceManager>
    </FormikProvider>
  );
}
