import { renderChildren } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';

interface FormikCheckboxControllerRenderProps {
  checked: boolean;
  indeterminate: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface FormikCheckboxControllerProps {
  name: string;
  fields: readonly string[];
  render: (props: FormikCheckboxControllerRenderProps) => ReactNode;
}

export function FormikCheckboxController({
  name,
  fields,
  render,
}: FormikCheckboxControllerProps) {
  const { values, setFieldValue } =
    useFormikContext<Record<string, Record<string, boolean>>>();
  const value = values[name] || {};

  const [allChecked, someChecked] = useMemo(() => {
    let all = true;
    let some = false;

    for (const key of fields) {
      if (value[key]) {
        some = true;
      } else {
        all = false;
      }
    }
    return [all, some];
  }, [value, fields]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      for (const key of fields) {
        setFieldValue(`${name}.${key}`, event.target.checked);
      }
    },
    [name, fields, setFieldValue],
  );

  return renderChildren(
    render({
      onChange,
      checked: allChecked,
      indeterminate: !allChecked && someChecked,
    }),
  );
}
