import {
  FormikDateField,
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Button,
  DrawerActions,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { DrawerForm } from 'shared/form/DrawerForm';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';

interface BondInsuranceEditDrawerProps {
  open: boolean;
  profile: ShipperProfileDTO;
  onClose: () => void;
  onUpdate?: () => void;
}

export function BondInsuranceEditDrawer({
  open,
  profile,
  onClose,
  onUpdate,
}: BondInsuranceEditDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateBondInsurance } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateBondInsurance(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedProfileValues('Bond Insurance', {
        bond_company: updatedValues.bond_company,
        bond_agent: updatedValues.bond_agent,
        bond_expiration_date: updatedValues.bond_expiration_date,
        agent_phone: updatedValues.agent_phone,
      });

      addSnackbar('Profile updated successfully', { variant: 'success' });
      onClose();
      onUpdate?.();
    },
  });

  const { dirty, isSubmitting, isValid, status, handleSubmit } = formik;

  return (
    <DrawerForm
      title="Bond Insurance"
      isDirty={dirty}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disableCloseOnNavigation={true}
      disableNavigationPrompt={status.type === 'submitted'}
      actions={
        <DrawerActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </Button>
        </DrawerActions>
      }
    >
      <FormikProvider value={formik}>
        <Stack space="small">
          <FormikTextField
            name="bond_company"
            label="Company"
            fullWidth={true}
          />

          <FormikTextField
            name="bond_agent"
            label="Agent Name"
            fullWidth={true}
          />

          <SuspendedFormikPhoneField
            label="Phone"
            fullWidth={true}
            name="agent_phone"
          />

          <FormikDateField
            fullWidth={true}
            name="bond_expiration_date"
            label="Bond Expiration Date"
            format="DateISO"
            enableClearable={true}
          />
        </Stack>
      </FormikProvider>
    </DrawerForm>
  );
}
