import { NullableDateString, parseDate } from '@superdispatch/dates';
import { Exclude, Expose, Type } from 'class-transformer';
import {
  CarrierRatingDTO,
  RatingStatsDTO,
} from 'core/ratings/data/CarrierRatingDTO';
import { DateTime } from 'luxon';
import { PrivateNetworkGroupDTO } from 'manage-carriers/data/PrivateNetworkDTO';
import { hasOnlyDigits } from '../utils/StringUtils';
import { AttachmentDTO } from './attachment';
import { OfferPayload } from './offer';

@Exclude()
export class CargoPolicy {
  @Expose() policy_expiration_date: NullableDateString;
  @Expose() limit: string | null;
  @Expose() deductible: string | null;
  @Expose() agent_name: string | null;
  @Expose() agent_phone: string | null;
  @Expose() auto_policy_number: string | null;
  @Expose() cargo_policy_number: string | null;
}

@Exclude()
export class ACHPayment {
  @Expose() bank_name: string | null;
  @Expose() bank_routing_number: string | null;
  @Expose() bank_account_number: string | null;
  @Expose() voided_check_url: string | null;
}
@Exclude()
export class CarrierBrokerPreferences {
  static isInsuranceExpired({
    insurance_expires_at,
  }: CarrierBrokerPreferences) {
    return (
      parseDate(insurance_expires_at, {
        format: 'DateISO',
      }) < DateTime.local().startOf('day')
    );
  }
  static hasStatus(broker_records: CarrierBrokerPreferences) {
    const isInsuranceExpired = this.isInsuranceExpired(broker_records);
    const {
      approved,
      preferred,
      is_in_private_network,
      insurance_certificate_holder,
      in_blacklist,
      can_expedite_payment,
    } = broker_records;
    return (
      approved ||
      preferred ||
      is_in_private_network ||
      insurance_certificate_holder ||
      isInsuranceExpired ||
      in_blacklist ||
      can_expedite_payment
    );
  }

  @Expose() guid: string;
  @Expose() accepted_loads_count: number;
  @Expose() approved: boolean | null;
  @Expose() approved_since: string | null;
  @Expose() preferred: boolean | null;
  @Expose() preferred_since: string | null;
  @Expose() can_expedite_payment: boolean | null;
  @Expose() can_expedite_payment_since: string | null;
  @Expose() is_in_private_network: boolean | null;
  @Expose() private_network_groups: PrivateNetworkGroupDTO[] | null;
  @Expose() in_blacklist: boolean | null;
  @Expose() in_blacklist_since: string | null;
  @Expose() insurance_certificate_holder: boolean | null;
  @Expose() insurance_certificate_holder_since: string | null;
  @Expose() insurance_cert_holder_file_url: string | null;
  @Expose() insurance_expires_at: NullableDateString;
  @Expose() custom_external_id: string | null;
  @Expose() usdot_number: string;
  @Expose() carrier_rating: CarrierRatingDTO | null;
  @Expose() superpay_requested: boolean;
}

export type CarrierStatus = 'APPROVED' | 'IN_BLACKLIST' | 'DEFAULT';

@Exclude()
export class CarrierBrokerPreferencesUpdatePayload {
  @Expose() custom_external_id: string | null;
  @Expose() preferred: boolean | null;
  @Expose() can_expedite_payment: boolean | null;
  @Expose() is_in_private_network: boolean | null;
  @Expose() private_network_group_guids: string[] | null;
  @Expose() insurance_certificate_holder: boolean | null;
  @Expose() status: CarrierStatus;
  @Expose() insurance_expires_at: NullableDateString;
}

export class CarrierBrokerStatusUpdatePayload {
  @Expose() status: CarrierStatus;
}

export class CarrierBrokerPreferencesEdit extends CarrierBrokerPreferencesUpdatePayload {
  @Expose() attachments: Array<AttachmentDTO | File>;
  @Expose() insurance_cert_holder_file_url: string | File | null;
}

export class CarrierBrokerStatus {
  @Expose() status: CarrierStatus;
}

@Exclude()
export class Carrier {
  static generateOrderOffer(carrier: Carrier): OfferPayload {
    return {
      carrier_guid: carrier.guid,
      carrier_type: 'CTMS',
      carrier_name: carrier.dba_name || carrier.name,
      carrier_contact_name: carrier.contact_name,
      carrier_email: carrier.email,
      carrier_phone: carrier.phone_numbers,
      carrier_usdot: carrier.us_dot,
      carrier_address: carrier.address,
      carrier_city: carrier.city,
      carrier_state: carrier.state,
      carrier_zip: carrier.zip,
    };
  }

  @Expose() guid: string;
  @Expose() name: string;
  @Expose() us_dot: string | null;
  @Expose() email: string | null;
  @Expose() phone_numbers: string | null;
  @Expose() city: string | null;
  @Expose() state: string | null;
  @Expose() contact_name?: string | null;
  @Expose() fax?: string | null;
  @Expose() website?: string | null;
  @Expose() carrier_type: 'INTERNAL' | 'CTMS' | 'FMCSA' | 'BROKER';
  @Expose() dba_name: string | null;
  @Expose() address: string | null;
  @Expose() zip: string | null;
  @Expose() w9: string | null;
  @Expose() usdot_certificate: string | null;
  @Expose() cargo_insurance: string | null;
  @Expose() is_super: boolean;
  @Expose() is_verified: boolean;
  @Expose() is_preferred: boolean;
  @Expose() is_super_pay_enabled: boolean;
  @Expose() has_signed_up: boolean;

  @Expose()
  @Type(() => CargoPolicy)
  cargo_policy: CargoPolicy | null;

  @Expose()
  @Type(() => CarrierBrokerPreferences)
  broker_records?: CarrierBrokerPreferences;

  @Expose() mc_number: string | null;
  @Expose() terms_and_conditions: string;
  @Expose() logo: string | null;

  @Expose()
  rating_stats?: RatingStatsDTO | null;
}

@Exclude()
export class FmcsaInfo {
  @Expose() guid: string;
  @Expose() name: string;
  @Expose() us_dot: string | null;
  @Expose() email: string | null;
  @Expose() phone_numbers: string | null;
  @Expose() city: string | null;
  @Expose() state: string | null;
  @Expose() contact_name?: string | null;
  @Expose() fax?: string | null;
  @Expose() website?: string | null;
  @Expose() carrier_type: 'INTERNAL' | 'CTMS' | 'FMCSA' | 'BROKER';
  @Expose() dba_name: string | null;
  @Expose() address: string | null;
  @Expose() zip: string | null;
  @Expose() w9: string | null;
  @Expose() usdot_certificate: string | null;
  @Expose() cargo_insurance: string | null;

  @Expose()
  @Type(() => CargoPolicy)
  cargo_policy: CargoPolicy | null;

  @Expose() is_super: boolean;
  @Expose() is_verified: boolean;
  @Expose() is_preferred: boolean;
  @Expose() is_super_pay_enabled: boolean;
  @Expose() carrier_operation: string | null;
  @Expose() hm_flag: boolean | null;
  @Expose() pc_flag: boolean | null;
  @Expose() street: string | null;
  @Expose() country: string | null;
  @Expose() mailing_street: string | null;
  @Expose() mailing_city: string | null;
  @Expose() mailing_state: string | null;
  @Expose() mailing_zip: string | null;
  @Expose() mailing_country: string | null;
  @Expose() phone: string | null;
  @Expose() mcs150Date: string | null;
  @Expose() mcs150Mileage: string | null;
  @Expose() mcs150MileageYear: string | null;
  @Expose() add_date: string | null;
  @Expose() oic_state: string | null;
  @Expose() nbr_powerUnit: number | null;
  @Expose() number_of_drivers: number | null;
  @Expose() operating_status: string | null;
  @Expose() out_of_service_date: string | null;
  @Expose() operation_classification: string | null;
  @Expose() cargo_carried: string | null;
  @Expose() updated_from_safer_date: NullableDateString;

  @Expose()
  @Type(() => CarrierBrokerPreferences)
  broker_records?: CarrierBrokerPreferences;

  @Expose() mc_number: string | null;
  @Expose() terms_and_conditions: string;
  @Expose() logo: string | null;
  @Expose() has_signed_up: boolean;
  @Expose() rating_stats?: RatingStatsDTO | null;
}

export type CarrierLogistics = 'AUTO_HAULER' | 'TOWING' | 'DRIVEAWAY';
export const CARRIER_LOGISTICS: { [key in CarrierLogistics]: string } = {
  AUTO_HAULER: 'Auto-Hauler',
  TOWING: 'Towing',
  DRIVEAWAY: 'Driveaway',
};

@Exclude()
export class CarrierFullInfo implements Carrier {
  @Expose() guid: string;
  @Expose() name: string;
  @Expose() us_dot: string | null;
  @Expose() email: string | null;
  @Expose() phone_numbers: string | null;
  @Expose() city: string | null;
  @Expose() state: string | null;
  @Expose() contact_name?: string | null;
  @Expose() fax?: string | null;
  @Expose() website?: string | null;
  @Expose() carrier_type: 'INTERNAL' | 'CTMS' | 'FMCSA' | 'BROKER';
  @Expose() dba_name: string | null;
  @Expose() address: string | null;
  @Expose() zip: string | null;
  @Expose() w9: string | null;
  @Expose() usdot_certificate: string | null;
  @Expose() cargo_insurance: string | null;
  @Expose() largest_trailer_capacity: string | null;
  @Expose() logistics_type: CarrierLogistics | null;
  @Expose() transporter_plate_registration: string | null;
  @Expose() state_motor_carrier_permit: string | null;
  @Expose() business_license: string | null;
  @Expose() is_super: boolean;
  @Expose() is_verified: boolean;
  @Expose() is_preferred: boolean;
  @Expose() is_super_pay_enabled: boolean;
  @Expose() in_business_since: number;
  @Expose() joined_since: NullableDateString;
  @Expose() has_signed_up: boolean;

  @Expose()
  @Type(() => CargoPolicy)
  cargo_policy: CargoPolicy | null;

  @Expose()
  @Type(() => CarrierBrokerPreferences)
  broker_records: CarrierBrokerPreferences;

  @Expose() mc_number: string | null;
  @Expose() terms_and_conditions: string;
  @Expose() logo: string | null;

  @Expose() billing_contact_name: string | null;
  @Expose() billing_email: string | null;
  @Expose() billing_phone_numbers: string | null;

  @Expose()
  @Type(() => ACHPayment)
  ach_payment: ACHPayment | null;

  @Expose() rating_stats?: RatingStatsDTO | null;
}

/**
 * Check if the carrier has a valid US DOT number that contains only digits.
 */
export function isCarrierHasValidUSDOT(carrier: CarrierFullInfo) {
  return hasOnlyDigits(carrier.us_dot);
}
