import { Typography } from '@material-ui/core';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { getGrouppedTimeline } from 'orders/view/OrderViewGrouppedDates';
import { useMemo } from 'react';
import Order from 'shared/types/order';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { makeCityStateZip } from 'shared/utils/AddressUtils';

interface OrderWaypointInfoProps {
  order: Order;
  type: 'pickup' | 'delivery';
}

const useWaypointInfo = function ({ order, type }: OrderWaypointInfoProps) {
  return useMemo(() => {
    const timeline = getGrouppedTimeline(order);
    const { items } = timeline[type];

    const { title, content = [] } =
      (items.length > 0 && items[items.length - 1]) || {};

    const dateItem = Array.from(content)
      .reverse()
      .find((item) => item.date)?.date;

    const address = makeCityStateZip(order[type]?.venue);

    return {
      title,
      dateItem,
      address,
    };
  }, [order]);
};

export const OrderWaypointInfo = ({ order, type }: OrderWaypointInfoProps) => {
  const isMobile = useResponsiveValue(true, false);

  const { title, dateItem, address } = useWaypointInfo({ order, type });

  const venueName = order[type]?.venue?.name;

  return (
    <Stack space="none">
      {address ? (
        <Typography variant="body1" component="div">
          <TextOverflow size="90%">
            {makeCityStateZip(order[type]?.venue)}
          </TextOverflow>
        </Typography>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No {type} address
        </Typography>
      )}

      {title && dateItem ? (
        <Inline>
          <Typography data-intercom-target={`${type} date`}>
            {Array.isArray(dateItem) ? (
              <DetailedFormattedDateRange
                range={dateItem}
                title={title}
                placement="bottom"
              />
            ) : (
              <DetailedFormattedDate
                variant="ShortDate"
                date={dateItem}
                title={title}
                placement="bottom"
              />
            )}
          </Typography>

          {venueName && (
            <>
              <Typography color="textSecondary">·</Typography>
              <Typography color="textSecondary" component="div">
                {isMobile ? (
                  <TextOverflow size={175}>{venueName}</TextOverflow>
                ) : (
                  venueName
                )}
              </Typography>
            </>
          )}
        </Inline>
      ) : (
        <Typography color="textSecondary">No {type} date</Typography>
      )}
    </Stack>
  );
};
