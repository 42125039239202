import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useDeliveryVerification } from 'orders/data/OrderDeliveryVerificationAPI';
import { useState } from 'react';
import { OrderDTO } from 'shared/dto/Order/OrderDTO';
import Order from 'shared/types/order';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OnHoldReasonList = styled.ul`
  padding-inline-start: 24px;
  margin: 0;
`;
const OnHoldReasonListItem = styled.li`
  margin-bottom: 4px;
`;

interface OnHoldReasonTooltipProps {
  order: Order;
}

export function OrderActivityPaymentOnHoldTooltip({
  order,
}: OnHoldReasonTooltipProps) {
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const { data, isLoading } = useDeliveryVerification(order.id, isTooltipOpen);
  return (
    <Tooltip
      onOpen={() => {
        setTooltipOpen(true);
      }}
      title={
        <Box width="218px">
          {isLoading ? (
            <SpinnerContainer>
              <CircularProgress size={20} />
            </SpinnerContainer>
          ) : (
            <Stack space="xsmall">
              <Typography>
                Payment wasn’t initiated automatically due to:
              </Typography>
              <OnHoldReasonList>
                {data?.fail_reasons?.map((reason) => (
                  <OnHoldReasonListItem key={reason}>
                    {OrderDTO.getDeliveryVerificationFailureReasonText(reason)}
                  </OnHoldReasonListItem>
                ))}
              </OnHoldReasonList>
              <Typography>
                Initiate payment manually after confirming the delivery and
                reviewing the price.
              </Typography>
            </Stack>
          )}
        </Box>
      }
    >
      <Info color="action" fontSize="small" />
    </Tooltip>
  );
}
