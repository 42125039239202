import { has } from 'lodash-es';
import { counterpartyDTO } from 'shared/dto/CounterpartyDTO';
import { CustomerContactFormData } from 'shared/dto/CustomerContactDTO';
import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export const isCustomerDTO = (value: unknown): value is CustomerDTO => {
  return (
    !!value && typeof value === 'object' && has(value, 'bill_the_delivery')
  );
};

export type CustomerFormData = Partial<CustomerDTO>;

export interface CustomerWithContactFormData {
  customer: Partial<CustomerDTO>;
  contact?: Partial<CustomerContactFormData>;
}

export type CustomerDTO = InferType<typeof customerDTO> &
  InferType<typeof counterpartyDTO>;

export const customerDTO = counterpartyDTO.concat(
  yupObject({
    email: yupString().email().nullable(),
    bill_the_delivery: yupBoolean().notRequired(),
    use_broker_load_id: yupBoolean().notRequired(),
    fax: yupString().nullable(),
    internal_notes: yupString().nullable(),
    loadboard_instructions: yupString().nullable(),
    order_instructions: yupString().nullable(),
    personal_page_url: yupString().nullable(),
    phone: yupString().nullable(),
    phone_extension: yupString().nullable().optional(),
  }),
);
