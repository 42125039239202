import { Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronRight, Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { showIntercomDialog } from 'shared/helpers/IntercomHelpers';
import { RequirementItem } from 'shared/ui/RequirementItem';
import {
  trackVerificationApplicationEvent,
  useTrackContactComplianceEvent,
} from '../data/VerificationAnalyticsEvents';

export interface W9HelpDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function W9HelpDrawer({ open, onClose }: W9HelpDrawerProps) {
  const trackContactComplianceEvent = useTrackContactComplianceEvent();
  const isInternationalShippersEnabled = useFeatureToggle(
    'verification-application.international-shippers.enabled.ui',
  );

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Learn more"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <DrawerContent>
        <Box maxWidth="400px">
          <Stack space="xlarge">
            <Stack space="small">
              <Typography variant="h2">
                Why do we need a current W-9?
              </Typography>
              <Typography>
                A W-9 is an official United States income tax document that
                helps Super Dispatch verify important information needed to
                confirm your business.
              </Typography>
            </Stack>
            <Stack space="small">
              <Typography>Before uploading your W-9 verify: </Typography>
              <RequirementItem text="The company name on the W-9 matches the company name on your Super Dispatch account." />
              <RequirementItem text="Your W-9 contains your TIN number." />
              <RequirementItem text="Your W-9 is signed and dated within the last 2 years." />
            </Stack>
            <Stack space="small">
              <Typography variant="h4">Can we help?</Typography>
              <AnchorButton
                variant="text"
                endIcon={<ChevronRight />}
                target="_blank"
                href="https://support.superdispatch.com/en/articles/6902578-shipper-tms-verified-shipper-requirements-and-information"
              >
                Read our Article
              </AnchorButton>
              <Button
                variant="text"
                endIcon={<ChevronRight />}
                onClick={() => {
                  trackContactComplianceEvent('W-9');
                  showIntercomDialog();
                }}
              >
                Contact our Compliance Team
              </Button>
              {isInternationalShippersEnabled && (
                <Button
                  variant="text"
                  endIcon={<ChevronRight />}
                  onClick={() => {
                    trackVerificationApplicationEvent({
                      name: 'Shipper Clicked on "I Do Not Own A W-9" Link',
                    });
                  }}
                >
                  I do not own a W-9
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </DrawerContent>
      <DrawerActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Ok
        </Button>
      </DrawerActions>
    </Drawer>
  );
}
