import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import {
  IntercomTourKey,
  launchIntercomTour,
} from 'shared/helpers/IntercomHelpers';

interface UseProductTourProps {
  tourKey: IntercomTourKey;
  enabled: boolean;
}

export function useProductTour({ tourKey, enabled }: UseProductTourProps) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    launchIntercomTour(tourKey);
  }, [enabled, tourKey]);
}

export function useSelfServeOnlyProductTour({
  enabled,
  ...props
}: UseProductTourProps) {
  const isFeatureEnabled = useFeatureToggle('self-serve-product-tour.ui');
  const isSelfServe = useUserState().user?.shipper.is_self_serve === true;

  useProductTour({
    enabled: isFeatureEnabled && isSelfServe && enabled,
    ...props,
  });
}
