import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { ConfirmDialog, ConfirmDialogProps } from 'shared/ui/ConfirmDialog';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';

interface DeleteOrderDialogProps extends ConfirmDialogProps {
  onCancel: () => void;
  onSubmit: () => void;
  onSubmitSuccess: () => void;
}

function DeleteOrderDialog({
  open,
  onCancel,
  onSubmit,
  onSubmitSuccess,
  ...props
}: DeleteOrderDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const { isSubmitting, handleSubmit } = useFormikEnhanced({
    key: open,
    initialValues: {},
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmDialog
      {...props}
      open={open}
      onClose={isSubmitting ? undefined : onCancel}
      cancelButtonProps={{ children: 'No', disabled: isSubmitting }}
      confirmButtonProps={{
        children: 'Yes, Delete',
        color: 'error',
        isLoading: isSubmitting,
        onClick: () => handleSubmit(),
      }}
    />
  );
}

interface SingleDeleteOrderDialogProps {
  order?: Order;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export function SingleDeleteOrderDialog({
  order,
  onCancel,
  onSubmitSuccess,
}: SingleDeleteOrderDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { deleteOrder } = useSingleOrderActionAPI();

  return (
    <DeleteOrderDialog
      open={!!order}
      onCancel={onCancel}
      title="Delete this order?"
      onSubmit={() => {
        if (!order) {
          return Promise.reject(new Error('No order found'));
        }

        return deleteOrder(order.id);
      }}
      onSubmitSuccess={() => {
        addSnackbar('Order deleted', { variant: 'success' });

        onSubmitSuccess();
      }}
    />
  );
}

interface BulkDeleteOrderDialogProps {
  orders: Order[] | undefined;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export function BulkDeleteOrderDialog({
  orders,
  onCancel,
  onSubmitSuccess,
}: BulkDeleteOrderDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkDeleteOrders } = useBulkOrderActionAPI();

  return (
    <DeleteOrderDialog
      open={!!orders}
      onCancel={onCancel}
      title={
        orders && orders.length > 1
          ? 'Delete selected orders?'
          : 'Delete this order?'
      }
      onSubmit={() => {
        if (orders) {
          const ids = orders.map((x) => x.id);
          return bulkDeleteOrders(ids);
        }

        return Promise.reject(new Error('No order found'));
      }}
      onSubmitSuccess={() => {
        if (orders) {
          trackEventLegacy('Bulk Delete', {
            count: orders.length,
            orders: orders.map((order) => ({ status: order.status })),
          });
          addSnackbar('Order(s) deleted', { variant: 'success' });

          onSubmitSuccess();
        }
      }}
    />
  );
}
