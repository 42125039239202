import {
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { OwnerFormFields } from './OwnerFormFields';
import { useOwnerAddForm } from './useOwnerAddForm';

interface OwnerDrawerProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function OwnerAddDrawerContent({
  onClose,
  onSubmitSuccess,
}: OwnerDrawerProps) {
  const formik = useOwnerAddForm({
    onSubmitSuccess,
    onClose,
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title="Add Representative"
      actions={<FormikDrawerActions />}
    >
      <OwnerFormFields withControllerCheckbox={true} />
    </FormikDrawerContent>
  );
}
