import { Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDateRange, parseDate } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { UsageIndicator } from './UsageIndicator';
import { useUsageDetails } from './useUsageDetails';

export function OverviewSection() {
  const { usageDetails, isUsageDetailsLoading } = useUsageDetails();

  if (isUsageDetailsLoading) {
    return (
      <Stack space="small">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  if (!usageDetails) {
    return null;
  }

  const postedVINs = usageDetails.usages.find(
    (usage) => usage.name === 'VIN_POSTED',
  );
  const deliveredVINs = usageDetails.usages.find(
    (usage) => usage.name === 'VIN_DELIVERED',
  );

  return (
    <Stack space="small">
      <Typography>
        Overage fees apply when the posted or delivered VINs exceed 50. Charges
        are assessed separately for each category, depending on the number of
        VINs that exceed the plan limit.{' '}
        <Link
          href="https://superdispatch.com/pricing-for-shippers"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </Link>
      </Typography>

      <TermDateRange
        start={usageDetails.current_term_start}
        end={usageDetails.current_term_end}
      />

      {postedVINs && (
        <UsageIndicator
          value={postedVINs.current_item_count}
          max={postedVINs.max_item_count}
          title="Number of Posted VINs"
          overageTitle="Number of Additional Posted VINs"
        />
      )}

      {deliveredVINs && (
        <UsageIndicator
          value={deliveredVINs.current_item_count}
          max={deliveredVINs.max_item_count}
          title="Number of Delivered VINs"
          overageTitle="Number of Additional Delivered VINs"
        />
      )}
    </Stack>
  );
}

function TermDateRange({
  start,
  end,
}: {
  start: string | null;
  end: string | null;
}) {
  if (!start || !end) {
    return null;
  }

  const formattedDate = formatDateRange(
    [
      //extracting date from datetime
      parseDate(start).toFormat('yyyy-MM-dd'),
      parseDate(end).toFormat('yyyy-MM-dd'),
    ],
    {
      fallback: 'N/A',
    },
    { format: 'DateISO' },
  );

  return (
    <Inline verticalAlign="center">
      <CalendarIcon color="action" fontSize="small" />
      <Typography>{formattedDate}</Typography>
    </Inline>
  );
}
