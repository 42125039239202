import { Link, Typography } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import styled from 'styled-components';

interface Props {
  type: 'customer' | 'broker';
}

const StyledTypography = styled(Typography)`
  color: ${Color.Dark300};
`;

export function TermsServiceLink({ type }: Props) {
  return (
    <>
      <StyledTypography align="center">
        By signing in, you agree to{' '}
        {type === 'customer' ? 'the' : "Super Dispatch's"}{' '}
      </StyledTypography>

      <StyledTypography align="center">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://superdispatch.com/terms-of-service"
        >
          {type === 'customer' ? 'Terms of Service' : 'Terms & Conditions'}
        </Link>{' '}
        and{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://superdispatch.com/privacy-policy"
        >
          Privacy Policy
        </Link>
      </StyledTypography>
    </>
  );
}
