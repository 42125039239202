import { Avatar, Card, CardContent, Link, Typography } from '@material-ui/core';
import { AccountBalance } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import {
  showIntercomDialog,
  showNewMessage,
} from 'shared/helpers/IntercomHelpers';
import styled from 'styled-components';
import { BankAccountDTO } from '../data/SuperPayDTO';
import { InformationErrorBanner } from '../helpers/InformationErrorBanner';
import { SuperPayAlert } from '../notifications/SuperPayAlert';
import { VerificationBankStatusTag } from './VerificationStatusTag';

const BankAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  background: ${Color.Silver200};
`;

const ContactSupportLink = styled(Link)`
  text-underline-offset: 2px;
  text-decoration-color: ${Color.Silver500};
  cursor: pointer;
`;

function BankAccountVerificationLocked() {
  return (
    <Alert severity="critical">
      <Inline verticalAlign="center">
        <Typography>
          Too many failed attempts of micro-deposits verification. Contact
          support for assistance.
        </Typography>
        <Button
          variant="neutral"
          size="small"
          data-intercom-target="SuperPay Bank Verification Status Failed"
          onClick={showIntercomDialog}
        >
          Contact Support
        </Button>
      </Inline>
    </Alert>
  );
}

export function BankAccountInformation({
  bankAccount,
  hasAlert,
}: {
  bankAccount: BankAccountDTO;
  hasAlert: boolean;
}) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Card square={isMobile} aria-label="bank account">
      <CardContent>
        <Stack space="small">
          <Typography variant="h3">Bank Account</Typography>
          <Columns>
            <Column>
              <Inline verticalAlign="center" space="small">
                <BankAvatar>
                  <AccountBalance color="action" />
                </BankAvatar>
                <Stack space="none">
                  <Typography variant="h3">
                    &bull;&bull;&bull;&bull;{bankAccount.display_number}
                  </Typography>
                  <Typography color="textSecondary">
                    {bankAccount.bank_name}
                  </Typography>
                </Stack>
              </Inline>
            </Column>
            <Column width="content">
              <VerificationBankStatusTag
                status={bankAccount.verification_status}
              />
            </Column>
          </Columns>

          {hasAlert ? (
            <SuperPayAlert />
          ) : (
            <>
              {(bankAccount.verification_status === 'ERRORED' ||
                bankAccount.verification_status === 'VERIFICATION_FAILED') && (
                <InformationErrorBanner
                  text="Bank account verification error. Contact support for assistance."
                  buttonText="Contact Support"
                  onButtonClick={showIntercomDialog}
                />
              )}

              {bankAccount.verification_status === 'VERIFIED' && (
                <Inline space="xxsmall">
                  <Typography variant="caption" color="textSecondary">
                    To change bank account,
                  </Typography>
                  <ContactSupportLink
                    aria-label="contact support link"
                    variant="caption"
                    color="textSecondary"
                    onClick={(e) => {
                      e.preventDefault();
                      showNewMessage('I want to change my bank account');
                    }}
                  >
                    contact support.
                  </ContactSupportLink>
                </Inline>
              )}

              {bankAccount.verification_status === 'VERIFICATION_LOCKED' && (
                <BankAccountVerificationLocked />
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
