/*
- ETA (average: 43%): Top >80% ; Good: 55% - 80% ; Can Improve: 0-54%
- Late Cancellation(average: 1%): Top 0% ; Good 1%-10% ; Can improve: >10%
- Photos taken on-site (average: 79%): Top: >90% ; Good: 80%-90% ; Can improve: 0-79%
 */

import { useMemo } from 'react';
import { CarrierPerformanceDTO } from '../data/CarrierPerformanceDTO';

export function etaThresholds(eta: number) {
  return {
    top: eta > 80,
    good: eta >= 55 && eta <= 80,
    canImprove: eta < 55,
  };
}

export function lateCancellationThresholds(lateCancellation: number) {
  return {
    top: lateCancellation < 1,
    good: lateCancellation >= 1 && lateCancellation <= 10,
    canImprove: lateCancellation > 10,
  };
}

export function photosOnSiteThresholds(photosTaken: number) {
  return {
    top: photosTaken > 90,
    good: photosTaken >= 80 && photosTaken <= 90,
    canImprove: photosTaken < 80,
  };
}

export function calculateRate(finalSample: number, generalSample: number) {
  return Math.round((finalSample / generalSample) * 100);
}

export function calculateAverageRate(
  finalSamples: number[],
  generalSample: number,
) {
  const totalRate = finalSamples.reduce(
    (acc, finalSample) => acc + calculateRate(finalSample, generalSample),
    0,
  );
  return Math.round(totalRate / finalSamples.length);
}

export function calculateETAMeanRate(indicators: CarrierPerformanceDTO) {
  const generalCount = indicators.eta_provided_general_count;
  const finalPickupCount = indicators.eta_provided_final_pickup_count;
  const finalDeliveryCount = indicators.eta_provided_final_delivery_count;

  if (!generalCount || finalPickupCount == null || finalDeliveryCount == null) {
    return null;
  }

  return calculateAverageRate(
    [finalPickupCount, finalDeliveryCount],
    generalCount,
  );
}

export function calculatePhotosOnSiteMeanRate(
  indicators: CarrierPerformanceDTO,
) {
  const generalCount = indicators.photos_on_site_general_count;
  const finalPickupCount = indicators.photos_on_site_final_pickup_count;
  const finalDeliveryCount = indicators.photos_on_site_final_delivery_count;

  if (!generalCount || finalPickupCount == null || finalDeliveryCount == null) {
    return null;
  }

  return calculateAverageRate(
    [finalPickupCount, finalDeliveryCount],
    generalCount,
  );
}

export function calculateLateCancellationMeanRate(
  indicators: CarrierPerformanceDTO,
) {
  const generalCount = indicators.late_cancellation_general_count;
  const finalCount = indicators.late_cancellation_final_count;

  if (!generalCount || finalCount == null) return null;

  return calculateRate(finalCount, generalCount);
}

type Rates = [
  number | null,
  { top: boolean; good: boolean; canImprove: boolean } | null,
];

export function useCalculateEtaRates(
  indicators: CarrierPerformanceDTO | undefined,
): Rates {
  return useMemo(() => {
    if (!indicators) return [null, null];

    const etaProvidedMeanRate = calculateETAMeanRate(indicators);
    const thresholds =
      etaProvidedMeanRate !== null ? etaThresholds(etaProvidedMeanRate) : null;

    return [etaProvidedMeanRate, thresholds];
  }, [indicators]);
}

export function useCalculateLateCancellationRates(
  indicators: CarrierPerformanceDTO | undefined,
): Rates {
  return useMemo(() => {
    if (!indicators) return [null, null];

    const lateCancellationMeanRate =
      calculateLateCancellationMeanRate(indicators);
    const thresholds =
      lateCancellationMeanRate !== null
        ? lateCancellationThresholds(lateCancellationMeanRate)
        : null;

    return [lateCancellationMeanRate, thresholds];
  }, [indicators]);
}

export function useCalculatePhotosOnSiteRates(
  indicators: CarrierPerformanceDTO | undefined,
): Rates {
  return useMemo(() => {
    if (!indicators) return [null, null];

    const photosOnSiteMeanRate = calculatePhotosOnSiteMeanRate(indicators);
    const thresholds =
      photosOnSiteMeanRate !== null
        ? photosOnSiteThresholds(photosOnSiteMeanRate)
        : null;

    return [photosOnSiteMeanRate, thresholds];
  }, [indicators]);
}
