import {
  Card,
  CardContent,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Edit, Info } from '@material-ui/icons';
import { Column, Columns, Inline, Stack, useUID } from '@superdispatch/ui';
import { ReactNode, useState } from 'react';

interface Props {
  title: ReactNode;
  hint?: ReactNode;
  children: ReactNode;
  onEdit: () => void;
}

export function TerminalDetailsCard({ title, hint, onEdit, children }: Props) {
  const uid = useUID();
  const [isHovered, setHover] = useState(false);

  return (
    <Card
      aria-labelledby={uid}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardContent>
        <Stack space="small">
          {title && (
            <Columns align="center">
              <Column>
                <Inline space="xxsmall" verticalAlign="center">
                  <Typography id={uid} variant="h3" display="inline">
                    {title}
                  </Typography>

                  {hint && (
                    <Tooltip title={hint}>
                      <Info color="action" fontSize="small" />
                    </Tooltip>
                  )}
                </Inline>
              </Column>

              <Column width="content">
                <Fade in={isHovered}>
                  <IconButton
                    size="small"
                    onClick={onEdit}
                    aria-label="edit terminal info"
                  >
                    <Edit />
                  </IconButton>
                </Fade>
              </Column>
            </Columns>
          )}

          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
