import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';
import { AuthorityRevocationDialog } from './AuthorityRevocationDialog';
import { VerificationApplicationDialog } from './VerificationApplicationDialog';

interface VerificationDialogsProps {
  open: boolean;
  onClose: () => void;
}

export function VerificationDialogs({
  open,
  onClose,
}: VerificationDialogsProps) {
  const isAuthorityRevocationEnabled = useFeatureToggle(
    'authority-revocation.enabled.ui',
  );

  const { data: verification } = useVerificationApplication();

  if (isAuthorityRevocationEnabled && verification?.status === 'REVOKED') {
    return <AuthorityRevocationDialog open={open} onClose={onClose} />;
  }

  return <VerificationApplicationDialog open={open} onClose={onClose} />;
}
