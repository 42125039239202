import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getVerificationBackUrl } from '../core/VerificationBackUrl';
import {
  trackClosedVerificationApplicationEvent,
  trackVerificationApplicationEvent,
} from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';
import { VerificationApplicationCompany } from './VerificationApplicationCompany';
import { VerificationApplicationIdentification } from './VerificationApplicationIdentification';
import { VerificationApplicationInsurance } from './VerificationApplicationInsurance';
import { VerificationApplicationLayout } from './VerificationApplicationLayout';

export function VerificationApplicationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useVerificationApplication();

  return (
    <VerificationApplicationLayout
      onBackButton={() => {
        trackClosedVerificationApplicationEvent(location.pathname);
        navigate(getVerificationBackUrl());
      }}
    >
      <Routes>
        {data && data.status !== 'NOT_SUBMITTED' && (
          <Route path="*" element={<Navigate to="/profile" />} />
        )}
        <Route
          path="1"
          element={
            <VerificationApplicationCompany
              onSubmitSuccess={() => {
                trackVerificationApplicationEvent({
                  name: 'Shipper Continued to Identification Step',
                });
                navigate(`/verification-application/steps/2${location.search}`);
              }}
            />
          }
        />
        <Route
          path="2"
          element={
            <VerificationApplicationIdentification
              onSubmitSuccess={() => {
                trackVerificationApplicationEvent({
                  name: 'Shipper Continued to Insurance Step',
                });
                navigate(`/verification-application/steps/3${location.search}`);
              }}
            />
          }
        />
        <Route
          path="3"
          element={
            <VerificationApplicationInsurance
              onSubmitSuccess={() => {
                trackVerificationApplicationEvent({
                  name: 'Shipper Submitted Verification Application',
                });
                navigate(
                  `/verification-application/underway${location.search}`,
                );
              }}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate to="/verification-application/steps/1" />}
        />
      </Routes>
    </VerificationApplicationLayout>
  );
}
