import { Typography } from '@material-ui/core';
import {
  Color,
  ColorProp,
  Column,
  Columns,
  ResponsiveProp,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import automatedPaymentsIllustration from '../assets/automated_payments.png';
import manualPaymentsIllustration from '../assets/manual_payments.png';

export const BannerContainer = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid ${Color.Silver400};
  border-bottom-width: 0;
  border-radius: 8px 8px 0 0;
  background-color: ${Color.White};
  padding-bottom: 32px;
  max-width: 674px;
  margin: auto;
`;

export const GradientCardContainer = styled(Box)`
  position: relative;
  &:before {
    background: linear-gradient(
      270deg,
      rgba(0, 99, 219, 0.15) -0.59%,
      rgba(255, 255, 255, 0.15) 51.28%,
      rgba(254, 74, 73, 0.15) 100%
    );
    filter: blur(50px);
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
  }
`;

export const PromoCardTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

export function PromoCard({
  backgroundColor,
  imgSrc,
  title,
  subtitle,
  minHeight,
  maxImgWidth,
}: {
  backgroundColor: ColorProp;
  imgSrc: string;
  title: string;
  subtitle: string;
  minHeight?: ResponsiveProp<string>;
  maxImgWidth?: ResponsiveProp<string>;
}) {
  return (
    <Box
      backgroundColor={backgroundColor}
      borderRadius="medium"
      minHeight={minHeight ?? '252px'}
      padding={['medium', 'small', 'small']}
    >
      <Stack align="center" space="small">
        <Box maxWidth={maxImgWidth ?? '200px'}>
          <img src={imgSrc} width="100%" />
        </Box>

        <Stack space="xxsmall">
          <Typography align="center" variant="h4">
            {title}
          </Typography>
          <Typography align="center" color="textSecondary">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export function PromoCardWide({
  imgSrc,
  title,
  subtitle,
}: {
  imgSrc: string;
  title: string;
  subtitle: string;
}) {
  const isMobile = useResponsiveValue(true, false);
  const align = isMobile ? 'center' : 'left';

  return (
    <Box
      backgroundColor="Blue50"
      borderRadius="small"
      padding="medium"
      width="100%"
    >
      <Columns
        align="center"
        collapseBelow="tablet"
        space="small"
        reverse={isMobile}
      >
        <Column width="1/2">
          <Stack space="xxsmall" align={align}>
            <Typography variant="h4" align={align}>
              {title}
            </Typography>
            <Box maxWidth={['100%', '300px']}>
              <TextBox variant="body" color="secondary" align={align}>
                {subtitle}
              </TextBox>
            </Box>
          </Stack>
        </Column>
        <Column width="1/2">
          <Stack align="center">
            <Box maxWidth="253px">
              <img src={imgSrc} width="100%" />
            </Box>
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
}

export function PromoFlexibleWorkflows() {
  return (
    <Box
      backgroundColor="Silver200"
      borderRadius="small"
      padding="medium"
      width="100%"
    >
      <Columns align="center" collapseBelow="tablet" space="small">
        <Column width="1/2">
          <Stack align="center">
            <Box maxWidth="240px">
              <img
                src={automatedPaymentsIllustration}
                width="100%"
                alt="Automated payments"
              />
            </Box>
            <Stack space="xxsmall">
              <Typography variant="h4" align="center">
                Automated Payments
              </Typography>
              <TextBox variant="body" color="secondary" align="center">
                Payments are processed on your terms automatically after Digital
                Delivery Verification, so you only manage exceptions.
              </TextBox>
            </Stack>
          </Stack>
        </Column>
        <Column width="1/2">
          <Stack align="center">
            <Box maxWidth="240px">
              <img
                src={manualPaymentsIllustration}
                width="100%"
                alt="Manual payments"
              />
            </Box>
            <Stack space="xxsmall">
              <Typography variant="h4" align="center">
                Manual Payments
              </Typography>
              <TextBox variant="body" color="secondary" align="center">
                Full control and flexibility to process payments. One click, two
                options: process instantly or schedule for a future date.
              </TextBox>
            </Stack>
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
}
