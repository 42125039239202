import { VehiclePhotoDTO } from 'shared/dto/Order/VehicleDTO';

export function mapVehiclePhotoToSlide(photos: VehiclePhotoDTO[] | null) {
  if (photos && photos.length > 0) {
    let slides = photos
      .map((photoItem) => photoItem.photo_url || photoItem.thumbnail_url || '')
      .filter(Boolean);
    return slides;
  }
  return [];
}
