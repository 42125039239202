import { Link, Tooltip, Typography } from '@material-ui/core';
import { Comment } from '@material-ui/icons';
import { FormattedDate, FormattedRelativeTime } from '@superdispatch/dates';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { DateTime } from 'luxon';
import { DeliveryETADTO } from 'orders/data/dto/ETAInformationDTO';
import { useMemo } from 'react';
import { ETAIcon, ETASystemIcon } from 'shared/icons/ETAIcon';
import { Step } from 'shared/types/step';
import { getDateWithOffset } from 'shared/utils/DataUtils';
import { getGooglePathURL } from 'shared/utils/MapUtils';
import styled from 'styled-components';
import { trackEvent } from '../helpers/AnalyticsHelpers';
import { InlineBulletItems } from './BulletTextItem';
import { ResponsiveTooltip } from './ResponsiveTooltip';

const StyledDateCaption = styled(Typography)`
  color: ${Color.Dark300};
`;

const StyledComment = styled(Comment)`
  color: ${Color.Dark100};
  margin-top: 2px;
  z-index: 1;
`;

interface LocationUpdatedAtProps {
  location: DeliveryETADTO['location'];
  updatedAt: DeliveryETADTO['updated_at'];
  delivery?: Step;
}

const LocationUpdatedAt = ({
  location,
  updatedAt,
  delivery,
}: LocationUpdatedAtProps) => {
  const googlePathUrl = useMemo(() => {
    if (!location || !delivery) {
      return '';
    }

    return getGooglePathURL(location, delivery);
  }, [location, delivery]);

  return (
    <span data-intercom-target="driver location info">
      <InlineBulletItems>
        {googlePathUrl ? (
          <Link
            target="_blank"
            rel="noreferrer"
            color="textSecondary"
            variant="caption"
            href={googlePathUrl}
          >
            {location?.text}
          </Link>
        ) : (
          <Typography color="textSecondary" variant="caption">
            {location?.text}
          </Typography>
        )}

        {updatedAt && (
          <Typography variant="caption" color="textSecondary">
            <FormattedRelativeTime date={updatedAt} format="JodaISO" />
          </Typography>
        )}
      </InlineBulletItems>
    </span>
  );
};

interface NotesTooltipProps {
  notes: DeliveryETADTO['notes'];
}

const NotesTooltip = ({ notes }: NotesTooltipProps) => {
  if (!notes) {
    return null;
  }

  return (
    <ResponsiveTooltip
      title={notes || ''}
      interactive={true}
      placement="top"
      onOpen={() => {
        trackEvent('Shipper hovered over Delivery ETA notes');
      }}
    >
      <StyledComment style={{ marginRight: 2 }} fontSize="small" />
    </ResponsiveTooltip>
  );
};

interface ETATooltipProps {
  fromDate?: DateTime;
  inline?: boolean;
}

const ETATooltip = ({ fromDate, inline = true }: ETATooltipProps) => (
  <Tooltip title="Delivery ETA provided by driver">
    <Typography
      aria-label="delivery eta date"
      data-intercom-target="delivery eta"
    >
      Driver ETA{' '}
      <FormattedDate date={fromDate} format="JodaISO" variant="ShortDate" />
      {inline ? ',' : ''}
    </Typography>
  </Tooltip>
);

interface ETAInfoProps {
  inline?: boolean;
  fromDate?: DateTime;
  untilDate?: DateTime;
  timeZone: DeliveryETADTO['time_zone'];
}

const ETAInfo = ({
  inline = true,
  fromDate,
  untilDate,
  timeZone,
}: ETAInfoProps) => {
  const [from, until] = useMemo(() => {
    return [
      fromDate?.toLocaleString({
        hour: 'numeric',
        hourCycle: 'h12',
      }),
      untilDate?.toLocaleString({
        hour: 'numeric',
        hourCycle: 'h12',
      }),
    ];
  }, [fromDate, untilDate]);

  return (
    <Inline verticalAlign="center" noWrap={true} space="xxsmall">
      {inline ? (
        <>
          <Typography aria-label="delivery eta time from">{from}</Typography>
          <Typography>–</Typography>
          <Typography aria-label="delivery eta time until">{until}</Typography>
          {!!timeZone && (
            <Typography aria-label="delivery eta timezone">
              {timeZone}
            </Typography>
          )}
        </>
      ) : (
        <>
          <StyledDateCaption
            variant="caption"
            aria-label="delivery eta time from"
          >
            {from}
          </StyledDateCaption>
          <StyledDateCaption variant="caption">–</StyledDateCaption>
          <StyledDateCaption
            variant="caption"
            aria-label="delivery eta time until"
          >
            {until}
          </StyledDateCaption>
          {!!timeZone && (
            <StyledDateCaption
              variant="caption"
              aria-label="delivery eta timezone"
            >
              {timeZone}
            </StyledDateCaption>
          )}
        </>
      )}
    </Inline>
  );
};

interface DeliveryETAprops {
  delivery?: Step;
  deliveryETA?: DeliveryETADTO;
  inline?: boolean;
  showEmpty?: boolean;
}
export function DeliveryETA({
  delivery,
  deliveryETA,
  inline,
  showEmpty,
}: DeliveryETAprops) {
  const {
    date_from,
    date_until,
    zone_offset_minutes,
    location,
    updated_at,
    time_zone,
    notes,
  } = deliveryETA || {};

  const [isValidETA, fromDate, untilDate] = useMemo(() => {
    return [
      new Date(date_until || '') > new Date(),
      getDateWithOffset(date_from, zone_offset_minutes),
      getDateWithOffset(date_until, zone_offset_minutes),
    ];
  }, [date_from, date_until, zone_offset_minutes]);

  if (!isValidETA && !location?.text && !updated_at) {
    return showEmpty ? <Typography>-</Typography> : null;
  }

  const Icon = date_until ? ETAIcon : ETASystemIcon;

  return (
    <Inline space="xxsmall" noWrap={true} aria-label="delivery eta">
      <Icon style={{ marginTop: 2 }} fontSize="small" />
      {inline ? (
        <Inline
          space="xxsmall"
          noWrap={true}
          verticalAlign="center"
          aria-label="delivery eta"
        >
          {isValidETA && <ETATooltip fromDate={fromDate} />}
          {isValidETA && (
            <ETAInfo
              fromDate={fromDate}
              untilDate={untilDate}
              timeZone={time_zone}
            />
          )}
          <NotesTooltip notes={notes} />
          <LocationUpdatedAt
            location={location}
            updatedAt={updated_at}
            delivery={delivery}
          />
        </Inline>
      ) : (
        <Stack space="none">
          <Inline verticalAlign="center" space="xxsmall" noWrap={true}>
            {isValidETA && <ETATooltip fromDate={fromDate} inline={false} />}
            <NotesTooltip notes={notes} />
          </Inline>
          {isValidETA && (
            <ETAInfo
              fromDate={fromDate}
              untilDate={untilDate}
              timeZone={time_zone}
              inline={false}
            />
          )}
          <LocationUpdatedAt
            location={location}
            updatedAt={updated_at}
            delivery={delivery}
          />
        </Stack>
      )}
    </Inline>
  );
}
