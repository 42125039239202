import { MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { useField } from 'formik';
import { FormikCountryField } from 'shared/form/FormikCountryField';
import { USAStateOptions } from 'shared/types/USAState';

interface PaywallBillingAddressProps {
  onFocus?: () => void;
}

export function PaywallBillingAddress({ onFocus }: PaywallBillingAddressProps) {
  const [field] = useField('country');

  const isUSASelected = field.value === 'US';

  return (
    <Stack space="small">
      <Typography variant="h4">Billing Address</Typography>

      <FormikTextField
        label="Address Line 1"
        name="address"
        fullWidth={true}
        onFocus={onFocus}
      />

      <FormikTextField
        label={
          <Inline space="xxsmall">
            Address Line 2
            <Typography color="textSecondary">(Optional)</Typography>
          </Inline>
        }
        name="address_second_line"
        fullWidth={true}
        onFocus={onFocus}
      />

      <FormikTextField
        label="City"
        name="city"
        fullWidth={true}
        onFocus={onFocus}
      />

      <Columns space="small">
        <Column width="1/2">
          <FormikTextField
            label="State"
            name="state"
            fullWidth={true}
            select={isUSASelected}
            onFocus={onFocus}
          >
            {Array.from(USAStateOptions, (state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>

        <Column width="1/2">
          <FormikTextField
            name="zip"
            onChange={(event) => {
              event.target.value = event.target.value.toUpperCase();
            }}
            label={
              <Inline verticalAlign="center">
                ZIP Code
                <Tooltip
                  interactive={true}
                  placement="right"
                  title="Zip code of your card's billing address."
                >
                  <Info fontSize="small" htmlColor={Color.Dark100} />
                </Tooltip>
              </Inline>
            }
            onFocus={onFocus}
            fullWidth={true}
          />
        </Column>
      </Columns>

      <FormikCountryField
        name="country"
        fullWidth={true}
        onFocus={onFocus}
        TextFieldProps={{
          fullWidth: true,
          label: 'Country',
        }}
      />
    </Stack>
  );
}
