import { Box, MenuItem, TextField, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Color, Inline } from '@superdispatch/ui';
import { ChangeEvent } from 'react';
import { Pagination as PaginationDTO } from 'shared/types/pagination';
import styled from 'styled-components';

const pageSizeOptions = [
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
] as const;

const PaginationContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  padding: 16px;
  background-color: ${Color.White};
`;

interface Props {
  pagination: PaginationDTO;
  onSizeChange: (size: number) => void;
  onPageChange: (page: number) => void;
}

export function ManageCarriersPagination({
  pagination,
  onSizeChange,
  onPageChange,
}: Props) {
  return (
    <PaginationContainer>
      <Inline space="small" verticalAlign="center">
        <Typography>Page size:</Typography>

        <TextField
          select={true}
          placeholder="Page size"
          value={pagination.limit || pageSizeOptions[0].value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onSizeChange(Number(event.target.value));
          }}
        >
          {pageSizeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Inline>

      <Box display="flex" flex={1} justifyContent="center">
        <Pagination
          page={Number(pagination.page) + 1}
          boundaryCount={2}
          count={pagination.total_pages}
          onChange={(_, value) => {
            onPageChange(value - 1);
          }}
        />
      </Box>
    </PaginationContainer>
  );
}
