import { counterpartyDTO } from 'shared/dto/CounterpartyDTO';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type TerminalDTO = InferType<typeof terminalDTO>;

export const terminalDTO = counterpartyDTO.concat(
  yupObject({
    phone: yupString().nullable(),
  }),
);
