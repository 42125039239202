import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { ExitTransitionPlaceholder, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useSingleOrderActionAPI } from 'orders/data/OrderActionAPI';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { SuperPayNowPreview } from './SuperPayNowPreview';

interface SingleSuperPayNowDialogContentProps {
  order: Order;
  onClose: () => void;
  onSuccess: () => void;
}
function SingleSuperPayNowDialogContent({
  order,
  onClose,
  onSuccess,
}: SingleSuperPayNowDialogContentProps) {
  const { superPayNow } = useSingleOrderActionAPI();
  const { addSnackbar } = useSnackbarStack();

  const nonSuperPayOrdersCount = Number(order.payment?.method !== 'superpay');

  useEffect(() => {
    trackEvent('Shipper Opened SuperPay Now');
  }, []);

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      return superPayNow(order.id);
    },
    onSubmitSuccess: () => {
      addSnackbar('Order updated', { variant: 'success' });
      trackEvent('Shipper SuperPaid Now', {
        change_to_SuperPay: nonSuperPayOrdersCount,
      });
      onSuccess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <DialogTitle>
          <Typography variant="h3">SuperPay Now</Typography>
        </DialogTitle>

        <SuperPayNowPreview
          orders={[order]}
          nonSuperPayOrdersCount={nonSuperPayOrdersCount}
        />

        <DialogActions>
          <Button
            variant="neutral"
            onClick={() => {
              trackEvent('Shipper Canceled SuperPay Now Dialog');
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" pending={formik.isSubmitting}>
            SuperPay Now
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

interface SingleSuperPayNowDialogProps {
  order?: Order;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function SingleSuperPayNowDialog({
  order,
  onClose,
  onSubmitSuccess,
}: SingleSuperPayNowDialogProps) {
  return (
    <Dialog open={!!order} onClose={onClose}>
      <Box width={['auto', '400px']}>
        <ExitTransitionPlaceholder in={!!order}>
          {!!order && (
            <SingleSuperPayNowDialogContent
              order={order}
              onClose={onClose}
              onSuccess={onSubmitSuccess}
            />
          )}
        </ExitTransitionPlaceholder>
      </Box>
    </Dialog>
  );
}
