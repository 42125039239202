import { Color } from '@superdispatch/ui';

export function FilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M0.541662 1.67498C2.225 3.83331 5.33333 7.83331 5.33333 7.83331V12.8333C5.33333 13.2916 5.70833 13.6666 6.16666 13.6666H7.83333C8.29166 13.6666 8.66666 13.2916 8.66666 12.8333V7.83331C8.66666 7.83331 11.7667 3.83331 13.45 1.67498C13.875 1.12498 13.4833 0.333313 12.7917 0.333313H1.2C0.508329 0.333313 0.116662 1.12498 0.541662 1.67498Z"
        fill={Color.Dark200}
      />
    </svg>
  );
}
