import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { EmptyStateIcon } from 'shared/icons/EmptyStateIcon';

interface EmptyStateProps {
  title: string;
}

export const EmptyState: React.ComponentType<EmptyStateProps> = ({
  title,
  children,
}) => {
  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <EmptyStateIcon style={{ width: '162', height: '114', fill: 'none' }} />

      <Box mt={4}>
        <Typography variant="h2">{title}</Typography>
      </Box>

      {children}
    </Box>
  );
};
