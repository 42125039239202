import { Dialog, Link, Typography } from '@material-ui/core';
import { ArrowForward, CheckCircle } from '@material-ui/icons';
import { FormattedDate, parseDate } from '@superdispatch/dates';
import { Color, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useSubscriptionDetails } from '../data/PaywallAPI';
import { trackClickedGoToOrders } from '../PaywallAnalyticsEvents';

export function PaywallAccountUpgradedDialog({ open }: { open: boolean }) {
  const { data } = useSubscriptionDetails();
  return (
    <Dialog open={open} disableEscapeKeyDown={true}>
      <Box padding="large" maxWidth="480px">
        <Stack space="large" align="center">
          <Box margin="auto" fontSize="88px">
            <CheckCircle fontSize="inherit" htmlColor={Color.Green300} />
          </Box>
          <Stack space="xsmall" align="center">
            <Typography variant="h2">Your account is activated!</Typography>
            <Typography>
              Your subscription will start on{' '}
              <b>
                <FormattedDate
                  date={parseDate(data?.chargebee.activated_at)}
                  variant="ShortDate"
                />
                , {DateTime.now().get('year')}
              </b>
            </Typography>
          </Stack>

          <Typography align="center">
            Thank you for choosing Super Dispatch! If you have any questions
            please contact us at{' '}
            <Link
              color="primary"
              underline="none"
              href="mailto:support@superdispatch.com"
            >
              support@superdispatch.com
            </Link>{' '}
            or click the chat icon in the bottom right corner.
          </Typography>
          <Button
            onClick={() => {
              trackClickedGoToOrders(() => {
                // reloading the page as the paywall is a top wrapper
                window.location.href = '/orders';
              });
            }}
            size="large"
            endIcon={<ArrowForward />}
          >
            Go to Orders
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
