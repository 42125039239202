import {
  Box,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Add, GetApp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Stack, VisibilityObserver } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarMenuItem,
  SidebarMenuItemAvatar,
} from '@superdispatch/ui-lab';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { LocationCardIcon } from 'shared/icons/LocationCardIcon';
import { ButtonGroupDropdown } from 'shared/ui/ButtonGroupDropdown';
import { useTerminalsNavigation } from '../TerminalsRouteHelpers';
import { TerminalBatchDeleteConfirmation } from './TerminalBatchDeleteConfirmation';
import { TerminalsSidebarSearch } from './TerminalsSidebarSearch';

function SidebarLoadingItem() {
  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circle" width={32} height={32} />
      </ListItemIcon>
      <ListItemText primary={<Skeleton />} />
    </ListItem>
  );
}

function EmptyList() {
  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb={2}>
        <LocationCardIcon />
      </Box>

      <Typography variant="body2" color="textSecondary" align="center">
        This section shows
        <br /> list of terminals once
        <br /> they are added
      </Typography>
    </Box>
  );
}

interface Props {
  selectedTerminals: Set<number>;
  onSelectedTerminalsChange: (value: Set<number>) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  total: number;
  terminals: TerminalDTO[];
  isFetchingNextPage: boolean;
}

export function TerminalsList({
  selectedTerminals,
  onSelectedTerminalsChange,
  total,
  hasNextPage,
  fetchNextPage,
  terminals,
  isFetchingNextPage,
}: Props) {
  const {
    terminalsSearchParams: { query, current_terminal_guid },
    navigateToTerminals,
    navigateToTerminalsCreate,
    navigateToTerminalsImport,
  } = useTerminalsNavigation();

  function navigateToTerminal(guid: string) {
    navigateToTerminals({
      query,
      current_terminal_guid: guid,
    });
  }

  return (
    <Sidebar
      count={total}
      title="Terminals"
      id="terminals-sidebar"
      header={
        <Stack space="small">
          <ButtonGroupDropdown
            color="primary"
            variant="contained"
            startIcon={<Add />}
            fullWidth={true}
            onClick={navigateToTerminalsCreate}
            label="Create New"
            MenuListProps={{ disablePadding: true }}
          >
            <MenuItem onClick={navigateToTerminalsCreate}>
              <Add fontSize="small" color="action" />

              <Box ml={1}>Create New</Box>
            </MenuItem>

            <MenuItem onClick={navigateToTerminalsImport}>
              <GetApp fontSize="small" color="action" />

              <Box ml={1}>Import</Box>
            </MenuItem>
          </ButtonGroupDropdown>

          {terminals && selectedTerminals.size > 0 ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box ml="-6px">
                <Checkbox
                  color="primary"
                  aria-label="toggle all"
                  indeterminate={selectedTerminals.size < terminals.length}
                  checked={selectedTerminals.size === terminals.length}
                  onChange={(_, checked) => {
                    const ids = terminals.map((x) => x.id);
                    const nextSelectedTerminals = checked
                      ? new Set(ids)
                      : new Set<number>();

                    onSelectedTerminalsChange(nextSelectedTerminals);
                  }}
                />
              </Box>

              <Box mr="-6px">
                <TerminalBatchDeleteConfirmation
                  terminals={selectedTerminals}
                  onSubmitSuccess={() => {
                    onSelectedTerminalsChange(new Set());
                  }}
                />
              </Box>
            </Box>
          ) : (
            <TerminalsSidebarSearch />
          )}
        </Stack>
      }
    >
      {!terminals ? (
        <SidebarLoadingItem />
      ) : terminals.length === 0 ? (
        query ? (
          <Typography align="center" color="textSecondary">
            No search results
          </Typography>
        ) : (
          <EmptyList />
        )
      ) : (
        terminals.map((terminal) => (
          <SidebarMenuItem
            key={terminal.guid}
            openContentOnClick={true}
            selected={current_terminal_guid === terminal.guid}
            onClick={() => navigateToTerminal(terminal.guid)}
            avatar={
              <SidebarMenuItemAvatar
                value={selectedTerminals.has(terminal.id)}
                onChange={(checked) => {
                  const nextSelectedTerminals = new Set(selectedTerminals);

                  if (checked) {
                    nextSelectedTerminals.add(terminal.id);
                  } else {
                    nextSelectedTerminals.delete(terminal.id);
                  }

                  onSelectedTerminalsChange(nextSelectedTerminals);
                }}
              >
                {terminal.name}
              </SidebarMenuItemAvatar>
            }
          >
            {terminal.name}
          </SidebarMenuItem>
        ))
      )}

      {hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (!isFetchingNextPage && visibility === 'visible') {
              fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <div ref={ref}>
              {Array.from({ length: 3 }, (_, key) => (
                <SidebarLoadingItem key={key} />
              ))}
            </div>
          )}
        />
      )}
    </Sidebar>
  );
}
