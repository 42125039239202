import { Typography } from '@material-ui/core';
import {
  CalendarQuickSelection,
  CalendarQuickSelectionItem,
  DateField,
  DateRangeField,
  parseDate,
  stringifyDate,
} from '@superdispatch/dates';
import { useValueObserver } from '@superdispatch/hooks';
import { useUID } from '@superdispatch/ui';
import { useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { endOfWorkDay, startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { Load } from 'shared/types/load';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';

interface StepScheduledFieldProps {
  type: string;
  label: string;
  scheduledAtName: string;
  scheduledAtRelatedFieldName?: string;
  scheduledEndsAtName: string;
  scheduledEndsAtRelatedFieldName?: string;
  enableClearable?: boolean;
  values?: Load;
}

export function LoadStepScheduledDateField({
  type,
  values,
  label,
  scheduledAtName,
  scheduledAtRelatedFieldName,
  scheduledEndsAtName,
  scheduledEndsAtRelatedFieldName,
  enableClearable,
}: StepScheduledFieldProps) {
  const uid = useUID();
  const { setFieldValue } = useFormikContext<BuildLoadsFormValues>();
  const isEstimated = values?.[type].date_type === 'estimated';

  const [{ value: scheduledAtInput }] = useField(scheduledAtName);
  const [{ value: scheduledEndsAtInput }] = useField(scheduledEndsAtName);

  const selectionStart = useMemo(() => startOfWorkDay(DateTime.local()), []);

  useValueObserver(values?.[type].date_type, () => {
    if (!isEstimated) {
      setFieldValue(`${type}.scheduled_ends_at`, null);
    }
  });

  const setFormValue = (name: string, value: string | null) => {
    setFieldValue(name, value);
  };

  const customerDateChangeHandler = (
    name: string,
    value: string | null,
    relatedFieldName?: string,
  ) => {
    setFormValue(name, value);

    if (relatedFieldName && !values?.[relatedFieldName]) {
      setFormValue(relatedFieldName, value);
    }
  };

  if (!isEstimated) {
    return (
      <DateField
        id={uid}
        label={<Typography color="textPrimary">{label}</Typography>}
        fullWidth={true}
        enableClearable={enableClearable ?? false}
        value={scheduledAtInput}
        CalendarProps={{ initialTime: startOfWorkDay(DateTime.local()) }}
        onChange={({ stringValue }) =>
          setFormValue(scheduledAtName, stringValue)
        }
      />
    );
  }

  return (
    <DateRangeField
      id={uid}
      label={<Typography color="textPrimary">{label}</Typography>}
      fullWidth={true}
      helperText={label === 'Carrier Pickup Date' ? 'Required for LBs' : ''}
      enableClearable={enableClearable ?? false}
      CalendarProps={{ direction: type === 'pickup' ? 'row' : 'row-reverse' }}
      value={[scheduledAtInput, scheduledEndsAtInput]}
      onChange={({ dateValue: [scheduledAt, scheduledEndsAt] }) => {
        customerDateChangeHandler(
          scheduledAtName,
          scheduledAt &&
            stringifyDate(startOfWorkDay(scheduledAt), {
              format: 'JodaISO',
            }),
          scheduledAtRelatedFieldName,
        );

        customerDateChangeHandler(
          scheduledEndsAtName,
          scheduledEndsAt &&
            stringifyDate(endOfWorkDay(scheduledEndsAt), { format: 'JodaISO' }),
          scheduledEndsAtRelatedFieldName,
        );
      }}
      renderFooter={() => (
        <Typography color="textSecondary">
          Selected date range allows preferred carriers to instantly book loads
          inside the Super Loadboard.
          <br />
          Dates out of selected range will still be available to request.
        </Typography>
      )}
      renderQuickSelection={({ close, dateValue, change }) => (
        <CalendarQuickSelection>
          {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((daysCount, idx) => {
            const [startValue, endValue] = dateValue;
            const selectionEnd = stringifyDate(
              endOfWorkDay(
                parseDate(selectionStart, { format: 'JodaISO' }),
              ).plus({ day: daysCount - 1 }),
              { format: 'JodaISO' },
            );

            return (
              <CalendarQuickSelectionItem
                key={idx}
                selected={
                  startValue === selectionStart && endValue === selectionEnd
                }
                onClick={() => {
                  change([selectionStart, selectionEnd]);
                  close();
                }}
              >
                {`${daysCount} days`}
              </CalendarQuickSelectionItem>
            );
          })}
        </CalendarQuickSelection>
      )}
    />
  );
}
