import { plainToClass } from 'class-transformer';
import { unset } from 'lodash-es';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { Order } from 'shared/types/order';
import { User } from 'shared/types/user';
import { OrderFormValues } from '../OrderForm';
import { normalizeContact, userNameToId } from './orderFormHelpers';

export function transformToOrder(
  formValues: OrderFormValues,
  users: User[],
): Order {
  const { price, pickup, customer, delivery, dispatcher_name } = formValues;

  const payload: Order = {
    ...formValues,
    price:
      typeof price === 'string'
        ? Math.round(parseFloat(price) * 100) / 100
        : price,
    customer: {
      ...customer,
      ...normalizeContact(customer.contact),
    },
    pickup: {
      ...pickup,
      venue: {
        ...pickup.venue,
        ...normalizeContact(pickup.venue.contact),
      },
    },
    delivery: {
      ...delivery,
      venue: {
        ...delivery.venue,
        ...normalizeContact(delivery.venue.contact),
      },
    },
    dispatcher_user_id: userNameToId(users, formValues.dispatcher_name) || null,
    vehicles: plainToClass(VehicleDTO, formValues.vehicles),
  };

  unset(payload, 'customer.contact');
  unset(payload, 'pickup.venue.contact');
  unset(payload, 'delivery.venue.contact');

  if (!dispatcher_name) {
    unset(payload, 'dispatcher_name');
  }

  return payload;
}

/**
 * Modify created order with values from form.
 * Adding stripped 'selected_from_recent' fields for analytics.
 * @param createdOrder - order created by API
 * @param formValues - form values
 * @returns modified order
 */
export const modifyCreatedOrder = (
  createdOrder: Order,
  formValues: OrderFormValues,
) => {
  let modifiedOrder: Order = structuredClone(createdOrder);

  if (modifiedOrder.customer) {
    modifiedOrder.customer.selected_from_recent =
      formValues.customer.selected_from_recent;
  }

  if (modifiedOrder.pickup?.venue) {
    modifiedOrder.pickup.venue.selected_from_recent =
      formValues.pickup.venue.selected_from_recent;
  }

  if (modifiedOrder.delivery?.venue && formValues.delivery.venue) {
    modifiedOrder.delivery.venue.selected_from_recent =
      formValues.delivery.venue.selected_from_recent;
  }

  return modifiedOrder;
};
