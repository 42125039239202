import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { ORDER_RECENT_SEARCH_TYPE_KEY } from 'shared/helpers/RecentSelectionsHelpers';
import { useUserState } from '../../../shared/data/AppUserState';
import { OrderListSearchTypeCategoryField } from './OrderListSearchTypeCategoryField';
import { OrderListSearchTypeField } from './OrderListSearchTypeField';

interface OrderListSearchType {
  fullWidth?: boolean;
}

export const defaultSearchType = 'number';

export function useSearchType(): string {
  return useStorageValue(ORDER_RECENT_SEARCH_TYPE_KEY) || defaultSearchType;
}

export function setSearchType(value: string) {
  writeStorageItem(ORDER_RECENT_SEARCH_TYPE_KEY, value);
}

export function OrderListSearchType({ fullWidth }: OrderListSearchType) {
  const { user } = useUserState();

  return user?.shipper.shipper_type === 'BROKER' ? (
    <OrderListSearchTypeCategoryField fullWidth={fullWidth} />
  ) : (
    <OrderListSearchTypeField fullWidth={fullWidth} />
  );
}
