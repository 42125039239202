import { createSvgIcon } from './IconUtils';

export const VerifiedIconNew = createSvgIcon(
  'VerifiedIconNew',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.46665 1.52642L7.00001 0.333336L5.53337 1.52642L3.66668 1.2265L2.99307 2.99306L1.22651 3.66667L1.52643 5.53336L0.333344 7L1.52643 8.46664L1.22651 10.3333L2.99307 11.0069L3.66668 12.7735L5.53337 12.4736L7.00001 13.6667L8.46665 12.4736L10.3333 12.7735L11.0069 11.0069L12.7735 10.3333L12.4736 8.46664L13.6667 7L12.4736 5.53336L12.7735 3.66667L11.0069 2.99306L10.3333 1.2265L8.46665 1.52642ZM9.48805 4.38819L5.92037 7.89469L4.51612 6.51322L3.64025 7.36882L5.92027 9.61187L10.3591 5.24507L9.48805 4.38819Z"
  />,
  { viewBox: '0 0 14 14', height: 14, width: 14 },
);
