import { Exclude, Expose } from 'class-transformer';
import { MapboxGeocoding } from 'shared/helpers/MapboxHelpers';

export type DictionaryPlaceKey = 'pickup_addresses' | 'delivery_addresses';
export type DictionaryStringKey =
  | 'statuses'
  | 'tags'
  | 'payment_statuses'
  | 'payment_methods'
  | 'customer_names'
  | 'dispatcher_names'
  | 'salespersons'
  | 'lot_numbers'
  | 'contact_names'
  | 'delivered_on_date'
  | 'is_superpay_available_carrier'
  | 'is_carrier_requested_superpay'
  | 'is_expedited_payment';
export type DictionaryCarriersKey = 'carriers';

export type DictionaryKey =
  | DictionaryPlaceKey
  | DictionaryStringKey
  | DictionaryCarriersKey;

export type DictionaryValues = {
  [key in DictionaryStringKey]?: string[];
} & {
  [key in DictionaryPlaceKey]?: DictionaryPlaceDTO[];
} & {
  [key in DictionaryCarriersKey]?: DictionaryCarrierDTO[];
};

export class DictionaryPlaceDTO {
  static fromGeocode({
    postcode,
    region_short,
    country_short,
    place,
  }: MapboxGeocoding): DictionaryPlaceDTO {
    return {
      zip: postcode,
      city: place,
      state: region_short?.toUpperCase(),
      country: country_short?.toUpperCase(),
    };
  }

  @Expose() place_id?: string;
  @Expose() route?: string;
  @Expose() street_number?: string;
  @Expose() street_address?: string;
  @Expose() zip?: string;
  @Expose() city?: string;
  @Expose() state?: string;
  @Expose() country?: string;
}

@Exclude()
export class DictionaryCarrierDTO {
  @Expose() name: string;
  @Expose() guid: string;
  @Expose() usdot_number: string | null | undefined;
  @Expose() phone: string | null | undefined;
  @Expose() email: string | null | undefined;
}

export function formatDictionary(type: DictionaryKey) {
  switch (type) {
    case 'statuses':
      return 'Status';
    case 'carriers':
      return 'Carrier';
    case 'customer_names':
      return 'Customer';
    case 'dispatcher_names':
      return 'Dispatcher';
    case 'salespersons':
      return 'Salesperson';
    case 'lot_numbers':
      return 'Lot Number';
    case 'pickup_addresses':
      return 'Pickup';
    case 'delivery_addresses':
      return 'Delivery';
    case 'delivered_on_date':
      return 'Delivered On';
    case 'contact_names':
      return 'Contact';
    case 'payment_statuses':
      return 'Payment Status';
    case 'payment_methods':
      return 'SuperPay';
    case 'tags':
      return 'Tags';
    case 'is_superpay_available_carrier':
      return 'SuperPay Carriers';
    case 'is_carrier_requested_superpay':
      return 'SuperPay Requested';
    case 'is_expedited_payment':
      return 'Expedited Payment';
  }
}

export function formatMultipleDictionaryValues(type: DictionaryKey) {
  switch (type) {
    case 'statuses':
      return 'statuses';
    case 'carriers':
      return 'carriers';
    case 'customer_names':
      return 'customers';
    case 'dispatcher_names':
      return 'dispatchers';
    case 'salespersons':
      return 'salespersons';
    case 'lot_numbers':
      return 'lot numbers';
    case 'pickup_addresses':
      return 'pickups';
    case 'delivery_addresses':
      return 'deliveries';
    case 'delivered_on_date':
      return 'delivered on';
    case 'contact_names':
      return 'contacts';
    case 'payment_statuses':
      return 'payment statuses';
    case 'payment_methods':
      return 'SuperPay';
    case 'tags':
      return 'tags';
    case 'is_superpay_available_carrier':
      return 'SuperPay Carriers';
    case 'is_carrier_requested_superpay':
      return 'SuperPay Requested';
    case 'is_expedited_payment':
      return 'Expedited Payment';
  }
}
