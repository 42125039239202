import {
  CircularProgress,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Box, Flex } from '@rebass/grid';
import {
  formatDate,
  FormattedDate,
  FormattedRelativeTime,
  parseDate,
} from '@superdispatch/dates';
import { Button, Color } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import {
  createInternalNote,
  fetchInternalNotes,
} from 'orders/data/OrdersAPILegacy';
import * as React from 'react';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Note } from 'shared/types/note';
import Order from 'shared/types/order';
import { Pagination } from 'shared/types/pagination';
import { Divider } from 'shared/ui/Divider';
import styled from 'styled-components';
import { OrderViewNote as NotesItem } from './OrderViewNote';

interface DetailedRelativeDateProps {
  date: string;
}

function DetailedRelativeDate({ date }: DetailedRelativeDateProps) {
  const today = DateTime.local();
  const yesterday = today.minus({ day: 1 });
  const parsedDate = parseDate(date, { format: 'JodaISO' });

  return (
    <Tooltip
      title={formatDate(date, { variant: 'DateTime' }, { format: 'JodaISO' })}
    >
      <span style={{ whiteSpace: 'nowrap' }}>
        {today.hasSame(parsedDate, 'day') ? (
          <FormattedRelativeTime date={parsedDate} format="JodaISO" />
        ) : yesterday.hasSame(parsedDate, 'day') ? (
          `Yesterday, ${formatDate(
            date,
            { variant: 'Time' },
            { format: 'JodaISO' },
          )}`
        ) : (
          <FormattedDate date={parsedDate} format="JodaISO" variant="Date" />
        )}
      </span>
    </Tooltip>
  );
}

const StyledTextField = styled(TextField)`
  textarea {
    border-color: ${Color.Silver400};
    resize: vertical;
    line-height: initial;

    &::-webkit-resizer {
      display: none;
      background: linear-gradient(
        135deg,
        transparent 0,
        transparent 14px,
        ${Color.Grey100} 15px,
        transparent 16px,
        transparent 17px,
        ${Color.Grey100} 18px,
        transparent 19px,
        transparent 100%
      );
    }
  }
`;

interface InternalNotesProps {
  order: Order;
}

export const OrderViewInternalNotes: React.ComponentType<InternalNotesProps> =
  ({ order }) => {
    const initialNote = '';
    const [notes, setNotes] = useState<Note[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMoreNotes, setHasMoreNotes] = useState(false);
    const [note, setNote] = useState<Note['note']>(initialNote);
    const [isFetchingNotes, setIsFetchingNotes] = useState(false);
    const [isCreatingNote, setIsCreatingNote] = useState(false);
    const formRef = useMemo(() => createRef<HTMLFormElement>(), []);
    const formElement = formRef.current;
    const buttonRef = useMemo(() => createRef<HTMLButtonElement>(), []);
    const buttonElement = buttonRef.current;
    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
      setNote(e.target.value);
    const handleNoteSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      setIsCreatingNote(true);
      createInternalNote(order.id, note)
        .then((newNote: Note) => setNotes([newNote, ...notes]))
        .finally(() => setIsCreatingNote(false));
      setNote(initialNote);
    };
    const fetchNotes = useCallback(
      (page: number) => {
        setIsFetchingNotes(true);
        fetchInternalNotes(order.id, { size: 100, page })
          .then(
            ({
              notes: fetchedNotes,
              pagination: { total_pages },
            }: {
              notes: Note[];
              pagination: Pagination;
            }) => {
              setCurrentPage(page);
              setHasMoreNotes(!!total_pages && page < total_pages - 1);
              setNotes((prev) => [...prev, ...fetchedNotes]);
            },
          )
          .finally(() => setIsFetchingNotes(false));
      },
      [setIsFetchingNotes, order.id],
    );

    const fetchMoreNotes = () => fetchNotes(currentPage + 1);

    useEffect(() => {
      fetchNotes(0);
    }, [fetchNotes]);

    useEffect(() => {
      const eventListener = (e: KeyboardEvent) => {
        if (buttonElement && e.keyCode === 13 && e.metaKey) {
          buttonElement.click();
        }
      };

      if (formElement) {
        formElement.addEventListener('keydown', eventListener);
      }

      return () => {
        if (formElement) {
          formElement.removeEventListener('keydown', eventListener);
        }
      };
    }, [formElement, buttonElement]);

    return (
      <>
        <form onSubmit={handleNoteSubmit} ref={formRef}>
          <Typography variant="h3">Internal Notes</Typography>

          <Flex mt={3} flexDirection="column">
            <Box>
              <StyledTextField
                minRows={3}
                value={note}
                multiline={true}
                fullWidth={true}
                onChange={handleNoteChange}
                inputProps={{ maxLength: 2000 }}
                label={
                  <TextBox color="secondary" align="right">{`${
                    note.length || 0
                  } of 2000`}</TextBox>
                }
              />
            </Box>

            <Box mt={2} alignSelf="flex-end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!note.trim()}
                isLoading={isCreatingNote}
                ref={buttonRef}
              >
                Add Note
              </Button>
            </Box>
          </Flex>
        </form>

        {notes.length > 0 && <Divider mt={3} />}

        <>
          {notes.map(
            ({
              id,
              user: { username },
              created_at: date,
              note: noteContent,
            }) => (
              <Flex key={id} mt={3} flexDirection="column">
                <Flex>
                  <Typography variant="body1" aria-label="internal note user">
                    {username}
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    {username && <>&nbsp;&nbsp; • &nbsp;&nbsp;</>}

                    {date && <DetailedRelativeDate date={date} />}
                  </Typography>
                </Flex>

                <NotesItem
                  mt={2}
                  lines={0}
                  aria-label="internal note text"
                  notes={noteContent}
                  bg={Color.Yellow75}
                />
              </Flex>
            ),
          )}

          {isFetchingNotes && (
            <Flex mt={3} justifyContent="center">
              <CircularProgress
                variant="indeterminate"
                color="primary"
                size={24}
              />
            </Flex>
          )}

          {hasMoreNotes && !isFetchingNotes && (
            <Flex mt={3} justifyContent="center">
              <Link component="button" variant="body2" onClick={fetchMoreNotes}>
                <Flex alignItems="center">
                  <ArrowDropDownIcon /> Show more
                </Flex>
              </Link>
            </Flex>
          )}
        </>
      </>
    );
  };
