import { Chip } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: block;
  border: none;
  outline: none;
  padding: 5px;
`;

interface LotNumberAutocompleteProps {
  value: string[] | undefined;
  onChange: (option: string[]) => void | undefined;
  onClose: () => void;
}

export function LotNumberAutocomplete({
  onChange,
  onClose,
  value = [],
}: LotNumberAutocompleteProps) {
  const [input, setInput] = useState('');
  const ref = useOnclickOutside(() => {
    onClose();
  });
  return (
    <div ref={ref}>
      <Stack>
        {value.length > 0 && (
          <Inline>
            {value.map((x, index) => (
              <Chip
                key={index}
                label={x}
                onDelete={() => {
                  onChange(value.filter((item) => item !== x));
                }}
              />
            ))}
          </Inline>
        )}

        <StyledInput
          value={input}
          autoFocus={true}
          placeholder="Add filter"
          onChange={({ target }) => setInput(target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && input) {
              const next = new Set(value.concat(input));

              setInput('');
              onChange(Array.from(next));
            }

            if (event.key === 'Backspace' && !input) {
              onChange(value.slice(0, -1));
            }
          }}
        />
      </Stack>
    </div>
  );
}
