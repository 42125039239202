export function getPasswordStrength(value: string) {
  const count = [
    has8OrMoreCharacters,
    hasNumber,
    hasLowerCaseAndUpperCase,
    hasSpecialCharacter,
    hasSeveralSpecialCharacters,
  ].reduce<number>((acc, check) => (check(value) ? (acc += 1) : acc), 0);

  if (count === 1 || count === 2) return 'weak';
  if (count === 3) return 'average';
  if (count >= 4) {
    return value.length > 11 ? 'strong' : 'good';
  }
  return undefined;
}

export function has8OrMoreCharacters(text: string) {
  return text.trim().length > 7;
}

export function hasNumber(text: string) {
  return /(?=.*[0-9])/.test(text);
}

export function hasLowerCaseAndUpperCase(text: string) {
  return /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(text);
}

export function hasSpecialCharacter(text: string) {
  return /[!@#$%^&*()_+\-={[}\]|\\;:'"<>?,.]/.test(text);
}

export function hasSeveralSpecialCharacters(text: string) {
  const regex = /[!@#$%^&*()_+\-={[}\]|\\;:'"<>?,.]/g;
  const charactersList = text.match(regex);
  return !!charactersList && charactersList.length > 1;
}

export type PasswordStrength = 'weak' | 'average' | 'good' | 'strong';
