import { Tag, TagProps } from '@superdispatch/ui';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';

function parseStatus(
  status: VehicleDTO['status'],
): [TagProps['color'], string] {
  switch (status) {
    case 'pending':
      return ['purple', 'Pending'];
    case 'declined':
      return ['red', 'Declined'];
    case 'accepted':
      return ['grey', 'Accepted'];
    case 'canceled':
      return ['blue', 'Offer Canceled'];
    case 'picked_up':
      return ['yellow', 'Picked Up'];
    case 'delivered':
      return ['green', 'Delivered'];
    case 'invoiced':
      return ['blue', 'Invoiced'];
    case 'paid':
      return ['blue', 'Paid'];
    case 'submitted':
      return ['blue', 'Submitted'];
    case 'scheduled':
      return ['grey', 'Scheduled'];
    case 'completed':
      return ['blue', 'Completed'];
    case 'order_canceled':
      return ['grey', 'Canceled'];
    default:
      return ['blue', 'New'];
  }
}

interface OrderVehicleStatusProps {
  status: VehicleDTO['status'];
}

export function OrderVehicleStatus({ status }: OrderVehicleStatusProps) {
  const [color, statusText] = parseStatus(status);

  return (
    <Tag aria-label="vehicle status" color={color} variant="subtle">
      {statusText}
    </Tag>
  );
}
