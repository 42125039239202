import { Avatar, IconButton } from '@material-ui/core';
import { AddRounded, Close, Edit } from '@material-ui/icons';
import { CardButton, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { SuperPayRepresentative } from 'shared/helpers/superpay/MoovHelpers';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { OwnerAddDrawerContent } from './OwnerAddDrawerContent';
import { OwnerDeleteConfirmationForm } from './OwnerDeleteConfirmationForm';
import { OwnerEditDrawerContent } from './OwnerEditDrawerContent';

interface OwnersListProps {
  owners: SuperPayRepresentative[];
  refetchOwnersList: () => void;
}

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export function OwnersList({ owners, refetchOwnersList }: OwnersListProps) {
  const [representative, setRepresentative] =
    useState<SuperPayRepresentative>();
  const [drawer, setDrawer] = useState<'edit' | 'add' | 'delete' | undefined>();

  const handleClose = () => {
    setRepresentative(undefined);
    setDrawer(undefined);
  };

  const handleSubmitSuccess = () => {
    handleClose();
    refetchOwnersList();
  };

  return (
    <Stack space="medium">
      <Stack>
        {owners.map((owner, index) => (
          <FlexBox key={owner.email}>
            <Box>
              <Inline space="small" verticalAlign="center">
                <Avatar>
                  {getInitials(
                    `${owner.name.firstName} ${owner.name.lastName}`,
                  )}
                </Avatar>
                {`${owner.name.firstName} ${owner.name.lastName}`}
              </Inline>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setRepresentative(owner);
                  setDrawer('edit');
                }}
                aria-label="edit-representative-data"
              >
                <Edit color="action" />
              </IconButton>
              {index !== 0 && (
                <IconButton
                  onClick={() => {
                    setRepresentative(owner);
                    setDrawer('delete');
                  }}
                >
                  <Close color="action" />
                </IconButton>
              )}
            </Box>
          </FlexBox>
        ))}
      </Stack>
      <CardButton
        size="small"
        startIcon={<AddRounded />}
        onClick={() => setDrawer('add')}
      >
        Add another owner, or a representative
      </CardButton>

      <OwnerDeleteConfirmationForm
        open={drawer === 'delete'}
        representative={representative}
        onClose={handleClose}
        onDeleteSuccess={handleSubmitSuccess}
      />

      <FormikDrawer
        open={drawer === 'add'}
        onClose={handleClose}
        confirmClose={false}
      >
        <OwnerAddDrawerContent
          onClose={handleClose}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </FormikDrawer>

      <FormikDrawer
        open={drawer === 'edit'}
        onClose={handleClose}
        confirmClose={false}
      >
        <OwnerEditDrawerContent
          initialValues={representative}
          onClose={handleClose}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </FormikDrawer>
    </Stack>
  );
}
