import { Link, Tooltip, Typography } from '@material-ui/core';
import {
  Business,
  Code,
  Email,
  Info,
  Language,
  Phone,
  Room,
} from '@material-ui/icons';
import { formatCustomerType } from '@superdispatch/sdk';
import { Inline, Stack } from '@superdispatch/ui';
import { DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { TextFieldCopy } from 'shared/form/TextFieldCopy';
import { BillingIcon } from 'shared/icons/BillingIcon';
import { FaxIcon } from 'shared/icons/FaxIcon';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import styled from 'styled-components';
import { CustomerDetailsCard } from './CustomerDetailsCard';

interface Props {
  onEdit: () => void;
  customer: CustomerDTO;
}

const StyledTypography = styled(Typography)`
  display: contents;
`;

export function CustomerDetailsInfo({ customer, onEdit }: Props) {
  const {
    name,
    city,
    state,
    zip,
    address,
    phone,
    fax,
    email,
    business_type,
    bill_the_delivery,
    personal_page_url,
    custom_external_id,
    use_broker_load_id,
  } = customer;

  return (
    <CustomerDetailsCard title="Customer Info" onEdit={onEdit}>
      <Stack space="small">
        <DescriptionItem icon={<Business fontSize="small" color="action" />}>
          <Stack space="xxsmall">
            <TextBox>{name}</TextBox>
            <TextBox color="secondary">
              {formatCustomerType(business_type)}
            </TextBox>
          </Stack>
        </DescriptionItem>

        <DescriptionItem
          fallback="No address"
          icon={<Room fontSize="small" color="action" />}
        >
          {makeAddressCityStateZip({ address, city, state, zip })}
        </DescriptionItem>

        <DescriptionItem
          fallback="No phone"
          icon={<Phone fontSize="small" color="action" />}
        >
          {phone && (
            <Link href={`tel:${phone}`} variant="body2">
              {phone}
            </Link>
          )}
        </DescriptionItem>

        <DescriptionItem
          fallback="No fax"
          icon={<FaxIcon fontSize="small" color="action" />}
        >
          {fax && (
            <Link href={`tel:${fax}`} variant="body2">
              {fax}
            </Link>
          )}
        </DescriptionItem>

        <DescriptionItem
          fallback="No email"
          icon={<Email fontSize="small" color="action" />}
        >
          {email && (
            <Link href={`mailto:${email}`} variant="body2">
              {email}
            </Link>
          )}
        </DescriptionItem>

        <DescriptionItem
          fallback="No website"
          icon={<Language fontSize="small" color="action" />}
        >
          {personal_page_url && (
            <Link
              href={personal_page_url}
              target="_blank"
              variant="body2"
              rel="noopener noreferrer"
            >
              {personal_page_url}
            </Link>
          )}
        </DescriptionItem>

        <DescriptionItem
          fallback="No custom external ID"
          icon={<Code fontSize="small" color="action" />}
        >
          {custom_external_id && (
            <TextFieldCopy
              fullWidth={true}
              value={custom_external_id}
              inputProps={{ 'aria-label': 'customer external id' }}
              label={<TextBox color="secondary">Custom External ID</TextBox>}
            />
          )}
        </DescriptionItem>

        {bill_the_delivery && (
          <DescriptionItem
            icon={<BillingIcon fontSize="small" color="action" />}
          >
            <Inline space="xsmall" verticalAlign="center">
              <Typography variant="body2" color="textSecondary">
                Delivery will be Billed
              </Typography>

              <Tooltip title="Bill the delivery location instead of the customer">
                <Info
                  style={{ display: 'block' }}
                  fontSize="small"
                  color="action"
                />
              </Tooltip>
            </Inline>
          </DescriptionItem>
        )}

        {use_broker_load_id && (
          <DescriptionItem
            icon={<HashtagIcon fontSize="small" color="action" />}
          >
            <StyledTypography variant="body2" color="textSecondary">
              <TextOverflow size="100%">
                {' '}
                Order ID auto generated in Customer Portal
              </TextOverflow>
            </StyledTypography>
          </DescriptionItem>
        )}
      </Stack>
    </CustomerDetailsCard>
  );
}
