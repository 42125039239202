import {
  CardCVV as CardCVVChargebee,
  CardExpiry as CardExpiryChargebee,
  CardNumber as CardNumberChargebee,
} from '@chargebee/chargebee-js-react-wrapper';
import {
  FormGroup,
  FormHelperText,
  InputLabel,
  Tooltip,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { TokenizeProps, useChargebee } from 'shared/helpers/ChargebeeWeb';
import { AmericanExpressIcon } from 'shared/icons/AmericanExpressIcon';
import { VisaCardIcon } from 'shared/icons/VisaCardIcon';
import styled, { css } from 'styled-components';

export interface CVVTitleProps {
  isEmpty?: boolean;
  cardType?: 'amex' | 'visa' | 'mastercard';
}

export function CVVTitle({ isEmpty, cardType }: CVVTitleProps) {
  if (isEmpty) {
    return (
      <Box maxWidth="250px">
        <Stack space="small">
          <Columns>
            <Column width="1/2">
              <Inline horizontalAlign="left">
                <VisaCardIcon />
              </Inline>
            </Column>
            <Column width="1/2">
              <Inline horizontalAlign="right">
                <AmericanExpressIcon />
              </Inline>
            </Column>
          </Columns>
          <TextBox color="white">
            For Visa, MasterCard and Discover the 3 digits on the back of the
            card.
          </TextBox>
          <TextBox color="white">
            For American Express, the 4 digits on the front of the card.
          </TextBox>
        </Stack>
      </Box>
    );
  }

  if (cardType === 'amex') {
    return (
      <Stack space="small">
        <Inline horizontalAlign="center">
          <AmericanExpressIcon width="100%" />
        </Inline>

        <TextBox color="white">4 digits on the front of the card.</TextBox>
      </Stack>
    );
  }

  return (
    <Stack space="small">
      <Inline horizontalAlign="center">
        <VisaCardIcon width="100%" />
      </Inline>
      <TextBox color="white">3 digits on the back of the card.</TextBox>
    </Stack>
  );
}

export function useCreditCard(client_token?: string) {
  const { addChargebeeCreditCard, isChargebeeAvailable, cardRef } =
    useChargebee(client_token);

  async function addCreditCard({ firstName, lastName }: TokenizeProps) {
    if (!client_token) {
      return Promise.reject(new Error('Unauthorized request'));
    }
    return addChargebeeCreditCard({ firstName, lastName });
  }

  return {
    addCreditCard,
    isChargebeeAvailable,
    cardRef,
  };
}

interface InputStyleProps {
  hasError: boolean;
  focused: boolean;
}

const baseStyles = {
  base: {
    '::placeholder': {
      color: Color.Dark100,
    },
  },
  invalid: {},
  empty: {},
};

const baseInputStyles = css<InputStyleProps>`
  border: 1px solid;
  border-radius: 4px;
  padding: 6px 8px;
  border-color: ${({ hasError, focused }) =>
    hasError ? Color.Red500 : focused ? Color.Blue500 : Color.Silver500};
  outline: ${({ hasError, focused }) =>
    hasError && focused
      ? `${Color.Red500} solid 1px`
      : focused
      ? `${Color.Blue500} solid 1px`
      : null};

  &:hover {
    border-color: ${({ hasError, focused }) =>
      hasError ? Color.Red500 : focused ? Color.Blue500 : Color.Dark100};
  }
`;

const CardNumber = styled(CardNumberChargebee)<InputStyleProps>`
  ${baseInputStyles}
`;

const CardCVV = styled(CardCVVChargebee)<InputStyleProps>`
  ${baseInputStyles}
`;

const CardExpiry = styled(CardExpiryChargebee)<InputStyleProps>`
  ${baseInputStyles}
`;

interface FormFieldProps {
  children: React.ReactNode;
  label: string;
  error: string;
  icon?: React.ReactNode;
}

interface FormInputProps extends CVVTitleProps {
  error: string;
  focused: boolean;
  label: string;
  onFocus: () => void;
  onBlur?: () => void;
  icon?: React.ReactNode;
}

const FormField = ({ children, error, label, icon }: FormFieldProps) => {
  return (
    <FormGroup>
      <InputLabel>
        <Inline verticalAlign="center">
          {label}
          {icon}
        </Inline>
      </InputLabel>

      {children}

      {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormGroup>
  );
};

export const FormCardNumber = ({
  error,
  focused,
  onFocus,
  onBlur,
  label,
}: FormInputProps) => {
  return (
    <FormField label={label} error={error}>
      <CardNumber
        styles={baseStyles}
        focused={focused}
        onFocus={onFocus}
        onBlur={onBlur}
        hasError={!!error}
      />
    </FormField>
  );
};

export const FormCardExpiry = ({
  error,
  focused,
  onFocus,
  onBlur,
  label,
}: FormInputProps) => {
  return (
    <FormField label={label} error={error}>
      <CardExpiry
        styles={baseStyles}
        focused={focused}
        onFocus={onFocus}
        onBlur={onBlur}
        hasError={!!error}
      />
    </FormField>
  );
};

export const FormCardCVV = ({
  error,
  focused,
  onFocus,
  onBlur,
  label,
  isEmpty,
  cardType,
}: FormInputProps) => {
  return (
    <FormField
      icon={
        <Tooltip
          interactive={true}
          placement="bottom-end"
          title={<CVVTitle isEmpty={isEmpty} cardType={cardType} />}
        >
          <Info fontSize="small" htmlColor={Color.Dark100} />
        </Tooltip>
      }
      label={label}
      error={error}
    >
      <CardCVV
        styles={baseStyles}
        focused={focused}
        onFocus={onFocus}
        onBlur={onBlur}
        hasError={!!error}
      />
    </FormField>
  );
};
