import { Stack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';

interface InformationErrorBannerProps {
  text: string | JSX.Element;
  buttonText?: string;
  onButtonClick?: () => void;
}

export function InformationErrorBanner({
  text,
  buttonText,
  onButtonClick,
}: InformationErrorBannerProps) {
  return (
    <Box aria-label="error message">
      <Alert severity="critical">
        <Stack>
          {text}
          {onButtonClick && (
            <Button
              size="small"
              variant="neutral"
              data-intercom-target="SuperPay Bank Verification Status Failed"
              onClick={onButtonClick}
            >
              {buttonText}
            </Button>
          )}
        </Stack>
      </Alert>
    </Box>
  );
}
