import { Tag } from '@superdispatch/ui';
import { Order } from 'shared/types/order';
import { PriceNegotiationDTO } from '../../../data/dto/PriceNegotiationDTO';
import { PriceNegotiationTooltip } from './PriceNegotiationTooltip';

interface PriceNegotiationStatusProps {
  order: Order;
  priceNegotiation: PriceNegotiationDTO;
}

export function PriceNegotiationStatus({
  order,
  priceNegotiation,
}: PriceNegotiationStatusProps) {
  return (
    <PriceNegotiationTooltip order={order} priceNegotiation={priceNegotiation}>
      <Tag color="blue" variant="subtle">
        Price Negotiation
      </Tag>
    </PriceNegotiationTooltip>
  );
}
