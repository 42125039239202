import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Inline } from '@superdispatch/ui';
import { Price } from 'core/Price';

export function GrossProfit({
  tariff,
  price,
  useBrackets,
}: {
  tariff: number;
  price: number;
  useBrackets?: boolean;
}) {
  const grossProfit = tariff - price;
  const grossProfitPercentage = !tariff ? 0 : (grossProfit / tariff) * 100;
  return useBrackets ? (
    <Inline space="xxsmall" verticalAlign="center">
      <Price value={grossProfit} />
      <Typography color="textSecondary">
        {`(${grossProfitPercentage.toFixed()}%)`}
      </Typography>
    </Inline>
  ) : (
    <Box display="flex" flexDirection="column">
      <Price value={grossProfit} />
      <Typography variant="caption" color="textSecondary">
        {`${grossProfitPercentage.toFixed()}%`}
      </Typography>
    </Box>
  );
}
