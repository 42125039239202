import { CircularProgress, Link, Tooltip, Typography } from '@material-ui/core';
import { AttachMoney, Person, Phone, PictureAsPdf } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import {
  Color,
  Column,
  Columns,
  InfoCard,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { CheckIconRounded } from 'shared/icons/CheckIconRounded';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { InfoIconRounded } from 'shared/icons/InfoIconRounded';
import { WarningIconRounded } from 'shared/icons/WarningIconRounded';
import { CarrierFullInfo, isCarrierHasValidUSDOT } from 'shared/types/carrier';
import styled from 'styled-components';
import { CarrierDocumentation } from '../core/CarrierDocumentation';
import { useAuthority, useCarrierProfileAPI } from '../data/ManageCarriersAPI';

interface CarrierAssessmentProps {
  carrier: CarrierFullInfo;
}

const Divider = styled.div`
  border-top: 1px solid ${Color.Silver400};
`;

const SpinnedContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;

const ResponsivePaddedItem = styled.div`
  margin-left: 32px;

  @media (min-width: 600px) {
    margin-left: 24px;
  }

  @media (min-width: 960px) {
    margin-left: 0;
  }
`;

const PaddedItem = styled.span`
  margin-right: 8px;
`;

const VerifiedDocumentIcon = styled(CheckIconRounded)`
  height: 20px;
  width: 20px;
`;

const UnverifiedDocumentIcon = styled(WarningIconRounded)`
  height: 20px;
  width: 20px;
`;

const InfoDocumentIcon = styled(InfoIconRounded)`
  height: 20px;
  width: 20px;
`;

export function CarrierAssessment({ carrier }: CarrierAssessmentProps) {
  const { data: authority, refetch } = useAuthority(carrier.us_dot);
  const { parseAndFetchAuthority } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();
  const isCarrierInternal = carrier.carrier_type === 'INTERNAL';
  const hasCargoInformation = useMemo(
    () =>
      Boolean(
        carrier.cargo_insurance ||
          (carrier.cargo_policy &&
            Object.values(carrier.cargo_policy).filter(Boolean).length),
      ),
    [carrier.cargo_insurance, carrier.cargo_policy],
  );

  const {
    handleSubmit: updateAuthorityInfo,
    isSubmitting: isUpdatingAuthorityInfo,
  } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      if (!carrier.us_dot) {
        return Promise.reject(new Error('No USDOT was found'));
      }

      return parseAndFetchAuthority(carrier.us_dot);
    },
    onSubmitSuccess: () => {
      void refetch();
    },
    onSubmitFailure: () =>
      addSnackbar('Failed to updated FMCSA status', { variant: 'error' }),
  });

  return (
    <InfoCard size="large">
      <Stack space="medium">
        <Stack space="small">
          <Typography variant="h3">Assessment</Typography>

          <Stack space="xsmall">
            {isCarrierHasValidUSDOT(carrier) ? (
              <Columns space="xsmall" align="center">
                <Column width="content">
                  {authority?.operating_status === 'ACTIVE' ||
                  authority?.operating_status?.startsWith('AUTHORIZED FOR') ? (
                    <VerifiedDocumentIcon />
                  ) : (
                    <UnverifiedDocumentIcon />
                  )}
                </Column>
                <Column>
                  <Typography aria-label="carrier authority">
                    Authority: {authority?.operating_status || 'No status'}
                  </Typography>
                </Column>
              </Columns>
            ) : (
              <Columns space="xsmall" align="center">
                <Column width="content">
                  <InfoDocumentIcon />
                </Column>
                <Column>
                  <Tooltip title="This carrier’s state does not require them to own a USDOT number.">
                    <Link aria-label="carrier authority">No USDOT</Link>
                  </Tooltip>
                </Column>
              </Columns>
            )}

            <CarrierDocumentation carrier={carrier} />
          </Stack>

          {!isCarrierInternal && <Divider />}
        </Stack>

        {!isCarrierInternal && (
          <Stack space="medium">
            {isCarrierHasValidUSDOT(carrier) && (
              <Stack space="small">
                <Typography variant="h4">Authority</Typography>

                <Stack space={['xxsmall', 'xsmall']}>
                  {isUpdatingAuthorityInfo ? (
                    <SpinnedContainer aria-label="carrier authority loader">
                      <CircularProgress size={24} />
                    </SpinnedContainer>
                  ) : (
                    <>
                      <Columns collapseBelow="desktop">
                        <Column>
                          <Typography color="textSecondary">Status</Typography>
                        </Column>
                        <Column>
                          <Typography
                            aria-label="carrier authority status"
                            color="textPrimary"
                          >
                            {authority?.operating_status || 'No status'}
                          </Typography>
                        </Column>
                      </Columns>

                      <Columns collapseBelow="desktop">
                        <Column>
                          <Typography color="textSecondary">
                            Last checked
                          </Typography>
                        </Column>
                        <Column>
                          <Typography
                            component="span"
                            aria-label="carrier authority last checked"
                          >
                            <PaddedItem>
                              {authority?.updated_from_safer_date ? (
                                <FormattedDate
                                  variant="Date"
                                  format="DateISO"
                                  date={authority.updated_from_safer_date}
                                />
                              ) : (
                                'No data'
                              )}{' '}
                            </PaddedItem>
                            <Link
                              component="button"
                              onClick={() => updateAuthorityInfo()}
                            >
                              Update
                            </Link>
                          </Typography>
                        </Column>
                      </Columns>
                    </>
                  )}

                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist?n_dotno=${
                      carrier.us_dot || ''
                    }`}
                    onClick={() =>
                      trackEvent('[STMS] Clicked View Carrier Profile on FMCSA')
                    }
                  >
                    View on FMCSA
                  </Link>
                </Stack>
              </Stack>
            )}
            <Stack space="small">
              <Typography variant="h4">Cargo Insurance</Typography>

              <Stack space="small">
                {hasCargoInformation ? (
                  <>
                    {carrier.cargo_insurance && (
                      <DescriptionItem icon={<PictureAsPdf />}>
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="insurance certificate"
                          href={carrier.cargo_insurance}
                          onClick={() =>
                            trackEvent(
                              '[STMS] Clicked Carrier Insurance Certificate',
                            )
                          }
                        >
                          Insurance Certificate
                        </Link>
                      </DescriptionItem>
                    )}

                    <Stack space={['xxsmall', 'xsmall']}>
                      {carrier.cargo_policy?.policy_expiration_date && (
                        <Columns collapseBelow="desktop">
                          <Column>
                            <DescriptionItem icon={<CalendarIcon />}>
                              <Typography
                                color="textSecondary"
                                component="span"
                              >
                                Expiration Date
                              </Typography>
                            </DescriptionItem>
                          </Column>
                          <Column>
                            <ResponsivePaddedItem aria-label="Expiration Date">
                              <DescriptionItem>
                                <FormattedDate
                                  variant="Date"
                                  format="DateISO"
                                  date={
                                    carrier.cargo_policy.policy_expiration_date
                                  }
                                />
                              </DescriptionItem>
                            </ResponsivePaddedItem>
                          </Column>
                        </Columns>
                      )}

                      {carrier.cargo_policy?.limit != null && (
                        <Columns collapseBelow="desktop">
                          <Column>
                            <DescriptionItem icon={<AttachMoney />}>
                              <Typography
                                color="textSecondary"
                                component="span"
                              >
                                Cargo Limit
                              </Typography>
                            </DescriptionItem>
                          </Column>
                          <Column>
                            <ResponsivePaddedItem aria-label="Cargo Limit">
                              <DescriptionItem>
                                {formatCurrency(
                                  Number(carrier.cargo_policy.limit),
                                  {
                                    minimumFractionDigits: 0,
                                  },
                                )}
                              </DescriptionItem>
                            </ResponsivePaddedItem>
                          </Column>
                        </Columns>
                      )}

                      {carrier.cargo_policy?.deductible != null && (
                        <Columns collapseBelow="desktop">
                          <Column>
                            <DescriptionItem icon={<AttachMoney />}>
                              <Typography
                                color="textSecondary"
                                component="span"
                              >
                                Deductible
                              </Typography>
                            </DescriptionItem>
                          </Column>
                          <Column>
                            <ResponsivePaddedItem aria-label="Deductible">
                              <DescriptionItem>
                                {formatCurrency(
                                  Number(carrier.cargo_policy.deductible),
                                  {
                                    minimumFractionDigits: 0,
                                  },
                                )}
                              </DescriptionItem>
                            </ResponsivePaddedItem>
                          </Column>
                        </Columns>
                      )}

                      {carrier.cargo_policy?.auto_policy_number && (
                        <Columns collapseBelow="desktop">
                          <Column>
                            <DescriptionItem icon={<HashtagIcon />}>
                              <Typography
                                color="textSecondary"
                                component="span"
                              >
                                Auto Policy
                              </Typography>
                            </DescriptionItem>
                          </Column>
                          <Column>
                            <ResponsivePaddedItem>
                              <DescriptionItem>
                                {carrier.cargo_policy.auto_policy_number}
                              </DescriptionItem>
                            </ResponsivePaddedItem>
                          </Column>
                        </Columns>
                      )}

                      {carrier.cargo_policy?.cargo_policy_number && (
                        <Columns collapseBelow="desktop">
                          <Column>
                            <DescriptionItem icon={<HashtagIcon />}>
                              <Typography
                                color="textSecondary"
                                component="span"
                              >
                                Cargo Policy
                              </Typography>
                            </DescriptionItem>
                          </Column>
                          <Column>
                            <ResponsivePaddedItem>
                              <DescriptionItem>
                                {carrier.cargo_policy.cargo_policy_number}
                              </DescriptionItem>
                            </ResponsivePaddedItem>
                          </Column>
                        </Columns>
                      )}
                    </Stack>

                    {!!carrier.cargo_policy?.agent_name &&
                      !!carrier.cargo_policy.agent_phone && (
                        <Stack space="xsmall">
                          <Typography variant="h6" color="textSecondary">
                            Agent
                          </Typography>

                          <Stack space="xxsmall">
                            {carrier.cargo_policy.agent_name && (
                              <DescriptionItem icon={<Person />}>
                                <Typography aria-label="cargo agent name">
                                  {carrier.cargo_policy.agent_name}
                                </Typography>
                              </DescriptionItem>
                            )}
                            {carrier.cargo_policy.agent_phone && (
                              <DescriptionItem icon={<Phone />}>
                                <SuspendedPhoneLink
                                  aria-label="cargo agent phone"
                                  phone={carrier.cargo_policy.agent_phone}
                                  fallback={carrier.cargo_policy.agent_phone}
                                />
                              </DescriptionItem>
                            )}
                          </Stack>
                        </Stack>
                      )}
                  </>
                ) : (
                  <Typography color="textSecondary">
                    No Information Provided
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </InfoCard>
  );
}
