import { InputAdornment } from '@material-ui/core';
import {
  FormikNumberField,
  FormikNumberFieldProps,
} from '@superdispatch/forms';

export type FormikCurrencyFieldProps = FormikNumberFieldProps;

export function FormikCurrencyField({
  InputProps: {
    startAdornment = <InputAdornment position="start">$</InputAdornment>,
    ...InputProps
  } = {},
  ...rest
}: FormikCurrencyFieldProps) {
  return (
    <FormikNumberField
      {...rest}
      InputProps={{
        ...InputProps,
        startAdornment,
      }}
    />
  );
}
