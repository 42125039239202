const EXTENSION_PACKAGE_ID = 'npciikilfcggljgdkebakdcccmkdmpik';

const checkExtension = (
  id: string,
  src: string,
  callback: (isExisted: boolean) => void,
) => {
  let image = new Image();
  image.src = `chrome-extension://${id}/${src}`;
  image.onload = () => {
    callback(true);
  };
  image.onerror = () => {
    callback(false);
  };
};

export function hasSuperDispatchExtension() {
  return new Promise((resolve) => {
    checkExtension(EXTENSION_PACKAGE_ID, 'icons/logo.png', resolve);
  });
}
