import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Box } from '@superdispatch/ui-lab';
import { usePrivateNetworkGroups } from 'manage-carriers/data/PrivateNetworkAPI';
import { MouseEvent, useState } from 'react';
import { trackEvent } from '../../../shared/helpers/AnalyticsHelpers';

export function PostToPrivateNetworkMenuItems({
  onSelect,
}: {
  onSelect: (event: MouseEvent, groupGuid?: string) => void;
}) {
  const { data: privateNetwork } = usePrivateNetworkGroups();

  if (!privateNetwork?.all_private_network_carrier_count) {
    return null;
  }

  return (
    <div>
      <MenuItem button={false}>
        <Typography variant="body1" color="textSecondary">
          Private Loadboard
        </Typography>
      </MenuItem>

      <MenuItem
        onClick={(event) => {
          onSelect(event, undefined);
          trackEvent('Posted to Private Network', {
            posted_for: 'All Private Network',
          });
        }}
      >
        All Private Network
      </MenuItem>

      {privateNetwork.groups.map((group) =>
        group.carrier_count ? (
          <MenuItem
            key={group.guid}
            onClick={(event) => {
              onSelect(event, group.guid);
              trackEvent('Posted to Private Network', {
                posted_for: group.name,
                group_guid: group.guid,
              });
            }}
          >
            <Box paddingLeft="xsmall">{group.name}</Box>
          </MenuItem>
        ) : (
          <Tooltip
            key={group.guid}
            title="To post, first add carriers to the group."
          >
            <MenuItem button={false} disabled={true}>
              <Box paddingLeft="xsmall">{group.name}</Box>
            </MenuItem>
          </Tooltip>
        ),
      )}
    </div>
  );
}

export function PostToPrivateNetworkMenuItem({
  onSelect,
  label,
}: {
  label: string;
  onSelect: (event: MouseEvent, groupGuid?: string) => void;
}) {
  const [anchor, setAnchor] = useState<Element>();
  const { data: privateNetwork } = usePrivateNetworkGroups();

  if (!privateNetwork?.all_private_network_carrier_count) {
    return null;
  }

  return (
    <div>
      <MenuItem
        data-intercom-target="Post to Private Network Button"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setAnchor(event.currentTarget);
        }}
      >
        {label} <KeyboardArrowRight fontSize="small" color="action" />
      </MenuItem>

      <Menu
        open={!!anchor}
        anchorEl={anchor}
        onClick={() => setAnchor(undefined)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
      >
        <PostToPrivateNetworkMenuItems onSelect={onSelect} />
      </Menu>
    </div>
  );
}
