import { get } from 'lodash-es';
import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { CurrentUser } from 'shared/types/user';
import { ShipperProfileDTO } from 'shipper-profile/data/ShipperProfileDTO';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';
import {
  OrderActionSource,
  trackOderActionEvent,
} from './OrderActionsAnalytics';

export type OrderInstantAction = typeof ORDER_INSTANTACTIONS[number];
const ORDER_INSTANTACTIONS = [
  'post_to_cd',
  'post_to_sdlb',
  'post_to_all',
  'post_to_private_loadboard',
  'unpost_from_cd',
  'unpost_from_sdlb',
  'unpost_from_all',
  'unpost_from_private_loadboard',
  'restore_order',
  'submit_to_broker',
  'mark_as_pending',
  'mark_as_on_hold',
  'mark_as_accepted',
  'mark_as_delivery_verification',
  'rollback_manual_status',
  'unmark_as_delivery_verification',
  'unmark_as_carrier_invoiced',
  'unmark_as_paid',
  'archive',
  'unarchive',
  'mark_as_new',
  'duplicate',
  'flag',
  'unflag',
  'resume_payment',
  'initiate_payment_now',
] as const;

export function getInstantActionConfig(
  type: OrderInstantAction,
  source: OrderActionSource,
  user: CurrentUser | undefined,
  shipper?: ShipperProfileDTO | undefined,
  args?: Record<string, unknown>,
): {
  message: string;
  submit: (
    api: ReturnType<typeof useSingleOrderActionAPI>,
    order: Order,
  ) => Promise<Order | undefined>;
} {
  switch (type) {
    case 'post_to_cd':
      return {
        message: 'Posted to Central Dispatch',
        submit: (api, order) =>
          api.postToLoadboards(order.guid, ['cd']).then(() => {
            trackOderActionEvent({
              name: '[STMS] Posted to CD',
              order,
              source,
              user,
            });

            return undefined;
          }),
      };
    case 'post_to_sdlb':
      return {
        message: 'Posted to Super Loadboard',
        submit: (api, order) =>
          api.postToLoadboards(order.guid, ['sdlb']).then(() => {
            trackOderActionEvent({
              name: '[STMS] Posted to SLB',
              order,
              source,
              user,
            });

            return undefined;
          }),
      };
    case 'post_to_private_loadboard':
      return {
        message: 'Posted to Private Loadboard',
        submit: (api, order) => {
          const groupGuid = get(args, 'groupGuid') as string | undefined;
          return api.postToPrivateNetwork(order.guid, groupGuid).then(() => {
            trackOderActionEvent({
              name: '[STMS] Posted to Private Loadboard',
              order,
              source,
              shipper,
              user,
            });

            return undefined;
          });
        },
      };
    case 'post_to_all':
      return {
        message: 'Posted to All Loadboards',
        submit: (api, order) =>
          api.postToLoadboards(order.guid, ['sdlb', 'cd']).then(() => {
            trackOderActionEvent({
              name: '[STMS] Posted to SLB',
              order,
              source,
              user,
            });
            trackOderActionEvent({
              name: '[STMS] Posted to CD',
              order,
              source,
              user,
            });

            return undefined;
          }),
      };
    case 'unpost_from_cd':
      return {
        message: 'Unposted from Central Dispatch. It may take few minutes',
        submit: (api, order) =>
          api.unpostFromLoadboards(order.guid, ['cd']).then(() => undefined),
      };
    case 'unpost_from_sdlb':
      return {
        message: 'Unposted from Super Loadboard',
        submit: (api, order) =>
          api.unpostFromLoadboards(order.guid, ['sdlb']).then(() => undefined),
      };
    case 'unpost_from_private_loadboard':
      return {
        message: 'Unposted from Private Loadboard',
        submit: (api, order) =>
          api.unpostFromLoadboards(order.guid, ['sdlb']).then(() => undefined),
      };
    case 'unpost_from_all':
      return {
        message: 'Unposted from All Load Boards. It may take few minutes',
        submit: (api, order) =>
          api
            .unpostFromLoadboards(order.guid, ['sdlb', 'cd'])
            .then(() => undefined),
      };
    case 'restore_order':
      return {
        message: 'Order restored',
        submit: (api, order) => api.restoreOrder(order.id),
      };
    case 'mark_as_on_hold':
      return {
        message: 'Order marked as on hold',
        submit: (api, order) =>
          api.markAsOnHold(order.id).then((response) => {
            trackEventLegacy('Mark as On Hold');
            return response;
          }),
      };
    case 'submit_to_broker':
      return {
        message: 'Order is submitted successfully.',
        submit: (api, order) =>
          api.submitToBroker(order.id).then((response) => {
            trackEventLegacy('Submitted Order');
            return response;
          }),
      };
    case 'mark_as_pending':
      return {
        message: 'Status changed successfully',
        submit: (api, order) =>
          api.markAsPending(order.id).then((response) => {
            trackEventLegacy('Marked as Pending', { order });
            return response;
          }),
      };
    case 'mark_as_accepted':
      return {
        message: 'Order marked as accepted',
        submit: (api, order) =>
          api.markAsAccepted(order.id).then((response) => {
            trackEventLegacy('Marked as Accepted', { order });
            return response;
          }),
      };
    case 'rollback_manual_status':
      return {
        message: 'Status changed successfully',
        submit: (api, order) => api.rollbackManualStatusChange(order.id),
      };
    case 'unmark_as_paid':
      return {
        message: 'Order unmarked as carrier paid',
        submit: (api, order) => api.unmarkAsPaid(order.id),
      };
    case 'unmark_as_carrier_invoiced':
      return {
        message: 'Order unmarked as carrier invoiced',
        submit: (api, order) => api.unmarkAsCarrierInvoiced(order.id),
      };
    case 'flag':
      return {
        message: 'Flagged',
        submit: (api, order) =>
          api.flagOrder(order.id).then(() => ({ ...order, is_flagged: true })),
      };
    case 'unflag':
      return {
        message: 'Unflagged',
        submit: (api, order) =>
          api
            .unflagOrder(order.id)
            .then(() => ({ ...order, is_flagged: false })),
      };
    case 'duplicate':
      return {
        message: 'Order duplicated',
        submit: (api, order) => {
          trackEventLegacy('Order duplicated');
          return api.duplicateOrder(order.guid);
        },
      };
    case 'archive':
      return {
        message: 'Archived',
        submit: (api, order) =>
          api.archiveOrder(order.id).then(() => {
            trackEventLegacy('Archived Order', {
              guid: order.guid,
              is_demo: order.is_demo,
            });
            return { ...order, is_archived: true };
          }),
      };
    case 'unarchive':
      return {
        message: 'Unarchived',
        submit: (api, order) =>
          api
            .unarchiveOrder(order.id)
            .then(() => ({ ...order, is_archived: false })),
      };
    case 'mark_as_new':
      return {
        message: 'Status changed successfully',
        submit: (api, order) => api.markAsNew(order.id),
      };
    case 'resume_payment':
      return {
        message: 'Status changed successfully',
        submit: (api, order) =>
          api.resumePayment(order.id).then((value) => {
            trackEvent('Shipper Resumed Payment');
            return value;
          }),
      };
    case 'initiate_payment_now':
      return {
        message: 'Status changed successfully',
        submit: (api, order) =>
          api.initiatePaymentNow(order.id).then((value) => {
            trackEvent('Initiated Payment Now');
            return value;
          }),
      };
    case 'mark_as_delivery_verification':
      return {
        message: 'Status changed successfully',
        submit: (api, order) =>
          api.markAsDeliveryVerification(order.id).then(() => {
            trackOderActionEvent({
              name: 'Shipper Marked as Delivery Verified',
              source,
              order,
            });
            return order;
          }),
      };
    case 'unmark_as_delivery_verification':
      return {
        message: 'Status changed successfully',
        submit: (api, order) => api.unmarkAsDeliveryVerification(order.id),
      };
  }
}

// TODO use Map
export function isInstantAction(type: string): type is OrderInstantAction {
  return ORDER_INSTANTACTIONS.includes(type as OrderInstantAction);
}
