import { MenuItem, TextField } from '@material-ui/core';
import { setSearchType, useSearchType } from './OrderListSearchType';

interface OrderListSearchTypeFieldProps {
  fullWidth?: boolean;
}

export function OrderListSearchTypeField({
  fullWidth,
}: OrderListSearchTypeFieldProps) {
  const searchType = useSearchType();

  return (
    <TextField
      select={true}
      variant="outlined"
      fullWidth={true}
      value={searchType}
      style={{ width: fullWidth ? '100%' : 130 }}
      onChange={(event) => {
        setSearchType(event.target.value);
      }}
    >
      <MenuItem value="number">Order ID</MenuItem>
      <MenuItem value="purchaseOrderNumber">PO number</MenuItem>
      <MenuItem value="pickup.venue.city">Pickup City</MenuItem>
      <MenuItem value="delivery.venue.city">Delivery City</MenuItem>
      <MenuItem value="vin">VIN</MenuItem>
      <MenuItem value="make">Make</MenuItem>
      <MenuItem value="model">Model</MenuItem>
      <MenuItem value="lotNumber">Lot number</MenuItem>
      <MenuItem value="all">All</MenuItem>
    </TextField>
  );
}
