import {
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { FormikCheckboxField } from '@superdispatch/forms';
import { CheckboxField, Inline, Stack, Tag } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useField, useFormikContext } from 'formik';
import { usePrivateNetworkGroups } from 'manage-carriers/data/PrivateNetworkAPI';
import { formatGroupNames } from 'manage-carriers/data/PrivateNetworkDTO';
import { useEffect, useMemo, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Protect from 'shared/data/UserPermissions';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArray';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import styled from 'styled-components';
import { PrivateNetworkDialog } from '../list/PrivateNetworkDialog';

const StyledTextField = styled(TextField)`
  width: 240px;
`;

interface PrivateNetworkCheckboxFieldProps {
  source: string;
  onRequestAccess: () => void;
}

export function PrivateNetworkCheckboxField({
  source,
  onRequestAccess,
}: PrivateNetworkCheckboxFieldProps) {
  const { user } = useUserState();
  const [open, setOpen] = useState(false);
  const { data } = usePrivateNetworkGroups();
  const { setFieldValue } = useFormikContext();
  const [{ value: isInPrivateNetwork }] = useField<boolean>(
    'is_in_private_network',
  );
  const [{ value: privateNetworkGroupGuids }] = useField<string[]>(
    'private_network_group_guids',
  );
  const privateNetworkGroupNames = useMemo(() => {
    return formatGroupNames(privateNetworkGroupGuids, data?.groups).join(', ');
  }, [privateNetworkGroupGuids, data?.groups]);

  useEffect(() => {
    if (!isInPrivateNetwork) {
      setFieldValue('private_network_group_guids', []);
    }
  }, [isInPrivateNetwork, setFieldValue]);

  if (!user?.shipper.is_private_network_enabled) {
    return (
      <CheckboxField
        disabled={true}
        label={
          <Inline verticalAlign="center" space="xxsmall">
            <Typography>Private Network</Typography>
            <Tag color="blue" variant="subtle">
              New
            </Tag>
          </Inline>
        }
        helperText={
          <Stack>
            <Typography>
              Offer your loads to select carriers first by posting to{' '}
              <b>Private Loadboard</b>, enhancing efficiency and reliability.{' '}
              <Link
                color="primary"
                rel="noreferrer"
                target="_blank"
                href="https://support.superdispatch.com/en/articles/9066625-private-network"
              >
                Learn more
              </Link>
            </Typography>

            <Button
              onClick={() => {
                onRequestAccess();
                trackEvent('Shipper Clicked Request Private Loadboard Access', {
                  utm_medium: source,
                });
              }}
              variant="neutral"
              data-intercom-target="request private loadboard access"
            >
              Request Access
            </Button>
          </Stack>
        }
      />
    );
  }

  return (
    <FormikCheckboxField
      name="is_in_private_network"
      label={
        <Inline verticalAlign="center" space="xxsmall">
          <Typography>Private Network</Typography>
          <Tag color="blue" variant="subtle">
            New
          </Tag>
        </Inline>
      }
      helperText={
        <Stack>
          <Typography>
            Carrier has access to loads posted exclusively on your Private
            Loadboard.{' '}
            <Link
              color="primary"
              rel="noreferrer"
              target="_blank"
              href="https://support.superdispatch.com/en/articles/9066625-private-network"
            >
              Learn more
            </Link>
          </Typography>

          <StyledTextField
            select={true}
            fullWidth={true}
            disabled={!isInPrivateNetwork}
            value={privateNetworkGroupNames}
            SelectProps={{
              renderValue: () => privateNetworkGroupNames,
            }}
            label={
              <Inline space="xxsmall">
                <Typography>Group</Typography>
                <Typography color="textSecondary">(Optional)</Typography>
              </Inline>
            }
          >
            <FormikCheckboxArrayGroup name="private_network_group_guids">
              {data?.groups.map(({ guid, name }) => (
                <MenuItem key={guid} component="label">
                  <FormikCheckboxArrayField name={guid} label={name} />
                </MenuItem>
              ))}

              <Protect
                permission="canExecute"
                entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
              >
                {!!data && data.groups.length >= 10 ? (
                  <Tooltip title="Max 10 carrier groups allowed.">
                    <span>
                      <MenuItem disabled={true}>
                        <Inline verticalAlign="center">
                          <Add color="disabled" />

                          <Typography color="textSecondary">
                            Create Group
                          </Typography>
                        </Inline>
                      </MenuItem>
                    </span>
                  </Tooltip>
                ) : (
                  <MenuItem onClick={() => setOpen(true)}>
                    <Inline verticalAlign="center">
                      <Add color="action" />

                      <Typography>Create Group</Typography>
                    </Inline>
                  </MenuItem>
                )}
              </Protect>
            </FormikCheckboxArrayGroup>
          </StyledTextField>

          <PrivateNetworkDialog
            source={source}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          />
        </Stack>
      }
    />
  );
}
