import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useSingleOrderActionAPI } from '../../data/OrderActionAPI';

interface CancelOrderDialogProps {
  order?: Order;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export function CancelOrderDialog({
  order,
  onCancel,
  onSubmitSuccess,
}: CancelOrderDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { cancelOrder } = useSingleOrderActionAPI();

  const { isSubmitting, handleSubmit } = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      if (!order) {
        return Promise.reject(new Error('No order found'));
      }

      return cancelOrder(order.id);
    },
    onSubmitSuccess() {
      addSnackbar('Order canceled successfully', { variant: 'success' });
      trackEventLegacy('Canceled Order');
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message || 'Order cancel failed', { variant: 'error' });
    },
  });

  return (
    <ConfirmDialog
      open={!!order}
      onClose={isSubmitting ? undefined : onCancel}
      title="Cancel this order?"
      cancelButtonProps={{ children: 'No', disabled: isSubmitting }}
      confirmButtonProps={{
        children: 'Yes, Cancel',
        isLoading: isSubmitting,
        onClick: () => handleSubmit(),
      }}
    />
  );
}
