import { Tab, Tabs } from '@material-ui/core';
import {
  Column,
  Columns,
  InfoCard,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

interface CarrierProfileLayoutProps {
  isCarrierInternal: boolean;
  header?: ReactNode;
  billingInfo?: ReactNode;
  companyInfo?: ReactNode;
  contactInfo?: ReactNode;
  termsAndConditions?: ReactNode;
  dispatched?: ReactNode;
  internalRecords?: ReactNode;
  internalNotes?: ReactNode;
  actions?: ReactNode;
  assessment?: ReactNode;
  ratings: ReactNode;

  activeTab: string;
  onTabChange: (tab: string) => void;
}

export function CarrierProfileLayout({
  isCarrierInternal,
  header,
  billingInfo,
  companyInfo,
  contactInfo,
  termsAndConditions,
  dispatched,
  internalRecords,
  internalNotes,
  actions,
  assessment,
  ratings,

  activeTab,
  onTabChange,
}: CarrierProfileLayoutProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');

  const headerAndTabs = (
    <InfoCard size="large" aria-label="carrier profile header">
      <Stack space="small">
        {header}

        <Box margin={['-small', '-medium']} marginTop="none">
          <Tabs
            value={activeTab}
            onChange={(_, value: typeof activeTab) => {
              onTabChange(value);
            }}
          >
            <Tab value="overview" label="Overview" />
            <Tab value="ratings" label="Ratings" />
          </Tabs>
        </Box>
      </Stack>
    </InfoCard>
  );

  if (platform === 'tablet') {
    return (
      <Stack space="small">
        {headerAndTabs}

        <Columns space="small" collapseBelow="tablet">
          <Column>
            {activeTab === 'ratings' ? (
              ratings
            ) : (
              <Stack space="small">
                {companyInfo}
                {contactInfo}
                {billingInfo}
                {assessment}
                {!isCarrierInternal && termsAndConditions}
              </Stack>
            )}
          </Column>

          {activeTab === 'overview' && (
            <Column>
              <Stack space="small">
                {dispatched}
                {internalRecords}
                {internalNotes}
                {actions}
              </Stack>
            </Column>
          )}
        </Columns>
      </Stack>
    );
  }

  if (isCarrierInternal) {
    return (
      <Columns space="small" collapseBelow="tablet">
        <Column width={['fluid', '1/2', '2/3']}>
          <Stack space="small">
            <InfoCard size="large" aria-label="carrier profile header">
              {header}
            </InfoCard>

            <Columns space="small" collapseBelow="desktop">
              <Column>{companyInfo}</Column>
              <Column>{contactInfo}</Column>
            </Columns>
          </Stack>
        </Column>

        <Column width={['fluid', '1/2', '1/3']}>
          <Stack space="small">
            {dispatched}
            {internalRecords}
            {internalNotes}
            {actions}
          </Stack>
        </Column>
      </Columns>
    );
  }

  return (
    <Columns space="small" collapseBelow="tablet">
      <Column width={['fluid', '1/2', '2/3']}>
        <Stack space="small">
          {headerAndTabs}

          {activeTab === 'ratings' ? (
            ratings
          ) : (
            <Columns space="small" collapseBelow="desktop">
              <Column>
                <Stack space="small">
                  {platform === 'mobile' && dispatched}
                  {companyInfo}
                  {contactInfo}
                  {platform !== 'mobile' && billingInfo}
                </Stack>
              </Column>

              <Column>
                <Stack space="small">
                  {assessment}
                  {platform === 'mobile' && billingInfo}
                  {termsAndConditions}
                  {platform === 'mobile' && actions}
                </Stack>
              </Column>
            </Columns>
          )}
        </Stack>
      </Column>

      {platform === 'desktop' && (
        <Column width={['fluid', '1/2', '1/3']}>
          <Stack space="small">
            {dispatched}
            {internalRecords}
            {internalNotes}
            {actions}
          </Stack>
        </Column>
      )}
    </Columns>
  );
}
