import { Divider, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import styled from 'styled-components';
import { useCanExecute } from '../shared/data/UserPermissions';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import { SuperLoadboardContactsDisabledDescription } from './core/SuperLoadboardContactsDisabledDescription';
import {
  SectionHeader,
  SectionSwitch,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';

interface HideContactsFieldProps {
  isDisabled?: boolean;
}

const HideContactsField = styled.div<HideContactsFieldProps>`
  color: ${({ isDisabled }) => (isDisabled ? Color.Dark100 : Color.Dark300)};
  h4 {
    color: ${({ isDisabled }) => (isDisabled ? Color.Dark100 : Color.Dark500)};
  }
`;

export interface HideContactsProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardHideContacts({ profile }: HideContactsProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Hide details',
        hide_contact_info_for: updatedValues.hide_contact_info_for,
      });
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  return (
    <SuperLoadboardSettingsSection aria-label="Hide contact info">
      <FormikProvider value={formik}>
        <Stack space="small">
          <HideContactsField isDisabled={profile.posts_on_multiple_loadboards}>
            <Stack space="small">
              <SectionHeader>
                <Columns align="center">
                  <Column width="fluid">
                    <Typography color="inherit" variant="h4">
                      Reduce the risk of Double-Brokering
                    </Typography>
                  </Column>
                  <Column width="content">
                    <SectionSwitch
                      disabled={
                        !canUpdateProfile ||
                        formik.isSubmitting ||
                        profile.posts_on_multiple_loadboards
                      }
                      checked={formik.values.hide_contact_info_for === 'ANY'}
                      onChange={(_, checked) => {
                        formik.setFieldValue(
                          'hide_contact_info_for',
                          checked ? 'ANY' : null,
                        );
                        void formik.submitForm();
                      }}
                    />
                  </Column>
                </Columns>
              </SectionHeader>
              <Divider />
              <Typography color="inherit">
                Only available for companies posting loads exclusively on the
                Super Loadboard.
              </Typography>
              <Typography color="inherit">
                When enabled, company and contact details will be hidden from
                unverified carriers who aren’t set as Approved or Can Instantly
                Book.
              </Typography>
            </Stack>
          </HideContactsField>
          {profile.posts_on_multiple_loadboards && (
            <SuperLoadboardContactsDisabledDescription />
          )}
        </Stack>
      </FormikProvider>
    </SuperLoadboardSettingsSection>
  );
}
