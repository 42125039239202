import { Color } from '@superdispatch/ui';

export function CertificateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M7.33333 9.33333H2.66667V8H7.33333V9.33333ZM9.33333 6.66667H2.66667V5.33333H9.33333V6.66667ZM9.33333 4H2.66667V2.66667H9.33333V4ZM10.6667 0H1.33333C0.593333 0 0 0.593333 0 1.33333V10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.593333 11.4 0 10.6667 0Z"
        fill={Color.Purple500}
      />
    </svg>
  );
}
