import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { BillingPeriodToggle } from './BillingPeriodToggle';
import { SubscriptionPackagesTable } from './SubscriptionPackagesTable';

const StyledDialogCloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

interface PackagesInfoDialogProps {
  open: boolean;
  onClose: () => void;
}

export function PackagesInfoDialog({ open, onClose }: PackagesInfoDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <Box
        paddingTop="large"
        paddingBottom="large"
        paddingLeft="xxlarge"
        paddingRight="xxlarge"
      >
        <Typography align="center" variant="h1">
          Packages
        </Typography>
        <Box marginTop="xlarge">
          <Stack space="xlarge">
            <BillingPeriodToggle />
            <SubscriptionPackagesTable />
          </Stack>
        </Box>
        <StyledDialogCloseButton onClick={onClose}>
          <Close />
        </StyledDialogCloseButton>
      </Box>
    </Dialog>
  );
}
