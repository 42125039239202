import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography,
} from '@material-ui/core';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { ChevronDown } from 'shared/icons/ChevronDown';
import styled from 'styled-components';
import { PackagesInfoDialog } from './PackagesInfoDialog';

const StyledAccordion = styled(Accordion)`
  border-left: none;
  border-right: none;
  border-top: none;

  &.Mui-expanded {
    margin: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 0;
  height: 76px;
  transition: all 0.2s;

  &.Mui-expanded {
    min-height: 0;
    height: 60px;
    padding-top: 20px;
    padding-bottom: 4px;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-top: 0;
  padding-bottom: 24px;
`;
const items = [
  {
    question: 'What happens at the end of my trial?',
    answer: `At the end of your trial, you'll have the option to select a plan that suits your needs. Don't worry, we'll remind you before your trial ends to ensure a seamless`,
  },
  {
    question: 'What plan is best for my business?',
    answer: (
      <div>
        The best plan for your business depends on your specific needs and
        goals. Our team can help you identify the perfect fit. Don&apos;t
        hesitate to {/* @todo - specify href */}
        <Link color="primary" href="#">
          reach out for a personalized consultation.
        </Link>
      </div>
    ),
  },
  {
    question: 'What does the renewal process look like?',
    answer: `Your subscription will automatically renew at the end of your billing cycle for uninterrupted service. You'll receive a notification beforehand, so you're always in the loop.`,
  },
  {
    question: 'How do I upgrade or downgrade?',
    answer: `You can easily upgrade or downgrade your plan by contacting support (Express & Pro Plans) or your customer success manager (Enterprise Plans). We're here to ensure your plan always matches your needs.`,
  },
  {
    question: 'What payment methods do you accept?',
    answer:
      'We accept all major credit cards. Our payment process is designed to be straightforward and secure.',
  },
  {
    question: 'What if I hit my limit on my plan?',
    answer: (
      <div>
        Should you exceed your plan&apos;s capacity, you have the flexibility to
        either cover the fee for additional VINs as detailed in our fee
        structure by plan, or opt for an upgrade to better suit your needs.{' '}
        <PlanLimitExploreOptionsLink /> through our pricing tiers. There&apos;s
        always room to grow with us.
      </div>
    ),
  },
  {
    question: 'How complicated is the setup?',
    answer:
      'You can get started in just a few minutes! For more complex setups, 1:1 onboarding is available with Express or Pro plans and included with Enterprise plans to ensure a smooth start.',
  },
  {
    question: 'Do I have to pay extra for support?',
    answer: (
      <div>
        No, support is always free. We&apos;re committed to your success and
        have phone, email, and chat support available during business hours to
        assist you. We also have a robust{' '}
        <Link
          color="primary"
          href="https://support.superdispatch.com/en/collections/164512-shipper"
        >
          Help Center
        </Link>{' '}
        full or great tutorials.
      </div>
    ),
  },
  {
    question: 'What integrations do you have?',
    answer: (
      <div>
        We are continually evolving, with new integrations added regularly.
        Check out our{' '}
        <Link color="primary" href="https://superdispatch.com/integrations/">
          integrations marketplace
        </Link>{' '}
        to explore how we can connect with your favorite tools and services.
      </div>
    ),
  },
] as const;

export function FaqList() {
  return (
    <>
      {items.map((item) => (
        <StyledAccordion key={item.question} variant="outlined">
          <StyledAccordionSummary expandIcon={<ChevronDown color="action" />}>
            <Typography variant="h3">{item.question}</Typography>
          </StyledAccordionSummary>

          <StyledAccordionDetails>{item.answer}</StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
}

function PlanLimitExploreOptionsLink() {
  const [isOpen, openDialog, closeDialog] = useBoolean(false);

  return (
    <>
      <Link color="primary" onClick={openDialog}>
        Explore your options
      </Link>
      <PackagesInfoDialog open={isOpen} onClose={closeDialog} />
    </>
  );
}
