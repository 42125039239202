import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { CancelSubscriptionDrawerContent } from './CancelSubscriptionDrawerContent';
import { EditDrawer } from './EditDrawer';

interface CancelSubscriptionDrawerProps {
  open: boolean;
  onClose: () => void;
  onCancelSuccess: () => void;
}

export function CancelSubscriptionDrawer({
  open,
  onClose,
  onCancelSuccess,
}: CancelSubscriptionDrawerProps) {
  const { data } = useSubscriptionDetails();
  const isTrial = data?.chargebee.status === 'IN_TRIAL';

  return (
    <EditDrawer
      open={open}
      onClose={onClose}
      title={isTrial ? 'Cancel Free Trial' : 'Cancel Subscription'}
    >
      <CancelSubscriptionDrawerContent
        onClose={onClose}
        onCancelSuccess={onCancelSuccess}
      />
    </EditDrawer>
  );
}
