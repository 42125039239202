import { Link } from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import { SuspendedPhoneText } from '@superdispatch/phones';
import { Stack } from '@superdispatch/ui';
import { DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { FaxIcon } from 'shared/icons/FaxIcon';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';

let isEditTooltipViewed = false;

function trackTooltipAnalytics() {
  if (!isEditTooltipViewed) {
    trackEventLegacy('Updated Profile Edit Tooltip Viewed', {
      isViewed: true,
    });
    isEditTooltipViewed = true;
  }
}

interface CarrierRequirementsDetailProps {
  profile: ShipperProfileDTO;
}

export function CarrierRequirementsDetails({
  profile,
}: CarrierRequirementsDetailProps) {
  return (
    <Stack space="xsmall">
      <Stack space="none">
        <DescriptionItem>
          <TextBox color="secondary">{profile.name}</TextBox>
        </DescriptionItem>

        <DescriptionItem fallback="No address">
          {ShipperProfileDTO.formatAddress(profile).length > 0 && (
            <TextBox color="secondary">
              {ShipperProfileDTO.formatAddress(profile)}
            </TextBox>
          )}
        </DescriptionItem>
      </Stack>

      <Stack space="xxsmall">
        <TextBox color="secondary">Carrier will send info to:</TextBox>

        <Stack space="none">
          {profile.contact_email && (
            <DescriptionItem icon={<Mail />}>
              <TextBox color="secondary">{profile.contact_email}</TextBox>
            </DescriptionItem>
          )}

          {profile.fax && (
            <DescriptionItem icon={<FaxIcon />}>
              <TextBox color="secondary">
                <SuspendedPhoneText
                  phone={profile.fax}
                  fallback={profile.fax}
                />
              </TextBox>
            </DescriptionItem>
          )}
        </Stack>
      </Stack>

      <ResponsiveTooltip
        onOpen={trackTooltipAnalytics}
        title="Contact support to edit the Shipper name. The address and contacts can be changed in the Company and Contact Info sections of Shipper profile."
      >
        <Link>Need to edit?</Link>
      </ResponsiveTooltip>
    </Stack>
  );
}
