import { Exclude, Expose, plainToClass } from 'class-transformer';

@Exclude()
export class AdditionalEmailPreferencesDTO {
  static create(values: unknown) {
    return plainToClass(AdditionalEmailPreferencesDTO, values);
  }

  @Expose() email: string | null;
  @Expose() is_load_offers_enabled: boolean;
  @Expose() is_load_requests_enabled: boolean;
  @Expose() is_status_and_modifications_enabled: boolean;
  @Expose() is_billing_enabled: boolean;
  @Expose() is_unsubscribed: boolean;
}

@Exclude()
export class NotificationSettingsDTO {
  @Expose() personal_email: string;
  @Expose() is_personal_email_notifications_enabled: boolean;
  @Expose() is_personal_email_unsubscribed: boolean;
  @Expose() is_personal_push_notifications_enabled: boolean;
  @Expose() company_email: string | null;
  @Expose() is_company_email_notifications_enabled: boolean;
  @Expose() is_company_email_unsubscribed: boolean;
  @Expose() additional_email_preferences: AdditionalEmailPreferencesDTO[];
  @Expose() sms_notification_phone_number: string | null;
}
