import { URITemplateParams } from '@superdispatch/uri';
import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { Schema } from 'yup';

export type APIQueryInput =
  | string
  | readonly [domain: string, resource: string, variables?: URITemplateParams];

export type APIQueryOptions<TData> = Omit<
  UseQueryOptions<TData, Error>,
  'queryFn' | 'queryKey' | 'queryHash' | 'queryKeyHashFn' | 'isDataEqual'
>;

export interface UseAPIQueryOptions<TData> extends APIQueryOptions<TData> {
  schema?: Schema<TData>;
  normalize?: (response: unknown) => TData;
}

export type APIQueryResult<TData> = UseQueryResult<TData, Error>;
export type APIQueryFn<TData> = (options: {
  signal: AbortSignal | undefined;
}) => Promise<TData>;

export function useAPIQuery<TData>(
  input: APIQueryInput,
  query: APIQueryFn<TData>,
  { schema, normalize, ...options }: UseAPIQueryOptions<TData> = {},
): APIQueryResult<TData> {
  return useQuery<TData, Error>(
    input,
    ({ signal }) =>
      query({ signal }).then((data) => {
        if (schema) data = schema.cast(data);
        if (normalize) data = normalize(data);
        return data;
      }),
    options,
  );
}

export type APIQueryDataUpdater<TData> = (data: TData) => TData;

export function setAPIQueryData<TData>(
  queryClient: QueryClient,
  input: APIQueryInput,
  updater: APIQueryDataUpdater<TData>,
): void {
  const data = queryClient.getQueryData<TData>(input);
  if (data) queryClient.setQueryData(input, updater(data));
}
