import { Box, Grid } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Button, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { ComponentType } from 'react';
import { trackUpdatedProfileValues } from '../data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { EditComponentProps } from '../Section';

export const TermsAndConditionsEdit: ComponentType<EditComponentProps> = ({
  profile,
  onSave,
}) => {
  const { addSnackbar } = useSnackbarStack();
  const { updateTermsAndConditions } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateTermsAndConditions(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      addSnackbar('Profile updated successfully', { variant: 'success' });
      trackUpdatedProfileValues('Terms and Conditions', {
        terms_and_conditions: updatedValues.terms_and_conditions,
      });
      onSave();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { dirty, isSubmitting, isValid, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <FormikTextField
              name="terms_and_conditions"
              label="Terms and Conditions"
              placeholder="Write your Terms and Conditions"
              multiline={true}
              rowsMax={10}
              rows={10}
              fullWidth={true}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Box pt={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!dirty || !isValid || isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
