import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { FormikDateField, FormikTextField } from '@superdispatch/forms';
import { formatDateType, listDateTypes } from '@superdispatch/sdk';
import { InfoTooltip, Inline, Stack, Tag, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { FunctionComponent, memo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { StepCounterpartyFields } from './counterparty/StepCounterpartyFields';
import { OrderFormValues } from './OrderForm';
import { StepScheduledDateField } from './StepScheduledDateField';

export interface StepProps {
  title: 'Pickup' | 'Delivery';
  name: 'pickup' | 'delivery';
  customer: OrderFormValues['customer'];
  stepData: OrderFormValues['pickup'] | OrderFormValues['delivery'];
  isAdjustedDateEnabled: boolean;
  adjustedDateLabel: string;
  isOrderCreate: boolean;
}

export const Step: FunctionComponent<StepProps> = memo(
  ({
    title,
    name,
    customer,
    stepData,
    isAdjustedDateEnabled,
    adjustedDateLabel,
    isOrderCreate,
  }) => {
    const uid = useUID();
    const { user } = useUserState();

    const styleLabelText = (text: string) => (
      <Typography color="textPrimary">{text}</Typography>
    );

    return (
      <Card
        aria-label={`${name} counterparty`}
        aria-labelledby={uid}
        data-intercom-target={`${name} section`}
      >
        <CardContent>
          <Stack space="small">
            <Inline space="small" verticalAlign="center">
              <Typography id={uid} variant="h3">
                {title}
              </Typography>

              {name === 'delivery' && customer.bill_the_delivery && (
                <Tag color="purple" variant="subtle">
                  Billing Contact
                </Tag>
              )}
            </Inline>

            <StepCounterpartyFields name={name} isOrderCreate={isOrderCreate} />

            <div data-intercom-target={`${name} date/notes section`}>
              <Stack>
                <Box paddingTop={2}>
                  <Typography variant="h4">Dates & Notes</Typography>
                </Box>

                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} lg={6} xl={4}>
                    <FormikTextField
                      select={true}
                      fullWidth={true}
                      label={styleLabelText('Date type')}
                      name={`${name}.date_type`}
                      unstableOnKeyDownSelection={true}
                    >
                      {listDateTypes().map((value) => (
                        <MenuItem key={value} value={value}>
                          {formatDateType(value)}
                        </MenuItem>
                      ))}
                    </FormikTextField>
                  </Grid>

                  {user?.shipper.shipper_type === 'BROKER' &&
                    (user.order_form_settings[name]
                      .is_scheduled_at_by_customer_visible ||
                      stepData.scheduled_at_by_customer) && (
                      <Grid item={true} xs={12} lg={6} xl={4}>
                        <StepScheduledDateField
                          type={name}
                          scheduledAtName={`${name}.scheduled_at_by_customer`}
                          scheduledAtRelatedFieldName={`${name}.scheduled_at`}
                          scheduledEndsAtName={`${name}.scheduled_ends_at_by_customer`}
                          scheduledEndsAtRelatedFieldName={`${name}.scheduled_ends_at`}
                          enableClearable={true}
                          label={
                            name === 'pickup'
                              ? 'Customer Pickup Date'
                              : 'Customer Delivery Date'
                          }
                        />
                      </Grid>
                    )}

                  <Grid item={true} xs={12} lg={6} xl={4}>
                    <StepScheduledDateField
                      type={name}
                      scheduledAtName={`${name}.scheduled_at`}
                      scheduledEndsAtName={`${name}.scheduled_ends_at`}
                      label={
                        user?.shipper.shipper_type === 'BROKER'
                          ? name === 'pickup'
                            ? 'Carrier Pickup Date'
                            : 'Carrier Delivery Date'
                          : name === 'pickup'
                          ? 'Pickup Date'
                          : 'Delivery Date'
                      }
                    />
                  </Grid>

                  {name === 'pickup' &&
                    user?.shipper.shipper_type === 'BROKER' &&
                    (user.order_form_settings.pickup
                      .is_first_available_pickup_date_visible ||
                      stepData.first_available_pickup_date) && (
                      <Grid item={true} xs={12} lg={6} xl={4}>
                        <FormikDateField
                          key="first_available_pickup_date"
                          name="pickup.first_available_pickup_date"
                          label={styleLabelText('1st Avail. Pickup Date')}
                          enableClearable={true}
                        />
                      </Grid>
                    )}

                  {user?.shipper.shipper_type === 'BROKER' &&
                    (user.order_form_settings[name].is_adjusted_date_visible ||
                      stepData.adjusted_date) && (
                      <Grid item={true} xs={12} lg={6} xl={4}>
                        <FormikDateField
                          name={`${name}.adjusted_date`}
                          disabled={!isAdjustedDateEnabled}
                          label={styleLabelText(adjustedDateLabel)}
                          enableClearable={true}
                        />
                      </Grid>
                    )}

                  <Grid item={true} xs={12}>
                    <FormikTextField
                      name={`${name}.notes`}
                      label={
                        <Box display="flex" justifyContent="space-between">
                          <InfoTooltip
                            title="Visible to carrier after accepting the load offer"
                            TextProps={{ color: 'textPrimary' }}
                          >
                            Notes
                          </InfoTooltip>

                          <TextBox color="secondary">
                            {`${stepData.notes?.length || 0} of 2000`}
                          </TextBox>
                        </Box>
                      }
                      multiline={true}
                      fullWidth={true}
                      inputProps={{
                        'aria-label': `${name} notes`,
                        maxLength: 2000,
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
          </Stack>
        </CardContent>
      </Card>
    );
  },
);

Step.displayName = 'Step';
