import {
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab';
import { useConstant } from '@superdispatch/hooks';
import { useUID } from '@superdispatch/ui';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import {
  VirtualizedAutocomplete,
  VirtualizedAutocompleteProps,
} from 'shared/form/VirtualizedAutocomplete';
import { Flag } from 'shared/ui/Flag';

function filterOptions(
  options: CountryFieldOption[],
  state: FilterOptionsState<CountryFieldOption>,
): CountryFieldOption[] {
  const query = state.inputValue.trim().toLocaleLowerCase();

  return options.filter((key) =>
    countryFieldOptions.get(key)?.trim().toLocaleLowerCase().includes(query),
  );
}

export interface CountryFieldProps
  extends Omit<
    VirtualizedAutocompleteProps<CountryFieldOption, false, false, false>,
    | 'value'
    | 'onChange'
    | 'options'
    | 'multiple'
    | 'autoHighlight'
    | 'renderInput'
    | 'filterOptions'
    | 'getOptionLabel'
  > {
  value?: null | CountryFieldOption;
  onChange?: (value: null | CountryFieldOption) => void;
  TextFieldProps?: Omit<StandardTextFieldProps, 'startAdornment'>;
}

export function CountryField({
  id,
  value,
  onChange,
  TextFieldProps: { autoComplete = 'off', ...textFieldProps } = {},
  ...props
}: CountryFieldProps) {
  const uid = useUID();
  const options = useConstant(() => Array.from(countryFieldOptions.keys()));

  return (
    <VirtualizedAutocomplete
      {...props}
      multiple={false}
      autoHighlight={true}
      id={id || uid}
      value={value}
      options={options}
      onChange={(_, nextValue) => onChange?.(nextValue)}
      filterOptions={filterOptions}
      getOptionLabel={(option: CountryFieldOption) =>
        countryFieldOptions.get(option) || ''
      }
      renderOption={(option: CountryFieldOption) => (
        <>
          <Flag code={option} />
          &nbsp;
          <Typography>{countryFieldOptions.get(option)}</Typography>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps.InputProps,
            startAdornment: value && <Flag code={value} />,
          }}
          autoComplete={autoComplete}
        />
      )}
    />
  );
}
