import { ProgressStep, ProgressStepper } from '../../shared/ui/ProgressStepper';
import { VerificationApplicationStepsBox } from './VerificationApplicationLayout';

interface VerificationStepperProps {
  step: 1 | 2 | 3;
}

export function VerificationStepper({ step }: VerificationStepperProps) {
  return (
    <VerificationApplicationStepsBox>
      <ProgressStepper>
        <ProgressStep
          title="Step 1"
          subtitle="Your Company"
          isActive={step === 1}
          isCompleted={false}
        />
        <ProgressStep
          title="Step 2"
          subtitle="Identification"
          isActive={step === 2}
          isCompleted={false}
        />
        <ProgressStep
          title="Step 3"
          subtitle="Insurance"
          isActive={step === 3}
          isCompleted={false}
        />
      </ProgressStepper>
    </VerificationApplicationStepsBox>
  );
}
