import { useConstant } from '@superdispatch/hooks';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { logError } from 'shared/helpers/MonitoringService';
import { ensureError } from 'shared/utils/ErrorUtils';

export const API_KEY = 'AIzaSyDfJ9dXPcMMxHuaz9I_GwQsAeQhfb9zQko';

const CALLBACK_NAME = '__googleMapsApiOnLoadCallback';
const API_URL = 'https://maps.googleapis.com/maps/api/js';
const API_PARAMS = [
  'language=en',
  'libraries=places',
  `callback=${CALLBACK_NAME}`,
  `key=${API_KEY}`,
].join('&');

function getMaps(): undefined | typeof google.maps {
  return 'google' in window && 'maps' in window.google
    ? window.google.maps
    : undefined;
}

function setCallback(cb: null | (() => void)): void {
  Object.assign(window, { [CALLBACK_NAME]: cb });
}

function loadAPI(): Promise<typeof google.maps> {
  const currentMaps = getMaps();

  if (currentMaps) {
    return Promise.resolve(currentMaps);
  }

  return new Promise((resolve, reject) => {
    setCallback(() => {
      const maps = getMaps();

      setCallback(null);

      if (maps) {
        resolve(maps);
      } else {
        reject(
          new Error(
            'google.maps not found in global scope after loading script',
          ),
        );
      }
    });

    const script = document.createElement('script');

    script.async = true;
    script.defer = true;

    script.onerror = () => {
      setCallback(null);
      reject(new Error('Failed to load google.maps'));
    };

    script.src = `${API_URL}?${API_PARAMS}`;

    document.body.appendChild(script);
  });
}

interface GoogleMapsContext {
  googleMaps?: typeof google.maps | null;
  getDirections: (
    request: google.maps.DirectionsRequest,
  ) => Promise<google.maps.DirectionsResult | null>;
}

const Context = createContext<GoogleMapsContext | null>(null);

export function useGoogleMapsContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useGoogleMapsContext is used outside of GoogleMapsProvider',
    );
  }

  return context;
}

export function GoogleMapsProvider({ children }: PropsWithChildren<unknown>) {
  const [googleMaps, setGoogleMaps] = useState<typeof google.maps>();
  const mapsPromise = useConstant(loadAPI);

  useEffect(() => {
    void mapsPromise
      .then(setGoogleMaps)
      .catch((reason) =>
        logError('Failed to load Google Maps', 'GoogleMaps', { reason }),
      );
  }, []);

  const getDirections = useCallback(
    ({ destination, origin }: google.maps.DirectionsRequest) =>
      mapsPromise.then(
        (maps) =>
          new Promise<google.maps.DirectionsResult | null>(
            (resolve, reject) => {
              const directionsService = new maps.DirectionsService();

              directionsService.route(
                {
                  origin,
                  destination,
                  travelMode: maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  switch (status) {
                    case maps.DirectionsStatus.OK:
                      resolve(result);
                      break;
                    case maps.DirectionsStatus.ZERO_RESULTS:
                      resolve(null);
                      break;
                    case maps.DirectionsStatus.NOT_FOUND:
                      resolve(null);
                      break;
                    default:
                      reject(ensureError(result));
                  }
                },
              );
            },
          ),
      ),
    [mapsPromise],
  );

  const context = useMemo(
    () => ({ googleMaps, getDirections }),
    [googleMaps, getDirections],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
