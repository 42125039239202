import { Drawer, DrawerProps, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  DrawerContent,
  DrawerContentProps,
  DrawerTitle,
  useUID,
} from '@superdispatch/ui';
import { FormEventHandler, ReactNode } from 'react';
import { Prompt } from 'shared/routing/Prompt';

const defaultOnSubmit: FormEventHandler<HTMLFormElement> = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

export interface DrawerFormProps
  extends Omit<DrawerProps, 'title' | 'anchor' | 'onClose' | 'onSubmit'> {
  open: boolean;
  title: ReactNode;
  actions?: ReactNode;
  onClose: () => void;
  isDirty: boolean;
  isSubmitting: boolean;
  disableNavigationPrompt?: boolean;
  disableCloseOnNavigation?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  drawerContentProps?: DrawerContentProps;
}

export function DrawerForm({
  title,
  open,
  onClose,
  actions,
  children,

  isDirty,
  isSubmitting,
  disableNavigationPrompt,
  disableCloseOnNavigation,
  onSubmit = defaultOnSubmit,
  drawerContentProps,

  ...props
}: DrawerFormProps) {
  const uid = useUID();

  return (
    <Drawer
      {...props}
      open={open}
      anchor="right"
      onClose={isSubmitting ? undefined : onClose}
      PaperProps={{
        ...props.PaperProps,
        'aria-labelledby': uid,
      }}
    >
      {!!title && (
        <DrawerTitle
          title={title}
          titleTypographyProps={{ id: uid }}
          endAction={
            <IconButton
              edge="end"
              aria-label="close"
              onClick={onClose}
              disabled={isSubmitting}
            >
              <Close />
            </IconButton>
          }
        />
      )}

      {!disableNavigationPrompt && (
        <Prompt
          message="Changes have not been saved. Leaving will result in unsaved changes being lost."
          when={() => {
            if (isDirty || isSubmitting) {
              return true;
            }

            if (!disableCloseOnNavigation) {
              onClose();
            }

            return false;
          }}
        />
      )}

      <form onSubmit={onSubmit} aria-label={`${String(title)} Form`}>
        <DrawerContent {...drawerContentProps}>{children}</DrawerContent>

        {actions}
      </form>
    </Drawer>
  );
}
