import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import CarrierTrackIcon from 'shared/icons/CarrierTrack.png';
import styled from 'styled-components';

interface EmptyNetworkCarrierProps {
  tabName: 'suggested' | 'previously_dispatched' | 'partner';
}
const StyledImg = styled.img`
  padding-top: 64px;
  padding-bottom: 8px;
`;
export function EmptyNetworkCarrier({ tabName }: EmptyNetworkCarrierProps) {
  return (
    <Stack align="center">
      <StyledImg
        width={220}
        src={CarrierTrackIcon}
        alt="No Previously Dispatched Carriers"
      />
      {tabName === 'previously_dispatched' ? (
        <>
          <Typography color="textSecondary" variant="h3">
            No carrier options for this lane
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Carriers will be listed here as they deliver orders for you within
            this lane.
          </Typography>
        </>
      ) : (
        <>
          <Typography color="textSecondary" variant="h3">
            No suggested carriers
          </Typography>
          <Typography color="textSecondary" variant="body2" align="center">
            <span>
              Carriers will be listed here as they deliver orders <br />
              within this lane through Super Dispatch.
            </span>
          </Typography>
        </>
      )}
    </Stack>
  );
}
