import { Box, MenuItem, TextField, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  useResponsiveValue,
} from '@superdispatch/ui';
import { ChangeEvent } from 'react';

const pageSizeOptions = [
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export interface OrdersListFooterProps {
  page?: number;
  pageSize?: number;
  totalPages?: number;
  handlePageChange: ({ selected }: { selected: number }) => void;
  handlePageSizeChange: ({ value }: { value: number }) => void;
}

export function OrdersListFooter({
  page = 0,
  pageSize,
  totalPages,
  handlePageChange,
  handlePageSizeChange,
}: OrdersListFooterProps) {
  const siblingCount = useResponsiveValue(0, 3);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  return (
    <Box
      padding={2}
      bgcolor={Color.White}
      borderTop={`1px solid ${Color.Silver400};`}
    >
      <Columns>
        {platform === 'desktop' && (
          <Column width="content">
            <Inline space="small" verticalAlign="center">
              <Typography>Page size:</Typography>

              <TextField
                select={true}
                placeholder="Page size"
                value={pageSize || pageSizeOptions[0]?.value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handlePageSizeChange({ value: Number(event.target.value) });
                }}
              >
                {pageSizeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Inline>
          </Column>
        )}

        {!!totalPages && totalPages > 0 && (
          <Column width="fluid">
            <Inline horizontalAlign="center">
              <Pagination
                siblingCount={siblingCount}
                page={page + 1}
                count={totalPages}
                onChange={(_, selectedPage) =>
                  handlePageChange({ selected: selectedPage - 1 })
                }
              />
            </Inline>
          </Column>
        )}
      </Columns>
    </Box>
  );
}
