import { IconButton, Link } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Column, Columns, OverflowText } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { AttachmentDTO } from 'shared/types/attachment';

export interface AttachmentListItemProps {
  attachment: AttachmentDTO | File;
  disabled?: boolean;
  onDelete?: (attachment: AttachmentDTO | File) => void;
}

export function AttachmentListItem({
  attachment,
  disabled,
  onDelete,
}: AttachmentListItemProps) {
  const [previewURL, setPreviewURL] = useState<string>();

  const fileName = attachment.name;

  useEffect(() => {
    if (!(attachment instanceof File)) {
      setPreviewURL(attachment.url);

      return;
    }

    const nextPreviewURL = URL.createObjectURL(attachment);

    setPreviewURL(nextPreviewURL);

    return () => {
      URL.revokeObjectURL(nextPreviewURL);
    };
  }, [attachment]);

  return (
    <Columns space="small" align="center">
      <Column>
        <Link href={previewURL} target="_blank" rel="noreferrer">
          <OverflowText>{fileName}</OverflowText>
        </Link>
      </Column>

      <Column width="content">
        <IconButton
          aria-label={`delete ${fileName}`}
          size="small"
          disabled={disabled}
          onClick={() => onDelete?.(attachment)}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Column>
    </Columns>
  );
}
