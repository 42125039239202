import { Avatar, Link } from '@material-ui/core';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { plainToClass } from 'class-transformer';
import { DictionaryCarrierDTO } from 'core/dictionary/data/DictionaryDTO';
import { CarrierRatingDialog } from 'core/ratings/CarrierRatingDialog';
import { RatingStats } from 'core/ratings/RatingStats';
import { TotalOrdersDispatchedLink } from 'core/TotalOrdersDispatchedLink';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { ProfileUnratedCarrierDTO } from './data/ShipperRatingDTO';
interface Props {
  carrier: ProfileUnratedCarrierDTO;
  onRateSuccess: () => void;
}

export function UnratedCarrier({ carrier, onRateSuccess }: Props) {
  const [open, setOpen] = useState(false);
  const [_, setQuery] = useQuery();
  return (
    <>
      <CarrierRatingDialog
        open={open}
        carrierGuid={carrier.guid}
        onClose={() => {
          setOpen(false);
          setQuery({ utm_medium: undefined });
        }}
        onSubmitSuccess={() => {
          setOpen(false);
          setQuery({ utm_medium: undefined });
          onRateSuccess();
        }}
      />
      <Columns align="top" space="small">
        <Column width="content">
          <Avatar src={carrier.logo} />
        </Column>
        <Column>
          <Columns align="center" space="small" collapseBelow="desktop">
            <Column>
              <Stack>
                <Inline>
                  <Link
                    component={RouterLink}
                    to={`/manage-carriers/${carrier.guid}?utm_medium=Shipper Profile&utm_content=Ratings to Give`}
                  >
                    {carrier.name}
                  </Link>
                  <RatingStats
                    source="Shipper Profile"
                    carrierGuid={carrier.guid}
                    ratingStats={{
                      overall_rating: carrier.overall_rating,
                      total_rating_count: carrier.total_rating_count,
                    }}
                  />
                </Inline>
                <TotalOrdersDispatchedLink
                  source="shipper_profile"
                  carrier={plainToClass(DictionaryCarrierDTO, carrier)}
                  loadsCount={carrier.accepted_loads_count}
                />
              </Stack>
            </Column>
            <Column width={['fluid', 'fluid', 'content']}>
              <Button
                variant="neutral"
                onClick={() => {
                  setOpen(true);
                  setQuery({ utm_medium: 'Ratings to Give' });
                }}
              >
                Rate Carrier
              </Button>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </>
  );
}
