import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import {
  ChargebeeErrorField,
  ChargebeeEvent,
  ChargebeeResponse,
  FieldError,
  useChargebee,
} from 'shared/helpers/ChargebeeWeb';
import {
  CVVTitleProps,
  FormCardCVV,
  FormCardExpiry,
  FormCardNumber,
} from './ChargebeeFormFields';

export interface ChargebeeCardFormRef {
  tokenize: () => Promise<ChargebeeResponse>;
}

const INVALID_CARD_NUMBER_ERROR_TEXT = 'Invalid card number';
const INVALID_EXPIRATION_DATE_ERROR_TEXT = 'Invalid expiration date';
const INVALID_CVV_ERROR_TEXT = 'Invalid security code';

const errorCodeToMessage: Record<FieldError['errorCode'], string> = {
  invalid_card: INVALID_CARD_NUMBER_ERROR_TEXT,
  card_number_invalid: INVALID_CARD_NUMBER_ERROR_TEXT,
  card_number_incomplete: INVALID_CARD_NUMBER_ERROR_TEXT,
  card_expiry_past: 'Card is expired',
  card_expiry_invalid: INVALID_EXPIRATION_DATE_ERROR_TEXT,
  card_expiry_incomplete: INVALID_EXPIRATION_DATE_ERROR_TEXT,
  card_cvv_invalid: INVALID_CVV_ERROR_TEXT,
  card_cvv_incomplete: INVALID_CVV_ERROR_TEXT,
};

const getErrorMessage = (error: FieldError | null) => {
  if (error === null) {
    return '';
  }

  return errorCodeToMessage[error.errorCode] || error.message || '';
};

export const ChargebeeCardForm = forwardRef<
  ChargebeeCardFormRef | null,
  {
    onValidation?: (isValid: boolean) => void;
  }
>(({ onValidation }, ref) => {
  const { data: subscriptionDetails } = useSubscriptionDetails();

  const { cardRef, isChargebeeAvailable } = useChargebee(
    subscriptionDetails?.chargebee.client_token,
  );

  const [focus, setFocus] = useState<'number' | 'expiry' | 'cvv'>();
  const [errors, setErrors] = useState<ChargebeeErrorField>({
    expiry: null,
    cvv: null,
    number: null,
  });
  const [card, setCard] = useState<CVVTitleProps>({
    isEmpty: true,
    cardType: undefined,
  });

  const handleError = (event: ChargebeeEvent) => {
    const { field, error } = event;
    const newErrors = { ...errors, [field]: error ?? null };
    setErrors(newErrors);
    if (onValidation) {
      const isValid = Object.values(errors).every((err) => err === null);
      onValidation(isValid);
    }
    if (field === 'number') {
      setCard({ cardType: event.cardType, isEmpty: event.empty });
    }
  };

  useImperativeHandle<ChargebeeCardFormRef | null, ChargebeeCardFormRef | null>(
    ref,
    () => {
      return {
        tokenize: () =>
          cardRef.current?.tokenize({}) as Promise<ChargebeeResponse>,
      };
    },
  );

  if (!isChargebeeAvailable) {
    return null;
  }

  return (
    <CardComponent
      ref={cardRef}
      onChange={handleError}
      placeholder={{
        cvv: 'CVV2, CVC2, etc.',
        number: ' ',
        expiry: 'MM / YY',
      }}
    >
      <Stack space="small">
        <FormCardNumber
          label="Card Number"
          focused={focus === 'number'}
          onFocus={() => {
            setFocus('number');
          }}
          error={getErrorMessage(errors.number)}
        />

        <Columns space="small">
          <Column>
            <FormCardExpiry
              label="Expiration"
              focused={focus === 'expiry'}
              onFocus={() => {
                setFocus('expiry');
              }}
              error={getErrorMessage(errors.expiry)}
            />
          </Column>

          <Column>
            <FormCardCVV
              {...card}
              label="Security Code"
              focused={focus === 'cvv'}
              onFocus={() => {
                setFocus('cvv');
              }}
              error={getErrorMessage(errors.cvv)}
            />
          </Column>
        </Columns>
      </Stack>
    </CardComponent>
  );
});

ChargebeeCardForm.displayName = 'ChargebeeCardForm';
