import { AuthenticatedRouteObject } from 'AppRouter';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { VerificationApplicationPage } from './VerificationApplicationPage';
import { VerificationUnderwayPage } from './VerificationUnderwayPage';
import { VerifiedApplicationInfoPage } from './VerifiedApplicationInfoPage';

export const verificationApplicationRoute: AuthenticatedRouteObject = {
  path: 'verification-application',
  handle: { layout: 'blank' },
  Component: VerificationApplicationContainer,
  children: [
    {
      path: 'info',
      element: <VerifiedApplicationInfoPage />,
    },
    {
      path: 'underway',
      element: <VerificationUnderwayPage />,
    },
    {
      path: 'steps/*',
      element: <VerificationApplicationPage />,
    },
    {
      path: '*?',
      Component: () => {
        const location = useLocation();
        return <Navigate to={`info${location.search}`} />;
      },
    },
  ],
};

export function VerificationApplicationContainer() {
  return <Outlet />;
}
