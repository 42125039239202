import { useState } from 'react';

interface ModalAction {
  type: string;
  payload?: unknown;
}

export function useModalState<T extends { type?: unknown; payload?: unknown }>(
  initialState?: T,
) {
  const [modal, setModal] = useState<T | undefined>(initialState);

  function closeModal() {
    setModal(undefined);
  }

  function getPayload<ActionType extends ModalAction['type']>(
    type: ActionType,
  ): Extract<ModalAction, { type: ActionType }>['payload'] {
    return modal?.type === type ? modal.payload : undefined;
  }

  return { modal, setModal, closeModal, getPayload };
}
