import { Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline } from '@superdispatch/ui';

interface CertHolderProps {
  holder: boolean;
  holderSince: string | null;
}

export const NetworkDrawerCertHolder = ({
  holder,
  holderSince,
}: CertHolderProps) => {
  return (
    <FormikCheckboxField
      name="insurance_certificate_holder"
      label={
        <Inline verticalAlign="center">
          <Typography>Insurance Certificate Holder</Typography>

          {holder && (
            <Typography variant="caption" color="textSecondary">
              on{' '}
              <FormattedDate
                variant="Date"
                date={holderSince}
                format="DateISO"
              />
            </Typography>
          )}
        </Inline>
      }
    />
  );
};
