import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  display: flex;
  gap: 16px;

  padding: 4px;
  border-radius: 8px;
  border: 1px solid ${Color.Silver400};
  background: ${Color.White};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding: 16px;
  }
`;

const IllustrationContainer = styled.div<{ color: Color }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;

  height: 136px;
  width: 160px;
  border-radius: 8px;
  background-color: ${({ color }) => color};

  & > * {
    max-height: 80%;
    max-width: 75%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 189px;
    width: 100%;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 content;
  padding-right: 20px;
  padding-left: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 8px 0 0;
  }
`;

interface InfoBannerProps {
  illustration: ReactNode;
  variant?: 'info' | 'warning';
  title: string;
  description: string;
  action: ReactNode;
}

export function InfoBanner({
  illustration,
  variant,
  title,
  description,
  action,
}: InfoBannerProps) {
  return (
    <BannerContainer>
      <IllustrationContainer
        color={variant === 'warning' ? Color.Red50 : Color.Green50}
      >
        {illustration}
      </IllustrationContainer>
      <MainContainer>
        <Stack space="xxsmall">
          <Typography variant="h3">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </MainContainer>
      <ActionContainer>{action}</ActionContainer>
    </BannerContainer>
  );
}
