import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const EmptyStateIcon = createSvgIcon(
  'EmptyState',
  <path
    d="M146.42 18.1033L159.329 13.4148C159.473 13.3534 159.626 13.3216 159.784 13.3216C160.415 13.3216 160.927 13.8308 160.927 14.4581L161 111.863C161 112.491 160.488 113 159.857 113H2.14286C1.512 113 1 112.491 1 111.863L1.06172 14.4536C1.06172 14.2967 1.09371 14.1444 1.15543 14.0012C1.40686 13.4262 2.08114 13.1625 2.65943 13.4125L15.5797 18.6002L15.4709 2.84302M15.4709 2.84302L16.1269 97.7799C48.7646 92.7838 66.5131 101.169 81 109.522M15.4709 2.84302C20.3646 1.59741 25.0091 1.03825 29.4686 1.00188C47.7109 0.854135 64.0583 9.42744 81 15.6191M81 15.6191C97.9349 9.42744 114.275 0.856408 132.522 1.00188C136.984 1.03825 141.631 1.59968 146.529 2.84075L145.853 97.7776C113.247 92.7838 95.4823 101.173 81 109.522M81 15.6191V109.522"
    stroke={Color.Silver500}
    strokeWidth="2"
    strokeDasharray="6 6"
  />,
  { viewBox: '0 0 162 114' },
);
