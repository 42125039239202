import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const WarningIconRounded = createSvgIcon(
  'WarningIconRounded',
  <>
    <circle cx="16" cy="16" r="16" fill={Color.Yellow50} />
    <path
      d="M8.6665 21H23.3332L15.9998 8.33334L8.6665 21ZM16.6665 19H15.3332V17.6667H16.6665V19ZM16.6665 16.3333H15.3332V13.6667H16.6665V16.3333Z"
      fill={Color.Yellow500}
    />
  </>,
  {
    viewBox: '0 0 32 32',
    'aria-label': 'warning icon',
  },
);
