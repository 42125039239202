import { Avatar } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { SuperPayRequestedStatisticsDTO } from '../data/SuperPayDTO';

const AvatarContainer = styled(Box)`
  align-items: center;
`;

const AvatarStyled = styled(Avatar)<{
  $hasMargin: boolean;
  $width?: number;
  $height?: number;
}>`
  border: 1px solid ${Color.White};
  margin-left: ${({ $hasMargin }) => ($hasMargin ? '-6px' : 'auto')};
  width: ${({ $width = 32 }) => `${$width}px`};
  height: ${({ $height = 32 }) => `${$height}px`};
`;

export function CarrierAvatars({
  carriersList,
  width,
  height,
}: {
  carriersList: SuperPayRequestedStatisticsDTO['carriers_list'];
  width?: number;
  height?: number;
}) {
  return (
    <AvatarContainer display="flex">
      {carriersList.map(({ guid, name, logo }, index) => (
        <AvatarStyled
          key={guid}
          $hasMargin={!!index}
          src={logo || ''}
          $width={width}
          $height={height}
        >
          {getInitials(name)}
        </AvatarStyled>
      ))}
    </AvatarContainer>
  );
}
