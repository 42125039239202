import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type UserDTO = InferType<typeof userDTO>;

export const userDTO = yupObject({
  guid: yupString().required(),
  first_name: yupString().nullable(),
  last_name: yupString().nullable(),
  username: yupString().required(),
  email: yupString().email().nullable(),
  phone: yupString().nullable(),
  role: yupString().nullable(),
  is_active: yupBoolean(),
  display_user_contact_to_carriers: yupBoolean(),
});
