import {
  BroadcastChannelHandler,
  useBroadcastChannel,
} from 'shared/helpers/BroadcastChannel';

export function useSuperLoadboardSettingsChannel(
  handler?: BroadcastChannelHandler<undefined>,
) {
  return useBroadcastChannel<undefined>('instant-booking', handler);
}
