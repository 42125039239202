import { Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import Order from 'shared/types/order';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';
import { CarrierPaymentForm } from './CarrierPaymentForm';

export function CarrierPaymentStep({ order }: { order?: Order }) {
  const { values } = useFormikContext<BuildLoadsFormValues>();
  return (
    <Stack space="large">
      {values.loads.map((load, index) => (
        <CarrierPaymentForm
          key={load.number}
          name={`loads[${index}].payment`}
          order={order}
          load={load}
          index={index}
        />
      ))}
    </Stack>
  );
}
