import { Tooltip, TooltipProps } from '@material-ui/core';
import {
  DateDisplayVariant,
  formatDate,
  FormattedDate,
  NullableDateInput,
} from '@superdispatch/dates';
import { HTMLAttributes } from 'react';

export interface DetailedFormattedDateProps {
  date: NullableDateInput;
  variant?: DateDisplayVariant;
  ContainerProps?: HTMLAttributes<HTMLSpanElement>;
  title?: string;
  placement?: TooltipProps['placement'];
}

export function DetailedFormattedDate({
  date,
  variant = 'Date',
  ContainerProps,
  title,
  placement = 'bottom',
}: DetailedFormattedDateProps) {
  return (
    <Tooltip
      title={
        title ||
        formatDate(date, { variant: 'DateTime' }, { format: 'JodaISO' })
      }
      placement={placement}
    >
      <span style={{ whiteSpace: 'nowrap' }} {...ContainerProps}>
        <FormattedDate date={date} format="JodaISO" variant={variant} />
      </span>
    </Tooltip>
  );
}
