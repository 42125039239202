import { Typography } from '@material-ui/core';
import { ErrorOutlined } from '@material-ui/icons';
import { Color, useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton, Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    align-items: flex-start;
    gap: 16px;
  }
`;

const Details = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
`;

export function RevocationHeaderNotification() {
  const isMobile = useResponsiveValue(true, false, false);

  const { data: verification } = useVerificationApplication();
  if (verification?.status !== 'REVOKED') {
    return null;
  }

  return (
    <Box padding="small" paddingLeft="small" backgroundColor="Red50">
      <Container>
        <ErrorOutlined htmlColor={Color.Red300} />
        <Details>
          <Typography color="error">
            Operating Authority Inactive. Contact FMCSA to regain Verified
            Shipper status and access to the Super Loadboard.{' '}
          </Typography>

          <AnchorButton
            size="small"
            variant="neutral"
            href="https://support.superdispatch.com/en/articles/9806978-reinstating-broker-authority"
            target="_blank"
            onClick={() => {
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on "Learn More" Link in Operating Authority Inactive Banner',
              });
            }}
            fullWidth={isMobile}
          >
            Learn More
          </AnchorButton>
        </Details>
      </Container>
    </Box>
  );
}
