import { useDeepEqualValue } from '@superdispatch/hooks';
import { renderChildren } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import { ReactNode, useEffect } from 'react';

type SourceValue = [primarySource: string, secondarySource?: string];

const SOURCE_STACK: SourceValue[] = [];

export function pushSource(...source: SourceValue): void {
  SOURCE_STACK.push(source);
}

export function popSource(): void {
  SOURCE_STACK.pop();
}

export function getCurrentSource(): SourceValue | undefined {
  return SOURCE_STACK[SOURCE_STACK.length - 1];
}

export function addSourceParams(url: string): string {
  const source = getCurrentSource();
  return parseURITemplate(`${url}{?utm_medium,utm_content}`, {
    utm_medium: source?.[0],
    utm_content: source?.[1],
  });
}

/**
 * @deprecated Use `SourceManager` instead.
 * */
export function useSourceManagerLegacy(...source: SourceValue): void {
  const pureSource = useDeepEqualValue(source);

  useEffect(() => {
    pushSource(...pureSource);

    return () => {
      popSource();
    };
  }, [pureSource]);
}

interface SourceManagerProps {
  primarySource: string;
  secondarySource?: string;
  children?: ReactNode;
}

/**
 * @deprecated Use `SourceManager` instead.
 * */
export function SourceManagerLegacy({
  primarySource,
  secondarySource,
  children,
}: SourceManagerProps) {
  useSourceManagerLegacy(primarySource, secondarySource);

  return renderChildren(children);
}
