import { Skeleton } from '@material-ui/lab';
import { Stack } from '@superdispatch/ui';
import { PricingSectionOverages } from './PricingSectionOverages';
import { PricingSectionPlanPrice } from './PricingSectionPlanPrice';
import { PricingSectionTotalPrice } from './PricingSectionTotalPrice';
import { useUsageDetails } from './useUsageDetails';

export function PricingSection({
  planName,
  monthlyPlanPrice,
  maxPlanVehicles,
}: {
  planName: string;
  monthlyPlanPrice: number;
  maxPlanVehicles: number;
}) {
  const { usageDetails, isUsageDetailsLoading } = useUsageDetails();

  return (
    <Stack space="small">
      <PricingSectionPlanPrice
        planName={planName}
        monthlyPrice={monthlyPlanPrice}
        maxVehicles={maxPlanVehicles}
      />

      {isUsageDetailsLoading && (
        <Stack>
          <Skeleton />
          <Skeleton />
        </Stack>
      )}

      {usageDetails && (
        <>
          <PricingSectionOverages usages={usageDetails.usages} />

          <PricingSectionTotalPrice
            monthlyPlanPrice={monthlyPlanPrice}
            usages={usageDetails.usages}
          />
        </>
      )}
    </Stack>
  );
}
