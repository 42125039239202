import { Address } from 'shared/types/address';
import { ViewRoute } from 'shared/ui/ViewRoute';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';

interface ViewOrderRouteProps {
  origin: Address;
  destination: Address;
}

export function ViewOrderRoute({ origin, destination }: ViewOrderRouteProps) {
  const originAddress = makeAddressCityStateZip(origin, ', ');
  const destinationAddress = makeAddressCityStateZip(destination, ', ');

  return originAddress && destinationAddress ? (
    <ViewRoute origin={originAddress} destination={destinationAddress} />
  ) : null;
}
