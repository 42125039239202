import { Toolbar, Typography } from '@material-ui/core';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { PageLayout } from 'shared/layout/PageLayout';
import { FeatureToggle } from '../shared/data/FeatureToggle';
import { LoadingContainer } from '../shared/ui/LoadingContainer';
import { useShipperProfile } from '../shipper-profile/data/ShipperProfileAPI';
import { SuperLoadboardAutoRepostCancelled } from './SuperLoadboardAutoRepostCancelled';
import { SuperLoadboardAutoRepostDeclined } from './SuperLoadboardAutoRepostDeclined';
import { SuperLoadboardHideContacts } from './SuperLoadboardHideContacts';
import { SuperLoadboardInstantBooking } from './SuperLoadboardInstantBooking';
import { SuperLoadboardPrivateNetwork } from './SuperLoadboardPrivateNetwork';

export function SuperLoadboardSettingsPage() {
  const { addSnackbar } = useSnackbarStack();

  const { data: profile, error } = useShipperProfile();

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  if (!profile) {
    return <LoadingContainer />;
  }

  return (
    <PageLayout
      withContentPadding={true}
      header={
        <Toolbar>
          <Box marginLeft="small" marginRight="small">
            <Typography variant="h2">Super Loadboard</Typography>
          </Box>
        </Toolbar>
      }
    >
      <Stack space="small">
        <SuperLoadboardInstantBooking profile={profile} />
        <SuperLoadboardHideContacts profile={profile} />

        <FeatureToggle name="slb-settings.auto-repost-toggle.declined.ui">
          <SuperLoadboardAutoRepostDeclined profile={profile} />
        </FeatureToggle>

        <FeatureToggle name="slb-settings.auto-repost-toggle.cancelled.ui">
          <SuperLoadboardAutoRepostCancelled profile={profile} />
        </FeatureToggle>

        <SuperLoadboardPrivateNetwork profile={profile} />
      </Stack>
    </PageLayout>
  );
}
