import { Divider, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import {
  calculatePriceNegotiationInfo,
  PriceNegotiationFormDTO,
} from 'orders/data/dto/PriceNegotiationDTO';
import { ReactNode, useMemo } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { Order } from 'shared/types/order';
import { DefinitionListRowLegacy } from 'shared/ui/DefinitionList';

interface PriceNegotiationPredictionProps {
  order: Order;
  priceNegotiation: PriceNegotiationFormDTO;
}

function PriceItem({
  label,
  value = 0,
}: {
  label: ReactNode;
  value: number | undefined;
}) {
  return (
    <DefinitionListRowLegacy
      label={label}
      value={formatCurrency(Number(value), {
        maximumFractionDigits: 0,
      })}
    />
  );
}

export function PriceNegotiationPrediction({
  priceNegotiation,
  order,
}: PriceNegotiationPredictionProps) {
  const {
    maxDay,
    maxDate,
    maxPrice,
    maxIteration,
    calcNthDay,
    calcNthPrice,
    calcNthDate,
    currentPrice,
    currentDay,
    currentDate,
    currentIteration,
  } = useMemo(
    () => calculatePriceNegotiationInfo(order.price, priceNegotiation),
    [order.price, priceNegotiation],
  );

  const predictions = useMemo(
    () =>
      [1, 2, 3]
        .filter((iteration) => iteration <= maxIteration)
        .map((iteration) => ({
          day: calcNthDay(iteration),
          date: calcNthDate(iteration),
          price: calcNthPrice(iteration),
        })),
    [calcNthDate, calcNthDay, calcNthPrice, maxIteration],
  );

  return (
    <Box
      borderColor="Silver400"
      borderWidth="small"
      padding="small"
      borderRadius="small"
    >
      <Stack space="small">
        <Typography variant="h5">Acceptable Bid Schedule</Typography>

        {predictions.map(({ price, date, day }, index) => (
          <PriceItem
            key={index}
            value={price}
            label={`Day ${day} ∙ ${formatDate(date, { variant: 'ShortDate' })}`}
          />
        ))}

        <Divider />

        {currentIteration > 0 && (
          <PriceItem
            value={currentPrice}
            label={`Day ${currentDay} ∙ ${formatDate(currentDate, {
              variant: 'ShortDate',
            })} - Current Price`}
          />
        )}

        <PriceItem
          value={maxPrice}
          label={`Day ${maxDay} ∙ ${formatDate(maxDate, {
            variant: 'ShortDate',
          })} - Max Price`}
        />
      </Stack>
    </Box>
  );
}
