import { PaymentMethod } from '@superdispatch/sdk';
import { AllPaymentTerm } from 'shared/dto/Order/CarrierPaymentDTO';
import { Address } from 'shared/types/address';
import { Load } from 'shared/types/load';
import Order from 'shared/types/order';
import { Venue } from 'shared/types/step';

export function generateLoadNumber(
  orderNumber = '',
  loadNumbers: string[],
): string {
  let index = 1;
  while (loadNumbers.find((number) => number === `${orderNumber}-${index}`)) {
    index++;
  }

  return `${orderNumber}-${index}`;
}

interface GenerateLoadParams {
  order?: Order;
  loadNumbers?: string[];
  pickupVenue?: Venue | null;
  paymentDefaultTerm: AllPaymentTerm;
  paymentDefaultMethod: PaymentMethod;
}

function generateFromVenue(venue?: Venue | null): Address {
  const {
    name = null,
    address = null,
    city = null,
    state = null,
    zip = null,
  } = venue || {};

  return { name, address, city, state, zip };
}

export function generateLoad({
  order,
  loadNumbers = [],
  paymentDefaultTerm,
  paymentDefaultMethod,
  pickupVenue = null,
}: GenerateLoadParams): Load {
  return {
    number: generateLoadNumber(order?.number, loadNumbers),
    pickup: {
      date_type: order?.pickup?.date_type || 'estimated',
      venue: generateFromVenue(pickupVenue),
    },
    delivery: {
      date_type: order?.delivery?.date_type || 'estimated',
      venue: generateFromVenue(null),
    },
    payment: {
      terms: paymentDefaultTerm,
      method: paymentDefaultMethod,
    },
    loadboard_instructions: order?.loadboard_instructions || null,
    instructions: order?.instructions || null,
  };
}
