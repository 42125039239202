import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { yupNumber, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const apiSubscriptionPlanSchema = yupObject({
  plan_id: yupString().nullable(),
  plan_name: yupString().required(),
  plan_price: yupNumber().nullable(),
  plan_vehicle_count: yupNumber().required(),
  overage_price_per_vehicle: yupNumber().nullable(),
  next_billing_date: yupString().nullable(),
  active_till_date: yupString().nullable(),
  total_price: yupNumber().nullable(),
  tax_rate: yupNumber().nullable(),
  tax_juris_code: yupString().nullable(),
});

type ApiSubscriptionPlan = InferType<typeof apiSubscriptionPlanSchema>;

export interface SubscriptionPlan {
  planId: string | null;
  plan: string;
  monthlyPlanPrice: number | null;
  maxPlanVehicles: number;
  perVehiclePrice: number | null;
  renewalDate: string | null;
  activeTillDate: string | null;
  totalPrice: number | null;
  taxPercent: number | null;
  taxJurisCode: string | null;
  isNonStandard: boolean;
}

interface UseSubscriptionPlanReturn {
  plan: SubscriptionPlan | undefined;
  isPlanLoading: boolean;
  planError: Error | null;
}

function toPlan(apiPlan: ApiSubscriptionPlan) {
  return {
    planId: apiPlan.plan_id,
    plan: apiPlan.plan_name,
    monthlyPlanPrice: apiPlan.plan_price,
    maxPlanVehicles: apiPlan.plan_vehicle_count,
    perVehiclePrice: apiPlan.overage_price_per_vehicle,
    renewalDate: apiPlan.next_billing_date,
    activeTillDate: apiPlan.active_till_date,
    totalPrice: apiPlan.total_price,
    taxPercent: apiPlan.tax_rate,
    taxJurisCode: apiPlan.tax_juris_code,
    isNonStandard: apiPlan.plan_id === null,
  };
}

const PLAN_CACHE_KEY = ['subscription-details', 'subscription-plan'] as const;

export function useInvalidateSubscriptionPlanQuery() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(PLAN_CACHE_KEY);
}

export function useSubscriptionPlan(): UseSubscriptionPlanReturn {
  const { requestResource } = useAPI();
  const {
    data: apiSubscriptionPlan,
    isLoading,
    error,
  } = useAPIQuery(
    PLAN_CACHE_KEY,
    () =>
      requestResource(
        'GET /internal/subscription/plan',
        (data) => data as ApiSubscriptionPlan,
      ),
    { schema: apiSubscriptionPlanSchema },
  );

  const plan = apiSubscriptionPlan ? toPlan(apiSubscriptionPlan) : undefined;

  return {
    plan,
    isPlanLoading: isLoading,
    planError: error,
  };
}
