import { Drawer as MuiDrawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns, DrawerTitle } from '@superdispatch/ui';
import styled from 'styled-components';

export const StyledDrawer = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    max-width: 432px;
  }
`;

export interface EditDrawerProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

export function EditDrawer({
  open,
  onClose,
  children,
  title,
}: EditDrawerProps) {
  return (
    <StyledDrawer open={open} onClose={onClose}>
      {title && (
        <DrawerTitle
          title={
            <Columns align="center">
              <Column width="fluid">{title}</Column>
              <Column width="content">
                <IconButton edge="end" onClick={onClose}>
                  <Close />
                </IconButton>
              </Column>
            </Columns>
          }
        />
      )}

      {children}
    </StyledDrawer>
  );
}
