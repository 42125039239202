export const CONSTRAINT_VIOLATION_ERROR = 'CONSTRAINT_VIOLATION_ERROR';
export const SEND_OFFER_ERROR = 'SEND_OFFER_ERROR';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';

export const findMessagesInObject = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key])) {
      const messages = obj[key].map((message) => `${key}: ${String(message)}`);
      return [...acc, ...messages];
    }

    if (typeof obj[key] === 'object') {
      return [...acc, findMessagesInObject(obj[key])];
    }

    if (typeof obj[key] === 'string') {
      return [...acc, obj[key]];
    }

    return acc;
  }, []);

export function getErrorMessage(error) {
  const { error_id, details, message } = error && (error.data || {});

  if (
    details &&
    (error_id === CONSTRAINT_VIOLATION_ERROR ||
      error_id === SEND_OFFER_ERROR ||
      error_id === VALIDATION_ERROR)
  ) {
    const messages = findMessagesInObject(details);

    return messages.join('\n');
  }

  if (message) {
    return message;
  }

  if (error && typeof error === 'string' && error.includes('Failed to fetch')) {
    return null;
  }

  return 'Something went wrong. Please try again.';
}

function APIErrorAlert(error) {
  const message = getErrorMessage(error);

  if (message) {
    alert(message);
  }
}

export default APIErrorAlert;
