import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { orderDeliveryVerificationSchema } from 'shared/dto/Order/DeliveryVerificationDTO';

export function useDeliveryVerification(orderId: number, enabled: boolean) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['order', 'delivery_verification', { orderId }],
    () =>
      requestResource(
        'GET /internal/orders/delivery_verification/{orderId}',
        (data) => orderDeliveryVerificationSchema.cast(data),
        { orderId },
      ),
    { enabled, refetchOnWindowFocus: false },
  );
}
