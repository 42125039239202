import { Link, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPayAddendum } from '../data/SuperPayAPI';

export function SuperPayTermsConditions() {
  const { data } = useSuperPayAddendum();
  const isAddendumRequired = data?.superpay_addendum_required;

  const trackTermsConditions = (eventName: string) => {
    trackEvent(eventName, {
      utm_medium: 'Business Details Page',
    });
  };

  return (
    <Stack space="small">
      <Typography variant="h3">Terms and Conditions</Typography>
      <Box paddingLeft="xxsmall" paddingRight="xxsmall">
        <Stack space="xsmall">
          <Columns space="xsmall">
            {isAddendumRequired && (
              <Column width="content">
                <Box width="10px">1.</Box>
              </Column>
            )}
            <Column>
              <Typography>
                Super Dispatch uses Moov to collect and verify your data for
                money movement. By clicking “Continue”, you agree to the{' '}
                <Link
                  href="https://moov.io/legal/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                  onClick={() =>
                    trackTermsConditions(
                      'Shipper Opened Moov Privacy Policy Terms',
                    )
                  }
                >
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link
                  href="https://moov.io/legal/platform-agreement/"
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                  onClick={() =>
                    trackTermsConditions(
                      'Shipper Opened Moov Platform Agreement',
                    )
                  }
                >
                  Platform Agreement
                </Link>
                .
              </Typography>
            </Column>
          </Columns>
          {isAddendumRequired && (
            <Columns space="xsmall">
              <Column width="content">
                <Box width="10px">2.</Box>
              </Column>
              <Column>
                <Typography>
                  I agree to the SuperPay terms as outlined in the{' '}
                  <Link
                    href="https://storage.googleapis.com/prod-broker-files/SuperDispatch_SuperPay_Addendum.pdf"
                    target="_blank"
                    rel="noreferrer"
                    color="primary"
                    onClick={() =>
                      trackTermsConditions('Shipper Opened SuperPay Addendum')
                    }
                  >
                    SuperPay Addendum
                  </Link>
                  , offering unlimited daily payouts at a rate of $1 per load,
                  billed monthly.
                </Typography>
              </Column>
            </Columns>
          )}
        </Stack>
      </Box>
      {isAddendumRequired && (
        <Box paddingLeft="xxsmall" paddingRight="xxsmall">
          <FormikCheckboxField
            name="termsConditionsAccept"
            label={<Typography variant="body1">I Accept</Typography>}
          />
        </Box>
      )}
    </Stack>
  );
}
