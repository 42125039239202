import { useQuery } from 'shared/helpers/RouteHelpers';
import { SubscriptionPlanGroupCode } from '../shared/subscription-plans/useSubscriptionPlans';

export function usePlanGroupCode() {
  const [query, setQuery] = useQuery({
    plan: 'express',
  });

  const value = query.plan as SubscriptionPlanGroupCode;

  function updateValue(newValue: SubscriptionPlanGroupCode) {
    setQuery(
      {
        plan: newValue,
      },
      true,
    );
  }

  return [value, updateValue] as const;
}
