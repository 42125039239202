import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';

interface OrderImportErrorMessageProps {
  errors: Record<string, string[]>;
}

export function OrderImportErrorMessage({
  errors,
}: OrderImportErrorMessageProps) {
  return (
    <Stack space="small">
      {Object.keys(errors).map((csvRow, index) => (
        <Stack key={index} space="xxsmall">
          <Typography variant="h5">Row {csvRow}:</Typography>
          {errors[csvRow]?.map((message) => (
            <Typography key={message}>{message}</Typography>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}
