import {
  formatDate,
  formatRelativeTime,
  parseDate,
} from '@superdispatch/dates';
import { formatVehicleType } from '@superdispatch/sdk';
import { RatingStatsDTO } from 'core/ratings/data/CarrierRatingDTO';
import { DateTime } from 'luxon';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { getCurrentTab } from 'shared/layout/AppNavbar';
import { Offer } from 'shared/types/offer';
import Order from 'shared/types/order';
import { Step } from 'shared/types/step';
import { CurrentUser } from 'shared/types/user';
import { joinStrings } from 'shared/utils/StringUtils';
import { ShipperProfileDTO } from '../../../shipper-profile/data/ShipperProfileDTO';
import { OrderRequestDTO } from '../../data/dto/OrderRequestDTO';
import { PriceNegotiationDTO } from '../../data/dto/PriceNegotiationDTO';

export type OrderActionSource =
  | 'Order Edit'
  | 'Order Create'
  | 'Order View'
  | 'Order List'
  | 'Order List Bulk'
  | 'Order Requests'
  | 'Dashboard Order List'
  | 'Import Order'
  | 'SuperPay Now Dialog';

export type SendOfferCarrierSource =
  | 'search_carriers'
  | 'carrier_request'
  | 'suggested_carriers'
  | 'partner_brokers'
  | 'previously_dispatched_carriers';

type OrderActionsAnalyticsEvent =
  | {
      name:
        | '[STMS] Sent Load Offer'
        | '[STMS] Counter Offer Sent'
        | '[STMS] Canceled Load Offer'
        | 'Shipper Declined Request';
      order: Order;
      source: OrderActionSource;
      user: CurrentUser | undefined;
      datesExpanded?: boolean;
      carrierSource?: SendOfferCarrierSource;
      offer?: Partial<Offer>;
      request?: OrderRequestDTO;
      totalRequests?: number;
      totalRequestsNotComplying?: number;
      priceNegotiation?: PriceNegotiationDTO;
      carrier: {
        guid: string;
        rating_stats?: RatingStatsDTO | null;
        is_previously_dispatched?: boolean;
      };
    }
  | {
      order: Order;
      source: OrderActionSource;
      user: CurrentUser | undefined;
      name:
        | '[STMS] Posted to SLB'
        | '[STMS] Posted to CD'
        | '[STMS] Auto Dispatched';
    }
  | {
      order: Order;
      source: OrderActionSource;
      user: CurrentUser | undefined;
      shipper: ShipperProfileDTO | undefined;
      name: '[STMS] Posted to Private Loadboard';
    }
  | {
      order: Order;
      source: OrderActionSource;
      user: CurrentUser | undefined;
      name:
        | 'Shipper Instant Dispatched'
        | 'Shipper Viewed Instant Dispatch Drawer'
        | 'Shipper Clicked Instant Dispatch Available';
      [key: string]: unknown;
    }
  | {
      source: OrderActionSource;
      content?: 'Single Order' | 'Bulk Orders';
      name: 'Method Changed to SuperPay';
    }
  | {
      source: OrderActionSource | 'Bulk Orders';
      name: 'Shipper Super Paid Now';
    }
  | {
      order: Order;
      source: OrderActionSource;
      priceNegotiation: PriceNegotiationDTO;
      name:
        | 'Shipper Added Price Negotiation'
        | 'Shipper Updated Price Negotiation'
        | 'Shipper Disabled Price Negotiation';
    }
  | {
      order: Order;
      source: OrderActionSource;
      name: 'Shipper Marked as Delivery Verified';
    };

function formatLane(lane: Step | undefined) {
  return joinStrings(', ', lane?.venue?.city, lane?.venue?.state);
}

function formatOrderDetails(
  order: Order,
  _user: CurrentUser | undefined,
  prefix = 'order_',
) {
  const { id, status, pickup, delivery, vehicles, created_at, tariff, price } =
    order;

  return {
    [`${prefix}id`]: id,
    [`${prefix}status`]: status,
    [`${prefix}tariff`]: tariff,
    [`${prefix}created_at`]: created_at,
    [`${prefix}price`]: price,
    [`${prefix}age`]: formatRelativeTime(created_at),

    [`${prefix}lane`]: joinStrings(
      ' -> ',
      formatLane(pickup) || null,
      formatLane(delivery) || null,
    ),

    [`${prefix}pickup_name`]: pickup?.venue?.name,
    [`${prefix}pickup_address`]: pickup?.venue?.address,
    [`${prefix}pickup_city`]: pickup?.venue?.city,
    [`${prefix}pickup_state`]: pickup?.venue?.state,
    [`${prefix}pickup_zip`]: pickup?.venue?.zip,
    [`${prefix}pickup_business_type`]: pickup?.venue?.business_type,
    [`${prefix}pickup_date_type`]: pickup?.date_type,
    [`${prefix}pickup_scheduled_at`]: pickup?.scheduled_at,
    [`${prefix}pickup_scheduled_ends_at`]: pickup?.scheduled_ends_at,

    [`${prefix}delivery_name`]: delivery?.venue?.name,
    [`${prefix}delivery_address`]: delivery?.venue?.address,
    [`${prefix}delivery_city`]: delivery?.venue?.city,
    [`${prefix}delivery_state`]: delivery?.venue?.state,
    [`${prefix}delivery_zip`]: delivery?.venue?.zip,
    [`${prefix}delivery_business_type`]: delivery?.venue?.business_type,
    [`${prefix}delivery_date_type`]: delivery?.date_type,
    [`${prefix}delivery_scheduled_at`]: delivery?.scheduled_at,
    [`${prefix}delivery_scheduled_ends_at`]: delivery?.scheduled_ends_at,

    [`${prefix}is_posted_to_loadboard`]: order.is_posted_to_loadboard,
    [`${prefix}is_posted_to_centraldispatch`]:
      order.is_posted_to_centraldispatch,

    [`${prefix}vehicle_count`]: vehicles?.length,
    [`${prefix}vehicle_types`]: vehicles
      ?.map((vehicle) => formatVehicleType(vehicle.type))
      .join(', '),
  };
}

export function trackOderActionEvent(event: OrderActionsAnalyticsEvent) {
  switch (event.name) {
    case '[STMS] Sent Load Offer':
    case '[STMS] Counter Offer Sent':
    case '[STMS] Canceled Load Offer':
    case 'Shipper Declined Request': {
      const {
        order,
        source,
        user,
        offer,
        carrier,
        carrierSource,
        request,
        priceNegotiation,
        totalRequests,
        totalRequestsNotComplying,
      } = event;
      trackEvent(event.name, {
        offer_id: offer?.id,
        offer_price: offer?.price,
        offer_type: source === 'Order Requests' ? 'request' : 'direct',

        utm_medium: source,

        sent_at_time: DateTime.local()
          .startOf('second')
          .toISO({ suppressMilliseconds: true }),

        carrier_source: carrierSource,
        carrier_guid: carrier.guid,
        carrier_is_previously_dispatched: carrier.is_previously_dispatched,
        carrier_overall_rating: carrier.rating_stats?.overall_rating,
        carrier_total_rating: carrier.rating_stats?.total_rating_count,
        carrier_requested_pickup_date: request?.pickup_date,
        carrier_requested_delivery_date: request?.delivery_date,
        //sent insurance_requirements_met only if largest_trailer_capacity is set
        insurance_requirements_met: request?.largest_trailer_capacity
          ? request.insurance_requirements_met
          : undefined,
        total_requests: totalRequests,
        total_requests_not_complying: totalRequestsNotComplying,

        is_bid: source === 'Order Requests',
        bid_price: request?.price,
        bid_price_delta:
          !request || !order.price ? undefined : request.price - order.price,

        is_price_negotiation: !!priceNegotiation,
        is_counter_offer: !!offer?.is_counter_offer,
        dates_payment_expanded: event.datesExpanded,
        ...formatOrderDetails(order, user),
      });
      break;
    }
    case '[STMS] Posted to SLB':
    case '[STMS] Posted to CD':
    case '[STMS] Auto Dispatched': {
      const { order, source, user } = event;
      trackEvent(event.name, {
        utm_medium: source,
        ...formatOrderDetails(order, user),
      });
      break;
    }
    case '[STMS] Posted to Private Loadboard': {
      const { order, source, user, shipper } = event;
      trackEvent(event.name, {
        utm_medium: source,
        private_loadboard_duration: shipper?.private_loadboard_duration,
        ...formatOrderDetails(order, user),
      });
      break;
    }
    case 'Shipper Viewed Instant Dispatch Drawer':
    case 'Shipper Clicked Instant Dispatch Available':
    case 'Shipper Instant Dispatched': {
      const { order, source, user, name, ...rest } = event;

      trackEvent(event.name, {
        utm_medium: source,
        lane_pickup: formatLane(order.pickup),
        lane_delivery: formatLane(order.delivery),
        ...rest,
        ...formatOrderDetails(order, user),
      });
      break;
    }
    case 'Method Changed to SuperPay': {
      const { source, content } = event;

      trackEvent(event.name, {
        tab: getCurrentTab(),
        utm_medium: source,
        utm_content: content,
      });
      break;
    }
    case 'Shipper Super Paid Now': {
      const { source } = event;

      trackEvent(event.name, {
        tab: getCurrentTab(),
        utm_medium: source,
      });
      break;
    }
    case 'Shipper Added Price Negotiation':
    case 'Shipper Disabled Price Negotiation':
    case 'Shipper Updated Price Negotiation': {
      const { order, source, priceNegotiation } = event;
      const startDate = parseDate(priceNegotiation.start_date, {
        format: 'JodaISO',
      });

      trackEvent(event.name, {
        utm_medium: source,
        acceptable_pickup_days: priceNegotiation.acceptable_pickup_days,
        acceptable_delivery_days: priceNegotiation.acceptable_delivery_days,
        negotiation_budget: priceNegotiation.negotiation_budget,
        has_gradual_increase: !!priceNegotiation.increase_every,
        increase_by: priceNegotiation.increase_by_percent,
        every_days: priceNegotiation.increase_every?.value,
        start_date_from_today: Math.floor(startDate.diffNow().as('days')),
        start_date: formatDate(priceNegotiation.start_date, {
          variant: 'Date',
        }),
        ...formatOrderDetails(order, undefined),
      });
      break;
    }
    case 'Shipper Marked as Delivery Verified': {
      const { order, source } = event;
      trackEvent(event.name, {
        tab: getCurrentTab(),
        utm_medium: source,
        order_status: order.status,
        payment_method: order.payment?.method,
        payment_term: order.payment?.terms,
        order_count: 1,
      });
      break;
    }
  }
}
