import { Typography } from '@material-ui/core';
import { formatDateType } from '@superdispatch/sdk';
import { Inline } from '@superdispatch/ui';
import { Step } from 'shared/types/step';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';

export function FormattedStep({ step, label }: { label: string; step?: Step }) {
  return (
    <Inline>
      <InlineBulletItems>
        {step?.date_type === 'estimated' ? (
          <DetailedFormattedDateRange
            range={[step.scheduled_at, step.scheduled_ends_at]}
          />
        ) : (
          <DetailedFormattedDate date={step?.scheduled_at} />
        )}

        {formatDateType(step?.date_type)}
      </InlineBulletItems>

      <Typography color="textSecondary">{label}</Typography>
    </Inline>
  );
}
