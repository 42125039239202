import { Divider, Typography } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const PlanPrice = styled(Typography)`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;
const StyledDivider = styled(Divider)`
  background-color: ${Color.Silver500};
`;

export function PaywallPlanInformation() {
  return (
    <Box
      paddingLeft="small"
      paddingRight="small"
      paddingTop="medium"
      paddingBottom="medium"
      borderRadius="small"
      backgroundColor="Blue50"
    >
      <Stack space="xsmall">
        <Columns align="center" space="small">
          <Column width="fluid">
            <Stack space="xxsmall">
              <Typography variant="h6" color="textSecondary">
                PLAN
              </Typography>
              <Typography variant="body1">
                up to 50 delivered vehicles
              </Typography>
            </Stack>
          </Column>
          <Column width="content">
            <Stack space="xxsmall">
              <Inline verticalAlign="bottom" space="none">
                <PlanPrice>$129</PlanPrice>
                <Typography variant="h5" color="textSecondary">
                  /month
                </Typography>
              </Inline>

              <Typography color="textSecondary">Billed monthly</Typography>
            </Stack>
          </Column>
        </Columns>
        <StyledDivider />

        <Typography>
          You will be charged <b>$2</b> per vehicle over 50
        </Typography>
      </Stack>
    </Box>
  );
}
