import Link from '@material-ui/core/Link';
import { Help, ThumbDown, ThumbUp } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import {
  Color,
  Column,
  Columns,
  InfoTooltip,
  Inline,
  Stack,
  Tag,
  useUID,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { trackRatings } from './data/ShipperProfileAnalytics';
import { ShipperRatingDTO } from './data/ShipperRatingDTO';

// TODO add white-space option to TextBox
const RawText = styled(TextBox)`
  white-space: break-spaces;
`;

const IconWrapper = styled.div`
  padding: 12px;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  &[data-variant='success'] {
    color: ${Color.Green300};
    background-color: ${Color.Green50};
  }

  &[data-variant='error'] {
    color: ${Color.Red300};
    background-color: ${Color.Red50};
  }
`;
interface ShipperRatingProps {
  rating: ShipperRatingDTO;
}
export function ShipperRating({ rating }: ShipperRatingProps) {
  const uid = useUID();

  return (
    <Columns aria-labelledby={uid} space="small" align="top">
      <Column width="content">
        {rating.rating === 'POSITIVE' ? (
          <IconWrapper data-variant="success">
            <ThumbUp color="inherit" fontSize="small" />
          </IconWrapper>
        ) : (
          <IconWrapper data-variant="error">
            <ThumbDown color="inherit" fontSize="small" />
          </IconWrapper>
        )}
      </Column>

      <Column>
        <Stack>
          <Stack space="small">
            <Stack space="xsmall">
              <Columns
                collapseBelow="desktop"
                space="xsmall"
                align={['top', 'top', 'center']}
              >
                <Column>
                  <Stack space="xxsmall">
                    <Inline aria-label="shipper name">
                      <TextBox id={uid}>
                        <Link
                          component={RouterLink}
                          target="_blank"
                          to={`/manage-carriers/${rating.carrier_guid}?utm_medium=Shipper Profile&utm_content=Ratings Received`}
                        >
                          {rating.name}
                        </Link>
                      </TextBox>
                      {rating.status === 'PENDING' && (
                        <Inline verticalAlign="center" space="xxsmall">
                          <Tag
                            variant="subtle"
                            color="purple"
                            aria-label="Pending Tag"
                          >
                            Pending
                          </Tag>
                          <InfoTooltip
                            title={
                              <>
                                Pending ratings are visible to everyone but with
                                hidden details.
                                <br />
                                <br />
                                Details are published after 10 days to allow
                                both sides to resolve the issue.
                              </>
                            }
                          />
                        </Inline>
                      )}
                    </Inline>
                    <TextBox
                      display="block"
                      variant="caption"
                      color="secondary"
                      aria-label="rated at"
                    >
                      <FormattedDate
                        variant="Date"
                        format="JodaISO"
                        date={rating.rated_at}
                      />
                    </TextBox>
                  </Stack>
                </Column>
              </Columns>
              {rating.rating === 'NEGATIVE' && rating.status === 'PUBLISHED' && (
                <Inline space="xxsmall" verticalAlign="center">
                  <Help fontSize="small" color="action" />
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.superdispatch.com/en/articles/6597062-how-can-i-dispute-a-negative-rating"
                    onClick={() => {
                      trackRatings('Shipper Clicked How To Dispute Link');
                    }}
                  >
                    How to dispute a rating
                  </Link>
                </Inline>
              )}
            </Stack>
            {(rating.tags.length > 0 || !!rating.comment) && (
              <Stack space="xsmall">
                {rating.tags.length > 0 && (
                  <Inline aria-label="tags">
                    {(
                      [
                        'ON_TIME_PAYMENT',
                        'GOOD_COMMUNICATION',
                        'ACCURATE_INFORMATION',
                        'LATE_PAYMENT',
                        'NON_PAYMENT',
                        'PARTIAL_PAYMENT',
                        'INACCURATE_INFORMATION',
                        'VEHICLE_NOT_READY_MISSING',
                      ] as const
                    ).map((tag) =>
                      !rating.tags.includes(tag) ? null : (
                        <Tag key={tag} variant="subtle" color="grey">
                          {ShipperRatingDTO.formatRatingTag(tag)}
                        </Tag>
                      ),
                    )}
                  </Inline>
                )}
                {!!rating.comment && (
                  <Box maxWidth="680px">
                    <RawText color="secondary" aria-label="comment">
                      {rating.comment}
                    </RawText>
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Column>
    </Columns>
  );
}
