import Cookies from 'js-cookie';
import { getCookie } from 'shared/helpers/CookieHelpers';

export function getDeviceGuid(username: string): string | undefined {
  return getCookie(`user_device_${username}`);
}

export function setDeviceGuid(username: string, deviceGuid: string): void {
  Cookies.set(`user_device_${username}`, deviceGuid, {
    secure: true,
    expires: 14,
  });
}
