import { ButtonGroupProps, MenuItem } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, ButtonVariantProp } from '@superdispatch/ui-lab';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { MouseEvent, MutableRefObject, useRef, useState } from 'react';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';
import { SuperPayScheduleCalendar } from './SuperPayScheduleCalendar';
import { SuperPayScheduleDialog } from './SuperPayScheduleDialog';

interface SuperPayScheduleProps {
  onScheduleSuperPayNow: (expectedDate: string) => void;
  onScheduleSuccess?: () => void;
  onOpenScheduleCalendar: () => void;
}

type ScheduleStep = 'calendar' | 'dialog' | null;

function useSchedule({
  onScheduleSuperPayNow,
  onScheduleSuccess,
  onOpenScheduleCalendar,
}: SuperPayScheduleProps) {
  const [scheduleStep, setScheduleStep] = useState<ScheduleStep>(null);

  const { addSnackbar } = useSnackbarStack();

  const onScheduleStep = (step: ScheduleStep) => {
    if (step === 'calendar') {
      onOpenScheduleCalendar();
    }
    setScheduleStep(step);
  };

  const formik = useFormikEnhanced({
    initialValues: { expected_date: '' },
    onSubmit: ({ expected_date }) => onScheduleSuperPayNow(expected_date),
    onSubmitSuccess: () => {
      addSnackbar('Status changed successfully', { variant: 'success' });
      setScheduleStep(null);
      onScheduleSuccess?.();
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
  });

  return {
    setScheduleStep: onScheduleStep,
    formik,
    scheduleStep,
  };
}

interface SuperPayScheduleFormContentProps {
  formik: FormikContextType<{ expected_date: string }>;
  scheduleStep: ScheduleStep;
  setScheduleStep: (step: ScheduleStep) => void;
  onScheduleSuperPayNow: (expectedDate: string) => void;
  anchorRef: MutableRefObject<HTMLButtonElement | null>;
  canScheduleConfirmDialog: boolean;
  ordersCount?: number;
  totalCharge?: number;
}

function SuperPayScheduleFormContent({
  formik,
  setScheduleStep,
  anchorRef,
  canScheduleConfirmDialog,
  ordersCount,
  totalCharge,
  scheduleStep,
}: SuperPayScheduleFormContentProps) {
  return (
    <FormikProvider value={formik}>
      <Form>
        <SuperPayScheduleCalendar
          onClose={() => setScheduleStep(null)}
          anchorRef={anchorRef.current}
          isOpen={scheduleStep === 'calendar'}
          onClick={
            canScheduleConfirmDialog
              ? () => setScheduleStep('dialog')
              : formik.handleSubmit
          }
          actionButtonTitle={
            canScheduleConfirmDialog ? 'Continue' : 'Schedule SuperPay'
          }
          isLoading={formik.isSubmitting}
        />
        <SuperPayScheduleDialog
          ordersCount={ordersCount}
          totalCharge={totalCharge}
          isOpen={scheduleStep === 'dialog'}
          onClick={formik.handleSubmit}
          onCancel={() => setScheduleStep(null)}
          isLoading={formik.isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}

interface SuperPayNowMenuButtonsProps {
  canScheduleSuperPay: boolean;
  canScheduleConfirmDialog: boolean;
  totalCharge?: number;
  onSuperPayNow: (event: MouseEvent) => void;
  onScheduleSuperPayNow: (expectedDate: string) => void;
  ordersCount?: number;
  onScheduleSuccess?: () => void;
  onOpenScheduleCalendar: () => void;
  disabled?: boolean;
}

export function SuperPayNowMenuButtons({
  totalCharge,
  canScheduleSuperPay,
  canScheduleConfirmDialog,
  onSuperPayNow,
  onScheduleSuperPayNow,
  ordersCount,
  onScheduleSuccess,
  onOpenScheduleCalendar,
  disabled,
}: SuperPayNowMenuButtonsProps) {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { setScheduleStep, formik, scheduleStep } = useSchedule({
    onScheduleSuperPayNow,
    onScheduleSuccess,
    onOpenScheduleCalendar,
  });

  return canScheduleSuperPay ? (
    <Box>
      <MenuItem
        disabled={disabled}
        onClick={onSuperPayNow}
        data-intercom-target="superpay now button"
      >
        SuperPay Now
      </MenuItem>
      <Box ref={anchorRef}>
        <MenuItem
          disabled={disabled}
          onClick={() => setScheduleStep('calendar')}
        >
          Schedule SuperPay
        </MenuItem>
      </Box>
      <SuperPayScheduleFormContent
        anchorRef={anchorRef}
        canScheduleConfirmDialog={canScheduleConfirmDialog}
        formik={formik}
        onScheduleSuperPayNow={onScheduleSuperPayNow}
        scheduleStep={scheduleStep}
        setScheduleStep={setScheduleStep}
        ordersCount={ordersCount}
        totalCharge={totalCharge}
      />
    </Box>
  ) : (
    <MenuItem
      disabled={disabled}
      onClick={onSuperPayNow}
      data-intercom-target="superpay now button"
    >
      SuperPay Now
    </MenuItem>
  );
}

interface SuperPayNowGroupButtonsProps {
  canScheduleSuperPay: boolean;
  canScheduleConfirmDialog: boolean;
  totalCharge?: number;
  onSuperPayNow: (event: MouseEvent) => void;
  onScheduleSuperPayNow: (expectedDate: string) => void;
  buttonType?: ButtonVariantProp;
  buttonGroupType?: ButtonGroupProps['variant'];
  ordersCount?: number;
  onScheduleSuccess?: () => void;
  onOpenScheduleCalendar: () => void;
}

export function SuperPayNowGroupButtons({
  totalCharge,
  canScheduleSuperPay,
  canScheduleConfirmDialog,
  onSuperPayNow,
  onScheduleSuperPayNow,
  onScheduleSuccess,
  onOpenScheduleCalendar,
  ordersCount,
  buttonType = 'default',
  buttonGroupType = 'contained',
}: SuperPayNowGroupButtonsProps) {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { setScheduleStep, formik, scheduleStep } = useSchedule({
    onScheduleSuperPayNow,
    onScheduleSuccess,
    onOpenScheduleCalendar,
  });

  return canScheduleSuperPay ? (
    <Box>
      <Box ref={anchorRef}>
        <ButtonGroupDropdown
          data-intercom-target="superpay now button"
          label="SuperPay Now"
          color="primary"
          variant={buttonGroupType}
          onClick={onSuperPayNow}
        >
          <MenuItem onClick={() => setScheduleStep('calendar')}>
            Schedule SuperPay
          </MenuItem>
        </ButtonGroupDropdown>
      </Box>
      <SuperPayScheduleFormContent
        anchorRef={anchorRef}
        canScheduleConfirmDialog={canScheduleConfirmDialog}
        formik={formik}
        onScheduleSuperPayNow={onScheduleSuperPayNow}
        scheduleStep={scheduleStep}
        setScheduleStep={setScheduleStep}
        ordersCount={ordersCount}
        totalCharge={totalCharge}
      />
    </Box>
  ) : (
    <Button
      data-intercom-target="superpay now button"
      onClick={onSuperPayNow}
      variant={buttonType}
    >
      SuperPay Now
    </Button>
  );
}
