import { useEffect } from 'react';

export function useFocusInput(name: string | undefined) {
  useEffect(() => {
    if (name) {
      const input = document.querySelector<HTMLInputElement>(`[name=${name}]`);

      input?.focus();
    }
  }, [name]);
}

interface Props {
  name: string | undefined;
}

export function FocusInput({ name }: Props) {
  useFocusInput(name);

  return null;
}
