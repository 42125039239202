import { Delete, Edit } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Link } from 'react-router-dom';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import Order from 'shared/types/order';
import { useSingleOrderActionAPI } from '../data/OrderActionAPI';

interface OrderImportListItemActionsProps {
  order: Order;
  onDeleteSuccess: () => void;
}

export function OrderImportListItemActions({
  order,
  onDeleteSuccess,
}: OrderImportListItemActionsProps) {
  const { addSnackbar } = useSnackbarStack();
  const { deleteOrder } = useSingleOrderActionAPI();

  const deleteForm = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => deleteOrder(order.id),
    onSubmitSuccess() {
      onDeleteSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  return (
    <Columns space="xsmall">
      <Column width="content">
        <Link to={`/orders/edit/${order.guid}`} aria-label="Edit">
          <Button variant="outlined">
            <Edit color="action" />
          </Button>
        </Link>
      </Column>
      <Column width="content">
        <Button
          variant="outlined"
          onClick={() => deleteForm.handleSubmit()}
          isLoading={deleteForm.isSubmitting}
          aria-label="Delete"
        >
          <Delete color="action" />
        </Button>
      </Column>
    </Columns>
  );
}
