import styled from 'styled-components';
import {
  SubscriptionPlanCard,
  SubscriptionPlanCardProps,
} from './SubscriptionPlanCard';
import { useSubscriptionPlanPrices } from './useSubscriptionPlanPrices';

const StyledSubscriptionPlanCard = styled(SubscriptionPlanCard)`
  padding-top: 44px;
`;

const features = [
  {
    title: 'Super Loadboard',
    helpText:
      'Marketplace connecting shippers with trusted carriers instantly, powered by Al suggestions to get loads moved faster.',
  },
  {
    title: 'Order Management',
    helpText:
      'Simplify order management: effortlessly create, import, edit, cancel, and send direct offers.',
  },
  {
    title: 'User Management',
    helpText:
      'Easily add, remove, and manage accounts and access based on user roles within the platform.',
  },
  {
    title: 'GPS Load Tracking & ETA',
    helpText: `Track every vehicle's location and status in real-time to ensure timely deliveries.`,
  },
  {
    title: 'Order Status Notification',
    helpText:
      'Keep track of every load with real-time tracking, ETA updates, and status notifications, ensuring complete visibility throughout the shipping process.',
  },
  {
    title: 'Electronic Documentation',
    helpText:
      'Instant access to Electronic Bills of Lading (eBOL), inspection photos, and invoices, providing you with the documentation you need when you need it.',
  },
  {
    title: 'Pricing Recommendations',
    helpText:
      'Receive a recommended price on new orders to move loads fast at the most competitive rates.',
  },
  {
    title: 'SuperPay',
    helpText:
      'Accelerate carrier payments using our integrated payment solution without the need to exchange bank account details.',
  },
  {
    title: 'Verified Carrier Network',
    helpText:
      'Instant access to thousands of trusted carriers, all rigorously monitored to ensure active authority and cargo insurance coverage.',
  },
  {
    title: 'Carrier Ratings & Reviews',
    helpText:
      'Make informed carrier decisions by leveraging real feedback from previous shippers who have conducted business with them.',
  },
] as const;

interface ExpressSubscriptionPlanCardProps {
  ctaText?: SubscriptionPlanCardProps['ctaText'];
  ctaVariant?: SubscriptionPlanCardProps['ctaVariant'];
  onCtaClick: SubscriptionPlanCardProps['onCtaClick'];
}

export function ExpressSubscriptionPlanCard({
  ctaText = 'Select Plan',
  ctaVariant = 'neutral',
  onCtaClick,
}: ExpressSubscriptionPlanCardProps) {
  const { prices, isSubscriptionPlansLoading } = useSubscriptionPlanPrices([
    'express',
  ]);

  return (
    <StyledSubscriptionPlanCard
      title="Shipper Express"
      isFixedPrice={true}
      price={prices?.express}
      priceLoading={isSubscriptionPlansLoading}
      description="Essential tools designed to streamline moving cars for shippers moving 50 VINs or less per month, making the process easier and more efficient."
      ctaText={ctaText}
      ctaVariant={ctaVariant}
      onCtaClick={onCtaClick}
      featuresTitle="Shipper Express Features"
      features={features}
      notice={['Up to 50 VINs (month)', '$2 per VIN additional usage']}
      vins={50}
    />
  );
}
