import {
  Exclude,
  Expose,
  Transform,
  TransformationType,
} from 'class-transformer';
import {
  DictionaryCarrierDTO,
  DictionaryPlaceDTO,
} from 'core/dictionary/data/DictionaryDTO';
import { TransformNumber } from 'shared/utils/TransformUtils';

function TransformTags() {
  return Transform((value: unknown[] | undefined, _, transformationType) => {
    const parsed = value?.map(Number);

    return transformationType === TransformationType.CLASS_TO_PLAIN
      ? parsed
      : parsed?.map(String);
  });
}

@Exclude()
export class OrderRequestsParamsDTO {
  @Expose()
  @TransformNumber()
  size?: number;

  @Expose()
  @TransformNumber()
  page?: number;

  @Expose() sort?: string;
  @Expose() order_guid?: string;

  @Expose() statuses?: string[];
  @Expose() payment_statuses?: string[];
  @Expose() carrier_guids?: string[];
  @Expose() carriers?: DictionaryCarrierDTO[];
  @Expose() customer_names?: string[];
  @Expose() dispatcher_names?: string[];
  @Expose() salespersons?: string[];
  @Expose() lot_numbers?: string[];
  @Expose() contact_names?: string[];

  @Expose()
  @TransformTags()
  tags?: string[];

  @Expose() pickup_addresses?: DictionaryPlaceDTO[];
  @Expose() delivery_addresses?: DictionaryPlaceDTO[];

  @Expose() delivered_on_start_date?: string;
  @Expose() delivered_on_end_date?: string;
  @Expose() delivered_on_date?: string[];

  @Expose() is_superpay_available_carrier?: string[];
  @Expose() is_carrier_requested_superpay?: string[];
  @Expose() is_expedited_payment?: string[];
  @Expose() payment_methods?: string[];
}
