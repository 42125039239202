import { Link } from '@material-ui/core';
import { MouseEvent } from 'react';

export function EmptyField({
  onClick,
  isDisabled,
  children,
}: {
  onClick: (
    e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
  ) => void;
  isDisabled: boolean;
  children: JSX.Element;
}) {
  if (isDisabled) {
    return (
      <Link
        component="button"
        underline="none"
        color="primary"
        onClick={onClick}
      >
        Add
      </Link>
    );
  }
  return children;
}
