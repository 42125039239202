import { Typography } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import Order from 'shared/types/order';
import { Divider } from 'shared/ui/Divider';
import { NoData } from './NoData';
import { OrderViewContactInfo } from './OrderViewContactInfo';
import { OrderViewDriverInfo } from './OrderViewDriverInfo';
import { OrderViewVenue } from './OrderViewVenue';

export interface Props {
  order: Order;
}

function OrderViewCarrierInfo({ order: { active_offer } }: Props) {
  const {
    carrier_name: name = null,
    carrier_usdot: usdot = null,
    carrier_guid: guid = null,
    carrier_contact_name: contactName = null,
    carrier_phone: phone = null,
    carrier_email: email = null,
    driver_name: driverName = null,
    driver_phone: driverPhone = null,
  } = active_offer || {};

  return (
    <Flex flexDirection="column" aria-label="carrier section">
      <Typography variant="h3">Carrier</Typography>

      {name || contactName || phone || email || driverName || driverPhone ? (
        <>
          <OrderViewVenue
            mt={2}
            name={name}
            info={usdot}
            guid={guid}
            type="CARRIER"
          />

          <OrderViewContactInfo
            name={contactName}
            phone={phone}
            email={email}
            ContainerProps={{ mt: 3 }}
          />

          {(driverName || driverPhone) && (
            <>
              <Divider my={3} />

              <OrderViewDriverInfo name={driverName} phone={driverPhone} />
            </>
          )}
        </>
      ) : (
        <NoData my={3} text="No carrier data yet" />
      )}
    </Flex>
  );
}

export default OrderViewCarrierInfo;
