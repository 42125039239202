import { WhoAmI } from 'shared/types/user';

type ApplicationType = 'customer-portal' | 'broker';

export function getAppType(): ApplicationType {
  const localAppType = localStorage.getItem('APP_TYPE');

  if (import.meta.env.MODE === 'development' && localAppType) {
    return localAppType as ApplicationType;
  }

  return window.location.host.includes('portal') ? 'customer-portal' : 'broker';
}

export function isInternalUser(user: WhoAmI) {
  return user.id === 0;
}
