import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import illustration from '../assets/verification-underway.png';
import { getVerificationBackUrl } from '../core/VerificationBackUrl';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: ${Color.White};
  padding: 16px;
`;

export function VerificationUnderwayPage() {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Container>
        <img src={illustration} alt="illustration" width="250" />
        <Stack align="center" space="xsmall">
          <Typography variant="h1">Verification underway!</Typography>
          <Typography color="textSecondary" align="center">
            Our compliance team will get back to you within 24 to 48 hours
          </Typography>
        </Stack>
        <Button
          onClick={() => {
            trackVerificationApplicationEvent({
              name: 'Shipper Clicked Finish and Continue',
            });
            navigate(getVerificationBackUrl());
          }}
        >
          Finish
        </Button>
      </Container>
    </PageLayout>
  );
}
