export type Printable = null | string | number | boolean | undefined;

export function trim(value: unknown): string {
  return value == null ? '' : String(value).trim();
}

export function compactStringArray(values: Printable[]): string[] {
  return values.reduce<string[]>((acc, value) => {
    if (value != null && value !== false) {
      const text = trim(value);

      if (text) {
        acc.push(text);
      }
    }

    return acc;
  }, []);
}

export function joinStrings(
  separator: string,
  ...args: Array<null | string | number | boolean | undefined>
): string {
  return compactStringArray(args).join(separator);
}

export const ONLY_DIGITS_PATTERN = /^\d+$/;
export function hasOnlyDigits(input: unknown): boolean {
  return typeof input == 'string' && ONLY_DIGITS_PATTERN.test(input);
}
