import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';

export function DisplayCard({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Typography variant="h3">{title}</Typography>

          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
