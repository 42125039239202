import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type EditUserDTO = InferType<typeof editUserDTO>;

export const editUserDTO = yupObject({
  first_name: yupString().nullable(),
  last_name: yupString().nullable(),
  username: yupString().required(),
  email: yupString().email().nullable(),
  phone: yupString().nullable(),
  role_id: yupString().nullable(),
  display_user_contact_to_carriers: yupBoolean(),
});
