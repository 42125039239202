import { Typography } from '@material-ui/core';
import { Box, Flex } from '@rebass/grid';
import { useUID } from '@superdispatch/ui';
import * as React from 'react';

export interface DetailProps {
  label: string;
  value: React.ReactNode;
}

export function OrderActivityDetail({ label, value }: DetailProps) {
  const uid = useUID();
  return (
    <Flex>
      <Box flex="0 0 60px">
        <Typography id={uid} variant="body1" color="textSecondary">
          {label}
        </Typography>
      </Box>

      <Box aria-labelledby={uid}>{value}</Box>
    </Flex>
  );
}
