import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useDeliveryVerification } from 'orders/data/OrderDeliveryVerificationAPI';
import { useState } from 'react';
import { OrderDTO } from 'shared/dto/Order/OrderDTO';
import Order from 'shared/types/order';
import styled from 'styled-components';

const OnHoldReasonList = styled.ul`
  padding-inline-start: 24px;
  margin: 0;
`;
const OnHoldReasonListItem = styled.li`
  margin-bottom: 4px;
`;

const SpinnedContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface OnHoldStatusProps {
  order: Order;
}

export function OrderPaymentOnHoldStatus({ order }: OnHoldStatusProps) {
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const { data, isLoading } = useDeliveryVerification(order.id, isTooltipOpen);
  const superPay = order.payment?.super_pay;

  return (
    <Inline space="xxsmall" verticalAlign="center">
      <Tag variant="subtle" color="yellow">
        Payment on Hold
      </Tag>
      {superPay?.is_manual_hold ? (
        <Tooltip
          PopperProps={{
            style: { boxSizing: 'content-box' },
          }}
          title={
            <Box width="300px">
              <Stack space="small">
                <Stack space="xsmall">
                  <Box color="Yellow400">
                    <Typography variant="h5">
                      Shipper put Payment on Hold
                    </Typography>
                  </Box>
                  {superPay.custom_on_hold_reason ? (
                    <Box>{superPay.custom_on_hold_reason}</Box>
                  ) : (
                    <Box color="Dark100">Reason wasn’t provided.</Box>
                  )}
                </Stack>
                <Box>
                  <Columns space="xsmall">
                    <Column width="content">
                      <Box top="2px" position="relative">
                        <Info fontSize="small" color="action" />
                      </Box>
                    </Column>
                    <Column>
                      <Box color="Dark100">
                        Payment didn’t initiate automatically due to reasons
                        above. Resume payment manually after confirming the
                        delivery.
                      </Box>
                    </Column>
                  </Columns>
                </Box>
              </Stack>
            </Box>
          }
        >
          <Info color="action" fontSize="small" />
        </Tooltip>
      ) : (
        <Tooltip
          onOpen={() => {
            setTooltipOpen(true);
          }}
          title={
            <Box width="218px">
              {isLoading ? (
                <SpinnedContainer>
                  <CircularProgress size={20} />
                </SpinnedContainer>
              ) : (
                <Stack space="xsmall">
                  <Typography>
                    Payment didn’t initiate automatically due to:
                  </Typography>
                  <OnHoldReasonList>
                    {data?.fail_reasons?.map((reason) => (
                      <OnHoldReasonListItem key={reason}>
                        {OrderDTO.getDeliveryVerificationFailureReasonText(
                          reason,
                        )}
                      </OnHoldReasonListItem>
                    ))}
                  </OnHoldReasonList>
                  <Typography>
                    Initiate payment manually after confirming the delivery and
                    reviewing the price.
                  </Typography>
                </Stack>
              )}
            </Box>
          }
        >
          <Info color="action" fontSize="small" />
        </Tooltip>
      )}
    </Inline>
  );
}
