import { useEffect } from 'react';
import { formatDictionary } from './data/DictionaryDTO';
import { InputControl, InputControlProps } from './InputControl';

interface Props extends Omit<InputControlProps, 'label'> {
  value?: string[];
  onChange: (value: string[]) => void;
}

export function PaymentMethodAutocomplete(props: Props) {
  const { value, onChange, onSelect, ...rest } = props;

  useEffect(() => {
    if (!value) {
      // Right now, we are using the filter only for the "superpay" method
      onChange(['superpay']);
      onSelect();
    }
  }, [onChange, onSelect, value]);

  return (
    <InputControl
      {...rest}
      active={false}
      label={formatDictionary('payment_methods')}
      onSelect={onSelect}
    />
  );
}
