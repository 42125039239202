import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { parseSearchQuery } from 'shared/helpers/RouteHelpers';
import { ShipperProfileDTO } from './ShipperProfileDTO';

type UpdatePayload = 'updated' | 'removed';

export function trackProfileAvatarUpdate(eventData: {
  profileAvatar: UpdatePayload;
}) {
  trackEventLegacy('Updated Profile Avatar', eventData);
}

type ProfileInfoType =
  | 'About Us'
  | 'Bond Insurance'
  | 'Carrier Requirements'
  | 'Contact Info'
  | 'Load ID'
  | 'Payment Info'
  | 'References'
  | 'Terms and Conditions'
  | 'CD Integration'
  | 'Super Loadboard'
  | 'Hide details';

type UpdateProfileValues = Partial<
  ShipperProfileDTO & { cargo_limit_units_filled: number | null }
>;

export function trackUpdatedProfileValues(
  infoType: ProfileInfoType,
  values: UpdateProfileValues,
) {
  trackEventLegacy(`Updated Profile "${infoType}"`, values);
}

export function trackProfileExternalLinkClick(eventName: string) {
  trackEventLegacy(eventName);
}

export type RatingsAnalyticsEvent =
  | '[STMS] Opened Own Ratings'
  | 'Shipper Clicked How To Dispute Link';

export function trackRatings(name: RatingsAnalyticsEvent) {
  const { utm_medium } = parseSearchQuery(window.location.search);
  trackEvent(name, {
    utm_medium: utm_medium ?? 'Shipper Profile',
  });
}
