import { useSearchInputState } from 'shared/helpers/ReactHelpers';
import { Contact } from 'shared/types/contact';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import styled from 'styled-components';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { useCustomers } from './data/DictionaryAPI';

const Wrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 1px;
    background: #e7e8e9;
  }
`;

const Item = styled.div`
  color: #6f7681;
`;

function formatOption(option: string | Contact): string {
  return typeof option === 'string' ? option : option.name;
}

interface CustomerAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<string | Contact>,
    'options' | 'onChange'
  > {
  onChange: (values: string[]) => void;
}

export function CustomerAutocomplete({
  onChange,
  ...props
}: CustomerAutocompleteProps) {
  const { input, query, setInput } = useSearchInputState();
  const { isLoading, data: { objects = [] } = {} } = useCustomers(query);

  return (
    <AutocompleteBase
      {...props}
      options={objects}
      inputValue={input}
      loading={isLoading}
      placeholder="3 symbols to search"
      onInputChange={(_, value) => setInput(value)}
      onChange={(_, value) => onChange(value.map(formatOption))}
      getOptionLabel={(option) => formatOption(option)}
      renderOption={(option: string | Contact) =>
        typeof option === 'string' ? (
          option
        ) : (
          <Wrapper>
            <div>{option.name}</div>
            <Item>{makeAddressCityStateZip(option)}</Item>
            <Item>{option.phone}</Item>
            <Item>{option.email}</Item>
          </Wrapper>
        )
      }
    />
  );
}
