import { Card, CardContent, Link } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { Color, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Component, ErrorInfo, ReactNode } from 'react';
import { showNewMessage } from 'shared/helpers/IntercomHelpers';
import { logError } from 'shared/helpers/MonitoringService';
import { AppTheme } from 'shared/theme/AppTheme';

function ErrorScreen() {
  return (
    <AppTheme>
      <Box
        maxWidth="800px"
        marginLeft="auto"
        marginRight="auto"
        marginTop={['small', 'xxlarge']}
      >
        <Card>
          <CardContent>
            <Box paddingTop="xxlarge" paddingBottom="xxlarge">
              <Stack align="center" space="medium">
                <Stack align="center" space="small">
                  <WarningRounded
                    htmlColor={Color.Yellow400}
                    style={{ fontSize: '54px' }}
                  />

                  <Stack align="center">
                    <TextBox variant="heading-2">Something Went Wrong</TextBox>
                    <TextBox>
                      Reload the page. If this happens again contact{' '}
                      <Link
                        component="button"
                        onClick={() => {
                          showNewMessage('');
                        }}
                      >
                        Support
                      </Link>
                    </TextBox>
                  </Stack>
                </Stack>

                <Button
                  size="large"
                  variant="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload Page
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </AppTheme>
  );
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
    return { error, errorInfo };
  }

  state: State = {};

  componentDidCatch(error: Error) {
    logError(error, 'ErrorBoundary');
  }

  render() {
    if (this.state.error) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}

class FakeErrorBoundary extends Component<ErrorBoundaryProps> {
  render() {
    return this.props.children;
  }
}

export const AppErrorBoundary =
  import.meta.env.MODE === 'production' ? ErrorBoundary : FakeErrorBoundary;
