import { Link, Typography } from '@material-ui/core';
import { parseDate } from '@superdispatch/dates';
import { Color, Inline, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';
import { PackagesInfoDialog } from 'subscription-plans/PackagesInfoDialog';
import { SubscriptionPlanCard } from '../../shared/subscription-plans/SubscriptionPlanCard';
import { useSubscriptionPlanPrices } from '../../shared/subscription-plans/useSubscriptionPlanPrices';
import logo1 from './assets/logo1.png';
import logo2 from './assets/logo2.png';
import logo3 from './assets/logo3.png';

const StyledBrands = styled.div`
  height: 44px;
  background-color: ${Color.Blue50};
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  gap: 8px;
  align-items: center;
`;

const features = [
  {
    title: 'Advanced Order Management',
    helpText:
      'Customize order fields to match your business needs and automate dispatching with instant booking.',
  },
  {
    title: 'Carrier Management',
    helpText:
      'Easily manage carriers, whether they are existing Super Dispatch customers or not, identify preferred carriers, and enable others to book instantly.',
  },
  {
    title: 'Customer Management',
    helpText:
      'Build out your customer database to easily access customer details when creating new orders.',
  },
  {
    title: 'Billing Management',
    helpText:
      'Effortlessly generate, oversee, bill, and process invoices for both customers and carriers instantly through a single platform.',
  },
  {
    title: 'Performance Dashboard',
    helpText:
      'At-a-glance view of essential business metrics, including aging orders, VINs delivered, as well as delayed and scheduled pickups and deliveries.',
  },
  {
    title: 'Reporting',
    helpText:
      'Get instant access to the order and financial data you need to make informed business decisions.',
  },
  {
    title: 'Automated Price Negotiation',
    helpText: `Eliminate negotiation hassles by letting Super Dispatch negotiate on your behalf to secure the lowest carrier rate.`,
  },
] as const;

export function ProSubscriptionPlanCard() {
  const navigate = useNavigate();
  const { prices, isSubscriptionPlansLoading } = useSubscriptionPlanPrices([
    'pro-100',
  ]);

  return (
    <SubscriptionPlanCard
      prepend={
        <StyledBrands>
          {[logo1, logo2, logo3].map((src, index) => (
            <img key={index} src={src} />
          ))}
          <Typography variant="caption">
            Recommended by leading shippers
          </Typography>
        </StyledBrands>
      }
      headerAppend={<TrialTag />}
      highlighted={true}
      title="Shipper Pro"
      price={prices?.['pro-100']}
      priceLoading={isSubscriptionPlansLoading}
      description="Ship cars like a pro, with advanced automation, enhanced visibility, and more operational control to move cars faster and easier."
      ctaText="Choose Your Package"
      ctaVariant="primary"
      onCtaClick={() => {
        navigate('/subscription-plans/shipper-pro?plan=pro-100');
      }}
      featuresTitle="Get everything in Shipper Express +"
      features={features}
      contentAppend={<ViewPackagesButton />}
    />
  );
}

function ViewPackagesButton() {
  const [isOpen, openDialog, closeDialog] = useBoolean(false);

  return (
    <>
      <Box marginTop="xlarge">
        <Inline horizontalAlign="center">
          <Link
            component="button"
            color="primary"
            underline="none"
            onClick={openDialog}
          >
            <Typography component="span" variant="h4">
              View Packages
            </Typography>
          </Link>
        </Inline>
      </Box>
      <PackagesInfoDialog open={isOpen} onClose={closeDialog} />
    </>
  );
}

function TrialTag() {
  const { data } = useSubscriptionDetails();

  if (data?.plan !== 'TRIAL') {
    return null;
  }

  const expirationDate = parseDate(data.chargebee.trial_end);
  const isExpired = expirationDate.diffNow('millisecond').milliseconds < 0;

  if (isExpired) {
    return null;
  }

  const expirationDay = expirationDate.get('day');
  const expirationMonth = expirationDate.get('monthLong');

  return (
    <Tag variant="bold" color="grey">
      Trial Expires on {[expirationMonth, expirationDay].join(' ')}
    </Tag>
  );
}
