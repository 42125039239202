import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { CarrierFullInfo } from 'shared/types/carrier';
import {
  useMarkExpeditePayment,
  useRemoveExpeditePayment,
} from '../data/ExpeditePaymentAPI';
import { CarrierType, useCarriersCache } from '../data/ManageCarriersAPI';

interface ExpeditePaymentButtonProps {
  carrierGuids: string[];
  selectedCarriers: CarrierFullInfo[];
  carrierType: CarrierType;
}

function RemoveExpeditePaymentButton({
  carrierGuids,
  selectedCarriers,
  carrierType,
}: ExpeditePaymentButtonProps) {
  const { invalidateCarriers } = useCarriersCache();
  const { addSnackbar } = useSnackbarStack();

  const { mutate: remove, isLoading } = useRemoveExpeditePayment({
    onSuccess({ updated_count }) {
      const carrierNames = selectedCarriers.map(({ name }) => name).join();
      trackEvent('Shipper Bulk Unmarked Carrier as Can Expedite Payment', {
        carrier: carrierNames,
        count: updated_count,
        tab: carrierType,
      });

      invalidateCarriers();
      addSnackbar(
        `${updated_count} ${formatPlural(
          updated_count,
          'carrier',
          'carriers',
        )} removed from Can Expedite Payment.`,
        { variant: 'success' },
      );
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Button
      onClick={() => remove(carrierGuids)}
      variant="neutral"
      pending={isLoading}
    >
      Remove from Can Expedite Payment
    </Button>
  );
}

function MarkExpeditePaymentButton({
  carrierGuids,
  selectedCarriers,
  carrierType,
}: ExpeditePaymentButtonProps) {
  const { invalidateCarriers } = useCarriersCache();
  const { addSnackbar } = useSnackbarStack();

  const { mutate: mark, isLoading } = useMarkExpeditePayment({
    onSuccess({ updated_count }) {
      const carrierNames = selectedCarriers.map(({ name }) => name).join();
      trackEvent('Shipper Bulk Marked Carrier as Can Expedite Payment', {
        carrier: carrierNames,
        count: updated_count,
        tab: carrierType,
      });

      invalidateCarriers();
      addSnackbar(
        `${updated_count} ${formatPlural(
          updated_count,
          'carrier',
          'carriers',
        )} marked as Can Expedite Payment.`,
        {
          variant: 'success',
        },
      );
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Button
      onClick={() => mark(carrierGuids)}
      variant="neutral"
      pending={isLoading}
    >
      Mark as Can Expedite Payment
    </Button>
  );
}

export function ToggleExpeditePaymentButton({
  selectedCarriers,
  carrierGuids,
  carrierType,
}: ExpeditePaymentButtonProps) {
  const canExpeditePayment = selectedCarriers.every(
    (carrier) => !carrier.broker_records.can_expedite_payment,
  );

  const canRemoveExpeditePayment = selectedCarriers.every(
    (carrier) => carrier.broker_records.can_expedite_payment,
  );

  return canExpeditePayment ? (
    <MarkExpeditePaymentButton
      carrierGuids={carrierGuids}
      selectedCarriers={selectedCarriers}
      carrierType={carrierType}
    />
  ) : canRemoveExpeditePayment ? (
    <RemoveExpeditePaymentButton
      carrierGuids={carrierGuids}
      selectedCarriers={selectedCarriers}
      carrierType={carrierType}
    />
  ) : null;
}
