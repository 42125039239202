import { FieldValidator, useField, useFormikContext } from 'formik';
import { FocusEvent } from 'react';
import { CountryField, CountryFieldProps } from 'shared/form/CountryField';
import { CountryFieldOption } from 'shared/form/CountryFieldOptions';

export interface FormikCountryFieldProps
  extends Omit<CountryFieldProps, 'TextFieldProps'> {
  name: string;
  validate?: FieldValidator;

  TextFieldProps?: Omit<
    NonNullable<CountryFieldProps['TextFieldProps']>,
    'error'
  >;
}

export function FormikCountryField({
  name,
  disabled,
  validate,
  onBlur,
  onChange,
  TextFieldProps: { helperText, ...textFieldProps } = {},
  ...props
}: FormikCountryFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue }] =
    useField<null | CountryFieldOption>({ name, validate });
  const errorText = touched && error;

  return (
    <CountryField
      {...props}
      {...field}
      value={field.value}
      disabled={disabled || isSubmitting}
      onChange={(value) => {
        onChange?.(value);
        setValue(value);
      }}
      onBlur={(event: FocusEvent<HTMLDivElement>) => {
        onBlur?.(event);
        field.onBlur(event);
      }}
      TextFieldProps={{
        ...textFieldProps,
        name,
        error: !!errorText,
        helperText: errorText || helperText,
      }}
    />
  );
}
