import { useCallback } from 'react';
import { useOrderRequests } from '../../data/OrderRequestsAPI';

export function useCountOrderRequests(guid: string | undefined) {
  const { data: orderRequests } = useOrderRequests(guid);
  return useCallback(() => {
    const activeRequests =
      orderRequests?.objects.filter(
        (request) => request.status === 'REQUEST_PENDING',
      ) || [];

    const notComplyingRequests = activeRequests.filter(
      (request) =>
        request.largest_trailer_capacity && !request.insurance_requirements_met,
    );

    return {
      totalRequests: activeRequests.length,
      totalRequestsNotComplying: notComplyingRequests.length,
    };
  }, [orderRequests]);
}
