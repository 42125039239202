import { Menu, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import {
  DictionaryKey,
  formatDictionary,
} from 'core/dictionary/data/DictionaryDTO';
import { DictionaryAutocomplete } from 'core/dictionary/DictionaryAutocomplete';
import { useDictionaryList } from 'core/dictionary/DictionaryList';
import { SingleBooleanFilter } from 'core/dictionary/SingleBooleanFilter';
import { useFormikContext } from 'formik';
import { useMemo, useRef, useState } from 'react';
import { useProductTiers } from 'shared/data/TiersUtils';
import { useUserValue } from 'shared/data/UserPermissions';
import { ReportParamsDTO } from './data/ReportParamsDTO';

const brokerFilters: Set<DictionaryKey> = new Set([
  'pickup_addresses',
  'delivery_addresses',
  'customer_names',
  'carriers',
  'dispatcher_names',
  'salespersons',
  'tags',
]);

const customerFilters: Set<DictionaryKey> = new Set([
  'pickup_addresses',
  'delivery_addresses',
]);

export function ReportFilterField() {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  const { isBasicTier } = useProductTiers();

  const {
    setFieldValue,
    values: { statuses, payment_statuses, ...values },
  } = useFormikContext<ReportParamsDTO>();

  const userFilters = useUserValue(brokerFilters, customerFilters);

  const items = useMemo(() => {
    const filters = new Set(userFilters);

    if (isBasicTier) {
      filters.delete('tags');
    }

    return filters;
  }, [userFilters, isBasicTier]);

  const { setActive, getProps, activeItems, availableItems, disabled } =
    useDictionaryList({
      values,
      items,
      onChange(key, value) {
        setFieldValue(key, value);
      },
      onRemove(key) {
        setFieldValue(key, undefined);
      },
    });

  return (
    <Stack>
      {activeItems.map((type) => {
        return (
          <Box key={type} position="relative">
            {type === 'pickup_addresses' || type === 'delivery_addresses' ? (
              <DictionaryAutocomplete key={type} {...getProps(type)} />
            ) : type === 'carriers' ? (
              <DictionaryAutocomplete key={type} {...getProps(type)} />
            ) : type === 'is_superpay_available_carrier' ||
              type === 'is_carrier_requested_superpay' ? (
              <SingleBooleanFilter key={type} {...getProps(type)} />
            ) : (
              <DictionaryAutocomplete key={type} {...getProps(type)} />
            )}
          </Box>
        );
      })}

      <Button
        ref={ref}
        variant="neutral"
        startIcon={<Add color="action" />}
        onClick={() => setOpen((prev) => !prev)}
        disabled={disabled}
      >
        Add Filter
      </Button>

      <Menu open={open} anchorEl={ref.current} onClose={() => setOpen(false)}>
        {availableItems.map((type) => (
          <MenuItem
            key={type}
            onClick={() => {
              setOpen(false);
              setActive(type);
            }}
          >
            {formatDictionary(type)}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
