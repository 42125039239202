import { Box, Link, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  DrawerContent,
  RadioField,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { FormikProvider, useFormikContext } from 'formik';
import { useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { isAPIError } from 'shared/errors/APIError';
import {
  ReasonCode,
  SubscriptionCancelDTO,
  usePaywallAPI,
  useSubscriptionDetails,
} from 'shared/errors/paywall/data/PaywallAPI';
import { showIntercomDialog } from 'shared/helpers/IntercomHelpers';
import { EditDrawerActions } from './EditDrawerActions';

const cancellationReasons: Array<[ReasonCode, string]> = [
  ['Not Enough Carriers', 'Not enough carriers'],
  ['Lack of Features', 'Lack of features'],
  ['Out of Business', "We're going out of business"],
  ['No Budget', 'Super Dispatch is too expensive'],
  ['Lost to Competition', 'We use a different software'],
  ['Other', 'Other'],
];

const subscriptionBenefits = [
  [
    'Centralized Order Management',
    'You will no longer be able to create or import orders on the Super Loadboard.',
  ],
  [
    'Real Time Load Updates',
    'You will no longer receive real-time status updates for any of your orders.',
  ],
  [
    'Access to our Verified Carrier Network',
    'You will lose access to the thousands of trusted carriers being monitored for FMCSA authority and insurance on the Super Dispatch platform.',
  ],
];

interface CancellationReasonsDrawerContentProps {
  onCancelSuccess: () => void;
  onClose: () => void;
}

export function CancelSubscriptionDrawerContent({
  onCancelSuccess,
  onClose,
}: CancellationReasonsDrawerContentProps) {
  const { cancelSubscription } = usePaywallAPI();
  const { addSnackbar } = useSnackbarStack();
  const [step, setStep] = useState<'confirmation' | 'reasons' | 'feedback'>(
    'confirmation',
  );
  const isCancellationReasonsEnabled = useFeatureToggle(
    'self-serve-cancellation-reasons.enabled.ui',
  );

  const formik = useFormikEnhanced<SubscriptionCancelDTO, unknown>({
    initialValues: {
      reason_code: 'Trial expired',
      cancellation_feedback: null,
    },
    onSubmit: (values) => cancelSubscription(values),
    onSubmitSuccess: () => onCancelSuccess(),
    onSubmitFailure: (error) => {
      if (isAPIError(error)) {
        addSnackbar(error.message, { variant: 'error' });
      } else {
        addSnackbar('Something went wrong', { variant: 'error' });
      }
    },
  });

  function onConfirm() {
    if (isCancellationReasonsEnabled) {
      setStep('reasons');
      return;
    }
    return void formik.submitForm();
  }

  return (
    <FormikProvider value={formik}>
      {step === 'confirmation' && (
        <CancellationConfirmation onClose={onClose} onContinue={onConfirm} />
      )}

      {step === 'reasons' && (
        <CancellationReasons onClick={() => setStep('feedback')} />
      )}

      {step === 'feedback' && <CancellationFeedback />}
      <DrawerContent>
        <Typography color="textSecondary">
          For any questions or feedback, please{' '}
          <Link component="button" onClick={showIntercomDialog}>
            contact support
          </Link>
        </Typography>
      </DrawerContent>
    </FormikProvider>
  );
}

function CancellationConfirmation({
  onClose,
  onContinue,
}: {
  onClose: () => void;
  onContinue: () => void;
}) {
  const { data } = useSubscriptionDetails();
  const isTrial = data?.chargebee.status === 'IN_TRIAL';
  const { isSubmitting } = useFormikContext();
  return (
    <>
      <DrawerContent>
        <Stack space="medium">
          <Stack space="small">
            <Typography variant="h4">
              {isTrial
                ? 'Are you sure you want to cancel your free trial?'
                : 'Are you sure you want to cancel your subscription?'}
            </Typography>

            <Typography>
              {isTrial
                ? 'If you cancel your free trial , you will lose access to the following features:'
                : 'If you cancel your subscription, you will lose access to the following features:'}
            </Typography>
          </Stack>

          <Stack space="medium">
            {subscriptionBenefits.map(([title, description]) => (
              <Stack key={title} space="xxsmall">
                <Columns space="medium">
                  <Column width="content">
                    <Close color="error" />
                  </Column>

                  <Column width="fluid">
                    <Stack space="xxsmall">
                      <Typography variant="h4">{title}</Typography>
                      <Typography color="textSecondary">
                        {description}
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </DrawerContent>
      <EditDrawerActions
        primaryActionText={isTrial ? 'Keep Free Trial' : 'Keep Subscription'}
        onPrimaryAction={onClose}
        secondaryActionText="Continue Canceling"
        onSecondaryAction={onContinue}
        primaryActionDisabled={isSubmitting}
        secondaryActionDisabled={isSubmitting}
      />
    </>
  );
}

function CancellationReasons({ onClick }: { onClick: () => void }) {
  const { data } = useSubscriptionDetails();
  const isTrial = data?.chargebee.status === 'IN_TRIAL';
  const formik = useFormikContext();
  return (
    <>
      <DrawerContent>
        <Stack space="xsmall">
          <Stack space="xxsmall">
            <Typography variant="body1">
              {isTrial
                ? 'Why do you want to cancel your free trial?'
                : 'Why do you want to cancel your subscription?'}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              Select at least one option to continue
            </Typography>
          </Stack>

          <Stack space="medium">
            <FormikRadioGroupField name="reason_code">
              {cancellationReasons.map(([name, label]) => (
                <Stack key={name} space="xxsmall">
                  <RadioField value={name} label={label} />
                </Stack>
              ))}
            </FormikRadioGroupField>
          </Stack>
        </Stack>
      </DrawerContent>
      <EditDrawerActions
        primaryActionText="Next"
        onPrimaryAction={onClick}
        primaryActionDisabled={formik.isSubmitting || !formik.dirty}
      />
    </>
  );
}

function CancellationFeedback() {
  const formik = useFormikContext<SubscriptionCancelDTO>();
  const count = (formik.values.cancellation_feedback || '').length;

  return (
    <>
      <DrawerContent>
        <Stack space="xsmall">
          <FormikTextField
            name="cancellation_feedback"
            label={
              <Box display="flex" justifyContent="space-between">
                Please tell us how we can improve
                <Typography color="textSecondary">
                  {`${count} of 2000`}
                </Typography>
              </Box>
            }
            multiline={true}
            fullWidth={true}
            inputProps={{
              maxLength: 2000,
            }}
            maxRows={3}
            placeholder="In a few sentences, tell us what we could have done better to keep you as a customer"
          />
        </Stack>
      </DrawerContent>
      <EditDrawerActions
        primaryActionText="Cancel Subscription"
        onPrimaryAction={() => formik.submitForm()}
        primaryActionDisabled={formik.isSubmitting}
      />
    </>
  );
}
