import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CreateUserDTO = InferType<typeof createUserDTO>;

export const createUserDTO = yupObject({
  first_name: yupString().nullable(),
  last_name: yupString().nullable(),
  username: yupString().required(),
  email: yupString().email().nullable(),
  phone: yupString().nullable(),
  password: yupString().nullable(),
  role_id: yupString().nullable(),
  display_user_contact_to_carriers: yupBoolean(),
});
