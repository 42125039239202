import { createSvgIcon } from 'shared/icons/IconUtils';

export const LocationsIcon = createSvgIcon(
  'Locations',
  <path
    d="M11.667 9.583a2.083 2.083 0 1 0 0-4.165 2.083 2.083 0 0 0 0 4.165Zm0-7.916A5.834 5.834 0 0 1 17.5 7.5c0 4.375-5.833 10.833-5.833 10.833S5.833 11.875 5.833 7.5a5.833 5.833 0 0 1 5.834-5.833ZM4.167 7.5c0 3.75 4.233 8.883 5 9.842l-.834.991S2.5 11.875 2.5 7.5a5.838 5.838 0 0 1 4.167-5.592 7.485 7.485 0 0 0-2.5 5.592Z"
    fill="curretColor"
  />,
  { viewBox: '0 0 20 20' },
);
