import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { OrderDTO } from 'shared/dto/Order/OrderDTO';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { useDeliveryVerification } from './data/OrderDeliveryVerificationAPI';

const FailureReasonList = styled.ul`
  padding-inline-start: 24px;
  margin: 0;
`;
const FailureReasonListItem = styled.li`
  margin-bottom: 4px;
`;

const SpinnedContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function OrderDeliveryVerificationFailedTooltip({
  order,
}: {
  order: Order;
}) {
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const { data, isLoading } = useDeliveryVerification(order.id, isTooltipOpen);

  return (
    <Tooltip
      onOpen={() => setTooltipOpen(true)}
      PopperProps={{
        style: { boxSizing: 'content-box' },
      }}
      title={
        <Box width="300px">
          <Stack space="xsmall">
            {isLoading ? (
              <SpinnedContainer>
                <CircularProgress size={20} />
              </SpinnedContainer>
            ) : !data?.fail_reasons?.length ? (
              <Typography>
                No reason provided for delivery verification failure.
              </Typography>
            ) : (
              <Stack space="xsmall">
                <Typography>Delivery verification failed due to:</Typography>
                <Stack space="small">
                  <FailureReasonList>
                    {data.fail_reasons.map((reason) => (
                      <FailureReasonListItem key={reason}>
                        {OrderDTO.getDeliveryVerificationFailureReasonText(
                          reason,
                        )}
                      </FailureReasonListItem>
                    ))}
                  </FailureReasonList>
                  <Typography>
                    Mark the order as Delivery Verified manually after
                    confirming the delivery.
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
      }
    >
      <Info color="action" fontSize="small" />
    </Tooltip>
  );
}
