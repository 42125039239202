import { Typography } from '@material-ui/core';
import { Column, Columns, InfoCard, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { VerifiedBadge } from './core/VerifiedBadge';
import { ShipperProfileDTO } from './data/ShipperProfileDTO';
import { ShipperProfileAvatar } from './ShipperProfileAvatar';

interface ShipperProfileHeaderProps {
  stats: ReactNode;
  children: ReactNode;
  shipper: ShipperProfileDTO;
}

export function ShipperProfileHeader({
  shipper,
  children,
  stats,
}: ShipperProfileHeaderProps) {
  return (
    <InfoCard size="large">
      <Stack space="small">
        <Columns align="top" collapseBelow="tablet" space="small">
          <Column width={['fluid', 'content']}>
            <ShipperProfileAvatar shipper={shipper} />
          </Column>

          <Column>
            <Stack space="xxsmall">
              <Typography variant="h1">{shipper.name}</Typography>
              {shipper.verification_status === 'VERIFIED' && <VerifiedBadge />}
            </Stack>
          </Column>

          <Column width={['fluid', 'content']}>{stats}</Column>
        </Columns>

        <Box margin={['-small', '-medium']} marginTop="none">
          {children}
        </Box>
      </Stack>
    </InfoCard>
  );
}
