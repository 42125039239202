import { Link, Typography } from '@material-ui/core';
import { Mail, Person, Phone } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, InfoCard, Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import * as React from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackAnchorClickLegacy } from 'shared/helpers/AnalyticsHelpers';
import { CarrierFullInfo } from 'shared/types/carrier';
import { CarrierAcceptsSuperPay } from '../../superpay/CarrierAcceptsSuperPay';
import { CarrierRequestsSuperPay } from '../../superpay/CarrierRequestsSuperPay';

interface CarrierBillingInfoProps {
  carrier: CarrierFullInfo;
}

export function CarrierBillingInfo({ carrier }: CarrierBillingInfoProps) {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const hasBillingContactInfo =
    carrier.billing_contact_name ||
    carrier.billing_email ||
    carrier.billing_phone_numbers;

  const hasACHPaymentInfo =
    carrier.ach_payment &&
    (carrier.ach_payment.bank_account_number ||
      carrier.ach_payment.bank_name ||
      carrier.ach_payment.bank_routing_number ||
      carrier.ach_payment.voided_check_url);
  const hasBillingInfo = hasACHPaymentInfo || hasBillingContactInfo;

  return (
    <InfoCard size="large">
      <Stack space="small">
        <Typography variant="h3">Billing Info</Typography>

        {isCarrierRequestsSuperPay &&
        carrier.broker_records.superpay_requested ? (
          <CarrierRequestsSuperPay source="Carrier Profile" />
        ) : (
          carrier.is_super_pay_enabled && (
            <CarrierAcceptsSuperPay source="Carrier Profile" />
          )
        )}

        {hasBillingInfo ? (
          <Stack space="medium">
            <Stack space="xsmall">
              <Typography variant="h4">Billing Contact</Typography>

              {hasBillingContactInfo ? (
                <Stack space="xxsmall">
                  {carrier.billing_contact_name && (
                    <DescriptionItem icon={<Person />}>
                      <Typography aria-label="carrier billing contact name">
                        {carrier.billing_contact_name}
                      </Typography>
                    </DescriptionItem>
                  )}
                  {carrier.billing_email && (
                    <DescriptionItem icon={<Mail />}>
                      <Link
                        href={`mailto:${carrier.billing_email}`}
                        aria-label="carrier billing email"
                      >
                        {carrier.billing_email}
                      </Link>
                    </DescriptionItem>
                  )}
                  {carrier.billing_phone_numbers && (
                    <DescriptionItem icon={<Phone />}>
                      <SuspendedPhoneLink
                        aria-label="carrier billing phone number"
                        phone={carrier.billing_phone_numbers}
                        fallback={carrier.billing_phone_numbers}
                      />
                    </DescriptionItem>
                  )}
                </Stack>
              ) : (
                <Typography color="textSecondary">
                  No Information Provided
                </Typography>
              )}
            </Stack>

            <Stack space="xsmall">
              <Typography variant="h4">ACH Info</Typography>

              {hasACHPaymentInfo ? (
                <>
                  {carrier.ach_payment?.bank_name && (
                    <Columns collapseBelow="desktop">
                      <Column>
                        <Typography color="textSecondary">Bank Name</Typography>
                      </Column>
                      <Column>
                        <Typography>{carrier.ach_payment.bank_name}</Typography>
                      </Column>
                    </Columns>
                  )}

                  {carrier.ach_payment?.bank_routing_number && (
                    <Columns collapseBelow="desktop">
                      <Column>
                        <Typography color="textSecondary">
                          Routing Number
                        </Typography>
                      </Column>
                      <Column>
                        <Typography>
                          {carrier.ach_payment.bank_routing_number}
                        </Typography>
                      </Column>
                    </Columns>
                  )}

                  {carrier.ach_payment?.bank_account_number && (
                    <Columns collapseBelow="desktop">
                      <Column>
                        <Typography color="textSecondary">
                          Bank Account Number
                        </Typography>
                      </Column>
                      <Column>
                        <Typography>
                          {carrier.ach_payment.bank_account_number}
                        </Typography>
                      </Column>
                    </Columns>
                  )}

                  {carrier.ach_payment?.voided_check_url && (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={carrier.ach_payment.voided_check_url}
                      onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                        trackAnchorClickLegacy(
                          'Clicked View Carrier Voided Check',
                          undefined,
                          event,
                        )
                      }
                    >
                      View Voided Check
                    </Link>
                  )}
                </>
              ) : (
                <Typography color="textSecondary">
                  No Information Provided
                </Typography>
              )}
            </Stack>
          </Stack>
        ) : (
          <Typography color="textSecondary">No Information Provided</Typography>
        )}
      </Stack>
    </InfoCard>
  );
}
