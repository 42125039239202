import { toBytes } from '@superdispatch/ui-lab';
import { FormikFileField } from 'shared/form/FormikFileField';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { validateFile } from 'shared/helpers/ValidationHelpers';

const INSURANCE_MAX_FILE_SIZE = toBytes(20, 'mb');
const INSURANCE_ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
];
const INSURANCE_ACCEPT_TYPES = INSURANCE_ALLOWED_MIME_TYPES.join();

export const NetworkDrawerInsuranceFile = () => {
  return (
    <FormikFileField
      aria-label="insurance file"
      label="Insurance File"
      name="insurance_cert_holder_file_url"
      accept={INSURANCE_ACCEPT_TYPES}
      validate={(value) =>
        value instanceof File
          ? validateFile(value, {
              allowFormats: INSURANCE_ALLOWED_MIME_TYPES,
              maxSize: INSURANCE_MAX_FILE_SIZE,
              maxSizeMessage: `File size should be less than ${formatNumber(
                INSURANCE_MAX_FILE_SIZE / (1024 * 1024),
              )} MB`,
            })
          : undefined
      }
    />
  );
};
