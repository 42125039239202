import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useManageUsersAPI } from './data/ManageUsersAPI';
import { UserDTO } from './data/UserDTO';

interface Props {
  user: UserDTO;
  open: boolean;
  onClose: () => void;
}

export function UserSendPasswordResetLinkDialog({
  user,
  open,
  onClose,
}: Props) {
  const { sendResetPasswordLink } = useManageUsersAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: (_) => sendResetPasswordLink({ username: user.username }),
    onSubmitFailure: (error) => {
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        addSnackbar(
          'You have exceeded the maximum number of password reset attempts per minute. Please retry later.',
          { variant: 'error' },
        );
      } else {
        addSnackbar(error.message, { variant: 'error' });
      }
    },
    onSubmitSuccess: () => {
      onClose();
      trackEvent('[STMS] Reset password link sent');
      addSnackbar('Reset password link sent', { variant: 'success' });
    },
  });
  return (
    <ConfirmDialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => (formik.isSubmitting ? undefined : onClose())}
      title="Send Password Reset Link to this user?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: formik.isSubmitting,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: 'Send Reset Link',
        isLoading: formik.isSubmitting,
        onClick: () => formik.handleSubmit(),
        autoFocus: false,
      }}
    >
      <Stack space="xxsmall">
        <Typography>
          This user will receive a reset link to: {user.username}
        </Typography>
        <Typography>
          By clicking the link, user will be able to set a new password.
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
}
