import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';

export function InspectionTypeWarning() {
  return (
    <Box padding="xsmall" backgroundColor="Yellow50" borderRadius="medium">
      <Inline verticalAlign="center" horizontalAlign="center">
        <Warning fontSize="small" htmlColor={Color.Yellow300} />
        <Typography>
          Inspection type will be changed to Advanced for SuperPay
        </Typography>
      </Inline>
    </Box>
  );
}
