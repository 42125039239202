import { formatPaymentMethod } from '@superdispatch/sdk';
import { CarrierAutocomplete } from './CarrierAutocomplete';
import { ContactAutocomplete } from './ContactAutocomplete';
import { CustomerAutocomplete } from './CustomerAutocomplete';
import {
  DictionaryCarriersKey,
  DictionaryPlaceKey,
  DictionaryStringKey,
  DictionaryValues,
  formatDictionary,
  formatMultipleDictionaryValues,
} from './data/DictionaryDTO';
import { OrderTagDTO, useOrderTags } from './data/OrdersTagAPI';
import {
  DeliveredOnDateRange,
  formatDeliveredOnDate,
} from './DeliveredOnDateRange';
import { InputControl, InputControlProps } from './InputControl';
import { InternalUsersAutocomplete } from './InternalUsersAutocomplete';
import { LotNumberAutocomplete } from './LotNumberAutocomplete';
import { OrderTagsAutocomplete } from './OrderTagsAutocomplete';
import {
  formatPaymentStatus,
  PaymentStatusAutocomplete,
} from './PaymentStatusAutocomplete';
import { formatPlace, PlaceAutocomplete } from './PlaceAutocomplete';
import { formatStatus, StatusAutocomplete } from './StatusAutocomplete';

interface BaseProps<TKey extends keyof DictionaryValues>
  extends Omit<InputControlProps, 'label'> {
  type: TKey;
  onClose: () => void;
  value: DictionaryValues[TKey];
  onChange: (option: DictionaryValues[TKey]) => void;
}

export type DictionaryAutocompleteProps =
  | BaseProps<DictionaryStringKey>
  | BaseProps<DictionaryPlaceKey>
  | BaseProps<DictionaryCarriersKey>;

export function DictionaryAutocomplete({
  onSelect,
  ...props
}: DictionaryAutocompleteProps) {
  const { data: { objects: orderTags = [] } = {} } = useOrderTags();

  const label = getFilterLabel(props, orderTags);

  return (
    <InputControl {...props} label={label} onSelect={onSelect}>
      {props.type === 'lot_numbers' ? (
        <LotNumberAutocomplete {...props} />
      ) : props.type === 'customer_names' ? (
        <CustomerAutocomplete {...props} />
      ) : props.type === 'contact_names' ? (
        <ContactAutocomplete {...props} />
      ) : props.type === 'carriers' ? (
        <CarrierAutocomplete {...props} />
      ) : props.type === 'delivery_addresses' ||
        props.type === 'pickup_addresses' ? (
        <PlaceAutocomplete {...props} />
      ) : props.type === 'delivered_on_date' ? (
        <DeliveredOnDateRange {...props} onSelect={onSelect} />
      ) : props.type === 'statuses' ? (
        <StatusAutocomplete {...props} />
      ) : props.type === 'payment_statuses' ? (
        <PaymentStatusAutocomplete {...props} />
      ) : props.type === 'tags' ? (
        <OrderTagsAutocomplete {...props} />
      ) : props.type === 'dispatcher_names' ? (
        <InternalUsersAutocomplete {...props} />
      ) : props.type === 'salespersons' ? (
        <InternalUsersAutocomplete {...props} />
      ) : null}
    </InputControl>
  );
}

interface FilterLabelBaseProps<TKey extends keyof DictionaryValues> {
  type: TKey;
  value: DictionaryValues[TKey];
}
type FilterLabelProps =
  | FilterLabelBaseProps<DictionaryStringKey>
  | FilterLabelBaseProps<DictionaryPlaceKey>
  | FilterLabelBaseProps<DictionaryCarriersKey>;

function getFilterLabel(props: FilterLabelProps, orderTags: OrderTagDTO[]) {
  const { type, value } = props;
  if (!value || !value[0]) {
    return formatDictionary(type);
  }

  const isMultiple = type !== 'delivered_on_date' && value.length > 1;

  if (isMultiple) {
    return `${value.length} ${formatMultipleDictionaryValues(type)}`;
  }

  let filterValue = '';
  switch (type) {
    case 'statuses':
      filterValue = formatStatus(value[0]);
      break;
    case 'tags':
      filterValue = formatOrderTag(value[0], orderTags);
      break;
    case 'delivered_on_date':
      filterValue = value[1] ? formatDeliveredOnDate(value[0], value[1]) : '';
      break;
    case 'pickup_addresses':
    case 'delivery_addresses':
      filterValue = formatPlace(value[0]);
      break;
    case 'payment_statuses':
      filterValue = formatPaymentStatus(value[0]);
      break;
    case 'carriers':
      filterValue = value[0].name;
      break;
    case 'customer_names':
    case 'dispatcher_names':
    case 'salespersons':
    case 'lot_numbers':
    case 'contact_names':
      filterValue = value[0];
      break;
    case 'payment_methods':
      return formatPaymentMethod(value[0]);
  }

  return `${formatDictionary(type)} is ${filterValue}`;
}

function formatOrderTag(orderTag: string, orderTags: OrderTagDTO[]) {
  const tagId = Number(orderTag);
  const tag = orderTags.find((x) => x.id === tagId);
  return tag?.name || '';
}
