import { Box, Popover, PopoverProps } from '@material-ui/core';
import { NullableDateString, stringifyDate } from '@superdispatch/dates';
import { FormikDateField, useFormikEnhanced } from '@superdispatch/forms';
import { Button, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import countBy from 'lodash/countBy';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import Order from 'shared/types/order';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';

interface MarkAsCarrierInvoicedFormValues {
  adjusted_invoice_date: NullableDateString;
}

interface MarkAsCarrierInvoicedPopoverProps<T>
  extends Omit<PopoverProps, 'onSubmit'> {
  onSubmit: (values: MarkAsCarrierInvoicedFormValues) => Promise<T>;
  onSubmitSuccess: (
    response: T,
    values: MarkAsCarrierInvoicedFormValues,
  ) => void;
}

function MarkAsCarrierInvoicedPopover<T>({
  open,
  onClose,
  onSubmit,
  onSubmitSuccess,
  ...props
}: MarkAsCarrierInvoicedPopoverProps<T>) {
  const initialValues = useMemo<MarkAsCarrierInvoicedFormValues>(() => {
    return {
      adjusted_invoice_date: stringifyDate(startOfWorkDay(DateTime.local()), {
        format: 'JodaISO',
      }),
    };
  }, []);

  const form = useFormikEnhanced<MarkAsCarrierInvoicedFormValues, T>({
    key: open,
    initialValues,
    onSubmit,
    onSubmitSuccess,
  });

  return (
    <Popover
      open={open}
      onClose={form.isSubmitting ? undefined : onClose}
      {...props}
    >
      <FormikProvider value={form}>
        <Form>
          <Box p={1}>
            <Box p={1}>
              <FormikDateField
                label="Adjusted Invoice Date"
                name="adjusted_invoice_date"
              />
            </Box>

            <Box display="flex" justifyContent="flex-end" p={1}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                isLoading={form.isSubmitting}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </Popover>
  );
}

interface SingleMarkAsCarrierInvoicedPopoverProps
  extends Omit<PopoverProps, 'open'> {
  order: Order | undefined;
  onSubmitSuccess: (order: Order) => void;
}

export function SingleMarkAsCarrierInvoicedPopover({
  order,
  anchorEl,
  onSubmitSuccess,
  ...props
}: SingleMarkAsCarrierInvoicedPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markAsCarrierInvoiced } = useSingleOrderActionAPI();
  return (
    <MarkAsCarrierInvoicedPopover
      {...props}
      anchorEl={anchorEl}
      open={!!order && !!anchorEl}
      onSubmit={(values) => {
        if (order) {
          return markAsCarrierInvoiced(order.id, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={(response: Order) => {
        trackEventLegacy('Order Mark as Carrier Invoiced');
        addSnackbar('Order marked as carrier invoiced', { variant: 'success' });

        onSubmitSuccess(response);
      }}
    />
  );
}

interface BulkMarkAsCarrierInvoicedPopoverProps
  extends Omit<PopoverProps, 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
}

export function BulkMarkAsCarrierInvoicedPopover({
  orders,
  anchorEl,
  onSubmitSuccess,
  ...props
}: BulkMarkAsCarrierInvoicedPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkMarkAsCarrierInvoiced } = useBulkOrderActionAPI();

  return (
    <MarkAsCarrierInvoicedPopover
      {...props}
      anchorEl={anchorEl}
      open={!!orders && !!anchorEl}
      onSubmit={(values) => {
        if (orders) {
          const ids = orders.map((x) => x.id);
          return bulkMarkAsCarrierInvoiced(ids, values);
        }

        return Promise.reject(new Error('Orders not selected'));
      }}
      onSubmitSuccess={() => {
        if (orders) {
          trackEventLegacy('Bulk Mark as Carrier Invoiced', {
            count: orders.length,
            orders: countBy(orders, 'status'),
          });

          addSnackbar('Order(s) marked as carrier invoiced', {
            variant: 'success',
          });

          onSubmitSuccess();
        }
      }}
    />
  );
}
