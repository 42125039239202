import { Typography } from '@material-ui/core';
import { CheckSharp } from '@material-ui/icons';
import {
  Column,
  Columns,
  DrawerContent,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { useRef, useState } from 'react';
import { isAPIError } from 'shared/errors/APIError';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import {
  ChargebeeCardForm,
  ChargebeeCardFormRef,
} from 'shared/form/ChargebeeCardForm';
import { ShieldIcon } from 'shared/icons/ShieldIcon';
import { EditDrawer } from './EditDrawer';
import { EditDrawerActions } from './EditDrawerActions';
import { useInvalidatePaymentMethodQuery } from './usePaymentDetails';
import { useUpdatePaymentDetails } from './useUpdatePaymentDetails';

export function SubscriptionEditPaymentDetailsDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <EditDrawer open={open} onClose={onClose} title="Edit Card Details">
      <EditForm onSubmitSuccess={onClose} />
    </EditDrawer>
  );
}

function EditForm({ onSubmitSuccess }: { onSubmitSuccess: () => void }) {
  const cardRef = useRef<ChargebeeCardFormRef | null>(null);
  const [isPending, setIsPending] = useState(false);
  const [isCardDetailsValid, setIsCardDetailsValid] = useState(false);
  const { addSnackbar } = useSnackbarStack();
  const invalidatePaymentMethodQuery = useInvalidatePaymentMethodQuery();
  const { updatePaymentDetails } = useUpdatePaymentDetails({
    onSuccess() {
      void invalidatePaymentMethodQuery();

      addSnackbar('Card details updated successfully.', {
        variant: 'success',
      });

      onSubmitSuccess();
    },
    onError(error) {
      if (isAPIError(error)) {
        addSnackbar(<APIErrorMessage error={error} />);
      } else if (error instanceof Error) {
        addSnackbar(error.message, {
          variant: 'error',
        });
      } else {
        addSnackbar('An unexpected error occurred.', {
          variant: 'error',
        });
      }
    },
  });

  const handleSubmit = async () => {
    setIsPending(true);

    const card = cardRef.current;

    if (card === null) {
      throw new Error(
        'cardRef is null, make sure you pass the ref to the ChargebeeCardForm component.',
      );
    }

    const chargebeeResponse = await card.tokenize();
    updatePaymentDetails(chargebeeResponse);

    setIsPending(false);
  };

  return (
    <>
      <DrawerContent>
        <Stack space="medium">
          <Stack>
            {[
              {
                Icon: ShieldIcon,
                text: 'We use industry-standard encryption and security protocols to safeguard your information.',
              },
              {
                Icon: CheckSharp,
                text: 'We accept all major credit cards.',
              },
            ].map(({ Icon, text }) => (
              <Columns space="xxsmall" key={text}>
                <Column width="content">
                  <Icon color="action" fontSize="small" />
                </Column>

                <Column width="fluid">
                  <Typography
                    component="p"
                    color="textSecondary"
                    variant="caption"
                  >
                    {text}
                  </Typography>
                </Column>
              </Columns>
            ))}
          </Stack>

          <ChargebeeCardForm
            ref={cardRef}
            onValidation={setIsCardDetailsValid}
          />
        </Stack>
      </DrawerContent>

      <EditDrawerActions
        primaryActionDisabled={isPending || !isCardDetailsValid}
        primaryActionText="Save"
        onPrimaryAction={handleSubmit}
      />
    </>
  );
}
