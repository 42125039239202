import { useMemo } from 'react';
import {
  removeStorageItem,
  useStorageValue,
  writeStorageItem,
} from 'shared/helpers/LocalStorage';

const LOGIN_VERIFICATION_EMAIL = 'login_verification_email';
const LOGIN_VERIFICATION_PHONE_NUMBER = 'login_verification_phone_number';
const LOGIN_VERIFICATION_CODE_CHANNEL = 'login_verification_code_channel';
const LOGIN_USERNAME = 'login_username';
const LOGIN_ATTEMPT_SESSION_GUID = 'login_attempt_session_guid';

interface SaveVerificationProps {
  email: string;
  username: string;
  phoneNumber?: string;
  channel: 'email' | 'sms';
  attemptSessionGuid: string;
}

export function useVerification() {
  const verificationChannel = useStorageValue(
    LOGIN_VERIFICATION_CODE_CHANNEL,
  ) as 'sms' | 'email' | undefined;
  const email = useStorageValue(LOGIN_VERIFICATION_EMAIL);
  const phoneNumber = useStorageValue(LOGIN_VERIFICATION_PHONE_NUMBER);
  const username = useStorageValue(LOGIN_USERNAME);
  const attemptSessionGuid = useStorageValue(LOGIN_ATTEMPT_SESSION_GUID);

  return useMemo(() => {
    //email
    function saveVerificationEmail(mail: string) {
      writeStorageItem(LOGIN_VERIFICATION_EMAIL, mail);
    }

    //phone
    function saveVerificationPhoneNumber(phone: string) {
      writeStorageItem(LOGIN_VERIFICATION_PHONE_NUMBER, phone);
    }

    // username
    function saveUsername(loginUsername: string) {
      writeStorageItem(LOGIN_USERNAME, loginUsername);
    }

    //channel
    function saveVerificationCodeChannel(channel: 'sms' | 'email') {
      writeStorageItem(LOGIN_VERIFICATION_CODE_CHANNEL, channel);
    }

    //session_guid
    function saveAttemptSessionGuid(value: string) {
      writeStorageItem(LOGIN_ATTEMPT_SESSION_GUID, value);
    }

    //save all
    function saveVerification({
      email: newEmail,
      username: newUsername,
      phoneNumber: newPhoneNumber,
      channel: newChannel,
      attemptSessionGuid: newAttemptSessionGuid,
    }: SaveVerificationProps) {
      saveUsername(newUsername);
      saveVerificationEmail(newEmail);
      saveVerificationCodeChannel(newChannel);
      saveAttemptSessionGuid(newAttemptSessionGuid);
      if (newPhoneNumber) saveVerificationPhoneNumber(newPhoneNumber);
    }

    function removeAttemptSessionGuid() {
      removeStorageItem(LOGIN_ATTEMPT_SESSION_GUID);
    }

    function removeVerification() {
      removeStorageItem(LOGIN_VERIFICATION_EMAIL);
      removeStorageItem(LOGIN_VERIFICATION_CODE_CHANNEL);
      removeStorageItem(LOGIN_VERIFICATION_PHONE_NUMBER);
      removeStorageItem(LOGIN_USERNAME);
      removeStorageItem(LOGIN_ATTEMPT_SESSION_GUID);
    }

    return {
      email,
      phoneNumber,
      username,
      verificationChannel,
      attemptSessionGuid,
      saveVerificationEmail,
      saveVerificationPhoneNumber,
      saveUsername,
      saveVerificationCodeChannel,
      saveAttemptSessionGuid,
      saveVerification,
      removeAttemptSessionGuid,
      removeVerification,
    };
  }, [email, phoneNumber, verificationChannel, username, attemptSessionGuid]);
}
