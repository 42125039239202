import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { TextBox } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { OrderPricePredictionDTO } from 'orders/data/dto/OrderPriceDTO';
import { useMemo } from 'react';
import Order from 'shared/types/order';
import { makeAddressCityStateZip } from 'shared/utils/AddressUtils';
import { usePricePrediction } from '../../data/OrderPriceAPI';

interface PricePerVehicleDTO {
  suv: number;
  van: number;
  sedan: number;
  is_inoperable: boolean;
  pickup_2_doors: number;
  pickup_4_doors: number;
}

function calculatePricePerVehicle(
  prices: OrderPricePredictionDTO[] = [],
): [PricePerVehicleDTO, PricePerVehicleDTO] {
  const operable: PricePerVehicleDTO = {
    sedan: 0,
    suv: 0,
    van: 0,
    pickup_2_doors: 0,
    pickup_4_doors: 0,
    is_inoperable: false,
  };
  const inOperable: PricePerVehicleDTO = {
    sedan: 0,
    suv: 0,
    van: 0,
    pickup_2_doors: 0,
    pickup_4_doors: 0,
    is_inoperable: true,
  };

  for (const price of prices) {
    const { is_inoperable, estimated_price, vehicle_type_name } = price;

    if (is_inoperable) {
      inOperable[vehicle_type_name] = estimated_price;
    } else {
      operable[vehicle_type_name] = estimated_price;
    }
  }

  return [operable, inOperable];
}

interface Props {
  order: Partial<Order>;
}

export function PricePerVehicles({ order }: Props) {
  const averageYear = useMemo(() => {
    if (!order.vehicles?.length) {
      return undefined;
    }

    const years = order.vehicles.map((x) => Number(x.year)).filter(Boolean);
    return years.reduce((sum, year) => sum + year, 0) / years.length;
  }, [order.vehicles]);

  const { data, error } = usePricePrediction({
    average_vehicle_year: averageYear,
    transport_type: order.transport_type?.toLowerCase(),
    pickup_address: makeAddressCityStateZip(order.pickup?.venue),
    delivery_address: makeAddressCityStateZip(order.delivery?.venue),
  });
  const pricePerVehicles = useMemo(
    () => calculatePricePerVehicle(data?.objects),
    [data],
  );

  if (error) {
    return (
      <Flex justifyContent="center">
        <Typography variant="h4" color="textSecondary">
          Something went wrong
        </Typography>
      </Flex>
    );
  }

  if (!data) {
    return (
      <Flex justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  if (data.objects.length === 0) {
    return (
      <Flex justifyContent="center">
        <Typography variant="h4" color="textSecondary">
          No Info Available
        </Typography>
      </Flex>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sedan</TableCell>
            <TableCell>SUV</TableCell>
            <TableCell>VAN</TableCell>
            <TableCell>Pickup (2 doors)</TableCell>
            <TableCell>Pickup (4 doors)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {pricePerVehicles.map(
            (
              {
                van,
                sedan,
                suv,
                is_inoperable,
                pickup_2_doors,
                pickup_4_doors,
              },
              index,
            ) => (
              <TableRow key={index}>
                <TableCell>
                  <Price value={sedan} />
                </TableCell>
                <TableCell>
                  <Price value={suv} />
                </TableCell>
                <TableCell>
                  <Price value={van} />
                </TableCell>
                <TableCell>
                  <Price value={pickup_2_doors} />
                </TableCell>
                <TableCell>
                  <Price value={pickup_4_doors} />
                </TableCell>
                <TableCell>
                  {is_inoperable && (
                    <TextBox variant="heading-5" color="secondary">
                      INOP
                    </TextBox>
                  )}
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
