import { SuperDispatchTheme, ThemeProvider } from '@superdispatch/ui';
import { ReactNode } from 'react';

function getScrollbarCss() {
  if (!navigator.appVersion.includes('Win')) {
    return {};
  }

  return {
    '::-webkit-scrollbar': {
      width: 10,
      height: 10,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      // Color.Dark400
      backgroundColor: 'rgba(50, 60, 78, 0.2)',
      borderRadius: '100vw',
      marginBottom: '100px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(50, 60, 78, 0.8)',
    },
  };
}

function modifyTheme(theme: SuperDispatchTheme) {
  theme.breakpoints.values.md = 1025;

  return {
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiCssBaseline: {
        '@global': {
          ...getScrollbarCss(),
        },
      },
      MuiTableCell: {
        ...theme.overrides.MuiTableCell,
        root: {
          ...theme.overrides.MuiTableCell?.root,
          borderBottomColor: '#dfe3e8', // TODO move to sd/ui
        },
      },
    },
  };
}

export function AppTheme({ children }: { children: ReactNode }) {
  return <ThemeProvider modifier={modifyTheme}>{children}</ThemeProvider>;
}
