import { createSvgIcon } from './IconUtils';

export const ChevronDown = createSvgIcon(
  'ChevronDownIcon',
  <path
    d="M7.41 8.58984L12 13.1698L16.59 8.58984L18 9.99984L12 15.9998L6 9.99984L7.41 8.58984Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 24 24' },
);
