import { Link } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../form/OrderForm';
import { validateOrder } from './PricingRecommendationHelpers';

const BelowText = styled(TextBox)`
  color: ${Color.Yellow500};
`;

const PointerLink = styled(Link)`
  cursor: pointer;
`;

interface PricingRecommendationCounterOfferProps {
  source: string;
  order: Partial<Order> | OrderFormValues;
  counterOfferPrice: number;
  onPriceClick: (value: number) => void;
}

export function PricingRecommendationCounterOffer({
  source,
  order,
  counterOfferPrice,
  onPriceClick,
}: PricingRecommendationCounterOfferProps) {
  const recommendedPrice = order.recommended_price;

  if (validateOrder(order)) {
    return null;
  }

  if (!recommendedPrice || counterOfferPrice == null) {
    return null;
  }

  if (recommendedPrice <= counterOfferPrice) {
    return null;
  }

  return (
    <div data-intercom-target="pricing recommendation">
      <Stack space="none">
        <BelowText variant="body-semibold">Below Recommended Price</BelowText>

        <div>
          <PointerLink
            display="inline"
            color="primary"
            onClick={() => {
              onPriceClick(recommendedPrice);
              trackEvent('Shipper Clicked Set Recommended Price', {
                utm_medium: source,
              });
            }}
          >
            Set to{' '}
            {formatCurrency(recommendedPrice, {
              maximumFractionDigits: 0,
            })}
          </PointerLink>

          <TextBox display="inline">&nbsp;for faster booking</TextBox>
        </div>
      </Stack>
    </div>
  );
}
