import { MenuItem } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';

const radiusOptions: number[] = [50, 100, 150];
const recencyOptions: number[] = [30, 60, 90, 180];

const formatRecencyValue = (recency: unknown) => {
  const formattedRecency = recency as number;
  return formattedRecency > 90
    ? `${formattedRecency / 30}mo`
    : `${formattedRecency}d`;
};

export function RecencyAndRadiusSelectors() {
  return (
    <Columns space="xsmall">
      <Column width={['1/2', '1/3']}>
        <FormikTextField
          name="search_radius"
          select={true}
          fullWidth={true}
          defaultValue={100}
          SelectProps={{
            renderValue(value) {
              return (
                <Columns space="xsmall">
                  <Column width="content">
                    <TextBox color="secondary" variant="body-semibold">
                      Radius
                    </TextBox>
                  </Column>

                  <Column>
                    <TextBox noWrap={true} wrapOverflow={true}>
                      {Number(value)} mi
                    </TextBox>
                  </Column>
                </Columns>
              );
            },
          }}
        >
          {radiusOptions.map((radius) => (
            <MenuItem key={radius} value={radius}>
              {radius} mi
            </MenuItem>
          ))}
        </FormikTextField>
      </Column>
      <Column width={['1/2', '1/3']}>
        <FormikTextField
          select={true}
          name="search_recency"
          fullWidth={true}
          defaultValue={90}
          SelectProps={{
            renderValue(value) {
              return (
                <Columns space="xsmall">
                  <Column width="content">
                    <TextBox color="secondary" variant="body-semibold">
                      Recency
                    </TextBox>
                  </Column>

                  <Column>
                    <TextBox noWrap={true} wrapOverflow={true}>
                      {formatRecencyValue(value)}
                    </TextBox>
                  </Column>
                </Columns>
              );
            },
          }}
        >
          {recencyOptions.map((recency) => (
            <MenuItem key={recency} value={recency}>
              {formatRecencyValue(recency)}
            </MenuItem>
          ))}
        </FormikTextField>
      </Column>
    </Columns>
  );
}
